import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../listingModels';
import VideosUpload from '../../../../../../shared/component/video/videosUpload/VideosUpload';
import './video.scss';

const VideoContainer: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    return (
        <>
            <VideosUpload listingId={editFormData.data.id} from="listing" />
        </>
    );
};
export default VideoContainer;
