import { combineReducers } from '@reduxjs/toolkit';
import { ActivityAndReportingState } from '../../features/listings/listing/activityAndReporting/activityAndReportingModels';
import { UpcomingEventsState } from '../../features/listings/listing/upcomingEvents/upcomingEventsModels';
import ActivityReportReducer from '../../features/listings/listing/activityAndReporting/activityAndMarketingSlice';
import ListingReducer from '../../features/listings/listing/listingSlice';
import UpcomingEventsReducer from '../../features/listings/listing/upcomingEvents/upcomingEventsSlice';
import { ListingState } from '../../features/listings/listing/listingModels';
import {
    OpenHouseState,
    ShowingsState,
} from '../../shared/models/openHouseShowings/openHouseShowingsModels';
import ShowingsReducer from '../../features/listings/listing/drawer/screens/showings/showingsSlice';
import OpenHouseReducer from '../../features/listings/listing/drawer/screens/openHouse/openHouseSlice';
import { ListingFeaturesState } from '../../features/listings/listing/drawer/screens/listingFeatures/listingFeaturesModels';
import ListingFeaturesReducer from '../../features/listings/listing/drawer/screens/listingFeatures/listingFeaturesSlice';
import ShowcaseWebsiteReducer, {
    ShowcaseWebsiteState,
} from '../../features/listings/listing/drawer/screens/showcaseWebsite/showcaseWebsiteSlice';
import AdCopyReducer from '../../features/listings/listing/drawer/screens/adCopy/adCopySlice';
import { AdCopyState } from '../../features/listings/listing/drawer/screens/adCopy/adCopyModels';
import { PhotoEditorState } from '../../features/listings/listing/drawer/screens/photos/photoEditorModels';
import PhotosReducer from '../../features/listings/listing/drawer/screens/photos/photoEditorSlice';
import VideosReducer from '../../features/listings/listing/drawer/screens/videos/videoSlice';
import ListingListReducer from '../../features/listings/listingListSlice';
import { ListingListState } from '../../features/listings/listingListModels';
import ListingAdvancedSearchReducer from '../../features/listings/advancedSearch/advancedSearchSlice';
import { AdvancedSearchState } from '../../features/listings/advancedSearch/advancedSearchModels';
import { NotesState } from '../../features/listings/listing/notes/notesModel';
import ListingNotesReducer from '../../features/listings/listing/notes/notesSlice';
import { PropertyMarketingState } from '../../features/listings/listing/marketing/propertyMarketingListModels';
import PropertyMarketingListReducer from '../../features/listings/listing/marketing/propertyMarketingListSlice';
import { ListingCommissionState } from '../../shared/models/listingCommission/listingCommissionModels';
import ListingCommissionReducer from '../../shared/slices/listingCommission/listingCommissionSlice';
import { VideosState } from '../../shared/models/video/videoModels';
import MlsDetailsReducer from '../../features/listings/mlsDetails/mlsDetailsSlice';
import { MlsDetailsState } from '../../features/listings/mlsDetails/mlsDetailsModels';

interface ListingsState {
    listingList: ListingListState;
    listing: ListingState;
    activityReport: ActivityAndReportingState;
    upcomingEvents: UpcomingEventsState;
    showings: ShowingsState;
    openHouse: OpenHouseState;
    showcaseWebsite: ShowcaseWebsiteState;
    adCopy: AdCopyState;
    listingFeatures: ListingFeaturesState;
    videos: VideosState;
    photos: PhotoEditorState;
    advancedSearch: AdvancedSearchState;
    notes: NotesState;
    propertyMarketingList: PropertyMarketingState;
    listingCommissionList: ListingCommissionState;
    mlsDetails: MlsDetailsState;
}

export default combineReducers<ListingsState>({
    listingList: ListingListReducer,
    listing: ListingReducer,
    activityReport: ActivityReportReducer,
    upcomingEvents: UpcomingEventsReducer,
    showings: ShowingsReducer,
    openHouse: OpenHouseReducer,
    showcaseWebsite: ShowcaseWebsiteReducer,
    adCopy: AdCopyReducer,
    listingFeatures: ListingFeaturesReducer,
    videos: VideosReducer,
    photos: PhotosReducer,
    advancedSearch: ListingAdvancedSearchReducer,
    notes: ListingNotesReducer,
    propertyMarketingList: PropertyMarketingListReducer,
    listingCommissionList: ListingCommissionReducer,
    mlsDetails: MlsDetailsReducer,
});
