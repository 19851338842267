import { Grid } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import './testimonialList.scss';
import { ReactSortable } from 'react-sortablejs';
import TestimonialsAdd from '../add/TestimonialsAdd';
import { TestimonialModel } from '../../../models/testimonial/testimonial';
import _ from 'lodash';

interface TestimonialListModel {
    stateTestimonialList: TestimonialModel[];
    update: (from: string, data: TestimonialModel) => void;
    dnd: (from: string, data: TestimonialModel[]) => void;
}
const TestimonialList: FunctionComponent<TestimonialListModel> = ({
    stateTestimonialList,
    update,
    dnd,
}) => {
    const [testimonial, setTestimonial] = useState<TestimonialModel[]>([]);
    const [toEdit, setToEdit] = useState('');

    useEffect(() => {
        if (stateTestimonialList && stateTestimonialList.length >= 0) {
            setTestimonial(_.sortBy(stateTestimonialList, 'displayOrder'));
        }
    }, [stateTestimonialList, setTestimonial]);

    const updateAction = (from: string, data: TestimonialModel) => {
        update(from, data);
    };

    const cancel = () => {
        setToEdit('');
    };

    const dragAndDrop = () => {
        const orderedTestimonials = testimonial.map((data: TestimonialModel, index) => {
            return { ...data, displayOrder: index + 1 };
        });
        dnd('dnd', orderedTestimonials);
    };

    return (
        <Grid container className="testimonialList">
            <Grid container item md={12} className="testimonialHeader">
                <Grid item md={1} className="title">
                    Drag
                </Grid>
                <Grid item md={3} className="title">
                    Client Name
                </Grid>
                <Grid item md={5} className="title">
                    Preview
                </Grid>
                <Grid item md={1} className="title">
                    Edit
                </Grid>
                <Grid item md={1} className="title">
                    Delete
                </Grid>
            </Grid>
            <Grid item md={12} className="testimonialContent">
                {testimonial && testimonial.length > 0 ? (
                    <ReactSortable
                        list={testimonial}
                        setList={setTestimonial}
                        onEnd={dragAndDrop}
                        filter={'.locked_item'}
                        preventOnFilter={false}
                        onMove={(evt) => !evt.related.classList.contains('locked_item')}
                    >
                        {testimonial.map((item) => (
                            <Grid
                                key={item.id}
                                className={toEdit !== '' ? 'locked_item' : ''}
                            >
                                <Grid
                                    container
                                    item
                                    md={12}
                                    className={
                                        toEdit === item.id
                                            ? 'testimonial hide'
                                            : 'testimonial'
                                    }
                                >
                                    <Grid item md={1} className="content">
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Grid>
                                    <Grid item md={3} className="content">
                                        {item.author}
                                    </Grid>
                                    <Grid item md={5} className="content">
                                        {item.testimony}
                                    </Grid>
                                    <Grid item md={1} className="content">
                                        <FontAwesomeIcon
                                            className="actionIcon"
                                            icon={faEdit}
                                            onClick={() => setToEdit(item.id)}
                                        />
                                    </Grid>
                                    <Grid item md={1} className="content">
                                        <FontAwesomeIcon
                                            className="actionIcon"
                                            icon={faTrash}
                                            onClick={() => updateAction('delete', item)}
                                        />
                                    </Grid>
                                </Grid>
                                {toEdit === item.id ? (
                                    <TestimonialsAdd
                                        from="edit"
                                        data={item}
                                        save={updateAction}
                                        cancel={cancel}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        ))}
                    </ReactSortable>
                ) : (
                    <Grid
                        container
                        item
                        md={12}
                        className="testimonial"
                        justifyContent="center"
                    >
                        <span className="content">No testimonial is added</span>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default TestimonialList;
