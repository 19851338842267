import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';

import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import Logger from '../../utils/logging/logger';
import { getAgentsFromApi } from '../agents/agentListApi';
import { AgentSearchRequest, AgentsResponse } from '../agents/agentListModels';

import { AdvertisementListState, IndividualUpdateModel } from './advertisementListModels';
import { getAgentsForListing, searchListings } from './pressItem/pressListApi';

import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import {
    AdvertisementFileType,
    AdvertisementListModel,
    AdvertisementSearchRequest,
    AdvertisementTableActionType,
    CreateAdvertisementResponseModel,
} from './advertisementHolder/advertisementModels';
import { getAdvertisementListApi } from './advertisementListApi';
import { deleteAdvertisementApi } from './advertisementHolder/advertisementApi';
import { ImageDeleteModel } from './mediaOutlets/component/createAndEdit/createEditMediaOutletModel';
import { loadUserPermissions } from '../listings/advancedSearch/advancedSearchUtils';
import {
    ListingSearchRequest,
    SearchedListingListModel,
} from '../../shared/models/listing/commonModel';
import { initialAdvertisementSearch } from './advertisementListConstants';
import { orderBy } from 'lodash';

const advertisementListState: AdvertisementListState = {
    isLoading: false,
    pressAgentSearch: false,
    advertisementList: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    advTableAction: initialAdvertisementSearch,
    agentsList: [],
    listingsList: [],
    listingBasedAgentList: [],
};

export const advertisementsSlice = createSlice({
    name: 'advertisements',
    initialState: advertisementListState,
    reducers: {
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAgentSearchProgress: (state, action: PayloadAction<boolean>) => {
            state.pressAgentSearch = action.payload;
        },
        upsertAdvertisementList: (
            state,
            action: PayloadAction<PaginationResponse<AdvertisementListModel>>,
        ) => {
            const results = [
                ...state.advertisementList.results,
                ...action.payload.results,
            ];
            let advertisementList = {
                ...state.advertisementList,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (
                state.advertisementList.results.length !== 0 ||
                action.payload.results.length !== 0
            ) {
                advertisementList = {
                    ...advertisementList,
                    results:
                        state.advertisementList.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                advertisementList: advertisementList,
            };
        },
        updateTableAction: (
            state,
            action: PayloadAction<AdvertisementTableActionType>,
        ) => {
            const finalData = { ...state.advTableAction, ...action.payload };
            return {
                ...state,
                advSearch: true,
                advTableAction: finalData,
            };
        },
        setSearchedAgent: (state, action: PayloadAction<AgentsResponse[]>) => {
            return {
                ...state,
                agentsList: action.payload,
            };
        },
        setSearchedListings: (
            state,
            action: PayloadAction<SearchedListingListModel[]>,
        ) => {
            return {
                ...state,
                listingsList: action.payload,
            };
        },
        updateIndividualAdvertisementFromList: (
            state,
            action: PayloadAction<
                | IndividualUpdateModel<ImageDeleteModel>
                | IndividualUpdateModel<CreateAdvertisementResponseModel>
            >,
        ) => {
            const findIndex = state.advertisementList.results.findIndex(
                (x) => x.id === action.payload.data.id,
            );
            if (action.payload.from && action.payload.from === 'imageDelete') {
                const imageDelete = action.payload.data as ImageDeleteModel;
                if (AdvertisementFileType.Image === imageDelete.fileType) {
                    state.advertisementList.results[findIndex].adImageUrl = '';
                } else {
                    state.advertisementList.results[findIndex].tearSheetUrl = '';
                }
            } else {
                if (findIndex !== -1) {
                    const newValue = {
                        ...state.advertisementList.results[findIndex],
                        ...action.payload.data,
                    };
                    state.advertisementList.results[findIndex] = newValue;
                } else {
                    state.advertisementList.results = [
                        action.payload.data as AdvertisementListModel,
                        ...state.advertisementList.results,
                    ];
                    state.advertisementList.totalRecords =
                        state.advertisementList.totalRecords + 1;
                }
            }
        },
        deleteAdvertisement: (state, action: PayloadAction<string>) => {
            const updatedAdvList = state.advertisementList.results.filter(
                (x) => x.id !== action.payload,
            );
            return {
                ...state,
                advertisementList: {
                    ...state.advertisementList,
                    totalRecords: state.advertisementList.totalRecords - 1,
                    results: [...updatedAdvList],
                },
            };
        },
        deleteAdvertisementWithMoId: (state, action: PayloadAction<string>) => {
            const removedAds = state.advertisementList.results.filter(
                (ad) => ad.mediaOutletId === action.payload,
            );
            const updatedAdList = state.advertisementList.results.filter(
                (ad) => ad.mediaOutletId !== action.payload,
            );
            return {
                ...state,
                advertisementList: {
                    ...state.advertisementList,
                    totalRecords:
                        state.advertisementList.totalRecords - removedAds.length,
                    results: [...updatedAdList],
                },
            };
        },
        setAgentListBasedOnListings: (state, action: PayloadAction<AgentsResponse[]>) => {
            return {
                ...state,
                listingBasedAgentList: action.payload,
            };
        },
    },
});

/**
 * function to fetch the advertisement list based on the provided data
 * @param data
 * @returns
 */
export const getAdvertisementList =
    (data: AdvertisementSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const userPermission = loadUserPermissions();
            const response = await getAdvertisementListApi({
                ...data,
                ...userPermission,
            });
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(upsertAdvertisementList(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the advertisement list`));
            Logger.error(
                `Failed to retrieve the advertisement list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const deleteAdvertisementFromList =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteAdvertisementApi(id);
            if (response.status === 204) {
                dispatch(setSuccess(`Advertisement deleted successfully`));
                dispatch(deleteAdvertisement(id));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete the selected advertisement`));
            Logger.error(
                `Failed to delete the selected advertisement ${JSON.stringify(
                    exception,
                )}`,
            );
        }
    };

export const getAgents =
    (agentsRequest: AgentSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setAgentSearchProgress(true));
            const response = await getAgentsFromApi(agentsRequest);
            if (response.currentPage || response.recordsPerPage) {
                dispatch(setSearchedAgent(response.results));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the agents`));
        } finally {
            dispatch(setAgentSearchProgress(false));
        }
    };

export const getListings =
    (listingsRequest: ListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            const response = await searchListings({
                ...listingsRequest,
                address1: `"${listingsRequest.address1}"`,
            });
            if (response.currentPage || response.recordsPerPage) {
                dispatch(setSearchedListings(orderBy(response.results, 'status', 'asc')));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the listings`));
        }
    };

export const getAgentListBasedOnListing =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getAgentsForListing(listingId);
            if (response) {
                dispatch(setAgentListBasedOnListings(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the listings`));
        }
    };

export const {
    setProgress,
    setSearchedAgent,
    setSearchedListings,
    upsertAdvertisementList,
    updateTableAction,
    updateIndividualAdvertisementFromList,
    deleteAdvertisement,
    deleteAdvertisementWithMoId,
    setAgentListBasedOnListings,
    setAgentSearchProgress,
} = advertisementsSlice.actions;

export const advertisements = (state: RootState): AdvertisementListState =>
    state.advertisement.advList;

export default advertisementsSlice.reducer;
