import { OwnerInfoModel } from '../../models/ownerInfoModel/ownerInfoModel';

export const initializeOwnerInfoData = (
    displayOrder: number,
    differentAddress = false,
): OwnerInfoModel => {
    return {
        firstName: null,
        lastName: null,
        address: null,
        address2: null,
        city: null,
        regionId: null,
        zip: null,
        displayOrder: displayOrder,
        ownerAddressDifferentFromPropertyAddress: differentAddress,
    };
};

export const validationForOwnerFields = (owners: OwnerInfoModel[]): boolean => {
    let value = false;
    for (const owner of owners) {
        if (!owner.firstName || !owner.lastName) {
            value = false;
            break;
        } else if (owner.ownerAddressDifferentFromPropertyAddress) {
            const zip = owner?.zip?.toString();
            if (
                !owner.address ||
                !owner.city ||
                !owner.regionId ||
                !zip ||
                zip.length < 5
            ) {
                value = false;
                break;
            } else {
                value = true;
            }
        } else {
            value = true;
        }
    }
    return value;
};
