import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../../../../app/hooks';
import OwnerInfoTemplate from '../../../../../../shared/component/ownerInfoTemplate/OwnerInfoTemplate';
import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import { OwnerInfoModel } from '../../../../../../shared/models/ownerInfoModel/ownerInfoModel';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import './ownerInfo.scss';
import {
    initializeOwnerInfoData,
    validationForOwnerFields,
} from '../../../../../../shared/functions/ownerInfo/ownerInfoFunction';

interface OwnerInfoProps {
    ownerInfo: OwnerInfoModel[];
    handleOwnerInfo: (updatedOwners: OwnerInfoModel[]) => void;
    listingStatusId: number | null;
}

const OwnerInfo: FunctionComponent<OwnerInfoProps> = ({
    listingStatusId,
    ownerInfo,
    handleOwnerInfo,
}) => {
    const { common } = useAppSelector(lookups);
    const { states } = common as CommonLookups;
    const updateOwnerInfo = (index: number, formData: OwnerInfoModel) => {
        const currentIndex = index;
        const currentOwners = ownerInfo.map((owner: OwnerInfoModel, index: number) => {
            if (index === currentIndex) {
                return { ...owner, ...formData };
            }
            return { ...owner };
        });
        handleOwnerInfo(currentOwners);
    };
    const incrementOwner = () => {
        const totalOwners = ownerInfo.length;
        const addedOwners = [...ownerInfo, initializeOwnerInfoData(totalOwners + 1)];
        handleOwnerInfo(addedOwners);
    };
    const decrementOwner = (index: number) => {
        let removedOwners: OwnerInfoModel[] = [];
        ownerInfo.forEach((owner: OwnerInfoModel, i: number) => {
            if (index !== i) {
                removedOwners.push({ ...owner });
            }
        });
        removedOwners = removedOwners.map((owner: OwnerInfoModel, index: number) => {
            return { ...owner, displayOrder: index + 1 };
        });
        handleOwnerInfo(removedOwners);
    };

    return (
        <Grid container className="ownerInfo">
            OWNER INFO
            {ownerInfo.map((owner: OwnerInfoModel, index: number) => (
                <OwnerInfoTemplate
                    key={index}
                    index={index}
                    owner={owner}
                    states={states}
                    updateOwnerInfo={updateOwnerInfo}
                    decrementOwner={decrementOwner}
                    listingStatusId={listingStatusId}
                />
            ))}
            {ownerInfo.length < 3 ? (
                <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <button
                        className="customButton disable"
                        onClick={incrementOwner}
                        disabled={!validationForOwnerFields(ownerInfo)}
                    >
                        + Add More
                    </button>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default OwnerInfo;
