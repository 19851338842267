import { TableHeadCell } from '../../shared/models/customTable/customTableModels';
import { DevelopmentSearchRequest, TableColumn } from './developmentListModel';

export const initialDevelopmentSearch: DevelopmentSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    address: '',
    city: '',
    regionId: 0,
    officeId: '',
    statusId: 1,
    publish: true,
    validity: true,
    sortDirection: 'desc',
    sortColumn: 'Title',
};

export const developmentListTableHeadCells: TableHeadCell[] = [
    {
        id: 'logo',
        label: 'Logo',
        dbColMapping: 'Logo',
        doSort: false,
        isImage: true,
    },
    {
        id: 'title',
        label: 'Title',
        dbColMapping: 'Title',
        doSort: true,
    },
    {
        id: 'totalUnits',
        label: 'Total Units',
        dbColMapping: 'TotalUnits',
        doSort: false,
    },
    {
        id: 'numberOfActiveUnits',
        label: 'No Of Active Units',
        dbColMapping: 'NumberOfActiveUnits',
        doSort: false,
    },
    // {
    //     id: 'agents',
    //     label: 'Agents',
    //     dbColMapping: 'agents',
    //     doSort: false,
    // },
    {
        id: 'city',
        label: 'City',
        dbColMapping: 'City',
        doSort: true,
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: 'Status',
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'edit',
    },
];

export const commonTable: TableColumn[] = [
    {
        name: '',
        key: '',
        isImage: false,
        doSort: false,
    },
    {
        name: 'List Price',
        key: 'currentPrice',
        isPrice: true,
        doSort: true,
    },
    {
        name: 'Price per sq.ft.',
        key: 'pricePerSq',
        isPricePerSq: true,
        doSort: false,
    },
    {
        name: 'Beds/Baths',
        key: 'beds',
        isBedAndBath: true,
        doSort: false,
    },
    {
        name: 'Sale/Rental',
        key: 'businessPurposeTypeId',
        isNormal: true,
        doSort: false,
    },
    {
        name: 'Property Type',
        key: 'propertyType',
        isNormal: true,
        doSort: true,
    },
];
