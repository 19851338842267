import { Order } from '../../shared/models/customTable/customTableModels';
import { LookupInfo } from '../../shared/models/lookups/lookupsModels';
import {
    ListingsType,
    ManageList,
    MainHeading,
    TableColumn,
    ListingsTypeData,
} from './listingListModels';

export const getDefaultValue = (status: string): ListingsTypeData => {
    const direction: Order = status === 'sold' ? 'desc' : 'asc';
    return {
        totalRecords: 0,
        results: [],
        tableAction: {
            sortColumn: '',
            sortDirection: direction,
            itemsPerPage: 20,
            currentPage: 1,
            status: status,
            businessPurposeTypeId: '',
        },
    };
};

export const initialListingListConstants: ListingsType = {
    active: getDefaultValue('active'),
    pending: getDefaultValue('pending'),
    sold: getDefaultValue('sold'),
    expired: getDefaultValue('expired'),
    offMarket: getDefaultValue('temp Off-Market'),
    comingSoon: getDefaultValue('coming Soon'),
    draft: getDefaultValue('draft'),
    privateListing: getDefaultValue('private'),
    comingSoonMyListing: getDefaultValue('my Listings'),
    myMarket: getDefaultValue('my Market'),
};

export const mainHeading: MainHeading[] = [
    {
        title: 'Reports',
        actionTitle: 'reports',
    },
    {
        title: 'Marketing',
        actionTitle: 'marketing',
    },
    {
        title: 'Manage',
        actionTitle: 'manage',
    },
];

export const manageList: ManageList = {
    title: 'Manage',
    data: [
        {
            subTitle: 'Listing Details',
            toOpen: 'listing',
        },
        {
            subTitle: 'Price',
            toOpen: 'price',
        },
        {
            subTitle: 'Features',
            toOpen: 'features',
        },
        {
            subTitle: 'Open House',
            toOpen: 'openHouse',
        },
        {
            subTitle: 'showings',
            toOpen: 'showings',
        },
        {
            subTitle: 'Ad Copy',
            toOpen: 'adCopy',
        },
        {
            subTitle: 'Property Marketing',
            toOpen: 'propertyMarketing',
        },
        {
            subTitle: 'Showcase Site',
            toOpen: 'showcase',
        },
        {
            subTitle: 'Photos',
            toOpen: 'photos',
        },
    ],
};

export const listingCommonTable: TableColumn[] = [
    {
        name: '',
        key: '',
        isImage: false,
        doSort: false,
    },
    {
        name: 'List Price',
        key: 'currentPrice',
        isPrice: true,
        doSort: true,
    },
    {
        name: 'Price per sq.ft.',
        key: 'pricePerSq',
        isPricePerSq: true,
        doSort: false,
    },
    {
        name: 'Beds/Baths',
        key: 'beds',
        isBedAndBath: true,
        doSort: false,
    },
    {
        name: 'Sale/Rental',
        key: 'businessPurposeTypeId',
        isNormal: true,
        doSort: false,
    },
    {
        name: 'Property Type',
        key: 'propertyType',
        isNormal: true,
        doSort: true,
    },
];

export const pendingAndDraftTables: TableColumn[] = [
    {
        name: '',
        key: '',
        isImage: false,
        doSort: false,
    },

    {
        name: 'List Price',
        key: 'currentPrice',
        isPrice: true,
        doSort: true,
    },
    {
        name: 'Contract Price',
        key: 'offerPrice',
        isPrice: true,
        doSort: true,
    },
    {
        name: 'Beds/Baths',
        key: 'beds',
        isBedAndBath: true,
        doSort: false,
    },

    {
        name: 'Sale/Rental',
        key: 'businessPurposeTypeId',
        isNormal: true,
        doSort: false,
    },
    {
        name: 'Property Type',
        key: 'propertyType',
        isNormal: true,
        doSort: true,
    },
];

export const soldTable: TableColumn[] = [
    {
        name: '',
        key: '',
        doSort: false,
    },
    {
        name: 'Publish',
        key: 'publish',
        doSort: true,
    },
    {
        name: 'Select Sale',
        key: 'isSelectSale',
        doSort: false,
    },

    {
        name: 'Last Asking Price or Sold Price / Price',
        key: 'displayOfferPriceForSelectSold',
        doSort: true,
    },
    {
        name: 'Address or Title',
        key: 'useAddressAsTitle',
        doSort: true,
    },
    {
        name: 'Show On Map',
        key: 'showMap',
        doSort: false,
    },
    {
        name: 'Sold Date',
        key: 'closedDate',
        doSort: true,
    },

    {
        name: 'Agent Side',
        key: 'sideTypeId',
        doSort: false,
    },
    {
        name: 'Sale/Rental',
        key: 'businessPurposeTypeId',
        doSort: false,
    },
    {
        name: 'Property Type',
        key: 'propertyType',
        doSort: true,
    },
];

export const tabId = {
    active: {
        id: 0,
    },
    pending: {
        id: 1,
    },
    sold: {
        id: 2,
    },
    expired: {
        id: 3,
    },
    offMarket: {
        id: 4,
    },
    comingSoon: {
        id: 5,
    },
    draft: {
        id: 6,
    },
    privateListing: {
        id: 7,
    },
};

export const tabs = [
    'active',
    'pending',
    'sold',
    'expired',
    'offMarket',
    'comingSoon',
    'draft',
    'privateListing',
];

export const listingTableFirstActiveExp: TableColumn[] = [
    {
        name: 'List Date',
        key: 'listingDate',
        isDate: true,
    },
    {
        name: 'Expiration Date',
        key: 'expirationDate',
        isDate: true,
    },
];
export const listingTableFirstPending: TableColumn[] = [
    {
        name: 'Contract Date',
        key: 'contractDate',
        isDate: true,
    },
];

export const listingTableFirstOffMarket: TableColumn[] = [
    {
        name: 'Withdrawn Date',
        key: 'canceledDate',
        isDate: true,
    },
];

export const listingTableFirstComingSoon: TableColumn[] = [
    {
        name: 'Projected List Date',
        key: 'projectedListDate',
        isDate: true,
    },
];

export const dataComplete = [{ key: 'isValid', value: 'Data Complete' }];
export const others = [
    { key: 'sendToSir', value: 'Sent to SIR.com' },
    { key: 'sendToPartnerSites', value: 'Sent to Partner Sites' },
    { key: 'publishSite', value: 'Showcase Site Live' },
    { key: 'privateSite', value: 'Showcase Site is Private' },
];
export const mlsImport = [
    { key: 'suppressMlsImport', value: 'MLS Listing Import Suppressed' },
    { key: 'suppressAdCopyImport', value: 'MLS AdCopy Import Suppressed' },
    { key: 'suppressPhotoImport', value: 'MLS Photo Import Suppressed' },
];

export const price: LookupInfo[] = [
    { id: 'askingPrice', name: 'Last Asking Price' },
    { id: 'soldPrice', name: 'Sold Price' },
];

export const listingStatusAndTab = [
    {
        statusId: 1,
        tabId: tabId.active.id,
        text: 'Active',
    },
    {
        statusId: 3,
        tabId: tabId.pending.id,
        text: 'Pending',
    },
    {
        statusId: 4,
        tabId: tabId.sold.id,
        text: 'Sold',
    },
    {
        statusId: 2,
        tabId: tabId.expired.id,
        text: 'Expired',
    },

    {
        statusId: 7,
        tabId: tabId.comingSoon.id,
        text: 'Coming Soon',
    },
    {
        statusId: 8,
        tabId: tabId.draft.id,
        text: 'Draft',
    },
    {
        statusId: 11,
        tabId: tabId.privateListing.id,
        text: 'private',
    },
    {
        statusId: 9,
        tabId: tabId.offMarket.id,
        text: 'temp Off-Market',
    },
];

export const sortItems: LookupInfo[] = [
    { id: 'lastUpdated', name: 'Last Updated (Desc)' },
    { id: 'listingDate', name: 'List Date (Desc)' },
    { id: 'currentPrice', name: 'Price (Desc)' },
];

export const searchTypeList: LookupInfo[] = [
    { id: 'auto', name: 'Auto Search' },
    { id: 'manual', name: 'Manual Search' }
]