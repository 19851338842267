import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import './componentLoader.scss';

interface ComponentWithLoaderProps {
    showLoader: boolean;
    size?: string | number | undefined;
    showOverlay?: boolean;
    styleClass?: string;
    loadingText?: string;
}

const ComponentWithLoader: FunctionComponent<ComponentWithLoaderProps> = (props) => {
    const overlayClass = props.showOverlay || false ? 'loader-overlay' : '';
    const styleClass = props.styleClass ? props.styleClass : '';
    const renderLoader = () => {
        return (
            <div className={`loading-content ${overlayClass} ${styleClass}`}>
                {props.loadingText?.length && (
                    <span className="loading-text">{props.loadingText}</span>
                )}
                <CircularProgress size={props.size} style={{ color: '#002349' }} />
            </div>
        );
    };

    return <>{props.showLoader ? renderLoader() : props.children}</>;
};

export default ComponentWithLoader;
