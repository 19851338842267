import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    manageOfficeTemplate,
    getTemplates,
    templates,
} from '../designVaultSlice';
import { DesignVaultTemplate } from '../designVaultModel';
import DesignTemplate from '../DesignTemplate';
import { Switch } from '@material-ui/core';
import './OfficeDesignVaultManager.scss';
import { useParams } from 'react-router-dom';

interface OfficeDesignVaultManagerModel {
    officeId: string;
}
const getMergedTemplates = (
    defaultTemplates: DesignVaultTemplate[],
    officeTemplates: DesignVaultTemplate[],
) => {
    const officeTemplateSet = new Set(officeTemplates.map((t) => t.id));
    return defaultTemplates.map((t) => ({ ...t, checked: officeTemplateSet.has(t.id) }));
};

const OfficeDesignVaultManager: FunctionComponent<OfficeDesignVaultManagerModel> = () => {
    const dispatch = useAppDispatch();
    const { officeId } = useParams<{ officeId: string }>();
    const dvData = useAppSelector(templates);

    const onTemplateSelect = (data: DesignVaultTemplate, checked: boolean) => {
        dispatch(manageOfficeTemplate(officeId, data.id, checked, dvData.templates));
    };
    useEffect(() => {
        dispatch(getTemplates(officeId));
    }, [officeId, dispatch]);

    return (
        <div className="officeDvManagePage">
            <div className="officeDvHeading">Manage Office Design Vault Templates</div>
            <div className="officeDvSubhead">
                Selected templates will be available for office listings.
            </div>
            <div className="templateHolder">
                {dvData.templates.map(
                    (data, index: number) => (
                        <div className="templateWrapper" key={index}>
                            <DesignTemplate
                                template={data}
                                templateGuid={dvData.propertyGuid}
                            ></DesignTemplate>
                            <div className="customSwitch">
                                <Switch
                                    className="switchIcon"
                                    name="dvTemplateSelected"
                                    checked={data.isEnabled}
                                    onChange={(e) =>
                                        onTemplateSelect(data, e.target.checked)
                                    }
                                />
                            </div>
                        </div>
                    ),
                )}
            </div>
        </div>
    );
};

export default OfficeDesignVaultManager;
