import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    getListingData,
    getQcStatus,
    getRentals,
    updateListingDetails,
    updateListingDates,
    updateListingPrices,
    updateRentalPrices,
    listingSynFlagApi,
    getListingValidationDetails,
    getListingAmenitiesApi,
    getListingImagesApi,
    getListingAgentsApi,
    getTridentValidationDetails,
    getListingLastUpdated,
} from './listingApi';
import {
    ListingState,
    QcStatusDetails,
    CustomKey,
    UpdateDomainInfo,
    ListingAgent,
    DatesModel,
    ListingPrice,
    ListingDetailsSummary,
    TridentValidationModel,
    LastUpdatedResponse,
} from './listingModels';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import { dealTypes, editFormComponent } from './listingConstants';
import { findIndex, sortBy } from 'lodash';
import { initialStateDataConstant } from './listingConstants';
import { filterLookups, getQcMessages, getListingListName } from './listingUtils';
import {
    ListingDetailsData,
    ListingSaveResponse,
} from './drawer/screens/listingDetails/listingDetailsModels';
import { VirtualTourUrls } from './drawer/screens/virtualTour/virtualTourModels';
import { ShowCaseModel } from './drawer/screens/showcaseWebsite/showcaseWebsiteModels';
import { toggleSuppressAgent } from './drawer/screens/listingAgents/agentSectionApi';
import { ImageData } from '../../../shared/models/images/sharedModel';
import { radioStatusUpdate } from '../listingListApi';
import {
    CommonLookups,
    ListingLookups,
} from '../../../shared/models/lookups/lookupsModels';
import { PricesModel, RentalTermsModel } from './drawer/screens/prices/priceModel';
import { saveListingEditFormData } from './listingSave';
import { updateActiveTabListingList, setListingList } from '../listingListSlice';
import { ListingAmenity } from '../../../shared/models/listing/amenityModels';
import {
    BuildingFeatures,
    InteriorFeatures,
    LotFeatures,
} from '../../../shared/models/listing/amenityFeatures';
import {
    MlsSave,
    AdditionalMls,
} from '../../../shared/models/additionalMls/additionalMlsModels';
import { DataFlowResponse } from '../../../utils/api/apiModels';
import { OwnerInfoModel } from '../../../shared/models/ownerInfoModel/ownerInfoModel';
import { getVideosFromApi } from '../../../shared/api/video/videoApi';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import { getData } from '../../../utils/storageService';

export const listingState: ListingState = {
    data: initialStateDataConstant,
    isLoading: true,
    qcStatus: null,
    editForm: {
        formOpen: false,
        editFormName: '',
        listingEditFormName: '',
    },
    initialFormState: null,
    isEditDone: false,
    listingValidity: {
        validationMessages: [],
        entityIsValid: true,
    },
    isSaving: false,
    tridentValidation: null,
};

export const listingSlice = createSlice({
    name: 'listing',
    initialState: listingState,
    reducers: {
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        updateQcStatus: (state, action: PayloadAction<QcStatusDetails>) => {
            return {
                ...state,
                qcStatus: { ...action.payload },
            };
        },

        setInitialState: (state) => {
            return {
                ...state,
                data: initialStateDataConstant,
                isLoading: true,
                qcStatus: null,
            };
        },
        // Sets the name of the component to open in the side drawer
        updateListingEditFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                editFormName: action.payload,
                listingEditFormName: '',
            };
        },
        updateListingEditFormNameAndField: (
            state,
            action: PayloadAction<{
                formOpen: boolean;
                editFormName: string;
                field?: { name: string; refIndex: number };
            }>,
        ) => {
            state.editForm = {
                formOpen: action.payload.formOpen,
                editFormName: action.payload.editFormName,
                listingEditFormName: '',
                field: action.payload.field,
            };
        },
        // Fired when the user wants to close the side drawer component
        closeListingEditForm: (state, action: PayloadAction) => {
            state.editForm = {
                formOpen: false,
                editFormName: '',
                listingEditFormName: '',
            };
        },
        // Sets the data currently being edited in the listing edit form open
        // in the side drawer component
        setCurrentFormData: (
            state,
            action: PayloadAction<{
                formData:
                    | ListingDetailsData
                    | ShowCaseModel
                    | BuildingFeatures
                    | InteriorFeatures
                    | ImageData[]
                    | OwnerInfoModel[]
                    | VirtualTourUrls
                    | DatesModel
                    | PricesModel
                    | RentalTermsModel
                    | LotFeatures
                    | MlsSave;
                listingEditFormName: string;
            }>,
        ) => {
            state.editForm = {
                editFormName: state.editForm?.editFormName || '',
                formOpen: state.editForm?.formOpen || false,
                currentFormData: action.payload.formData,
                listingEditFormName: action.payload.listingEditFormName,
            };
        },
        // Updates main listing data with data from the form that was open
        // in the side drawer component
        updateMainListingData: (
            state,
            action: PayloadAction<
                | ListingSaveResponse
                | BuildingFeatures
                | InteriorFeatures
                | ShowCaseModel
                | VirtualTourUrls
                | UpdateDomainInfo
                | DatesModel
                | PricesModel
                | RentalTermsModel
                | LotFeatures
                | ListingDetailsSummary
                | CustomKey
                | ListingAgent[]
                | LastUpdatedResponse
            >,
        ) => {
            state.data = { ...state.data, ...action.payload };
        },
        setInitialFormState: (
            state,
            action: PayloadAction<{
                initialData: ShowCaseModel | OwnerInfoModel | AdditionalMls[];
            }>,
        ) => {
            state.initialFormState = action.payload.initialData;
        },
        updateListingFeaturesFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                editFormName: editFormComponent.listingFeatures,
                listingEditFormName: action.payload,
            };
        },
        toggleAgentSelectSale: (state, action: PayloadAction<string>) => {
            const currentAgents = current(state.data.listingAgents);
            const updatedAgents = currentAgents.map((agent: ListingAgent) => {
                const currentAgent = { ...agent };
                if (agent.agentId === action.payload) {
                    currentAgent.selectSale = !currentAgent.selectSale;
                }
                return currentAgent;
            });
            state.data.listingAgents = updatedAgents;
        },
        toggleSuppressAgentMLS: (state, action: PayloadAction<boolean>) => {
            state.data.suppressAgentImport = action.payload;
        },
        updatePhotoEditorFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                editFormName: editFormComponent.photos,
                listingEditFormName: action.payload,
            };
        },
        addListAgent: (state, action: PayloadAction<ListingAgent>) => {
            state.data.listingAgents = [...state.data.listingAgents, action.payload];
        },
        updateListAgent: (state, action: PayloadAction<ListingAgent>) => {
            state.data.listingAgents = state.data.listingAgents.map(
                (agent: ListingAgent) =>
                    agent.id === action.payload.id
                        ? { ...agent, ...action.payload }
                        : agent,
            );
        },
        deleteListAgent: (state, action: PayloadAction<ListingAgent>) => {
            state.data.listingAgents = state.data.listingAgents.filter(
                (agent: ListingAgent) => {
                    return agent.id !== action.payload.id;
                },
            );
        },
        updateOrderedListAgents: (state, action: PayloadAction<ListingAgent[]>) => {
            if (action.payload.length > 0) {
                action.payload.forEach((agent) => {
                    const index = findIndex(state.data.listingAgents, function (o) {
                        return o.id === agent.id && o.sideTypeId === agent.sideTypeId;
                    });
                    if (index !== -1) {
                        state.data.listingAgents[index] = agent;
                    }
                });
            }
        },
        updateAmenities: (
            state,
            action: PayloadAction<{
                features: ListingAmenity[];
                addingUpdating: boolean;
            }>,
        ) => {
            const { features, addingUpdating } = action.payload;
            if (addingUpdating) {
                const updatedAmenities = state.data.amenities.map((a) => ({
                    ...a,
                    ...features.find((f) => f.amenityId === a.amenityId),
                }));

                // Add new amenities
                const newAmenities = features.filter(
                    (f) =>
                        state.data.amenities.findIndex(
                            (a) => f.amenityId === a.amenityId,
                        ) < 0,
                );
                state.data.amenities = updatedAmenities.concat(newAmenities);
            } else {
                const ids = features.map((a) => a.amenityId);
                state.data.amenities = state.data.amenities.filter(
                    (a) => ids.indexOf(a.amenityId) < 0,
                );
            }
        },
        updateListingEditAction: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditDone: action.payload,
            };
        },
        updateListingValidStatus: (
            state,
            action: PayloadAction<DataFlowResponse | null>,
        ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    isValid: action.payload?.entityIsValid || false,
                },
                listingValidity: action.payload,
            };
        },
        updateFlag: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSaving: action.payload,
            };
        },
        updateTridentValidStatus: (
            state,
            action: PayloadAction<TridentValidationModel | null>,
        ) => {
            return {
                ...state,
                tridentValidation: action.payload,
            };
        },
    },
});

export const getListingDetailData =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getListingData(listingId);
            const videos = await getVideosFromApi(
                listingId,
                entityTypes.listing.key,
                videoTypes.propertyVideo.key,
            );
            if (response.id !== '')
                dispatch(
                    updateMainListingData({
                        ...response,
                        currentPrice: response?.prices[0]?.price,
                        videoDetail: videos[0] || [],
                    }),
                );
        } catch (e) {
            Logger.error('Failed to fetch Listing App data');
            dispatch(setError(`Failed to fetch Listing App data`));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const getListingAmenities =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getListingAmenitiesApi(listingId);
            if (response.length) {
                dispatch(updateMainListingData({ amenities: response }));
            }
        } catch (e) {
            Logger.error('Failed to fetch listing amenities');
            dispatch(setError(`Failed to fetch listing amenities`));
        }
    };

export const getListingImages =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getListingImagesApi(listingId);
            if (response.images.length || response.floorplanImages.length) {
                dispatch(updateMainListingData({ images: response.images }));
                dispatch(
                    updateMainListingData({ floorplanImages: response.floorplanImages }),
                );
            }
        } catch (e) {
            Logger.error('Failed to fetch listing images');
            dispatch(setError(`Failed to fetch listing images`));
        }
    };

export const getListingAgents =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getListingAgentsApi(listingId);
            if (response.length) {
                dispatch(
                    updateMainListingData({
                        listingAgents: sortBy(response, ['sideTypeId', 'displayOrder']),
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to fetch listing agents');
            dispatch(setError(`Failed to fetch listing agents`));
        }
    };

export const getQcInfo =
    (listingNumber: string | null, listingType: string | null): AppThunk =>
    async (dispatch) => {
        try {
            let qcStatus = await getQcStatus(listingNumber, listingType);
            if (qcStatus != null) {
                qcStatus = {
                    ...qcStatus,
                    rejectReasons: getQcMessages(qcStatus.rejectReasons),
                };
                dispatch(updateQcStatus(qcStatus));
            }
        } catch (e) {
            Logger.error('Error Invalid Qc Status');
            // dispatch(setError('Invalid Qc Status' + listingId));
        }
    };

export const fetchRentals =
    (salesRegionId: number, id: string): AppThunk =>
    async (dispatch) => {
        const rentals = await getRentals(salesRegionId, id);
        if (rentals) {
            const mainStateUpdate: CustomKey = {
                listingRentalDetails: rentals,
            };
            dispatch(updateMainListingData(mainStateUpdate));
        }
    };

const handleListingSave =
    (response: ListingSaveResponse): AppThunk =>
    async (dispatch, getState) => {
        // Load lookup names
        const { common, listing } = getState().lookups;
        const propertyType = filterLookups(
            (listing as ListingLookups).propertyTypes,
            response.propertyTypeId?.toString(),
        );
        const office = filterLookups(
            (common as CommonLookups).offices,
            response.officeId?.toString(),
        );
        const state = filterLookups(
            (common as CommonLookups).states,
            response.regionId?.toString(),
        );
        const status = filterLookups(
            (listing as ListingLookups).statuses,
            response.listingStatusId?.toString(),
        );

        let dealType = '';
        switch (response.dealTypeId) {
            case 1:
                dealType = dealTypes.listSide;
                break;
            case 2:
                dealType = dealTypes.bothSides;
                break;
            case 3:
                dealType = dealTypes.saleSide;
                break;
            default:
                dealType = '';
                break;
        }

        dispatch(
            updateMainListingData({
                ...response,
                state,
                propertyType,
                office,
                dealType,
                status,
            }),
        );
    };

export const saveListingDetails =
    (id: string, request: ListingDetailsData): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                const response = await updateListingDetails(id, request);
                dispatch(setSuccess('Listing details updated successfully'));
                dispatch(handleListingSave(response));
            }
        } catch {
            Logger.error(`Error saving listing details: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving listing details'));
        }
    };

export const handleListingFormSwitch =
    (newFormName: string, currentState: ListingState): AppThunk =>
    async (dispatch) => {
        dispatch(saveListingEditFormData(currentState));
        dispatch(updateListingFeaturesFormName(newFormName));
    };

export const toggleSuppress =
    (listingId: string, status: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const response = await toggleSuppressAgent(listingId);
            if (response.status === 204) {
                dispatch(toggleSuppressAgentMLS(status));
                dispatch(setSuccess('Agent MLS import is changed'));
            }
        } catch {
            Logger.error('Error in suppressing the agent MLS import');
            dispatch(setError('Error in suppressing the agent MLS import'));
        }
    };

export const handlePhotoEditorSwitch =
    (newFormName: string, currentState: ListingState): AppThunk =>
    async (dispatch) => {
        dispatch(saveListingEditFormData(currentState));
        dispatch(updatePhotoEditorFormName(newFormName));
    };

export const saveToggle =
    (listingId: string, status: boolean, name: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await radioStatusUpdate(listingId, name, status);
            if (name === 'suppressMlsImport') {
                const mainStateUpdate: CustomKey = {
                    suppressMlsImport: status,
                };
                dispatch(updateMainListingData(mainStateUpdate));
            } else
                dispatch(handleListingSave(response as unknown as ListingSaveResponse));
            dispatch(updateListingEditAction(true));
            dispatch(setSuccess(`Updated Successfully`));
        } catch (e) {
            Logger.error('Failed to update the listings publish status');
            dispatch(setError(`Failed to update the listings publish status`));
        }
    };

export const saveListingDates =
    (id: string, request: DatesModel, successMsg = true): AppThunk =>
    async (dispatch) => {
        try {
            if (request) {
                dispatch(updateFlag(true));
                const response = await updateListingDates(id, request);
                if (response) {
                    dispatch(handleListingSave(response));
                    if (successMsg) {
                        dispatch(setSuccess('Listing dates updated successfully'));
                    }
                }
            }
        } catch {
            Logger.error(`Error saving listing dates: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving listing dates'));
        } finally {
            dispatch(updateFlag(false));
        }
    };

export const saveListingPrices =
    (id: string, request: PricesModel): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                //defaulting commission to null, if empty is passed in
                request = {
                    ...request,
                    buyerCommission: request.buyerCommission
                        ? request.buyerCommission
                        : null,
                    listingCommission: request.listingCommission
                        ? request.listingCommission
                        : null,
                };
                const response = await updateListingPrices(id, request);
                if (response) {
                    const currentPrices = getState().listing.listing.data.prices;
                    const updatedPrices: ListingPrice[] = [
                        {
                            price: response.currentPrice ? response.currentPrice : 0,
                            rentalPeriod: currentPrices[0].rentalPeriod,
                        },
                    ];
                    const mainStateUpdate: CustomKey = {
                        prices: updatedPrices,
                    };
                    dispatch(updateMainListingData(mainStateUpdate));
                    dispatch(handleListingSave(response));
                    dispatch(updateListingEditAction(true));
                    dispatch(setSuccess('Listing prices updated successfully'));
                }
            }
        } catch {
            Logger.error(`Error saving listing prices: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving listing prices'));
        }
    };

export const saveRentalPrices =
    (id: string, request: RentalTermsModel): AppThunk =>
    async (dispatch) => {
        try {
            if (request) {
                //defaulting commission to null, if empty is passed in
                request = {
                    ...request,
                    buyerCommission: request.buyerCommission
                        ? request.buyerCommission
                        : null,
                    listingCommission: request.listingCommission
                        ? request.listingCommission
                        : null,
                };
                const updatedListingRentals = {
                    ...request,
                    listingRentalDetails: request.listingRentalDetails.filter(
                        (l) => l.price && l.price > 0,
                    ),
                    prices: request.prices.filter((p) => p.price && p.price > 0),
                };
                /**id is set to null, thus in DB it can reenter all the time */
                updatedListingRentals.listingRentalDetails =
                    updatedListingRentals.listingRentalDetails.map((v) => ({
                        ...v,
                        id: null,
                    }));
                const response = await updateRentalPrices(id, updatedListingRentals);
                if (response) {
                    const mainStateUpdate: CustomKey = {
                        prices: request.prices,
                    };
                    dispatch(updateMainListingData(mainStateUpdate));
                    dispatch(
                        updateMainListingData({
                            ...response,
                            listingRentalDetails: request.listingRentalDetails,
                        }),
                    );
                    dispatch(setSuccess('Rental prices updated successfully'));
                }
            }
        } catch {
            Logger.error(`Error saving rental prices: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving rental prices'));
        }
    };

export const fetchListingSyndicationFlags =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            if (listingId) {
                const response = await listingSynFlagApi(listingId);
                if (response !== null) {
                    dispatch(updateActiveTabListingList(response));
                }
            }
        } catch (e) {
            Logger.error(
                `Error in getting the listing syndication flags: ${JSON.stringify(e)}`,
            );
        }
    };

export const fetchListingValidDetails =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            if (listingId) {
                const response = await getListingValidationDetails(listingId);
                if (response !== null) {
                    dispatch(updateListingValidStatus(response));
                    dispatch(
                        updateActiveTabListingList({
                            listingId,
                            isValid: response.entityIsValid || false,
                        }),
                    );
                }
            }
        } catch (e) {
            Logger.error(`Error fetching listing valid status: ${JSON.stringify(e)}`);
        }
    };

export const fetchListingLastUpdated =
    (listingId: string, listingStatusId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (listingId) {
                const response = await getListingLastUpdated(listingId);
                if (response) {
                    dispatch(updateMainListingData(response));
                    const savedSortOption = getData('listingSortPreference');
                    if (
                        !savedSortOption ||
                        JSON.parse(savedSortOption).id === 'lastUpdated'
                    ) {
                        const tabToBeUpdated = getListingListName(listingStatusId);
                        if (tabToBeUpdated.length) {
                            const list =
                                getState().listing.listingList.data[tabToBeUpdated]
                                    .results;
                            const toChange = list.findIndex(
                                (item) => item.listingId === listingId,
                            );
                            if (toChange !== -1) {
                                const updatedListing = list[toChange];
                                const listCopy = [...list];
                                listCopy.splice(toChange, 1);
                                const finalList = [updatedListing, ...listCopy];
                                dispatch(
                                    setListingList({
                                        list: finalList,
                                        from: tabToBeUpdated,
                                    }),
                                );
                            }
                        }
                    }
                }
            }
        } catch (e) {
            Logger.error(
                `Error getting last updated date for listing: ${JSON.stringify(e)}`,
            );
        }
    };

export const fetchTridentValidateDetails =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            if (listingId) {
                const response = await getTridentValidationDetails(listingId);
                if (response !== null) {
                    dispatch(updateTridentValidStatus(response));
                }
            }
        } catch (e) {
            Logger.error(`Error fetching listing trident status: ${JSON.stringify(e)}`);
        }
    };

export const soldlistingOptionUpdate =
    (listingId: string, name: string, status: boolean, agentId?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            await radioStatusUpdate(listingId, name, status, agentId);
            if (name !== 'isSelectSale') {
                const mainStateUpdate: CustomKey = {
                    [name as keyof typeof initialStateDataConstant]: status,
                };
                dispatch(updateMainListingData(mainStateUpdate));
            } else {
                const agentList = [...getState().listing.listing.data.listingAgents];
                const updatedList = agentList.map((data) => {
                    if (data.agentId === agentId) {
                        return {
                            ...data,
                            selectSale: status,
                        };
                    } else {
                        return data;
                    }
                });
                // const currentAgentIndex = agentList.findIndex(
                //     (data) => data.agentId === agentId,
                // );
                // agentList[currentAgentIndex].selectSale = status;
                dispatch(
                    updateMainListingData({
                        listingAgents: updatedList,
                    }),
                );
            }
            dispatch(setSuccess(`Updated Successfully`));
        } catch (e) {
            Logger.error('Failed to update the listings options' + e);
            dispatch(setError(`Failed to update the listings options`));
        }
    };

export const {
    updateLoader,
    updateQcStatus,
    updateListingEditFormName,
    updateListingEditFormNameAndField,
    closeListingEditForm,
    setCurrentFormData,
    setInitialState,
    updateMainListingData,
    setInitialFormState,
    updateListingFeaturesFormName,
    toggleAgentSelectSale,
    toggleSuppressAgentMLS,
    updatePhotoEditorFormName,
    addListAgent,
    deleteListAgent,
    updateListAgent,
    updateOrderedListAgents,
    updateAmenities,
    updateListingEditAction,
    updateListingValidStatus,
    updateFlag,
    updateTridentValidStatus,
} = listingSlice.actions;

export const listingDetail = (state: RootState): ListingState => state.listing.listing;

export default listingSlice.reducer;
