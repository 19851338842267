import { postExternalApiCall } from '../../../utils/api/apiWrapper';
import {
    AgentCMSTestimonialModel,
    CMSTestimonialSave,
} from '../../models/testimonial/testimonial';

// /:entityType/:id/testimonial

export function updateAgentCmsTestimonialsList(
    entityType: number,
    profileId: string,
    testimonial: AgentCMSTestimonialModel[],
    oktaId: string,
): Promise<Response> {
    const toCmsList = {
        entityIdentifier: profileId,
        entityType: entityType,
        lastModifiedUserId: oktaId,
        testimonialList: testimonial,
    };
    return postExternalApiCall<CMSTestimonialSave, Response>(
        `${process.env.REACT_APP_AGENTCMS_URL}api/siteoptions/associate`,
        toCmsList,
        '',
    );
}

export function updateSalesTeamCmsTestimonialsList(
    entityType: number,
    teamId: string,
    testimonial: AgentCMSTestimonialModel[],
    oktaId: string,
): Promise<Response> {
    const toCmsList = {
        entityIdentifier: teamId,
        entityType: entityType,
        lastModifiedUserId: oktaId,
        testimonialList: testimonial,
    };
    return postExternalApiCall<CMSTestimonialSave, Response>(
        `${process.env.REACT_APP_AGENTCMS_URL}api/siteoptions/salesteam`,
        toCmsList,
        '',
    );
}
