import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import Logger from '../../../utils/logging/logger';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import {
    AdvancedSearchState,
    AdvancedSearchField,
    AdvancedSearchCriteria,
    AdvancedSearchRequest,
    SavedSearchDetails,
} from './advancedSearchModels';
import {
    getAdvancedSearchFields,
    performAdvancedSearch,
    exportAdvancedSearchResults,
    getSavedSearches,
    saveSearch,
    deleteSearch,
    setDefaultSearch,
} from './advancedSearchApi';
import {
    PaginationResponse,
    SecuredSearchRequest,
} from '../../../shared/models/pagination/paginationModels';
import { ListingsResponse } from '../listingListModels';
import { Order } from '../../../shared/models/customTable/customTableModels';
import _ from 'lodash';
import {
    insertFieldInCorrectOrder,
    loadSearchRequest,
    updateCriteriaList,
} from './advancedSearchUtils';
import { returnFile } from '../../../utils/api/fileResults';

export const initialState: AdvancedSearchState = {
    fields: [],
    criteria: [],
    isLoadingFields: false,
    isLoadingSearchResults: false,
    showActionButtons: false,
    results: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    sortOrder: 'asc',
    sortColumn: 'ListingNumber',
    officeStatsPreLoad: {
        field: '',
        businessPurpose: '',
    },
};

const resetFields = (fields: AdvancedSearchField[]): AdvancedSearchCriteria[] => {
    return fields
        .filter((f) => f.isDefault)
        .map((f) => {
            return { ...f, values: [] };
        });
};

export const advancedSearchSlice = createSlice({
    name: 'advancedSearch',
    initialState: initialState,
    reducers: {
        /** Sets loader flag for the fields multi-select component */
        updateFieldsLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoadingFields = action.payload;
        },
        /** Sets loader flag for the search results table */
        updateResultsLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoadingSearchResults = action.payload;
        },
        /**
         * Loads all possible fields to search on to the state, and also
         * default criteria
         */
        loadFields: (state, action: PayloadAction<AdvancedSearchField[]>) => {
            state.fields = action.payload;
            const defaultCriteria = (state.criteria = resetFields(action.payload));

            state.criteria = defaultCriteria;
            state.selectedFields = defaultCriteria;
        },
        /** Adds specific filter criteria to the state */
        addCriteria: (state, action: PayloadAction<AdvancedSearchCriteria>) => {
            const criteria = updateCriteriaList(state.criteria, action.payload);
            state.criteria = criteria;
        },
        /** Adds or removes fields to/from the state */
        updateField: (
            state,
            action: PayloadAction<{
                field: AdvancedSearchField;
                selected: boolean;
            }>,
        ) => {
            const { field, selected } = action.payload;
            let criteria = state.criteria;
            if (selected) {
                const existingCriteria = state.criteria.find(
                    (c) => c.fieldName === field.fieldName,
                );
                if (!existingCriteria) {
                    criteria = insertFieldInCorrectOrder(state.criteria, {
                        ...field,
                        values: [],
                    });
                }
            } else {
                criteria = criteria.filter((c) => c.fieldName !== field.fieldName);
            }

            state.criteria = criteria;
            state.selectedFields = criteria;
        },
        /** Loads criteria, field, and other metadata from a saved search to the state */
        loadSavedSearchDetails: (state, action: PayloadAction<SavedSearchDetails>) => {
            // Merge field info in with the saved criteria, so that we have all names/values
            // needed to render the filter info in the UI
            const fields: AdvancedSearchField[] = [];
            const criteria = action.payload.criteria.map((c) => {
                const field = state.fields.find((f) => f.fieldName === c.fieldName);
                if (field) {
                    fields.push(field);
                }
                return { ...field, values: c.values, endValue: c.endValue };
            });
            state.criteria = criteria as AdvancedSearchCriteria[];
            state.selectedFields = fields;
            state.savedSearchName = action.payload.name;
            state.sortColumn = action.payload.sortColumn;
            state.sortOrder = action.payload.sortDirection;
        },
        /** Loads search results to the state */
        loadResults: (
            state,
            action: PayloadAction<PaginationResponse<ListingsResponse>>,
        ) => {
            const reponse = action.payload;
            const combinedResults =
                reponse.currentPage > 1
                    ? [...state.results.results, ...reponse.results]
                    : reponse.results;
            state.results = { ...reponse, results: combinedResults };
        },
        /** Updates sort info on the state for re-use in export workflow */
        updateSortInfo: (
            state,
            action: PayloadAction<{ order: Order; column: string }>,
        ) => {
            state.sortColumn = action.payload.column;
            state.sortOrder = action.payload.order;
        },
        /** Load the user's saved searches to the state */
        loadSavedSearches: (state, action: PayloadAction<SavedSearchDetails[]>) => {
            state.savedSearches = action.payload;
            const defaultList = action.payload.find((x) => x.isDefault);
            if (defaultList && defaultList.criteria) {
                state.criteria = defaultList?.criteria;
            }
        },
        /** Adds new saved search or updates existing one in the state */
        updateSavedSearch: (
            state,
            action: PayloadAction<{ details: SavedSearchDetails; editing: boolean }>,
        ) => {
            const savedSearch = action.payload.details;
            if (action.payload.editing) {
                state.savedSearches = (state.savedSearches || []).map((s) =>
                    s.id === savedSearch.id ? savedSearch : s,
                );
            } else {
                state.savedSearches = (state.savedSearches || []).concat([savedSearch]);
            }
            state.savedSearchName = savedSearch.name;
        },
        /** Removes saved search from the state */
        removeSavedSearch: (state, action: PayloadAction<SavedSearchDetails>) => {
            const id = action.payload.id || '';
            state.savedSearches = (state.savedSearches || []).filter(
                (ss) => ss.id !== id,
            );
            state.savedSearchName = undefined;

            // Reset criteria so it's initialized when going back to the
            // search results page
            state.criteria = resetFields(state.fields);
            state.results = initialState.results;
        },
        /**set or load criteria for office stats */
        setOfficeStatLoad: (
            state,
            action: PayloadAction<{ field: string; businessPurpose: string }>,
        ) => {
            return {
                ...state,
                officeStatsPreLoad: action.payload,
            };
        },
        /** add multiple criteria when called from stats*/
        addMultipleCriteria: (state, action: PayloadAction<AdvancedSearchCriteria[]>) => {
            //to override any previous saved searches by reseting to default list
            const defaultCriteria = state.fields
                .filter((f) => f.isDefault)
                .map((f) => {
                    return { ...f, values: [] };
                });
            return {
                ...state,
                criteria: [..._.unionBy(action.payload, defaultCriteria, 'fieldName')],
                savedSearchName: undefined,
                officeStatsPreLoad: {
                    field: 'makeApiCall',
                    businessPurpose: '',
                },
            };
        },
        /** Updates the default flag on the saved searches */
        updateDefaultSearch: (state, action: PayloadAction<string>) => {
            state.savedSearches = (state.savedSearches || []).map((s) => {
                s.isDefault = s.id === action.payload;
                return s;
            });
        },
        /** update criteria on dnd*/
        updateCriteriaOnDnd: (state, action: PayloadAction<AdvancedSearchCriteria[]>) => {
            return {
                ...state,
                criteria: [...action.payload],
            };
        },
        /**load new search */
        loadNewSearch: (state) => {
            //to override any previous saved searches by reseting to default list
            const defaultCriteria = resetFields(state.fields);
            return {
                ...state,
                criteria: defaultCriteria,
                savedSearchName: undefined,
                selectedFields: state.fields.filter((f) => f.isDefault),
            };
        },
        updateActionButtonsShowStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                showActionButtons: action.payload,
            };
        },
        emptySavedSearchName: (state) => {
            return {
                ...state,
                savedSearchName: undefined,
            };
        },
        emptyResults: (state) => {
            return {
                ...state,
                results:  {
                    currentPage: 1,
                    totalRecords: 0,
                    recordsPerPage: 20,
                    results: [],
                }
            }
        }
    },
});

export const fetchAdvancedSearchFields =
    (criteria: SecuredSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFieldsLoader(true));
            const fields = await getAdvancedSearchFields(criteria);
            dispatch(loadFields(fields));
        } catch (e) {
            Logger.error('Failed to get advanced search fields', e);
            dispatch(setError('Failed to get advanced search fields'));
        } finally {
            dispatch(updateFieldsLoader(false));
        }
    };

export const performSearch =
    (page: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateResultsLoader(true));
            const request = loadSearchRequest(
                getState().listing.advancedSearch.criteria,
                getState().listing.advancedSearch.sortOrder,
                getState().listing.advancedSearch.sortColumn,
                page,
            );
            const response = await performAdvancedSearch(request);
            dispatch(loadResults(response));
        } catch (e) {
            Logger.error('Failed to perform advanced search', e);
            dispatch(setError('Failed to perform advanced search'));
        } finally {
            dispatch(updateResultsLoader(false));
        }
    };

export const exportResults =
    (request: AdvancedSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateResultsLoader(true));
            await returnFile(exportAdvancedSearchResults(request));
        } catch (e) {
            Logger.error('Failed to export advanced search results', e);
            dispatch(setError('Failed to export advanced search results'));
        } finally {
            dispatch(updateResultsLoader(false));
        }
    };

export const fetchSavedSearches =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            const results = await getSavedSearches(userId);
            dispatch(loadSavedSearches(results));
        } catch (e) {
            Logger.error('Error getting saved searches', e);
            dispatch(setError('Error getting saved searches'));
        }
    };

export const saveAdvancedSearch =
    (request: SavedSearchDetails): AppThunk =>
    async (dispatch, getState) => {
        try {
            const editing = (request.id?.length || 0) > 0;
            const saveRequest = {
                ...request,
                criteria: getState().listing.advancedSearch.criteria,
                sortColumn: getState().listing.advancedSearch.sortColumn,
                sortDirection: getState().listing.advancedSearch.sortOrder,
            };

            const savedSearch = await saveSearch(saveRequest);
            dispatch(updateSavedSearch({ details: savedSearch, editing }));
            dispatch(setSuccess('Search saved successfully'));
        } catch (e) {
            Logger.error('Error saving search', e);
            dispatch(setError('Error saving search'));
        }
    };

export const deleteAdvancedSearch =
    (request: SavedSearchDetails): AppThunk =>
    async (dispatch) => {
        try {
            if (window.confirm(`Are you sure you want to delete '${request.name}'?`)) {
                await deleteSearch(request.id || '');
                dispatch(removeSavedSearch(request));
                dispatch(setSuccess('Search deleted successfully'));
            }
        } catch (e) {
            Logger.error('Error deleting search', e);
            dispatch(setError('Error deleting search'));
        }
    };

export const setDefaultAdvancedSearch =
    (id: string, userId: string): AppThunk =>
    async (dispatch) => {
        try {
            await setDefaultSearch(id, userId);
            dispatch(updateDefaultSearch(id));
            dispatch(setSuccess('Default search set successfully'));
        } catch (e) {
            Logger.error('Error setting default search', e);
            dispatch(setError('Error setting default search'));
        }
    };

export const setAdvancedSearchName =
    (id: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const savedSearches = getState().listing.advancedSearch.savedSearches || [];
            const savedSearch = savedSearches.find((s) => s.id === id);
            if (!savedSearch) return;

            const saveRequest = { ...savedSearch, name };
            const result = await saveSearch(saveRequest);
            dispatch(updateSavedSearch({ details: result, editing: true }));
            dispatch(setSuccess('Search name set successfully'));
        } catch (e) {
            Logger.error('Error setting search name', e);
            dispatch(setError('Error setting search name'));
        }
    };

export const {
    updateFieldsLoader,
    updateResultsLoader,
    loadFields,
    addCriteria,
    updateField,
    loadSavedSearchDetails,
    loadResults,
    updateSortInfo,
    loadSavedSearches,
    updateSavedSearch,
    removeSavedSearch,
    setOfficeStatLoad,
    addMultipleCriteria,
    updateDefaultSearch,
    updateCriteriaOnDnd,
    loadNewSearch,
    updateActionButtonsShowStatus,
    emptySavedSearchName,
    emptyResults
} = advancedSearchSlice.actions;

export const advancedSearch = (state: RootState): AdvancedSearchState =>
    state.listing.advancedSearch;

export default advancedSearchSlice.reducer;
