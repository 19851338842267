import {
    getCall,
    deleteCall,
    postCall,
    postFormData,
    putCallRaw,
} from '../../utils/api/apiWrapper';
import {
    AppsModel,
    AppsRequestModel,
    CCMAAppModel,
    SaveAppResModel,
    ApplicationResponse,
} from './applicationsModel';

export function getAllAppsApi(data: AppsRequestModel): Promise<AppsModel[]> {
    return postCall<AppsRequestModel, AppsModel[]>(`applications/list`, data);
}

export function saveApp(data: FormData): Promise<SaveAppResModel> {
    return postFormData<SaveAppResModel>(`applications`, data);
}

export function deletApp(id: string): Promise<Response> {
    return deleteCall(`applications/${id}`);
}

export function updatePromotedApps(appId: string): Promise<Response> {
    return putCallRaw<null>(`applications/${appId}/togglePromoted`, null);
}

export function updateAppsCanShow(appId: string): Promise<Response> {
    return putCallRaw<null>(`applications/${appId}/toggleCanShow`, null);
}

export function getCCMAAppDetails(): Promise<CCMAAppModel[]> {
    return getCall<CCMAAppModel[]>('applications/cloudCmaInfo');
}

export function getApp(id: string): Promise<ApplicationResponse> {
    return getCall<ApplicationResponse>(`applications/${id}`);
}

export function setFavouriteApp(appId: string, profileId: string): Promise<Response> {
    return putCallRaw<null>(`applications/${appId}/fav/${profileId}`, null);
}

export function removeFavouriteApp(appId: string, profileId: string): Promise<Response> {
    return deleteCall(`applications/${appId}/fav/${profileId}`);
}
