import { postExternalApiCall } from '../../utils/api/apiWrapper';
import { ApproveAccessCodeRequest } from './approveAccessCodeModel';

export const approveAccessCode = (
    request: ApproveAccessCodeRequest,
): Promise<Response> => {
    return postExternalApiCall<ApproveAccessCodeRequest, Response>(
        `${process.env.REACT_APP_APIURL}/privatelisting/approveAccessCode`,
        request,
        '',
    );
};
