import React, { FunctionComponent, useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { useAppDispatch } from '../../../../../../app/hooks';
import { ImageData, Filter } from '../../../../../models/images/sharedModel';
import { AppThunk } from '../../../../../../app/store';
import './tagChips.scss';
import { googleAnalyticsAction } from '../../../../../functions/googleAnalytics/ga';

interface TagChipsProps {
    cancelHandler(): void;
    tagsData: Filter[];
    image: ImageData | undefined;
    currentImageType: string;
    isFullScreen: boolean;
    saveImageTags: (
        image: ImageData,
        chipData: Filter | undefined,
        currentImageType: string,
    ) => AppThunk;
    entityType: string;
    office: string | null;
}

const TagChips: FunctionComponent<TagChipsProps> = ({
    cancelHandler,
    tagsData,
    image,
    currentImageType,
    isFullScreen,
    saveImageTags,
    entityType,
    office,
}) => {
    const [chipsData, setChipsData] = useState<Filter[]>(tagsData);
    const [chipData, setChipData] = useState<Filter>();
    const [isDisabled, setDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const actionHandler = (id: number, value: boolean) => {
        const updatedData = chipsData.map((item) => {
            return item.isSelectable
                ? parseInt(item.id) === id
                    ? { ...item, isSelected: value }
                    : { ...item, isSelected: false }
                : item;
        });
        const selectedTag = updatedData.find(
            (item) => parseInt(item.id) === id && item.isSelected,
        );
        setChipData(selectedTag);
        setChipsData(updatedData);
    };

    const saveHandler = async () => {
        if (image) {
            await dispatch(saveImageTags(image, chipData, currentImageType));
            cancelHandler();
            googleAnalyticsAction(
                'Save',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image Edit, Image tags save, Image type: ${currentImageType}`,
            );
            googleAnalyticsAction(
                'Save',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image Edit, Image tags save, Image type: ${currentImageType}, Office: ${office}`,
            );
        }
    };

    useEffect(() => {
        tagsData.filter((item) => item.isSelectable).length > 0
            ? setDisabled(false)
            : setDisabled(true);
    }, [tagsData]);

    return (
        <div
            className={isFullScreen ? 'tagsSection tagsSectionFullScreen' : 'tagsSection'}
        >
            <div className="chipsContainer">
                {chipsData.length > 0 ? (
                    <>
                        {chipsData.map((chip: Filter) => {
                            return chip.isSelectable ? (
                                chip.isSelected ? (
                                    <Chip
                                        key={chip.id}
                                        label={chip.name}
                                        className="selected"
                                        onDelete={() =>
                                            actionHandler(parseInt(chip.id), false)
                                        }
                                    />
                                ) : (
                                    <Chip
                                        key={chip.id}
                                        label={chip.name}
                                        onClick={() =>
                                            actionHandler(parseInt(chip.id), true)
                                        }
                                    />
                                )
                            ) : (
                                ''
                            );
                        })}
                    </>
                ) : (
                    ''
                )}
                {isDisabled && (
                    <div className="noTags">No tags available for current image.</div>
                )}
            </div>
            <div className="buttonsHolder">
                <button className="actionButton cancel" onClick={cancelHandler}>
                    Cancel
                </button>
                <button
                    className={
                        isDisabled
                            ? 'actionButton saveActive saveInActive'
                            : 'actionButton saveActive'
                    }
                    onClick={saveHandler}
                    disabled={isDisabled}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default TagChips;
