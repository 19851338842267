import { Order, TableHeadCell } from '../../shared/models/customTable/customTableModels';
import {
    PaginationResponse,
    PaginationRequest,
} from '../../shared/models/pagination/paginationModels';

export interface AgentsState {
    agents: PaginationResponse<AgentsResponse>;
    progress: boolean;
    tableAction: AgentSearchRequest;
    isSearched: boolean;
}

/**
 * Criteria for handling agent searches
 */
export interface AgentSearchRequest extends PaginationRequest {
    /** Name of the agent(s) to return */
    name?: string;

    /** Status of the agents to return */
    status?: number;

    /** Name of the office of agents to return */
    officeName?: string;

    /** Validity flag of agents to return */
    isValid?: number;

    /** published flag of agents to return */
    isPublished?: number;

    /** agent with undersized image of agents to return */
    withUndersizedImage?: boolean;
}

export interface AgentsResponse {
    agentId: string;
    firstName: string;
    lastName: string;
    agentType: string;
    officeName: string;
    status: string;
    mlsNumber: string;
    isValid: boolean;
    publish: boolean;
    imageUrlId: string;
    imageExtension: string;
}

export const agentTableHeadCells: TableHeadCell[] = [
    {
        id: 'imageUrlId',
        label: '',
        dbColMapping: '',
        doSort: false,
        isImage: true,
    },
    {
        id: 'isValid',
        label: 'Data Complete',
        dbColMapping: '',
        checkbox: true,
        doSort: false,
    },
    {
        id: 'publish',
        label: 'Publish',
        dbColMapping: '',
        checkbox: true,
        doSort: false,
    },
    {
        id: 'mlsNumber',
        label: 'MLS ID',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'firstName',
        label: 'First Name',
        dbColMapping: 'FirstName',
    },
    {
        id: 'lastName',
        label: 'Last Name',
        dbColMapping: 'LastName',
    },
    {
        id: 'agentType',
        label: 'Type',
        dbColMapping: 'Type',
    },
    {
        id: 'officeName',
        label: 'Office',
        dbColMapping: 'WebOffice',
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: '',
        icon: true,
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
];

export const agentInitialSearch: AgentSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    status: 1,
    name: '',
    officeName: '',
    isValid: 1,
    isPublished: 1,
    withUndersizedImage: false,
    sortColumn: 'FirstName',
    sortDirection: 'asc',
};

export interface AgentListUpdate {
    agentId: string;
    agentType: string;
    mlsNumber: string;
    officeName: string;
    publish: boolean;
    status: string;
    imageUrlId?: string;
}

export interface AgentTableActionType {
    [key: string]: string | Order | number | string[] | undefined | boolean;
}
