import React, { FunctionComponent, useState } from 'react';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import {
    cmrDetails,
    displayReportToggle,
    deleteData,
    saveShowing,
} from '../../../cmrSlice';
import { sortByDate } from '../../../../../utils/urlUtils';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import { SectionProps, Showing } from '../../../cmrModels';
import './index.scss';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import ShowingsTemplate from '../../../../../shared/component/listing/showingsTemplate/ShowingsTemplate';

const Showings: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, listingId, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const showings = sortByDate(paneldata.showings as Showing[], 'showingDate');
    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const [activeEditId, setActiveEditId] = useState<string>('');

    const initialState: Showing = {
        listingId: listingdata.id,
        listingNumber: listingId,
        showingDate: '',
        buyer: '',
        location: '',
        buyerAgents: '',
        notes: '',
        feedback: '',
        count: 0,
    };

    const deleteIt = (id: string) => {
        const url = `Showing/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'id'));
        googleAnalyticsAction('Delete', 'Click', 'Showings Delete');
    };

    const editClickHandler = (currentEditId: string) => {
        if (activeEditId === currentEditId) {
            setActiveEditId('');
        } else if (currentEditId) {
            googleAnalyticsAction('Edit', 'Click', 'Showings Edit');
            setActiveEditId(currentEditId);
        }
    };
    const handleSaveClick = (showingData: Showing) => {
        dispatch(saveShowing(showingData));
    };
    return (
        <div className="showings-container-cmr">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Showings Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={hideDisplayOnReport(5, sortOrder) ? true : false}
            />
            <ShowingsTemplate
                useGa={true}
                showings={showings}
                activeEditId={activeEditId}
                deleteIt={deleteIt}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
                expanded={props.expanded}
                isCmr={true}
            />
        </div>
    );
};
export default Showings;
