import { FunctionComponent, useState, useEffect, MouseEvent } from 'react';
import { WebsiteData, AnalyticsCounts } from '../../../../cmrModels';
import { useAppDispatch, useFormData } from '../../../../../../app/hooks';
import { Input } from '@material-ui/core';
import { useFileManagement } from '../../../../../../shared/marketing/hooks';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveWebisteData } from '../../../../cmrSlice';

interface WebsiteFormProps {
    siteData?: WebsiteData;
    formEdit?: boolean;
    cancelClick(): void;
    listingId: string;
    sectionId: number;
    addedSites: AnalyticsCounts[];
}

const initialSiteData: WebsiteData = {
    listingId: '',
    siteName: '',
    deepLink: '',
    totalViews: 0,
    isFile: false,
    file: undefined,
    url: '',
};

const WebsiteForm: FunctionComponent<WebsiteFormProps> = ({
    siteData,
    formEdit,
    cancelClick,
    listingId,
    sectionId,
    addedSites,
}) => {
    const dispatch = useAppDispatch();
    const { formData, setFormData, handleTextChange, removeSpaces, handleNumberChange } =
        useFormData(siteData || initialSiteData);
    const { fileData, handleFileUpload, setFileData, handleFileClear } =
        useFileManagement(formData);

    const [active, setActive] = useState(true);

    useEffect(() => {
        setFormData(formData);
        setFileData(fileData);
        const siteAlreadyExists = (siteName: string): boolean => {
            return addedSites.find((site: AnalyticsCounts) => {
                return formEdit
                    ? site.id !== formData.id && site.siteName === siteName
                    : site.siteName === siteName;
            })
                ? true
                : false;
        };
        const { siteName, deepLink, totalViews } = formData;
        setActive(
            siteName === '' ||
                deepLink === '' ||
                totalViews < 1 ||
                siteAlreadyExists(siteName),
        );
    }, [formData, setFormData, fileData, setFileData, addedSites, formEdit]);

    const save = (e: MouseEvent<HTMLButtonElement>) => {
        const saveRequest = { ...formData, ...fileData, listingId: listingId };
        dispatch(saveWebisteData(saveRequest));
        setFormData(initialSiteData);
        handleFileClear(e, false);
        cancelClick();
    };

    const appendHttp = () => {
        let updatedDeeplink = formData.deepLink;
        if (updatedDeeplink && updatedDeeplink !== '') {
            if (updatedDeeplink.search(/^http[s]?:\/\//) === -1) {
                updatedDeeplink = `http://${updatedDeeplink}`;
            }
            setFormData({ ...formData, deepLink: updatedDeeplink });
        }
    };

    return (
        <>
            {formEdit ? <div className="overlayEditForm" /> : ''}
            <div className={formEdit ? 'editWebisteForm' : 'webisteForm'}>
                <div className={formEdit ? 'editSite' : 'addSite'}>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            placeholder="Website Name"
                            name="siteName"
                            value={formData.siteName}
                            onChange={handleTextChange}
                            onBlur={removeSpaces}
                            inputProps={{ 'aria-label': 'Website Name', maxLength: 500 }}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            placeholder="Deep Link"
                            name="deepLink"
                            value={formData.deepLink}
                            onChange={handleTextChange}
                            onBlur={appendHttp}
                            inputProps={{ 'aria-label': 'Url' }}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            placeholder="Total Views"
                            name="totalViews"
                            value={formData.totalViews !== 0 ? formData.totalViews : ''}
                            onChange={handleNumberChange}
                            inputProps={{ 'aria-label': 'Total Visits' }}
                        />
                    </span>
                    <span
                        className={
                            formEdit
                                ? 'editActionHolder siteFileHolder'
                                : 'actionHolder siteFileHolder'
                        }
                    >
                        {!fileData.file && !fileData.isFile ? (
                            <label className="siteFile">
                                Upload File
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                                <Input
                                    className={
                                        formEdit ? 'cmrEditInput file' : 'cmrInput file'
                                    }
                                    name="logoUpload"
                                    inputProps={{
                                        'aria-label': 'File Upload',
                                        accept: '.jpg,.png',
                                    }}
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                            </label>
                        ) : (
                            <label className="siteFile">
                                <button
                                    onClick={(e) => handleFileClear(e, false)}
                                    className="removeFile"
                                >
                                    Remove File
                                </button>
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                            </label>
                        )}

                        <span className="spec">
                            File Format: JPG, PNG | File Size: 50MB
                        </span>
                    </span>
                </div>
                {!formEdit ? (
                    <div className="addButtonHolder">
                        <button
                            className={`addButton ${active ? 'active' : 'unActive'}`}
                            onClick={save}
                            disabled={active ? true : false}
                        >
                            Add
                        </button>
                    </div>
                ) : (
                    <div className={formEdit ? 'editButtonHolder' : ''}>
                        <button className="actionButton cancel" onClick={cancelClick}>
                            Cancel
                        </button>
                        <button
                            disabled={active ? true : false}
                            className={
                                !active
                                    ? 'actionButton saveActive'
                                    : 'actionButton saveInActive'
                            }
                            onClick={save}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default WebsiteForm;
