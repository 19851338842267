import { FunctionComponent, useMemo } from 'react';
import { AgentDescription, DrawerContainerProps } from '../../../agentModels';

import { useAppDispatch } from '../../../../../../app/hooks';
import Testimonials from '../../../../../../shared/component/testimonials';
import {
    AgentTestimonialsModel,
    TestimonialModel,
} from '../../../../../../shared/models/testimonial/testimonial';
import { saveTestimonials } from '../../../agentSlice';
import { getUser } from '../../../../../../shared/auth/authService';
import { Grid } from '@material-ui/core';

const AgentTestimonials: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const stateTestimonialList = editFormData.agentDetails
        .agentTestimonials as AgentTestimonialsModel[];
    const profileId = (editFormData.agentDetails.descriptionDetails as AgentDescription)
        .profileId;
    const user = useMemo(() => getUser(), []);

    const saveCall = (from: string, data: TestimonialModel | TestimonialModel[]) => {
        if (from === 'add') {
            const agentTestimonial = {
                ...data,
                profileId: profileId,
                displayOrder: stateTestimonialList.length + 1,
            };
            dispatch(
                saveTestimonials(
                    from,
                    profileId,
                    [...stateTestimonialList, agentTestimonial as AgentTestimonialsModel],
                    user,
                ),
            );
        } else if (from === 'edit') {
            const findIndex = stateTestimonialList.findIndex(
                (x) => x.id === (data as AgentTestimonialsModel).id,
            );
            const testimonialList = [...stateTestimonialList];
            testimonialList[findIndex] = data as AgentTestimonialsModel;
            dispatch(saveTestimonials(from, profileId, testimonialList, user));
        } else if (from === 'delete') {
            const remainingTestimonials = stateTestimonialList
                .filter((x) => x.id !== (data as AgentTestimonialsModel).id)
                .map((data, index) => {
                    return {
                        ...data,
                        displayOrder: index + 1,
                    };
                });
            dispatch(saveTestimonials(from, profileId, remainingTestimonials, user));
        } else {
            dispatch(
                saveTestimonials(from, profileId, data as AgentTestimonialsModel[], user),
            );
        }
    };

    return (
        <>
            <Grid item container md={12} className="info-text">
                Note: Data entered in the Testimonials will only be shown in the Agent
                site
            </Grid>
            <Testimonials
                saveCall={saveCall}
                stateTestimonialList={stateTestimonialList}
            />
        </>
    );
};
export default AgentTestimonials;
