import { ChangeEvent, FunctionComponent, useState } from 'react';
import './virtualTour.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles, TextField } from '@material-ui/core';
import { DrawerContainerProps } from '../../../listingModels';
import { useAppDispatch, useFormData } from '../../../../../../app/hooks';
import { validURL } from '../../../../../../utils/urlUtils';
import { VirtualTourUrls } from './virtualTourModels';
import { setCurrentFormData } from '../../../listingSlice';
import { editFormComponent } from '../../../listingConstants';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        bottom: '-22px',
    },
}));

interface Tour {
    virtualTourUrl: boolean;
    interactiveFloorPlanUrl: boolean;
    virtualTour3dUrl: boolean;
}

const VirtualTour: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const helperTextStyles = useHelperTextStyles();
    const dispatch = useAppDispatch();
    const [errorState, setErrorState] = useState<Tour>({
        virtualTourUrl: false,
        interactiveFloorPlanUrl: false,
        virtualTour3dUrl: false,
    });

    const initialData: VirtualTourUrls = {
        virtualTourUrl: editFormData.data.virtualTourUrl,
        interactiveFloorPlanUrl: editFormData.data.interactiveFloorPlanUrl,
        virtualTour3dUrl: editFormData.data.virtualTour3dUrl,
    };

    const { formData, setFormData } = useFormData(initialData);

    const updateChange = () => {
        if (
            !errorState.interactiveFloorPlanUrl &&
            !errorState.virtualTour3dUrl &&
            !errorState.virtualTourUrl
        ) {
            dispatch(
                setCurrentFormData({
                    formData,
                    listingEditFormName: editFormComponent.virtualTour,
                }),
            );
        }
    };

    const isValidURL = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (value !== '' && !validURL(value)) {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: true,
            }));
        } else {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: false,
            }));
        }
    };

    return (
        <div className="virtualTourHolder">
            <Grid container className="content" alignItems="center">
                <Grid item md={3} sm={3} xs={12} className="tourLabel">
                    VIRTUAL TOUR
                </Grid>
                <Grid item md={9} sm={9} xs={12} className="">
                    <TextField
                        className="tourHolder"
                        name="virtualTourUrl"
                        onBlur={updateChange}
                        onChange={isValidURL}
                        value={formData.virtualTourUrl || ''}
                        InputProps={{
                            disableUnderline: true,
                            'aria-label': 'virtualTour',
                            className: 'tourInput',
                        }}
                        error={errorState.virtualTourUrl}
                        helperText={
                            errorState.virtualTourUrl
                                ? 'Url should be in the format of https eg: https://www.google.com'
                                : ''
                        }
                        FormHelperTextProps={{
                            classes: {
                                root: helperTextStyles.root,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className="content" alignItems="center">
                <Grid item md={3} sm={3} xs={12} className="tourLabel">
                    INTERACTIVE FLOOR PLAN
                </Grid>
                <Grid item md={9} sm={9} xs={12} className="">
                    <TextField
                        className="tourHolder"
                        name="interactiveFloorPlanUrl"
                        InputProps={{
                            disableUnderline: true,
                            'aria-label': 'interactiveFloorPlanUrl',
                            className: 'tourInput',
                        }}
                        onBlur={updateChange}
                        onChange={isValidURL}
                        value={formData.interactiveFloorPlanUrl || ''}
                        error={errorState.interactiveFloorPlanUrl}
                        helperText={
                            errorState.interactiveFloorPlanUrl
                                ? 'Url should be in the format of https eg: https://www.google.com'
                                : ''
                        }
                        FormHelperTextProps={{
                            classes: {
                                root: helperTextStyles.root,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className="content" alignItems="center">
                <Grid item md={3} sm={3} xs={12} className="tourLabel">
                    MATTERPORT 3D TOUR
                </Grid>
                <Grid item md={9} sm={9} xs={12} className="">
                    <TextField
                        className="tourHolder"
                        name="virtualTour3dUrl"
                        InputProps={{
                            disableUnderline: true,
                            'aria-label': 'matterportTour',
                            className: 'tourInput',
                        }}
                        onBlur={updateChange}
                        onChange={isValidURL}
                        value={formData.virtualTour3dUrl || ''}
                        error={errorState.virtualTour3dUrl}
                        helperText={
                            errorState.virtualTour3dUrl
                                ? 'Url should be in the format of https eg: https://www.google.com'
                                : ''
                        }
                        FormHelperTextProps={{
                            classes: {
                                root: helperTextStyles.root,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
export default VirtualTour;
