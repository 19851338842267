import { combineReducers } from '@reduxjs/toolkit';
import salesTeamList from '../../features/salesTeam/salesTeamListSlice';
import salesTeamDetail from '../../features/salesTeam/salesTeamDetail/salesTeamSlice';
import { SalesTeamState } from '../../features/salesTeam/salesTeamListModels';
import { SalesTeamDetailInitialState } from '../../features/salesTeam/salesTeamDetail/salesTeamModels';

interface SalesTeamRootState {
    list: SalesTeamState;
    details: SalesTeamDetailInitialState;
}
export default combineReducers<SalesTeamRootState>({
    list: salesTeamList,
    details: salesTeamDetail,
});
