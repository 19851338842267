import { userRoles } from '../../constants/user/roles';
import { User, RolePermission } from '../authModels';
import { roleFilter } from '../../constants/user/roles';
import { getUser } from '../authService';
import _ from 'lodash';

const containsPermission = <K extends keyof RolePermission>(
    rolePermission: RolePermission,
    permissionToCheck: number,
    permissionField: K,
): boolean => {
    const permissionValue = rolePermission[permissionField] as number;
    const val = permissionValue & permissionToCheck;
    return val !== 0;
};

/**
 * Determines if a user has a specific permission
 * @param user The current user to fetch permissions for
 * @param permissionToCheck Permission to check that the user has
 * @param permissionField The field on the object to check for the permission (ex: listing permission)
 * @returns Flag indicating if the user has the given permission
 */
export const hasPermission = <K extends keyof RolePermission>(
    user: User,
    permissionToCheck: number,
    permissionField: K,
): boolean => {
    const filteredPermissions = user.roles?.filter((p) =>
        containsPermission(p, permissionToCheck, permissionField),
    );
    return (filteredPermissions?.length || 0) > 0;
};

/**
 * Gets data from user permission local storage
 * @param user the current user to fetch permissions for
 * @param key any key of the permission object
 */
export const fetchUserPermissions = <K extends keyof User>(
    user: User,
    key: K,
): string[] => {
    if (!userIsInRole(user, userRoles.systemAdmin.key)) {
        return user[key] as string[];
    } else {
        return [];
    }
};

/**
 * Get list of roles the user is asscoiated with
 * @param user the current user to get role ids of
 * @returns list of roles the user is associated with
 */

export const getUserRoleIds = (user: User): string[] => {
    return user.roles?.map((r) => r.roleId) || [];
};

/**
 * Checks to see if the user is in at all of the roles in the
 * supplied list
 * @param user the current user to check roles for
 * @param roleIds the list of role IDs to check if the user is in
 * @returns true if the user is in all roles in the list,
 * otherwise false
 */
export const userIsInAllRole = (user: User, roleIds: string[]): boolean => {
    const userRoleIds = getUserRoleIds(user)?.map((r) => r.toUpperCase());
    const commonRoles = _.intersectionBy(roleIds, userRoleIds);
    return _.isEqual(roleIds, commonRoles);
};

/**
 * Checks to see if the user is in at least one of the roles in the
 * supplied list
 * @param user the current user to check roles for
 * @param roleIds the list of role IDs to check if the user is in
 * @returns true if the user is in any of the roles in the list,
 * otherwise false
 */
export const userIsInAnyRole = (user: User, roleIds: string[]): boolean => {
    const userRoleIds = getUserRoleIds(user);
    return userRoleIds.some((ur) => roleIds.indexOf(ur.toUpperCase()) >= 0);
};

/**
 * Checks to see if the user is assigned to the given role
 * @param user the current user to check roles for
 * @param roleId the of the role to check that the user is in
 * @returns true if the user is assigned to the role, otherwise false
 */
export const userIsInRole = (user: User, roleId: string): boolean => {
    return userIsInAnyRole(user, [roleId]);
};

/**
 * Checks to see if a user who is an agent is associated with more than one agent or
 * sales team
 * @param user current user to validate
 * @param toCheck string value indicating which field we're checking to see if agent
 * is associated with more than one of that entity (i.e., agent or sales team)
 * @returns true if an agent and associated with more than one of the entity type
 */
export const validateAgentAction = (user: User, toCheck: string): boolean => {
    if (userIsInRole(user, userRoles.agent.key)) {
        if (toCheck === 'agentId') {
            const agentId = fetchUserPermissions(user, 'agentIds');
            return toCheck === 'agentId' && agentId.length > 1;
        } else if (toCheck === 'salesTeamId') {
            const salesTeamId = fetchUserPermissions(user, 'salesTeamIds');
            return toCheck === 'salesTeamId' && salesTeamId.length > 1;
        } else if (toCheck === 'developmentAccess' && user.isAssociatedWithBuilding) {
            return toCheck === 'developmentAccess' && user.isAssociatedWithBuilding;
        }
    }
    return false;
};

/**
 * Determines if a user has access to the given route
 * @param user current user to check permissions for
 * @param route route on which validation has to be checked
 * @param roleId current roleId
 * @param property key from roleFilter, since validateRoute being used for left nav and url paths
 * @returns boolean value indicating if user has permission to the route
 */
export const validateRoute = (user: User, route: string, property: string): boolean => {
    const isValid = roleFilter.find((item) => {
        return (
            (item[property as keyof typeof item] as string)?.length > 0 &&
            route === item[property as keyof typeof item] &&
            userIsInAnyRole(user, item.permissions)
        );
    });
    return isValid ? true : false;
};

/**
 * Method to check if any field be disabled or not based on user's role,
 * for system admin field will be enabled, for other roles validation will happen based on ids passed to method
 * @param user current user to disable field for
 * @param rolesToDisableFor the list of role IDs to check if the user is in
 * @returns boolean value to determine whether field should be disabled or not for current user,
 * by default will return true if user is not admin and not roles passed for disabling
 */
export const shouldDisableField = (rolesToDisableFor?: string[]): boolean => {
    const user = getUser();
    return userIsInAnyRole(user, [userRoles.systemAdmin.key])
        ? false
        : rolesToDisableFor && rolesToDisableFor.length
        ? userIsInAnyRole(user, rolesToDisableFor)
        : true;
};
