import {
    BuildingFeatures,
    InteriorFeatures,
    LotFeatures,
} from '../../../../../../shared/models/listing/amenityFeatures';
import { ListingAmenity } from '../../../../../../shared/models/listing/amenityModels';
import { getCall, postCallRaw, putCallRaw } from '../../../../../../utils/api/apiWrapper';

/** Returns all amenities in the system */
export function getAmenities(): Promise<ListingAmenity[]> {
    return getCall<ListingAmenity[]>('amenities');
}

export function saveListingAmenities(
    amenities: ListingAmenity[],
    listingId: string,
): Promise<Response> {
    return postCallRaw<{ amenities: ListingAmenity[] }>(
        `listing/${listingId}/amenities`,
        {
            amenities,
        },
    );
}

export function saveListingFeatureData(
    id: string,
    request: LotFeatures | InteriorFeatures | BuildingFeatures,
    featureName: string,
): Promise<Response> {
    return putCallRaw<LotFeatures | InteriorFeatures | BuildingFeatures>(
        `listing/${id}/${featureName}`,
        request,
    );
}
