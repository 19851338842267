import { FC } from 'react';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import {
    ImageDeleteData,
    ImageDetail,
} from '../../../../../../shared/models/images/sharedModel';
import { useAppDispatch } from '../../../../../../app/hooks';
import { minLogoDim } from '../../../developmentDetailsConstants';
import {
    deleteDevelopmentLogo,
    uploadDevelopmentLogo,
} from '../../../developmentDetailsSlice';
import { getImageIdFromUrl } from '../../../../../../shared/functions/photo/utils';
import PhotoTemplate from '../../../../../../shared/component/photo/PhotoTemplate';

const DevelopmentLogo: FC<DrawerContainerProps> = ({ editFormData }) => {
    const { data, flags } = editFormData;
    const { logo } = flags;
    const dispatch = useAppDispatch();
    const handleUpload = (imageData: ImageDetail) => {
        dispatch(uploadDevelopmentLogo(imageData));
    };

    const deleteHandler = () => {
        const deleteData: ImageDeleteData = {
            ids: getImageIdFromUrl(data?.logo[0].imageUrl || ''),
            imageType: imageTypes.buildingLogos,
            shouldValidate: false,
        };
        dispatch(deleteDevelopmentLogo(deleteData));
    };

    const validateImageSize = (width: number, height: number): boolean => {
        return width >= minLogoDim.minWidth && height >= minLogoDim.minHeight;
    };

    return (
        <PhotoTemplate
            entityId={data?.id || ''}
            entityType={entityTypes.building.value}
            imageType={imageTypes.buildingLogos}
            photoUrl={data?.logo[0]?.imageUrl || ''}
            isUploading={logo.isUploading}
            infoText={
                'Submit image in jpg/jpeg/png format, minimum 70px x 70px, at 72 dpi resolution.'
            }
            handleUpload={handleUpload}
            deleteHandler={deleteHandler}
            validateImageSize={validateImageSize}
            imageCrop={false}
        />
    );
};
export default DevelopmentLogo;
