import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ApproveAccessCodeRequest } from './approveAccessCodeModel';
import { approveAccessCode } from './approveAccessCodeApi';

const ApproveAccessCode: FC = () => {
    const search = Object.fromEntries(new URLSearchParams(useLocation().search));
    useEffect(() => {
        const request: ApproveAccessCodeRequest = {
            email: search['email'],
            accessCode: decodeURIComponent(search['accessCode']),
            listingTitle: decodeURIComponent(search['listingTitle']),
            userName: decodeURIComponent(search['userName']),
            siteUrl: search['siteUrl'],
            agentEmail: search['agentEmail'],
        };
        (async () => {
            const response = await approveAccessCode(request);
            if (response.status === 200) setStatus('Approved');
            else setStatus('Not approved, please check with the agent');
        })();
    }, [search]);
    const [status, setStatus] = useState<string>('');
    return <>{status}</>;
};
export default ApproveAccessCode;
