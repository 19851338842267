import { sortBy } from 'lodash';
import { ListingAgent } from '../../../../listingModels';
import { dealTypes } from '../../../../listingConstants';
import { AgentSearchResult } from '../../../../../../../shared/models/agent/agentModel';

export function filterListingAgents(
    listingAgents: ListingAgent[],
    sideTypeId: number,
): ListingAgent[] {
    const filteredAgents = listingAgents.filter((listingAgent: ListingAgent) => {
        return listingAgent.sideTypeId === sideTypeId;
    });
    return sortBy(filteredAgents, 'displayOrder');
}

export function addAgentData(
    agent: AgentSearchResult,
    sideTypeId: number,
    displayOrder: number,
    listingAgents: ListingAgent[],
): ListingAgent {
    const checkSelectedSale = listingAgents.find((a) => {
        return a.agentId === agent.agentId;
    })?.selectSale;

    return {
        agentId: agent.agentId,
        sideTypeId: sideTypeId,
        selectSale: checkSelectedSale
            ? checkSelectedSale
            : agent.defaultSelectSale ?? false,
        name: agent.firstName + ' ' + agent.lastName,
        office: agent.office,
        split: 0,
        displayOrder: displayOrder,
    };
}

export function findTotalSplit(
    listingAgents: ListingAgent[],
    currentAgentId: string,
    split: number,
): number {
    return listingAgents.length > 1
        ? listingAgents
              .filter((data: ListingAgent) => {
                  return data.agentId !== currentAgentId;
              })
              .map((s) => s.split)
              .reduce(function (prev, next) {
                  return prev + next;
              }) + Number(split)
        : Number(split);
}

export function checkDealType(dealType: string, option: number): boolean {
    if (
        (dealType === dealTypes.listSide || dealType === dealTypes.bothSides) &&
        option === 1
    ) {
        return true;
    } else if (
        (dealType === dealTypes.saleSide || dealType === dealTypes.bothSides) &&
        option === 2
    ) {
        return true;
    } else {
        return false;
    }
}
