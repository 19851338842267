import { FunctionComponent, useState } from 'react';
import { Chip } from '@material-ui/core';
import { CustomChipData, ExpertiseSection } from '../../../../agentModels';
import { getSelectedIds } from '../../../../agentUtils';
import './customChips.scss';

interface CustomChipsProps {
    chipsData: CustomChipData[];
    formData: ExpertiseSection;
    setFormData: React.Dispatch<React.SetStateAction<ExpertiseSection>>;
    propertyName: string;
    setFormDataToPersist: (updatedFormData: ExpertiseSection) => void;
}

const CustomChips: FunctionComponent<CustomChipsProps> = ({
    chipsData,
    formData,
    setFormData,
    propertyName,
    setFormDataToPersist,
}) => {
    const [currentChipData, setCurrentChipData] = useState(chipsData);

    const actionHandler = (id: string, toSelect: boolean) => {
        const updatedData = currentChipData.map((chip) => {
            return chip.id === id
                ? { ...chip, selected: toSelect ? true : false }
                : { ...chip };
        });

        setCurrentChipData(updatedData);
        setFormData({
            ...formData,
            [propertyName]: { ids: getSelectedIds(updatedData) },
        });
        setFormDataToPersist({
            ...formData,
            [propertyName]: { ids: getSelectedIds(updatedData) },
        });
    };
    return (
        <div className="chipsContainer">
            {currentChipData.length > 0 ? (
                <>
                    {currentChipData.map((chip: CustomChipData) => {
                        return chip.selected ? (
                            <Chip
                                key={chip.id}
                                label={chip.name}
                                className="selected"
                                onDelete={() => actionHandler(chip.id, false)}
                            />
                        ) : (
                            <Chip
                                key={chip.id}
                                label={chip.name}
                                onClick={() => actionHandler(chip.id, true)}
                            />
                        );
                    })}
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default CustomChips;
