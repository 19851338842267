import { ListingAdCopy } from '../../../../../../shared/models/listing/adCopyModel';
import { DataFlowResponse } from '../../../../../../utils/api/apiModels';
import {
    getCall,
    postCall,
    putCall,
    putCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import { AdCopyDetails, LookupData } from './adCopyModels';

export function getAdCopies(listingId: string): Promise<AdCopyDetails> {
    return getCall<AdCopyDetails>(`listing/${listingId}/adcopy`);
}

export function getLookups(): Promise<LookupData> {
    return getCall<LookupData>('adcopy/lookups');
}

export function createAdCopy(request: ListingAdCopy): Promise<ListingAdCopy> {
    return postCall<ListingAdCopy, ListingAdCopy>('adcopy', request);
}

export function updateAdCopy(request: ListingAdCopy): Promise<ListingAdCopy> {
    return putCall<ListingAdCopy, ListingAdCopy>(`adcopy/${request.id}`, request);
}

export function updateSuppressMlsImport(listingId: string): Promise<Response> {
    return putCallRaw<null>(`adcopy/${listingId}/togglesuppressimport`, null);
}

export function saveListingTitle(id: string, title: string): Promise<DataFlowResponse> {
    return putCall<{ title: string }, DataFlowResponse>(`listing/${id}/title`, {
        title: title,
    });
}
