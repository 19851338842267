import { AgentReorderRequest } from '../../../../../../shared/models/agent/agentModel';
import { deleteCall, putCall, putCallRaw } from '../../../../../../utils/api/apiWrapper';
import { SalesTeamMember } from '../../../salesTeamModels';

export function addAgentToSalesTeamApi(
    teamId: string,
    agentId: string,
): Promise<SalesTeamMember> {
    return putCall<string, SalesTeamMember>(`SalesTeam/${teamId}/agent/add`, agentId);
}

export function updateAgentDisplayOrderFromSalesTeamApi(
    teamId: string,
    updatedAgents: AgentReorderRequest[],
): Promise<Response> {
    return putCallRaw<AgentReorderRequest[]>(
        `SalesTeam/${teamId}/reordermembers`,
        updatedAgents,
    );
}

export function deleteAgentFromSalesTeamApi(
    id: string,
    agentId: string,
): Promise<Response> {
    return deleteCall(`SalesTeam/${id}/agent/${agentId}/delete`);
}
