import { initializeAdCopy } from '../../../shared/utils/listing/adCopyUtil';
import { ListingDraftRequest } from './listingDraftModels';

export const listingDraftRequest: ListingDraftRequest = {
    title: '',
    mlsNumber: '',
    address1: '',
    address2: '',
    city: '',
    regionId: 0,
    state: '',
    zip: '',
    neighborhood: '',
    showAddress: false,
    listingType: 'Sale',
    agentIds: [],
    officeId: '',
    salesRegionId: 0,
    currentPrice: 0,
    priceUponRequest: false,
    realEstateTax: 0,
    taxesAreMonthly: false,
    commonCharges: 0,
    maintenance: 0,
    flipTax: '',
    flipTaxAmount: 0,
    rentalPeriods: [],
    propertyTypeId: 0,
    exteriorSize: 0,
    rooms: 0,
    bedrooms: 0,
    fullBaths: 0,
    halfBaths: 0,
    interiorSize: 0,
    lotSize: 0,
    lotSizeTypeId: 1,
    yearBuilt: 0,
    id: '',
    listingDate: '',
    expirationDate: '',
    domain: '',
    customTabs: [],
    headline: '',
    userEmail: '',
};

export const initialadCopies = [
    initializeAdCopy(1, 1, 'Internet Copy*'),
    initializeAdCopy(2, 1, '155 Total'),
    // initializeAdCopy(8, 1, 'OWNER QUOTE'),
    // initializeAdCopy(9, 1, 'WEB FLYER COPY'),
];

export const listingFeatures = [];
