import React, { FunctionComponent, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import './index.scss';
import { useFormData } from '../../../app/hooks';
import { OfficeSearch } from '../../../shared/models/office/officeModel';
import { offices as officesSlice } from '../officeListSlice';
import { useAppSelector } from '../../../app/hooks';

import FormRow from '../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../shared/component/formFields/DropDownSearch';

export const initialOfficeSearch: OfficeSearch = {
    sortColumn: 'Name',
    sortDirection: 'asc',
    status: 1,
    officeName: '',
    currentPage: 1,
    ids: [],
    itemsPerPage: 20,
};

interface SearchProps {
    onSearch(data: OfficeSearch, isClear?: boolean): void;
}

const OfficesSearch: FunctionComponent<SearchProps> = ({ onSearch }) => {
    const { tableAction } = useAppSelector(officesSlice);
    const { formData, setFormData, handleTextChange, handleDropDownSearch } =
        useFormData(tableAction);

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    const clear = () => {
        setFormData(initialOfficeSearch);
        onSearch(initialOfficeSearch, true);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') onSearch(formData);
    };

    return (
        <Grid
            container
            alignItems="flex-end"
            className="officeSearchHolder"
            spacing={2}
            md={12}
        >
            <Grid item md={4} xs={12}>
                <FormRow
                    label="Office Name"
                    labelMd={12}
                    controlMd={12}
                    labelSm={12}
                    controlSm={12}
                    control={
                        <TextField
                            value={formData.officeName || ''}
                            name="officeName"
                            InputProps={{
                                disableUnderline: true,
                                className: 'customInput',
                            }}
                            onChange={handleTextChange}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                    }
                    controlClass="wide-field"
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <FormRow
                    label="Office Status"
                    labelMd={12}
                    controlMd={12}
                    labelSm={12}
                    controlSm={12}
                    controlClass="wide-field"
                    control={
                        <DropDownSearch
                            id={formData.status}
                            items={[
                                { id: '0', name: 'All' },
                                { id: '1', name: 'Active' },
                                { id: '2', name: 'Inactive' },
                            ]}
                            name="status"
                            handleDropDownChange={handleDropDownSearch}
                        />
                    }
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <button
                    className="customButton buttons"
                    onClick={() => {
                        onSearch(formData);
                    }}
                >
                    Search
                </button>
                <button className="customButton buttons space" onClick={clear}>
                    Clear
                </button>
            </Grid>
        </Grid>
    );
};
export default OfficesSearch;
