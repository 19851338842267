import { Grid } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import './video.scss';
import { AgentDescription, DrawerContainerProps } from '../../../agentModels';
import {
    agentDashboardDetails,
    setCurrentAgentEditFormData,
    updateAgentDetails,
    updateVideoStatus,
    uploadAgentVideo,
} from '../../../agentSlice';
import {
    VideoFieldsProps,
    VideoModel,
} from '../../../../../../shared/models/video/videoModels';
import { videoTypes } from '../../../../../../shared/constants/video/videoConstants';
import {
    getVideos,
    deleteVideo,
    downloadVideo,
} from '../../../../../../shared/slices/video/videoSlice';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import loDash from 'lodash';
import { agentDashboardMapping, editFormComponent } from '../../../agentConstants';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import VideoTemplate from '../../../../../../shared/component/video/VideoTemplate';
import { getAbortController } from '../../../../../../utils/urlUtils';

const Video: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { agentDetails, statuses } = useAppSelector(agentDashboardDetails);
    const { agentId, profileId } = agentDetails.descriptionDetails as AgentDescription;
    const videos = agentDetails.agentVideo as VideoModel[];
    const [formData, setFormData] = useState<VideoModel>(videos[0]);
    const [downloadedVideo, setDownloadedVideo] = useState('noVideo');
    const showVideoFields: VideoFieldsProps = {
        showTitle: true,
        showDescription: true,
    };

    const uploadVideo = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files) {
            const file = event.target.files[0];
            dispatch(updateVideoStatus({ videoTypeId: videoTypes.agentVideo.key }));
            dispatch(
                uploadAgentVideo(
                    agentId,
                    profileId,
                    file,
                    getAbortController(formData.isCancelled, index),
                ),
            );
        }
    };

    const cancelVideo = (video: VideoModel, index: number) => {
        const abortController = getAbortController(formData.isCancelled, index);
        abortController.abort();
        deleteIt(video);
    };

    const downloadIt = (video: VideoModel, from: string) => {
        dispatch(downloadVideo(video, from));
    };

    const deleteIt = (video: VideoModel) => {
        dispatch(deleteVideo(video));
    };

    const updateFields = (currentVideo: VideoModel) => {
        const video = { ...currentVideo, displayOrder: 1 };
        dispatch(
            setCurrentAgentEditFormData({
                formData: video,
                agentEditFormName: editFormComponent.video,
            }),
        );
        setFormData(video);
    };

    const updateInterval = (remainingInterval: number, fileInputName: string) => {
        if (fileInputName === 'agentVideo')
            dispatch(
                updateAgentDetails({
                    name: agentDashboardMapping.video.stateName,
                    data: [{ ...videos[0], reuploadInterval: remainingInterval }],
                }),
            );
    };

    useEffect(() => {
        setFormData(videos[0]);
    }, [videos]);

    useEffect(() => {
        const interval = setInterval(() => {
            const objIndex = loDash.findIndex(videos, function (o) {
                return o.status === 2;
            });
            if (objIndex !== -1) dispatch(getVideos(profileId, entityTypes.profile.key));
            else clearInterval(interval);
        }, 20000);
        return () => clearInterval(interval);
    }, [dispatch, profileId, videos]);

    useEffect(() => {
        if (statuses.downloadedVideo.from !== '') {
            setDownloadedVideo(statuses.downloadedVideo.from);
        }
    }, [statuses.downloadedVideo]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} className="video-container">
                <ComponentWithLoader
                    showLoader={statuses.downloadedVideo.from === downloadedVideo}
                    showOverlay={true}
                    styleClass="progress-bar"
                />
                <VideoTemplate
                    fileInputName="agentVideo"
                    videoSectionTitle="Agent Video"
                    data={formData}
                    videoFields={showVideoFields}
                    reuploadInterval={videos[0].reuploadInterval}
                    index={videoTypes.agentVideo.index}
                    uploadAction={uploadVideo}
                    cancelAction={cancelVideo}
                    downloadAction={downloadIt}
                    deleteAction={deleteIt}
                    updateInterval={updateInterval}
                    updateFields={updateFields}
                />
            </Grid>
        </Grid>
    );
};
export default Video;
