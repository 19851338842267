import React, { FunctionComponent } from 'react';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { Grid } from '@material-ui/core';

interface SearchFieldWrapperProps {
    label: string;
    control: JSX.Element;
    suffix?: string | null;
}

const SearchFieldWrapper: FunctionComponent<SearchFieldWrapperProps> = (props) => {
    return (
        <Grid item container alignItems="center" className="search-field" md={12}>
            <FormRow
                {...props}
                label={props.label.toUpperCase()}
                labelMd={12}
                controlMd={12}
                showLabel={props.label && props.label.length > 0 ? true : false}
            />
        </Grid>
    );
};

export default SearchFieldWrapper;
