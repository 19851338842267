import {
    deleteCall,
    getCall,
    getCallRaw,
    postCall,
    postCallRaw,
    putCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import {
    DomainAvailabilityResponse,
    DomainModel,
    PurchaseRequest,
    PurchaseResponse,
    ReleaseRequest,
    RenewDomainRequest,
    ShowCaseModel,
    PrivateSiteUser,
    RedeemDomainRequest,
} from './showcaseWebsiteModels';

export function checkDomainAvailability(
    domain: string,
): Promise<DomainAvailabilityResponse> {
    return getCall<DomainAvailabilityResponse>(
        `domain/checkAvailability?domain=${domain}`,
    );
}

export function getDomainDetails(domainId: string): Promise<DomainModel> {
    return getCall<DomainModel>(`domain/getDomainInfo?id=${domainId}`);
}

export function checkEligibility(listingId: string): Promise<Response> {
    return getCallRaw(`domain/${listingId}/eligibility`);
}

export function registerDomain(data: PurchaseRequest): Promise<PurchaseResponse> {
    return postCall<PurchaseRequest, PurchaseResponse>(`purchase`, data);
}

export function releaseDomain(release: ReleaseRequest): Promise<Response> {
    return putCallRaw<ReleaseRequest>(`domain/release`, release);
}

export function renewDomain(renewRequest: RenewDomainRequest): Promise<Response> {
    return postCallRaw<RenewDomainRequest>(`domain/renew`, renewRequest);
}

export function redeemDomain(redeemRequest: RedeemDomainRequest): Promise<Response> {
    return postCallRaw<RedeemDomainRequest>(`domain/redeem`, redeemRequest);
}

export function getShowcaseSiteDetails(listingId: string): Promise<ShowCaseModel> {
    return getCall<ShowCaseModel>(`showCaseSite/${listingId}`);
}

export function createShowcaseSite(data: ShowCaseModel): Promise<ShowCaseModel> {
    return postCall<ShowCaseModel, ShowCaseModel>(`showCaseSite`, data);
}

export function updateShowcaseSiteDetails(data: ShowCaseModel): Promise<Response> {
    return putCallRaw(`showCaseSite`, data);
}

export function deleteShowcaseSite(listingId: string): Promise<Response> {
    return deleteCall(`showCaseSite/${listingId}`);
}

export function getUsersDetails(showcaseSiteId: string): Promise<PrivateSiteUser[]> {
    return getCall<PrivateSiteUser[]>(`PrivateListing/${showcaseSiteId}/users`);
}

export function postUserDetails(userData: PrivateSiteUser): Promise<PrivateSiteUser> {
    return postCall<PrivateSiteUser, PrivateSiteUser>(`PrivateListing/user`, userData);
}

export function putUserDetails(userData: PrivateSiteUser): Promise<Response> {
    return putCallRaw<PrivateSiteUser>(`PrivateListing/user`, userData);
}
