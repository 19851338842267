export const entityTypes = {
    listing: {
        key: 1,
        value: 'listing',
    },
    building: {
        key: 2,
        value: 'building',
    },
    office: {
        key: 3,
        value: 'office',
    },
    profile: {
        key: 4,
        value: 'associate',
    },
    salesTeam: {
        key: 5,
        value: 'salesteam',
    },
};
