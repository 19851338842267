import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import {
    getShowingsApi,
    putShowingApi,
    postShowingApi,
    deleteShowingApi,
} from '../../../../../../shared/api/openHouseShowings/openHouseShowingsApi';
import {
    Showing,
    ShowingsState,
} from '../../../../../../shared/models/openHouseShowings/openHouseShowingsModels';
import Logger from '../../../../../../utils/logging/logger';
import { updateEventsState } from '../../../upcomingEvents/upcomingEventsSlice';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import { ListingDetailEvents } from '../../../../../../shared/models/loader/loaderModels';

const showingsState: ShowingsState = {
    showings: [],
    showingActiveId: '',
};

export const showingsSlice = createSlice({
    name: 'showings',
    initialState: showingsState,
    reducers: {
        updateShowings: (state, action: PayloadAction<Showing[]>) => {
            return {
                ...state,
                showings: [...action.payload],
            };
        },
        updateShowingActiveId: (state, action: PayloadAction<string>) => {
            state.showingActiveId = action.payload;
        },
        resetShowingsState: (state) => {
            return {
                ...state,
                ...showingsState,
            };
        },
    },
});

export const getShowings =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const showingsResponse = await getShowingsApi(id);
            if (showingsResponse.length > 0) {
                dispatch(updateShowings(showingsResponse));
            }
        } catch (e) {
            Logger.error('Error fetching showings');
            dispatch(setError('Error fetching showings'));
        }
    };

export const postOrPutShowing =
    (showingData: Showing): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(ListingDetailEvents.LISTING_SAVE));
            if (showingData) {
                let updatedData = showingData;
                const editingItem = (showingData.id?.length || 0) > 0;

                if (editingItem) {
                    const updatedCall = await putShowingApi(showingData);
                    if (updatedCall.status !== 200) {
                        throw Error('Unable to update showing');
                    }
                } else {
                    updatedData = await postShowingApi(showingData);
                    if (!updatedData.id) {
                        throw Error('Unable to add showing');
                    }
                }

                const currentShowings = getState().listing.showings.showings;
                const updatedShowings = editingItem
                    ? currentShowings.map((item) =>
                          item.id === updatedData.id ? { ...item, ...updatedData } : item,
                      )
                    : [updatedData, ...currentShowings];
                dispatch(updateShowings(updatedShowings));
                dispatch(
                    updateEventsState({
                        property: 'showings',
                        value: updatedShowings,
                    }),
                );
                dispatch(setSuccess(`Showing saved successfully`));
            }
        } catch (e) {
            dispatch(setError(`Failed to save showing`));
        } finally {
            dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_SAVE));
        }
    };

export const deleteShowing =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(ListingDetailEvents.LISTING_DELETE));
            if (id?.length > 0) {
                const response = await deleteShowingApi(id);
                if (response.status === 204) {
                    const currentShowings = getState().listing.showings.showings;
                    const updatedShowings = currentShowings.filter(
                        (showing: Showing) => showing.id !== id,
                    );
                    dispatch(updateShowings(updatedShowings));
                    dispatch(
                        updateEventsState({
                            property: 'showings',
                            value: updatedShowings,
                        }),
                    );
                    dispatch(setSuccess(`Showing deleted successfully`));
                } else {
                    throw Error('Failed to delete showing');
                }
            }
        } catch (e) {
            dispatch(setError(`Failed To Delete Showing`));
        } finally {
            dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_DELETE));
        }
    };

export const { updateShowings, updateShowingActiveId, resetShowingsState } =
    showingsSlice.actions;

export const showings = (state: RootState): ShowingsState => state.listing.showings;

export default showingsSlice.reducer;
