import { Grid, TextField } from '@material-ui/core';
import { FunctionComponent } from 'react';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { currencyFormat } from '../../../../../utils/urlUtils';
import { DevelopmentDetailsData } from '../../../developmentDetails/developmentDetailsModel';
import { CreateDevelopmentRequest } from '../createDevelopmentModel';

interface DetailsProps {
    formData: CreateDevelopmentRequest | DevelopmentDetailsData;
    handleNumberChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isPhone?: boolean,
    ) => void;
    handleCurrencyChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean,
    ) => void;
    setFormDataToPersistInState?: () => void;
}

const Details: FunctionComponent<DetailsProps> = ({
    formData,
    handleNumberChange,
    handleCurrencyChange,
    setFormDataToPersistInState,
}) => {
    return (
        <>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    id="totalUnits"
                    label="Total Units*"
                    control={
                        <TextField
                            value={formData.totalUnits || ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="totalUnits"
                            inputProps={{ maxLength: 4 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    id="totalDollarValue"
                    label="Development Value*"
                    control={
                        <TextField
                            value={currencyFormat(formData.totalDollarValue)}
                            onChange={handleCurrencyChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                                startAdornment: '$',
                            }}
                            name="totalDollarValue"
                            inputProps={{ maxLength: 11 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    id="minimumPricePerUnit"
                    label="Minimum Offering Price Per Unit*"
                    control={
                        <TextField
                            value={currencyFormat(formData.minimumPricePerUnit)}
                            onChange={handleCurrencyChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                                startAdornment: '$',
                            }}
                            name="minimumPricePerUnit"
                            inputProps={{ maxLength: 11 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    label="Max Financing"
                    control={
                        <TextField
                            value={formData.maxFinancing || ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                                endAdornment: '%',
                            }}
                            name="maxFinancing"
                            inputProps={{ maxLength: 3 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
        </>
    );
};

export default Details;
