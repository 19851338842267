import { Button, Grid } from '@material-ui/core';
import type { Identifier, XYCoord } from 'dnd-core';
import { FunctionComponent } from 'react';
import { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { AgentDNDItem } from '../../../../models/agent/agentModel';

export interface GridItemProps {
    index: number;
    item: AgentDNDItem;
    moveItem: (
        dragIndex: number,
        hoverIndex: number,
        isDropped: boolean,
        entity: string,
    ) => void;
    deleteItem: (agentId: string, entity: string) => void;
    entity: string;
}

interface DragItem {
    index: number;
    id: string;
}

const GridItem: FunctionComponent<GridItemProps> = ({
    index,
    item,
    moveItem,
    deleteItem,
    entity,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const id = item.agentId;
    const [, drag] = useDrag({
        type: entity,
        item: () => {
            return { id, index };
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: entity,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex, false, entity);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }, // Remove from here
        drop(item: DragItem) {
            const dragIndex = item.index;
            const hoverIndex = index;
            moveItem(dragIndex, hoverIndex, true, entity);
        },
    });
    drag(drop(ref));
    return (
        <Grid
            item
            container
            xs={12}
            md={12}
            data-handler-id={handlerId}
            key={item.agentId}
            ref={ref}
            alignItems="center"
            spacing={2}
            className="dnd-grid-item-container"
        >
            <Grid item xs={12} md={1} className="dnd-grid-item">
                {index + 1}
            </Grid>
            <Grid item xs={12} md={4} className="dnd-grid-item">
                {item.name}
            </Grid>
            <Grid item xs={12} md={6} className="dnd-grid-item">
                {item.office}
            </Grid>
            <Grid item xs={12} md={1}>
                <Button
                    onClick={() => deleteItem(item.agentId, entity)}
                    className="delete-button"
                >
                    Delete
                </Button>
            </Grid>
        </Grid>
    );
};

export default GridItem;
