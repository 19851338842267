import {
    deleteCall,
    getCall,
    postCall,
    postCallRaw,
    putCallRaw,
} from '../../../utils/api/apiWrapper';
import {
    AdvancedSearchField,
    AdvancedSearchRequest,
    SavedSearchDetails,
} from './advancedSearchModels';
import {
    PaginationResponse,
    SecuredSearchRequest,
} from '../../../shared/models/pagination/paginationModels';
import { ListingsResponse } from '../listingListModels';

/**
 * Gets all advanced search fields in the system
 * @param criteria Criteria with user info so we get fields the user
 * has permissions to
 * @returns List of fields to render and filter on
 */
export function getAdvancedSearchFields(
    criteria: SecuredSearchRequest,
): Promise<AdvancedSearchField[]> {
    return postCall<SecuredSearchRequest, AdvancedSearchField[]>(
        'listing/advancedsearch/fields',
        criteria,
    );
}

/**
 * Performs advanced search for listings
 * @param request Request containing search criteria to filter on
 * @returns Paginated list of listing search results
 */
export function performAdvancedSearch(
    request: AdvancedSearchRequest,
): Promise<PaginationResponse<ListingsResponse>> {
    const url = 'listing/advancedsearch';
    return postCall<AdvancedSearchRequest, PaginationResponse<ListingsResponse>>(
        url,
        request,
    );
}

/**
 * Performs search and returns file response for downloading
 * @param request Request containing search criteria to filter on
 */
export function exportAdvancedSearchResults(
    request: AdvancedSearchRequest,
): Promise<Response> {
    const url = `listing/advancedsearch/export`;
    return postCallRaw<AdvancedSearchRequest>(url, request);
}

/**
 * Gets all saved searches for a user
 * @param userId The ID of the user to retrieve saved searches for
 * @returns List of the user's saved searches
 */
export function getSavedSearches(userId: string): Promise<SavedSearchDetails[]> {
    const url = `savedsearches/${userId}`;
    return getCall<SavedSearchDetails[]>(url);
}

/**
 * Creates or updates a user's saved search
 * @param request Reqeust containing search criteria and other parameters
 * to save
 */
export function saveSearch(request: SavedSearchDetails): Promise<SavedSearchDetails> {
    const url = 'savedsearches';
    return postCall<SavedSearchDetails, SavedSearchDetails>(url, request);
}

/**
 * Deletes a user's saved search
 * @param id ID of the saved search to delete
 */
export function deleteSearch(id: string): Promise<Response> {
    const url = `savedsearches/${id}`;
    return deleteCall(url);
}

/**
 * Updates the default saved search for a user
 * @param id ID of the saved search to set as the default
 * @param userId ID of the user the default search is being set for
 */
export function setDefaultSearch(id: string, userId: string): Promise<Response> {
    const url = `savedsearches/${id}/setdefault`;
    const request = { userId: userId };
    return putCallRaw(url, request);
}
