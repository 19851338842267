import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { DrawerContainerProps } from '../../../listingModels';
import { TextField, FormControlLabel, Checkbox, Grid, GridSize } from '@material-ui/core';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import { currencyFormat } from '../../../../../../utils/urlUtils';
import { useFormData, useAppDispatch } from '../../../../../../app/hooks';
import { fetchListingPrices } from '../../../listingUtils';
import { editFormComponent, listingTypeDetails } from '../../../listingConstants';
import { setCurrentFormData } from '../../../listingSlice';
import RentalTerms from './listingType/RentalTerms';
import SalePrices from './listingType/SalePrices';
import './index.scss';
import { updateListingCommission } from '../../../../../../shared/slices/listingCommission/listingCommissionSlice';
import { getUser } from '../../../../../../shared/auth/authService';
import { userIsInAnyRole } from '../../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../../shared/constants/user/roles';

const Prices: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const initialPriceData = fetchListingPrices(editFormData.data);
    const dispatch = useAppDispatch();
    const user = useMemo(() => getUser(), []);
    const canAccess = useMemo(
        () => userIsInAnyRole(user, [userRoles.staff.key, userRoles.systemAdmin.key]),
        [user],
    );
    const {
        formData,
        setFormData,
        handleTextChange,
        handleCurrencyChange,
        handleCheckboxChange,
        handleRadioButtonChange,
    } = useFormData(initialPriceData);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentFormData({
                formData,
                listingEditFormName: editFormComponent.prices,
            }),
        );
    };

    const renderFormRow = (
        label: string,
        control: JSX.Element,
        labelSize: GridSize = 2,
        controlSize: GridSize = 4,
    ) => {
        return (
            <FormRow
                label={label}
                control={control}
                prefix=""
                suffix=""
                controlClass="formControlRoot"
                labelXs={null}
                labelSm={labelSize}
                labelMd={labelSize}
                controlXs={null}
                controlSm={controlSize}
                controlMd={controlSize}
            />
        );
    };

    const renderRow = (
        label: string,
        property: string,
        disabled?: boolean,
        endAdornment?: string,
        refIndex?: number,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => {
        return renderFormRow(
            label,
            <TextField
                value={currencyFormat(
                    formData[property as keyof typeof formData] as number,
                )}
                onChange={handleCurrencyChange}
                InputProps={{
                    disableUnderline: true,
                    'aria-label': `${property}`,
                    className: `inputBaseRoot ${disabled ? 'inputDisabled' : ''}`,
                    startAdornment: '$',
                    endAdornment: `${endAdornment ? endAdornment : ''}`,
                }}
                inputProps={{ maxLength: 11 }}
                onBlur={setFormDataToPersist}
                name={property}
                disabled={disabled ? disabled : false}
                inputRef={(e) => {
                    if (e && refIndex !== undefined && refIndex >= 0)
                        inputRefs.current[refIndex] = e;
                }}
            />,
            labelSize,
            controlSize,
        );
    };

    const handleDisplayBuyerCommissionCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData({ ...formData, [name]: !checked });
    };

    const renderCheckBox = (
        label: string,
        property: string,
        disabled?: boolean,
        customField?: string,
        customFieldValue?: boolean,
    ) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        inputProps={{
                            'aria-label': `${label}`,
                        }}
                        checked={
                            property === 'displayBuyerCommissionOnWebsite'
                                ? !(formData[
                                      property as keyof typeof formData
                                  ] as boolean)
                                : customField && customField?.length > 0
                                ? customFieldValue
                                : formData[property as keyof typeof formData]
                                ? (formData[property as keyof typeof formData] as boolean)
                                : false
                        }
                        classes={{
                            root: disabled ? 'disable-check-box' : 'enable-check-box',
                        }}
                        disabled={disabled ? true : false}
                        onChange={
                            property === 'displayBuyerCommissionOnWebsite'
                                ? handleDisplayBuyerCommissionCheckbox
                                : handleCheckboxChange
                        }
                        name={property}
                        onBlur={setFormDataToPersist}
                    />
                }
                label={<div className="checkboxLabel">{label}</div>}
                labelPlacement="end"
            />
        );
    };

    const renderField = (
        label: string,
        property: string,
        disabled?: boolean,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => {
        return renderFormRow(
            label,
            <TextField
                value={formData[property as keyof typeof formData] || ''}
                onChange={handleTextChange}
                InputProps={{
                    disableUnderline: true,
                    'aria-label': `${property}`,
                    className: `inputBaseRoot ${disabled ? 'inputDisabled' : ''}`,
                    endAdornment: '%',
                }}
                inputProps={{ maxLength: 5 }}
                onBlur={setFormDataToPersist}
                name={property}
                disabled={disabled ? disabled : false}
            />,
            labelSize,
            controlSize,
        );
    };

    useEffect(() => {
        const { name, refIndex } = editFormData.editForm?.field || {};
        if (name && name !== '' && (refIndex || 0) >= 0) {
            (inputRefs.current[refIndex || 0] as HTMLInputElement | HTMLSelectElement) &&
                (
                    inputRefs.current[refIndex || 0] as
                        | HTMLInputElement
                        | HTMLSelectElement
                ).scrollIntoView({
                    behavior: 'smooth',
                });
            // (inputRefs.current[refIndex || 0] as HTMLInputElement | HTMLSelectElement) &&
            //     (
            //         inputRefs.current[refIndex || 0] as
            //             | HTMLInputElement
            //             | HTMLSelectElement
            //     ).focus();
        }
    }, [editFormData.editForm?.field]);

    return (
        <div className="pricesContainer">
            <Grid item container md={12} className="info-text">
                * Indicates Required Fields
            </Grid>
            {editFormData.data.listingType === listingTypeDetails.rental && (
                <RentalTerms
                    data={editFormData.data}
                    canAccess={canAccess}
                    renderCheckBox={renderCheckBox}
                    renderFormRow={renderFormRow}
                />
            )}
            {editFormData.data.listingType === listingTypeDetails.sale && (
                <SalePrices
                    renderRow={renderRow}
                    renderCheckBox={renderCheckBox}
                    renderField={renderField}
                    formData={formData}
                    setFormData={setFormData}
                    setFormDataToPersist={setFormDataToPersist}
                    handleCurrencyChange={handleCurrencyChange}
                    handleRadioButtonChange={handleRadioButtonChange}
                    data={editFormData.data}
                    inputRefs={inputRefs}
                    canAccess={canAccess}
                />
            )}
        </div>
    );
};

export default Prices;
