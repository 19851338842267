import {
    EmailCampaignData,
    MarketingData,
    SocialCampaignData,
} from '../../shared/models/marketing/marketingModels';
import {
    getCall,
    postCall,
    putCall,
    putCallRaw,
    deleteCall,
    postFormData,
    putFormData,
} from '../../utils/api/apiWrapper';
import {
    Analytics,
    CmrHeader,
    SortingOrder,
    Showing,
    AdvertisementData,
    TitleSummaryData,
    OpenHouseData,
    Notes,
    ListingImage,
    ImageVideoModel,
    WebsiteData,
} from './cmrModels';
export function fetchCmrSortingByListingId(listingId: string): Promise<SortingOrder[]> {
    return getCall<SortingOrder[]>(`clientmarketing/${listingId}/sections`);
}

export function fetchPanelDetails(
    listingId: string,
    name: string,
): Promise<
    | Analytics
    | AdvertisementData[]
    | Showing[]
    | OpenHouseData[]
    | TitleSummaryData[]
    | EmailCampaignData[]
    | SocialCampaignData[]
    | Notes
    | MarketingData[]
> {
    return getCall<
        | Analytics
        | AdvertisementData[]
        | Showing[]
        | OpenHouseData[]
        | TitleSummaryData[]
        | EmailCampaignData[]
        | SocialCampaignData[]
        | Notes
        | MarketingData[]
    >(`clientmarketing/${listingId}/${name}`);
}

export function fetchHeaderDetails(listingId: string): Promise<CmrHeader> {
    return getCall<CmrHeader>(`clientmarketing/${listingId}/headerSectionDetails`);
}

export function toggleDisplaySection(id: string): Promise<Response> {
    return putCallRaw(`clientmarketing/${id}/toggledisplaysection`, {});
}

export function updateSortingOrder(requestData: SortingOrder[]): Promise<Response> {
    return putCallRaw(`clientmarketing/sortorder`, requestData);
}

export function updateNotes(id: string, remark: string): Promise<Response> {
    const reqData = {
        remark,
    };
    return putCallRaw(`clientmarketing/${id}/introduction`, reqData);
}

export function deleteDBData(url: string): Promise<Response> {
    return deleteCall(url);
}

export function addShowingsData(showing: Showing): Promise<Showing> {
    return postCall<Showing, Showing>('Showing', showing);
}

export function addOpenHouseData(openHouse: OpenHouseData): Promise<OpenHouseData> {
    return postCall<OpenHouseData, OpenHouseData>('openhouse', openHouse);
}

export function addAdv(
    listingId: string,
    requestData: FormData,
): Promise<AdvertisementData> {
    return postFormData<AdvertisementData>(
        `listing/${listingId}/advertisement`,
        requestData,
    );
}

export function editAdv(id: string, requestData: FormData): Promise<AdvertisementData> {
    return putFormData<AdvertisementData>(`advertisement/${id}`, requestData);
}

export function updateShowing(requestData: Showing): Promise<Response> {
    return putCallRaw(`showing/${requestData.id}`, requestData);
}

export function updateOpenHouses(requestData: OpenHouseData): Promise<Response> {
    return putCallRaw(`openhouse/${requestData.id}`, requestData);
}

export function getListingImages(listingId: string): Promise<ListingImage[]> {
    return getCall<ListingImage[]>(`clientmarketing/${listingId}/listingImages`);
}

export function updateSelectedImage(data: ImageVideoModel): Promise<ImageVideoModel> {
    return putCall(`clientmarketingmedia/${data.listingNumber}/select`, data);
}

export function addWebSiteData(requestData: FormData): Promise<WebsiteData> {
    return postFormData<WebsiteData>(`ViewsOnWeb`, requestData);
}

export function updateWebSiteData(
    id: string,
    requestData: FormData,
): Promise<WebsiteData> {
    return putFormData<WebsiteData>(`ViewsOnWeb/${id}`, requestData);
}
