import React, { FunctionComponent, useEffect } from 'react';
import {
    ListingsResponse,
    TableColumn,
    SoldTableListInterface,
} from '../../../listingListModels';
import '../../../listingList.scss';
import {
    Checkbox,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleInfo, faXmark } from '@fortawesome/free-solid-svg-icons';
import { bedsAndBath, currencyFormat, displayData } from '../../../../../utils/urlUtils';
import { useInView } from 'react-intersection-observer';
import { mlsImport } from '../../../listingListConstants';
import {
    getSide,
    validateListingAccess,
    withPriceAdded,
    getListingTabName,
} from '../../../utils';
import { Link } from 'react-router-dom';
import { LookupInfo } from '../../../../../shared/models/lookups/lookupsModels';

import MenuAction from '../../MenuAction';
import RadioButtonToggle from '../../../../../shared/component/formFields/RadioButtonToggle';
import {
    fetchUserPermissions,
    userIsInRole,
} from '../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../shared/constants/user/roles';
import {
    agentDashboardDetails,
    fetchAgentDescriptionDetails,
} from '../../../../agents/agentDashboard/agentSlice';
import { AgentDescription } from '../../../../agents/agentDashboard/agentModels';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { getData } from '../../../../../utils/storageService';
import { AuthorizationResponse } from '../../../../../shared/auth/authModels';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';

const useStyles = makeStyles({
    root: {
        width: '100%',
        color: '#666',
        fontFamily: 'MercuryTextG4Roman',
        fontSize: '12px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '0px 10px',
    },
    checkbox: {
        '&$checked': {
            color: '#002349',
        },
        '&&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checked: {},
    label: {
        color: '#002349',
        fontFamily: 'BenTonSansMedium',
        fontSize: '12px',
    },
    customTableContainer: {
        overflowX: 'initial',
    },
});

const Sold: FunctionComponent<SoldTableListInterface> = ({
    isLoading,
    from,
    tableColumn,
    listings,
    order,
    orderBy,
    handleRequestSort,
    handleNavigation,
    handleSoldCheckboxChange,
    handleSoldRadioButtonChange,
    price,
    loadMore,
    designVaultRedirectFn,
    user,
    salesRegionId,
    agentId,
}) => {
    const {
        agentDetails: { descriptionDetails },
    } = useAppSelector(agentDashboardDetails);
    const dispatch = useAppDispatch();
    const userDetails = JSON.parse(getData('zeus-user')) as AuthorizationResponse;
    const storeAgentId = userDetails.agentIds?.length ? userDetails.agentIds[0] : '';
    const classes = useStyles();
    const { ref } = useInView({
        triggerOnce: true,
        onChange: (inView: boolean) => {
            if (inView) {
                loadMore();
            }
        },
    });

    useEffect(() => {
        if (!(descriptionDetails as AgentDescription).agentId.length) {
            const id = agentId?.length ? agentId[0] : storeAgentId;
            dispatch(fetchAgentDescriptionDetails(id));
        }
    }, [descriptionDetails]);

    const agentsIds: string[] = agentId
        ? agentId
        : fetchUserPermissions(user, 'agentIds');

    const isUserAgent =
        from === 'selectSales' ? false : userIsInRole(user, userRoles.agent.key);

    const getTooltipData = (listing: ListingsResponse) => {
        return mlsImport.map((data, index: number) => (
            <div key={index} className="listing-list-tooptip">
                {listing[data.key as keyof typeof listing] ? (
                    <FontAwesomeIcon icon={faCheck} className="list-icon green" />
                ) : (
                    <FontAwesomeIcon icon={faXmark} className="list-icon red" />
                )}
                {data.value}
            </div>
        ));
    };

    /** form the radio button content for sold */
    const priceRadio = (details: ListingsResponse): LookupInfo[] => {
        //houston and santa fe show only the asking price //20,9
        const data = withPriceAdded(details, price);
        if (salesRegionId.indexOf(20) !== -1 || salesRegionId.indexOf(9) !== -1) {
            return data.filter((data) => data.id === 'askingPrice');
        }
        return data;
    };

    /** from the selection value for radio button */
    const radioSelection = (details: ListingsResponse) => {
        //houston and santa fe show only the asking price //20,9
        const data = details.displayOfferPriceForSelectSold ? 'soldPrice' : 'askingPrice';
        if (salesRegionId.indexOf(20) !== -1 || salesRegionId.indexOf(9) !== -1) {
            return 'askingPrice';
        }
        return data;
    };

    return (
        <TableContainer
            component={Paper}
            className="appsTableContainer"
            classes={{ root: from !== 'selectSales' ? classes.customTableContainer : '' }}
        >
            <Table stickyHeader aria-label="apps table">
                <TableHead>
                    <TableRow>
                        {tableColumn?.map((data: TableColumn, dataIndex: number) =>
                            data.doSort ? (
                                <TableCell
                                    align="center"
                                    key={dataIndex}
                                    className="heading cells "
                                >
                                    <TableSortLabel
                                        active={orderBy === data.key}
                                        direction={orderBy === data.key ? order : 'asc'}
                                        onClick={() => handleRequestSort(data.key)}
                                    >
                                        {data.name}
                                    </TableSortLabel>
                                </TableCell>
                            ) : (
                                <TableCell
                                    align="center"
                                    key={dataIndex}
                                    className="heading cells"
                                >
                                    {data.name}
                                </TableCell>
                            ),
                        )}

                        <TableCell className="heading icon"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                    {listings?.length ? (
                        listings.map((details: ListingsResponse, index: number) => (
                            <TableRow
                                key={index}
                                ref={index === listings.length - 5 ? ref : null}
                            >
                                <TableCell
                                    className={'cells content forAll firstCell'}
                                    component="th"
                                    scope="row"
                                >
                                    <Link
                                        to={`/eng/listing/${details.listingId}`}
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Listing clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleNavigation(details.listingId);
                                        }}
                                    >
                                        <div className="listing-first-column-holder sold">
                                            {from !== 'selectSales' ? (
                                                <div
                                                    className={
                                                        details?.listingSearchImageResult &&
                                                        !details?.listingSearchImageResult
                                                            .isLandscape
                                                            ? 'portrait list-image'
                                                            : 'list-image'
                                                    }
                                                >
                                                    <img
                                                        alt="listing"
                                                        src={
                                                            details?.listingSearchImageResult &&
                                                            details
                                                                .listingSearchImageResult
                                                                .imageUrl
                                                                ? details
                                                                      .listingSearchImageResult
                                                                      .imageUrl
                                                                : '/img/s-grey.png'
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="first-column-content">
                                                <div className="others">
                                                    Valid:{' '}
                                                    {details.isValid ? (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="green"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faXmark}
                                                            className="red"
                                                        />
                                                    )}{' '}
                                                    <span className="listingStatusTooltip">
                                                        <Tooltip
                                                            title={getTooltipData(
                                                                details,
                                                            )}
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <span className="icon">
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    size="lg"
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                                <div className="others">
                                                    Beds/Bath:{' '}
                                                    <span>
                                                        {bedsAndBath(
                                                            details.bedrooms,
                                                            details.fullBaths,
                                                            details.halfBaths,
                                                        )}
                                                    </span>
                                                </div>

                                                <div className="others">
                                                    MLS Number:{' '}
                                                    <span>{details.mlsNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'Publish',
                                        }}
                                        disableRipple={true}
                                        checked={details.publish}
                                        disabled={from !== 'selectSales'}
                                        name="publish"
                                        classes={{
                                            root:
                                                from !== 'selectSales'
                                                    ? 'disabledClass'
                                                    : 'checkBoxRoot',
                                            checked: '',
                                        }}
                                        style={{
                                            transform: 'scale(0.8)',
                                        }}
                                        id="publish"
                                        onChange={(e) => {
                                            googleAnalyticsAction(
                                                'Check Box',
                                                'Check',
                                                `Listing Results - List View: Listing publish ${
                                                    e.target.checked
                                                        ? 'checked'
                                                        : 'unchecked'
                                                }, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleSoldCheckboxChange(
                                                e,
                                                details,
                                                from || '',
                                            );
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'Select Sale',
                                        }}
                                        disableRipple={true}
                                        checked={details.isSelectSale}
                                        name="isSelectSale"
                                        classes={{
                                            root: 'checkBoxRoot',
                                            checked: '',
                                        }}
                                        style={{
                                            transform: 'scale(0.8)',
                                        }}
                                        id="selectSale"
                                        onChange={(e) => {
                                            googleAnalyticsAction(
                                                'Check Box',
                                                'Check',
                                                `Listing Results - List View: Listing select sale ${
                                                    e.target.checked
                                                        ? 'checked'
                                                        : 'unchecked'
                                                }, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleSoldCheckboxChange(
                                                e,
                                                details,
                                                from || '',
                                            );
                                        }}
                                    />
                                </TableCell>

                                <TableCell
                                    align="center"
                                    className="cells content forAll radio"
                                >
                                    {details.businessPurposeTypeId ===
                                    1 /* Last Asking or Sold Price */ ? (
                                        <RadioButtonToggle
                                            id={radioSelection(details)}
                                            name="displayOfferPriceForSelectSold"
                                            items={priceRadio(details)}
                                            handleRadioButtonChange={(e) => {
                                                googleAnalyticsAction(
                                                    'Radio',
                                                    'Select',
                                                    `Listing Results - List View: ${
                                                        e.target.value === 'askingPrice'
                                                            ? 'Last asking price'
                                                            : 'Sold price'
                                                    } selected, Id:${
                                                        details.listingNumber
                                                    }, Tab: ${getListingTabName(from)}`,
                                                );
                                                handleSoldRadioButtonChange(
                                                    e,
                                                    details,
                                                    from || '',
                                                );
                                            }}
                                            formClass={{ label: classes.label }}
                                            radioClass={{
                                                root: classes.checkbox,
                                            }}
                                            disableAll={
                                                !details.isSelectSale ? 'yes' : ''
                                            }
                                        />
                                    ) : (
                                        currencyFormat(details?.soldPrice || 0)
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className="cells content forAll radio"
                                >
                                    {/* Address or title */}
                                    <RadioButtonToggle
                                        id={
                                            details.useAddressAsTitle
                                                ? 'address'
                                                : 'title'
                                        }
                                        name="useAddressAsTitle"
                                        items={[
                                            {
                                                id: 'address',
                                                name: details.streetAddress,
                                            },
                                            { id: 'title', name: details.title },
                                        ]}
                                        handleRadioButtonChange={(e) => {
                                            googleAnalyticsAction(
                                                'Radio',
                                                'Select',
                                                `Listing Results - List View: ${
                                                    e.target.value === 'address'
                                                        ? 'Address'
                                                        : 'Title'
                                                } selected, Id:${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleSoldRadioButtonChange(
                                                e,
                                                details,
                                                from || '',
                                            );
                                        }}
                                        formClass={{ label: classes.label }}
                                        radioClass={{
                                            root: classes.checkbox,
                                        }}
                                        disableAll={!details.isSelectSale ? 'yes' : ''}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    {/* show on map */}
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'Show on Map',
                                        }}
                                        disableRipple={true}
                                        checked={details.showMap}
                                        name="showMap"
                                        classes={{
                                            root: 'checkBoxRoot',
                                            checked: '',
                                        }}
                                        style={{
                                            transform: 'scale(0.8)',
                                        }}
                                        id="showMap"
                                        className={
                                            !details.isSelectSale ? 'disabledClass' : ''
                                        }
                                        disabled={!details.isSelectSale ? true : false}
                                        onChange={(e) => {
                                            googleAnalyticsAction(
                                                'Check Box',
                                                'Check',
                                                `Listing Results - List View: Listing show on map ${
                                                    e.target.checked
                                                        ? 'checked'
                                                        : 'unchecked'
                                                }, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleSoldCheckboxChange(
                                                e,
                                                details,
                                                from || '',
                                            );
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    <Link
                                        to={`/eng/listing/${details.listingId}`}
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Listing clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleNavigation(details.listingId);
                                        }}
                                    >
                                        {details?.closedDate
                                            ? displayData(details.closedDate, true)
                                            : '-'}
                                    </Link>
                                </TableCell>

                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    {/* Agent side */}
                                    <Link
                                        to={`/eng/listing/${details.listingId}`}
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Listing clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleNavigation(details.listingId);
                                        }}
                                    >
                                        {getSide(details, agentsIds)}
                                    </Link>
                                </TableCell>
                                {from !== 'selectSales' ? (
                                    <TableCell
                                        align="center"
                                        className="cells content forAll"
                                    >
                                        <Link
                                            to={`/eng/listing/${details.listingId}`}
                                            onClick={() => {
                                                googleAnalyticsAction(
                                                    'Navigation',
                                                    'Click',
                                                    `Listing Results - List View: Listing clicked, Id: ${
                                                        details.listingNumber
                                                    }, Tab: ${getListingTabName(from)}`,
                                                );
                                                handleNavigation(details.listingId);
                                            }}
                                        >
                                            {' '}
                                            {details.businessPurposeTypeId === 1
                                                ? 'Sale'
                                                : 'Rental'}
                                        </Link>
                                    </TableCell>
                                ) : (
                                    ''
                                )}

                                <TableCell
                                    align="center"
                                    className="cells content forAll"
                                >
                                    <Link
                                        to={`/eng/listing/${details.listingId}`}
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Listing clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleNavigation(details.listingId);
                                        }}
                                    >
                                        {' '}
                                        {details.propertyType}
                                    </Link>
                                </TableCell>
                                {validateListingAccess(
                                    [
                                        ...details.listSideAgents,
                                        ...details.saleSideAgents,
                                    ],
                                    isUserAgent,
                                    agentsIds,
                                ) && designVaultRedirectFn ? (
                                    <TableCell className="cells content forAll">
                                        <MenuAction
                                            details={details}
                                            designVaultRedirectFn={designVaultRedirectFn}
                                            from={from}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className="cells content forAll"></TableCell>
                                )}
                            </TableRow>
                        ))
                    ) : !isLoading ? (
                        <TableRow>
                            <TableCell colSpan={6} className="noResults">
                                No records found
                            </TableCell>
                        </TableRow>
                    ) : (
                        ''
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default Sold;
