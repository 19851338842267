import React, { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import OpenHouseTemplate from '../../../../../../shared/component/listing/openHouseTemplate/OpenHouseTemplate';
import { OpenHouse as OpenHouseModel } from '../../../../../../shared/models/openHouseShowings/openHouseShowingsModels';
import { sortByDate } from '../../../../../../utils/urlUtils';
import { DrawerContainerProps } from '../../../listingModels';
import {
    deleteOpenHouse,
    getOpenHouses,
    openHouse,
    postOrPutOpenHouse,
    updateOpenHouseActiveId,
} from './openHouseSlice';
import './openHouse.scss';

const OpenHouse: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const initialState: OpenHouseModel = {
        listingId: editFormData.data.id,
        listingNumber: editFormData.data.id,
        startDateTime: '',
        endDateTime: '',
        section: '',
        showingAgent: '',
        byAppointmentOnly: false,
        doNotShowTime: false,
        brokerOnly: false,
        isVirtual: false,
        virtualUrl: '',
        notes: '',
        attendees: 0,
    };
    const { openHouses, openHouseActiveId } = useAppSelector(openHouse);
    const sortedOpenHouses = sortByDate(openHouses as OpenHouseModel[], 'startDateTime');
    const dispatch = useAppDispatch();

    const deleteIt = (id: string) => {
        dispatch(deleteOpenHouse(id));
    };

    useEffect(() => {
        if (!openHouses.length) {
            dispatch(getOpenHouses(editFormData.data.id));
        }
    }, [editFormData.data.id, dispatch, openHouses]);

    const editClickHandler = (currentEditId: string) => {
        if (openHouseActiveId === currentEditId) {
            dispatch(updateOpenHouseActiveId(''));
        } else if (currentEditId) {
            dispatch(updateOpenHouseActiveId(currentEditId));
        }
    };

    const handleSaveClick = (openHouseData: OpenHouseModel) => {
        dispatch(postOrPutOpenHouse(openHouseData));
    };

    return (
        <div className="openHouse-listing">
            <OpenHouseTemplate
                useGa={false}
                sortedOpenHouses={sortedOpenHouses}
                activeEditId={openHouseActiveId}
                deleteIt={deleteIt}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
            />
        </div>
    );
};

export default OpenHouse;
