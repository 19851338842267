import { FunctionComponent, useEffect } from 'react';
import { DrawerContainerProps } from '../../../userDetailsModels';
import {
    lookups as lookupsSlice,
    getDashRolesLookups,
} from '../../../../../../shared/slices/lookups/lookupsSlice';
import { useAppSelector, useFormData, useAppDispatch } from '../../../../../../app/hooks';
import {
    CommonLookups,
    DashRolesLookups,
} from '../../../../../../shared/models/lookups/lookupsModels';
import { DashRoleForm, UserDashRole } from '../../../userDetailsModels';
import { Grid } from '@material-ui/core';
import {
    saveDashRole,
    getUserDashRoles,
    deleteDashRole,
} from '../../../userDetailsSlice';
import { findNameFromLookups } from '../../../../../../utils/urlUtils';
import { setError } from '../../../../../../shared/slices/messaging/messagingSlice';
import './index.scss';

import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';

const initialFormData: DashRoleForm = {
    officeId: '',
    roleId: '',
    userId: '',
};

const DashRoles: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const { common, dashRolesLook } = useAppSelector(lookupsSlice);
    const dispatch = useAppDispatch();
    const { id, userDashRoles } = editFormData.details;
    const { offices } = common as CommonLookups;
    const { dashRoleType } = dashRolesLook as DashRolesLookups;
    const { formData, setFormData, handleDropDownSearch } = useFormData(initialFormData);
    useEffect(() => {
        if (!dashRoleType.length) {
            dispatch(getDashRolesLookups());
        }
    }, [dashRolesLook, dashRoleType.length, dispatch]);
    useEffect(() => {
        if (!userDashRoles.length) {
            dispatch(getUserDashRoles(editFormData.details.id));
        }
    }, [dispatch, userDashRoles.length, editFormData.details.id]);

    const isDuplicateRecord = () => {
        return userDashRoles.find(
            (role) =>
                role.officeId === formData.officeId &&
                role.roleId.toString() === formData.roleId,
        )
            ? true
            : false;
    };

    const saveHandler = () => {
        if (isDuplicateRecord()) {
            dispatch(setError('Office cannot have same role again'));
        } else if (formData.officeId.length && formData.roleId.length) {
            const data = { ...formData, userId: id };
            dispatch(saveDashRole(data));
            setFormData(initialFormData);
        }
    };
    const deleteRole = (id: string) => {
        dispatch(deleteDashRole(id));
    };
    return (
        <Grid container className="dashroles-container">
            <Grid item container className="content" spacing={2} alignItems="center">
                <FormRow
                    label="OFFICE"
                    control={
                        <DropDownSearch
                            id={formData.officeId}
                            items={offices}
                            name="officeId"
                            handleDropDownChange={handleDropDownSearch}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass={`formControlRoot`}
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={4}
                />
                <FormRow
                    label="TITLE CODE"
                    control={
                        <DropDownSearch
                            id={formData.roleId}
                            items={dashRoleType}
                            name="roleId"
                            handleDropDownChange={handleDropDownSearch}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass={`formControlRoot`}
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={4}
                />
                <Grid item container>
                    <button className="button customButton" onClick={saveHandler}>
                        Save
                    </button>
                </Grid>
            </Grid>
            {userDashRoles.length ? (
                <Grid item container className="content" alignItems="center" spacing={2}>
                    <Grid item container md={12} className="formControlLabel">
                        <Grid item md={4} sm={4}>
                            Office
                        </Grid>
                        <Grid item md={4} sm={4}>
                            Role
                        </Grid>
                    </Grid>
                    {userDashRoles.map((role: UserDashRole) => (
                        <Grid item container key={role.id} alignItems="center">
                            <Grid item sm={4} md={4} className="formControlData">
                                {findNameFromLookups(role.officeId, offices)}
                            </Grid>
                            <Grid item sm={4} md={4} className="formControlData">
                                {findNameFromLookups(
                                    role.roleId.toString(),
                                    dashRoleType,
                                )}
                            </Grid>
                            <Grid item sm={4} md={4}>
                                <button
                                    className="button customButton"
                                    onClick={() => deleteRole(role.id)}
                                >
                                    Delete
                                </button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
};

export default DashRoles;
