export const typesInitialState = {
    buildings: {
        name: '',
        alias: '',
        maxPhotos: '',
        allowPortrait: true,
        allowLandscape: true,
        photosLoaded: 0,
        requiredSize: {
            minLongSide: 0,
            minShortSide: 0,
        },
        featureType: '',
    },
    buildingFloorplans: {
        name: '',
        alias: '',
        maxPhotos: '',
        allowPortrait: true,
        allowLandscape: true,
        photosLoaded: 0,
        requiredSize: {
            minLongSide: 0,
            minShortSide: 0,
        },
        featureType: '',
    },
};
