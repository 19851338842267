import { FunctionComponent, useEffect, useState, Fragment, useCallback } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { Input, makeStyles, Tooltip, withStyles, Zoom } from '@material-ui/core';
import { Showing } from '../../../../../features/cmr/cmrModels';
import { useFormData } from '../../../../../app/hooks';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';
import { useWindowDimensions } from '../../../Window';
import { getData, storeData } from '../../../../../utils/storageService';
import { datePickerFormat } from '../../../../../utils/urlUtils';

interface ShowingFormProps {
    useGa: boolean;
    showingData?: Showing;
    formEdit: boolean;
    initialState: Showing;
    editClickHandler(id: string | undefined): void;
    handleSaveClick(showingData: Showing): void;
    expanded?: boolean;
    isCmr?: boolean;
}

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#002349',
        color: '#fff',
        width: 300,
        textAlign: 'center',
        border: '1px solid #dadde9',
        fontSize: '14px',
        fontFamily: 'BentonSansMedium',
    },
    arrow: {
        color: '#002349',
    },
}))(Tooltip);

const useStyles = makeStyles({
    closed: {
        maxHeight: 0,
        display: 'none',
    },
    slider: {
        display: 'block',
        overflowY: 'hidden',
        maxHeight: '500px',
        transitionProperty: 'all',
        transitionDuration: '5s',
        msTransitionTimingFunction: 'cubic-bezier(0,1,0.5,1)',
        marginTop: '10px',
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

const FormTemplate: FunctionComponent<ShowingFormProps> = (props) => {
    const {
        formData,
        setFormData,
        handleTextChange,
        handleDateChange,
        removeSpaces,
        handleNumberChange,
    } = useFormData(props.showingData || props.initialState);
    const [active, setActive] = useState(true);

    const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
    const [moreInfo, setMoreInfo] = useState<boolean>(false);
    const classes = useStyles();
    const { width } = useWindowDimensions();

    const expandDetailSection = () => {
        setMoreInfo(!moreInfo);
    };

    const onToolOpen = useCallback(() => {
        const isShown = getData('showingsCount');
        if (!isShown && !tooltipIsOpen) {
            setTooltipIsOpen(true);
            storeData('showingsCount', 'visited');
        }
    }, [tooltipIsOpen]);

    const onToolClose = () => {
        setTooltipIsOpen(false);
        if (moreInfo) {
            expandDetailSection();
        }
    };

    useEffect(() => {
        if (!props.formEdit) {
            //since same component is used in the mobile and desktop on different design
            //to prevent them from overlapping below logic is implemented.
            if (width > 1023) {
                //triggers only on desktop
                onToolOpen();
            } else if (props.expanded) {
                //triggers only on device less than 1023 when expanded
                onToolOpen();
            }
        }
    }, [props, width, onToolOpen]);

    useEffect(() => {
        //to preload showings count to 1 when the date is selected.
        if (!props.formEdit && formData.showingDate !== '' && formData.count === 0) {
            formData.count = 1;
        }
        setFormData(formData);
        setActive(
            formData.showingDate === null ||
                !formData.showingDate.length ||
                formData.count === 0 ||
                !formData.count.toString().length,
        );
    }, [setFormData, formData, props.formEdit]);

    const save = () => {
        props.handleSaveClick(formData);
        setFormData(props.initialState);
        if (formData.id) {
            props.editClickHandler(formData.id);
        }
        if (props.formEdit && props.useGa)
            googleAnalyticsAction('Edit', 'Click', 'Showings Edit Save');
        if (!props.formEdit && props.useGa)
            googleAnalyticsAction('Add', 'Click', 'Showings Add');
    };

    return (
        <div>
            {props.formEdit ? <div className="overlayEditForm" /> : ''}
            <div className={props.formEdit ? '' : 'form-container'}>
                <div
                    className={props.formEdit ? 'showingsEditContainer' : 'add-showing'}
                    id={'showingsEdit' + props.showingData?.id}
                >
                    <div className="formFields">
                        <span className="actionHolder formControlRoot">
                            <DatePicker
                                className="datePickerCommon cmrDate"
                                placeholder="Date"
                                name="showingDate"
                                value={
                                    formData.showingDate !== ''
                                        ? moment(formData.showingDate)
                                        : undefined
                                }
                                format={datePickerFormat()}
                                onChange={(date: Moment | null) => {
                                    handleDateChange('showingDate', date);
                                }}
                                showToday={false}
                            />
                        </span>
                        <span className="actionHolder">
                            <Input
                                className="cmrInput"
                                placeholder="Buyer"
                                name="buyer"
                                value={formData.buyer}
                                inputProps={{
                                    'aria-label': 'Buyer',
                                    maxLength: 500,
                                }}
                                onChange={handleTextChange}
                                onBlur={removeSpaces}
                                disableUnderline={props.isCmr ? false : true}
                            />
                        </span>
                        <span className="actionHolder">
                            <Input
                                className="cmrInput"
                                placeholder="Buyer Agent"
                                name="buyerAgents"
                                value={formData.buyerAgents}
                                inputProps={{
                                    'aria-label': 'Buyer Agents',
                                    maxLength: 500,
                                }}
                                onChange={handleTextChange}
                                onBlur={removeSpaces}
                                disableUnderline={props.isCmr ? false : true}
                            />
                        </span>
                        <span className="actionHolder">
                            <Input
                                className="cmrInput"
                                placeholder="Notes"
                                name="notes"
                                value={formData.notes}
                                inputProps={{ 'aria-label': 'Notes', maxLength: 500 }}
                                onChange={handleTextChange}
                                onBlur={removeSpaces}
                                disableUnderline={props.isCmr ? false : true}
                            />
                        </span>
                        <span className="actionHolder">
                            <Input
                                className="cmrInput"
                                placeholder="Feedback"
                                name="feedback"
                                value={formData.feedback}
                                inputProps={{ 'aria-label': 'Feedback', maxLength: 4000 }}
                                onChange={handleTextChange}
                                onBlur={removeSpaces}
                                disableUnderline={props.isCmr ? false : true}
                            />
                        </span>
                        <span className="actionHolder">
                            <CustomTooltip
                                placement="right-start"
                                arrow={true}
                                interactive={true}
                                TransitionComponent={Zoom}
                                open={tooltipIsOpen}
                                onOpen={onToolOpen}
                                onClose={onToolClose}
                                disableFocusListener={true}
                                title={
                                    <Fragment>
                                        <div className="titleOne">
                                            {'New Feature: Number of Showings'}
                                        </div>
                                        <div className="titleTwo">
                                            <span
                                                className={classes.link}
                                                onClick={expandDetailSection}
                                            >
                                                {'Click to read more'}
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                !moreInfo
                                                    ? classes.closed
                                                    : classes.slider
                                            }
                                        >
                                            <p>
                                                Group multiple showings in a day together.
                                            </p>
                                            <p>
                                                Enter the total number of showings done
                                                for the day.
                                            </p>
                                            <p>
                                                Will show on client report on one line,
                                                e.g. 5 Showings held
                                            </p>
                                        </div>
                                    </Fragment>
                                }
                            >
                                <Input
                                    value={
                                        (formData.count || 0) > 0 ? formData.count : ''
                                    }
                                    name="count"
                                    className="cmrInput"
                                    placeholder="Number of Showings"
                                    inputProps={{ 'aria-label': 'Number of Showings' }}
                                    onChange={handleNumberChange}
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </CustomTooltip>
                        </span>
                    </div>
                    {!props.formEdit ? (
                        <div className="addButtonHolder">
                            <button
                                className={!active ? 'unActive' : 'active'}
                                onClick={save}
                                disabled={active}
                            >
                                Add
                            </button>
                        </div>
                    ) : (
                        <div className="buttonGroup">
                            <button
                                className="actionButton cancel"
                                onClick={() => props.editClickHandler(formData.id)}
                            >
                                Cancel
                            </button>
                            <button
                                className={
                                    !active
                                        ? 'actionButton saveActive'
                                        : 'actionButton saveInActive'
                                }
                                onClick={save}
                                disabled={active}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormTemplate;
