import { AppThunk } from '../../../app/store';
import { agentDashboardMapping } from '../../../features/agents/agentDashboard/agentConstants';
import {
    updateAgentDetails,
    updateDownloadedAgentVideoStatus,
    updateFlag,
} from '../../../features/agents/agentDashboard/agentSlice';
import {
    addVideosToListing,
    updateDownloadedListingVideoStatus,
    updateVideos,
} from '../../../features/listings/listing/drawer/screens/videos/videoSlice';
import Logger from '../../../utils/logging/logger';
import { getVideosFromApi, updateVideoApi } from '../../api/video/videoApi';
import { entityTypes } from '../../constants/entityTypes';
import { setError, setSuccess } from '../messaging/messagingSlice';
import {
    deleteVideoApi,
    downloadVideoApi,
    downloadVideoUrlsApi,
} from '../../api/video/brightcove/brightcoveApi';
import { VideoModel } from '../../models/video/videoModels';
import {
    updateDownloadedSalesTeamVideoStatus,
    updateSalesTeamVideo,
} from '../../../features/salesTeam/salesTeamDetail/salesTeamSlice';
import { initializeVideoData, validateVideoId } from '../../functions/video/video';
import download from 'downloadjs';
import { videoTypes } from '../../constants/video/videoConstants';
import { updateMainDevelopmentData } from '../../../features/developments/developmentDetails/developmentDetailsSlice';
import { updateShowcaseWebsiteData } from '../../../features/listings/listing/drawer/screens/showcaseWebsite/showcaseWebsiteSlice';

export const getVideos =
    (
        entityId: string,
        entityTypeId: number,
        updateHeroUrl?: boolean,
        videoTypeId?: number,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await getVideosFromApi(entityId, entityTypeId, videoTypeId);
            if (response.length > 0) {
                if (entityTypeId === entityTypes.listing.key) {
                    dispatch(addVideosToListing(response));
                    const heroVideo = response.find((v) => v.videoTypeId === 3);
                    if (heroVideo && heroVideo.url && updateHeroUrl) {
                        const showcaseData =
                            getState().listing.showcaseWebsite.showCaseData;
                        dispatch(
                            updateShowcaseWebsiteData({
                                property: 'showCaseData',
                                value: {
                                    ...showcaseData,
                                    showcaseVideoUrl: heroVideo.url,
                                },
                            }),
                        );
                    }
                } else if (entityTypeId === entityTypes.profile.key) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.video.stateName,
                            data: response.map((r) => {
                                return { ...r, isCancelled: false };
                            }),
                        }),
                    );
                } else if (entityTypeId === entityTypes.salesTeam.key) {
                    dispatch(
                        updateSalesTeamVideo(
                            response.map((r) => {
                                return { ...r, isCancelled: false };
                            }),
                        ),
                    );
                } else {
                    dispatch(
                        updateMainDevelopmentData({
                            videoDetail: { ...response[0], isCancelled: false },
                        }),
                    );
                }
            }
        } catch (err) {
            Logger.error(`Error Fetching Video Details: ${JSON.stringify(err)}`);
            dispatch(setError('Error while fetching Video Details'));
        }
    };

export const updateVideo =
    (updatedVideo: VideoModel, entityTypeId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateFlag({
                    property: 'isVideoUploading',
                    value: true,
                }),
            );
            const videoId = await validateVideoId(updatedVideo);
            const response = await updateVideoApi({ ...updatedVideo, videoId: videoId });
            if (response.status === 204) {
                if (entityTypeId === entityTypes.listing.key) {
                } else if (entityTypeId === entityTypes.profile.key) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.video.stateName,
                            data: [{ ...updatedVideo }],
                        }),
                    );
                } else {
                    dispatch(updateSalesTeamVideo([{ ...updatedVideo }]));
                }
            }
        } catch (err) {
            Logger.error(`Error in updating the video: ${JSON.stringify(err)}`);
            dispatch(setError('Error in updating the video'));
        } finally {
            dispatch(
                updateFlag({
                    property: 'isVideoUploading',
                    value: false,
                }),
            );
            dispatch(setSuccess('Video details is updated'));
        }
    };

export const deleteVideo =
    (video: VideoModel, updateHeroUrl?: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateFlag({
                    property: 'isVideoUploading',
                    value: true,
                }),
            );
            const videoId = await validateVideoId(video);
            const response = await deleteVideoApi(videoId, video.id);
            if (response.status === 204) {
                if (video.entityTypeId === entityTypes.listing.key) {
                    dispatch(
                        updateVideos({
                            videoId: video.videoId,
                            videoTypeId: video.videoTypeId,
                            reuploadInterval: 180000,
                            isCancelled: true,
                        }),
                    );
                    if (video.videoTypeId === 3 && updateHeroUrl) {
                        const showcaseData =
                            getState().listing.showcaseWebsite.showCaseData;
                        dispatch(
                            updateShowcaseWebsiteData({
                                property: 'showCaseData',
                                value: { ...showcaseData, showcaseVideoUrl: '' },
                            }),
                        );
                    }
                } else if (video.entityTypeId === entityTypes.profile.key) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.video.stateName,
                            data: [
                                initializeVideoData(
                                    entityTypes.profile.key,
                                    videoTypes.agentVideo.key,
                                    1,
                                    180000,
                                    true,
                                ),
                            ],
                        }),
                    );
                } else if (video.entityTypeId === entityTypes.salesTeam.key) {
                    dispatch(
                        updateSalesTeamVideo([
                            initializeVideoData(
                                entityTypes.salesTeam.key,
                                videoTypes.salesTeamVideo.key,
                                1,
                                180000,
                                true,
                            ),
                        ]),
                    );
                } else {
                    dispatch(
                        updateMainDevelopmentData({
                            videoDetail: initializeVideoData(
                                entityTypes.building.key,
                                videoTypes.buildingVideo.key,
                                1,
                                180000,
                                true,
                            ),
                        }),
                    );
                }
                dispatch(setSuccess('Video is deleted'));
            }
        } catch (err) {
            Logger.error(`Error Deleting Video: ${JSON.stringify(err)}`);
            dispatch(setError('Error Deleting Video'));
        } finally {
            dispatch(
                updateFlag({
                    property: 'isVideoUploading',
                    value: false,
                }),
            );
        }
    };

export const downloadVideo =
    (video: VideoModel, inputName: string): AppThunk =>
    async (dispatch) => {
        try {
            const id = await validateVideoId(video);
            const response = await downloadVideoUrlsApi(id);
            if (response.src !== '') {
                const url = response.src.replace('http://', 'https://');
                const videoResponse = await downloadVideoApi(url);
                if (videoResponse.status === 200) {
                    const contentLength = videoResponse.headers.get('content-length');
                    let total = 0;
                    if (contentLength) {
                        total = parseInt(contentLength, 10);
                    }
                    let loaded = 0;
                    const res = new Response(
                        new ReadableStream({
                            async start(controller) {
                                if (videoResponse.body) {
                                    const reader = videoResponse.body.getReader();
                                    for (;;) {
                                        const { done, value } = await reader.read();
                                        if (done) break;
                                        if (value) {
                                            loaded += value.byteLength;
                                            const data = {
                                                progress: Math.floor(
                                                    loaded / (total / 100),
                                                ),
                                                from: inputName,
                                            };
                                            if (
                                                video.entityTypeId ===
                                                entityTypes.listing.key
                                            ) {
                                                dispatch(
                                                    updateDownloadedListingVideoStatus(
                                                        data,
                                                    ),
                                                );
                                            } else if (
                                                video.entityTypeId ===
                                                entityTypes.profile.key
                                            ) {
                                                dispatch(
                                                    updateDownloadedAgentVideoStatus(
                                                        data,
                                                    ),
                                                );
                                            } else {
                                                dispatch(
                                                    updateDownloadedSalesTeamVideoStatus(
                                                        data,
                                                    ),
                                                );
                                            }
                                            controller.enqueue(value);
                                        }
                                    }
                                }

                                controller.close();
                            },
                        }),
                    );
                    let blob = await res.blob();
                    blob = blob.slice(0, blob.size, 'video/mp4');
                    download(blob, inputName);
                }
            }
        } catch {
            Logger.error('Error Fetching Video Details');
            dispatch(setError('Error while fetching Video Details'));
        } finally {
            if (video.entityTypeId === entityTypes.listing.key) {
                dispatch(
                    updateDownloadedListingVideoStatus({
                        progress: 0,
                        from: '',
                    }),
                );
            } else if (video.entityTypeId === entityTypes.profile.key) {
                dispatch(
                    updateDownloadedAgentVideoStatus({
                        progress: 0,
                        from: '',
                    }),
                );
            } else {
                dispatch(
                    updateDownloadedSalesTeamVideoStatus({
                        progress: 0,
                        from: '',
                    }),
                );
            }
        }
    };
