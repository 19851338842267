import { Grid } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { showCaseWebsiteStyle } from '../../style';
import loDash from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import ComponentWithLoader from '../../../../../../../../shared/component/loader/ComponentWithLoader';
import VideoTemplate from '../../../../../../../../shared/component/video/VideoTemplate';
import {
    DownloadedVideoStatus,
    VideoModel,
} from '../../../../../../../../shared/models/video/videoModels';
import {
    getVideoCancellationStatus,
    uploadAction,
} from '../../../../../../../../shared/constants/video/videoConstants';
import {
    updateStatus,
    updateVideos,
    uploadVideo,
    videos as videosSlice,
} from '../../../videos/videoSlice';
import {
    getVideos,
    updateVideo,
    deleteVideo,
    downloadVideo,
} from '../../../../../../../../shared/slices/video/videoSlice';
import { entityTypes } from '../../../../../../../../shared/constants/entityTypes';
import { videoTypes } from '../../../../../../../../shared/constants/video/videoConstants';
import { getAbortController } from '../../../../../../../../utils/urlUtils';

interface VideoProps {
    listingId: string;
    isVideoDownloadData: DownloadedVideoStatus;
}

const VideoSection: FunctionComponent<VideoProps> = ({
    listingId,
    isVideoDownloadData,
}) => {
    const dispatch = useAppDispatch();
    const classes = showCaseWebsiteStyle();
    const { videos } = useAppSelector(videosSlice);
    const [heroVideo, setHeroVideo] = useState<VideoModel>(videos[2]);
    const [otherVideoOne, setOtherVideoOne] = useState<VideoModel>(videos[3]);
    const [otherVideoTwo, setOtherVideoTwo] = useState<VideoModel>(videos[4]);
    const [otherVideoThree, setOtherVideoThree] = useState<VideoModel>(videos[5]);
    const [videoDownloaded, setVideoDownloaded] = useState('noVideo');

    // Video Events

    const uploadIt = async (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const inputEvent = event as ChangeEvent<HTMLInputElement>;
        if (!inputEvent.target.files) return;
        const uploadedFile = inputEvent.target.files[0];
        const name = event.target.name;
        const data = uploadAction(uploadedFile, name, listingId);
        //to remove the data when sending it to upload video
        const { fromName, videoType, ...updatedData } = data;
        await dispatch(
            updateStatus({
                videoType: videoType,
                displayOrder: updatedData.displayOrder,
            }),
        );
        await dispatch(
            uploadVideo(
                updatedData,
                fromName,
                getAbortController(
                    getVideoCancellationStatus(
                        videos,
                        videoType,
                        updatedData.displayOrder,
                    ),
                    index,
                ),
            ),
        );
    };
    const cancelVideo = (video: VideoModel, index: number) => {
        const abortController = getAbortController(
            getVideoCancellationStatus(videos, video.videoTypeId, video.displayOrder),
            index,
        );
        abortController.abort();
        deleteIt(video);
    };
    //to download the video
    const downloadIt = (video: VideoModel, from: string) => {
        dispatch(downloadVideo(video, from));
    };
    //to delete the video
    const deleteIt = (video: VideoModel) => {
        dispatch(deleteVideo(video, true));
    };

    //to update title of the video
    const updateFields = (currentVideo: VideoModel) => {
        dispatch(updateVideo(currentVideo, entityTypes.listing.key));
    };

    //set status on the inital api call
    useEffect(() => {
        const findIfVideoAvailable = (
            data: VideoModel[],
            videoType: number,
            order: number,
        ) => {
            const video = data.filter((data) => {
                if (order === 0) {
                    return data.videoTypeId === videoType;
                } else {
                    return data.videoTypeId === videoType && data.displayOrder === order;
                }
            });
            return video[0];
        };
        setHeroVideo({ ...findIfVideoAvailable(videos, 3, 0) });
        setOtherVideoOne({ ...findIfVideoAvailable(videos, 4, 1) });
        setOtherVideoTwo({ ...findIfVideoAvailable(videos, 4, 2) });
        setOtherVideoThree({ ...findIfVideoAvailable(videos, 4, 3) });
    }, [videos]);

    //after upload continous api call to get the results
    useEffect(() => {
        const interval = setInterval(() => {
            const objIndex = loDash.findIndex(videos, function (o) {
                return o.status === 2;
            });
            if (objIndex !== -1) {
                dispatch(getVideos(listingId, entityTypes.listing.key, true));
            } else {
                clearInterval(interval);
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [videos, dispatch, listingId]);

    //to validate whether the image is being downloaded or not
    useEffect(() => {
        if (isVideoDownloadData.from !== '') {
            setVideoDownloaded(isVideoDownloadData.from);
        }
    }, [isVideoDownloadData]);

    const updateInterval = (remainingInterval: number, fileInputName: string) => {
        dispatch(
            updateVideos({
                videoId: '',
                videoTypeId:
                    fileInputName === 'showcaseVideo'
                        ? videoTypes.showcaseVideo.key
                        : fileInputName === 'additionalVideoOne'
                        ? videoTypes.additionalVideoOne.key
                        : fileInputName === 'additionalVideoTwo'
                        ? videoTypes.additionalVideoTwo.key
                        : videoTypes.additionalVideoThree.key,
                reuploadInterval: remainingInterval,
                isCancelled: true,
            }),
        );
    };

    return (
        <Grid className="showcaseVideoContainer">
            <ComponentWithLoader
                showLoader={isVideoDownloadData.from === videoDownloaded ? true : false}
                showOverlay={true}
                styleClass="loaderClass"
                loadingText="Video is being downloaded"
            />
            <Grid container className={classes.mainVideos}>
                <Grid item xs={12} md={6} className="heroVideo">
                    <VideoTemplate
                        fileInputName="showcaseVideo"
                        tooltipMessage="<div><div>Showcase website hero video will be displayed on showcase websites only.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li><li>Recommended 15 seconds</li><li>No sound</li></ul></div></div>"
                        videoSectionTitle="Hero Video"
                        data={heroVideo}
                        reuploadInterval={videos[2].reuploadInterval}
                        index={videoTypes.showcaseVideo.index}
                        uploadAction={uploadIt}
                        cancelAction={cancelVideo}
                        downloadAction={downloadIt}
                        deleteAction={deleteIt}
                        updateInterval={updateInterval}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.otherVideos}>
                <Grid item xs={12} md={6}>
                    <VideoTemplate
                        fileInputName="additionalVideoOne"
                        tooltipMessage="<div><div>Additional showcase videos will be displayed on showcase websites only.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li></ul></div></div>"
                        videoSectionTitle="Other Videos"
                        data={otherVideoOne}
                        videoFields={{ showTitle: true }}
                        reuploadInterval={videos[3].reuploadInterval}
                        index={videoTypes.additionalVideoOne.index}
                        uploadAction={uploadIt}
                        cancelAction={cancelVideo}
                        downloadAction={downloadIt}
                        deleteAction={deleteIt}
                        updateInterval={updateInterval}
                        updateFields={updateFields}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <VideoTemplate
                        fileInputName="additionalVideoTwo"
                        data={otherVideoTwo}
                        videoFields={{ showTitle: true }}
                        reuploadInterval={videos[4].reuploadInterval}
                        index={videoTypes.additionalVideoTwo.index}
                        uploadAction={uploadIt}
                        cancelAction={cancelVideo}
                        downloadAction={downloadIt}
                        deleteAction={deleteIt}
                        updateInterval={updateInterval}
                        updateFields={updateFields}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <VideoTemplate
                        fileInputName="additionalVideoThree"
                        data={otherVideoThree}
                        videoFields={{ showTitle: true }}
                        reuploadInterval={videos[5].reuploadInterval}
                        index={videoTypes.additionalVideoThree.index}
                        uploadAction={uploadIt}
                        cancelAction={cancelVideo}
                        downloadAction={downloadIt}
                        deleteAction={deleteIt}
                        updateInterval={updateInterval}
                        updateFields={updateFields}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VideoSection;
