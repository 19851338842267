import { postCall, postCallRaw } from '../../../utils/api/apiWrapper';
import { UserSearchRequest, UserSearchResponse } from '../../models/users/userModels';
import { PaginationResponse } from '../../models/pagination/paginationModels';

export function searchUsers(
    request: UserSearchRequest,
): Promise<PaginationResponse<UserSearchResponse>> {
    return postCall<UserSearchRequest, PaginationResponse<UserSearchResponse>>(
        `user/search`,
        request,
    );
}

export function activeUsers(): Promise<Response> {
    return postCallRaw('users/active', {});
}
