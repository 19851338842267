import { Grid } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import { videoTypes } from '../../../../../../shared/constants/video/videoConstants';
import {
    VideoFieldsProps,
    VideoModel,
} from '../../../../../../shared/models/video/videoModels';
import {
    deleteVideo,
    downloadVideo,
    getVideos,
} from '../../../../../../shared/slices/video/videoSlice';
import {
    salesTeamDetail,
    setCurrentSalesTeamEditFormData,
    updateSalesTeamVideo,
    updateSalesTeamVideoStatus,
    uploadSalesTeamVideo,
} from '../../../salesTeamSlice';
import './video.scss';
import { editFormComponent } from '../../../salesTeamConstants';
import loDash from 'lodash';

import VideoTemplate from '../../../../../../shared/component/video/VideoTemplate';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import { getAbortController } from '../../../../../../utils/urlUtils';

const Video: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { data, statuses } = useAppSelector(salesTeamDetail);
    const teamId = data?.id || '';
    const [formData, setFormData] = useState<VideoModel>(data.videos[0]);
    const [downloadedVideo, setDownloadedVideo] = useState('noVideo');
    const showVideoFields: VideoFieldsProps = {
        showTitle: true,
        showDescription: true,
    };

    const uploadVideo = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files) {
            const file = event.target.files[0];
            dispatch(
                updateSalesTeamVideoStatus({
                    videoTypeId: videoTypes.salesTeamVideo.key,
                }),
            );
            dispatch(
                uploadSalesTeamVideo(
                    teamId,
                    file,
                    getAbortController(formData.isCancelled, index),
                ),
            );
        }
    };

    const cancelVideo = (video: VideoModel, index: number) => {
        const abortController = getAbortController(formData.isCancelled, index);
        abortController.abort();
        deleteIt(video);
    };

    const downloadIt = (video: VideoModel, from: string) => {
        dispatch(downloadVideo(video, from));
    };

    const deleteIt = (video: VideoModel) => {
        dispatch(deleteVideo(video));
    };

    const updateFields = (currentVideo: VideoModel) => {
        const video = { ...currentVideo, displayOrder: 1 };
        dispatch(
            setCurrentSalesTeamEditFormData({
                formData: video,
                salesTeamEditFormName: editFormComponent.video,
            }),
        );
        setFormData(video);
    };

    const updateInterval = (remainingInterval: number, fileInputName: string) => {
        if (fileInputName === 'salesTeamVideo')
            dispatch(
                updateSalesTeamVideo([
                    { ...data.videos[0], reuploadInterval: remainingInterval },
                ]),
            );
    };

    useEffect(() => {
        setFormData(data.videos[0]);
    }, [data.videos]);

    useEffect(() => {
        const interval = setInterval(() => {
            const objIndex = loDash.findIndex(data.videos, function (o) {
                return o.status === 2;
            });
            if (objIndex !== -1) {
                dispatch(getVideos(teamId, entityTypes.salesTeam.key));
            } else {
                clearInterval(interval);
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [dispatch, teamId, data.videos]);

    useEffect(() => {
        if (statuses.downloadedVideo.from !== '') {
            setDownloadedVideo(statuses.downloadedVideo.from);
        }
    }, [statuses.downloadedVideo]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} className="video-container">
                <ComponentWithLoader
                    showLoader={statuses.downloadedVideo.from === downloadedVideo}
                    showOverlay={true}
                    styleClass="progress-bar"
                />
                <VideoTemplate
                    fileInputName="salesTeamVideo"
                    videoSectionTitle="Sales Team Video"
                    data={formData}
                    videoFields={showVideoFields}
                    reuploadInterval={data.videos[0].reuploadInterval}
                    index={videoTypes.salesTeamVideo.index}
                    uploadAction={uploadVideo}
                    cancelAction={cancelVideo}
                    downloadAction={downloadIt}
                    deleteAction={deleteIt}
                    updateInterval={updateInterval}
                    updateFields={updateFields}
                />
            </Grid>
        </Grid>
    );
};
export default Video;
