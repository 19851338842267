import { faEdit, faTrashAlt, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { Fragment, FunctionComponent, useState } from 'react';
import { putUser } from '../../showcaseWebsiteSlice';
import { Audit, PrivateSiteUser } from '../../showcaseWebsiteModels';
import GenerateToken from '../generateToken';
import './index.scss';
import { showCaseWebsiteStyle } from '../../style';
import moment from 'moment';
import { useAppDispatch } from '../../../../../../../../app/hooks';
import { dateFormatMMDDYYY, phoneNoFormat } from '../../../../../../../../utils/urlUtils';

interface UserTokensProps {
    showcaseSiteId: string;
    users: PrivateSiteUser[];
}

const UserTokens: FunctionComponent<UserTokensProps> = ({ showcaseSiteId, users }) => {
    const [activeEditId, setActiveEditId] = useState<string>('');
    const [activeLogId, setActiveLogId] = useState<string>('');

    const editAccess = (id?: string) => {
        if (id) {
            const findUser = users.find((user) => {
                return user.id === id;
            });
            if (findUser) {
                setActiveEditId(id);
            }
        }
    };

    const dispatch = useAppDispatch();

    const disableAccess = (userData: PrivateSiteUser) => {
        const user: PrivateSiteUser = {
            ...userData,
            endDate: moment().subtract(1, 'day').format(),
            customDate: true,
        };
        dispatch(putUser(user, true));
    };
    const handleCancel = (id?: string | boolean) => {
        if (typeof id === 'string') {
            if (activeEditId === id) {
                setActiveEditId('');
            } else if (id) {
                setActiveEditId(id);
            }
        }
    };
    const handleCancelLog = (id?: string) => {
        if (activeLogId === id) {
            setActiveLogId('');
        } else if (id) {
            setActiveLogId(id);
        }
    };
    const showLogs = (id?: string) => {
        if (id) {
            const findUser = users.find((user) => {
                return user.id === id;
            });
            if (findUser) {
                setActiveLogId(id);
            }
        }
    };
    const showEditOrDisableIcons = (user: PrivateSiteUser) => {
        const currentDate = new Date();
        const endDate = new Date(user.endDate);
        return !user.endDate || endDate > currentDate;
    };

    const classes = showCaseWebsiteStyle();

    const getAccessCodeStatus = (data: PrivateSiteUser) => {
        //not approved
        if (!data.approved) {
            return 'Pending Approval';
        } else if (data.approved && !data.customDate) {
            //approved and custom date not set
            return 'Active';
        } else if (data.approved && data.customDate) {
            //approved and custom dates are set
            const isBefore = moment(moment(new Date()).format('MM-DD-YYYY')).isBefore(
                moment(data.startDate).format('MM-DD-YYYY'),
            );
            const isAfter = moment(moment(new Date()).format('MM-DD-YYYY')).isAfter(
                moment(data.endDate).format('MM-DD-YYYY'),
            );
            if (isBefore) {
                return 'Inactive';
            } else if (isAfter) {
                return 'Expired';
            } else {
                return 'Active';
            }
        }
        return '';
    };
    return users.length > 0 && users[0].id !== '' ? (
        <Grid container className="userTokens">
            <Grid item container spacing={2}>
                <Grid item xs={12} lg={2} className="contentHeader">
                    CLIENT NAME
                </Grid>
                <Grid item xs={12} lg={2} className="contentHeader">
                    ACCESS CODE
                </Grid>
                <Grid item xs={12} lg={2} className="contentHeader">
                    EMAIL
                </Grid>
                <Grid item xs={12} lg={2} className="contentHeader">
                    PHONE
                </Grid>
                <Grid item xs={12} lg={1} className="contentHeader">
                    CREATED
                </Grid>
                <Grid item xs={12} lg={1} className="contentHeader">
                    STATUS
                </Grid>
                <Grid item xs={12} lg={1} className="contentHeader">
                    TOTAL LOGINS
                </Grid>
                <Grid item container xs={12} lg={1}></Grid>
                {users.map((user: PrivateSiteUser) =>
                    activeEditId === user.id ? (
                        <Fragment key={user.id}>
                            <Grid item xs={12} lg={12}>
                                <GenerateToken
                                    initialState={user}
                                    formEdit={true}
                                    showcaseSiteId={showcaseSiteId}
                                    handleCancel={handleCancel}
                                />
                            </Grid>
                        </Fragment>
                    ) : activeLogId === user.id ? (
                        <Fragment key={user.id}>
                            <Grid item container spacing={1}>
                                <Grid item xs={12} lg={12} className="contentHeader">
                                    CLIENT NAME
                                </Grid>
                                <Grid item xs={12} lg={12} className="contentBody">
                                    {user.name}
                                </Grid>
                                <Grid item xs={12} lg={12} className="contentHeader">
                                    LOGIN DATE
                                </Grid>
                                <Grid item xs={12} lg={12} className="contentBody">
                                    {user?.privateSiteUserAudits?.length > 0 ? (
                                        user.privateSiteUserAudits.map(
                                            (audit: Audit, index: number) => (
                                                <Fragment key={index}>
                                                    {audit.loginDate &&
                                                        dateFormatMMDDYYY(
                                                            audit.loginDate,
                                                            'slash',
                                                        )}
                                                    {index <
                                                    user.privateSiteUserAudits.length - 1
                                                        ? ', '
                                                        : ''}
                                                </Fragment>
                                            ),
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="flex-end">
                                <Button
                                    onClick={() => handleCancelLog(user?.id)}
                                    className={classes.cancelButton}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Fragment>
                    ) : (
                        <Fragment key={user.id}>
                            <Grid item xs={12} lg={2} className="contentBody">
                                {user.name}
                            </Grid>
                            <Grid item xs={12} lg={2} className="contentBody">
                                {user.accessCode}
                            </Grid>
                            <Grid item xs={12} lg={2} className="contentBody">
                                {user.email}
                            </Grid>
                            <Grid item xs={12} lg={2} className="contentBody">
                                {phoneNoFormat(user.phone, '-')}
                            </Grid>
                            <Grid item xs={12} lg={1} className="contentBody">
                                {user.createdDate &&
                                    dateFormatMMDDYYY(user.createdDate, 'slash')}
                            </Grid>
                            <Grid item xs={12} lg={1} className="contentBody">
                                {getAccessCodeStatus(user)}
                            </Grid>
                            <Grid item xs={12} lg={1} className="contentBody">
                                {user.privateSiteUserAudits.length}
                            </Grid>
                            <Grid item container className="icons" xs={12} lg={1}>
                                {user?.privateSiteUserAudits?.length > 0 ? (
                                    <Grid item xs={12} lg={4}>
                                        <FontAwesomeIcon
                                            icon={faList}
                                            size="sm"
                                            className="logs icon"
                                            onClick={() => showLogs(user?.id)}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} lg={4}></Grid>
                                )}
                                {showEditOrDisableIcons(user) ? (
                                    <>
                                        <Grid item xs={12} lg={4}>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                size="sm"
                                                className="edit icon"
                                                onClick={() => editAccess(user?.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                size="sm"
                                                className="delete icon"
                                                onClick={() => disableAccess(user)}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Fragment>
                    ),
                )}
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};

export default UserTokens;
