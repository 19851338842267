import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { loadUserPermissions } from '../../../features/listings/advancedSearch/advancedSearchUtils';
import Logger from '../../../utils/logging/logger';
import { getDataForGlobalSearch } from '../../api/globalSearch/globalSearchApi';
import {
    getGlobalDefaultValue,
    initialGlobalSearchData,
    orderSearchResult,
} from '../../component/globalSearchDetail/globalSearchUtils';
import {
    GlobalSearchState,
    GlobalSearchCriteriaCalledFrom,
    DynamicSearchResponse,
} from '../../models/globalSearch/globalSearchModel';
import { setError } from '../messaging/messagingSlice';

const globalSearchState: GlobalSearchState = {
    loader: false,
    data: initialGlobalSearchData,
    viewMoreClicked: false,
    textSearchedFor: '',
};

export const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState: globalSearchState,
    reducers: {
        setLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loader: action.payload,
            };
        },
        updateViewMore: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                viewMoreClicked: action.payload,
            };
        },
        searchDetailsUpdate: (
            state,
            action: PayloadAction<GlobalSearchCriteriaCalledFrom>,
        ) => {
            const { from, sortColumn, sortDirection, text, status, entityType } =
                action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [from]: {
                        ...state.data[from],
                        tableAction: {
                            ...state.data[from].tableAction,
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                            text: text,
                            status: status,
                            entityType: entityType,
                        },
                    },
                },
            };
        },
        addSearchResults: (state, action: PayloadAction<DynamicSearchResponse>) => {
            const { from, apiResults } = action.payload;
            const orderedResult = orderSearchResult(
                from,
                apiResults.currentPage === 1
                    ? apiResults.results
                    : [...state.data[from].results, ...apiResults.results],
            );
            return {
                ...state,
                data: {
                    ...state.data,
                    [from]: {
                        ...state.data[from],
                        totalRecords: apiResults.totalRecords,
                        results: orderedResult,
                        tableAction: {
                            ...state.data[from].tableAction,
                            currentPage: apiResults.currentPage,
                            itemsPerPage: apiResults.recordsPerPage,
                        },
                    },
                },
            };
        },
        setDefaultStateValue: (state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    active: getGlobalDefaultValue('Active'),
                    pending: getGlobalDefaultValue('Pending'),
                    sold: getGlobalDefaultValue('Sold'),
                    expired: getGlobalDefaultValue('Expired'),
                    offMarket: getGlobalDefaultValue('Temp Off-Market'),
                    comingSoon: getGlobalDefaultValue('Coming Soon'),
                    draft: getGlobalDefaultValue('Draft'),
                    agents: getGlobalDefaultValue('', 'Agent'),
                    salesTeams: getGlobalDefaultValue('', 'SalesTeam'),
                    offices: getGlobalDefaultValue('', 'Office'),
                    applications: getGlobalDefaultValue('', 'Application'),
                },
            };
        },
        setSearchedText: (state, action: PayloadAction<string>) => {
            state.textSearchedFor = action.payload;
        },
    },
});

export const fetchMoreSearchResults =
    (request: GlobalSearchCriteriaCalledFrom): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const { from, ...searchData } = request;
            const userPermission = loadUserPermissions();
            if (from === 'all') {
                searchData.text = `"${searchData.text}"`;
            }
            dispatch(setSearchedText(searchData.text));
            const response = await getDataForGlobalSearch({
                ...searchData,
                ...userPermission,
            });
            if (response) {
                dispatch(searchDetailsUpdate(request));
                dispatch(addSearchResults({ from: from, apiResults: response }));
            }
        } catch (exception) {
            Logger.error('Failed to fetch Detailed page search results');
            dispatch(setError(`Failed to fetch Detailed page search results`));
        } finally {
            dispatch(setLoader(false));
        }
    };

export const {
    updateViewMore,
    searchDetailsUpdate,
    addSearchResults,
    setLoader,
    setDefaultStateValue,
    setSearchedText,
} = globalSearchSlice.actions;
export const globalSearch = (state: RootState): GlobalSearchState => state.globalSearch;
export default globalSearchSlice.reducer;
