import { FunctionComponent, ChangeEvent } from 'react';
import Radio, { RadioClassKey } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel, {
    FormControlLabelClassKey,
} from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { LookupInfo } from '../../models/lookups/lookupsModels';

interface RadioButtonToggleProps {
    id: string | boolean | number;
    name: string;
    items: LookupInfo[];
    handleRadioButtonChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: () => void;
    formClass?: Partial<ClassNameMap<FormControlLabelClassKey>> | undefined;
    radioClass?: Partial<ClassNameMap<RadioClassKey>> | undefined;
    disableAll?: string;
}

const RadioButtonToggle: FunctionComponent<RadioButtonToggleProps> = ({
    id,
    name,
    items,
    handleRadioButtonChange,
    handleBlur,
    formClass,
    radioClass,
    disableAll,
}) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                aria-label={name}
                name={name}
                value={id.toString()}
                onChange={handleRadioButtonChange}
                onBlur={handleBlur}
                row
            >
                {items.map((i) => {
                    return (
                        <FormControlLabel
                            value={i.id}
                            control={<Radio classes={radioClass} />}
                            label={i.name}
                            key={i.id}
                            classes={formClass}
                            disabled={disableAll?.length ? true : false}
                            className={
                                disableAll?.length
                                    ? 'disabledClass customRadioGrp'
                                    : 'customRadioGrp'
                            }
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonToggle;
