import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { MlsEmailsView, MlsEmailDetail } from './mlsEmailsModels';
import { getForMls, saveEmail, deleteEmail } from './mlsEmailsApi';
import { setSuccess, setError } from '../../shared/slices/messaging/messagingSlice';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../shared/slices/loader/loaderSlice';
import { MlsEmailEvents } from '../../shared/models/loader/loaderModels';
import Logger from '../../utils/logging/logger';

const initialState: MlsEmailsView = {
    emails: [],
    mls: '',
    showEditModal: false,
};

const initialMlsEmail: MlsEmailDetail = {
    id: '',
    email: '',
    mls: '',
    filterValue: '',
    insertOnly: false,
};

export const getEmailsForMls =
    (mls: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(MlsEmailEvents.MLS_EMAILS_GET));
            dispatch(loadMls(mls));
            const response = await getForMls(mls);
            dispatch(loadEmails(response));
        } catch (e) {
            Logger.error(`Error getting MLS emails: ${JSON.stringify(e)}`);
            dispatch(setError(`Error gettimg emails for '${mls}'`));
        } finally {
            dispatch(removeLoadingEvent(MlsEmailEvents.MLS_EMAILS_GET));
        }
    };

export const saveMlsEmail =
    (mlsEmail: MlsEmailDetail | null): AppThunk =>
    async (dispatch, getState) => {
        if (mlsEmail) {
            dispatch(addLoadingEvent(MlsEmailEvents.MLS_EMAILS_SAVE));
            try {
                await saveEmail(mlsEmail);
                dispatch(setSuccess(`Email '${mlsEmail.email}' saved successfully`));
            } catch (e) {
                Logger.error(`Error saving MLS email: ${JSON.stringify(e)}`);
                dispatch(setError('Error saving email'));
            } finally {
                dispatch(removeLoadingEvent(MlsEmailEvents.MLS_EMAILS_SAVE));
            }
        }
        const { mls } = getState().mlsEmails;
        dispatch(getEmailsForMls(mls || ''));
    };

export const deleteMlsEmail =
    (mlsEmail: MlsEmailDetail): AppThunk =>
    async (dispatch, getState) => {
        if (window.confirm(`Are you sure you want to delete '${mlsEmail?.email}'?`)) {
            dispatch(addLoadingEvent(MlsEmailEvents.MLS_EMAILS_DELETE));
            try {
                await deleteEmail(mlsEmail.id || '');
                dispatch(setSuccess(`Email '${mlsEmail.email}' deleted successfully`));
                const { mls } = getState().mlsEmails;
                dispatch(getEmailsForMls(mls || ''));
            } catch (e) {
                Logger.error(`Error deleting MLS email: ${JSON.stringify(e)}`);
                dispatch(setError('Error deleting email'));
            } finally {
                dispatch(removeLoadingEvent(MlsEmailEvents.MLS_EMAILS_DELETE));
            }
        }
    };

export const mlsEmailsSlice = createSlice({
    name: 'mlsEmails',
    initialState,
    reducers: {
        editEmail: (state, action: PayloadAction<MlsEmailDetail>) => {
            state.showEditModal = true;
            state.currentEmail = action.payload;
        },
        createEmail: (state, action: PayloadAction<string>) => {
            state.showEditModal = true;
            state.currentEmail = { ...initialMlsEmail, mls: action.payload };
        },
        loadEmails: (state, action: PayloadAction<MlsEmailDetail[]>) => {
            state.emails = action.payload;
            state.showEditModal = false;
            state.currentEmail = undefined;
        },
        loadMls: (state, action: PayloadAction<string>) => {
            state.mls = action.payload;
        },
    },
});

export const { createEmail, editEmail, loadEmails, loadMls } = mlsEmailsSlice.actions;
export const selectMlsEmailDetails = (state: RootState): MlsEmailsView => state.mlsEmails;

export default mlsEmailsSlice.reducer;
