import React, { FunctionComponent, useEffect, useState } from 'react';
import { SearchComponentProps } from '../../advancedSearchModels';
import SearchFieldWrapper from './SearchFieldWrapper';
import MultiSelectDropDown from '../../../../../shared/component/formFields/MultiSelectDropDown';
import { LookupInfo } from '../../../../../shared/models/lookups/lookupsModels';
import { Checkbox, FormControl, Grid } from '@material-ui/core';

const LookupSearch: FunctionComponent<SearchComponentProps> = ({
    criteria,
    onCriteriaAdd,
}) => {
    const [selectedVals, setSelectedVals] = useState<LookupInfo[]>([]);
    const [selectedCheckboxVals, setSelectedCheckboxVals] = useState<string[]>(
        criteria.values,
    );

    useEffect(() => {
        if (criteria.lookups?.length) {
            const lookupsWithFlagSet = criteria.lookups.filter((l) => {
                return criteria.values.some((v) => v === l.id);
            });
            setSelectedVals(lookupsWithFlagSet);
        }
    }, [criteria.values, criteria.lookups]);

    const handleCriteriaUpdate = (lookupCriteria: LookupInfo[]) => {
        const values = lookupCriteria.map((c) => c.id);
        onCriteriaAdd({ ...criteria, values: values });
    };

    /**
     * function to handle the checkbox selection and unselection.
     * @param id - from the lookup
     */
    const handleCheckboxCriteriaUpdate = (id: string) => {
        if (selectedCheckboxVals.includes(id)) {
            const newSelectedCheckboxVals = selectedCheckboxVals.filter((e) => e !== id);
            setSelectedCheckboxVals([...newSelectedCheckboxVals]);
            onCriteriaAdd({ ...criteria, values: [...newSelectedCheckboxVals] });
        } else {
            setSelectedCheckboxVals([...selectedCheckboxVals, id]);
            onCriteriaAdd({ ...criteria, values: [...selectedCheckboxVals, id] });
        }
    };

    /**
     * used to calculate the checkbox checked status
     * @param id - from the lookup
     * @returns boolean
     */
    const setChecked = (id: string) => {
        return selectedCheckboxVals.includes(id) ? true : false;
    };

    const component = (
        <MultiSelectDropDown
            id={`${criteria.fieldName}-list`}
            label={criteria.displayName}
            options={criteria.lookups || []}
            handleSelection={handleCriteriaUpdate}
            defaultValues={selectedVals}
            defaultWidth={false}
        />
    );

    const renderRow = (label: string, id: string): JSX.Element => {
        return (
            // <FormRow
            //     label={label}
            //     control={
            //         <Checkbox
            //             id={id}
            //             checked={setChecked(id)}
            //             onChange={() => handleCheckboxCriteriaUpdate(id)}
            //             className="checkbox-search customCheckbox"
            //         />
            //     }
            //     labelMd={4}
            //     controlMd={2}
            // />
            <>
                <Grid item className="field-control" xs={12} sm={12} md={1}>
                    <FormControl>
                        <Checkbox
                            id={id}
                            checked={setChecked(id)}
                            onChange={() => handleCheckboxCriteriaUpdate(id)}
                            className="checkbox-search customCheckbox"
                        />
                    </FormControl>
                </Grid>
                <Grid id={id} item className="field-label" xs={12} sm={12} md={5}>
                    <label>{label}</label>
                </Grid>
            </>
        );
    };

    const checkboxComponent = (): JSX.Element => {
        return (
            <Grid item container className="content" alignItems="center" md={12}>
                {criteria.lookups?.map((data) => {
                    return renderRow(data.name, data.id);
                })}
            </Grid>
        );
    };

    return (
        <SearchFieldWrapper
            control={
                criteria.fieldName === 'officeId' || criteria.fieldName === 'regionId'
                    ? component
                    : checkboxComponent()
            }
            label=""
        />
    );
};

export default LookupSearch;
