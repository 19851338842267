import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { setCommonLoader } from '../../shared/slices/loader/loaderSlice';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import {
    exportReportsApi,
    getAgentCitReport,
    getAdMarketingReport,
    exportCitReport,
} from './reportingApi';
import {
    ReportingRequest,
    ReportingState,
    CITReportForm,
    AgentCitReportResults,
    AdvertisementMarketingReportForm,
    AdvertisementMarketingReportResults,
} from './reportingModel';
import { getAgentsByLastName } from '../../shared/api/agent/agentApi';
import { AgentSearchResult } from '../../shared/models/agent/agentModel';
import { returnFile } from '../../utils/api/fileResults';
import _ from 'lodash';

const reportingState: ReportingState = {
    citReportResults: [],
    agentSearchResults: [],
    marketingResults: [],
    progress: false,
    searchLoader: false,
};

export const reportingSlice = createSlice({
    name: 'reporting',
    initialState: reportingState,
    reducers: {
        updateReportResults: (state, action: PayloadAction<AgentCitReportResults[]>) => {
            return {
                ...state,
                citReportResults: action.payload,
            };
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.progress = action.payload;
        },
        setAgentSearchResults: (state, action: PayloadAction<AgentSearchResult[]>) => {
            return {
                ...state,
                agentSearchResults: action.payload,
            };
        },
        updateMarketingReportResults: (
            state,
            action: PayloadAction<AdvertisementMarketingReportResults[]>,
        ) => {
            return {
                ...state,
                marketingResults: action.payload,
            };
        },
        setSearchLoader: (state, action: PayloadAction<boolean>) => {
            state.searchLoader = action.payload;
        },
        setInitialState: (state) => {
            return {
                ...state,
                citReportResults: [],
                agentSearchResults: [],
                marketingResults: [],
                progress: false,
                searchLoader: false,
            };
        },
    },
});

export const getCitReport =
    (citRequest: CITReportForm): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await getAgentCitReport(citRequest);
            if (response && response.length)
                dispatch(updateReportResults(_.orderBy(response, 'date', 'desc')));
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the Agent CIT Report`));
        } finally {
            dispatch(setProgress(false));
        }
    };

export const getAdMarketingReportResults =
    (request: AdvertisementMarketingReportForm): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await getAdMarketingReport(request.agentId, {
                mediaOutletId: request.mediaOutletId,
                startDate: request.startDate,
                endDate: request.endDate,
            });
            if (response && response.length)
                dispatch(
                    updateMarketingReportResults(
                        _.orderBy(response, 'issueDate', 'desc'),
                    ),
                );
        } catch (exception) {
            dispatch(setError(`Failed to retrieve Advertisement Marketing Report`));
        } finally {
            dispatch(setProgress(false));
        }
    };

export const fetchAgents =
    (agentName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getAgentsByLastName(agentName);
            dispatch(setAgentSearchResults(response));
        } catch {
            dispatch(setError('Error in fetching agents'));
        } finally {
            dispatch(setSearchLoader(true));
        }
    };

/**
 * function to export the report based on the selected
 * option and save the data in excel
 * @param request
 * @param from
 * @returns
 */
export const exportReports =
    (request: ReportingRequest | CITReportForm, from: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setCommonLoader(true));
            const response =
                from === 'cit'
                    ? exportCitReport(request as CITReportForm)
                    : exportReportsApi(request as ReportingRequest, from);
            await returnFile(response);
        } catch (exception) {
            dispatch(setError(`Failed to export the ${from} report list`));
        } finally {
            dispatch(setCommonLoader(false));
        }
    };

export const {
    setProgress,
    updateReportResults,
    setAgentSearchResults,
    updateMarketingReportResults,
    setSearchLoader,
    setInitialState,
} = reportingSlice.actions;

export const reporting = (state: RootState): ReportingState => state.reporting;

export default reportingSlice.reducer;
