import {
    OfficeDescription,
    OfficeDetailsSummary,
    OfficeEditScreenRequiredFields,
} from './officeModels';
import { findIdFromLookups, phoneNoFormat, validateEmail } from '../../../utils/urlUtils';
import { editFormComponent, officeRegion } from './officeConstants';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';
import { status } from '../../../shared/constants/lookups/lookupsConstants';

export const createNeededData = (
    base: CustomFormedData[],
    data: OfficeDetailsSummary,
): CustomFormedData[] => {
    const newformattedData = base.map((baseData: CustomFormedData) => {
        return {
            title: baseData.title,
            value:
                typeof data[baseData.value as keyof typeof data] === 'boolean'
                    ? data[baseData.value as keyof typeof data]
                        ? 'Yes'
                        : 'No'
                    : baseData.isNum
                    ? phoneNoFormat(
                          data[baseData.value as keyof typeof data]
                              ? data[baseData.value as keyof typeof data]?.toString()
                              : '',
                      )
                    : getCustomValues(baseData.value, data),
            isDate: baseData.isDate ? true : false,
            isMap: baseData.isMap ? true : false,
            isNum: baseData.isNum ? true : false,
            isLink: baseData.isLink ? true : false,
            isExternal: baseData?.isExternal || false,
        };
    });

    return newformattedData;
};

export const getCustomValues = (
    value: string | number | boolean | null,
    data: OfficeDetailsSummary,
): string => {
    switch (value) {
        case 'showMap':
            return data.latitude && data.longitude
                ? 'Show Map'
                : 'There is no map available for this office';
        default:
            return data[value as keyof typeof data]
                ? `${data[value as keyof typeof data]}`
                : '';
    }
};

export const getOfficeDescriptionFieldsData = (
    data: OfficeDetailsSummary,
    state: LookupInfo[],
    officeSalesRegion: LookupInfo[],
): OfficeDescription => {
    return {
        id: data.id,
        primaryMlsName: data.primaryMlsName,
        mlsNumber: data.mlsNumber,
        officeRegion: data.officeRegion,
        officeRegionId: findIdFromLookups(data.officeRegion, officeRegion),
        salesRegion: data.salesRegion,
        salesRegionId: Number(findIdFromLookups(data.salesRegion, officeSalesRegion)),
        publish: data.publish,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        fax: data.fax,
        email: data.email,
        facebookUrl: data.facebookUrl,
        linkedInUrl: data.linkedInUrl,
        twitterUrl: data.twitterUrl,
        instagramUrl: data.instagramUrl,
        officeSpecializations: data.officeSpecializations,
        history: data.history,
        statusId: data.status === status.inactive ? 2 : 1,
        regionId: Number(findIdFromLookups(data.state, state)),
        name: data.name,
        createdDate: data?.createdDate,
        lastUpdated: data?.lastUpdated,
        locationCode: data?.tridentLocationCode,
    };
};

export const getCustomData = (data: LookupInfo[]): CustomFormedData[] => {
    if (data.length > 0) {
        const customData = data.map((item, index) => {
            return {
                title: '',
                value: item.name + `${index !== data.length - 1 ? ', ' : ''}`,
                isDate: false,
            };
        });
        return customData;
    }
    return [];
};

export const generateOfficeEditScreens = (): string[] => {
    return [
        editFormComponent.description,
        editFormComponent.history,
        editFormComponent.officePhoto,
        editFormComponent.managers,
        editFormComponent.manageDesignVault,
        editFormComponent.settings,
    ];
};

export const displayErrorForOfficeEditScreen = (
    formData: OfficeEditScreenRequiredFields,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;
    if (!formData.name)
        result = { isValid: false, errorMessage: result.errorMessage + 'Name,' };
    if (!formData.officeRegion)
        result = { isValid: false, errorMessage: result.errorMessage + 'Office Region,' };
    if (!formData.salesRegion)
        result = { isValid: false, errorMessage: result.errorMessage + 'Sales Region,' };
    if (!formData.address1)
        result = { isValid: false, errorMessage: result.errorMessage + 'Address1,' };
    if (!formData.city)
        result = { isValid: false, errorMessage: result.errorMessage + 'City,' };
    if (!formData.state)
        result = { isValid: false, errorMessage: result.errorMessage + 'State,' };
    if (!formData.zip)
        result = { isValid: false, errorMessage: result.errorMessage + 'Zip,' };
    if (!formData.phoneNumber)
        result = { isValid: false, errorMessage: result.errorMessage + 'Phone Number,' };
    if (!formData.fax)
        result = { isValid: false, errorMessage: result.errorMessage + 'Fax,' };
    if (!formData.email)
        result = { isValid: false, errorMessage: result.errorMessage + 'Email,' };

    if (!result.errorMessage) {
        if (formData.zip.toString().length !== 5)
            result = { isValid: false, errorMessage: 'Zip should be five digits' };
        else if (formData.phoneNumber.toString().length !== 10)
            result = { isValid: false, errorMessage: 'Phone number should be 10 digits' };
        else if (!validateEmail(formData.email))
            result = { isValid: false, errorMessage: 'Email is not valid' };
        else result = { isValid: true, errorMessage: '' };
    }
    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};
