import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';
import {
    OfficeLookupInfo,
    UserRoleLookups,
} from '../../../shared/models/lookups/lookupsModels';
import { editFormComponent } from './userDetailsConstants';
import {
    UserIdentity,
    UserDetails,
    UserEditScreenRequiredFields,
} from './userDetailsModels';
import lodash from 'lodash';
import { AgentDetailsForAssistant } from '../../agents/agentDashboard/agentModels';
import { validateEmail } from '../../../utils/urlUtils';

export const createNeededData = (
    base: CustomFormedData[],
    data: UserIdentity,
): CustomFormedData[] => {
    const newformattedData = base.map((baseData: CustomFormedData) => {
        return {
            title: baseData.title,
            value: data[baseData.value as keyof typeof data],
        };
    });

    return newformattedData;
};

export const displayUserIdentity = (userDetails: UserDetails): UserIdentity => {
    return {
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        middleName: userDetails.middleName,
        preferredFirstName: userDetails.preferredFirstName,
        salutation: userDetails.salutation,
        status: userDetails.status,
        title: userDetails.title,
        userName: userDetails.userName,
        dashStaffId: userDetails.dashStaffId,
    };
};

export const displayAgentsForAssistant = (
    agents: AgentDetailsForAssistant[],
): CustomFormedData[] => {
    const agentList = agents.map((agent) => agent.agentName);
    return [
        {
            title: 'Agents',
            value: agentList.length ? agentList.join(', ') : '-',
        },
    ];
};

export const displayUserOffices = (
    offices: OfficeLookupInfo[],
    selectedOffices: string[],
): CustomFormedData[] => {
    const activeOffices = offices.filter((off) => typeof off.salesRegion === 'string');
    const sortedOffices = lodash.sortBy(activeOffices, 'salesRegion');
    const officeNameList: string[] = [];
    sortedOffices.forEach((sortedOffice) => {
        if (selectedOffices.includes(sortedOffice.id)) {
            officeNameList.push(sortedOffice.name);
        }
    });
    return [
        {
            title: 'SalesRegion',
            value: officeNameList.length
                ? officeNameList.join(', ')
                : 'There are no offices associated with the user.',
        },
    ];
};

export const displayUserRoles = (
    userRoleLookups: UserRoleLookups[],
    selectedRoles: string[],
): CustomFormedData[] => {
    const userRoles: string[] = [];
    userRoleLookups.forEach((userRoleLookup) => {
        if (selectedRoles.includes(userRoleLookup.key.toLowerCase())) {
            userRoles.push(userRoleLookup.value);
        }
    });
    return [
        {
            title: 'Roles',
            value: userRoles.length
                ? userRoles.join(', ')
                : 'There are no roles associated with the user.',
        },
    ];
};

export const generateUserDetailsEditScreens = (): string[] => {
    return [
        editFormComponent.identity,
        editFormComponent.officeAccess,
        editFormComponent.roles,
        editFormComponent.dashRoles,
    ];
};

export const displayErrorForUserEditScreen = (
    formData: UserEditScreenRequiredFields,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;

    if (!formData.salutation)
        result = { isValid: false, errorMessage: result.errorMessage + 'Salutation,' };
    if (!formData.firstName)
        result = { isValid: false, errorMessage: result.errorMessage + 'First Name,' };
    if (!formData.lastName)
        result = { isValid: false, errorMessage: result.errorMessage + 'Last Name,' };
    if (!formData.preferredFirstName)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Preferred First Name,',
        };
    if (!formData.email)
        result = { isValid: false, errorMessage: result.errorMessage + 'Email,' };
    if (!formData.title)
        result = { isValid: false, errorMessage: result.errorMessage + 'Title,' };
    if (!formData.userName)
        result = { isValid: false, errorMessage: result.errorMessage + 'Username,' };

    if (!result.errorMessage) {
        if (!validateEmail(formData.email))
            result = { isValid: false, errorMessage: 'Email is not valid' };
        else
            result = {
                isValid: true,
                errorMessage: '',
            };
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};
