import { FunctionComponent, useState } from 'react';
import {
    developmentPhotoDetails,
    saveImageDetails,
    updateOrderNo,
    updateTotalImageCount,
    deleteImageDetails,
    updateAllImages,
    saveImageSharePackage,
    downloadImagePackage,
    imageDownload,
    fetchImageTags,
    updateFlag,
    saveImageCaption,
    saveImageTags,
} from '../developmentPhotosSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks';
import { imageTypes } from '../../../../../../../shared/constants/imageTypes';
import { UpdateFlag } from '../../../../../../../shared/models/flag/flagModel';
import { DrawerContainerProps } from '../../../../developmentDetailsModel';
import { setCurrentDevelopmentEditFormData } from '../../../../developmentDetailsSlice';
import {
    ImageData,
    OrderNoUpdate,
    ImageUpdate,
} from '../../../../../../../shared/models/images/sharedModel';
import PhotoTab from '../../../../../../../shared/component/photos/PhotoTab';

const Buildings: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
    currentSection,
}) => {
    const developmentPhotoInfo = useAppSelector(developmentPhotoDetails);
    const dispatch = useAppDispatch();
    const { currentOrderNo, allImages, flags, types, filter, imageTags } =
        developmentPhotoInfo;
    const [imageSelectCount, setImageSelectCount] = useState<number>(0);

    const updateOrderNumber = (data: OrderNoUpdate) => {
        dispatch(updateOrderNo(data));
    };

    const updateImageCount = (data: OrderNoUpdate) => {
        dispatch(updateTotalImageCount(data));
    };

    const updateImages = (imageData: ImageUpdate) => {
        dispatch(updateAllImages(imageData));
    };

    const flagUpdate = (data: UpdateFlag) => {
        dispatch(updateFlag(data));
    };

    const setFormData = (images: ImageData[], formName: string) => {
        dispatch(
            setCurrentDevelopmentEditFormData({
                formData: images,
                developmentEditFormName: formName,
            }),
        );
    };

    const disableUpload = () => {
        return types.buildings.photosLoaded >= parseInt(types.buildings.maxPhotos);
    };

    return (
        <>
            <PhotoTab
                from="development"
                imageOrderNo={currentOrderNo.buildings}
                images={allImages.buildings}
                isUploading={flags.isUploading}
                currentImageType={imageTypes.Buildings}
                entityId={editFormData.data.id}
                saveImageDetails={saveImageDetails}
                updateOrderNumber={updateOrderNumber}
                updateImageCount={updateImageCount}
                currentImageDetails={types.buildings}
                filter={filter}
                flags={flags}
                deleteImageDetails={deleteImageDetails}
                updateImages={updateImages}
                saveImageSharePackage={saveImageSharePackage}
                downloadImagePackage={downloadImagePackage}
                imageDownload={imageDownload}
                fetchImageTags={fetchImageTags}
                flagUpdate={flagUpdate}
                saveImageCaption={saveImageCaption}
                imageTags={imageTags}
                saveImageTags={saveImageTags}
                entityType="building"
                setFormData={setFormData}
                currentSection={currentSection ? currentSection : ''}
                imageSelectCount={imageSelectCount}
                setImageSelectCount={setImageSelectCount}
                maxAllowedPhotos={50}
                disableUpload={disableUpload()}
                office={editFormData.data.office}
            />
        </>
    );
};
export default Buildings;
