import { FunctionComponent, useState, Fragment } from 'react';
import loDash from 'lodash';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, deleteData, displayReportToggle } from '../../../cmrSlice';
import './index.scss';
import { SectionProps } from '../../../cmrModels';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import ReadOnlyList from './components/ReadOnlyList';
import CustomFeaturedPlacement from '../../../../../shared/component/marketing/featuredPlacement/CustomFeaturedPlacement';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import { MarketingData } from '../../../../../shared/models/marketing/marketingModels';

const FeaturedPlacement: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const listingId = listingdata.id;
    // TODO: Implement sortByDate DESC
    const sortedPlacements = loDash
        .sortBy(paneldata.featuredPlacements, 'date')
        .reverse();

    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const [editId, setEditId] = useState('');
    const onEdit = (id: string) => {
        googleAnalyticsAction('Edit', 'Click', 'Featured Placements Edit');
        setEditId(id);
    };
    const deleteItem = (id: string) => {
        googleAnalyticsAction('Delete', 'Click', 'Featured Placements Delete');
        const url = `listing/${listingId}/marketing/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'id'));
    };
    const cancelClick = () => {
        setEditId('');
    };
    return (
        <div className="feature-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Featured Placements Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={
                    hideDisplayOnReport(9, sortOrder, props.sectionId) ? true : false
                }
            />
            <CustomFeaturedPlacement
                formEdit={false}
                listingId={listingId}
                cancelClick={cancelClick}
                sectionId={props.sectionId}
            />
            <div className="inner-container">
                {sortedPlacements &&
                    sortedPlacements.map((featuredPlacementData: MarketingData) => (
                        <Fragment key={'editData' + featuredPlacementData.id}>
                            {editId === featuredPlacementData.id ? (
                                <CustomFeaturedPlacement
                                    featuredPlacementData={featuredPlacementData}
                                    formEdit={true}
                                    cancelClick={cancelClick}
                                    listingId={listingId}
                                    sectionId={props.sectionId}
                                />
                            ) : (
                                <ReadOnlyList
                                    addition={featuredPlacementData}
                                    onEdit={onEdit}
                                    deleteItem={deleteItem}
                                />
                            )}
                        </Fragment>
                    ))}
            </div>
        </div>
    );
};
export default FeaturedPlacement;
