import { currencyFormat, dateFormatMMDDYYY } from '../../../utils/urlUtils';
import { Order } from '../../models/customTable/customTableModels';
import {
    ListingMetaData,
    AgentMetaData,
    GlobalSearchResponse,
    OfficeMetaData,
    SalesTeamMetaData,
    ApplicationMetaData,
    SearchResult,
} from '../../models/globalSearch/globalSearchModel';
import loDash from 'lodash';

export const globalSearchRedirect = (
    data: GlobalSearchResponse,
): { type: string; url: string } => {
    const toReturn = {
        type: '',
        url: '',
    };
    if (data.entityType === 'Listing') {
        const listingInfo = data.metadata as ListingMetaData;
        toReturn.type = 'listing';
        toReturn.url = `/eng/listing/${listingInfo.id}`;
    } else if (data.entityType === 'Office') {
        const officeInfo = data.metadata as OfficeMetaData;
        toReturn.type = 'office';
        toReturn.url = `/eng/office/${officeInfo.id}`;
    } else if (data.entityType === 'SalesTeam') {
        const salesTeamInfo = data.metadata as SalesTeamMetaData;
        toReturn.type = 'salesTeam';
        toReturn.url = `/eng/salesTeam/${salesTeamInfo.id}`;
    } else if (data.entityType === 'Application') {
        toReturn.type = 'application';
        toReturn.url = `/eng/applications`;
    } else {
        const agentInfo = data.metadata as AgentMetaData;
        toReturn.type = 'agent';
        toReturn.url = `/eng/agent/${agentInfo.id}`;
    }
    return toReturn;
};

export const dataToBind = (data: GlobalSearchResponse): string => {
    if (data.entityType === 'Listing') {
        const listingInfo = data.metadata as ListingMetaData;
        const add1 = `<span class='textBold'>${listingInfo?.address1}</span>`;
        const add2 = listingInfo?.address2
            ? `<span class='textBold'>, ${listingInfo?.address2}</span>`
            : '';
        const listingStatus = listingInfo?.status ? ' - ' + listingInfo?.status : '';
        const businessPurpose =
            listingInfo?.businessPurposeTypeId === 1 ? ' - Sale' : ' - Rental';
        const mlsNumber = listingInfo?.mlsNumber
            ? ` - <span class='textBold'>(${listingInfo?.mlsNumber})</span>`
            : '';
        const publishStatus = listingInfo?.publish ? ' - Published' : ''; // Adds unpublished status later
        const listAgent = listingInfo?.primaryListAgent
            ? ` - <span class='textBold'>${listingInfo?.primaryListAgent}</span>`
            : '';
        const sellAgent = listingInfo?.primarySaleAgent
            ? ` - <span class='textBold'>${listingInfo?.primarySaleAgent}</span>`
            : '';
        const price = listingInfo?.currentPrice
            ? ` - <span class='textBold'>$${currencyFormat(
                  listingInfo?.currentPrice,
              )}</span>`
            : '';
        let date = '';
        if (
            listingInfo?.status.toLowerCase() === 'active' ||
            listingInfo?.status.toLowerCase() === 'coming soon' ||
            listingInfo?.status.toLowerCase() === 'draft' ||
            listingInfo?.status.toLowerCase() === 'temp off-market'
        ) {
            date = listingInfo?.listingDate
                ? ' - Listing Date: ' + dateFormatMMDDYYY(listingInfo?.listingDate)
                : '';
        } else if (listingInfo?.status.toLowerCase() === 'pending') {
            const date1 = listingInfo?.listingDate
                ? ' - Listing Date: ' + dateFormatMMDDYYY(listingInfo?.listingDate)
                : '';
            const date2 = listingInfo?.contractDate
                ? ' - Contract Date: ' + dateFormatMMDDYYY(listingInfo?.contractDate)
                : '';
            date = date1 + date2;
        } else if (listingInfo?.status.toLowerCase() === 'sold') {
            date = listingInfo?.closedDate
                ? ' - Sold Date: ' + dateFormatMMDDYYY(listingInfo?.closedDate)
                : '';
        } else if (listingInfo?.status.toLowerCase() === 'expired') {
            const date1 = listingInfo?.listingDate
                ? ' - Listing Date: ' + dateFormatMMDDYYY(listingInfo?.listingDate)
                : '';
            const date2 = listingInfo?.expirationDate
                ? ' - Expiration Date: ' + dateFormatMMDDYYY(listingInfo?.expirationDate)
                : '';
            date = date1 + date2;
        }
        return (
            add1 +
            add2 +
            mlsNumber +
            listingStatus +
            publishStatus +
            businessPurpose +
            listAgent +
            sellAgent +
            price +
            date
        );
    } else if (data.entityType === 'Office') {
        const officeInfo = data.metadata as OfficeMetaData;
        return (
            officeInfo.name + ` (${officeInfo.statusId === 1 ? 'Active' : 'Inactive'})`
        );
    } else if (data.entityType === 'SalesTeam') {
        const salesTeamInfo = data.metadata as SalesTeamMetaData;
        return salesTeamInfo.name;
    } else if (data.entityType === 'Application') {
        const applicationInfo = data.metadata as ApplicationMetaData;
        return applicationInfo.name;
    } else {
        const agentInfo = data.metadata as AgentMetaData;
        const fN = agentInfo?.firstName;
        const lN = agentInfo?.lastName ? ' ' + agentInfo?.lastName : '';
        return fN + lN;
    }
};

export const globalTabId = {
    all: {
        id: 0,
    },
    active: {
        id: 1,
    },
    pending: {
        id: 2,
    },
    sold: {
        id: 3,
    },
    expired: {
        id: 4,
    },
    offMarket: {
        id: 5,
    },
    comingSoon: {
        id: 6,
    },
    draft: {
        id: 7,
    },
    agents: {
        id: 8,
    },
    salesTeams: {
        id: 9,
    },
    offices: {
        id: 10,
    },
    applications: {
        id: 11,
    },
    privateListing: {
        id: 12,
    },
};

export const getGlobalDefaultValue = (status = '', entityType = ''): SearchResult => {
    const direction: Order = 'asc';
    return {
        totalRecords: 0,
        results: [],
        tableAction: {
            sortColumn: '',
            sortDirection: direction,
            currentPage: 1,
            itemsPerPage: 30,
            text: '',
            status: status,
            entityType: entityType,
        },
    };
};

export const initialGlobalSearchData = {
    all: getGlobalDefaultValue(),
    active: getGlobalDefaultValue('Active'),
    privateListing: getGlobalDefaultValue('Private'),
    pending: getGlobalDefaultValue('Pending'),
    sold: getGlobalDefaultValue('Sold'),
    expired: getGlobalDefaultValue('Expired'),
    offMarket: getGlobalDefaultValue('Temp Off-Market'),
    comingSoon: getGlobalDefaultValue('Coming Soon'),
    draft: getGlobalDefaultValue('Draft'),
    agents: getGlobalDefaultValue('', 'Agent'),
    salesTeams: getGlobalDefaultValue('', 'SalesTeam'),
    offices: getGlobalDefaultValue('', 'Office'),
    applications: getGlobalDefaultValue('', 'Application'),
};

export const searchTabs = [
    'all',
    'active',
    'privateListing',
    'pending',
    'sold',
    'expired',
    'offMarket',
    'comingSoon',
    'draft',
    'agents',
    'salesTeams',
    'offices',
    'applications',
];

export const formGlobalSearchViewData = (
    results: GlobalSearchResponse[],
): GlobalSearchResponse[] => {
    const listings = results.filter((data) => data.entityType === 'Listing');
    const others = results.filter((data) => data.entityType !== 'Listing');
    const listingGroup: { [key: string]: GlobalSearchResponse[] } = {
        active: [],
        draft: [],
        pending: [],
        comingSoon: [],
        sold: [],
        tempOffMarket: [],
        expired: [],
        permOffMarket: [],
        deleted: [],
        privateListing: [],
    };
    listings.forEach((data) => {
        if (data.searchData.toLowerCase().includes('active')) {
            listingGroup['active'].push(data);
        } else if (data.searchData.toLowerCase().includes('private')) {
            listingGroup['privateListing'].push(data);
        } else if (data.searchData.toLowerCase().includes('draft')) {
            listingGroup['draft'].push(data);
        } else if (data.searchData.toLowerCase().includes('pending')) {
            listingGroup['pending'].push(data);
        } else if (data.searchData.toLowerCase().includes('coming soon')) {
            listingGroup['comingSoon'].push(data);
        } else if (data.searchData.toLowerCase().includes('sold')) {
            listingGroup['sold'].push(data);
        } else if (data.searchData.toLowerCase().includes('expired')) {
            listingGroup['expired'].push(data);
        } else if (data.searchData.toLowerCase().includes('temp off-market')) {
            listingGroup['tempOffMarket'].push(data);
        } else if (data.searchData.toLowerCase().includes('perm off-market')) {
            listingGroup['permOffMarket'].push(data);
        } else if (data.searchData.toLowerCase().includes('deleted')) {
            listingGroup['deleted'].push(data);
        }
    });
    const finalResult = [
        ...loDash.orderBy(listingGroup['draft'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['comingSoon'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['active'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['privateListing'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['pending'], 'metadata.contractDate', 'desc'),
        ...loDash.orderBy(listingGroup['sold'], 'metadata.closedDate', 'desc'),
        ...loDash.orderBy(listingGroup['tempOffMarket'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['expired'], 'metadata.expirationDate', 'desc'),
        ...loDash.orderBy(listingGroup['permOffMarket'], 'metadata.listingDate', 'desc'),
        ...loDash.orderBy(listingGroup['deleted'], 'metadata.expirationDate', 'desc'),
        ...others,
    ];

    return finalResult;
};

export const orderSearchResult = (
    from: string,
    data: GlobalSearchResponse[],
): GlobalSearchResponse[] => {
    if (
        from === 'all' ||
        from === 'agents' ||
        from === 'salesTeams' ||
        from === 'offices' ||
        from === 'applications'
    ) {
        return data;
    } else if (
        from === 'active' ||
        from === 'draft' ||
        from === 'comingSoon' ||
        from === 'offMarket'
    ) {
        return loDash.orderBy(data, 'metadata.listingDate', 'desc');
    } else if (from === 'pending') {
        return loDash.orderBy(data, 'metadata.contractDate', 'desc');
    } else if (from === 'expired') {
        return loDash.orderBy(data, 'metadata.expirationDate', 'desc');
    } else {
        return loDash.orderBy(data, 'metadata.closedDate', 'desc');
    }
};
