import { lazy, Suspense, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { MessageToast } from './shared/component/messaging/MessageToast';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { Container } from '@material-ui/core';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { CustomLinearProgress } from './shared/component/loader/CustomLinearProgress';
import Section from './shared/component/section/Section';
import FloatingButton from './shared/component/feedback/FloatingButton';
import { UserToken } from './shared/auth/authModels';
import { getToken, getUser, saveUser } from './shared/auth/authService';
import Cmr from './features/cmr/Cmr';
import NavContainer from './shared/component/nav/NavContainer';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import ApproveAccessCode from './features/showcaseSite/ApproveAccessCode';
import OfficeDesignVaultManager from './features/designVault/manage/OfficeDesignVaultManager';
import { useAppDispatch } from './app/hooks';
import { saveConciergeUserLogin } from './features/users/userDetails/userDetailsSlice';
import Support from './shared/component/support/Support';
import { pdfjs } from 'react-pdf';

const MlsEmails = lazy(() => import('../src/features/mlsemails/MlsEmails'));
const DesignVault = lazy(() => import('../src/features/designVault/DesignVault'));
const ListingSyndication = lazy(
    () => import('../src/features/listingSyndication/ListingSyndication'),
);
const CmrReport = lazy(() => import('../src/features/cmrReport/CmrReport'));
const Scheduler = lazy(() => import('../src/features/cmr/header/scheduler/Scheduler'));
const CannyWidget = lazy(() => import('../src/shared/component/canny/Canny'));
const ShowingsUpload = lazy(
    () => import('./features/excelUpload/components/showingsUpload/ShowingsUpload'),
);
const SignUp = lazy(() => import('../src/features/photographer/signUp/SignUp'));
const Upload = lazy(() => import('../src/features/photographer/upload/Upload'));
const LogIn = lazy(() => import('../src/features/photographer/login/Login'));
const UxLogin = lazy(() => import('../src/features/uxLogin/UxLogin'));
const ForgotPassword = lazy(() => import('../src/features/photographer/forgotPassword'));
const ResetPassword = lazy(() => import('../src/features/photographer/resetPassword'));
const ListingMarketingList = lazy(
    () => import('../src/features/marketing/components/ListingMarketingList'),
);
const AgentMarketingList = lazy(
    () => import('../src/features/marketing/components/AgentMarketingList'),
);
const LogOut = lazy(() => import('../src/features/logout/LogOut'));

const App = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const searchParam = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParam.entries());
    const oldHeaderNeeded =
        window.location.href.indexOf('photographer') > -1 ||
        window.location.href.indexOf('reportbuilder') > -1;
    const searchURLStr = searchParam.toString();
    const indexOfStr = searchURLStr.indexOf('&');
    const from = params['calledfrom'];
    const redirectedFrom = params['redirectedFrom'];
    const user = useMemo(() => getUser(), []);
    const referrerUrl =
        params['referer'] + (indexOfStr > 0 ? searchURLStr.substr(indexOfStr) : '');
    const redirectURL = referrerUrl.replace(/&calledfrom=[^&;]*/, '');
    const hideBar = from === 'report' ? true : false;
    //Intialize global worker for PDF.js for react-pdf
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const oktaAuth = new OktaAuth({
        issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER}`,
        clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
        redirectUri: window.location.origin + '/auth',
        scopes: [
            'openid offline_access email profile memberOf userinfo_metro userinfo_office',
        ],
        pkce: true,
        tokenManager: {
            autoRenew: true,
        },
    });

    const history = useHistory();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        const authState = _oktaAuth.authStateManager.getAuthState();
        const userToken: UserToken = {
            token: authState?.accessToken?.accessToken || '',
            idToken: authState?.idToken?.idToken || '',
            expires: moment.unix(authState?.accessToken?.expiresAt || 0).toDate(),
            refresh_token: authState?.refreshToken?.refreshToken || '',
        };
        await saveUser(userToken).then(() => {
            history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
        });
    };

    const token = getToken();

    useEffect(() => {
        if (user && redirectedFrom) {
            const data = {
                user: user.userName,
                changeDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                notes: `${redirectedFrom} - ${user.firstName}  ${user.lastName}`,
                source: 'Concierge Redirect',
                profileId: user.profileId ? user.profileId : '',
            };
            dispatch(saveConciergeUserLogin(data));
        }
    }, [redirectedFrom, user, dispatch]);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Suspense fallback={<></>}>
                <Container disableGutters maxWidth={false}>
                    {oldHeaderNeeded ? (
                        <NavContainer
                            from={from}
                            redirectUrl={redirectURL}
                            token={token}
                        />
                    ) : (
                        ''
                    )}
                    {oldHeaderNeeded && <CustomLinearProgress />}
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to="/eng/home" />}
                        />
                        <Route
                            exact
                            path="/auth"
                            render={() => (
                                <LoginCallback loadingElement={<>Authenticating...</>} />
                            )}
                        />
                        <Route exact path="/eng/logout" component={LogOut} />
                        <SecureRoute
                            path="/agent/:agentId/marketing"
                            component={AgentMarketingList}
                        />
                        <SecureRoute
                            path="/designVault/office-templates/:officeId"
                            component={OfficeDesignVaultManager}
                        />
                        <SecureRoute
                            path="/designVault/:listingId?"
                            component={DesignVault}
                        />
                        <SecureRoute path="/feedback" component={CannyWidget} />
                        <SecureRoute
                            path="/listing/:listingId/marketing"
                            component={ListingMarketingList}
                        />
                        <Route
                            path="/ListingMarketingReport/:listingId?"
                            component={CmrReport}
                        />
                        <SecureRoute
                            path="/listingSyndication/:listingId?"
                            component={ListingSyndication}
                        />
                        <SecureRoute path="/mlsemails" component={MlsEmails} />
                        <SecureRoute path="/reportBuilder/:listingId?">
                            <Cmr />
                        </SecureRoute>
                        <SecureRoute
                            path="/scheduler/:listingId?"
                            component={Scheduler}
                        />
                        <SecureRoute path="/showingsUpload" component={ShowingsUpload} />
                        <Route path="/photographer/login" component={LogIn} />
                        <Route path="/photographer/upload" component={Upload} />
                        <Route path="/photographer/register" component={SignUp} />
                        <Route
                            path="/photographer/forgotpassword"
                            component={ForgotPassword}
                        />
                        <Route
                            path="/photographer/resetpassword"
                            component={ResetPassword}
                        />
                        <Route path="/uxLogin/login" component={UxLogin} />
                        <Route
                            path="/showcase/approveAccessCode"
                            component={ApproveAccessCode}
                        />
                        <SecureRoute path="/eng">
                            <Section />
                        </SecureRoute>
                        <Route path="/support" component={Support} />
                    </Switch>
                    {!hideBar ? <FloatingButton /> : <></>}
                    <MessageToast />
                </Container>
            </Suspense>
        </Security>
    );
};

export default App;
