import { DataFlowResponse } from '../../../../../../utils/api/apiModels';
import {
    deleteCallWithResponse,
    postCall,
    putCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import { ListingAgent } from '../../../listingModels';

export function toggleSelectSale(
    listingId: string,
    agentId: string,
    selectSale: boolean,
): Promise<Response> {
    return putCallRaw(
        `listing/${listingId}/agent/${agentId}/toggleselectsale?toggleStatus=${selectSale}`,
        {},
    );
}
export function updateToggleSwitch(
    id: string,
    fieldName: string,
    status: boolean,
): Promise<Response> {
    const generateUrl = getToggleSwitchField(id, fieldName);
    const requestJson = {
        toggleStatus: status,
    };
    return putCallRaw(generateUrl, requestJson);
}
function getToggleSwitchField(id: string, fieldValue: string) {
    switch (fieldValue) {
        case 'soldPrice':
            return `listing/${id}/toggleshowsoldpriceforselectsold`;
        default:
            return '';
    }
}
export function addNewAgent(id: string, agentData: ListingAgent): Promise<ListingAgent> {
    return postCall<ListingAgent, ListingAgent>(`listing/${id}/agent`, agentData);
}
export function updateAgentDetails(
    id: string,
    agentData: ListingAgent,
): Promise<Response> {
    return putCallRaw<ListingAgent>(`listing/${id}/agent`, agentData);
}
export function updateOrderedAgentDetails(
    id: string,
    agents: ListingAgent[],
): Promise<Response> {
    return putCallRaw<ListingAgent[]>(`listing/${id}/reorderagents`, agents);
}
export function deleteAgentDetails(
    id: string,
    agentData: ListingAgent,
): Promise<DataFlowResponse> {
    return deleteCallWithResponse<DataFlowResponse>(
        `listing/${id}/agent/${agentData.agentId}/${agentData.sideTypeId}`,
    );
}
export function toggleSuppressAgent(id: string): Promise<Response> {
    return putCallRaw(`listing/${id}/togglesuppressmlsagent`, {});
}
