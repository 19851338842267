import { FunctionComponent } from 'react';
import React, { Redirect, Route, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
    isAuthorized: boolean;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
    isAuthorized,
    ...props
}) => {
    if (isAuthorized) {
        return <Route {...props} />;
    }
    return <Redirect to="/" />;
};

export default ProtectedRoute;
