import { getCall, postCall, putCall } from '../../../utils/api/apiWrapper';
import {
    AdditionalMls,
    AdditionalMlsValue,
    OfficeMls,
} from '../../models/additionalMls/additionalMlsModels';

export function fetchAdditionalMls(id: string): Promise<AdditionalMls[]> {
    return getCall<AdditionalMls[]>(`office/${id}/additionalmls`);
}

export function fetchMlsValues(
    propertyId: string,
    id: string,
): Promise<AdditionalMlsValue[]> {
    return getCall<AdditionalMlsValue[]>(`${propertyId}/${id}/officeAdditionalMls`);
}

export function updateMlsData(
    request: AdditionalMls[],
    propertyId: string,
    id: string,
    profileId?: string,
): Promise<AdditionalMlsValue[]> {
    return postCall<AdditionalMls[], AdditionalMlsValue[]>(
        `${propertyId}/${id}/officeAdditionalMls${
            profileId ? '?profileId=' + profileId : ''
        }`,
        request,
    );
}

export function addMls(officeMls: OfficeMls): Promise<string> {
    return postCall<OfficeMls, string>(`office/additionalmls`, officeMls);
}

export function updateMls(officeMls: AdditionalMls): Promise<AdditionalMls> {
    return putCall<AdditionalMls, AdditionalMls>(
        `office/${officeMls.officeAdditionalMlsId}/additionalmls`,
        officeMls,
    );
}
