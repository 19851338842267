export const panelDetails = {
    viewsOnWeb: {
        id: 1,
    },
    inTheNews: {
        id: 2,
    },
    advertisements: {
        id: 3,
    },
    showings: {
        id: 4,
    },
    openHouses: {
        id: 5,
    },
    emailCampaigns: {
        id: 6,
    },
    socialCampaigns: {
        id: 7,
    },
    featuredPlacements: {
        id: 8,
    },
    additionalPromotion: {
        id: 9,
    },
    notes: {
        id: 10,
    },
    graph: {
        id: 11,
    },
};

export const basedOnSectionId = (
    sectionId: number,
): { panelStateName: string; urlName: string } => {
    switch (sectionId) {
        case panelDetails.viewsOnWeb.id:
            return {
                panelStateName: 'viewsOnWeb',
                urlName: 'viewsonweb',
            };
        case panelDetails.inTheNews.id:
            return {
                panelStateName: 'inTheNews',
                urlName: 'inthenews',
            };
        case panelDetails.advertisements.id:
            return {
                panelStateName: 'advertisements',
                urlName: 'advertisements',
            };
        case panelDetails.showings.id:
            return {
                panelStateName: 'showings',
                urlName: 'showings',
            };
        case panelDetails.openHouses.id:
            return {
                panelStateName: 'openHouses',
                urlName: 'openhouses',
            };
        case panelDetails.emailCampaigns.id:
            return {
                panelStateName: 'emailCampaigns',
                urlName: 'emailcampaigns',
            };
        case panelDetails.socialCampaigns.id:
            return {
                panelStateName: 'socialCampaigns',
                urlName: 'socialcampaigns',
            };
        case panelDetails.featuredPlacements.id:
            return {
                panelStateName: 'featuredPlacements',
                urlName: 'featuredplacements',
            };
        case panelDetails.additionalPromotion.id:
            return {
                panelStateName: 'additionalPromotion',
                urlName: 'additionalpromotion',
            };
        case panelDetails.notes.id:
            return {
                panelStateName: 'notes',
                urlName: 'introduction',
            };
        default:
            return {
                panelStateName: 'viewsOnWeb',
                urlName: 'viewsonweb',
            };
    }
};
