import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditHistoryState, HistoryPayload } from '../../models/audit/auditModels';
import { AppThunk, RootState } from '../../../app/store';
import Logger from '../../../utils/logging/logger';
import { setError } from '../messaging/messagingSlice';
import { getHistoryData } from '../../api/audit/auditHistoryApi';

const initialState: AuditHistoryState = {
    auditHistory: {
        data: [],
        reachedEnd: false,
    },
};

export const auditHistorySlice = createSlice({
    name: 'auditHistory',
    initialState: initialState,
    reducers: {
        updateLoadingStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHistoryData: (state, action: PayloadAction<HistoryPayload>) => {
            state.auditHistory.data =
                action.payload.pageNo === 1
                    ? action.payload.history
                    : [...state.auditHistory.data, ...action.payload.history];
        },
        setReachEnd: (state, action: PayloadAction<boolean>) => {
            state.auditHistory.reachedEnd = action.payload;
        },
        resetAuditState: (state) => {
            return {
                ...state,
                auditHistory: {
                    data: [],
                    reachedEnd: false,
                },
            };
        },
    },
});

export const getHistory =
    (entity: string, id: string, pageNo: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingStatus(true));
            const pageSize = 100;
            const response = await getHistoryData(entity, id, pageNo, pageSize);
            if (response.length) {
                const historyPayload = {
                    history: response,
                    pageNo: pageNo,
                };

                dispatch(setHistoryData(historyPayload));
            } else {
                dispatch(setReachEnd(true));
            }
        } catch {
            Logger.error('Error in getting audit history data');
            dispatch(setError('Error in getting audit history data'));
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const { updateLoadingStatus, setHistoryData, setReachEnd, resetAuditState } =
    auditHistorySlice.actions;
export const auditHistory = (state: RootState): AuditHistoryState => state.auditHistory;

export default auditHistorySlice.reducer;
