import React, { FunctionComponent } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { loaderDetails } from '../../slices/loader/loaderSlice';
import LinearProgress from '@material-ui/core/LinearProgress';
import './componentLoader.scss';

export const CustomLinearProgress: FunctionComponent = () => {
    const details = useAppSelector(loaderDetails);
    const renderLoader = () => {
        return (
            <LinearProgress
                classes={{
                    colorPrimary: 'color-primary',
                    barColorPrimary: 'bar-color-primary',
                }}
            />
        );
    };

    const showMessages = details.loadingEvents.length > 0;
    return showMessages ? renderLoader() : null;
};
