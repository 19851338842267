import {
    EmailCampaignData,
    MarketingData,
    SocialCampaignData,
    OtherMarketing,
} from '../../../../shared/models/marketing/marketingModels';
import {
    deleteCall,
    getCall,
    postCallRaw,
    postFormData,
} from '../../../../utils/api/apiWrapper';
import { PropertyMarketingList } from './propertyMarketingListModels';

export const getPropertyMarketingListApi = (
    listingId: string,
): Promise<PropertyMarketingList> => {
    return getCall<PropertyMarketingList>(`listing/${listingId}/marketinglist`);
};

export const getPropertyMarketingApi = (marketingId: string): Promise<MarketingData> => {
    return getCall<MarketingData>(`marketing/${marketingId}`);
};

export const getPropertyMarketingEmailCampaign = (
    emailCampaignId: string,
): Promise<EmailCampaignData> => {
    return getCall<EmailCampaignData>(`emailcampaign/${emailCampaignId}`);
};

export const getPropertyMarketingSocialCampaign = (
    socialCampaignId: string,
): Promise<SocialCampaignData> => {
    return getCall<SocialCampaignData>(`socialcampaign/${socialCampaignId}`);
};

export const deletePropertyMarketingApi = (
    listingId: string,
    marketingId: string,
): Promise<Response> => {
    return deleteCall(`listing/${listingId}/marketing/${marketingId}`);
};

export function savePropertyMarketingApi(data: FormData): Promise<OtherMarketing> {
    return postFormData<OtherMarketing>('marketing', data);
}

export function exportMarketingReportsApi(listingId: string): Promise<Response> {
    return postCallRaw<string>(`listing/${listingId}/advMarketingExport`, listingId);
}

export function updateOtherMarketingApi(data: FormData): Promise<OtherMarketing> {
    return postFormData<OtherMarketing>('marketing', data);
}
