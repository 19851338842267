import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { getAgentsByLastName } from '../../shared/api/agent/agentApi';
import { AgentSearchResult } from '../../shared/models/agent/agentModel';
import { ListingSearchRequest } from '../../shared/models/listing/commonModel';
import {
    LookupInfo,
    OtherMarketingLookups,
} from '../../shared/models/lookups/lookupsModels';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';

import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import Logger from '../../utils/logging/logger';
import { loadUserPermissions } from '../listings/advancedSearch/advancedSearchUtils';
import {
    deleteOtherMarketingApi,
    getListingListApi,
    getListingAgentListApi,
    getOtherMarketingListApi,
    saveOtherMarketingApi,
} from './otherMarketingApi';
import { initialOtherMarketingSearch } from './otherMarketingConstant';
import {
    ListingSearchResultsByField,
    OtherMarketingListModel,
    OtherMarketingSearchRequest,
    OtherMarketingState,
    OtherMarketingTableActionType,
    SearchedListingAgentModel,
} from './otherMarketingModel';
import { generateData, getMarketingName, isFormDataChanged } from './otherMarketingUtils';
import { OtherMarketing } from '../../shared/models/marketing/marketingModels';
import { getOtherMarketing } from '../../shared/api/marketing/marketingApis';
import { orderBy } from 'lodash';

const otherMarketingState: OtherMarketingState = {
    isLoading: false,
    marketingTableAction: initialOtherMarketingSearch,
    otherMarketingList: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    searchedAgents: [],
    searchedListings: {
        rfgId: [],
        mlsNo: [],
        address1: [],
    },
    searchedListingAgentList: [],
    individualOtherMarketing: undefined,
    searchLoader: false,
    isSearched: false,
};

export const otherMarketingListSlice = createSlice({
    name: 'otherMarketing',
    initialState: otherMarketingState,
    reducers: {
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setSearchLoader: (state, action: PayloadAction<boolean>) => {
            state.searchLoader = action.payload;
        },
        updateTableAction: (
            state,
            action: PayloadAction<OtherMarketingTableActionType>,
        ) => {
            return {
                ...state,
                marketingTableAction: {
                    ...state.marketingTableAction,
                    ...action.payload,
                },
            };
        },
        upsertOtherMarketingList: (
            state,
            action: PayloadAction<{
                data: PaginationResponse<OtherMarketingListModel>;
                from: string;
            }>,
        ) => {
            const results = [
                ...state.otherMarketingList.results,
                ...action.payload.data.results,
            ];
            let otherMarketingList = {
                ...state.otherMarketingList,
                currentPage: action.payload.data.currentPage,
                totalRecords: action.payload.data.totalRecords,
                recordsPerPage: action.payload.data.recordsPerPage,
            };
            if (
                state.otherMarketingList.results.length !== 0 ||
                action.payload.data.results.length !== 0
            ) {
                otherMarketingList = {
                    ...otherMarketingList,
                    results:
                        state.otherMarketingList.currentPage >=
                        action.payload.data.currentPage
                            ? action.payload.data.results
                            : results,
                };
            }
            return {
                ...state,
                otherMarketingList: otherMarketingList,
            };
        },
        setAgent: (state, action: PayloadAction<AgentSearchResult[]>) => {
            return {
                ...state,
                searchedAgents: action.payload,
            };
        },
        setListing: (state, action: PayloadAction<ListingSearchResultsByField>) => {
            return {
                ...state,
                searchedListings: {
                    ...state.searchedListings,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        setListingAgentList: (
            state,
            action: PayloadAction<SearchedListingAgentModel[]>,
        ) => {
            return {
                ...state,
                searchedListingAgentList: action.payload,
            };
        },
        setIndividualOtherMarketing: (
            state,
            action: PayloadAction<OtherMarketing | undefined>,
        ) => {
            return {
                ...state,
                individualOtherMarketing: action.payload,
            };
        },
        deleteOtherMarketing: (state, action: PayloadAction<string>) => {
            const updatedOMList: OtherMarketingListModel[] =
                state.otherMarketingList.results.filter((x) => x.id !== action.payload);
            return {
                ...state,
                otherMarketingList: {
                    ...state.otherMarketingList,
                    totalRecords: state.otherMarketingList.totalRecords - 1,
                    results: [...updatedOMList],
                },
            };
        },
        updateOMList: function (
            state,
            action: PayloadAction<{
                data: OtherMarketing;
                marketingTypelookup: LookupInfo[];
                from: string;
                toUpdateMainList: boolean;
            }>,
        ) {
            const { data, marketingTypelookup, from, toUpdateMainList } = action.payload;
            let dataToUpdate: OtherMarketingListModel[] =
                state.otherMarketingList.results;
            const dataToSave: OtherMarketingListModel = {
                id: data.id ? data.id : '',
                date: data.date,
                marketingType: getMarketingName(
                    data.marketingTypeId.toString(),
                    marketingTypelookup,
                ),
                description: data.description,
                createdBy: data.createdBy ? data.createdBy : '',
                lastUpdatedBy: '',
                mediaLink: data.url,
                published: data.publish,
            };
            if (from === 'edit') {
                dataToSave.lastUpdatedBy = data.createdBy ? data.createdBy : '';
                const findIndex = state.otherMarketingList.results.findIndex(
                    (x) => x.id === data.id,
                );
                dataToUpdate[findIndex] = { ...dataToSave };
                state.otherMarketingList.results = dataToUpdate;
            } else {
                if (toUpdateMainList) {
                    dataToUpdate = [dataToSave, ...dataToUpdate];
                    return {
                        ...state,
                        otherMarketingList: {
                            ...state.otherMarketingList,
                            totalRecords: state.otherMarketingList.totalRecords + 1,
                            results: [...dataToUpdate],
                        },
                    };
                }
            }
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSearched: action.payload,
            };
        },
    },
});

/**
 * function that fetched the full OM list
 * @param data
 * @returns
 */
export const getOtherMarketingList =
    (data: OtherMarketingSearchRequest, calledFrom?: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const userPermission = loadUserPermissions();
            //remove publish key if it is false
            if (!data.publish) {
                delete data.publish;
            }
            const response = await getOtherMarketingListApi({
                ...data,
                ...userPermission,
            });
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            )
                dispatch(
                    upsertOtherMarketingList({
                        data: response,
                        from: calledFrom ? calledFrom : '',
                    }),
                );
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the Other Marketing list`));
            Logger.error(
                `Failed to retrieve the Other Marketing list ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

/**
 * function fetched agent based on the agent name entered in autocomplete
 * @param data
 * @returns
 */
export const fetchAgent =
    (data: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getAgentsByLastName(data);
            if (response.length > 0) {
                dispatch(setAgent(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the agent list`));
            Logger.error(`Failed to fetch the agent list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };
/**
 * function will get the listing list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchListing =
    (listingSearchRequest: ListingSearchRequest, field: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getListingListApi(listingSearchRequest);
            if (response.results.length > 0) {
                dispatch(
                    setListing({
                        key: field,
                        value: orderBy(response.results, 'status', 'asc'),
                    }),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the listing list`));
            Logger.error(`Failed to fetch the listing list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * function will call the agent api based on the listing that is selected
 * @param listingId
 * @returns
 */
export const fetchListingAgent =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getListingAgentListApi(listingId);
            if (response.length > 0) {
                dispatch(setListingAgentList(response));
            } else {
                dispatch(setError(`No Agent associated with the listing`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the selected listings agent list`));
            Logger.error(
                `Failed to fetch the selected listings agent list ${JSON.stringify(
                    exception,
                )}`,
            );
        }
    };

/**
 * function is used to save/update the OM details
 * @param data
 * @param isEdit
 * @returns
 */
export const saveOtherMarketing =
    (data: OtherMarketing, isEdit: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setProgress(true));
            const formData = generateData(data);

            const response = await saveOtherMarketingApi(formData);
            if (response.id) {
                dispatch(setProgress(false));
                dispatch(setListingAgentList([]));
                const { marketingTypes } = getState().lookups
                    .otherMarketingLook as OtherMarketingLookups;
                const { publish } =
                    getState().otherMarketing.otherMarketingList.marketingTableAction;
                if (isEdit) {
                    dispatch(
                        updateOMList({
                            data: response,
                            marketingTypelookup: marketingTypes,
                            from: 'edit',
                            toUpdateMainList: true,
                        }),
                    );
                    dispatch(setIndividualOtherMarketing(undefined));
                    dispatch(setSuccess(`Successfully updated the Other Marketing`));
                } else {
                    //update to main list based on the search criteria
                    // ie if published is search and OM is created with published options
                    // update in main list and vice versa for the publish also
                    let toUpdateMainList = true;
                    if (publish && !response.publish) {
                        toUpdateMainList = false;
                    } else if (!publish && response.publish) {
                        toUpdateMainList = false;
                    }
                    dispatch(
                        updateOMList({
                            data: response,
                            marketingTypelookup: marketingTypes,
                            from: 'create',
                            toUpdateMainList: toUpdateMainList,
                        }),
                    );
                    dispatch(setSuccess(`Successfully saved the Other Marketing`));
                }
            }
        } catch (exception) {
            dispatch(
                setError(`Failed to ${!isEdit ? 'save' : 'update'} the other marketing`),
            );
            Logger.error(
                `Failed to ${
                    !isEdit ? 'save' : 'update'
                } the other marketing ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };
/**
 * function to delete the OM
 * @param id of OM ot be deleted
 * @returns
 */
export const deleteOtherMarketingFromList =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteOtherMarketingApi(id);
            if (response.status === 204) {
                dispatch(setSuccess(`Other Marketing deleted successfully`));
                dispatch(deleteOtherMarketing(id));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete the selected Other Marketing`));
            Logger.error(
                `Failed to delete fthe selected Other Marketing ${JSON.stringify(
                    exception,
                )}`,
            );
        }
    };
/**
 * fetches the individual Other marketing data
 * @param id id of OM
 * @returns
 */

export const getIndividualOM =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getOtherMarketing(id);
            if (response.id) {
                const data = {
                    ...response,
                    marketingTypeId: response.marketingTypeId.toString(),
                };
                dispatch(setIndividualOtherMarketing(data));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch other marketing details`));
            Logger.error(
                `Failed to fetch other marketing details ${JSON.stringify(exception)}`,
            );
        }
    };

/**
 *  this function is used to determine the save/update call to make and for update it also
 * validates the difference and then calls the data
 * @param formData - data to save / update
 * @param from - notifies from where the fn is called (create/update)
 * @returns
 */
export const saveOMFormData =
    (formData: OtherMarketing, from: string): AppThunk =>
    async (dispatch, getState) => {
        const oMDetails =
            getState().otherMarketing.otherMarketingList.individualOtherMarketing;
        if (oMDetails && !isFormDataChanged(formData, oMDetails) && from === 'update') {
            const amcAdded = { ...formData, isAmcChanged: true };
            dispatch(saveOtherMarketing(amcAdded, true));
        } else {
            dispatch(setIndividualOtherMarketing(undefined));
        }
        if (from === 'create') {
            dispatch(saveOtherMarketing(formData, false));
        }
    };

export const {
    setProgress,
    updateTableAction,
    upsertOtherMarketingList,
    setAgent,
    setListing,
    setListingAgentList,
    deleteOtherMarketing,
    setIndividualOtherMarketing,
    updateOMList,
    setSearchLoader,
    setIsSearched,
} = otherMarketingListSlice.actions;

export const otherMarketingListState = (state: RootState): OtherMarketingState =>
    state.otherMarketing.otherMarketingList;

export default otherMarketingListSlice.reducer;
