import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import { searchUsers, activeUsers } from '../../shared/api/users/usersApi';
import { UserTableActionType, UserListState, UpdateSingleUser } from './userListModels';
import {
    UserSearchRequest,
    UserSearchResponse,
} from '../../shared/models/users/userModels';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { returnFile } from '../../utils/api/fileResults';

export const initialUserSearch: UserSearchRequest = {
    sortColumn: 'LastName',
    sortDirection: 'asc',
    currentPage: 1,
    firstName: '',
    lastName: '',
    statusId: 1,
    isAgent: false,
    email: '',
    itemsPerPage: 20,
};

const usersListState: UserListState = {
    tableAction: initialUserSearch,
    data: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    isLoading: false,
    isSearched: false,
};

export const userListSlice = createSlice({
    name: 'users',
    initialState: usersListState,
    reducers: {
        updateUsers: (
            state,
            action: PayloadAction<PaginationResponse<UserSearchResponse>>,
        ) => {
            const results = [...state.data.results, ...action.payload.results];
            let data = {
                ...state.data,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (state.data.results.length !== 0 || action.payload.results.length !== 0) {
                data = {
                    ...data,
                    results:
                        state.data.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                data: data,
            };
        },
        updateSingleUserDetailsFromList: (
            state,
            action: PayloadAction<UpdateSingleUser>,
        ) => {
            const updatedUsers = state.data.results.map((result) =>
                result.userId === action.payload.userId
                    ? { ...result, ...action.payload }
                    : { ...result },
            );
            return {
                ...state,
                data: { ...state.data, results: updatedUsers },
            };
        },
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        updateTableAction: (state, action: PayloadAction<UserTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        updateIsSearched: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSearched: action.payload,
            };
        },
    },
});

export const getUsers =
    (request: UserSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            //removed isAgent key when the value is false, since that key is not needed for backed
            //isAgent key need to be sent only is isAgent is true
            if (!request.isAgent) {
                delete request.isAgent;
            }
            const response = await searchUsers(request);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            )
                dispatch(updateUsers(response));
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the user list`));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const getActiveUsers = (): AppThunk => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        await returnFile(activeUsers());
    } catch (exception) {
        dispatch(setError(`Failed to retrieve active users`));
    } finally {
        dispatch(updateLoader(false));
    }
};

export const {
    updateUsers,
    updateSingleUserDetailsFromList,
    updateLoader,
    updateTableAction,
    updateIsSearched,
} = userListSlice.actions;

export const users = (state: RootState): UserListState => state.user.list;

export default userListSlice.reducer;
