import Grid from '@material-ui/core/Grid/Grid';
import { FunctionComponent } from 'react';
import { useFormData } from '../../../../../app/hooks';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { initialAgentSelectSaleSearch } from '../../agentConstants';
import { AgentSearchSelect } from '../../agentModels';

const SearchSelectSale: FunctionComponent<{
    searchData: AgentSearchSelect;
    onSearch(data: AgentSearchSelect, isCleared?: boolean): void;
}> = ({ searchData, onSearch }) => {
    const {
        formData,
        setFormData,
        handleTextChange,
        handleCheckboxChange,
        handleNumberChange,
    } = useFormData(searchData);

    /**
     * to trigger search based on enter
     */
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            if (
                formData.address1 !== '' ||
                formData.rfgid !== '' ||
                formData.soldPrice !== 0 ||
                formData.mlsNumber !== ''
            ) {
                search();
            } else {
                onSearch(initialAgentSelectSaleSearch);
            }
        }
    };

    /**
     * to perform listing search based on the entered information
     */
    const search = () => {
        if (formData.address1 !== '') {
            const newFormData = {
                ...formData,
                address1: `"${formData.address1}"`,
            };
            onSearch(newFormData);
        } else {
            onSearch(formData);
        }
    };

    /**
     * to clear the entered search information
     */
    const clear = () => {
        onSearch({ ...initialAgentSelectSaleSearch }, true);
        setFormData({ ...initialAgentSelectSaleSearch });
    };

    return (
        <Grid className="select-sales-search-container">
            <Grid
                container
                className={'select-sales-search'}
                spacing={2}
                alignItems="flex-end"
            >
                <Grid item xs={12} md={3} sm={6}>
                    <FormRow
                        label="Address"
                        labelMd={12}
                        controlMd={12}
                        control={
                            <TextField
                                value={formData.address1 || ''}
                                name="address1"
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                }}
                                onChange={handleTextChange}
                                onKeyDown={(e) => handleKeyPress(e)}
                            />
                        }
                        controlClass="formControlRoot"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormRow
                        label="RFGID"
                        labelMd={12}
                        controlMd={12}
                        control={
                            <TextField
                                value={formData.rfgid || ''}
                                name="rfgid"
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                }}
                                onChange={handleTextChange}
                                onKeyDown={(e) => handleKeyPress(e)}
                            />
                        }
                        controlClass="formControlRoot"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormRow
                        label="MLS Number"
                        labelMd={12}
                        controlMd={12}
                        control={
                            <TextField
                                value={formData.mlsNumber || ''}
                                name="mlsNumber"
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                }}
                                onChange={handleTextChange}
                                onKeyDown={(e) => handleKeyPress(e)}
                            />
                        }
                        controlClass="formControlRoot"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormRow
                        label="Sold Price"
                        labelMd={12}
                        controlMd={12}
                        control={
                            <TextField
                                value={formData.soldPrice || ''}
                                name="soldPrice"
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                }}
                                onChange={handleNumberChange}
                                onKeyDown={(e) => handleKeyPress(e)}
                            />
                        }
                        controlClass="formControlRoot"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Rental',
                                }}
                                checked={formData.published}
                                onChange={handleCheckboxChange}
                                name="published"
                                id="published"
                            />
                        }
                        label={<div className={'checkboxLabel'}>Published Only</div>}
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Rental',
                                }}
                                checked={formData.isValid}
                                onChange={handleCheckboxChange}
                                name="isValid"
                                id="isValid"
                            />
                        }
                        label={<div className={'checkboxLabel'}>Data Complete Only</div>}
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Rental',
                                }}
                                checked={formData.isSelectSale}
                                onChange={handleCheckboxChange}
                                name="isSelectSale"
                                id="isSelectSale"
                            />
                        }
                        label={<div className={'checkboxLabel'}>Select Sale Only</div>}
                        labelPlacement="end"
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    spacing={2}
                    justifyContent="flex-end"
                >
                    <button
                        className="customButton space-between"
                        onClick={search}
                        // disabled={flags.isSearching}
                    >
                        Search
                    </button>
                    <button className="customButton space-between" onClick={clear}>
                        Clear
                    </button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SearchSelectSale;
