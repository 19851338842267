import { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import './index.scss';
import { schedulerDetails, fetchSendAudit } from '../schedulerSlice';
import { cmrDetails } from '../../../cmrSlice';
import { SentEmails } from '../schedulerModels';
import { dateFormatMMDDYYY } from '../../../../../utils/urlUtils';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';

const SentEmailsCom: FunctionComponent = () => {
    const { viewEmails } = useAppSelector(schedulerDetails);
    const { listingId } = useAppSelector(cmrDetails);
    const dispatch = useAppDispatch();
    const [action, setAction] = useState(false);
    const fetchDetails = () => {
        googleAnalyticsAction('Email', 'Click', 'Email Report View Emails');
        const callfetch = async () => {
            await dispatch(fetchSendAudit(listingId));
            setAction(true);
        };
        callfetch();
    };

    return (
        <div className="emailSentHolder">
            <div className="emailSentHeader">
                <div className="emailSentHeaderText" onClick={fetchDetails}>
                    View Emails
                </div>
            </div>
            {viewEmails && viewEmails.length > 0 ? (
                <div className="emailSentContent">
                    <div className="emailSentTable">
                        <div className="tableHead">
                            <div className="section">RECIPIENT (S):</div>
                            <div className="section">EMAIL (S):</div>
                            <div className="section date">DATE</div>
                        </div>
                        <div className="tableBody">
                            {viewEmails.map((emails: SentEmails, index: number) => (
                                <div className="content" key={index}>
                                    <div className="section">
                                        {emails.recipientNames &&
                                        emails.recipientNames.trim().length > 0
                                            ? emails.recipientNames
                                            : ''}
                                    </div>
                                    <div className="section email">
                                        {emails.recipients &&
                                        emails.recipients.trim().length > 0
                                            ? emails.recipients
                                            : ''}
                                    </div>
                                    <div className="section date">
                                        {emails.sendDate &&
                                        dateFormatMMDDYYY(emails.sendDate.toString())
                                            ? dateFormatMMDDYYY(emails.sendDate)
                                            : ''}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>{action ? 'No Emails have been Sent' : ''}</div>
            )}
        </div>
    );
};

export default SentEmailsCom;
