import { getCall, deleteCall } from '../../utils/api/apiWrapper';
import { MarketingFields } from '../../shared/models/marketing/marketingModels';

/**
 * Gets list of marketing associated with the given listing ID
 * @param id ID of the listing to get marketing for
 * @returns List of marketing associated with the listing
 */
export function getListingMarketing(id: string): Promise<MarketingFields[]> {
    return getCall<MarketingFields[]>(`listing/${id}/marketing`);
}

/**
 * Gets list of marketing associated with the given agent ID
 * @param id ID of the agent to get marketing for
 * @returns List of marketing associated with the agent
 */
export function getAgentMarketing(id: string): Promise<MarketingFields[]> {
    return getCall<MarketingFields[]>(`agent/${id}/marketing`);
}

/**
 * Deletes a marketing item from an entity
 * @param entityName Name of entity type marketing is being deleted from
 * (listing or agent)
 * @param entityId ID of the entity to delete the marketing from (i.e.,
 * listing or agent ID)
 * @param marketingId ID of the marketing, email, or social campaign item being removed
 */
export function deleteEntityMarketing(
    entityName: string,
    entityId: string,
    marketingId: string,
): Promise<Response> {
    const url = `${entityName}/${entityId}/marketing/${marketingId}`;
    return deleteCall(url);
}
