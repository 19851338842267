export const parentPageConstants = [
    '/eng/listings',
    '/eng/marketing',
    '/eng/offices',
    '/eng/agents',
    '/eng/salesTeams',
    '/eng/users',
    '/eng/applications',
    '/eng/masquerade',
    '/eng/notifications',
    '/eng/home',
    '/eng/selectSoldsUpload',
    '/eng/reporting',
];
