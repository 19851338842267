import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { checkDomainAvailability } from '../showcaseWebsiteApi';
import './index.scss';
import { purchase, listingShowCase, checkEligible } from '../showcaseWebsiteSlice';
import { PurchaseRequest } from '../showcaseWebsiteModels';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks';
import { listingDetail } from '../../../../listingSlice';
import { getUser } from '../../../../../../../shared/auth/authService';
import {
    setError,
    setSuccess,
} from '../../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../../utils/logging/logger';
import { showCaseWebsiteStyle } from '../style';
import { listingStatuses } from '../../../../listingConstants';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        bottom: '-22px',
    },
}));

interface NewDomainModel {
    skipEligibilityStatusCheck?: boolean;
    handleAction?: (data: string, from: string) => void;
    isDomainChanged?: () => void;
}
const NewDomain: FunctionComponent<NewDomainModel> = ({
    skipEligibilityStatusCheck = false,
    handleAction,
    isDomainChanged,
}) => {
    const helperTextStyles = useHelperTextStyles();
    const dispatch = useAppDispatch();
    const [previousDomain, setPreviousDomain] = useState('');
    const [domain, setDomain] = useState('');
    const [isDomainAvailable, setIsDomainAvailable] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const listingInfo = useAppSelector(listingDetail);
    const listingId = listingInfo.data.id;
    const { officeId, listingStatusId } = listingInfo.data;
    const { showCaseData, eligibilityStatus } = useAppSelector(listingShowCase);
    const classes = showCaseWebsiteStyle();

    const purchaseData: PurchaseRequest = {
        ipAddress: '',
        domain: domain,
        createdBy: getUser().firstName,
        userEmail: getUser().email,
        entityId: listingId,
        siteType: 0,
        officeId: officeId || '',
        privateSite: listingStatusId === listingStatuses.private,
    };

    const handleDomain = (event: ChangeEvent<HTMLInputElement>) => {
        const domainName = event && event.target.value;
        const domainPattern =
            /^(https?:\/\/)?((?:[a-z0-9-]+\.)+(?:com|net|org|me|us|biz|online))(?:\/|$)/i;

        if (!domainName.match(domainPattern)) {
            setErrorState(true);
        } else {
            setErrorState(false);
            setDomain(cleanDomain(domainName));
            if (isDomainChanged) isDomainChanged();
        }
    };

    const cleanDomain = (domain: string) => {
        return domain ? domain.replace(/^(https?[://]*)?(www\.)?/, '') : domain;
    };

    const checkAvailability = async () => {
        try {
            const domainStatus = await checkDomainAvailability(domain);
            if (!domainStatus.available) {
                dispatch(setError('Domain is not available'));
                Logger.error('Domain is not available');
            } else {
                setIsDomainAvailable(true);
                if (!skipEligibilityStatusCheck) {
                    dispatch(setSuccess('Domain is available'));
                }
                setPreviousDomain(domain);
                if (handleAction) handleAction(domain, 'domain');
            }
        } catch {
            dispatch(setError('Error in checking domain availability'));
            Logger.error('Error in checking domain availability');
        }
    };

    const registerDomain = () => {
        dispatch(
            purchase(purchaseData, {
                ...showCaseData,
                privateSite: listingStatusId === listingStatuses.private,
            }),
        );
    };

    useEffect(() => {
        if (listingId) dispatch(checkEligible(listingId));
    }, [listingId, dispatch]);

    return (
        <Grid item xs={12} className="newDomainHolder">
            {eligibilityStatus || skipEligibilityStatusCheck ? (
                <>
                    {!skipEligibilityStatusCheck ? (
                        <>
                            <Typography variant="subtitle2" className={classes.status}>
                                Refresh to check for status updates. Even after domain is
                                ready, website may take up to 24 hours to be accessible
                                from the internet.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.requiredFields}
                            >
                                * Indicates Required Fields
                            </Typography>{' '}
                        </>
                    ) : (
                        ''
                    )}

                    <Grid
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} sm={12} md={3} className="domainHeader">
                            {skipEligibilityStatusCheck ? 'Showcase Domain' : 'DOMAIN'}
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            <TextField
                                className="full-width"
                                onChange={handleDomain}
                                size="small"
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputFields',
                                }}
                                error={errorState}
                                defaultValue=""
                                helperText={errorState ? 'Not a valid domain' : ''}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            {eligibilityStatus || skipEligibilityStatusCheck ? (
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    justifyContent="flex-end"
                    className="domainAction"
                >
                    {isDomainAvailable &&
                    !errorState &&
                    domain === previousDomain &&
                    !skipEligibilityStatusCheck ? (
                        <button className="customButton" onClick={registerDomain}>
                            Register
                        </button>
                    ) : (
                        <button className="customButton" onClick={checkAvailability}>
                            Check Availability
                        </button>
                    )}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};
export default NewDomain;
