import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mlsEmailsReducer from '../features/mlsemails/mlsEmailsSlice';
import messagingReducer from '../shared/slices/messaging/messagingSlice';
import loaderReducer from '../shared/slices/loader/loaderSlice';
import listingReducer from '../features/listingSyndication/listingSyndicationSlice';
import templateReducer from '../features/designVault/designVaultSlice';
import CMRRootReducer from './reducers/cmrReducers';
import marketingReducer from '../features/marketing/marketingSlice';
import NavigationReducer from '../shared/slices/navigation/navigationSlice';
import ListingReducer from './reducers/listingReducers';
import OfficeReducer from './reducers/officeReducers';
import AgentReducer from './reducers/agentReducers';
import ApplicationsReducer from '../features/applications/applicationsSlice';
import SalesTeamReducer from './reducers/salesTeamReducers';
import LookupsReducer from '../shared/slices/lookups/lookupsSlice';
import HomePageReducer from '../features/homepage/homePageSlice';
import UserReducers from './reducers/userReducers';
import MyBusinessReducer from '../features/myBusiness/myBusinessSlice';
import AdditionalMlsReducer from '../shared/slices/additionalMls/additionalMlsSlice';
import GlobalSearchReducer from '../shared/slices/globalSearch/globalSearchSlice';
import AdvertisementReducer from './reducers/advertisementReducers';
import ExcelUploadReducer from '../features/excelUpload/excelUploadSlice';
import AuditHistoryReducer from '../shared/slices/audit/auditHistorySlice';
import NotificationReducer from '../features/notifications/manage/manageNotificationsSlice';
import OtherMarketingReducer from './reducers/otherMarketingReducers';
import DevelopmentReducer from './reducers/developmentReducers';
import SpotBookingReducer from '../shared/slices/spotBooking/spotBookingSlice';
import PhotographerListingsReducer from '../features/photographer/listingsList/listingsListSlice';
import ListingDraftReducer from '../features/listings/newListing/listingDraftSlice';
import MasqueradeReducer from '../features/masquerade/masqueradeSlice';
import MediaOutletReducer from '../shared/slices/mediaOutlet/mediaOutletSlice';
import ReportingReducer from '../features/reporting/reportingSlice';

export const store = configureStore({
    reducer: {
        applications: ApplicationsReducer,
        agent: AgentReducer,
        cmr: CMRRootReducer,
        designVaultTemplate: templateReducer,
        listing: ListingReducer,
        listingSyndication: listingReducer,
        loader: loaderReducer,
        lookups: LookupsReducer,
        marketing: marketingReducer,
        messaging: messagingReducer,
        mlsEmails: mlsEmailsReducer,
        navigation: NavigationReducer,
        office: OfficeReducer,
        salesTeam: SalesTeamReducer,
        homePage: HomePageReducer,
        user: UserReducers,
        myBusiness: MyBusinessReducer,
        additionalMls: AdditionalMlsReducer,
        globalSearch: GlobalSearchReducer,
        advertisement: AdvertisementReducer,
        excelUpload: ExcelUploadReducer,
        auditHistory: AuditHistoryReducer,
        notifications: NotificationReducer,
        otherMarketing: OtherMarketingReducer,
        development: DevelopmentReducer,
        spotBooking: SpotBookingReducer,
        photographerListings: PhotographerListingsReducer,
        listingDraft: ListingDraftReducer,
        masquerade: MasqueradeReducer,
        mediaOutlet: MediaOutletReducer,
        reporting: ReportingReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
