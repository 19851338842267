import { OfficeSearch, OfficesResponse } from '../../models/office/officeModel';
import { PaginationResponse } from '../../models/pagination/paginationModels';
import { postCall } from '../../../utils/api/apiWrapper';

export function getOfficesFromApi(
    officesRequest: OfficeSearch,
): Promise<PaginationResponse<OfficesResponse>> {
    return postCall<OfficeSearch, PaginationResponse<OfficesResponse>>(
        `office/search`,
        officesRequest,
    );
}
