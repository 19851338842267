import { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../officeModels';
import { useFormData, useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { findIdFromLookups } from '../../../../../../utils/urlUtils';
import { ManagerData } from './managerModels';
import { addManager, removeManager } from './managerSlice';
import './index.scss';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import { OfficeLookups } from '../../../../../../shared/models/lookups/lookupsModels';

import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';

const Managers: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const {
        data: { officeDetails },
    } = editFormData;
    const dispatch = useAppDispatch();
    const { officeLook } = useAppSelector(lookups);
    const offices = officeLook as OfficeLookups;
    const initialManagerData: ManagerData = {
        agentId: findIdFromLookups(officeDetails.managerName, offices.availableManagers),
        title: officeDetails.managerTitle ? officeDetails.managerTitle : 'Manager',
        managerName: officeDetails.managerName ? officeDetails.managerName : '',
    };

    const { formData, setFormData } = useFormData(initialManagerData);

    const handleDropDownChange = (data: LookupInfo, name: string) => {
        setFormData({
            ...formData,
            agentId: data.id,
            managerName: data.name,
        });
    };

    const saveHandler = () => {
        dispatch(addManager(officeDetails?.id || '', formData));
    };

    const deleteHandler = () => {
        dispatch(removeManager(officeDetails?.id || ''));
    };

    return (
        <div className="managerContainer">
            <Grid
                item
                container
                className="content"
                alignItems="center"
                spacing={2}
                md={12}
            >
                <FormRow
                    label="MANAGER"
                    control={
                        <DropDownSearch
                            id={formData.agentId}
                            items={offices.availableManagers}
                            name="agentId"
                            handleDropDownChange={handleDropDownChange}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={8}
                />
                <Grid item container justifyContent="flex-end" md={10}>
                    <button className="button customButton" onClick={saveHandler}>
                        Assign Manager
                    </button>
                </Grid>
            </Grid>
            {officeDetails.managerName?.length ? (
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item container md={12} className="formControlLabel">
                        <Grid item md={4} sm={4}>
                            Agent Name
                        </Grid>
                        <Grid item md={4} sm={4}>
                            Office Name
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        md={12}
                        className="formControlData"
                        alignItems="center"
                    >
                        <Grid item md={4} sm={4}>
                            {officeDetails.managerName}
                        </Grid>
                        <Grid item md={4} sm={4}>
                            {officeDetails.officeName}
                        </Grid>
                        <Grid item md={4}>
                            <Grid className="deleteHolder">
                                <button
                                    className="button customButton"
                                    onClick={deleteHandler}
                                >
                                    delete
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </div>
    );
};

export default Managers;
