import { entityTypes } from '../entityTypes';
import { initializeVideoData } from '../../functions/video/video';
import { VideoAction, VideoModel } from '../../models/video/videoModels';

export const videoTypes = {
    propertyVideo: {
        key: 1,
        value: 'PROPERTY_VIDEO',
        referenceId: 'sir_listings',
        index: 0,
    },
    secondVideo: {
        key: 2,
        value: 'FIFTEEN_SECOND_VIDEO',
        referenceId: '15_second_property_clip',
        index: 1,
    },
    showcaseVideo: {
        key: 3,
        value: 'SHOWCASE_WEBSITE_HERO_VIDEO',
        referenceId: 'sir_showcase',
        index: 2,
    },
    additionalVideoOne: {
        key: 4,
        value: 'ADDITIONAL_VIDEOS',
        referenceId: 'sir_listings_additional',
        index: 3,
    },
    additionalVideoTwo: {
        key: 4,
        value: 'ADDITIONAL_VIDEOS',
        referenceId: 'sir_listings_additional',
        index: 4,
    },
    additionalVideoThree: {
        key: 4,
        value: 'ADDITIONAL_VIDEOS',
        referenceId: 'sir_listings_additional',
        index: 5,
    },
    agentVideo: {
        key: 5,
        value: 'AGENT_VIDEOS',
        referenceId: 'sir_agents',
        index: 6,
    },
    salesTeamVideo: {
        key: 6,
        value: 'TEAM_VIDEOS',
        referenceId: 'sir_teams',
        index: 7,
    },
    buildingVideo: {
        key: 7,
        value: 'BUILDING_VIDEOS',
        referenceId: 'sir_buildings',
        index: 8,
    },
};

export const videoFields = {
    title: 'Title',
    description: 'Description',
};

export const refQueryParam = 'ref:';

export const videoStatuses = {
    started: 1,
    inProgress: 2,
    completed: 3,
    failed: 4,
};

// TO DO: Create a constructor and do the initialization instead of an interface

export const videoDetailsInitial = [
    initializeVideoData(entityTypes.listing.key, videoTypes.propertyVideo.key, 0),
    initializeVideoData(entityTypes.listing.key, videoTypes.secondVideo.key, 0),
    initializeVideoData(entityTypes.listing.key, videoTypes.showcaseVideo.key, 0),
    initializeVideoData(entityTypes.listing.key, videoTypes.additionalVideoOne.key, 1),
    initializeVideoData(entityTypes.listing.key, videoTypes.additionalVideoTwo.key, 2),
    initializeVideoData(entityTypes.listing.key, videoTypes.additionalVideoThree.key, 3),
];

export const zeroDisplayOrder = 0;

export const addDispayOrder = {
    additionalVideoOne: 1,
    additionalVideoTwo: 2,
    additionalVideoThree: 3,
};

export const uploadAction = (
    toUploadFile: File,
    toUploadName: string,
    listingId: string,
): VideoAction => {
    const uploadedFile = toUploadFile;
    let fromName = 'PROPERTY_VIDEO';
    let displayOrder = zeroDisplayOrder;
    let videoType = videoTypes.propertyVideo.key;
    const name = toUploadName;
    if (name === 'propertyVideo') {
        fromName = 'PROPERTY_VIDEO';
        videoType = videoTypes.propertyVideo.key;
    } else if (name === 'secondVideo') {
        fromName = 'FIFTEEN_SECOND_VIDEO';
        videoType = videoTypes.secondVideo.key;
    } else if (name === 'showcaseVideo') {
        fromName = 'SHOWCASE_WEBSITE_HERO_VIDEO';
        videoType = videoTypes.showcaseVideo.key;
    } else if (
        name === 'additionalVideoOne' ||
        name === 'additionalVideoTwo' ||
        name === 'additionalVideoThree'
    ) {
        fromName = 'ADDITIONAL_VIDEOS';
        displayOrder = getDisplayOrder(name);
        videoType = videoTypes.additionalVideoOne.key;
    }
    return {
        listingId: listingId,
        from: name,
        file: uploadedFile,
        displayOrder: displayOrder,
        fromName: fromName,
        videoType: videoType,
    };
};

const getDisplayOrder = (from: string) => {
    switch (from) {
        case 'additionalVideoOne':
            return addDispayOrder.additionalVideoOne;
        case 'additionalVideoTwo':
            return addDispayOrder.additionalVideoTwo;
        case 'additionalVideoThree':
            return addDispayOrder.additionalVideoThree;
        default:
            return zeroDisplayOrder;
    }
};

export const getVideoCancellationStatus = (
    videos: VideoModel[],
    videoTypeId: number,
    displayOrder: number,
): boolean => {
    return (
        videos.find(
            (v) => v.videoTypeId === videoTypeId && v.displayOrder === displayOrder,
        )?.isCancelled || false
    );
};
