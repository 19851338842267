import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import {
    deleteOpenHouseApi,
    getOpenHousesApi,
    postOpenHouseApi,
    putOpenHouseApi,
} from '../../../../../../shared/api/openHouseShowings/openHouseShowingsApi';
import {
    OpenHouse,
    OpenHouseState,
} from '../../../../../../shared/models/openHouseShowings/openHouseShowingsModels';
import Logger from '../../../../../../utils/logging/logger';
import { updateEventsState } from '../../../upcomingEvents/upcomingEventsSlice';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import { ListingDetailEvents } from '../../../../../../shared/models/loader/loaderModels';

const openHouseState: OpenHouseState = {
    openHouses: [],
    openHouseActiveId: '',
};

export const openHouseSlice = createSlice({
    name: 'openHouse',
    initialState: openHouseState,
    reducers: {
        updateOpenHouses: (state, action: PayloadAction<OpenHouse[]>) => {
            return {
                ...state,
                openHouses: [...action.payload],
            };
        },
        updateOpenHouseActiveId: (state, action: PayloadAction<string>) => {
            state.openHouseActiveId = action.payload;
        },
        resetOpenHouseState: (state) => {
            return {
                ...state,
                ...openHouseState,
            };
        },
    },
});

export const getOpenHouses =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const openHouse = await getOpenHousesApi(id);
            if (openHouse.length > 0) {
                dispatch(updateOpenHouses(openHouse));
            }
        } catch (e) {
            Logger.error('Error fetching open houses');
            dispatch(setError('Error fetching open houses'));
        }
    };

export const postOrPutOpenHouse =
    (openHouseData: OpenHouse): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(ListingDetailEvents.LISTING_SAVE));
            if (openHouseData) {
                let updatedData = openHouseData;
                const editingItem = (openHouseData.id?.length || 0) > 0;
                if (editingItem) {
                    const updatedCall = await putOpenHouseApi(openHouseData);
                    if (updatedCall.status !== 200) {
                        throw Error('Unable to update Open House');
                    }
                } else {
                    updatedData = await postOpenHouseApi(openHouseData);
                    if (!updatedData.id) {
                        throw Error('Unable to add Open House');
                    }
                }
                const currentOpenHouse = getState().listing.openHouse.openHouses;
                const updatedOpenHouse = editingItem
                    ? currentOpenHouse.map((item: OpenHouse) =>
                          item.id === updatedData.id ? { ...item, ...updatedData } : item,
                      )
                    : [updatedData, ...currentOpenHouse];
                dispatch(updateOpenHouses(updatedOpenHouse));
                dispatch(
                    updateEventsState({
                        property: 'openHouses',
                        value: updatedOpenHouse,
                    }),
                );
                dispatch(setSuccess(`Open House saved successfully`));
            }
        } catch (e) {
            dispatch(setError(`Failed to save Open House`));
        } finally {
            dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_SAVE));
        }
    };

export const deleteOpenHouse =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(ListingDetailEvents.LISTING_DELETE));
            if (id && id.length > 0) {
                const response = await deleteOpenHouseApi(id);
                if (response.status === 204) {
                    const currentOpenHouse = getState().listing.openHouse.openHouses;
                    const updatedOpenHouse = currentOpenHouse.filter(
                        (openHouse: OpenHouse) => openHouse.id !== id,
                    );
                    dispatch(updateOpenHouses(updatedOpenHouse));
                    dispatch(
                        updateEventsState({
                            property: 'openHouses',
                            value: updatedOpenHouse,
                        }),
                    );
                    dispatch(setSuccess(`Open House deleted successfully`));
                } else {
                    throw Error('Failed to delete Open House');
                }
            }
        } catch (e) {
            dispatch(setError(`Failed To Delete Open House`));
        } finally {
            dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_DELETE));
        }
    };

export const { updateOpenHouses, updateOpenHouseActiveId, resetOpenHouseState } =
    openHouseSlice.actions;

export const openHouse = (state: RootState): OpenHouseState => state.listing.openHouse;

export default openHouseSlice.reducer;
