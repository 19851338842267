import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import {
    deletePropertyMarketingApi,
    exportMarketingReportsApi,
    getPropertyMarketingApi,
    getPropertyMarketingEmailCampaign,
    getPropertyMarketingListApi,
    getPropertyMarketingSocialCampaign,
    savePropertyMarketingApi,
    updateOtherMarketingApi,
} from './propertyMarketingListApi';
import Logger from '../../../../utils/logging/logger';
import { setError, setSuccess } from '../../../../shared/slices/messaging/messagingSlice';
import { PropertyMarketingState, PropertyMarketing } from './propertyMarketingListModels';
import {
    EmailCampaignData,
    MarketingData,
    SocialCampaignData,
    OtherMarketing,
} from '../../../../shared/models/marketing/marketingModels';
import { initialPropertyMarketingDetails } from './propertyMarketingListConstants';
import { AdvertisementData } from '../../../cmr/cmrModels';
import { getOtherMarketing } from '../../../../shared/api/marketing/marketingApis';
import { generateData } from '../../../otherMarketing/otherMarketingUtils';
import { deleteSpotAssignedListingApi } from '../../../../shared/api/advertisement/advertisementApi';
import { returnFile } from '../../../../utils/api/fileResults';

const initialPropertyMarketingState: PropertyMarketingState = {
    upcoming: [],
    past: [],
    propertyMarketingDetails: initialPropertyMarketingDetails,
    oMDetails: undefined,
    isLoading: false,
};

export const propertyMarketingSlice = createSlice({
    name: 'propertyMarketing',
    initialState: initialPropertyMarketingState,
    reducers: {
        setUpcomingList: (state, action: PayloadAction<PropertyMarketing[]>) => {
            return {
                ...state,
                upcoming: action.payload,
            };
        },
        setPastList: (state, action: PayloadAction<PropertyMarketing[]>) => {
            return {
                ...state,
                past: action.payload,
            };
        },
        setMarketingDetails: (
            state,
            action: PayloadAction<
                MarketingData | AdvertisementData | EmailCampaignData | SocialCampaignData
            >,
        ) => {
            return {
                ...state,
                propertyMarketingDetails: action.payload,
            };
        },
        removePropertyMarketingFromUpcoming: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                upcoming: state.upcoming.filter(
                    (um) => um.marketingId !== action.payload,
                ),
            };
        },
        removePropertyMarketingFromPast: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                past: state.past.filter((pm) => pm.marketingId !== action.payload),
            };
        },
        resetPropertyMarketing: () => {
            return { ...initialPropertyMarketingState };
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setOtherMarketingDetails: (
            state,
            action: PayloadAction<OtherMarketing | undefined>,
        ) => {
            return {
                ...state,
                oMDetails: action.payload,
            };
        },
    },
});

export const getPropertyMarketingList =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await getPropertyMarketingListApi(listingId);
            if (response.pastMarketings.length) {
                dispatch(setPastList(response.pastMarketings));
            }
            if (response.upcomingMarketings.length) {
                dispatch(setUpcomingList(response.upcomingMarketings));
            }
        } catch (e) {
            Logger.error('Error in getting the property marketing list');
            dispatch(setError('Error in getting the property marketing list'));
        } finally {
            dispatch(setLoading(false));
        }
    };

export const getPropertyMarketing =
    (id: string, marketingTypeName: string): AppThunk =>
    async (dispatch) => {
        try {
            let response;
            if (marketingTypeName === 'Email Campaign')
                response = await getPropertyMarketingEmailCampaign(id);
            else if (marketingTypeName === 'Social Campaign')
                response = await getPropertyMarketingSocialCampaign(id);
            else response = await getPropertyMarketingApi(id);
            if (response.id?.length) {
                response =
                    marketingTypeName === 'Advertisement'
                        ? {
                              ...response,
                              issueDate: response.date,
                              publication: response.description,
                          }
                        : response;
                dispatch(setMarketingDetails(response));
            }
        } catch (e) {
            Logger.error('Error in getting the property marketing');
            dispatch(setError('Error in getting the property marketing'));
        }
    };

export const deletePropertyMarketing =
    (isPast: boolean, listingId: string, marketingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deletePropertyMarketingApi(listingId, marketingId);
            if (response.status === 204) {
                isPast
                    ? dispatch(removePropertyMarketingFromPast(marketingId))
                    : dispatch(removePropertyMarketingFromUpcoming(marketingId));
                dispatch(setSuccess('Property marketing is removed successfully'));
            }
        } catch (e) {
            Logger.error('Error in deleting the property marketing');
            dispatch(setError('Error in deleting the property marketing'));
        }
    };

export const saveOtherMarketing =
    (data: FormData, listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await savePropertyMarketingApi(data);
            if (response) {
                dispatch(getPropertyMarketingList(listingId));
                dispatch(setSuccess('Successfully created a property marketing'));
                return 'success';
            }
        } catch (e) {
            Logger.error(
                `Error in creating the property marketing: ${JSON.stringify(e)}`,
            );
            dispatch(setError('Error in creating the property marketing list'));
        } finally {
            dispatch(setLoading(false));
        }
    };

/**
 * function to export the marketing summart data
 * @param listingId
 * @returns
 */
export const exportListingMarketingReports =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            await returnFile(exportMarketingReportsApi(listingId));
        } catch (exception) {
            dispatch(setError(`Failed to export the data`));
        }
    };

/**
 * fetches the individual Other marketing data
 * @param id id of OM
 * @returns
 */

export const getOMDetails =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getOtherMarketing(id);
            if (response.id) {
                const data = {
                    ...response,
                    marketingTypeId: response.marketingTypeId.toString(),
                };
                dispatch(setOtherMarketingDetails(data));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch other marketing details`));
            Logger.error(
                `Failed to fetch other marketing details ${JSON.stringify(exception)}`,
            );
        }
    };

/**
 * function is used to save/update the OM details
 * @param data
 * @param isEdit
 * @param listingId listing guid
 * @param officeId office guid
 * @returns
 */
export const updateOtherMarketing =
    (data: OtherMarketing, listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const formData = generateData({ ...data, isAmcChanged: false });
            const response = await updateOtherMarketingApi(formData);
            if (response.id) {
                dispatch(getPropertyMarketingList(listingId));
                dispatch(setOtherMarketingDetails(undefined));
                dispatch(setLoading(false));
                dispatch(setSuccess(`Successfully updated the Other Marketing`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to update the other marketing`));
            Logger.error(
                `Failed to update the other marketing ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

export const deleteAdSpot =
    (advertisementId: string, spotListingId: string, listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteSpotAssignedListingApi(
                advertisementId,
                spotListingId,
            );
            if (response.status === 204) {
                dispatch(getPropertyMarketingList(listingId));
                dispatch(setSuccess(`Successfully deleted`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete`));
            Logger.error(`Failed to delete ${JSON.stringify(exception)}`);
        }
    };

/* Exporting Listing Marketing Actions */
export const {
    setUpcomingList,
    setPastList,
    setMarketingDetails,
    removePropertyMarketingFromUpcoming,
    removePropertyMarketingFromPast,
    resetPropertyMarketing,
    setOtherMarketingDetails,
    setLoading,
} = propertyMarketingSlice.actions;

export const propertyMarketingList = (state: RootState): PropertyMarketingState =>
    state.listing.propertyMarketingList;

/* Exporting Listing Marketing Reducer*/
export default propertyMarketingSlice.reducer;
