import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../salesTeamModels';
import { uploadSalesTeamPhoto, deleteSalesTeamLogo } from '../../../salesTeamSlice';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
    ImageDetail,
    ImageDeleteData,
} from '../../../../../../shared/models/images/sharedModel';
import { minPhotoDimension, salesTeamImageTypes } from '../../../salesTeamConstants';
import PhotoTemplate from '../../../../../../shared/component/photo/PhotoTemplate';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';

const TeamLogo: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const imageURL = editFormData.data.largePhoto;
    const { largePhoto } = salesTeamImageTypes;
    const dispatch = useAppDispatch();

    const validateImageSize = (width: number, height: number): boolean => {
        return (
            width >= minPhotoDimension.width &&
            height >= minPhotoDimension.height &&
            height > width
        );
    };

    const handleUpload = (imageData: ImageDetail, isCropped = false) => {
        if (isCropped) {
            deleteHandler(false);
        }
        dispatch(uploadSalesTeamPhoto(imageData, isCropped)); // Send other boolean values
    };

    const getImageId = (url: string): string[] => {
        const id = url.split('/').slice(-1)[0].split('.', 1);
        return id;
    };

    const deleteHandler = (showToaster = true) => {
        const deleteData: ImageDeleteData = {
            ids: getImageId(imageURL),
            imageType: largePhoto,
            shouldValidate: false,
        };
        dispatch(deleteSalesTeamLogo(deleteData, showToaster));
    };

    const photoUploadInstructions = () => {
        return (
            <div>
                Photo must be a jpg/jpeg with a minimum size of 840x1120 pixels in
                portrait format, at 72 dpi resolution. Photo must be in color, not in
                black and white.
                <br /> Composition: It's best when a person/people are in the center of
                the image or slightly off-center and the image has a ratio of 3:4
                (Width:Height). Images that do not conform to a 3:4 ratio may be
                automatically cropped on the web.
                <br /> Cropping: It's best to have chest-up/waist-up cropping for a
                person. The portrait should have at least 200px around the head(s).
            </div>
        );
    };

    return (
        <PhotoTemplate
            entityId={editFormData.data?.id || ''}
            entityType={entityTypes.salesTeam.value}
            imageType={largePhoto}
            photoUrl={imageURL || ''}
            isUploading={editFormData.flags.uploadingLargePhoto}
            infoText={photoUploadInstructions()}
            handleUpload={handleUpload}
            deleteHandler={deleteHandler}
            validateImageSize={validateImageSize}
            imageCrop={true}
        />
    );
};

export default TeamLogo;
