import { FunctionComponent, useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { AdditionalMls } from '../../models/additionalMls/additionalMlsModels';
import './index.scss';

import FormRow from '../formFields/FormRow';
interface AdditionalMlsProps {
    additionalMls: AdditionalMls[];
    setMlsDataToPersist: (additionalMlsData: AdditionalMls[]) => void;
}

const AdditionalMlsList: FunctionComponent<AdditionalMlsProps> = ({
    additionalMls,
    setMlsDataToPersist,
}) => {
    const [additionalMlsData, setAdditionalMlsData] = useState<AdditionalMls[]>([]);

    useEffect(() => {
        setAdditionalMlsData(additionalMls);
    }, [additionalMls]);

    const handleMlsChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        mlsToUpdate: AdditionalMls,
    ) => {
        const { name, value } = event.target;
        const updatedMls = additionalMlsData.map((mls) =>
            mls.officeAdditionalMlsId === mlsToUpdate.officeAdditionalMlsId
                ? { ...mls, [name]: value }
                : { ...mls },
        );
        setAdditionalMlsData([...updatedMls]);
    };

    return additionalMlsData.length > 0 ? (
        <>
            {additionalMlsData.map(
                (mls, index) =>
                    mls.active && (
                        <Grid
                            item
                            container
                            className="content"
                            alignItems="center"
                            spacing={2}
                            md={12}
                            key={index}
                        >
                            <FormRow
                                label={mls.mlsName ? mls.mlsName : ''}
                                control={
                                    <TextField
                                        value={mls.mlsNumber ? mls.mlsNumber : ''}
                                        onChange={(e) => handleMlsChange(e, mls)}
                                        InputProps={{
                                            disableUnderline: true,
                                            'aria-label': `${mls.mlsName}`,
                                            className: 'modalInputBaseRoot',
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        name="mlsNumber"
                                        onBlur={() =>
                                            setMlsDataToPersist(additionalMlsData)
                                        }
                                    />
                                }
                                prefix=""
                                suffix=""
                                controlClass="modalControlRoot"
                                labelXs={null}
                                labelSm={null}
                                labelMd={2}
                                controlXs={null}
                                controlSm={null}
                                controlMd={4}
                            />
                        </Grid>
                    ),
            )}
        </>
    ) : (
        <></>
    );
};

export default AdditionalMlsList;
