import React, { FunctionComponent } from 'react';
import { AdCopySizeDetail } from './adCopyModels';

interface SizeNavProps {
    sizes: AdCopySizeDetail[];
    sizeId: number;
    handleSizeChange: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        sizeId: number,
    ) => void;
}

const SizeNav: FunctionComponent<SizeNavProps> = ({
    sizes,
    handleSizeChange,
    sizeId,
}) => {
    const renderSizeNavigation = () => {
        return sizes.map((s) => (
            <a
                className={`nav-link ${s.id === sizeId ? 'selected' : ''}`}
                href="!#"
                onClick={(e) => handleSizeChange(e, s.id)}
                key={s.id}
            >
                {s.name.toUpperCase()}
            </a>
        ));
    };

    return <div className="size-nav-container">{renderSizeNavigation()}</div>;
};

export default SizeNav;
