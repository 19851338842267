import {
    getCall,
    postFormData,
    putFormData,
    deleteCall,
} from '../../../utils/api/apiWrapper';
import {
    MarketingData,
    EmailCampaignData,
    SocialCampaignData,
    MarketingTypes,
    OtherMarketing,
} from '../../models/marketing/marketingModels';

/**
 * Gets marketing data for the item with the given ID
 * @param urlName Name of the endpoint to hit to get the marketing info, based on its type
 * @param id ID of the marketing item to get
 * @returns Marketing item's data
 */
export function getMarketing(
    urlName: string,
    id: string,
): Promise<MarketingData | EmailCampaignData | SocialCampaignData> {
    return getCall<MarketingData | EmailCampaignData | SocialCampaignData>(
        `${urlName}/${id}`,
    );
}

/**
 * Adds a new marketing item and associates it with a listing
 * @param urlName Name of the endpoint to hit to update the marketing info, based on its type
 * (ex: marketing, email campaign, social campaign)
 * @param listingId Id of the listing the marketing is being added to
 * @param requestData Object containing marketing data to persist as well as optional
 * @param entityType Type of entity marketing is being added to (listing, agent)
 * @returns Marketing data with populated Id
 */
export function addMarketing(
    urlName: string,
    listingId: string,
    requestData: FormData,
    entityType = 'listing',
): Promise<MarketingData | EmailCampaignData | SocialCampaignData> {
    return postFormData<MarketingData | EmailCampaignData | SocialCampaignData>(
        `${entityType}/${listingId}/${urlName}`,
        requestData,
    );
}

/**
 * Updates an existing marketing item
 * @param urlName Name of the endpoint to hit to update the marketing info, based on its type
 * (ex: marketing, email campaign, social campaign)
 * @param id Id of the marketing item being updated
 * @param requestData Object containing marketing data to persist as well as optional
 * file data
 * @returns The populated request object
 */
export function updateMarketing(
    urlName: string,
    id: string,
    requestData: FormData,
): Promise<MarketingData | EmailCampaignData | SocialCampaignData> {
    return putFormData<MarketingData | EmailCampaignData | SocialCampaignData>(
        `${urlName}/${id}`,
        requestData,
    );
}

/**
 * Gets list of marketing types
 * @returns List of all marketing types in the db
 */
export function getMarketingTypes(): Promise<MarketingTypes[]> {
    return getCall<MarketingTypes[]>(`marketing/types`);
}

/**
 * Deletes a marketing file from AWS and updates the db fields
 * @param id The ID of the marketing item whose file we are deleting
 * @returns HTTP response
 */
export function deleteFile(id: string): Promise<Response> {
    return deleteCall(`marketing/${id}/file`);
}

/**
 *
 * @param id marketingId
 * @returns other marketing details
 */
export function getOtherMarketing(id: string): Promise<OtherMarketing> {
    return getCall<OtherMarketing>(`Marketing/${id}/details`);
}
