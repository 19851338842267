import { getCall, postCall } from '../../../utils/api/apiWrapper';
import {
    AdvertisementLookups,
    CommonLookups,
    ListingLookups,
    OfficeLookups,
    DashRolesLookups,
    OtherMarketingLookups,
    DevelopmentLookups,
} from '../../models/lookups/lookupsModels';
import { OfficeIds } from '../../models/pagination/paginationModels';

export function getAdvertisementLookupsApi(): Promise<AdvertisementLookups> {
    return getCall<AdvertisementLookups>(`advlookup/lookups`);
}

export function getCommonLookupsApi(): Promise<CommonLookups> {
    return getCall<CommonLookups>(`lookups`);
}

export function getListingLookupsApi(): Promise<ListingLookups> {
    return getCall<ListingLookups>(`listing/lookups`);
}

export function getOfficeLookupsApi(): Promise<OfficeLookups> {
    return getCall<OfficeLookups>(`Office/lookups`);
}

export function getDashRolesLookupsApi(): Promise<DashRolesLookups> {
    return getCall<DashRolesLookups>(`userdashrole/lookups`);
}

export function getOtherMarketingLookupsApi(): Promise<OtherMarketingLookups> {
    return getCall<OtherMarketingLookups>(`Marketing/marketingTypes`);
}

export function getDevelopmentLookupsApi(
    officeIds: OfficeIds,
): Promise<DevelopmentLookups> {
    return postCall<OfficeIds, DevelopmentLookups>(`building/lookups`, officeIds);
}
