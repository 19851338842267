import { FunctionComponent, useEffect, useState, MouseEvent } from 'react';
import { Input } from '@material-ui/core';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { AdvertisementData } from '../../../../features/cmr/cmrModels';
import { useAppDispatch, useFormData } from '../../../../app/hooks';
import { useFileManagement } from '../../../marketing/hooks';
import {
    deleteMarketingFile,
    saveAdvertisement,
} from '../../../../features/cmr/cmrSlice';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';
import { datePickerFormat } from '../../../../utils/urlUtils';

interface CustomAdvertisementProps {
    /* Advertisement data passed from parent component during edit */
    advertisement?: AdvertisementData;
    /* Emit function when cancel button is clicked */
    cancelClick(): void;
    /* Indicates whether the form is in edit mode */
    formEdit?: boolean;
    /* Guid of the listing */
    listingId?: string;
    /* If defined, use it for CMR, otherwise for property marketing */
    sectionId?: number;
}

const initialAd: AdvertisementData = {
    id: '',
    date: '',
    issueDate: '',
    publication: '',
    allowEditing: true,
    tearsheetFilename: '',
    isFile: false,
};

const CustomAdvertisement: FunctionComponent<CustomAdvertisementProps> = (props) => {
    const dispatch = useAppDispatch();
    const { formData, setFormData, handleTextChange, handleDateChange, removeSpaces } =
        useFormData(props.advertisement || initialAd);
    const {
        fileData,
        handleFileUpload,
        handleUrlChange,
        ensureHttp,
        setFileData,
        handleFileClear,
    } = useFileManagement(formData);
    const [active, setActive] = useState(true);

    const deleteFile = () => {
        dispatch(deleteMarketingFile(formData, props.sectionId));
    };

    useEffect(() => {
        setFormData(formData);
        setFileData(fileData);
        const { issueDate, publication } = formData;
        setActive(issueDate === '' || !publication || publication === '');
    }, [formData, fileData, setFileData, setFormData]);

    const save = (e: MouseEvent<HTMLButtonElement>) => {
        const saveRequest = { ...formData, ...fileData };
        dispatch(
            saveAdvertisement(
                props.listingId || '',
                saveRequest,
                props.sectionId ? true : false,
            ),
        );
        setFormData(initialAd);
        handleFileClear(e, false);
        if (props.sectionId) {
            if (!props.formEdit)
                googleAnalyticsAction('Add', 'Click', 'Advertisement Add');
            else googleAnalyticsAction('Edit', 'Click', 'Advertisement Edit Save');
        }
        props.cancelClick();
    };
    return (
        <div className={`adv-container-inner  ${props.formEdit ? 'adv-edit' : ''}`}>
            {props.formEdit && props.sectionId ? <div className="overlayEditForm" /> : ''}
            <div className="form-container">
                <div className="add-showing">
                    <span className="actionHolder">
                        <DatePicker
                            className="datePickerCommon cmrDate"
                            placeholder="Date"
                            name="issueDate"
                            format={datePickerFormat()}
                            showToday={false}
                            onChange={(date: Moment | null) => {
                                handleDateChange('issueDate', date);
                            }}
                            value={
                                formData.issueDate && formData.issueDate !== ''
                                    ? moment(formData.issueDate, 'YYYY-MM-DDTHH:mm:ss')
                                    : undefined
                            }
                        />
                    </span>
                    <span className="actionHolder">
                        <Input
                            className="cmrInput"
                            placeholder="Description"
                            name="publication"
                            onChange={handleTextChange}
                            onBlur={removeSpaces}
                            inputProps={{ 'aria-label': 'Description', maxLength: 500 }}
                            value={formData.publication || ''}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className="actionHolder">
                        <Input
                            className="cmrInput"
                            placeholder="Url"
                            name="url"
                            onChange={handleUrlChange}
                            onBlur={ensureHttp}
                            inputProps={{ 'aria-label': 'Url' }}
                            value={fileData.url || ''}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className="actionHolder advFileHolder">
                        {!fileData.file && !fileData.isFile ? (
                            <label className="advFile">
                                Upload File
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                                <Input
                                    className="cmrInput file"
                                    name="fileUpload"
                                    inputProps={{
                                        'aria-label': 'File Upload',
                                        accept: '.jpg,.png,.pdf',
                                    }}
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                            </label>
                        ) : (
                            <label className="advFile">
                                <button
                                    onClick={(e) =>
                                        handleFileClear(
                                            e,
                                            props.formEdit || false,
                                            deleteFile,
                                        )
                                    }
                                    className="removeFile"
                                >
                                    Remove File
                                </button>
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                            </label>
                        )}
                        <span className="spec">
                            File Format: JPG, PNG, PDF | File Size: 50MB
                        </span>
                    </span>
                </div>
                {!props.formEdit ? (
                    <div className="addButtonHolder">
                        <button
                            className={
                                !active ? 'actionButton unActive' : 'actionButton active'
                            }
                            onClick={save}
                            disabled={active}
                        >
                            Add
                        </button>
                    </div>
                ) : (
                    <div className="editButtonHolder">
                        <button
                            className="actionButton cancel"
                            onClick={props.cancelClick}
                        >
                            Cancel
                        </button>
                        <button
                            className={
                                !active
                                    ? 'actionButton saveActive'
                                    : 'actionButton saveInActive'
                            }
                            onClick={save}
                            disabled={active}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CustomAdvertisement;
