import { PaginationResponse } from '../../../../../../shared/models/pagination/paginationModels';
import { jsonToFormData } from '../../../../../../utils/urlUtils';
import {
    ReservedSpotGroupingModel,
    SpotReservationResponse,
    SpotCreateRequest,
} from './spotTypeModels';

export const setFilesToPropsIfUploaded = (
    formData: SpotCreateRequest,
    advId: string,
): FormData => {
    const officeIds = formData.officeIds;
    const { officeIds: _, ...data } = formData;
    if (formData.tearSheetFile) {
        data.tearSheetFile = formData.tearSheetFile;
    }
    data.advertisementId = advId;
    const jsonData = jsonToFormData(data);
    if (jsonData.has('officeDetails')) {
        jsonData.set('officeDetails', JSON.stringify(officeIds));
    } else {
        jsonData.append('officeDetails', JSON.stringify(officeIds));
    }
    return jsonData;
};

export const reservedSpotGroupingUtil = (
    reservedSpots: SpotReservationResponse[],
): ReservedSpotGroupingModel[] => {
    //get the unique records based on the reserved spots
    const uniqueSpotOnId: SpotReservationResponse[] = Object.values(
        reservedSpots.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}),
    );
    //add a custom text based on id, which is used on the table to show the linkage between
    //reserved spots and listing
    const reservedWithText = uniqueSpotOnId.map((res, index) => {
        return {
            id: res.id,
            text: 'RS ' + (index + 1),
        };
    });
    return reservedWithText;
};

export const initiatePaginationResponse = <T>(): PaginationResponse<T> => {
    return {
        currentPage: 1,
        recordsPerPage: 10,
        totalRecords: 0,
        results: [],
    };
};
