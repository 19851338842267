import { FunctionComponent } from 'react';
import ToggleButton from '../../../shared/component/toggleButton/ToggleButton';
import { HeaderProps } from './model';
import './index.scss';

const Header: FunctionComponent<HeaderProps> = (props) => {
    return (
        <div className="cmr-container">
            <div className="title" id={'scrollId' + props.section}>
                {props.title}
            </div>
            {props.hideDisplay ? (
                ''
            ) : (
                <div className="report">
                    <span>Display on Report</span>
                    <ToggleButton
                        isActive={props.isButtonActive}
                        data={props.data}
                        action={props.action}
                    />
                </div>
            )}
        </div>
    );
};
export default Header;
