import React, { FunctionComponent, useEffect } from 'react';

const Support: FunctionComponent = () => {
    useEffect(() => {
        window.open(
            'https://sirapplications.atlassian.net/servicedesk/customer/portal/2',
            '_self',
        );
    }, []);

    return <></>;
};

export default Support;
