import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { GlobalSearchResponse } from '../../models/globalSearch/globalSearchModel';
import {
    dataToBind,
    formGlobalSearchViewData,
    globalSearchRedirect,
} from './globalSearchUtils';
import { Link, useHistory } from 'react-router-dom';
import { setBackTo, setTitle } from '../../slices/navigation/navigationSlice';
import { useAppDispatch } from '../../../app/hooks';
import DOMPurify from 'dompurify';
interface ViewGlobalResultsModel {
    from: string;
    list: GlobalSearchResponse[];
    totalRecords: number;
    loadMore: () => void;
    viewMoreClicked: boolean;
}

const ViewGlobalResults: FunctionComponent<ViewGlobalResultsModel> = ({
    from,
    list,
    totalRecords,
    loadMore,
    viewMoreClicked,
}) => {
    const dispatch = useAppDispatch();
    const { ref, inView } = useInView();
    const history = useHistory();

    useEffect(() => {
        if (inView && totalRecords !== list.length && loadMore) {
            loadMore();
        }
    }, [inView, totalRecords, list]);

    const [formattedResults, setFormattedResults] = useState<GlobalSearchResponse[]>([]);
    const redirect = (res: GlobalSearchResponse) => {
        const response = globalSearchRedirect(res);
        dispatch(
            setBackTo({
                url: '/eng/searchDetailed',
                title: 'Global Search Results Page',
            }),
        );
        if (response.type === 'listing') dispatch(setTitle(`Listings`));
        if (response.type === 'office') dispatch(setTitle(`Offices`));
        if (response.type === 'salesTeam') dispatch(setTitle(`Sales Teams`));
        if (response.type === 'application') dispatch(setTitle(`Applications`));
        if (response.type === 'agent') dispatch(setTitle(`Agents`));
        history.push(response.url);
    };

    const fetchFormatedResult = useCallback((results: GlobalSearchResponse[]) => {
        const finalResult = formGlobalSearchViewData(results);
        setFormattedResults(finalResult);
    }, []);

    useEffect(() => {
        if (from === 'all' && list.length > 0) {
            fetchFormatedResult(list);
        } else {
            setFormattedResults(list);
        }
    }, [from, list, fetchFormatedResult, setFormattedResults]);

    return (
        <>
            {viewMoreClicked ? (
                <>
                    <Grid item xs={12} className="indiRes totalRecords">
                        Total Records: {totalRecords}
                    </Grid>
                    {list && list.length ? (
                        formattedResults.map(
                            (res: GlobalSearchResponse, index: number) => (
                                <Grid
                                    item
                                    xs={12}
                                    className="indiRes"
                                    key={index}
                                    ref={
                                        list.length < totalRecords &&
                                        index === list.length - 10
                                            ? ref
                                            : null
                                    }
                                >
                                    <Link
                                        to={globalSearchRedirect(res).url}
                                        onClick={() => redirect(res)}
                                        key={index}
                                        className="searchResults-indi"
                                    >
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                            className="search-icon"
                                        />
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    dataToBind(res),
                                                ),
                                            }}
                                        ></span>
                                    </Link>

                                    {/* <div
                                    key={index}
                                    className="searchResults-indi"
                                    onClick={() => redirect(res)}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        className="search-icon"
                                    />
                                    {dataToBind(res)}
                                </div> */}
                                    {/* <Link to={Url(res)}>{dataToBind(res)}</Link> */}
                                </Grid>
                            ),
                        )
                    ) : (
                        <Grid>No Search Results Available</Grid>
                    )}
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default ViewGlobalResults;
