import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    AddListingCommissionResponse,
    ListingCommissionState,
    ListingCommissionTableModel,
    ListingsRequestAPI,
    UpdateListingCommissionRequest,
} from '../../models/listingCommission/listingCommissionModels';
import { initialListingCommissionResponse } from '../../utils/listingCommission/listingCommissionUtils';
import { ListingsResponse } from '../../../features/listings/listingListModels';
import { getListingsFromApi } from '../../../features/listings/listingListApi';
import { setError, setSuccess } from '../messaging/messagingSlice';
import { PaginationResponse } from '../../models/pagination/paginationModels';
import {
    exportCommApi,
    updateListingCommissionApi,
} from '../../api/listingCommission/listingCommissionApi';
import Logger from '../../../utils/logging/logger';
import { returnFile } from '../../../utils/api/fileResults';
import { isManager } from '../../utils/shared/sharedUtils';

const initialListingCommissionState: ListingCommissionState = {
    isLoading: false,
    activeTabState: -1,
    data: {
        sales: initialListingCommissionResponse('1'),
        rentals: initialListingCommissionResponse('2'),
    },
};

export const listingListCommissionSlice = createSlice({
    name: 'listingListCommission',
    initialState: initialListingCommissionState,
    reducers: {
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        updateTableAction: (state, action: PayloadAction<ListingsRequestAPI>) => {
            const type =
                action.payload.businessPurposeTypeId === '1' ? 'sales' : 'rentals';
            const finalData = {
                ...state.data[type].tableAction,
                ...action.payload,
            };
            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        tableAction: {
                            ...finalData,
                        },
                    },
                },
            };
        },
        addListings: (state, action: PayloadAction<AddListingCommissionResponse>) => {
            const { type, apiResults } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        totalRecords: apiResults.totalRecords,
                        results:
                            apiResults.currentPage === 1
                                ? apiResults.results
                                : [...state.data[type].results, ...apiResults.results],
                    },
                },
            };
        },
        updateIndividualListingCommissionFromList: (
            state,
            action: PayloadAction<{
                listingType: string;
                listingCommissions: UpdateListingCommissionRequest;
            }>,
        ) => {
            const { listingType, listingCommissions } = action.payload;
            const {
                id,
                listingCommission,
                buyerCommission,
                doNotDisplayBuyerCommission,
                buyerCommissionNotOffered,
            } = listingCommissions;
            const results = state.data[listingType as keyof typeof state.data].results;
            const index = results.findIndex((result) => result.id === id);
            const listing = {
                ...results[index],
                listingCommission: listingCommission,
                buyerCommission: buyerCommission,
                doNotDisplayBuyerCommission: doNotDisplayBuyerCommission,
                buyerCommissionType: buyerCommissionNotOffered ? 3 : 1,
            };
            state.data[listingType].results[index] = listing;
        },
        setActiveTabState: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                activeTabState: action.payload,
            };
        },
    },
});

export const getListingListCommission =
    (request: ListingsRequestAPI): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getListingsFromApi(request);
            const results = response.results;
            // .filter((result) => {
            //     const listSideAgents = result.agentDetails.split(' | ');
            //     return listSideAgents.some((agent) => agent.includes('List'));
            // });
            const listingCommissions: PaginationResponse<ListingCommissionTableModel> = {
                ...response,
                results: results.map((result: ListingsResponse) => {
                    return {
                        office: result.officeName,
                        address: result.streetAddress,
                        currentPrice: result.currentPrice || 0,
                        listAgent: (result.agentDetails.split(' | ')[0] || '')
                            .replace(/[^a-zA-Z0-9  ,]/g, '')
                            .replace(/List/g, ''),
                        listingCommission: result.listingCommission || null,
                        buyerCommission: result.buyerCommission || null,
                        id: result.listingId,
                        listingDate: result.listingDate,
                        createdDate: result.createdDate,
                        buyerCommissionType: result.buyerCommissionType,
                        doNotDisplayBuyerCommission: result.doNotDisplayBuyerCommission,
                        buyerCommissionNotOffered:
                            result.buyerCommissionType === 3 ? true : false,
                        mlsNumber: result.mlsNumber,
                    };
                }),
            };
            dispatch(updateTableAction(request));
            dispatch(
                addListings({
                    type: request.businessPurposeTypeId === '1' ? 'sales' : 'rentals',
                    apiResults: listingCommissions,
                }),
            );
        } catch (e) {
            dispatch(setError('Error in getting the listing commissions'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const updateListingCommission =
    (request: UpdateListingCommissionRequest, listingTypeId?: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await updateListingCommissionApi(request);
            if (response.status === 204) {
                if (listingTypeId) {
                    dispatch(
                        updateIndividualListingCommissionFromList({
                            listingType: listingTypeId === '1' ? 'sales' : 'rentals',
                            listingCommissions: request,
                        }),
                    );
                    dispatch(setSuccess('Changes are updated successfully'));
                }
            }
        } catch (e) {
            Logger.error(`Error in updating the listing commission`, e);
            dispatch(setError('Error in updating the changes'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * export the searched results as excel
 * @param data
 * @returns
 */
export const exportCommReport =
    (exportRequest: ListingsRequestAPI): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            //update agent id and agent role for manager
            const data = isManager(exportRequest);
            exportRequest.agentIds = data.agentIds;
            exportRequest.roleIds = data.roleIds;
            await returnFile(exportCommApi(exportRequest));
        } catch (e) {
            Logger.error('Failed to export commission data', e);
            dispatch(setError('Failed to export commission data'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const {
    addListings,
    updateLoader,
    updateTableAction,
    updateIndividualListingCommissionFromList,
    setActiveTabState,
} = listingListCommissionSlice.actions;

export const listingListCommission = (state: RootState): ListingCommissionState =>
    state.listing.listingCommissionList;

export default listingListCommissionSlice.reducer;
