import { getCall } from '../../../utils/api/apiWrapper';
import { GetDomainInfo } from '../../models/domain/domain';

export function getDomainInfoBasedOnEntity(
    entityId: string,
    siteType: number,
): Promise<GetDomainInfo> {
    return getCall<GetDomainInfo>(
        `domain/getbyid?entityId=${entityId}&siteType=${siteType}`,
    );
}
