import {
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import './agentSearchAndAdd.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { showCaseWebsiteStyle } from '../../showcaseWebsite/style';
import GridItem from '../listAgent/dragAndDrop/GridItem';
import { agentSearch } from '../listAgent/listAgentConstants';
import { useAppDispatch, useDebounce } from '../../../../../../../app/hooks';
import { AgentSearchResult } from '../../../../../../../shared/models/agent/agentModel';
import { getAgentsByLastName } from '../../../../../../../shared/api/agent/agentApi';
import { ListingAgent } from '../../../../listingModels';
import { setError } from '../../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../../utils/logging/logger';

interface AgentSearchAndAddProps {
    title: string;
    initialState: ListingAgent[];
    total: number;
    dealType: boolean;
    listingType: string | null;
    suppressAgent: boolean;
    handleSuppressAgent: (status: boolean) => void;
    sendSelectedAgent: (agent: AgentSearchResult) => void;
    sendOrderedAgents: (agents: ListingAgent[]) => void;
    updateFormFields: (agent: ListingAgent, id?: string) => void;
    updateSelectSale: (agentId: string, selectSale: boolean) => void;
    deleteSelectedAgent: (agent: ListingAgent, id?: string) => void;
    totalSplitHandler: (agentId: string, split: number) => void;
    errorMessage: string | null;
}

const AgentSearchAndAdd: FunctionComponent<AgentSearchAndAddProps> = ({
    title,
    initialState,
    total,
    dealType,
    listingType,
    suppressAgent,
    handleSuppressAgent,
    sendSelectedAgent,
    sendOrderedAgents,
    updateFormFields,
    updateSelectSale,
    deleteSelectedAgent,
    totalSplitHandler,
    errorMessage,
}) => {
    const classes = showCaseWebsiteStyle();
    const [search, setSearch] = useState<string>(''); // SearchTerm
    const [agents, setAgents] = useState<AgentSearchResult[]>([]);
    const debouncedSearchTerm: string = useDebounce<string>(search, 500);
    const [agentList, updateAgentList] = useState<ListingAgent[]>([]);
    const [suppress, setSuppress] = useState<boolean>(suppressAgent);
    const [reset, setReset] = useState<number>(1);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const check = debouncedSearchTerm.includes('-') ? true : false;
        if (!check && debouncedSearchTerm !== '') {
            const fetch = async () => {
                try {
                    const response = await getAgentsByLastName(debouncedSearchTerm);
                    setAgents(
                        response.filter((agent: AgentSearchResult) => {
                            return agentList.every((listingAgent: ListingAgent) => {
                                return agent.agentId !== listingAgent.agentId;
                            });
                        }),
                    );
                } catch {
                    dispatch(setError('Error in fetching agents'));
                    Logger.error('Error in fetching agents');
                }
            };
            fetch();
        }
    }, [debouncedSearchTerm, agentList, dispatch]); // Remove agentList later on

    useEffect(() => {
        updateAgentList([...initialState]);
    }, [initialState]);

    // Drag & Drop Related Stuffs

    const moveGrid = (dragIndex: number, hoverIndex: number, isDropped: boolean) => {
        if (dragIndex !== hoverIndex) {
            reorder(dragIndex, hoverIndex);
        }
        if (isDropped) {
            sendOrderedAgents(agentList);
        }
    };

    const reorder = (startIndex: number, endIndex: number) => {
        const stateValue = [...agentList];
        const dragItem = stateValue[startIndex];
        const agentsList = update(stateValue, {
            $splice: [
                [startIndex, 1],
                [endIndex, 0, dragItem],
            ],
        });
        const orderedAgentsList = updateDisplayOrder(agentsList);
        updateAgentList([...orderedAgentsList]);
    };

    const updateDisplayOrder = (agentsList: ListingAgent[]) => {
        const clone = JSON.parse(JSON.stringify(agentsList));
        const ordered = clone.map((a: ListingAgent, i: number) => {
            a.displayOrder = i + 1;
            return a;
        });

        return ordered;
    };

    const renderGridItem = (gridItem: ListingAgent, index: number) => {
        return (
            <GridItem
                key={gridItem.id}
                index={index}
                gridItem={gridItem}
                moveGrid={moveGrid}
                updateFormFields={updateFormFields}
                updateSelectSale={updateSelectSale}
                deleteSelectedAgent={deleteSelectedAgent}
                totalSplitHandler={totalSplitHandler}
            />
        );
    };

    const handleIndicator = (status: boolean) => {
        handleSuppressAgent(status);
        setSuppress(status);
    };

    // Child Component
    return dealType ? (
        <Grid container>
            <Grid
                item
                container
                justifyContent="flex-end"
                className="suppressMLSGrid"
                alignItems="center"
            >
                <FormControlLabel
                    label="SUPPRESS AGENT MLS IMPORT"
                    className="suppressMLSLabel"
                    control={
                        <Checkbox
                            name="suppressMLS"
                            classes={{
                                root: classes.checkBox,
                                checked: classes.checked,
                            }}
                            checked={suppress}
                            onChange={() => handleIndicator(!suppress)}
                        />
                    }
                    labelPlacement="start"
                />
            </Grid>
            <Grid item container className="requiredField">
                <Typography>* Indicates Required Fields</Typography>
            </Grid>
            <Grid item container className="title">
                <Grid className="sideText">{title}</Grid>
            </Grid>
            <Grid item container className="title">
                <Grid className="sideMsg">
                    A property may be represented by up to 20 agents per side. To select
                    an agent, type their last name into the textbox below. A filtered list
                    of agents will appear below. Click the name of the agent to add them
                    to the list.
                </Grid>
            </Grid>
            <Grid item container className="agentSearchGrid" alignItems="center">
                <Grid item xs={12} md={2} className="agentLabel">
                    {/* Agent Name */}
                    <Typography>AGENT NAME</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    {/* Search Field */}
                    <Autocomplete
                        forcePopupIcon={false}
                        key={reset}
                        options={agents}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                className="autoComplete formControlRoot"
                            />
                        )}
                        getOptionLabel={(option) => option.name + ' - ' + option.office}
                        onChange={(event, value) =>
                            value
                                ? (sendSelectedAgent(value),
                                  setAgents([]),
                                  setReset(reset + 1))
                                : sendSelectedAgent(agentSearch)
                        }
                        onInputChange={(event, value) => setSearch(value)}
                        noOptionsText=""
                        className="agentSearchField searchAutoComplete"
                        onBlur={() => setAgents([])}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    fontSize: 14,
                                    fontFamily: 'MercuryTextG4Roman',
                                    marginTop: '5px',
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        loading={agents.length === 0 ? true : false}
                        loadingText="Loading..."
                        clearOnBlur={false}
                    />
                </Grid>
            </Grid>
            {agentList.length > 0 ? (
                <>
                    <Grid item container className="splitMsgContainer">
                        <Typography>
                            Agents may split the sales commission in any percentages they
                            wish. The sum of all agent commissions must equal 100% for
                            each side.
                        </Typography>
                    </Grid>
                    {total < 100 ? (
                        <Grid
                            item
                            container
                            className="splitPercentage"
                            justifyContent="center"
                        >
                            Commission splits should be equal to 100%.
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid
                        item
                        container
                        className="dndContainer"
                        // spacing={4}
                        alignItems="center"
                    >
                        <DndProvider backend={HTML5Backend}>
                            <Grid
                                item
                                container
                                md={12}
                                spacing={2}
                                className="agentSplitter"
                            >
                                <Grid item md={1} sm={1} className="dndHeader">
                                    SI.NO
                                </Grid>
                                <Grid item md={2} sm={2} className="dndHeader">
                                    AGENT NAME
                                </Grid>
                                <Grid item md={4} sm={4} className="dndHeader">
                                    OFFICE NAME
                                </Grid>
                                <Grid item md={2} sm={2} className="dndHeader">
                                    Side Split(%)
                                </Grid>
                                <Grid item md={2} sm={2} className="dndHeader">
                                    {listingType === 'Sale'
                                        ? 'SELECT SALE'
                                        : 'SELECT RENTAL'}
                                </Grid>
                                <Grid item md={1} sm={1}></Grid>
                            </Grid>
                            {agentList && agentList.length > 0 ? (
                                agentList.map((f, i) => renderGridItem(f, i))
                            ) : (
                                <></>
                            )}
                        </DndProvider>
                    </Grid>
                    {total > 0 ? (
                        <Grid item container justifyContent="center">
                            <Grid item md={2} sm={2} className="total">
                                <Typography
                                    style={{
                                        fontFamily: 'MercuryTextG4Roman',
                                        fontSize: '14px',
                                        color: '#002349',
                                        letterSpacing: '0.02px',
                                        lineHeight: '18px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Total: {total.toFixed(2)} %
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {errorMessage ? (
                        <Grid
                            item
                            container
                            justifyContent="center"
                            className="splitError"
                        >
                            <Typography className="message">{errorMessage}</Typography>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
        </Grid>
    ) : (
        <></>
    );
};

export default AgentSearchAndAdd;
