import {
    SpotListingSearchRequest,
    Spot,
    SpotAssignedListingsResponse,
} from '../../../features/advertisements/advertisementHolder/viewAdvertisement/component/spotTable/spotTypeModels';
import { ListingsResponse } from '../../../features/listings/listingListModels';
import { getCall, postCall, postCallRaw } from '../../../utils/api/apiWrapper';
import { PaginationResponse } from '../../models/pagination/paginationModels';
import {
    AdvSpotList,
    AdvSpotRequest,
    AgentListingSearchModel,
    ListingAssignment,
    RequestSpotModel,
    SpotMOList,
    SpotMORequest,
} from '../../models/spotBooking/spotBookingModel';
import { isManager } from '../../utils/shared/sharedUtils';

export function fetchMOForAgentApi(
    request: SpotMORequest,
): Promise<PaginationResponse<SpotMOList>> {
    return postCall<SpotMORequest, PaginationResponse<SpotMOList>>(
        `MediaOutlet/spotbasedsearch`,
        request,
    );
}

export function fetchAdvSpotListApi(
    request: AdvSpotRequest,
): Promise<PaginationResponse<AdvSpotList>> {
    return postCall<AdvSpotRequest, PaginationResponse<AdvSpotList>>(
        `advertisement/mediaoutletsearch`,
        request,
    );
}

export function assignListingToSpotFromListingApi(
    assignListingToSpot: ListingAssignment,
): Promise<Response> {
    return postCall<ListingAssignment, Response>(`Spot/listing`, assignListingToSpot);
}

export function spotRequestApi(requestData: RequestSpotModel): Promise<Response> {
    return postCallRaw(`Spot/agentSpotRequest`, requestData);
}

export function getSpotAssignedListingsApi(
    spotId: string,
    request: SpotListingSearchRequest,
): Promise<PaginationResponse<SpotAssignedListingsResponse>> {
    return postCall<
        SpotListingSearchRequest,
        PaginationResponse<SpotAssignedListingsResponse>
    >(`Spot/${spotId}/listings`, request);
}

export function fetchIndiSpotStatisticsApi(spotId: string): Promise<Spot> {
    return getCall<Spot>(`Spot/details/${spotId}`);
}

export function searchAgentListingsApi(
    assignListingToSpot: AgentListingSearchModel,
): Promise<PaginationResponse<ListingsResponse>> {
    //update agent id and agent role for manager
    const data = isManager(assignListingToSpot);
    assignListingToSpot.agentIds = data.agentIds
        ? data.agentIds
        : assignListingToSpot.agentIds;
    assignListingToSpot.roleIds = data.roleIds;
    return postCall<AgentListingSearchModel, PaginationResponse<ListingsResponse>>(
        `listing/search`,
        assignListingToSpot,
    );
}
