import { FunctionComponent, useState } from 'react';
import { imageSizes } from '../../../../constants/photos/photoConstants';
import { getPhotoId } from '../../../../utils/photos/photoUtils';
import { ImageData } from '../../../../models/images/sharedModel';
import { useAppDispatch } from '../../../../../app/hooks';
import { AppThunk } from '../../../../../app/store';
import './imageDownload.scss';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';

interface ImageDownloadProps {
    image: ImageData | undefined;
    currentImageType: string;
    cancelHandler(): void;
    size: number;
    imageDownload: (url: string) => AppThunk;
    entityType: string;
    office: string | null;
}

const ImageDownload: FunctionComponent<ImageDownloadProps> = ({
    image,
    currentImageType,
    cancelHandler,
    size,
    imageDownload,
    entityType,
    office,
}) => {
    const [dimensions, setDimensions] = useState<string>('');
    const dispatch = useAppDispatch();

    const downloadHandler = async (imageSize: string) => {
        if (image) {
            googleAnalyticsAction(
                'Download',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image downloaded, Image type: ${currentImageType}`,
            );
            googleAnalyticsAction(
                'Download',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image downloaded, Image type: ${currentImageType}, Office: ${office}`,
            );
            setDimensions(imageSize);
            const id = getPhotoId(image, imageSize, currentImageType);
            const url = `${process.env.REACT_APP_PHOTOURL}/${id}`;
            await dispatch(imageDownload(url));
            cancelHandler();
        }
    };

    const thumbnailClass = () => {
        if (size === 1) {
            return 'thumbnailSizeButton';
        } else if (size === 2) {
            return 'thumbnailSizeButton mediumThumbnailSizeButton';
        } else if (size === 3) {
            return 'thumbnailSizeButton largeThumbnailSizeButton';
        } else {
            return '';
        }
    };
    return (
        <div className="imageDownloadContainer">
            <div
                className={
                    size > 0 ? 'buttonsHolder thumbnailButtonHolder' : 'buttonsHolder'
                }
            >
                <button
                    className={
                        dimensions === imageSizes.smallLandscape ||
                        dimensions === imageSizes.smallPortrait
                            ? `sizeButton sizeButtonSelected ${thumbnailClass()}`
                            : `sizeButton ${thumbnailClass()}`
                    }
                    onClick={(event) => {
                        event.stopPropagation();
                        downloadHandler(
                            image?.islandscape
                                ? imageSizes.smallLandscape
                                : imageSizes.smallPortrait,
                        );
                    }}
                >
                    Small
                </button>
                <button
                    className={
                        dimensions === imageSizes.mediumLandscape ||
                        dimensions === imageSizes.mediumPortrait
                            ? `sizeButton sizeButtonSelected ${thumbnailClass()}`
                            : `sizeButton ${thumbnailClass()}`
                    }
                    onClick={(event) => {
                        event.stopPropagation();
                        downloadHandler(
                            image?.islandscape
                                ? imageSizes.mediumLandscape
                                : imageSizes.mediumPortrait,
                        );
                    }}
                >
                    Large
                </button>
                <button
                    className={
                        dimensions === ''
                            ? `sizeButton sizeButtonSelected ${thumbnailClass()}`
                            : `sizeButton ${thumbnailClass()}`
                    }
                    onClick={(event) => {
                        event.stopPropagation();
                        downloadHandler('');
                    }}
                >
                    Original
                </button>
            </div>
        </div>
    );
};

export default ImageDownload;
