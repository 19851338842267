import {
    getCall,
    postCallRaw,
    postCall,
    putCallRaw,
} from '../../../utils/api/apiWrapper';
import { OfficeDetailsSummary, OfficeDescription } from './officeModels';

export function getOfficeDetails(officeId: string): Promise<OfficeDetailsSummary> {
    return getCall<OfficeDetailsSummary>(`office/${officeId}`);
}

export function createOffice(
    officeDetails: OfficeDescription,
): Promise<OfficeDescription> {
    return postCall(`office`, officeDetails);
}

export function updateOffice(office: OfficeDescription): Promise<Response> {
    const id = office?.id;
    return putCallRaw<OfficeDescription>(`office/${id}`, office);
}

export function updateChipsData(officeId: string, data: number[]): Promise<Response> {
    return postCallRaw(`office/${officeId}/specializations`, { ids: data });
}
