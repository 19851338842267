import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
    ChangeEvent,
    useRef,
    useMemo,
} from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Grid, Input, Typography } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { getNumberValue } from './numberUtils';
import HeaderControls from './HeaderControls';
import SizeNav from './SizeNav';
import ListingTitle from './ListingTitle';
import './adCopyForm.scss';
import { DrawerContainerProps, ListingData } from '../../../listingModels';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import { adCopy, fetchAdCopies, fetchLookupData, saveAdCopy } from './adCopySlice';
import { englishLanguage, initialAdCopy, internetAdCopySize } from './adCopyConstants';
import { updateMainListingData } from '../../../listingSlice';
import RenderRemainingChar from '../../../../../../shared/component/editor/RenderRemainingChar';
import OpenAIAdCopy from '../../../../../../shared/component/openAIAdcopy';
import { getAmenityNamesForSection } from '../../../../../../shared/utils/listing/featureUtils';
import { amenitySections } from '../../../../../../shared/constants/listing/amenities';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import { Editor as TinyMCEEditor } from 'tinymce';
import CustomModal from '../../../../../../shared/component/modal';
import { getUser } from '../../../../../../shared/auth/authService';
import { userIsInAnyRole } from '../../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../../shared/constants/user/roles';
import { calculateRemainingChars } from '../../../../../../utils/urlUtils';

const AdCopyForm: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
    handleCloseStatus,
}) => {
    const dispatch = useAppDispatch();
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const apiKeyOfTinyMce = process.env.REACT_APP_TINY_MCE;
    const [isOpen, setOpen] = useState(false);
    const adCopyDetails = useAppSelector(adCopy);
    const user = useMemo(() => getUser(), []);
    const canUserView = useMemo(
        () => userIsInAnyRole(user, [userRoles.staff.key, userRoles.systemAdmin.key]),
        [user],
    );

    const { editForm } = editFormData;
    const listingId = editFormData.data.id;

    const { formData, setFormData, handleTextChange, handleEditorChange } = useFormData({
        ...initialAdCopy,
        listingId: listingId,
    });

    const [size, setSize] = useState(internetAdCopySize);
    const [language, setLanguage] = useState(englishLanguage);
    const [aiLoading, setAiLoading] = useState<boolean>(false);

    const findAdCopy = useCallback(() => {
        return (adCopyDetails.adCopies || []).find(
            (a) => a.adCopySizeId === size.id && a.languageId === language.id,
        );
    }, [adCopyDetails.adCopies, size.id, language.id]);

    const setAdCopy = () => {
        const adCopy = findAdCopy();
        setFormData({
            ...(adCopy || initialAdCopy),
            adCopySizeId: size.id,
            languageId: language.id,
            listingId: listingId,
        });
    };

    const handleSizeChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        sizeId: number,
    ) => {
        event.preventDefault();
        setSize(adCopyDetails.sizes.find((s) => s.id === sizeId) || internetAdCopySize);
        setAdCopy();
        handleCloseStatus?.(true, 0);
    };

    const handleLanguageChange = (
        e: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        const numVal = getNumberValue(e.target.value);
        setLanguage(
            adCopyDetails.languages.find((l) => l.id === numVal) || englishLanguage,
        );
        setAdCopy();
        handleCloseStatus?.(true, 0);
    };

    // Load lookups
    useEffect(() => {
        if (editForm?.formOpen) {
            if (adCopyDetails.languages.length <= 1) {
                dispatch(fetchLookupData());
            }
        }
    }, [dispatch, editForm?.formOpen, adCopyDetails.languages]);

    // Load ad copies for listing
    useEffect(() => {
        if (editForm?.formOpen && !adCopyDetails.adCopies) {
            dispatch(fetchAdCopies(listingId));
        }
    }, [editForm?.formOpen, dispatch, listingId, adCopyDetails.adCopies]);

    // Load form data
    useEffect(() => {
        if (editForm?.formOpen) {
            const adCopy = findAdCopy() || initialAdCopy;
            setFormData({
                ...adCopy,
                adCopySizeId: size.id,
                languageId: language.id,
                listingId: listingId,
            });
        }
    }, [editForm?.formOpen, listingId, findAdCopy, language.id, size.id, setFormData]);

    const save = () => {
        const remainingChars = calculateRemainingChars(
            formData.body || '',
            size.bodyLength,
        );
        dispatch(saveAdCopy(formData, size.bodyLength || 0, remainingChars));
        // If updating English internet ad copy (which is the default size/language)
        // update the main state as well
        if (remainingChars >= 0) {
            if (size.id === internetAdCopySize.id && language.id === englishLanguage.id) {
                dispatch(
                    updateMainListingData({
                        internetAdCopy: formData.body || '',
                    }),
                );
            }
            handleCloseStatus?.(true, size.bodyLength || 0);
        } else {
            handleCloseStatus?.(false, size.bodyLength || 0);
        }
    };

    //to fetch address for the AI adcopy
    const fetchAddress = (data: ListingData): string => {
        let add = data.address1 ? data.address1 : '';
        if (data.address2) {
            add = add + ', ' + data.address2;
        }
        return add;
    };

    //to fetch bathroom count for the AI adcopy
    const fetchBathRoom = (data: ListingData) => {
        const fullbath = data.fullBaths ? data.fullBaths : 0;
        const halfBaths = data.halfBaths ? data.halfBaths : 0;
        return fullbath + halfBaths;
    };

    //to fetch amenities for the AI adcopy
    const fetchAmenities = (calledFrom: string, data: ListingData) => {
        if (calledFrom === 'interior') {
            const interior = getAmenityNamesForSection(
                data.amenities,
                amenitySections.interior,
                'openAi',
            );
            return interior !== '-' ? interior.replace(/<\/?span[^>]*>/gi, '') : '';
        }
        if (calledFrom === 'outdoor') {
            const outdoor = getAmenityNamesForSection(
                data.amenities,
                amenitySections.outdoor,
                'openAi',
            );
            return outdoor !== '-' ? outdoor.replace(/<\/?span[^>]*>/gi, '') : '';
        }
        if (calledFrom === 'building') {
            const building = getAmenityNamesForSection(
                data.amenities,
                amenitySections.building,
            );
            return building !== '-' ? building : '';
        }
        return '';
    };

    //handle the respose from AI and make changes are per need and add it to editor
    const text = (adcopy: string) => {
        setAiLoading(false);
        if (adcopy.length) {
            setOpen(true);
            setFormData({
                ...formData,
                id: '',
                body: adcopy.replace(/\n/g, '<br>').replace(/(^"|"$)/g, ''),
            });
        }
    };

    //function used to verfiy whether show the AI call link
    const aiValidate = () => {
        if (size.id === internetAdCopySize.id && canUserView) {
            if (formData && formData.body && formData.body.length > 0) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    /** triggered when ai model is closed */
    const closeAINoti = () => {
        setOpen(false);
        if (editorRef.current) {
            //to set focus on the editor
            editorRef.current.focus();
        }
    };

    return (
        <>
            <ComponentWithLoader
                showLoader={aiLoading}
                showOverlay={true}
                styleClass="loaderClass"
            />
            <form>
                <HeaderControls
                    adCopyDetails={adCopyDetails}
                    handleLanguageChange={handleLanguageChange}
                    listingId={listingId}
                    languageId={language.id}
                />

                <SizeNav
                    sizeId={size.id}
                    sizes={adCopyDetails.sizes}
                    handleSizeChange={handleSizeChange}
                />

                {size.id === internetAdCopySize.id ? (
                    <ListingTitle editFormData={editFormData} />
                ) : (
                    ''
                )}

                <div className="size-header">
                    <span>
                        <span className="size-title">{size.name.toUpperCase()}</span>
                        <span className="info-text">
                            {size.showHeadline
                                ? `Headine ${size.headlineLength} characters, `
                                : ''}
                            Body: {size.bodyLength} characters
                        </span>
                    </span>
                    {aiValidate() ? (
                        <span onClick={() => setAiLoading(true)}>
                            <OpenAIAdCopy
                                address={fetchAddress(editFormData.data)}
                                city={
                                    editFormData.data.city ? editFormData.data.city : ''
                                }
                                bedroom={
                                    editFormData.data.bedrooms
                                        ? editFormData.data.bedrooms
                                        : 0
                                }
                                bathroom={fetchBathRoom(editFormData.data)}
                                buildingAmenities={fetchAmenities(
                                    'building',
                                    editFormData.data,
                                )}
                                interiorAmenities={fetchAmenities(
                                    'interior',
                                    editFormData.data,
                                )}
                                outdoorAmenities={fetchAmenities(
                                    'outdoor',
                                    editFormData.data,
                                )}
                                outputText={text}
                            />
                        </span>
                    ) : (
                        <></>
                    )}
                </div>

                {size.showHeadline ? (
                    <Input
                        name="headline"
                        fullWidth
                        disableUnderline
                        placeholder="Headline"
                        className="headline"
                        value={formData.headline || ''}
                        onChange={(e) => handleTextChange(e, size.headlineLength)}
                        onBlur={save}
                    />
                ) : (
                    ''
                )}

                <FormControl fullWidth>
                    <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        apiKey={apiKeyOfTinyMce}
                        value={formData.body || ''}
                        onEditorChange={(newValue) => {
                            handleEditorChange('body', newValue);
                        }}
                        onBlur={save}
                        init={{
                            placeholder: 'Body',
                            height: 240,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            setup: (editor) => {
                                editor.on('PostProcess', function (ed) {
                                    // we are cleaning empty paragraphs
                                    ed.content = ed.content
                                        .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                        .replace(/(<br \/>)/gi, '<br>')
                                        .replace(/(\r\n|\n|\r)/gi, '<br>')
                                        .replace(/(<br>){1,}/g, '<br>');
                                });
                            },
                            toolbar: 'bold | italic ',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                        }}
                    ></Editor>
                </FormControl>
                <RenderRemainingChar
                    value={formData.body || ''}
                    maxLength={size.bodyLength}
                />
                <CustomModal title="" show={isOpen} onClose={() => closeAINoti()}>
                    <Grid item container xs={12} md={12}>
                        <Typography>
                            <b>Proofread and VERIFY!</b> Relying solely on the information
                            generated by AI is a big mistake. AI models can sometimes
                            generate inaccurate information, so you must fact check the
                            output for accuracy and compliance with Fair Housing and
                            copyright laws.
                        </Typography>
                        <br></br>
                        <Typography>
                            As an example of the need to verify information from AI models
                            with your sellers: an agent used a tool to write a listing
                            description, and while the description looked good, there was
                            a problem. The description was inaccurate; the home had a
                            basement that was completely left out of the write-up. This
                            gap might limit the interest from buyers searching for a home
                            with a basement.
                        </Typography>
                    </Grid>
                </CustomModal>
            </form>
        </>
    );
};

export default AdCopyForm;
