import { FunctionComponent, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { OfficeChipData, OfficeDescription } from '../../../../officeModels';

interface OfficeChipsProps {
    chipsData: OfficeChipData[];
    formData: OfficeDescription;
    setFormData: React.Dispatch<React.SetStateAction<OfficeDescription>>;
    propertyName: string;
    setFormDataToPersist: (updatedFormData: OfficeDescription) => void;
}

const OfficeChips: FunctionComponent<OfficeChipsProps> = ({
    chipsData,
    formData,
    setFormData,
    propertyName,
    setFormDataToPersist,
}) => {
    const [currentChipData, setCurrentChipData] = useState<OfficeChipData[]>([
        ...chipsData,
    ]);

    useEffect(() => {
        setCurrentChipData([...chipsData]);
    }, [chipsData, setCurrentChipData]);

    const actionHandler = (id: string, toSelect: boolean) => {
        const updatedData = currentChipData.map((chip) => {
            return chip.id === id
                ? { ...chip, selected: toSelect ? true : false }
                : { ...chip };
        });
        const dataToSend = updatedData
            .filter((data: OfficeChipData) => data.selected)
            .map((selectedData: OfficeChipData) => {
                return parseInt(selectedData.id);
            });
        setCurrentChipData(updatedData);
        setFormData({
            ...formData,
            [propertyName]: dataToSend,
        });
        setFormDataToPersist({
            ...formData,
            [propertyName]: dataToSend,
        });
    };

    return (
        <div className="chipsContainer">
            {currentChipData.length > 0 ? (
                <>
                    {currentChipData.map((chip: OfficeChipData) => {
                        return chip.selected ? (
                            <Chip
                                key={chip.id}
                                label={chip.name}
                                className="selected"
                                onDelete={() => actionHandler(chip.id, false)}
                            />
                        ) : (
                            <Chip
                                key={chip.id}
                                label={chip.name}
                                onClick={() => actionHandler(chip.id, true)}
                            />
                        );
                    })}
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default OfficeChips;
