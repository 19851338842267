export const imageTypes = {
    associateLogo: 'associate-logos',
    associates: 'associates',
    FloorPlan: 'floorplans',
    MainPhotos: 'photos',
    offices: 'offices',
    ShowcaseHeroImages: 'vanity',
    UploadedImages: 'photographers', // photographer imageType for UI purposes
    photographerUpload: 'photographers-upload', // photographer imageType for API calls
    Buildings: 'buildings',
    BuildingFloorPlans: 'building-floorplans', // building floorplan imageType for API calls
    buildingFloorplans: 'buildingFloorplans', // building floorplan imageType for UI purposes
    buildings: 'buildings',
    buildingLogos: 'building-logos',
};
