import { Grid, TextField } from '@material-ui/core';
import { FunctionComponent } from 'react';
import FormRow from '../../formFields/FormRow';
import './testimonialsAdd.scss';
import RenderRemainingChar from '../../editor/RenderRemainingChar';
import { useFormData } from '../../../../app/hooks';
import { TestimonialModel } from '../../../models/testimonial/testimonial';
import { calculateRemainingChars } from '../../../../utils/urlUtils';

interface TestimonialAddModel {
    from: string;
    data: TestimonialModel;
    save: (from: string, data: TestimonialModel) => void;
    cancel: () => void;
}
const TestimonialsAdd: FunctionComponent<TestimonialAddModel> = ({
    from,
    data,
    save,
    cancel,
}) => {
    const { formData, setFormData, handleTextChange } = useFormData(data);

    const cancelAction = () => {
        if (from === 'edit') {
            cancel();
        } else {
            setFormData(data);
        }
    };

    const saveAction = () => {
        save(from, formData);
        if (from === 'add') {
            setFormData(data);
        }
    };

    const remainingChar = calculateRemainingChars(formData.testimony, 500);

    return (
        <Grid container className="testimonialsAdd">
            <Grid item container className="content" md={12}>
                <FormRow
                    label="Author"
                    control={
                        <TextField
                            value={formData.author || ''}
                            onChange={(e) => handleTextChange(e, 30)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'headline',
                                className: 'inputBaseRoot',
                            }}
                            name="author"
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot headline"
                    labelMd={2}
                    controlMd={10}
                />
            </Grid>
            <Grid item container className="content noMarginBottom" md={12}>
                <FormRow
                    label="Testimonials"
                    control={
                        <TextField
                            value={formData.testimony || ''}
                            onChange={(e) => handleTextChange(e)}
                            multiline
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'areaOfExpertise',
                                className: 'inputBaseRoot customTextArea',
                            }}
                            minRows={4}
                            name="testimony"
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot headline"
                    labelMd={2}
                    controlMd={10}
                />
            </Grid>
            <Grid item container md={12} justifyContent="flex-end">
                <Grid item md={10}>
                    <RenderRemainingChar value={formData.testimony} maxLength={500} />
                </Grid>
            </Grid>
            <Grid item container className="content" md={12} justifyContent="flex-end">
                <button
                    className="customButton"
                    onClick={saveAction}
                    disabled={
                        remainingChar < 0 ||
                        !formData.author.length ||
                        !formData.testimony.length
                    }
                >
                    {from === 'add' ? 'Save' : 'Update'}
                </button>
                <button className="customButton marginLeft" onClick={cancelAction}>
                    cancel
                </button>
            </Grid>
        </Grid>
    );
};
export default TestimonialsAdd;
