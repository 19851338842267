import { Checkbox, Grid, TextField } from '@material-ui/core';
import { FunctionComponent, useEffect } from 'react';
import { useAppSelector, useFormData } from '../../../../../../app/hooks';
import {
    DrawerContainerProps,
    AgentDescription,
    AgentDetails,
} from '../../../agentModels';
import './description.scss';
import { useAppDispatch } from '../../../../../../app/hooks';
import { setCurrentAgentEditFormData } from '../../../agentSlice';
import { editFormComponent, soldListingPriceOption } from '../../../agentConstants';
import { datePickerFormat } from '../../../../../../utils/urlUtils';
import { lookups as lookupsSlice } from '../../../../../../shared/slices/lookups/lookupsSlice';
import { statuses } from '../../../../../../shared/constants/lookups/lookupsConstants';
import {
    CommonLookups,
    LookupInfo,
} from '../../../../../../shared/models/lookups/lookupsModels';
import {
    mlsSliceState,
    getAdditionalMls,
} from '../../../../../../shared/slices/additionalMls/additionalMlsSlice';
import {
    AdditionalMls,
    MlsSave,
} from '../../../../../../shared/models/additionalMls/additionalMlsModels';
import { mlsFor } from '../../../../../../shared/constants/additionalMls/mlsConstants';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import InternetEmail from './internetEmail/InternetEmail';
import Phone from './phone/Phone';
import Mail from './mail/Mail';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import AdditionalMlsList from '../../../../../../shared/component/additionalMls';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { shouldDisableField } from '../../../../../../shared/auth/permissions/permissionsService';

const Description: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
    isCreate,
}) => {
    const editAgentDescriptionData: AgentDescription = (
        editFormData.agentDetails as AgentDetails
    ).descriptionDetails;
    const lookups = editFormData.lookups;
    const { additionalMls, isData } = useAppSelector(mlsSliceState);
    const dispatch = useAppDispatch();
    const {
        formData,
        setFormData,
        handleCheckboxChange,
        handleTextChange,
        handleNumberChange,
        handleFloatChange,
    } = useFormData(editAgentDescriptionData);
    const { common } = useAppSelector(lookupsSlice);
    const { offices, salutations } = common as CommonLookups;

    const setFormDataToPersist = () => {
        if (isCreate === undefined) {
            // publish value updated, i.e no need top validate form
            // if (editAgentDescriptionData.publish !== formValue.publish) {
            //     formValue = { ...formValue, isFormValid: true };
            // }
            dispatch(
                setCurrentAgentEditFormData({
                    formData: {
                        ...formData,
                        zip: formData?.zip?.toString() || null,
                        workPhone:
                            formData?.workPhone?.toString().replace(/\./g, '') || null,
                        mobilePhone: formData?.mobilePhone || null,
                        homePhone: formData?.homePhone || null,
                        fax: formData?.fax || null,
                    },
                    agentEditFormName: editFormComponent.description,
                }),
            );
        }
    };

    const handleDropDownChange = (data: LookupInfo, name: string) => {
        if (name === 'statusId') {
            setFormData({
                ...formData,
                statusId: Number(data.id),
                status: data.name,
            });
        } else if (name === 'agentTypeId') {
            setFormData({
                ...formData,
                agentTypeId: Number(data.id),
                type: data.name,
            });
        } else if (name === 'tridentOfficeId') {
            setFormData({
                ...formData,
                tridentOfficeId: data.id,
                tridentOffice: data.name,
            });
        } else if (name === 'webOfficeId') {
            setFormData({
                ...formData,
                webOfficeId: data.id,
                webOffice: data.name,
            });
        } else if (name === 'sortField') {
            setFormData({
                ...formData,
                sortField: data.id,
            });
        } else if (name === 'salutationTypeId') {
            setFormData({
                ...formData,
                salutationTypeId: Number(data.id),
                salutation: data.name,
            });
        } else {
            setFormData({
                ...formData,
                regionId: Number(data.id),
                state: data.name,
            });
        }
    };

    const renderField = (
        label: string,
        property: string,
        disabledInput?: boolean,
        isNumber?: boolean,
        isUrl?: boolean,
    ) => {
        return (
            <FormRow
                label={label}
                control={
                    <TextField
                        InputProps={{
                            disableUnderline: true,
                            className: `inputBaseRoot ${
                                disabledInput && isCreate === undefined
                                    ? 'inputDisabled'
                                    : ''
                            }`,
                            'aria-label': `${property}`,
                        }}
                        value={
                            formData[property as keyof typeof formData]
                                ? formData[property as keyof typeof formData]
                                : ''
                        }
                        onChange={
                            isNumber
                                ? handleNumberChange
                                : isUrl
                                ? (e) => handleTextChange(e, 255, true)
                                : handleTextChange
                        }
                        onBlur={setFormDataToPersist}
                        name={property}
                        disabled={
                            disabledInput && isCreate === undefined
                                ? disabledInput
                                : false
                        }
                    />
                }
                prefix=""
                suffix=""
                controlClass="formControlRoot"
                labelXs={null}
                labelSm={null}
                labelMd={2}
                controlXs={null}
                controlSm={null}
                controlMd={4}
            />
        );
    };

    const renderDropDown = (
        label: string,
        property: string,
        list: LookupInfo[],
        disabledInput?: boolean,
    ) => {
        return (
            <FormRow
                label={label}
                control={
                    <DropDownSearch
                        id={
                            formData[property as keyof typeof formData]
                                ? (formData[property as keyof typeof formData] as
                                      | string
                                      | number)
                                : ''
                        }
                        items={list}
                        name={property}
                        handleDropDownChange={handleDropDownChange}
                        handleBlur={setFormDataToPersist}
                        disabled={
                            disabledInput && isCreate === undefined
                                ? disabledInput
                                : false
                        }
                    />
                }
                prefix=""
                suffix=""
                controlClass={`formControlRoot ${
                    disabledInput && isCreate === undefined ? 'inputDisabled' : ''
                }`}
                labelXs={null}
                labelSm={null}
                labelMd={2}
                controlXs={null}
                controlSm={null}
                controlMd={4}
            />
        );
    };

    const renderCheckbox = (label: string, property: string, checked: boolean) => {
        return (
            <FormRow
                label={label}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                        onBlur={setFormDataToPersist}
                        name={property}
                        className="identity-checkbox"
                    />
                }
                prefix=""
                suffix=""
                controlClass=""
                labelXs={null}
                labelSm={null}
                labelMd={2}
                controlXs={null}
                controlSm={null}
                controlMd={4}
            />
        );
    };

    const renderDateRow = (label: string, property: string, disabled?: boolean) => {
        return (
            <FormRow
                label={label}
                control={
                    <DatePicker
                        value={
                            formData[property as keyof typeof formData] !== ''
                                ? moment(
                                      formData[
                                          property as keyof typeof formData
                                      ] as string,
                                  )
                                : undefined
                        }
                        format={datePickerFormat()}
                        onChange={(date: Moment | null) => {
                            // handleDateChange(property, date);
                        }}
                        onBlur={() => setFormDataToPersist()}
                        name={property}
                        showToday={false}
                        className="datePickerCommon descDate"
                        style={{
                            padding: '5px 10px',
                        }}
                        disabled={isCreate === undefined ? disabled : false}
                    />
                }
                prefix=""
                suffix=""
                controlClass="dateSelect formControlRoot"
                labelXs={null}
                labelSm={null}
                labelMd={2}
                controlXs={null}
                controlSm={null}
                controlMd={4}
            />
        );
    };

    useEffect(() => {
        if (
            !additionalMls.length &&
            (editFormData.agentDetails as AgentDetails).descriptionDetails.webOfficeId &&
            !isData
        ) {
            dispatch(
                getAdditionalMls(
                    (editFormData.agentDetails as AgentDetails).descriptionDetails
                        .webOfficeId,
                    mlsFor.agent,
                    (editFormData.agentDetails as AgentDetails).descriptionDetails
                        .agentId,
                ),
            );
        }
    }, []);

    const setMlsDataToPersist = (additionalMlsData: AdditionalMls[]) => {
        const saveData: MlsSave = {
            data: additionalMlsData.filter((mls) => mls.active),
            mlsFor: mlsFor.agent,
            id: (editFormData.agentDetails as AgentDetails).descriptionDetails.agentId,
            profileId: editAgentDescriptionData.profileId,
        };
        dispatch(
            setCurrentAgentEditFormData({
                formData: saveData,
                agentEditFormName: editFormComponent.additionalMls,
            }),
        );
    };

    return (
        <div className="description-container">
            <Grid item container md={12} className="info-text">
                * Indicates Required Fields
            </Grid>
            <div className="section">
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderField('TRIDENT ID', 'tridentId', true)}
                    {renderField('AGENT ID', 'agentId', true)}
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderField('LICENSE NUMBER', 'licenseNumber', true)}
                    {renderCheckbox('PUBLISH', 'publish', formData.publish)}
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderDropDown('STATUS', 'statusId', statuses)}
                    {renderDateRow('START DATE', 'startDate', true)};
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderField('PRIMARY MLS', 'primaryMls')}
                    {renderDropDown(
                        'SALUTATION',
                        'salutationTypeId',
                        salutations,
                        shouldDisableField(),
                    )}
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderDropDown('WEB OFFICE', 'webOfficeId', offices)}
                </Grid>
                <AdditionalMlsList
                    additionalMls={additionalMls}
                    setMlsDataToPersist={setMlsDataToPersist}
                />
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    <FormRow
                        label="FIRST NAME*"
                        control={
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className:
                                        isCreate === undefined
                                            ? 'inputBaseRoot inputDisabled'
                                            : 'inputBaseRoot',
                                    'aria-label': 'firstName',
                                }}
                                value={formData.firstName ? formData.firstName : ''}
                                onChange={(e) => handleTextChange(e, 50)}
                                onBlur={setFormDataToPersist}
                                name="firstName"
                                disabled={isCreate === undefined ? true : false}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelXs={null}
                        labelSm={null}
                        labelMd={2}
                        controlXs={null}
                        controlSm={null}
                        controlMd={4}
                    />
                    {renderField('MIDDLE NAME', 'middleName', true)}
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    <FormRow
                        label="LAST NAME*"
                        control={
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className:
                                        isCreate === undefined
                                            ? 'inputBaseRoot inputDisabled'
                                            : 'inputBaseRoot',
                                    'aria-label': 'lastName',
                                }}
                                value={formData.lastName ? formData.lastName : ''}
                                onChange={(e) => handleTextChange(e, 50)}
                                onBlur={setFormDataToPersist}
                                name="lastName"
                                disabled={isCreate === undefined ? true : false}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelXs={null}
                        labelSm={null}
                        labelMd={2}
                        controlXs={null}
                        controlSm={null}
                        controlMd={4}
                    />
                    {renderDropDown('TYPE*', 'agentTypeId', lookups.agentTypes)}
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    <FormRow
                        label="PREFERRED FIRST NAME"
                        control={
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                    'aria-label': 'preferred first name',
                                }}
                                value={
                                    formData.preferredFirstName
                                        ? formData.preferredFirstName
                                        : ''
                                }
                                onChange={(e) => handleTextChange(e, 50)}
                                onBlur={setFormDataToPersist}
                                name="preferredFirstName"
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                    <FormRow
                        label="PREFERRED LAST NAME"
                        control={
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'inputBaseRoot',
                                    'aria-label': 'preferred last name',
                                }}
                                value={
                                    formData.preferredLastName
                                        ? formData.preferredLastName
                                        : ''
                                }
                                onChange={(e) => handleTextChange(e, 50)}
                                onBlur={setFormDataToPersist}
                                name="preferredLastName"
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderField('TITLE', 'title')}
                    {renderDropDown('TRIDENT OFFICE*', 'tridentOfficeId', offices, true)}
                </Grid>
            </div>
            <Mail
                formData={formData}
                lookups={common as CommonLookups}
                handleNumberChange={handleNumberChange}
                setFormDataToPersist={setFormDataToPersist}
                renderField={renderField}
                renderDropDown={renderDropDown}
                handleFloatChange={handleFloatChange}
            />
            <Phone formData={formData} renderField={renderField} />
            <InternetEmail formData={formData} renderField={renderField} />
            <div className="section">
                <Grid className="info-text content" item container sm={12} md={12}>
                    Select how you would like the select sales and rentals ordered on the
                    website:
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderDropDown('FIELD', 'sortField', soldListingPriceOption)};
                </Grid>

                <Grid className="info-text content" item container sm={12} md={12}>
                    Should all of your new listings be set as a select sale?
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    alignItems="center"
                    className="content"
                    spacing={2}
                >
                    {renderCheckbox(
                        'DEFAULT SELECT SALE',
                        'defaultSelectSale',
                        formData.defaultSelectSale || false,
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default Description;
