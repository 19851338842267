import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import { ImageDeleteModel } from '../../../../mediaOutlets/component/createAndEdit/createEditMediaOutletModel';
import {
    createSpotsApi,
    deleteReservedApi,
    deleteSpotApi,
    deleteSpotTypeImageApi,
    fetchIndividualSpotApi,
    fetchSelectedSpotAgentBillingApi,
    getReservedSpotsApi,
    getSpotsApi,
    reservationSpotApi,
    saveSelectedSpotAgentBillingApi,
    spotRequestApi,
} from './spotTypeApi';
import {
    Spot,
    SpotAssignedListingsResponse,
    SpotAssignedListingUpdateFlag,
    SpotTypeState,
    UpdateIndividualSpotTypeFromList,
    AssignListingToSpotRequest,
    SpotReservationRequest,
    SpotReservationResponse,
    SpotTableActionType,
    SpotCreateRequest,
    ReservedSpotEntity,
    ReservedSpotGroupingModel,
    AssignListingSearchRequestModel,
    RequestSpotModel,
    EditSpotReservationRequest,
    SpotListingSearchRequest,
} from './spotTypeModels';
import Logger from '../../../../../../utils/logging/logger';
import {
    assignListingToSpotApi,
    searchListingsApi,
} from './assignListingReserveSpots/reservationHolder/component/assignListing/assignListingApi';
import {
    PaginationRequest,
    PaginationResponse,
} from '../../../../../../shared/models/pagination/paginationModels';
import lodash from 'lodash';
import {
    initiatePaginationResponse,
    reservedSpotGroupingUtil,
    setFilesToPropsIfUploaded,
} from './spotTypeUtils';
import { setProgress } from '../../../advertisementSlice';
import { findNameFromLookups } from '../../../../../../utils/urlUtils';
import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import {
    initialAgentCostTableAction,
    initialAssignedListingsTableAction,
    initialSpotTableAction,
} from './spotTypeConstants';
import { AgentSelectedToBeBilled } from '../../../../../../shared/models/agentBilling/agentBilling';
import {
    fetchIndiSpotStatisticsApi,
    getSpotAssignedListingsApi,
} from '../../../../../../shared/api/spotBooking/spotBooking';
import {
    AgentSearchRequest,
    AgentsResponse,
} from '../../../../../agents/agentListModels';
import { getAgentsFromApi } from '../../../../../agents/agentListApi';
import { deleteSpotAssignedListingApi } from '../../../../../../shared/api/advertisement/advertisementApi';

export const initialSpotTypeState: SpotTypeState = {
    spotListLoading: true,
    spotTypes: [],
    individualSpotData: null,
    reservedSpots: [],
    selectedReservedSpot: undefined,
    reservedSpotGrouping: undefined,
    searchedAgents: [],
    tableAction: initialSpotTableAction,
    searchedListings: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    assignedListingsTableAction: initialAssignedListingsTableAction,
    assignedListings: initiatePaginationResponse<SpotAssignedListingsResponse>(),
    agentCostTableAction: initialAgentCostTableAction,
    selectedSpotAgentCost: initiatePaginationResponse<AgentSelectedToBeBilled>(),
    flags: {
        isAdding: false,
        isLoading: false,
        isRemoving: false,
        isSearching: false,
        searchingAgents: false,
    },
};

export const spotTypeSlice = createSlice({
    name: 'spotType',
    initialState: initialSpotTypeState,
    reducers: {
        setSpotProgress: (state, action: PayloadAction<boolean>) => {
            state.spotListLoading = action.payload;
        },
        addSpotTypes: (state, action: PayloadAction<Spot[]>) => {
            return {
                ...state,
                spotTypes: action.payload,
            };
        },
        updateIndividualSpotTypeFromList: (
            state,
            action: PayloadAction<UpdateIndividualSpotTypeFromList>,
        ) => {
            const spotTypeList = state?.spotTypes || [];
            const { calledFrom, responseData, imageDeleteData } = action.payload;
            if (
                (calledFrom === 'update' || calledFrom === 'imageDelete') &&
                state.spotTypes
            ) {
                const spotTypeId = responseData?.id || imageDeleteData?.id;
                const findSpotTypeIndex = state.spotTypes.findIndex(
                    (x) => x.id === spotTypeId,
                );
                if (calledFrom === 'update') {
                    const newValue = {
                        ...spotTypeList[findSpotTypeIndex],
                        ...responseData,
                    };
                    state.spotTypes[findSpotTypeIndex] = newValue;
                } else if (calledFrom === 'imageDelete') {
                    state.spotTypes[findSpotTypeIndex].tearSheet = '';
                    state.spotTypes[findSpotTypeIndex].tearSheetFile = undefined;
                    if (state.individualSpotData) {
                        state.individualSpotData.tearSheet = '';
                        state.individualSpotData.tearSheetFile = undefined;
                    }
                }
            } else if (calledFrom === 'create' && state.spotTypes && responseData) {
                return {
                    ...state,
                    spotTypes: [responseData, ...spotTypeList],
                };
            }
        },
        deleteSpotFromList: (state, action: PayloadAction<string>) => {
            const updatedSpotList = state.spotTypes.filter(
                (x) => x.id !== action.payload,
            );
            return {
                ...state,
                spotTypes: [...updatedSpotList],
            };
        },
        setSearchedAgents: (state, action: PayloadAction<AgentsResponse[]>) => {
            return {
                ...state,
                searchedAgents: action.payload,
            };
        },
        setSearchedListings: (
            state,
            action: PayloadAction<PaginationResponse<SpotAssignedListingsResponse>>,
        ) => {
            let data = [...state.searchedListings.results, ...action.payload.results];
            if (state.searchedListings.currentPage >= action.payload.currentPage) {
                data = action.payload.results;
            }
            return {
                ...state,
                searchedListings: {
                    ...state.searchedListings,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: data,
                },
            };
        },
        updateAssignedListings: (
            state,
            action: PayloadAction<PaginationResponse<SpotAssignedListingsResponse>>,
        ) => {
            let results = [...state.assignedListings.results, ...action.payload.results];
            if (state.assignedListings.currentPage >= action.payload.currentPage) {
                results = action.payload.results;
            }
            return {
                ...state,
                assignedListings: {
                    ...state.assignedListings,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: results,
                },
            };
        },
        removeListingFromSpotType: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                assignedListings: {
                    ...state.assignedListings,
                    results: state.assignedListings.results.filter(
                        (assignedListing) => assignedListing.id !== action.payload,
                    ),
                    totalRecords: state.assignedListings.totalRecords - 1,
                },
            };
        },
        updateFlagStatus: (
            state,
            action: PayloadAction<SpotAssignedListingUpdateFlag>,
        ) => {
            state.flags[action.payload.key as keyof typeof state.flags] =
                action.payload.value;
        },
        reservedSpotList: (state, action: PayloadAction<SpotReservationResponse[]>) => {
            return {
                ...state,
                reservedSpots: action.payload,
            };
        },
        deleteSpotReservation: (state, action: PayloadAction<string>) => {
            const updatedReservedList = state.reservedSpots.filter(
                (x) => x.id !== action.payload,
            );
            return {
                ...state,
                reservedSpots: [...updatedReservedList],
            };
        },
        editSpotReservation: (
            state,
            action: PayloadAction<{ id: string; spotCnt: number }>,
        ) => {
            const updatedReservedList = state.reservedSpots.map((x) => {
                if (x.id === action.payload.id) {
                    return { ...x, numberOfSpotsReserved: action.payload.spotCnt };
                } else {
                    return x;
                }
            });
            return {
                ...state,
                reservedSpots: [...updatedReservedList],
            };
        },
        addToReservedSpotList: (
            state,
            action: PayloadAction<SpotReservationResponse[]>,
        ) => {
            const reservedSpotArray = [...state.reservedSpots, ...action.payload];
            return {
                ...state,
                reservedSpots: reservedSpotArray,
            };
        },
        setIndividualspotData: (
            state,
            action: PayloadAction<{
                from: string;
                data: Spot | Partial<Spot> | null;
            }>,
        ) => {
            const { data, from } = action.payload;
            let indiData = data as Spot;
            if (from === 'partialUpdate')
                indiData = { ...state.individualSpotData, ...data } as Spot;
            return {
                ...state,
                individualSpotData: indiData,
            };
        },
        updateTableAction: (state, action: PayloadAction<SpotTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        updateAssignedListingsTableAction: (
            state,
            action: PayloadAction<SpotTableActionType>,
        ) => {
            const finalData = { ...state.assignedListingsTableAction, ...action.payload };
            return {
                ...state,
                assignedListingsTableAction: finalData,
            };
        },
        updateAgentCostTableAction: (
            state,
            action: PayloadAction<SpotTableActionType>,
        ) => {
            const finalData = { ...state.agentCostTableAction, ...action.payload };
            return {
                ...state,
                agentCostTableAction: finalData,
            };
        },
        setSelectedReservedSpot: (
            state,
            action: PayloadAction<ReservedSpotEntity | undefined>,
        ) => {
            return {
                ...state,
                selectedReservedSpot: action.payload,
            };
        },
        setReservedSpotGrouping: (
            state,
            action: PayloadAction<ReservedSpotGroupingModel[] | undefined>,
        ) => {
            return {
                ...state,
                reservedSpotGrouping: action.payload,
            };
        },
        setSelectedSpotAgentCost: (
            state,
            action: PayloadAction<PaginationResponse<AgentSelectedToBeBilled>>,
        ) => {
            let results = [
                ...state.selectedSpotAgentCost.results,
                ...action.payload.results,
            ];
            if (state.selectedSpotAgentCost.currentPage >= action.payload.currentPage) {
                results = action.payload.results;
            }
            return {
                ...state,
                selectedSpotAgentCost: {
                    ...state.selectedSpotAgentCost,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: results,
                },
            };
        },
    },
});

export const getSpotTypeList =
    (advertisementId: string, officeIds: string[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSpotProgress(true));
            const response = await getSpotsApi(advertisementId, officeIds);
            if (response.length >= 0) {
                dispatch(addSpotTypes(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get Spot`));
            Logger.error(`Failed to get Spot ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSpotProgress(false));
        }
    };

// Function to trigger an API call to save form data in case of change
export const saveSpotTypeFormData =
    (formData: SpotCreateRequest, advId: string): AppThunk =>
    async (dispatch, getState) => {
        const currentSpotTypeDetails =
            getState().advertisement.spotType.individualSpotData;
        if (!isFormDataChanged(formData, currentSpotTypeDetails)) {
            const jsonData = setFilesToPropsIfUploaded(formData, advId);
            jsonData.delete('listingIds');
            dispatch(createSpot('update', jsonData));
        }
    };

// Function to check if the current form data is equivalent or not with old data.
export const isFormDataChanged = (
    currentState: SpotCreateRequest,
    initialState: Spot | null,
): boolean => {
    let flag = true;
    for (const [key, value] of Object.entries(currentState)) {
        if (initialState && initialState.hasOwnProperty(key)) {
            if (lodash.isEqual(value, initialState[key as keyof typeof initialState])) {
                flag = true;
            } else {
                flag = false;
                break;
            }
        }
    }
    return flag;
};

export const createSpot =
    (from: string, data: FormData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await createSpotsApi(data);
            if (response.id !== '') {
                const data = {
                    calledFrom: from,
                    responseData: response,
                };
                dispatch(setIndividualspotData({ from: 'fullUpdate', data: response }));
                dispatch(fetchIndividualSpotStatistics(response.id, data));
                dispatch(
                    setSuccess(
                        `Spot ${from === 'create' ? 'created' : 'updated'} Successfully`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(
                setError(`Failed to ${from === 'create' ? 'create' : 'update'} Spot`),
            );
            Logger.error(
                `Failed to ${
                    from === 'create' ? 'create' : 'update'
                }  Spot ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const deleteSpotTypeImages =
    (dataToBeDeleted: ImageDeleteModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteSpotTypeImageApi(dataToBeDeleted);
            if (response.status === 204) {
                const deleteImage = {
                    calledFrom: 'imageDelete',
                    imageDeleteData: dataToBeDeleted,
                };
                dispatch(updateIndividualSpotTypeFromList(deleteImage));
                dispatch(setSuccess(`File removed from server`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete file from server`));
            Logger.error(
                `Failed to delete file from server ${JSON.stringify(exception)}`,
            );
        }
    };

export const searchListings =
    (searchListingsRequest: AssignListingSearchRequestModel): AppThunk =>
    async (dispatch) => {
        try {
            //to get the last advertised date for listings
            const request = { ...searchListingsRequest, isLastAdvertisedRequired: true };
            const response = await searchListingsApi(request);
            if (response.currentPage || response.recordsPerPage) {
                const searchedListings: SpotAssignedListingsResponse[] =
                    response.results.map((listing) => {
                        return {
                            agentName: listing.agentName,
                            address1: listing.address1 || '',
                            address2: listing.address2 || '',
                            businessPurposeTypeId:
                                listing.businessPurposeTypeId.toString(),
                            currentPrice: listing?.currentPrice || 0,
                            id: '',
                            isValid: listing.isValid,
                            publish: listing.publish,
                            listingId: listing.listingId,
                            mlsNumber: listing?.mlsNumber || '',
                            rfgid: '',
                            status: listing.status,
                            listingNumber: listing?.listingNumber || '',
                            officeName: listing?.officeName || '',
                            lastAdvertised: listing.lastAdvertised || '',
                        };
                    });
                const listings: PaginationResponse<SpotAssignedListingsResponse> = {
                    currentPage: response.currentPage,
                    totalRecords: response.totalRecords,
                    recordsPerPage: response.recordsPerPage,
                    results: searchedListings,
                };
                dispatch(setSearchedListings(listings));
                dispatch(updateFlagStatus({ key: 'isSearching', value: false }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the listings`));
        }
    };

export const assignListingToSpot =
    (assignListing: Partial<AssignListingToSpotRequest>): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isAdding', value: true }));
            const response = await assignListingToSpotApi(assignListing);
            if (response?.id?.length) {
                //to update the individual spot statistics
                dispatch(fetchIndividualSpotStatistics(response.spotTypeId));
                // to get the latest agent cost details based on the listing selected
                dispatch(
                    getAssignedListingsForSpot(
                        response.spotTypeId,
                        initialAssignedListingsTableAction,
                    ),
                );
                dispatch(
                    fetchSelectedSpotAgentBilling(
                        response.spotTypeId,
                        initialAgentCostTableAction,
                    ),
                );
                dispatch(setSuccess('Listing has been assigned to Spot successfully'));
                //to call the reserved spot api thus we can get the latest filled details
                if (response?.spotReservationId && response?.spotReservationAssignmentId)
                    dispatch(getReservedDataForSpot(response.spotTypeId));
            }
        } catch (exception) {
            if (exception && (exception as Error).message) {
                dispatch(setError((exception as Error).message));
            } else {
                dispatch(setError('Can not assign listing to the Spot'));
            }
            Logger.error(
                `Can not assign listing to the Spot ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateFlagStatus({ key: 'isAdding', value: false }));
        }
    };

export const getAssignedListingsForSpot =
    (spotId: string, request: SpotListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isLoading', value: true }));
            const response = await getSpotAssignedListingsApi(spotId, request);
            if (response.results.length >= 0) {
                dispatch(updateAssignedListings(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the assigned listings for spot`));
            Logger.error(
                `Failed to fetch the assigned listings ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };

export const deleteAssignedListingFromSpot =
    (
        advertisementId: string,
        spotTypeId: string,
        spotTypeListingId: string,
        callReservedList: boolean,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isRemoving', value: true }));
            const response = await deleteSpotAssignedListingApi(
                advertisementId,
                spotTypeListingId,
            );
            if (response.status === 204) {
                dispatch(removeListingFromSpotType(spotTypeListingId));
                dispatch(fetchIndividualSpotStatistics(spotTypeId));
                // to get the latest agent cost details based on the listing selected
                dispatch(
                    fetchSelectedSpotAgentBilling(
                        spotTypeId,
                        initialAgentCostTableAction,
                    ),
                );
                dispatch(setSuccess('Listing has been successfully removed from Spot'));
                //to call the reserved spot api thus we can get the latest filled details
                if (callReservedList) dispatch(getReservedDataForSpot(spotTypeId));
            }
        } catch (exception) {
            dispatch(setError(`Failed to remove the selected listings`));
            Logger.error(
                `Failed to remove the selected listings ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateFlagStatus({ key: 'isRemoving', value: false }));
        }
    };

export const deleteSpots =
    (advertisementId: string, spotTypeId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteSpotApi(advertisementId, spotTypeId);
            if (response.status === 204) {
                dispatch(setSuccess(`Spot deleted successfully`));
                dispatch(deleteSpotFromList(spotTypeId));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete the selected Spot`));
            Logger.error(
                `Failed to delete fthe selected Spot ${JSON.stringify(exception)}`,
            );
        } finally {
        }
    };

export const officeAgentSpotReservation =
    (reservationData: SpotReservationRequest): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await reservationSpotApi(reservationData);
            const { offices } = getState().lookups.common as CommonLookups;
            if (response.id !== '') {
                dispatch(setSuccess(`Spot(s) has been reserved successfully`));
                const updatedResponse: SpotReservationResponse[] = [];
                (response.entityIds as string[]).forEach((item: string) => {
                    if (response.entityTypeId === 3) {
                        updatedResponse.push({
                            ...response,
                            office: findNameFromLookups(item, offices),
                        });
                    } else if (response.entityTypeId === 4) {
                        updatedResponse.push(response);
                    }
                });
                dispatch(addToReservedSpotList(updatedResponse)); //to update the reserved spot list
                //to update the individual spot statistics
                dispatch(fetchIndividualSpotStatistics(reservationData.spotTypeId));
            } else {
                throw Error(response.responseText);
            }
        } catch (exception) {
            dispatch(setError(`Failed to reserve the Spot(S)`));
            Logger.error(`Failed to reserve the Spot(S) ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };

export const getReservedDataForSpot =
    (spotTypeId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isLoading', value: true }));
            const response = await getReservedSpotsApi(spotTypeId);
            if (response.length >= 0) {
                dispatch(reservedSpotList(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the reserved spot list`));
            Logger.error(
                `Failed to fetch the reserved spot list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };

export const deleteReserved =
    (
        advertisementId: string,
        spotId: string,
        ReservedSpotId: string,
        removeListingTo: boolean,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await deleteReservedApi(
                advertisementId,
                ReservedSpotId,
                removeListingTo,
            );
            if (response.status === 204) {
                dispatch(setSuccess(`Reserved spot is deleted successfully`));
                dispatch(deleteSpotReservation(ReservedSpotId));
                // dispatch(getSpotTypeList(advertisementId, [])); //to update the main spot table
                //to update the individual spot and data in spot list
                dispatch(fetchIndividualSpot(spotId));
                //to remove listing from the assigned lisitng list based on
                // the user selection
                if (removeListingTo) {
                    dispatch(
                        getAssignedListingsForSpot(
                            spotId,
                            initialAssignedListingsTableAction,
                        ),
                    );
                } else {
                    //to update the grouping based on the reserved spot removal
                    const reserved = getState().advertisement.spotType.reservedSpots;
                    const reservedWithText = reservedSpotGroupingUtil(reserved);
                    dispatch(setReservedSpotGrouping(reservedWithText));
                }
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete the reserved Spot`));
            Logger.error(
                `Failed to delete the reserved Spot ${JSON.stringify(exception)}`,
            );
        } finally {
        }
    };

export const fetchIndividualSpot =
    (spotId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await fetchIndividualSpotApi(spotId);
            if (response.id !== '') {
                const statistics = await fetchIndiSpotStatisticsApi(spotId);
                const updatedResponse = { ...response, ...statistics };
                dispatch(
                    setIndividualspotData({ from: 'fullUpdate', data: updatedResponse }),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the spot details`));
            Logger.error(`Failed to fetch the spot details ${JSON.stringify(exception)}`);
        } finally {
        }
    };

export const fetchIndividualSpotStatistics =
    (spotTypeId: string, data?: UpdateIndividualSpotTypeFromList): AppThunk =>
    async (dispatch) => {
        try {
            const response = await fetchIndiSpotStatisticsApi(spotTypeId);
            if (response.id !== '') {
                dispatch(
                    setIndividualspotData({ from: 'partialUpdate', data: response }),
                );
                if (data) {
                    dispatch(
                        updateIndividualSpotTypeFromList({
                            calledFrom: data.calledFrom,
                            responseData: {
                                ...data.responseData,
                                ...(response as Spot),
                            },
                        }),
                    );
                }
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the spot statistics`));
            Logger.error(
                `Failed to fetch the spot statistics ${JSON.stringify(exception)}`,
            );
        } finally {
        }
    };

export const fetchSelectedSpotAgentBilling =
    (spotTypeId: string, agentCostRequest: PaginationRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isLoading', value: true }));
            const response = await fetchSelectedSpotAgentBillingApi(
                spotTypeId,
                agentCostRequest,
            );
            if (response.results.length >= 0) {
                dispatch(setSelectedSpotAgentCost(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the agent cost`));
            Logger.error(`Failed to fetch the agent cost ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };

export const saveSelectedSpotAgentBilling =
    (spotId: string, data: AgentSelectedToBeBilled[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isAdding', value: true }));
            const response = await saveSelectedSpotAgentBillingApi(spotId, data);
            if (response.status === 204) {
                //reset state to empty
                dispatch(
                    setSelectedSpotAgentCost(
                        initiatePaginationResponse<AgentSelectedToBeBilled>(),
                    ),
                );
                dispatch(setSuccess('Agent reservation costs are updated successfully'));
            }
        } catch (exception) {
            dispatch(setError(`Failed to update agent cost`));
            Logger.error(`Failed to update agent cost ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateFlagStatus({ key: 'isAdding', value: false }));
        }
    };

export const spotRequest =
    (requestData: RequestSpotModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'isLoading', value: true }));
            const response = await spotRequestApi(requestData);
            if (response.status === 204) {
                dispatch(
                    setSuccess(
                        `Successfully requested the spot. Marketing staff will contact you for more information`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Cannot request a spot, please try agein later`));
            Logger.error(`Failed to request a spot ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };

export const getAgents =
    (agentsRequest: AgentSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus({ key: 'searchingAgents', value: true }));
            const response = await getAgentsFromApi(agentsRequest);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.totalRecords ||
                response.results.length
            ) {
                dispatch(setSearchedAgents(response.results));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the agents`));
        } finally {
            dispatch(updateFlagStatus({ key: 'searchingAgents', value: false }));
        }
    };

export const officeAgentEditSpotReservation =
    (reservationData: EditSpotReservationRequest): AppThunk =>
    async (dispatch) => {
        try {
            const response = await reservationSpotApi(reservationData);
            if (response.id !== '') {
                dispatch(setSuccess(`Reserved spot count have been successfully`));
                //to update the edited reserved spot details in list
                dispatch(
                    editSpotReservation({
                        id: reservationData.id,
                        spotCnt: reservationData.numberOfSpotsReserved,
                    }),
                );
                //to update the individual spot statistics
                dispatch(fetchIndividualSpotStatistics(reservationData.spotTypeId));
            } else {
                throw Error(response.responseText);
            }
        } catch (exception) {
            dispatch(setError(`Failed to update the reserved Spot(S)`));
            Logger.error(
                `Failed to update the reserved Spot(S) ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateFlagStatus({ key: 'isLoading', value: false }));
        }
    };
export const {
    addSpotTypes,
    updateIndividualSpotTypeFromList,
    setSearchedListings,
    updateAssignedListings,
    removeListingFromSpotType,
    updateFlagStatus,
    deleteSpotFromList,
    setSpotProgress,
    deleteSpotReservation,
    reservedSpotList,
    addToReservedSpotList,
    setIndividualspotData,
    updateTableAction,
    updateAssignedListingsTableAction,
    setSelectedReservedSpot,
    setReservedSpotGrouping,
    setSelectedSpotAgentCost,
    setSearchedAgents,
    editSpotReservation,
    updateAgentCostTableAction,
} = spotTypeSlice.actions;

export const spotType = (state: RootState): SpotTypeState => state.advertisement.spotType;

export default spotTypeSlice.reducer;
