import {
    fetchUserPermissions,
    userIsInAnyRole,
} from '../../shared/auth/permissions/permissionsService';
import { postCall, putCallRaw, putCall } from '../../utils/api/apiWrapper';
import { getUser } from '../../shared/auth/authService';
import { ListingsResponse, ListingsRequestAPI } from './listingListModels';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { userRoles } from '../../shared/constants/user/roles';
import { isManager } from '../../shared/utils/shared/sharedUtils';

export function getListingsFromApi(
    listingsRequest: ListingsRequestAPI,
): Promise<PaginationResponse<ListingsResponse>> {
    const url = `listing/search`;
    const isManagerRole = userIsInAnyRole(getUser(), [userRoles.branchManager.key]);
    if (listingsRequest.status.includes('Sold') && !isManagerRole) {
        listingsRequest.agentIds = fetchUserPermissions(getUser(), 'agentIds');
    } else if (!listingsRequest.status.includes('Coming Soon')) {
        //update agent id and agent role for manager
        const data = isManager(listingsRequest);
        listingsRequest.agentIds = data.agentIds;
        listingsRequest.roleIds = data.roleIds;
    }

    if (listingsRequest.sortColumn === '') {
        delete listingsRequest.sortColumn;
        delete listingsRequest.sortDirection;
    }
    return postCall<ListingsRequestAPI, PaginationResponse<ListingsResponse>>(
        url,
        listingsRequest,
    );
}

export function radioStatusUpdate(
    id: string,
    name: string,
    status: boolean,
    agentid?: string,
): Promise<Response> {
    let url = `listing/${id}/toggleshowsoldpriceforselectsold/${status}`;
    switch (name) {
        case 'useAddressAsTitle':
            url = `listing/${id}/toggleuseaddress/${status}`;
            return getResponse(status, url);
        case 'isSelectSale':
            url = `listing/${id}/agent/${agentid}/toggleselectsale?toggleStatus=${status}`;
            return getResponse(status, url, true);
        case 'showMap':
            url = `listing/${id}/toggleshowmap/${status}`;
            return getResponse(status, url);
        case 'suppressMlsImport':
            url = `listing/${id}/togglesuppressmls`;
            return getResponse(status, url);
        case 'publish':
            url = `listing/${id}/togglepublish/${status}`;
            return getResponse(status, url);
        case 'showAddress':
            url = `listing/${id}/toggleshowaddress/${status}`;
            return getResponse(status, url);
        default:
            return getResponse(status, url);
    }
}

const getResponse = (status: boolean, url: string, getRaw = false) => {
    const requestJson = {
        toggleStatus: status,
    };
    return getRaw
        ? putCallRaw(url, requestJson)
        : putCall<{ toggleStatus: boolean }, Response>(url, requestJson);
};
