import { combineReducers } from '@reduxjs/toolkit';
import agents from '../../features/agents/agentListSlice';
import agentDashboard from '../../features/agents/agentDashboard/agentSlice';
import { AgentsState } from '../../features/agents/agentListModels';
import { AgentDashboardState } from '../../features/agents/agentDashboard/agentModels';

interface AgentRootState {
    list: AgentsState;
    details: AgentDashboardState;
}

export default combineReducers<AgentRootState>({
    list: agents,
    details: agentDashboard,
});
