import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UpcomingEventsState, UpcomingEventsStateUpdate } from './upcomingEventsModels';
import {
    getOpenHousesApi,
    getShowingsApi,
} from '../../../../shared/api/openHouseShowings/openHouseShowingsApi';
import { AppThunk, RootState } from '../../../../app/store';
import Logger from '../../../../utils/logging/logger';
import { setError } from '../../../../shared/slices/messaging/messagingSlice';
import { UpdateFlag } from '../../../../shared/models/flag/flagModel';

const initialState: UpcomingEventsState = {
    showings: [],
    flags: {
        showingFlag: false,
        openHouseFlag: false,
    },
    openHouses: [],
};

export const upcomingEventsSlice = createSlice({
    name: 'upcomingEvents',
    initialState: initialState,
    reducers: {
        updateEventsState: (state, action: PayloadAction<UpcomingEventsStateUpdate>) => {
            return {
                ...state,
                [action.payload.property]: [...action.payload.value],
            };
        },
        updateFlag: (state, action: PayloadAction<UpdateFlag>) => {
            state.flags[action.payload.property as keyof typeof initialState.flags] =
                action.payload.value;
        },
        setInitialState: (state) => {
            return {
                ...state,
                showings: [],
                flags: {
                    showingFlag: false,
                    openHouseFlag: false,
                },
                openHouses: [],
            };
        },
    },
});

export const fetchShowings =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlag({ property: 'showingFlag', value: true }));
            const showingResponse = await getShowingsApi(id);
            if (showingResponse.length > 0) {
                dispatch(
                    updateEventsState({
                        property: 'showings',
                        value: showingResponse,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Error fetching showings');
            dispatch(setError('Error fetching Showings'));
        } finally {
            dispatch(updateFlag({ property: 'showingFlag', value: false }));
        }
    };

export const fetchOpenHouses =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlag({ property: 'openHouseFlag', value: true }));
            const openHouse = await getOpenHousesApi(id);
            if (openHouse.length > 0) {
                dispatch(
                    updateEventsState({
                        property: 'openHouses',
                        value: openHouse,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Error fetching open houses');
            dispatch(setError('Error fetching Open Houses'));
        } finally {
            dispatch(updateFlag({ property: 'openHouseFlag', value: false }));
        }
    };

export const { updateFlag, setInitialState, updateEventsState } =
    upcomingEventsSlice.actions;
export const upcomingEvents = (state: RootState): UpcomingEventsState =>
    state.listing.upcomingEvents;

export default upcomingEventsSlice.reducer;
