import { Button, Grid, Switch } from '@material-ui/core';
import { ChangeEvent, FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import { getUser } from '../../../../../../../../shared/auth/authService';
import { dateFormatMMDDYYY } from '../../../../../../../../utils/urlUtils';
import {
    DomainModel,
    ReleaseRequest,
    RenewDomainRequest,
    ShowCaseModel,
} from '../../showcaseWebsiteModels';
import { redeem, release, renew } from '../../showcaseWebsiteSlice';
import { userIsInRole } from '../../../../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../../../../shared/constants/user/roles';
import { listingDetail } from '../../../../../listingSlice';
import { domainStatuses } from '../../showcaseWebsiteConstants';

interface DomainInfoProps {
    domainData: DomainModel;
    formData: ShowCaseModel;
    showCaseData: ShowCaseModel;
    switchChange(e: ChangeEvent<HTMLInputElement>, name: string): void;
}

const DomainInfo: FunctionComponent<DomainInfoProps> = ({
    domainData,
    formData,
    showCaseData,
    switchChange,
}) => {
    const dispatch = useAppDispatch();
    const user = getUser();
    const isAdmin = userIsInRole(user, userRoles.systemAdmin.key);
    const listingInfo = useAppSelector(listingDetail);
    const officeId = listingInfo.data.officeId;
    const { expirationDate, goDaddyStatus, isRenewable, isRedeemable } = domainData;
    const nineteenDaysFromExpiry = new Date(expirationDate);
    nineteenDaysFromExpiry.setDate(nineteenDaysFromExpiry.getDate() + 19);
    const thirtyDaysFromExpiry = new Date(expirationDate);
    thirtyDaysFromExpiry.setDate(thirtyDaysFromExpiry.getDate() + 30);
    const currentDate = new Date();

    const displayDomainStatus = (): string => {
        if ([domainStatuses[0], domainStatuses[1]].includes(goDaddyStatus))
            return goDaddyStatus;
        else if (
            currentDate > new Date(expirationDate) &&
            currentDate < nineteenDaysFromExpiry &&
            isRenewable
        )
            return domainStatuses[2] + ' - RENEWABLE';
        else if (
            currentDate > nineteenDaysFromExpiry &&
            currentDate < thirtyDaysFromExpiry &&
            isRedeemable
        )
            return domainStatuses[2] + ' - REDEEMABLE';
        else return domainStatuses[3];
    };

    const showRenewalButton = (): boolean => {
        return (
            domainData.status !== 0 &&
            currentDate < nineteenDaysFromExpiry &&
            !showCaseData.alreadyOwned &&
            isRenewable
        );
    };

    const showRedeemButton = (): boolean => {
        return (
            currentDate > nineteenDaysFromExpiry &&
            currentDate < thirtyDaysFromExpiry &&
            isAdmin &&
            isRedeemable
        );
    };

    const handleRenewButton = () => {
        const request: RenewDomainRequest = {
            id: domainData.id,
            domain: domainData.domainUrl,
            user: '',
            userEmail: getUser().email,
            officeId: officeId || '',
        };
        dispatch(renew(request));
    };

    const handleRedeemButton = () => {
        dispatch(redeem({ domainUrl: domainData.domainUrl }));
    };

    const handleReleaseButton = () => {
        const releaseData: ReleaseRequest = {
            domainId: domainData.id,
        };
        dispatch(
            release(
                releaseData,
                showCaseData.privateSite,
                showCaseData.publish,
                showCaseData.listingId,
            ),
        );
    };

    return (
        <Grid container className="domainGrid">
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    DOMAIN
                </Grid>
                <Grid item className="domainBody">
                    {domainData?.domainUrl || '-'}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    EXPIRATION
                </Grid>
                <Grid item className="domainBody">
                    {dateFormatMMDDYYY(domainData.expirationDate, 'slash') || '-'}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    RENEWAL <br></br>DEADLINE
                </Grid>
                <Grid item className="domainBody">
                    {dateFormatMMDDYYY(domainData.renewDeadline, 'slash') || '-'}
                </Grid>
            </Grid>{' '}
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    STATUS
                </Grid>
                <Grid item className="domainBody">
                    {displayDomainStatus()}
                </Grid>
            </Grid>{' '}
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    ACTIVATE
                </Grid>
                <Grid item className="domainBody">
                    <div className="customSwitch">
                        <Switch
                            className="switchIcon"
                            name="publish"
                            checked={formData.publish}
                            onChange={(e) => switchChange(e, 'publish')}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
                <Grid item className="domainHeader">
                    Site Actions
                </Grid>
                <Grid item className="domainBody">
                    {showRenewalButton() ? (
                        <Button
                            className="domainActionButton"
                            onClick={handleRenewButton}
                        >
                            Renew
                        </Button>
                    ) : showRedeemButton() ? (
                        <Button
                            className="domainActionButton"
                            onClick={handleRedeemButton}
                        >
                            Redeem
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button className="domainActionButton" onClick={handleReleaseButton}>
                        Release
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DomainInfo;
