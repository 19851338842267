import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

export const googleAnalyticsAction = (
    category: string,
    action: string,
    label: string,
): void => {
    ReactGA.event({
        category: category, //'Click',
        action: action, //'Opened Email Sent Model',
        label: label, //'Opened',
    });
};
