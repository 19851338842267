import { combineReducers } from '@reduxjs/toolkit';
import UserList from '../../features/users/userListSlice';
import UserDetails from '../../features/users/userDetails/userDetailsSlice';
import UserNotifications from '../../features/notifications/userNotifications/userNotificationSlice';
import { UserListState } from '../../features/users/userListModels';
import { UserDetailsState } from '../../features/users/userDetails/userDetailsModels';
import { UserNotificationState } from '../../features/notifications/userNotifications/userNotificationModels';

interface UserRootState {
    list: UserListState;
    details: UserDetailsState;
    notifications: UserNotificationState;
}
export default combineReducers<UserRootState>({
    list: UserList,
    details: UserDetails,
    notifications: UserNotifications,
});
