import { FunctionComponent, useRef } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormData, useAppSelector, useAppDispatch } from '../../../../../../app/hooks';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { DrawerContainerProps, Biography, AgentDescription } from '../../../agentModels';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import { setCurrentAgentEditFormData } from '../../../agentSlice';
import { editFormComponent } from '../../../agentConstants';
import './index.scss';
import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';
import RenderRemainingChar from '../../../../../../shared/component/editor/RenderRemainingChar';
import { calculateRemainingChars } from '../../../../../../utils/urlUtils';

const AgentBiography: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { common } = useAppSelector(lookups);
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const getCurrentAgentBio = (languageId: number, profileId: string): Biography => {
        const initialBiographyData = editFormData.agentDetails
            .agentBiography as Biography[];

        const bioByLanguageId =
            initialBiographyData.length &&
            initialBiographyData.find(
                (data: Biography) => data.languageId === Number(languageId),
            );

        const agentBioData: Biography = bioByLanguageId
            ? bioByLanguageId
            : {
                  id: '',
                  profileId: profileId,
                  languageId: languageId,
                  body: '',
                  headline: null,
              };
        return agentBioData;
    };

    const handleDropDown = (data: LookupInfo, name: string) => {
        if (name === 'languageId') {
            setFormData({
                ...formData,
                languageId: Number(data.id),
            });
            const selectedBio = getCurrentAgentBio(Number(data.id), formData.profileId);
            setFormData(selectedBio);
        }
    };

    const { formData, setFormData, handleTextChange, handleEditorChange } = useFormData(
        getCurrentAgentBio(
            1,
            (editFormData.agentDetails.descriptionDetails as AgentDescription).profileId,
        ),
    );

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentAgentEditFormData({
                formData,
                agentEditFormName: editFormComponent.biography,
            }),
        );
    };

    const checkAndCallPersist = () => {
        const remainingChar = calculateRemainingChars(formData.body || '', 4000);
        if (remainingChar >= 0) {
            setFormDataToPersist();
        }
    };

    return (
        <div className="biographyContainer">
            <Grid
                item
                container
                className="content"
                alignItems="center"
                spacing={2}
                md={12}
            >
                <FormRow
                    label="LANGUAGE"
                    control={
                        <DropDownSearch
                            id={formData.languageId ? formData.languageId : 1}
                            items={(common as CommonLookups).languages}
                            name="languageId"
                            handleDropDownChange={handleDropDown}
                            handleBlur={setFormDataToPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={4}
                />
            </Grid>
            <Grid item container className="content" md={12} spacing={2}>
                <FormRow
                    label="HEADLINE"
                    control={
                        <TextField
                            value={formData.headline ? formData.headline : ''}
                            onChange={(e) => handleTextChange(e, 30)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'headline',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={setFormDataToPersist}
                            name="headline"
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot headline"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid>
            <Grid item container className="content" md={12} spacing={2}>
                <FormRow
                    label="BIOGRAPHY"
                    control={
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_MCE}
                            init={{
                                placeholder: 'Type here to add biography.',
                                height: 600,
                                statusbar: false,
                                menubar: false,
                                plugins: ['link', 'lists', 'paste'],
                                branding: false,
                                paste_as_text: true,
                                setup: (editor) => {
                                    editor.on('PostProcess', function (ed) {
                                        // we are cleaning empty paragraphs
                                        ed.content = ed.content
                                            .replace(/(<p>&nbsp;<?\/?p>)/g, '<br>')
                                            .replace(/<br ?\/?>/g, '<br>')
                                            .replace(/(\r\n|\n|\r)/gi, '<br>')
                                            .replace(/(<br>){1,}/g, '<br>');
                                    });
                                },
                                toolbar: 'bold | italic ',
                                content_style:
                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                            }}
                            value={formData.body || ''}
                            onInit={(event, editor) => (editorRef.current = editor)}
                            onEditorChange={(newValue) => {
                                handleEditorChange('body', newValue);
                            }}
                            onBlur={checkAndCallPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot editorInput"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid>
            <Grid item container md={12} justifyContent="flex-end">
                <Grid item md={10}>
                    <RenderRemainingChar value={formData.body || ''} maxLength={4000} />
                </Grid>
            </Grid>
        </div>
    );
};

export default AgentBiography;
