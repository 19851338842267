import { Grid, TextField } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import { findIdFromLookups } from '../../../../../../utils/urlUtils';
import { editFormComponent } from '../../../userDetailsConstants';
import { DrawerContainerProps } from '../../../userDetailsModels';
import { setCurrentUserEditFormData, userDetails } from '../../../userDetailsSlice';
import './index.scss';

import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';

const Identity: FC<DrawerContainerProps> = () => {
    const dispatch = useAppDispatch();
    const { details } = useAppSelector(userDetails);
    const { common } = useAppSelector(lookups);
    const { salutations, statuses } = common as CommonLookups;
    const { formData, setFormData, handleTextChange } = useFormData(details);

    const handleDropDown = (data: LookupInfo, name: string) => {
        setFormData({
            ...formData,
            [name as keyof typeof formData]: data.id,
        });
    };

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentUserEditFormData({
                formData,
                userEditFormName: editFormComponent.identity,
            }),
        );
    };

    useEffect(() => {
        setFormData({
            ...details,
            salutation: findIdFromLookups(details.salutation, salutations),
            status: findIdFromLookups(details.status, statuses),
        });
    }, [details]);

    return (
        <Grid container className="identity-container" spacing={2} alignItems="center">
            <FormRow
                label="ACCOUNT STATUS"
                control={
                    <DropDownSearch
                        id={formData.status}
                        items={statuses}
                        name="status"
                        handleDropDownChange={handleDropDown}
                        handleBlur={setFormDataToPersist}
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="SALUTATION*"
                control={
                    <DropDownSearch
                        id={formData.salutation}
                        items={salutations}
                        name="salutation"
                        handleDropDownChange={handleDropDown}
                        handleBlur={setFormDataToPersist}
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="FIRST NAME*"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.firstName || ''}
                        onChange={(e) => handleTextChange(e, 50)}
                        onBlur={setFormDataToPersist}
                        name="firstName"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="MIDDLE NAME"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.middleName || ''}
                        onChange={(e) => handleTextChange(e, 50)}
                        onBlur={setFormDataToPersist}
                        name="middleName"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="LAST NAME*"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.lastName || ''}
                        onChange={(e) => handleTextChange(e, 50)}
                        onBlur={setFormDataToPersist}
                        name="lastName"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="PREFERRED FIRST NAME*"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.preferredFirstName || ''}
                        onChange={(e) => handleTextChange(e, 50)}
                        onBlur={setFormDataToPersist}
                        name="preferredFirstName"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="EMAIL*"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.email || ''}
                        onChange={(e) => handleTextChange(e, 255)}
                        onBlur={setFormDataToPersist}
                        name="email"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="TITLE*"
                control={
                    <TextField
                        className="identity-text-field"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.title || ''}
                        onChange={(e) => handleTextChange(e, 100)}
                        onBlur={setFormDataToPersist}
                        name="title"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
            <FormRow
                label="USERNAME*"
                control={
                    <TextField
                        className="identity-text-field inputDisabled"
                        InputProps={{
                            disableUnderline: true,
                            className: 'identity-input',
                        }}
                        value={formData.userName || ''}
                        disabled={true}
                        name="userName"
                    />
                }
                controlClass="identity-form-control"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
        </Grid>
    );
};
export default Identity;
