export const returnFile = (response: Promise<Response>): Promise<void> => {
    let filename = '';
    return response
        .then((res) => {
            const header = res.headers.get('Content-Disposition');
            const parts = header?.split(';') || [];
            filename = parts[1].split('=')[1];
            return res.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename.trim().replaceAll('"', '');
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element
        });
};
