import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { FC } from 'react';

const RoleExplanation: FC = () => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>Definition</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Masquerade</TableCell>
                        <TableCell>
                            Will give permission for logged in user to Masquerade as other
                            users
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Listing Edit (Agent)</TableCell>
                        <TableCell>
                            When selected Will give full access to listing edit, when not
                            selected to user will have not access to edit the listing
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Listing Draft Creation (Agent)</TableCell>
                        <TableCell>
                            Will give permission for logged in user to create a draft
                            listing
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Publish Listing (Agent)</TableCell>
                        <TableCell>
                            Will give permission for logged in user to publish the listing
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Agent Spot Booking (Agent)</TableCell>
                        <TableCell>
                            When selected the user can book a spot, when not selected user
                            can only request a spot
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disable Listing Price Edit (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will not have access to listing price edit
                            but he can edit other section
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disable Listing Dates Edit (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will not have access to listing date edit but
                            he can edit other section
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disable Listing Agents Edit (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will not have access to listing agents(both
                            sell/list side) edit but he can edit other section
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disable Showcase Edit (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will not have access to listing showcase site
                            edit but he can edit other section
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Disable AdCopy Edit (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will not have access to listing adcopy edit
                            but he can edit other section
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Listing Photo Reorder (Agent)</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will be able to perform only the photo
                            reorder and other action in the photo edit will be prevented
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Power User</TableCell>
                        <TableCell>
                            If Listing Edit Role is enabled, then when this role is
                            enabled the user will be able to change the status of a
                            listing
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RoleExplanation;
