import { deleteCall, postCall } from '../../../../../../utils/api/apiWrapper';
import { SalesTeamAssistant } from '../../../salesTeamModels';

export function addSalesTeamAssistantApi(
    salesTeamId: string,
    assistant: SalesTeamAssistant,
): Promise<string> {
    return postCall<SalesTeamAssistant, string>(
        `salesteamassistants/${salesTeamId}/assistant`,
        assistant,
    );
}

export function deleteSalesTeamAssistantApi(
    salesTeamId: string,
    salesTeamAssistantId: string,
): Promise<Response> {
    return deleteCall(
        `salesteamassistants/${salesTeamId}/assistant/${salesTeamAssistantId}`,
    );
}
