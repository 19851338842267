import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { UpdateFlag } from '../../shared/models/flag/flagModel';
import { OfficeSearch, OfficesResponse } from '../../shared/models/office/officeModel';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import { getOfficesFromApi } from '../../shared/api/office/officeApi';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import {
    OfficeEditListUpdate,
    OfficesState,
    OfficeTableActionType,
} from './officeListModels';
import { initialOfficeSearch } from './officeSearch';

const officeListInitialState: OfficesState = {
    data: { currentPage: 1, totalRecords: 0, recordsPerPage: 20, results: [] },
    isSearched: false,
    tableAction: initialOfficeSearch,
    flags: {
        isLoading: false,
    },
};

export const officeListSlice = createSlice({
    name: 'offices',
    initialState: officeListInitialState,
    reducers: {
        updateOffices: (
            state,
            action: PayloadAction<PaginationResponse<OfficesResponse>>,
        ) => {
            const results = [...state.data.results, ...action.payload.results];
            let data = {
                ...state.data,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (state.data.results.length !== 0 || action.payload.results.length !== 0) {
                data = {
                    ...data,
                    results:
                        state.data.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                data: data,
            };
        },
        updateLoader: (state, action: PayloadAction<UpdateFlag>) => {
            return {
                ...state,
                flags: {
                    ...state.flags,
                    [action.payload.property]: action.payload.value,
                },
            };
        },
        updateTableAction: (state, action: PayloadAction<OfficeTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        updateOfficeList: (state, action: PayloadAction<OfficeEditListUpdate>) => {
            const { results } = state.data;
            const { id } = action.payload;
            const findIndex = results.findIndex((x) => x.id === id);
            const newValue = {
                ...state.data.results[findIndex],
                ...action.payload,
            };
            state.data.results[findIndex] = newValue;
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSearched: action.payload,
            };
        },
    },
});

export const getOffices =
    (officesRequest: OfficeSearch): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateLoader({
                    property: 'isLoading',
                    value: true,
                }),
            );
            const response = await getOfficesFromApi(officesRequest);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(updateOffices(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the office list`));
        } finally {
            dispatch(
                updateLoader({
                    property: 'isLoading',
                    value: false,
                }),
            );
        }
    };

export const {
    updateOffices,
    updateLoader,
    updateTableAction,
    updateOfficeList,
    setIsSearched,
} = officeListSlice.actions;

export const offices = (state: RootState): OfficesState => state.office.list;

export default officeListSlice.reducer;
