import React, { FunctionComponent } from 'react';
import { Input } from '@material-ui/core';
import { updateListingTitle } from './adCopySlice';
import { DrawerContainerProps } from '../../../listingModels';
import { useAppDispatch, useFormData } from '../../../../../../app/hooks';

const ListingTitle: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { formData, handleTextChange } = useFormData({
        title: editFormData.data.title,
    });

    const listingId = editFormData.data.id;

    const saveTitle = () => {
        dispatch(updateListingTitle(listingId, formData.title || ''));
    };

    return (
        <>
            <div className="size-header">
                <span className="size-title">TITLE</span>
            </div>
            <Input
                name="title"
                fullWidth
                disableUnderline
                className="headline"
                value={formData.title || ''}
                onChange={(e) => handleTextChange(e, 40)}
                onBlur={saveTitle}
            />
        </>
    );
};

export default ListingTitle;
