import { FunctionComponent, useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import { getUser } from '../../../../../../shared/auth/authService';
import {
    fetchUserPermissions,
    userIsInAnyRole,
    userIsInRole,
} from '../../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../../shared/constants/user/roles';
import {
    CommonLookups,
    DevelopmentLookups,
} from '../../../../../../shared/models/lookups/lookupsModels';
import { lookups as lookupsSlice } from '../../../../../../shared/slices/lookups/lookupsSlice';
import './developmentDetailsEdit.scss';
import { setCurrentDevelopmentEditFormData } from '../../../developmentDetailsSlice';
import { editFormComponent } from '../../../developmentDetailsConstants';
import { findNameFromLookups } from '../../../../../../utils/urlUtils';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import Address from '../../../../createDevelopment/create/Components/Address';
import Dates from '../../../../createDevelopment/create/Components/Date';
import Details from '../../../../createDevelopment/create/Components/Details';
const DevelopmentDetailsEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const { common, developmentLook } = useAppSelector(lookupsSlice);
    const states = (common as CommonLookups).states;
    const statuses = (developmentLook as DevelopmentLookups).statuses;
    const offices = (common as CommonLookups).offices;
    const user = useMemo(() => getUser(), []);
    const officeIds = useMemo(
        () =>
            userIsInAnyRole(user, [userRoles.systemAdmin.key])
                ? []
                : fetchUserPermissions(user, 'officeIds'),
        [user],
    );
    let filteredOffices = offices.map((o) => {
        return {
            id: o.id,
            name: o.name,
        };
    });
    filteredOffices = userIsInRole(user, userRoles.systemAdmin.key)
        ? filteredOffices
        : filteredOffices.filter((office) => {
              return officeIds.includes(office.id);
          });

    const {
        formData,
        setFormData,
        handleNumberChange,
        handleTextChange,
        handleDateChange,
        handleCurrencyChange,
        handleDropDownSearch,
        handleFloatChange,
    } = useFormData(editFormData.data);

    const setFormDataToPersistInState = () => {
        dispatch(
            setCurrentDevelopmentEditFormData({
                formData: {
                    ...formData,
                    status: findNameFromLookups(formData.statusId.toString(), statuses),
                },
                developmentEditFormName: editFormComponent.developmentDetails,
            }),
        );
    };

    const handleDropDown = (data: LookupInfo, name: string) => {
        if (name === 'regionId') {
            setFormData({
                ...formData,
                regionId: Number(data.id),
                state: data.name,
            });
        }
    };

    return (
        <Grid container>
            <ComponentWithLoader showLoader={false} showOverlay={true}>
                <Grid container spacing={2} className="developmentDetailsEdit">
                    <Grid item container className="Basics" md={12}>
                        <Grid item container className="section" md={12} spacing={2}>
                            <Grid item container alignItems="center">
                                <FormRow
                                    id="title"
                                    label="Title*"
                                    controlClass="formControlRoot"
                                    control={
                                        <TextField
                                            value={formData.title || ''}
                                            onChange={(e) => handleTextChange(e, 50)}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'inputBaseRoot',
                                            }}
                                            name="title"
                                            id="title"
                                            onBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    labelMd={2}
                                    controlMd={4}
                                />
                                <FormRow
                                    label="Sira Id"
                                    control={
                                        <TextField
                                            value={formData.siraDevelopmentId || ''}
                                            onChange={(e) => handleTextChange(e, 255)}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'inputBaseRoot',
                                            }}
                                            name="siraDevelopmentId"
                                            onBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    controlClass="formControlRoot"
                                    labelMd={2}
                                    controlMd={4}
                                />
                            </Grid>
                            <Grid item container alignItems="center">
                                <FormRow
                                    id="officeId"
                                    label="Office*"
                                    control={
                                        <DropDownSearch
                                            id={formData.officeId || ''}
                                            items={filteredOffices}
                                            name="officeId"
                                            handleDropDownChange={handleDropDownSearch}
                                            handleBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    controlClass="formControlRoot"
                                    labelMd={2}
                                    controlMd={4}
                                />
                                <FormRow
                                    label="Status"
                                    control={
                                        <DropDownSearch
                                            id={formData.statusId || ''}
                                            items={statuses}
                                            name="statusId"
                                            handleDropDownChange={handleDropDownSearch}
                                            handleBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    controlClass="formControlRoot"
                                    labelMd={2}
                                    controlMd={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container className="section" md={12} spacing={2}>
                            <Address
                                formData={formData}
                                handleTextChange={handleTextChange}
                                handleDropDownChange={handleDropDown}
                                handleNumberChange={handleNumberChange}
                                setFormDataToPersistInState={setFormDataToPersistInState}
                                states={states}
                                handleFloatChange={handleFloatChange}
                            />
                        </Grid>
                        <Grid item container className="section" md={12} spacing={2}>
                            <Dates
                                formData={formData}
                                handleTextChange={handleTextChange}
                                handleDateChange={handleDateChange}
                                setFormDataToPersistInState={setFormDataToPersistInState}
                            />
                        </Grid>
                        <Grid item container className="section last" md={12} spacing={2}>
                            <Details
                                formData={formData}
                                handleNumberChange={handleNumberChange}
                                handleCurrencyChange={handleCurrencyChange}
                                setFormDataToPersistInState={setFormDataToPersistInState}
                            />
                        </Grid>
                        <Grid container className="ownerInfo">
                            OWNER INFO
                            <Grid item container alignItems="center">
                                <FormRow
                                    id="owner"
                                    label="First Name"
                                    controlClass="formControlRoot"
                                    control={
                                        <TextField
                                            value={formData.ownerFirstName || ''}
                                            onChange={(e) => handleTextChange(e, 50)}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'inputBaseRoot',
                                            }}
                                            name="ownerFirstName"
                                            id="owner"
                                            onBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    labelMd={2}
                                    controlMd={4}
                                />
                                <FormRow
                                    label="Last Name"
                                    control={
                                        <TextField
                                            value={formData.ownerLastName || ''}
                                            onChange={(e) => handleTextChange(e, 50)}
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'inputBaseRoot',
                                            }}
                                            name="ownerLastName"
                                            onBlur={setFormDataToPersistInState}
                                        />
                                    }
                                    controlClass="formControlRoot"
                                    labelMd={2}
                                    controlMd={4}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ComponentWithLoader>
        </Grid>
    );
};

export default DevelopmentDetailsEdit;
