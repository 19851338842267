import { sortBy, groupBy } from 'lodash';
import {
    ListingAmenity,
    ListingAmenityWithSelected,
} from '../../models/listing/amenityModels';

const getAmenities = <K extends keyof ListingAmenity>(
    amenities: ListingAmenity[],
    property: K,
    propVal: string | number,
): ListingAmenity[] => {
    const filteredFeatures = amenities.filter((f) => f[property] === propVal);
    return sortBy(filteredFeatures, 'amenityName');
};

/**
 * Gets list of all amenities in a given section
 * @param amenities The list of amenities to filter on
 * @param sectionName The name of the section to get amenities for
 * @returns List of all amenities associated with the given section
 */
export const getAmenitiesForSection = (
    amenities: ListingAmenity[],
    sectionName: string,
): ListingAmenity[] => {
    return getAmenities(amenities, 'sectionName', sectionName);
};

/**
 * Returns list of names of all of a listing's amenities in the given section
 * @param amenities The full list of amenities associated with the listing
 * @param sectionName The name of the amenity section to return amenities for
 * @returns List of amenity names associated with the given section
 */
export const getAmenityNamesForSection = (
    amenities: ListingAmenity[],
    sectionName: string,
    from = 'other',
): string => {
    const sectionAmenities = getAmenitiesForSection(amenities, sectionName);
    if (from === 'other') {
        return sectionAmenities.length
            ? sectionAmenities.map((a) => generateAmenityName(a)).toString()
            : '-';
    } else {
        if (sectionAmenities.length) {
            const groupedData = groupBy(sectionAmenities, 'groupName');
            let data = '';
            for (const [key] of Object.entries(groupedData)) {
                if (data === '') {
                    data =
                        `<span class='highlight'>${key}</span>: ` +
                        groupedData[key].map((a) => generateAmenityName(a)).toString();
                } else {
                    data =
                        data +
                        `, <span class='highlight'>${key}</span>: ` +
                        groupedData[key].map((a) => generateAmenityName(a)).toString();
                }
            }
            return data.length ? data : '-';
        } else {
            return '-';
        }
    }
};

/**
 * Gets the list of amenities that allow text for the group
 * @param listingAmenities The amenities associated with the listing
 * @param allGroupAmenities All amenities in the system for the group
 * @param allowText Indicates if we should return amenities that allow
 * text or not
 * @returns List of amenities with flag set indicated if they are already
 * selected
 */
export const getAmenitiesForGroup = (
    listingAmenities: ListingAmenity[],
    allGroupAmenities: ListingAmenity[],
    allowText: boolean,
): ListingAmenityWithSelected[] => {
    const listingAmenityIds = listingAmenities.map((la) => la.amenityId);
    return allGroupAmenities
        .filter((la) => la.allowText === allowText)
        .map((a: ListingAmenity) => {
            return {
                ...a,
                text: listingAmenities.find((la) => la.amenityId === a.amenityId)?.text,
                selected: listingAmenityIds.indexOf(a.amenityId || 0) > -1,
            };
        });
};

const generateAmenityName = (amenity: ListingAmenity): string => {
    let name = amenity.amenityName;
    if (amenity.text?.length) {
        name = `${name}: ${amenity.text}`;
    }
    return name;
};
