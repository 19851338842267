import { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { makeOpenAICall } from '../../../utils/api/apiWrapper';
import { useAppDispatch } from '../../../app/hooks';
import { setError } from '../../slices/messaging/messagingSlice';

interface OpenAIAdCopyModel {
    address: string;
    city: string;
    bedroom: number;
    bathroom: number;
    buildingAmenities: string;
    interiorAmenities: string;
    outdoorAmenities: string;
    outputText: (text: string) => void;
}
const OpenAIAdCopy: FunctionComponent<OpenAIAdCopyModel> = ({
    address,
    city,
    bedroom,
    bathroom,
    buildingAmenities,
    interiorAmenities,
    outdoorAmenities,
    outputText,
}) => {
    const dispatch = useAppDispatch();

    // const getapi = () => {
    //     const configuration = {
    //         apiKey: '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',
    //         // dangerouslyAllowBrowser: true,
    //     };
    //     const openai = new OpenAI(configuration);
    //     // text-davinci-001
    //     openai.chat.completions
    //         .create({
    //             model: 'gpt-3.5-turbo',
    //             messages: [
    //                 {
    //                     role: 'user',
    //                     content:
    //                         'write me anadcopy for 123 Main street located in NYC, it has 5 bedrooms, 3 bathrooms',
    //                 },
    //             ],
    //             temperature: 0.8,
    //             max_tokens: 250,
    //             top_p: 1,
    //             frequency_penalty: 0,
    //             presence_penalty: 0,
    //         })
    //         .then((res: any) => {
    //             console.log(res.choices[0].message.content);
    //         });
    // };

    /**function to form text that is send to AI to get the adcopy */
    const formContent = () => {
        let text = `write me an adcopy for ${address} located in ${city}, it has ${bedroom} bedrooms, ${bathroom} bathrooms`;
        if (buildingAmenities.length) {
            text = text + ` and building amenities such as ${buildingAmenities}`;
        }
        if (interiorAmenities.length) {
            text = text + ` and interior amenities like ${interiorAmenities}`;
        }
        if (outdoorAmenities.length) {
            text = text + ` also have outdoor amenities like ${outdoorAmenities}`;
        }
        return text;
    };

    /** function to make the post call to the get the data from AI */
    const openAiRequest = async () => {
        const text = formContent();
        const openAiObj = {
            model: 'gpt-3.5-turbo',
            messages: [
                {
                    role: 'user',
                    content: text,
                },
            ],
            temperature: 1,
            max_tokens: 1000,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        };
        const res = await makeOpenAICall(openAiObj);
        if (res && !res.error && res.choices.length) {
            outputText(res.choices[0].message.content);
        } else {
            outputText('');
            dispatch(setError('Failed to fetch the Adcopy using Ai'));
        }
    };

    return (
        <Grid container>
            <span className="customButton" onClick={openAiRequest}>
                Generate Ad Copy using AI
            </span>
        </Grid>
    );
};

export default OpenAIAdCopy;
