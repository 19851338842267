import { FunctionComponent, Suspense, useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    navigationDetails,
    openCloseMobileMenu,
} from '../../slices/navigation/navigationSlice';
import { CircularProgress } from '@material-ui/core';
import './section.scss';
import {
    getCommonLookups,
    getOfficeLookups,
    lookups,
} from '../../slices/lookups/lookupsSlice';
import { CommonLookups, OfficeLookups } from '../../models/lookups/lookupsModels';
import { cancelMasquerade, getUser, isMasquerading } from '../../auth/authService';
import { validateAgentAction } from '../../auth/permissions/permissionsService';
import { roleFilter, userRoles } from '../../constants/user/roles';
import { validateRoute, userIsInRole } from '../../auth/permissions/permissionsService';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ProtectedRoute from '../route/ProtectedRoute';
import AuthorizedNav from '../nav/AuthorizedNav';
import ZeusHeader from '../zeusHeader/ZeusHeader';
import SessionTimeout from '../sessionTimeout/SessionTimeout';
import {
    masqueradeMinTimeout,
    minTimeout,
} from '../sessionTimeout/sessionTimeoutConstants';
import { getData } from '../../../utils/storageService';
import { googleAnalyticsAction } from '../../functions/googleAnalytics/ga';

const SectionComponent: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { common, officeLook } = useAppSelector(lookups);
    const { languages, offices, salutations, states, statuses } = common as CommonLookups;
    const { availableManagers, officeSpecializations, officeSalesRegions } =
        officeLook as OfficeLookups;
    const { showMobileMenu } = useAppSelector(navigationDetails);
    const userDetails = useMemo(() => getUser(), []);
    const { authState, oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (
            !languages.length ||
            !offices.length ||
            !salutations.length ||
            !states.length ||
            !statuses.length
        ) {
            dispatch(getCommonLookups());
        }
    }, [dispatch, languages, offices, salutations, states, statuses]);

    useEffect(() => {
        if (
            !availableManagers.length ||
            !officeSpecializations.length ||
            !officeSalesRegions.length
        ) {
            dispatch(getOfficeLookups());
        }
    }, [dispatch, availableManagers, officeSpecializations, officeSalesRegions]);

    const renderRoutes = () => {
        return roleFilter.map((route, index) => {
            if (route.isProtected) {
                return (
                    <ProtectedRoute
                        path={route.route}
                        component={route.component}
                        isAuthorized={
                            route.validateActionKey && route.validateActionKey.length > 0
                                ? validateRoute(userDetails, route.route, 'route') ||
                                  (userIsInRole(userDetails, userRoles.agent.key) &&
                                      validateAgentAction(
                                          userDetails,
                                          route.validateActionKey,
                                      ))
                                : validateRoute(userDetails, route.route, 'route')
                        }
                        key={index}
                    />
                );
            } else {
                return (
                    <Route path={route.route} component={route.component} key={index} />
                );
            }
        });
    };

    const closeMobileMenu = () => {
        if (showMobileMenu) dispatch(openCloseMobileMenu(false));
    };

    const displayListingForPrint = () => {
        return getData('listingStatus') ? getData('listingStatus') === '11' : false;
    };

    return authState?.isAuthenticated ? (
        <>
            {isMasquerading() ? (
                <div
                    className={
                        'cancelMasquerade' +
                        (displayListingForPrint() ? ' screen-display' : '')
                    }
                >
                    Currently you are masquerading as {userDetails.firstName}{' '}
                    {userDetails.lastName}
                    <span
                        className="action"
                        onClick={() => {
                            googleAnalyticsAction(
                                'Masquerade',
                                'Click',
                                `Masquerading cancelled`,
                            );
                            cancelMasquerade();
                        }}
                    >
                        Switch Back
                    </span>
                </div>
            ) : (
                <></>
            )}
            <div className="sectionWrapper">
                <div className="sectionDiv">
                    <div
                        className={
                            (showMobileMenu
                                ? 'leftNavigation showMenu'
                                : 'leftNavigation') +
                            (displayListingForPrint() ? ' screen-display' : '')
                        }
                    >
                        <div className="leftNavContainer">
                            {showMobileMenu ? (
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="menuCloseIcon"
                                    onClick={closeMobileMenu}
                                />
                            ) : (
                                ''
                            )}

                            <div className="logoWrapper">
                                <a
                                    aria-label="Sotheby's International Realty home"
                                    href="#/"
                                >
                                    <img
                                        className="logo"
                                        src={`/img/logo-sothebys-white.png`}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <AuthorizedNav />
                        </div>
                    </div>
                    <div className="rightSection" onClick={closeMobileMenu}>
                        <Suspense
                            fallback={
                                <div className="circularOverlay">
                                    <CircularProgress />
                                </div>
                            }
                        >
                            <ZeusHeader />
                            <Switch>{renderRoutes()}</Switch>
                            <SessionTimeout
                                minTimeout={
                                    isMasquerading() ? masqueradeMinTimeout : minTimeout
                                }
                                oktaAuth={oktaAuth}
                            />
                        </Suspense>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};

export default SectionComponent;
