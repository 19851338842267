import { ListingSearchRequest } from '../../shared/models/listing/commonModel';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { isManager } from '../../shared/utils/shared/sharedUtils';
import { getCall, postCall } from '../../utils/api/apiWrapper';
import { OfficeDetailsSummary } from '../offices/office/officeModels';
import {
    CreateDevelopmentRequest,
    CreateDevelopmentResponse,
} from './createDevelopment/create/createDevelopmentModel';
import {
    DevelopmentListModel,
    DevelopmentSearchRequest,
    SearchedListingListDevModel,
} from './developmentListModel';

export function getListingListApi(
    data: ListingSearchRequest,
): Promise<PaginationResponse<SearchedListingListDevModel>> {
    //update agent id and agent role for manager
    const dataNew = isManager(data);
    data.agentIds = data.agentIds ? data.agentIds : dataNew.agentIds;
    data.roleIds = data.roleIds;
    return postCall<
        ListingSearchRequest,
        PaginationResponse<SearchedListingListDevModel>
    >(`listing/search`, data);
}

export function saveDevelopmentApi(
    data: CreateDevelopmentRequest,
): Promise<CreateDevelopmentResponse> {
    return postCall<CreateDevelopmentRequest, CreateDevelopmentResponse>(
        'building',
        data,
    );
}

export function getDevelopmentListApi(
    developmentSearchRequest: DevelopmentSearchRequest,
): Promise<PaginationResponse<DevelopmentListModel>> {
    return postCall<DevelopmentSearchRequest, PaginationResponse<DevelopmentListModel>>(
        `building/search`,
        developmentSearchRequest,
    );
}

export function getOfficeState(officeId: string): Promise<OfficeDetailsSummary> {
    return getCall<OfficeDetailsSummary>(`office/${officeId}`);
}
