import React, { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../../../../../../app/hooks';
import { DrawerContainerProps } from '../../../../../listingModels';
import { handleListingFormSwitch } from '../../../../../listingSlice';
import { listingAmenityFormMap } from '../../listingFeaturesSlice';
import { amenitySections } from '../../../../../../../../shared/constants/listing/amenities';
import './mainListingForm.scss';

const MainListingForm: FunctionComponent<DrawerContainerProps> = (props) => {
    const dispatch = useAppDispatch();
    const selectedForm =
        props.editFormData.editForm?.listingEditFormName || amenitySections.building;
    const DetailForm = props.editFormData
        ? listingAmenityFormMap.get(selectedForm)
        : null;

    const getSelectedClass = (formName: string): string => {
        return selectedForm === formName ? 'selected' : '';
    };

    const handleNavClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        formName: string,
    ) => {
        event.preventDefault();
        dispatch(handleListingFormSwitch(formName, props.editFormData));
    };

    const renderNavItem = (title: string, formName: string) => {
        return (
            <a
                className={`nav-link ${getSelectedClass(formName)}`}
                onClick={(e) => handleNavClick(e, formName)}
                href="!#"
            >
                {title}
            </a>
        );
    };

    const renderTopNav = () => {
        return (
            <div className="top-nav">
                {renderNavItem('BUILDING', amenitySections.building)}
                {renderNavItem('INTERIOR', amenitySections.interior)}
                {renderNavItem('LOT', amenitySections.lot)}
                {renderNavItem('ROOM', amenitySections.room)}
                {renderNavItem('CONSTRUCTION', amenitySections.construction)}
                {renderNavItem('PARKING', amenitySections.parking)}
                {renderNavItem('OUTDOOR', amenitySections.outdoor)}
                {renderNavItem('ASSOCIATION', amenitySections.association)}
                {renderNavItem('GREEN', amenitySections.green)}
            </div>
        );
    };

    const renderForm = () => {
        return (
            <>
                {renderTopNav()}
                {DetailForm ? (
                    <DetailForm {...props} sectionName={selectedForm} />
                ) : (
                    <></>
                )}
            </>
        );
    };

    return DetailForm ? renderForm() : <></>;
};

export default MainListingForm;
