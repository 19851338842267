import { makeStyles } from '@material-ui/core/styles';

export const showCaseWebsiteStyle = makeStyles({
    status: {
        fontFamily: 'MercuryTextG4Roman',
        fontSize: '16px',
        color: '#666666',
        marginTop: '20px',
        paddingBottom: '30px',
        borderBottom: '1px solid #b28e3a',
    },
    requiredFields: {
        fontFamily: 'MercuryTextG4Italic',
        fontSize: '18px',
        color: '#666666',
        marginTop: '20px',
        paddingBottom: '30px',
    },
    video: {
        marginTop: '20px',
        paddingBottom: '30px',
        fontFamily: 'BentonSansMedium',
        fontSize: '16px',
        color: '#666666',
    },
    mainVideos: {
        marginTop: '30px',
    },
    otherVideos: {
        marginTop: '20px',
        paddingBottom: '30px',
    },
    checkBox: {
        '&$checked': {
            color: '#002349',
        },
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    checked: {},
    cancelButton: {
        '&:hover': {
            backgroundColor: '#FFF !important',
            color: '#002349',
            border: '1px solid #002349',
            borderRadius: '0px !important',
        },
        margin: '20px',
        fontFamily: 'MercuryTextG4Roman',
        fontSize: '15px',
        backgroundColor: '#FFF !important',
        color: '#002349',
        border: '1px solid #002349',
        borderRadius: '0px !important',
    },
    showcaseFormContainer: {
        position: 'relative',
    },
    loaderClass: {
        position: 'absolute',
        width: '100%',
    },
});
