import { useState, ChangeEvent, MouseEvent } from 'react';
import { MarketingFileData } from '../models/marketing/marketingModels';
import { useAppDispatch } from '../../app/hooks';
import { setError } from '../slices/messaging/messagingSlice';

export const useFileManagement = (
    initialState: MarketingFileData,
): {
    fileData: MarketingFileData;
    handleFileUpload: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => MarketingFileData;
    handleUrlChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    ensureHttp: () => void;
    setFileData: React.Dispatch<React.SetStateAction<MarketingFileData>>;
    handleFileClear: (
        event: MouseEvent<HTMLButtonElement>,
        shouldDeleteFile: boolean,
        fileDeleteHandler?: () => void,
    ) => void;
} => {
    const intitialFileData: MarketingFileData = {
        file: initialState.file,
        isFile: initialState.isFile,
        url: initialState.url,
    };
    const [fileData, setFileData] = useState(intitialFileData);
    const dispatch = useAppDispatch();

    const fileUpload = (fileName: string, uploadedFile: File) => {
        const size = uploadedFile.size / 1024 / 1024; // in MiB
        const words = uploadedFile.name.split('.');
        const validType =
            (uploadedFile.type === 'image/jpg' ||
                uploadedFile.type === 'image/jpeg' ||
                uploadedFile.type === 'image/png') &&
            words[words.length - 1] !== 'jpeg';

        if (size > 50) {
            dispatch(setError('Max file size supported: 50 MB'));
            return false;
        }
        if (!validType) {
            // TODO: Pass in some field we can use to indicate what types are supported,
            // rather than hard-coding the field name
            if (fileName === 'logoUpload') {
                dispatch(setError('Image type supported: JPG, PNG'));
                return false;
            } else if (uploadedFile.type === 'application/pdf') {
                return true;
            } else {
                dispatch(setError('File format supported: JPG, PNG, PDF'));
                return false;
            }
        }
        return true;
    };

    const handleUrlChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setFileData({ ...fileData, url: event.target.value });
    };

    const ensureHttp = () => {
        let updatedUrl = fileData.url;
        if (updatedUrl && updatedUrl !== '') {
            if (updatedUrl.search(/^http[s]?:\/\//) === -1) {
                updatedUrl = `http://${updatedUrl}`;
            }
            setFileData({ ...fileData, url: updatedUrl });
        }
    };

    const handleFileUpload = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): MarketingFileData => {
        const inputEvent = event as ChangeEvent<HTMLInputElement>;
        if (inputEvent && inputEvent.target && inputEvent.target.files) {
            const uploadedFile = inputEvent.target.files[0];
            if (uploadedFile && fileUpload(inputEvent.target.name, uploadedFile)) {
                const updatedFileData = {
                    ...fileData,
                    file: uploadedFile,
                    url: uploadedFile.name,
                    isFile: true,
                };
                setFileData(updatedFileData);
                return updatedFileData;
            }
        }
        return fileData;
    };

    const handleFileClear = (
        event: MouseEvent<HTMLButtonElement>,
        shouldDeleteFile: boolean,
        fileDeleteHandler?: () => void,
    ) => {
        event.preventDefault();
        setFileData((prevState) => {
            return { ...prevState, isFile: false, url: '', file: undefined };
        });

        if (shouldDeleteFile && fileDeleteHandler) {
            fileDeleteHandler();
        }
    };

    return {
        fileData,
        handleFileUpload,
        handleUrlChange,
        ensureHttp,
        setFileData,
        handleFileClear,
    };
};
