import { AppThunk } from '../../../../../../app/store';
import {
    ListingInformationEvents,
    AgentEvents,
} from '../../../../../../shared/models/loader/loaderModels';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import {
    CustomKey,
    ListingAgent,
    ListingAgent as ListingAgentResponse,
} from '../../../listingModels';
import { updateMainListingData } from '../../../listingSlice';
import {
    addNewAgent,
    deleteAgentDetails,
    toggleSelectSale,
    updateAgentDetails,
    updateOrderedAgentDetails,
    updateToggleSwitch,
} from './agentSectionApi';
import { filterListingAgents } from './agentSearchAndAdd/agentFunction';
import { updateActiveTabListingList } from '../../../../listingListSlice';
import { filter, sortBy } from 'lodash';

export const selectSaleToggle =
    (listingId: string, agentId: string, selectSale: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(ListingInformationEvents.LISTING_AGENT_UPDATE));
            const response = await toggleSelectSale(listingId, agentId, selectSale);
            if (response.status === 204) {
                dispatch(setSuccess(`Agent select sale is updated successfully`));
                let bothSidesAgents = getState().listing.listing.data.listingAgents;
                bothSidesAgents = bothSidesAgents.map((agent: ListingAgent) =>
                    agent.agentId === agentId
                        ? { ...agent, selectSale: selectSale }
                        : agent,
                );
                dispatch(
                    updateMainListingData({
                        listingAgents: sortBy(bothSidesAgents, [
                            'sideTypeId',
                            'displayOrder',
                        ]),
                    }),
                );
                const isSelectSaleForAgent = bothSidesAgents.some(
                    (agent) => agent.selectSale,
                );
                dispatch(
                    updateActiveTabListingList({
                        listingId: listingId,
                        isSelectSale: isSelectSaleForAgent,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to toggle select Sale');
            dispatch(setError('Agent select sale is not updated'));
        } finally {
            dispatch(removeLoadingEvent(ListingInformationEvents.LISTING_AGENT_UPDATE));
        }
    };

export const updateToggle =
    (listingId: string, toggleName: string, status: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(ListingInformationEvents.LISTING_HEADER_UPDATE));
            const response = await updateToggleSwitch(listingId, toggleName, status);
            if (response.status === 204) {
                const mainStateUpdate: CustomKey = {
                    displayOfferPriceForSelectSold: status,
                };
                dispatch(updateMainListingData(mainStateUpdate));
                dispatch(setSuccess(`Updated Successfully`));
            }
        } catch (e) {
            Logger.error('Toggle update failed');
            dispatch(setError(`Toggle Update Failed`));
        } finally {
            dispatch(removeLoadingEvent(ListingInformationEvents.LISTING_HEADER_UPDATE));
        }
    };

export const addAgent =
    (listingId: string, agent: ListingAgent): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(AgentEvents.AGENT_ADD));
            const bothSidesAgents = getState().listing.listing.data.listingAgents;

            // Filter agents based on sideTypeId (Buyer / Seller)
            const filteredAgents = filterListingAgents(bothSidesAgents, agent.sideTypeId);
            const totalAgents = filteredAgents.length + 1;
            let i = 0;
            const equalSplit = Math.floor(100 / totalAgents);
            const equalSplitRem = 100 % totalAgents;
            const response = await addNewAgent(listingId, agent);
            if (response?.id !== '') {
                const updatedAgents = [...bothSidesAgents, { ...agent, ...response }].map(
                    (item) => {
                        i++;
                        return item.sideTypeId === agent.sideTypeId
                            ? {
                                  ...item,
                                  split: i <= equalSplitRem ? equalSplit + 1 : equalSplit,
                              }
                            : item;
                    },
                );
                // need to update split for even a single agent added, so calling reorder
                if (totalAgents > 0) {
                    dispatch(
                        updateOrderedAgents(listingId, updatedAgents, agent.sideTypeId),
                    );
                }
                const mainStateUpdate: CustomKey = {
                    listingAgents: sortBy(updatedAgents, ['sideTypeId', 'displayOrder']),
                };
                dispatch(updateMainListingData(mainStateUpdate));
                dispatch(setSuccess('Agent is added'));
            }
        } catch {
            Logger.error('Error in adding the agent');
            dispatch(setError('Error in adding the agent'));
        } finally {
            dispatch(removeLoadingEvent(AgentEvents.AGENT_ADD));
        }
    };

export const updateAgent =
    (listingId: string, agent: ListingAgent, status: boolean, split: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(AgentEvents.AGENT_UPDATE));
            const response = await updateAgentDetails(listingId, agent);
            if (response.status === 200) {
                const bothSidesAgents = getState().listing.listing.data.listingAgents;
                const updatedAgents = bothSidesAgents.map((item) =>
                    item.agentId === agent.agentId && item.sideTypeId === agent.sideTypeId
                        ? { ...item, ...agent }
                        : item,
                );
                const mainStateUpdate: CustomKey = {
                    listingAgents: sortBy(updatedAgents, ['sideTypeId', 'displayOrder']),
                };
                dispatch(updateMainListingData(mainStateUpdate));
                if (status) {
                    dispatch(setSuccess('Agent is updated'));
                } else {
                    dispatch(
                        setError('Remaining commission is: ' + (100 - split).toFixed(2)),
                    );
                }
            }
        } catch {
            Logger.error('Error in updating the agent');
            dispatch(setError('Error in updating the agent'));
        } finally {
            dispatch(removeLoadingEvent(AgentEvents.AGENT_UPDATE));
        }
    };

export const deleteAgent =
    (listingId: string, agent: ListingAgent): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(AgentEvents.AGENT_DELETE));
            const response = await deleteAgentDetails(listingId, agent);
            if (response) {
                const bothSidesAgents = getState().listing.listing.data.listingAgents;

                // Filter agents based on sideTypeId (Buyer / Seller)
                const filteredAgents = filterListingAgents(
                    bothSidesAgents,
                    agent.sideTypeId,
                );
                const totalAgents = filteredAgents.length - 1;
                const updatedAgents = bothSidesAgents.filter((item) => {
                    return item.agentId === agent.agentId &&
                        item.sideTypeId === agent.sideTypeId
                        ? false
                        : true;
                });
                let i = 0;
                const equalSplit = Math.floor(100 / totalAgents);
                const equalSplitRem = 100 % totalAgents;
                const updatedSplit = updatedAgents.map((item) => {
                    if (item.sideTypeId === agent.sideTypeId) {
                        i++;
                        return {
                            ...item,
                            split: i <= equalSplitRem ? equalSplit + 1 : equalSplit,
                            displayOrder: i,
                        };
                    } else {
                        return item;
                    }
                });

                if (totalAgents > 0)
                    dispatch(
                        updateOrderedAgents(listingId, updatedSplit, agent.sideTypeId),
                    );
                const mainStateUpdate: CustomKey = {
                    listingAgents: sortBy(updatedSplit, ['sideTypeId', 'displayOrder']),
                };

                dispatch(updateMainListingData(mainStateUpdate));
                dispatch(setSuccess('Agent is deleted'));
            }
        } catch {
            Logger.error('Error in deleting the agent');
            dispatch(setError('Error in deleting the agent'));
        } finally {
            dispatch(removeLoadingEvent(AgentEvents.AGENT_DELETE));
        }
    };

export const updateOrderedAgents =
    (listingId: string, agents: ListingAgent[], sideTypeId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(AgentEvents.AGENT_UPDATE));
            const response = await updateOrderedAgentDetails(
                listingId,
                sortBy(filter(agents, ['sideTypeId', sideTypeId]), 'displayOrder'),
            );
            if (response.status === 204) {
                const mainStateUpdate: CustomKey = {
                    listingAgents: sortBy(agents as ListingAgentResponse[], [
                        'sideTypeId',
                        'displayOrder',
                    ]),
                };
                dispatch(updateMainListingData(mainStateUpdate));
                dispatch(setSuccess('Agents orders are updated'));
            }
        } catch {
            Logger.error('Error in updating the agents orders');
            dispatch(setError('Error in updating the agents orders'));
        } finally {
            dispatch(removeLoadingEvent(AgentEvents.AGENT_UPDATE));
        }
    };
