import { FunctionComponent, useEffect, useState, MouseEvent } from 'react';
import moment, { Moment } from 'moment';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@material-ui/core';
import { DatePicker } from 'antd';
import { useAppDispatch, useFormData } from '../../../../app/hooks';
import {
    saveSocialCampaign,
    deleteMarketingFile,
} from '../../../../features/cmr/cmrSlice';
import { useFileManagement } from '../../../marketing/hooks';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';
import { SocialCampaignData } from '../../../models/marketing/marketingModels';
import { datePickerFormat } from '../../../../utils/urlUtils';

interface CustomSocialCampaignProps {
    /* Social campaign data passed from parent component during edit */
    socialCampaign?: SocialCampaignData;
    /* Emit function when cancel button is clicked */
    cancelClick(): void;
    /* Indicates whether the form is in edit mode */
    formEdit?: boolean;
    /* Guid of the listing */
    listingId?: string;
    /* If defined, use it for CMR, otherwise for property marketing */
    sectionId?: number;
}

const initialSocialCampaign: SocialCampaignData = {
    fromDate: '',
    toDate: '',
    description: '',
    potentialReach: 0,
    views: 0,
    url: '',
    engagements: 0,
    clicks: 0,
    isFile: false,
    file: undefined,
};

const CustomSocialCampaign: FunctionComponent<CustomSocialCampaignProps> = (props) => {
    const [active, setActive] = useState(true);
    const dispatch = useAppDispatch();

    const {
        formData,
        setFormData,
        handleTextChange,
        handleDateChange,
        handleNumberChange,
        removeSpaces,
    } = useFormData(props.socialCampaign || initialSocialCampaign);
    const {
        fileData,
        handleFileUpload,
        handleUrlChange,
        ensureHttp,
        setFileData,
        handleFileClear,
    } = useFileManagement(formData);

    useEffect(() => {
        setFormData(formData);
        setFileData(fileData);
        const { fromDate, description } = formData;
        setActive(fromDate === '' || !description || description === '');
    }, [formData, fileData, setFileData, setFormData]);

    const deleteFile = () => {
        dispatch(deleteMarketingFile(formData, props.sectionId));
    };

    const disableToDate = (current: Moment): boolean => {
        const customDate = moment(formData.fromDate, 'MM-DD-YYYY');
        return current <= moment(customDate, 'MM-DD-YYYY').endOf('day');
    };

    const disableFromDate = (current: Moment): boolean => {
        const customDate = moment(formData.toDate, 'MM-DD-YYYY');
        return current >= moment(customDate, 'MM-DD-YYYY').endOf('day');
    };

    const save = (e: MouseEvent<HTMLButtonElement>) => {
        const saveRequest = { ...formData, ...fileData };
        dispatch(
            saveSocialCampaign(
                props.listingId || '',
                saveRequest,
                props.sectionId ? true : false,
            ),
        );
        setFormData(initialSocialCampaign);
        handleFileClear(e, false);
        if (props.sectionId) {
            if (!props.formEdit)
                googleAnalyticsAction('Add', 'Click', 'Social Campaign Add');
            else googleAnalyticsAction('Edit', 'Click', 'Social Campaign Edit Save');
        }
        props.cancelClick();
    };

    const spanClass = props.formEdit ? 'editActionHolder' : 'actionHolder';
    const spanDateClass = props.formEdit ? 'editDateHolder' : 'actionHolder';
    const inputClass = (additionalClass?: string): string => {
        return props.formEdit
            ? `cmrEditInput ${additionalClass}`
            : `cmrInput ${additionalClass}`;
    };

    const renderFileUploadFields = () => {
        return (
            <>
                <span className="actionHolder">
                    <Input
                        className={props.formEdit ? 'cmrUrlInput' : 'cmrInput'}
                        placeholder="Url"
                        name="url"
                        onChange={handleUrlChange}
                        onBlur={ensureHttp}
                        inputProps={{ 'aria-label': 'Url' }}
                        value={fileData?.url || ''}
                        disableUnderline={props.sectionId ? false : true}
                    />
                </span>
                <span className="actionHolder ecFileHolder">
                    {!fileData.file && !fileData.isFile ? (
                        <label className="ecFile">
                            Upload File
                            <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                            <Input
                                className={
                                    props.formEdit ? 'cmrUrlInput file' : 'cmrInput file'
                                }
                                name="fileUpload"
                                inputProps={{
                                    'aria-label': 'File Upload',
                                    accept: '.jpg,.png,.pdf',
                                }}
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </label>
                    ) : (
                        <label className="ecFile">
                            <button
                                onClick={(e) =>
                                    handleFileClear(
                                        e,
                                        props.formEdit || false,
                                        deleteFile,
                                    )
                                }
                                className="removeFile"
                            >
                                Remove File
                            </button>
                            <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                        </label>
                    )}

                    <span className="spec">
                        File Format: JPG, PNG, PDF | File Size: 50MB
                    </span>
                </span>
            </>
        );
    };

    return (
        <div>
            {props.formEdit && props.sectionId ? <div className="overlayEditForm" /> : ''}
            <div className={props.formEdit ? 'edit-form-container' : 'form-container'}>
                <div
                    className={
                        props.formEdit ? 'edit-social-campaign' : 'add-social-campaign'
                    }
                >
                    <span className={spanDateClass}>
                        <DatePicker
                            value={
                                formData.fromDate && formData.fromDate !== ''
                                    ? moment(formData.fromDate, 'YYYY-MM-DDTHH:mm:ss')
                                    : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('fromDate', date);
                            }}
                            disabledDate={disableFromDate}
                            name="fromDate"
                            placeholder="From Date"
                            showToday={false}
                            className="datePickerCommon cmrDate"
                        />
                        <DatePicker
                            value={
                                formData?.toDate && formData?.toDate !== ''
                                    ? moment(formData.toDate, 'YYYY-MM-DDTHH:mm:ss')
                                    : undefined
                            }
                            disabledDate={disableToDate}
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('toDate', date);
                            }}
                            name="toDate"
                            placeholder="To Date"
                            showToday={false}
                            className="datePickerCommon cmrDate"
                        />
                    </span>
                    <span className={spanClass}>
                        <Input
                            className={inputClass('editType')}
                            placeholder="Description"
                            name="description"
                            value={formData?.description || ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            onBlur={(e) => removeSpaces(e, 255)}
                            inputProps={{ 'aria-label': 'Type' }}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className={spanClass}>
                        <Input
                            className={inputClass('editReach')}
                            placeholder="Potential Reach"
                            name="potentialReach"
                            value={formData?.potentialReach || ''}
                            onChange={(e) => handleNumberChange(e, true)}
                            inputProps={{ 'aria-label': 'Reach', maxLength: 10 }}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className={spanClass}>
                        <Input
                            className={inputClass('editViews')}
                            placeholder="Views"
                            name="views"
                            value={formData?.views || ''}
                            onChange={handleNumberChange}
                            inputProps={{ 'aria-label': 'Views', maxLength: 10 }}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className={spanClass}>
                        <Input
                            className={inputClass('editEngagement')}
                            placeholder="Engagements"
                            name="engagements"
                            value={formData?.engagements || ''}
                            onChange={handleNumberChange}
                            inputProps={{ 'aria-label': 'Engagements', maxLength: 10 }}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    <span className={spanClass}>
                        <Input
                            className={inputClass('editClicks')}
                            placeholder="Clicks"
                            name="clicks"
                            value={formData?.clicks || ''}
                            onChange={handleNumberChange}
                            inputProps={{ 'aria-label': 'Clicks', maxLength: 10 }}
                            disableUnderline={props.sectionId ? false : true}
                        />
                    </span>
                    {!props.formEdit ? renderFileUploadFields() : ''}
                </div>
                {props.formEdit ? (
                    <div className="editFileHolder">{renderFileUploadFields()}</div>
                ) : (
                    ''
                )}
                {!props.formEdit ? (
                    <div className="addButtonHolder">
                        <button
                            disabled={active ? true : false}
                            className={`addButton ${active ? 'active' : 'unActive'}`}
                            onClick={save}
                        >
                            Add
                        </button>
                    </div>
                ) : (
                    <div className={props.formEdit ? 'editButtonHolder' : ''}>
                        <button
                            className="actionButton cancel"
                            onClick={() => props.cancelClick()}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={active ? true : false}
                            className={
                                !active
                                    ? 'actionButton saveActive'
                                    : 'actionButton saveInActive'
                            }
                            onClick={save}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSocialCampaign;
