import {
    DesignVaultTemplateFetchResponse,
    PropertyGuidResponse,
} from './designVaultModel';
import { getCall } from '../../utils/api/apiWrapper';

export function fetchDesignVaultTemplate(
    officeId?: string,
): Promise<DesignVaultTemplateFetchResponse> {
    const query = officeId ? `?officeId=${officeId}` : '';
    return getCall<DesignVaultTemplateFetchResponse>(`designvault/templates${query}`);
}

export function fetchPropertyGuid(listingNumber: string): Promise<PropertyGuidResponse> {
    return getCall<PropertyGuidResponse>(`DesignVault/${listingNumber}`);
}
