import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import {
    getCall,
    postCall,
    postFormData,
    putCallRaw,
} from '../../../utils/api/apiWrapper';
import {
    ImageDeleteModel,
    MediaOutletCreateEditModel,
} from './component/createAndEdit/createEditMediaOutletModel';
import { MediaOutletList, MediaOutletSearchRequest } from './mediaOutletListModels';

export function getMODetails(id: string): Promise<MediaOutletCreateEditModel> {
    return getCall<MediaOutletCreateEditModel>(`MediaOutlet/${id}`);
}

export function getMediaOutletFromApi(
    mediaOutletRequest: MediaOutletSearchRequest,
): Promise<PaginationResponse<MediaOutletList>> {
    return postCall<MediaOutletSearchRequest, PaginationResponse<MediaOutletList>>(
        `MediaOutlet/search`,
        mediaOutletRequest,
    );
}

export function createEditMediaOutlets(data: FormData): Promise<MediaOutletList> {
    return postFormData<MediaOutletList>('MediaOutlet', data);
}

export function deleteMediaOutletImageApi(data: ImageDeleteModel): Promise<Response> {
    return putCallRaw(`MediaOutlet/${data.id}/file`, data);
}

export function deactivateMediaOutletApi(id: string): Promise<Response> {
    return putCallRaw(`MediaOutlet/${id}/deactivation`, {});
}
