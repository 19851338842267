import React, { ChangeEvent, FunctionComponent } from 'react';
import { Grid, GridSize, TextField } from '@material-ui/core';
import { ListingData, ListingPrice, RentalDetails } from '../../../../listingModels';
import { RentalTermsModel } from '../priceModel';
import { useFormData, useAppDispatch } from '../../../../../../../app/hooks';
import FormRow from '../../../../../../../shared/component/formFields/FormRow';
import RadioButtonToggle from '../../../../../../../shared/component/formFields/RadioButtonToggle';
import { currencyFormat } from '../../../../../../../utils/urlUtils';
import { disableListingPriceEdit } from '../../../../../utils';
import { LookupInfo } from '../../../../../../../shared/models/lookups/lookupsModels';
import {
    rentalDetails,
    editFormComponent,
    listingCommissionTypes,
    buyerCommissionRentalTypes,
} from '../../../../listingConstants';
import { setCurrentFormData } from '../../../../listingSlice';

interface RentalTermProps {
    data: ListingData;
    canAccess: boolean;
    renderCheckBox: (
        label: string,
        property: string,
        disabled?: boolean,
        customField?: string,
        customFieldValue?: boolean,
    ) => JSX.Element;
    renderFormRow: (
        label: string,
        control: JSX.Element,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => JSX.Element;
}

const RentalTerms: FunctionComponent<RentalTermProps> = ({
    data,
    canAccess,
    renderCheckBox,
    renderFormRow
}) => {
    const initialRentalData: RentalTermsModel = {
        listingRentalDetails: data.listingRentalDetails ? data.listingRentalDetails : [],
        noFeeRental: data.noFeeRental,
        buyerCommission: data.buyerCommission,
        listingCommission: data.listingCommission,
        prices: data.prices,
        buyerCommissionType: data.buyerCommissionType,
        listingCommissionType: data.listingCommissionType,
    };
    const dispatch = useAppDispatch();
    const { formData, setFormData, handleRadioButtonChange, handleTextChange, handleCurrencyChange } =
        useFormData(initialRentalData);

    const handleRentalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const rentalToUpdate = formData.listingRentalDetails.find(
            (rental: RentalDetails) =>
                rental.rentalPeriodId === parseInt(name.split(':')[0]),
        );
        let updatedRentals: RentalDetails[] = [];
        if (
            name.split(':')[1] === rentalDetails.price ||
            name.split(':')[1] === rentalDetails.yearInfo
        ) {
            const numberVal = parseInt(value.replace(/[$,]+/g, ''));
            if (!isNaN(numberVal) && rentalToUpdate) {
                updatedRentals = formData.listingRentalDetails.map(
                    (rental: RentalDetails) => {
                        return rental.rentalPeriodId === rentalToUpdate.rentalPeriodId
                            ? { ...rentalToUpdate, [name.split(':')[1]]: numberVal }
                            : rental;
                    },
                );
                setFormData({ ...formData, listingRentalDetails: updatedRentals });
            } else {
                if (rentalToUpdate) {
                    updatedRentals = formData.listingRentalDetails.map(
                        (rental: RentalDetails) => {
                            return rental.rentalPeriodId === rentalToUpdate.rentalPeriodId
                                ? {
                                      ...rentalToUpdate,
                                      [name.split(':')[1]]:
                                          name.split(':')[1] === rentalDetails.price
                                              ? 0
                                              : name.split(':')[1] ===
                                                rentalDetails.yearInfo
                                              ? ''
                                              : '',
                                  }
                                : rental;
                        },
                    );
                    setFormData({ ...formData, listingRentalDetails: updatedRentals });
                }
            }
        } else {
            if (rentalToUpdate) {
                updatedRentals = formData.listingRentalDetails.map(
                    (rental: RentalDetails) => {
                        return rental.rentalPeriodId === rentalToUpdate.rentalPeriodId
                            ? { ...rentalToUpdate, [name.split(':')[1]]: value }
                            : rental;
                    },
                );
                setFormData({ ...formData, listingRentalDetails: updatedRentals });
            }
        }
        const prices: ListingPrice[] = [];
        if (updatedRentals.length > 0) {
            updatedRentals.forEach((updated: RentalDetails) => {
                const rent: ListingPrice = {
                    price: updated.price ? updated.price : 0,
                    rentalPeriod: updated.name,
                };
                prices.push(rent);
            });
        }
        setFormData({
            ...formData,
            listingRentalDetails: updatedRentals,
            prices: prices,
        });
    };

    const getName = (rental: RentalDetails, property: string) => {
        return (rental.rentalPeriodId + ':' + property).toString();
    };
    const noFeeRentals: LookupInfo[] = [
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
    ];

    const renderField = (
        label: string,
        property: string,
        labelSize: GridSize = 2,
        controlSize: GridSize = 4,
    ) => {
        const disabled =
            property === 'buyerCommission'
                ? disableListingPriceEdit() || formData.buyerCommissionType === 3
                : disableListingPriceEdit();
        return (
            <FormRow
                label={label}
                control={
                    <TextField
                        value={formData[property as keyof typeof formData] || ''}
                        onChange={handleTextChange}
                        InputProps={{
                            disableUnderline: true,
                            'aria-label': `${property}`,
                            className: `inputBaseRoot ${disabled ? 'inputDisabled' : ''}`,
                            endAdornment: '%',
                        }}
                        inputProps={{ maxLength: 5 }}
                        onBlur={setFormDataToPersist}
                        name={property}
                        disabled={disabled}
                    />
                }
                prefix=""
                suffix=""
                controlClass="formControlRoot"
                labelXs={null}
                labelSm={labelSize}
                labelMd={labelSize}
                controlXs={null}
                controlSm={controlSize}
                controlMd={controlSize}
            />
        );
    };

    const renderRow = (
        label: string,
        property: string,
        disabled?: boolean,
        endAdornment?: string,
        refIndex?: number,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => {
        return renderFormRow(
            label,
            <TextField
                value={currencyFormat(
                    formData[property as keyof typeof formData] as number,
                )}
                onChange={handleCurrencyChange}
                InputProps={{
                    disableUnderline: true,
                    'aria-label': `${property}`,
                    className: `inputBaseRoot ${disabled ? 'inputDisabled' : ''}`,
                    startAdornment: '$',
                }}
                inputProps={{ maxLength: 11 }}
                onBlur={setFormDataToPersist}
                name={property}
                disabled={disabled ? disabled : false}
            />,
            labelSize,
            controlSize,
        );
    };
    const setFormDataToPersist = () => {
        dispatch(
            setCurrentFormData({
                formData,
                listingEditFormName: editFormComponent.prices,
            }),
        );
    };

    //to handle the buyer comm radio change and update the comm based on selection
    const buyerCommRadioUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === '3') {
            setFormData({
                ...formData,
                buyerCommission: null,
                buyerCommissionType: 3,
            });
            // setFormDataToPersist();
        } else {
            handleRadioButtonChange(event);
        }
    };
    return (
        <div className="pricesSection">
            <div className="section">
                {formData.listingRentalDetails.length > 0
                    ? formData.listingRentalDetails.map(
                          (rental: RentalDetails, index: number) => (
                              <Grid key={index}>
                                  <Grid
                                      item
                                      container
                                      className="content"
                                      alignItems="center"
                                      spacing={2}
                                      md={12}
                                      key={index}
                                  >
                                      <FormRow
                                          label={`${
                                              rental.name ? rental.name.toUpperCase() : ''
                                          }`}
                                          control={
                                              <TextField
                                                  value={
                                                      formData.listingRentalDetails[index]
                                                          .price
                                                          ? currencyFormat(
                                                                formData
                                                                    .listingRentalDetails[
                                                                    index
                                                                ].price,
                                                            )
                                                          : ''
                                                  }
                                                  onChange={handleRentalChange}
                                                  InputProps={{
                                                      disableUnderline: true,
                                                      'aria-label': 'price',
                                                      className: `inputBaseRoot ${
                                                          disableListingPriceEdit()
                                                              ? 'inputDisabled'
                                                              : ''
                                                      }`,
                                                      startAdornment: '$',
                                                  }}
                                                  inputProps={{ maxLength: 13 }}
                                                  onBlur={setFormDataToPersist}
                                                  name={getName(rental, 'price')}
                                                  disabled={disableListingPriceEdit()}
                                              />
                                          }
                                          prefix=""
                                          suffix=""
                                          controlClass="formControlRoot"
                                          labelXs={null}
                                          labelSm={2}
                                          labelMd={2}
                                          controlXs={null}
                                          controlSm={4}
                                          controlMd={4}
                                      />
                                      <FormRow
                                          label="YEAR INFO"
                                          control={
                                              <TextField
                                                  name={getName(rental, 'yearInfo')}
                                                  value={
                                                      formData.listingRentalDetails[index]
                                                          .yearInfo
                                                          ? formData.listingRentalDetails[
                                                                index
                                                            ].yearInfo
                                                          : ''
                                                  }
                                                  inputProps={{ maxLength: 4 }}
                                                  onBlur={setFormDataToPersist}
                                                  onChange={handleRentalChange}
                                                  InputProps={{
                                                      disableUnderline: true,
                                                      'aria-label': 'yearInfo',
                                                      className: 'inputBaseRoot',
                                                  }}
                                              />
                                          }
                                          prefix=""
                                          suffix=""
                                          controlClass="formControlRoot"
                                          labelXs={null}
                                          labelSm={2}
                                          labelMd={2}
                                          controlXs={null}
                                          controlSm={4}
                                          controlMd={4}
                                      />
                                  </Grid>
                                  {rental.rentalPeriodId === 14 ? (
                                      <Grid
                                          item
                                          container
                                          className="content"
                                          alignItems="center"
                                          spacing={2}
                                          md={6}
                                          sm={6}
                                      >
                                          <Grid
                                              item
                                              container
                                              sm={4}
                                              md={4}
                                              className="description"
                                          >
                                              {rental.name
                                                  ? rental.name.toUpperCase()
                                                  : ''}{' '}
                                              DESCRIPTION
                                          </Grid>
                                          <Grid item container sm={8} md={8}>
                                              <TextField
                                                  className="formControlRoot"
                                                  value={
                                                      formData.listingRentalDetails[index]
                                                          .description
                                                          ? formData.listingRentalDetails[
                                                                index
                                                            ].description
                                                          : ''
                                                  }
                                                  onChange={handleRentalChange}
                                                  InputProps={{
                                                      disableUnderline: true,
                                                      'aria-label': 'description',
                                                      className: 'inputBaseRoot',
                                                  }}
                                                  inputProps={{ maxLength: 35 }}
                                                  onBlur={setFormDataToPersist}
                                                  name={getName(rental, 'description')}
                                              />
                                          </Grid>
                                      </Grid>
                                  ) : (
                                      ''
                                  )}
                              </Grid>
                          ),
                      )
                    : ''}
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="baseline"
                    spacing={2}
                    md={12}
                >
                    <Grid item container alignItems="center" md={6}>
                        {formData.listingCommissionType &&
                        formData.listingCommissionType === 2
                            ? renderRow(
                                  'LISTING COMMISSION',
                                  'listingCommission',
                                  disableListingPriceEdit(),
                                  undefined,
                                  undefined,
                                  4,
                                  8,
                              )
                            : renderField(
                                  'LISTING COMMISSION',
                                  'listingCommission',
                                  4,
                                  8,
                              )}
                        <FormRow
                            label=""
                            control={
                                <RadioButtonToggle
                                    id={
                                        formData.listingCommissionType !== null
                                            ? formData.listingCommissionType
                                            : '1' //defaulted to percentage
                                    }
                                    name="listingCommissionType"
                                    items={listingCommissionTypes}
                                    handleRadioButtonChange={handleRadioButtonChange}
                                    handleBlur={setFormDataToPersist}
                                    formClass={{
                                        label: 'formControlLabel',
                                    }}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot customRadioButton"
                            labelXs={null}
                            labelSm={null}
                            labelMd={4}
                            controlXs={null}
                            controlSm={null}
                            controlMd={8}
                        />
                    </Grid>
                    <Grid item container alignItems="center" md={6}>
                        {formData.buyerCommissionType &&
                        formData.buyerCommissionType === 2
                            ? renderRow(
                                  'BUYER COMMISSION',
                                  'buyerCommission',
                                  disableListingPriceEdit(),
                                  undefined,
                                  undefined,
                                  4,
                                  8,
                              )
                            : renderField('BUYER COMMISSION', 'buyerCommission', 4, 8)}
                        <FormRow
                            label=""
                            control={
                                <RadioButtonToggle
                                    id={
                                        formData.buyerCommissionType !== null
                                            ? formData.buyerCommissionType
                                            : '1' //defaulted to percentage
                                    }
                                    name="buyerCommissionType"
                                    items={buyerCommissionRentalTypes}
                                    handleRadioButtonChange={buyerCommRadioUpdate}
                                    handleBlur={setFormDataToPersist}
                                    formClass={{
                                        label: 'formControlLabel',
                                    }}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot customRadioButton"
                            labelXs={null}
                            labelSm={null}
                            labelMd={4}
                            controlXs={null}
                            controlSm={null}
                            controlMd={8}
                        />
                    </Grid>
                </Grid>
                {/* <Grid
                    item
                    container
                    className="content infoText"
                    alignItems="center"
                    md={12}
                >
                    * "Contact Listing Agent" text will appear on applicable websites
                </Grid> */}
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="NO FEE RENTAL"
                        control={
                            <RadioButtonToggle
                                id={
                                    formData.noFeeRental !== null
                                        ? formData.noFeeRental
                                        : ''
                                }
                                name="noFeeRental"
                                items={noFeeRentals}
                                handleRadioButtonChange={handleRadioButtonChange}
                                handleBlur={setFormDataToPersist}
                                formClass={{ label: 'formControlLabel' }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelXs={null}
                        labelSm={2}
                        labelMd={2}
                        controlXs={null}
                        controlSm={4}
                        controlMd={4}
                    />
                </Grid>
            </div>
        </div>
    );
};

export default RentalTerms;
