import { FunctionComponent, useEffect, useState } from 'react';
import { Input } from '@material-ui/core';
import { useFormData, useAppDispatch } from '../../../../../app/hooks';
import { ImageData, CaptionDetails } from '../../../../models/images/sharedModel';
import './imageCaption.scss';
import { calculateRemainingChars } from '../../../../../utils/urlUtils';
import { AppThunk } from '../../../../../app/store';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';

interface ImageCaptionProps {
    image: ImageData | undefined;
    currentImageType: string;
    isFloorPlanTitle: boolean;
    cancelHandler(): void;
    isFullScreen: boolean;
    saveImageCaption: (id: string, data: CaptionDetails, imageType: string) => AppThunk;
    entityType: string;
    office: string | null;
}

const ImageCaption: FunctionComponent<ImageCaptionProps> = ({
    image,
    currentImageType,
    isFloorPlanTitle,
    cancelHandler,
    isFullScreen,
    saveImageCaption,
    entityType,
    office,
}) => {
    const initialCaptionData: CaptionDetails = {
        caption: image
            ? isFloorPlanTitle
                ? image.floorplancaption
                    ? image.floorplancaption
                    : ''
                : image.caption
                ? image.caption
                : ''
            : '',
        isFloorPlanCaption: isFloorPlanTitle,
    };
    const { formData, setFormData, handleTextChange, removeSpaces } =
        useFormData(initialCaptionData);
    const dispatch = useAppDispatch();
    const [totalChar, setTotalChar] = useState<number>(250);

    const saveCaption = async () => {
        if (image) {
            await dispatch(saveImageCaption(image.guid, formData, currentImageType));
            cancelHandler();
            googleAnalyticsAction(
                'Save',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image Edit, ${
                    isFloorPlanTitle ? 'Floor plan title' : 'Image caption'
                } save, Image type: ${currentImageType}`,
            );
            googleAnalyticsAction(
                'Save',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image Edit, ${
                    isFloorPlanTitle ? 'Floor plan title' : 'Image caption'
                } save, Image type: ${currentImageType}, Office: ${office}`,
            );
        }
    };

    const focusCaption = () => {
        const captionInputFieldElement = document.getElementById('captionInputField');
        if (captionInputFieldElement !== null) {
            captionInputFieldElement.focus();
        }
    };

    useEffect(() => {
        if (isFloorPlanTitle && image) {
            setFormData({
                ...formData,
                caption: image.floorplancaption ? image.floorplancaption : '',
                isFloorPlanCaption: isFloorPlanTitle,
            });
        } else if (!isFloorPlanTitle && image) {
            setFormData({
                ...formData,
                caption: image.caption ? image.caption : '',
                isFloorPlanCaption: isFloorPlanTitle,
            });
        }
    }, [isFloorPlanTitle, image, formData, setFormData]);

    useEffect(() => {
        setFormData(formData);
        if (formData.caption?.length) {
            const remainingChar = calculateRemainingChars(formData.caption, 250);
            setTotalChar(remainingChar);
        }
    }, [formData, setFormData, setTotalChar]);

    return (
        <div
            className={
                isFullScreen
                    ? 'imageCaptionContainer imageCaptionContainerFullScreen'
                    : 'imageCaptionContainer'
            }
        >
            <span className="actionHolder">
                <Input
                    id="captionInputField"
                    value={formData.caption ? formData.caption : ''}
                    onChange={handleTextChange}
                    className="captionInput"
                    placeholder={isFloorPlanTitle ? 'Floor Plan Title' : 'Caption Text'}
                    name="caption"
                    inputProps={{
                        'aria-label': 'Caption',
                        maxLength: 250,
                    }}
                    onBlur={removeSpaces}
                    onClick={focusCaption}
                />
                <span className="textCount">{totalChar}/250</span>
                <button className="saveAction" onClick={saveCaption}>
                    Save
                </button>
            </span>
        </div>
    );
};

export default ImageCaption;
