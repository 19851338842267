import {
    getCall,
    postFormData,
    postCall,
    packagePost,
    postCallRaw,
    putCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import { TotalImageCount, ImageSaveResult, DeleteData } from './developmentPhotosModels';
import { DataFlowResponse } from '../../../../../../utils/api/apiModels';
import {
    PackageProps,
    DownloadPackage,
    ShareDetails,
    CaptionDetails,
    TagSave,
    dndSave,
    Filter,
    ImageTypes,
} from '../../../../../../shared/models/images/sharedModel';

export function getTotalImageCount(
    entityId: string,
    entityType: string,
): Promise<TotalImageCount[]> {
    return getCall<TotalImageCount[]>(`image/${entityId}/count?entityType=${entityType}`);
}

export function getImageTypes(
    entityId: string,
    entityType: string,
): Promise<ImageTypes[]> {
    return getCall<ImageTypes[]>(`image/${entityId}/${entityType}/types`);
}

export function saveImages(request: FormData): Promise<ImageSaveResult> {
    return postFormData<ImageSaveResult>(`image/save`, request);
}

export function deleteImages(request: DeleteData): Promise<DataFlowResponse> {
    return postCall<DeleteData, DataFlowResponse>(`image/delete`, request);
}

export function saveImagesPackage(
    url: string,
    request: PackageProps | DownloadPackage,
): Promise<string> {
    return packagePost<PackageProps | DownloadPackage>(url, request).then((res) =>
        res.text(),
    );
}

export function shareImages(request: ShareDetails): Promise<Response> {
    return postCallRaw('share/Send', request);
}

export function getFilter(): Promise<Filter[]> {
    // EntityTypeId = 2 for building
    return getCall<Filter[]>(`image/2/tags`);
}

export function getTags(entityId: string, imageId: string): Promise<Filter[]> {
    // EntityTypeId = 2 for building
    return getCall<Filter[]>(`image/2/${entityId}/${imageId}/tags`);
}

export function saveCaptionDetails(
    id: string,
    request: CaptionDetails,
): Promise<Response> {
    return putCallRaw(`image/${id}/caption`, request);
}

export function saveTags(
    entityId: string,
    imageId: string,
    request: TagSave,
): Promise<Response> {
    // EntityTypeId = 2 for building
    return putCallRaw(`image/2/${entityId}/${imageId}/tags`, request);
}

export function saveReorderImage(id: string, data: dndSave[]): Promise<Response> {
    return putCallRaw(`image/${id}/reorder`, data);
}

export function updateThumbnailImage(
    listingId: string,
    imageId: string,
): Promise<Response> {
    return putCallRaw(`listing/${listingId}/videos/thumbnail?imageId=${imageId}`, {});
}
