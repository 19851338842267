import { Chip } from '@material-ui/core';
import { FC } from 'react';
import { UserRoleLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import './userRoleCustomChips.scss';

interface UserRoleCustomChipsProps {
    userRole: UserRoleLookups;
    updateUserRoles: (userRole: UserRoleLookups, isAdded: boolean) => void;
}

const UserRoleCustomChips: FC<UserRoleCustomChipsProps> = ({
    userRole,
    updateUserRoles,
}) => {
    return (
        <Chip
            key={userRole.key}
            label={userRole.value}
            className={`user-role-custom-chip ${userRole.isSelected ? 'selected' : ''}`}
            onClick={() => updateUserRoles(userRole, true)}
            onDelete={() => updateUserRoles(userRole, false)}
        />
    );
};
export default UserRoleCustomChips;
