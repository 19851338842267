import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from '@material-ui/core';
import type { Identifier, XYCoord } from 'dnd-core';
import { FunctionComponent } from 'react';
import { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { itemTypes } from '../../../../../../../../../shared/constants/dragAndDrop/itemTypes';
import { CustomTabModel } from '../../../showcaseWebsiteModels';

export interface DndTabProps {
    tabId: number;
    index: number;
    tab: CustomTabModel;
    moveTab: (dragIndex: number, hoverIndex: number, isDropped: boolean) => void;
    sendSelectedTab: (index: number | undefined) => void;
    deleteSelectedTab: (
        e: React.MouseEvent<SVGElement, MouseEvent>,
        tabId: number | undefined,
    ) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DndTab: FunctionComponent<DndTabProps> = ({
    tabId,
    index,
    tab,
    moveTab,
    sendSelectedTab,
    deleteSelectedTab,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: itemTypes.customTab,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveTab(dragIndex, hoverIndex, false);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop(item: DragItem) {
            const dragIndex = item.index;
            const hoverIndex = index;
            moveTab(dragIndex, hoverIndex, true);
        },
    });
    const id = tab.tabId;
    const [, drag] = useDrag({
        type: itemTypes.customTab,
        item: () => {
            return { id, index };
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));
    return (
        <Tab
            label={
                <div>
                    {tab?.title?.length > 0 ? tab.title : 'New Tab '}
                    <FontAwesomeIcon
                        className="customTabDelete"
                        icon={faTrash}
                        onClick={(e) => deleteSelectedTab(e, tab.tabId)}
                    />
                </div>
            }
            data-handler-id={handlerId}
            id={`custom-tab-${tab.tabId}`}
            key={index}
            ref={ref}
            onClick={() => sendSelectedTab(tab.tabId)}
            value={tab.tabId}
            className={tabId === tab.tabId ? `selected` : 'not-selected'}
        />
    );
};

export default DndTab;
