import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { SearchComponentProps } from '../../advancedSearchModels';
import SearchFieldWrapper from './SearchFieldWrapper';
import { TextField } from '@material-ui/core';

const TextSearch: FunctionComponent<SearchComponentProps> = ({
    criteria,
    onCriteriaAdd,
}) => {
    const [val, setVal] = useState('');

    useEffect(() => {
        if (criteria.values?.length) {
            setVal(criteria.values.join(','));
        }
    }, [criteria.values]);

    const handleCriteriaUpdate = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const filterValues = criteria.allowMultiple
            ? e.target.value.split(',')
            : [e.target.value];
        onCriteriaAdd({ ...criteria, values: filterValues });
    };

    const component = (
        <TextField
            id={criteria.fieldName}
            onBlur={handleCriteriaUpdate}
            className=""
            onChange={(e) => setVal(e.currentTarget.value)}
            value={val}
            InputProps={{
                disableUnderline: true,
                className: 'inputBaseRoot',
            }}
        />
    );
    return (
        <SearchFieldWrapper
            control={component}
            label=""
            suffix={
                criteria.allowMultiple
                    ? 'You can enter multiple values separated by a comma'
                    : ''
            }
        />
    );
};

export default TextSearch;
