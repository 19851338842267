import React, { FunctionComponent } from 'react';
import { ListingAmenity } from '../../../../../../../../shared/models/listing/amenityModels';
import Grid from '@material-ui/core/Grid';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import MemoizedFeatureChips from './FeatureChips';
import FeatureWithText from './FeatureWithText';
import { getAmenitiesForGroup } from '../../../../../../../../shared/utils/listing/featureUtils';

interface AmenityGroupProps {
    /** The ID of the listing to render amenities for */
    listingId: string;

    /** The title of the group (corresponds to the amenity group name) */
    title: string;

    /** All amenities in the system for the group */
    amenitiesForGroup: ListingAmenity[];

    /** All amenities the listing is associated with */
    listingAmenities: ListingAmenity[];
}

const AmenityGroup: FunctionComponent<AmenityGroupProps> = ({
    listingId,
    title,
    amenitiesForGroup,
    listingAmenities,
}) => {
    const renderAmenitiesWithText = () => {
        const textAmenities = getAmenitiesForGroup(
            listingAmenities,
            amenitiesForGroup,
            true,
        );
        return textAmenities.map((ta) => (
            <FeatureWithText listingAmenity={ta} key={ta.amenityId} />
        ));
    };

    const renderChips = () => {
        const chips = getAmenitiesForGroup(listingAmenities, amenitiesForGroup, false);
        return (
            <FormRow
                label={title.toUpperCase()}
                control={
                    <>
                        <MemoizedFeatureChips chips={chips} listingId={listingId} />
                        {renderAmenitiesWithText()}
                    </>
                }
                controlClass="chipContent"
                labelMd={2}
                controlMd={10}
            />
        );
    };

    return (
        <Grid item container className="content section group" md={12} spacing={2}>
            {renderChips()}
        </Grid>
    );
};

export default AmenityGroup;
