import { combineReducers } from '@reduxjs/toolkit';
import { CmrSummary } from '../../features/cmr/cmrModels';
import BuilderReducer from '../../features/cmr/cmrSlice';
import ReportReducer from '../../features/cmrReport/cmrReportSlice';
import SchedulerReducer from '../../features/cmr/header/scheduler/schedulerSlice';
import { ClientMarketingSchedule } from '../../features/cmr/header/scheduler/schedulerModels';
import { CmrReport } from '../../features/cmrReport/cmrReportModel';

interface CMRRootState {
    builder: CmrSummary;
    report: CmrReport;
    scheduler: ClientMarketingSchedule;
}

export default combineReducers<CMRRootState>({
    builder: BuilderReducer,
    report: ReportReducer,
    scheduler: SchedulerReducer,
});
