import {
    SyndicationFetchResponse,
    UpdateSyndicationRequest,
} from './listingSyndicationModels';
import { getCall, postCallRaw } from '../../utils/api/apiWrapper';

// Fetching listing details by id
export function fetchListingSyndicationBylistingNumber(
    listingNumber: string,
): Promise<SyndicationFetchResponse> {
    return getCall<SyndicationFetchResponse>(`ListingSyndication/${listingNumber}`);
}

//saving the opt in value
export function updateSelectedListingValue(
    requestList: SyndicationFetchResponse,
): Promise<string> {
    const requestJson: UpdateSyndicationRequest = {
        listingId: requestList.id,
        listingNumber: requestList.listingNumber,
        sendToSothebysRealtyOnly: requestList.sendToSothebysRealtyOnly,
        dataConsumerInfos: requestList.listingSyndication.map((data, index) => {
            return {
                dataConsumerId: data.dataConsumerId,
                status: data.selected,
            };
        }),
    };
    return postCallRaw<UpdateSyndicationRequest>('ListingSyndication', requestJson).then(
        (res) => res.text(),
    );
}
