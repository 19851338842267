import {
    MarketingData,
    EmailCampaignData,
    SocialCampaignData,
    MarketingTypes,
    MarketingFields,
} from '../../shared/models/marketing/marketingModels';

export interface CmrSummary {
    paneldata: CmrPanel | Panel;
    listingdata: CmrHeader;
    listingId: string;
    sortOrder: SortingOrder[];
    currentOrderElement: number;
    graph: SortingOrder[];
    panelSpinner: boolean;
    panelSpinnerRight: boolean;
    panelInfo: PanelInfo;
    additionalPromotionTypes: MarketingTypes[];
    listingImage: ListingImage;
    listingImages: ListingImage[];
}
// Entire Panel
export interface Panel {
    [key: string]:
        | Analytics
        | TitleSummaryData[]
        | AdvertisementData[]
        | Showing[]
        | EmailCampaignData[]
        | SocialCampaignData[]
        | OpenHouseData[]
        | Notes
        | MarketingData[]
        | null;
}

export interface CmrPanel {
    viewsOnWeb: Analytics | null;
    inTheNews: TitleSummaryData[] | null;
    advertisements: AdvertisementData[] | null;
    showings: Showing[] | null;
    emailCampaigns: EmailCampaignData[] | null;
    socialCampaigns: SocialCampaignData[] | null;
    openHouses: OpenHouseData[] | null;
    notes: Notes | null;
    featuredPlacements: MarketingData[] | null;
    additionalPromotion: MarketingData[] | null;
}

export interface Notes {
    id: string;
    remark: string;
}
//CMR Header Section
export interface CmrHeader {
    photoUrl: string;
    state: string;
    streetAddress: string;
    city: string;
    listingDate: string;
    photoIsLandscape: boolean;
    salesRegionId: number;
    videoUrl: string;
    videoSelected: boolean;
    id: string;
    officeId: number;
}
//View on Web/Analyics
export interface Analytics {
    counts: AnalyticsCounts[] | null;
    graph: AnalyticsGraph[] | null;
}
export interface AnalyticsCounts extends SiteDetails {
    isShowcase: boolean;
    id?: string;
    logo?: string;
    listTrac: boolean;
}
export interface AnalyticsGraph {
    eventDate: string;
    details: SiteDetails[];
}
export interface SiteDetails {
    siteName: string;
    deepLink: string;
    totalViews: number;
}
//In the News
export interface TitleSummaryData {
    date: string;
    description: string;
    link: string;
    publicationName: string;
}

//Advertisement
export interface AdvertisementData extends MarketingFields {
    issueDate: string;
    publication: string;
    allowEditing: boolean;
    tearsheetFilename: string;
}

//Showing
export interface Showing {
    id?: string;
    listingId: number | string;
    listingNumber: number | string;
    showingDate: string;
    buyer: string;
    location: string;
    buyerAgents: string;
    notes: string;
    feedback: string;
    count: number;
}

//Open House
export interface OpenHouseData {
    id?: string;
    listingId?: number | string | null;
    listingNumber?: string | null;
    startDateTime: string;
    endDateTime: string;
    showingAgent: string | null;
    byAppointmentOnly: boolean;
    section?: string | null;
    notes: string | null;
    attendees: number | null;
    doNotShowTime: boolean;
    brokerOnly: boolean;
    isVirtual: boolean;
    virtualUrl: string | null;
}

//listing id
export interface CmrDetailsProps {
    listingId: string;
}

//Section Sorting
export interface SortingOrder {
    id: string;
    sectionId: number;
    displayName: string;
    sortOrder: number;
    displaySection: boolean;
    childSections: SortingOrder[];
    type?: string;
}

export interface User {
    userId: string;
}

export interface Passing {
    name: string;
    data:
        | Analytics
        | AdvertisementData[]
        | Showing[]
        | OpenHouseData[]
        | TitleSummaryData[]
        | EmailCampaignData[]
        | SocialCampaignData[]
        | Notes
        | MarketingData[];
}

export interface UpdatePanelItem {
    sectionId: number;
    data:
        | AdvertisementData
        | SocialCampaignData
        | MarketingFields
        | MarketingData
        | OpenHouseData
        | Showing
        | EmailCampaignData;
    editing: boolean;
}

export interface DeleteStructure {
    name: string;
    id: number | string;
    key: string;
}

export interface SectionProps {
    sectionId: number;
    expanded?: boolean;
}

export interface ReportToggleParams {
    id: string;
    flag?: boolean;
}

//common state value used on CMR
export interface PanelInfo {
    viewsOnWeb: SectionAndNot;
    inTheNews: SectionAndNot;
    advertisements: SectionAndNot;
    showings: SectionAndNot;
    openHouses: SectionAndNot;
    emailCampaigns: SectionAndNot;
    socialCampaigns: SectionAndNot;
    featuredPlacements: SectionAndNot;
    additionalPromotion: SectionAndNotWithNumberArrayOnly;
    notes: SectionAndNot;
    graph: SectionAndNot;
    containerText: {
        container: number[];
    };
}
export interface SectionAndNot {
    sectionId: number;
    dndRule: null | number | number[];
}
export interface SectionAndNotWithNumberArrayOnly {
    sectionId: number;
    dndRule: number[];
}

export class SpinnerState {
    constructor(public value: boolean, public panel: string = 'right') {}
}

export interface FeaturePlacementsItem {
    forceInsert: boolean;
    id: number;
    marketingId: number;
    emailsSent: number;
    views: number;
    clicks: number;
    audience: string;
    date: string;
    description?: string;
    isNew?: boolean;
    marketingTypeId?: number | string;
}

export interface ListingImage {
    id: number;
    caption: string;
    url: string;
    displayStatus: boolean;
    assetId: string;
    landscapeRatio: boolean;
}

export interface ListingImageSelected {
    selectedListingImage: string;
    isLandscape: boolean;
}

export interface BlockProps {
    block: SortingOrder;
    clickHandler(sectionId: number): void;
    currentOrderElement: number;
    className?: string;
    showContainer: boolean;
    blockIndex?: number[];
    setBlocks?: React.Dispatch<React.SetStateAction<SortingOrder[]>>;
    reorderUpdate?(): Promise<void>;
    componentMapping?: {
        [x: number]: React.FunctionComponent<SectionProps>;
    };
}

export interface ContainerProps {
    block: SortingOrder;
    blockIndex?: number[];
    setBlocks?: React.Dispatch<React.SetStateAction<SortingOrder[]>>;
    reorderUpdate?(): Promise<void>;
    componentMapping?: {
        [x: number]: React.FunctionComponent<SectionProps>;
    };
}
export interface WebsiteData extends MarketingFields {
    siteName: string;
    deepLink: string;
    totalViews: number;
    listingId: string;
}

export interface ImageVideoModel extends ListingImageSelected {
    listingNumber: string;
    imageId?: string;
    isVideoSelected: boolean;
}

export interface ImageVideoDataModel {
    listingNumber: string;
    isVideoSelected: boolean;
}

export interface UpdateSiteData {
    editing: boolean;
    data: AnalyticsCounts;
}

export interface GraphElement {
    name: string;
    totalView: number;
}
export interface GraphDate {
    date: string;
}

export interface OtherAddedWebsites {
    first: AnalyticsCounts[];
    second: AnalyticsCounts[];
}

export interface TotalViewCount {
    viewTotalCount: number;
    otherSites: AnalyticsCounts[];
    firstTwoRows: AnalyticsCounts[];
}

export interface WebsiteGraphData {
    color: string;
    data: number[][];
    dataGrouping: {
        enabled: boolean;
    };
    name: string;
    opacity: number;
    timeStamp: (string | number)[][];
}
export interface ChartOptions {
    chart: {
        type: string;
        zoomType: string;
        backgroundColor: string;
        width?: number | string | null;
        height?: number;
        marginRight: number;
        style: {
            fontFamily: string;
        };
    };
    xAxis: {
        type: string;
        title: {
            text: string | null;
        };
        zoomType: string;
        max: Date;
        events: {
            setExtremes(e: { min: number; max: number }): void;
        };
        startOnTick: boolean;
        endOnTick: boolean;
        tickmarkPlacement: string;
    };
    yAxis: {
        title: {
            text: string | null;
        };
        min: number;
        max: number;
    };

    plotOptions: {
        series: {
            animation: boolean;
            cursor: string;
            states: {
                hover: {
                    lineWidthPlus: number;
                };
            };
            showInLegend: boolean;
        };
    };
    title: {
        text: string | null;
    };
    series: WebsiteGraphData[];
    credits: {
        enabled: boolean;
    };
    tooltip: {
        headerFormat: string;
        pointFormat: string;
        borderWidth: number;
        borderRadius: number;
    };
    rangeSelector: {
        inputEnabled: boolean;
        verticalAlign: string;
        buttonPosition: {
            align: string;
        };
        enabled: boolean;
        allButtonsEnabled: boolean;
        buttons: [
            {
                type: string;
                text: string;
                dataGrouping: {
                    forced: boolean;
                    units: (string | number[])[][];
                };
                events: {
                    click: () => void;
                };
            },
            {
                type: string;
                count: number;
                text: string;
                dataGrouping: {
                    forced: boolean;
                    units: (string | number[])[][];
                };
                events: {
                    click: () => void;
                };
            },
            {
                type: string;
                count: number;
                text: string;
                dataGrouping: {
                    forced: boolean;
                    units: (string | number[])[][];
                };
                events: {
                    click: () => void;
                };
            },
        ];
        buttonTheme: {
            height: number;
            paddingRight: number;
            paddingLeft: number;
            width: number;
            states: {
                select: {
                    style: {
                        color: string;
                        fontSize: string;
                        fontFamily: string;
                    };
                };
            };
        };
        selected: number;
    };
}
export interface GraphData {
    first: WebsiteGraphData[];
    second: GraphElement;
}

export interface GraphDataLogic {
    sorted: AnalyticsGraph[];
    otherSortedSites: AnalyticsGraph[];
    maxDate: GraphDate;
    minDate: GraphDate;
    availableSiteData: WebsiteGraphData[];
    maxElement: GraphElement;
}
