import {
    deleteCall,
    getBrightCoveVideo,
    getCall,
    postCall,
} from '../../../../utils/api/apiWrapper';
import { VideoDownload } from '../../../models/video/videoModels';
import {
    BrightcoveIngest,
    SiraId,
    VideoInfo,
} from '../../../models/video/brightcove/brightcoveModels';

export function ingestVideo(injestUrl: BrightcoveIngest, id: string): Promise<Response> {
    return postCall(`brightcove/videos/${id}/ingestrequests`, injestUrl);
}
export function shareVideo(videoId: string, siraID: Array<SiraId>): Promise<Response> {
    return postCall(`brightcove/videos/${videoId}/shares`, siraID);
}
export function getVideoInfo(referenceId: string): Promise<VideoInfo[]> {
    return getCall<VideoInfo[]>(`brightcove/videos/${referenceId}/info`);
}
export function downloadVideoUrlsApi(videoId: string): Promise<VideoDownload> {
    return getCall<VideoDownload>(`brightcove/videos/${videoId}/download`);
}
export function downloadVideoApi(url: string): Promise<Response> {
    return getBrightCoveVideo(url);
}
export function deleteVideoApi(videoId: string, id: string): Promise<Response> {
    return deleteCall(`brightcove/videos/${videoId}/${id}`);
}
