import { FunctionComponent } from 'react';
import { Grid, TextField } from '@material-ui/core';
import {
    DrawerContainerProps,
    AgentDescription,
    ExpertiseSection,
    CustomChipData,
} from '../../../agentModels';
import { useFormData, useAppDispatch } from '../../../../../../app/hooks';
import { setCurrentAgentEditFormData } from '../../../agentSlice';
import { editFormComponent, agentDashboardMapping } from '../../../agentConstants';
import { getSelectedIds } from '../../../agentUtils';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';
import './index.scss';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import CustomChips from './chips/CustomChips';

const Expertise: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const descriptionDetails = editFormData.agentDetails
        .descriptionDetails as AgentDescription;

    const languages = editFormData.agentDetails.agentLanguages as LookupInfo[];
    const specializationData = editFormData.agentDetails
        .agentSpecializations as LookupInfo[];
    const designationData = editFormData.agentDetails.agentDesignations as LookupInfo[];

    const { specializations, designations, agentLanguages } = editFormData.lookups;

    const designationChipData: CustomChipData[] = designations.map((item) => {
        const selectedData = designationData.find(
            (desig) => desig.id.toString() === item.id,
        );
        return { ...item, selected: selectedData ? true : false };
    });

    const specializationChipData: CustomChipData[] = specializations.map((item) => {
        const selectedData = specializationData.find(
            (spec) => spec.id.toString() === item.id,
        );
        return { ...item, selected: selectedData ? true : false };
    });

    const languageChipData: CustomChipData[] = agentLanguages.map((item) => {
        const selectedData = languages.find((lang) => lang.id.toString() === item.id);
        return { ...item, selected: selectedData ? true : false };
    });

    const initialExpertiseData: ExpertiseSection = {
        profileId: descriptionDetails.profileId,
        areaOfExpertise: descriptionDetails.areaOfExpertise,
        designations: { ids: getSelectedIds(designationChipData) },
        specializations: { ids: getSelectedIds(specializationChipData) },
        languages: { ids: getSelectedIds(languageChipData) },
    };
    const { formData, setFormData, handleTextChange } = useFormData(initialExpertiseData);

    const setFormDataToPersist = (updatedFormData: ExpertiseSection) => {
        dispatch(
            setCurrentAgentEditFormData({
                formData: updatedFormData,
                agentEditFormName: editFormComponent.expertise,
            }),
        );
    };

    return (
        <div className="expertiseContainer">
            <Grid
                item
                container
                className="content noBorder subTitle"
                md={12}
                spacing={2}
            >
                <FormRow
                    label="OTHER SPECIALIZATIONS"
                    control={
                        <TextField
                            value={
                                formData.areaOfExpertise ? formData.areaOfExpertise : ''
                            }
                            onChange={(e) => handleTextChange(e, 140)}
                            multiline
                            rows={4}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'areaOfExpertise',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist(formData)}
                            name="areaOfExpertise"
                            inputProps={{ maxLength: 140 }}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid>
            <Grid item container spacing={2} md={12} className="content subTitle">
                <Grid item md={2}></Grid>
                <Grid item md={10} className="info-text">
                    Max chars: 140
                </Grid>
            </Grid>
            <Grid item container className="content" md={12} spacing={2}>
                <FormRow
                    label="LANGUAGES"
                    control={
                        <CustomChips
                            chipsData={languageChipData}
                            formData={formData}
                            setFormData={setFormData}
                            propertyName={agentDashboardMapping.languages.urlName}
                            setFormDataToPersist={setFormDataToPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass=""
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid>
            <Grid item container className="content noBorder" md={12} spacing={2}>
                <FormRow
                    label="DESIGNATIONS"
                    control={
                        <CustomChips
                            chipsData={designationChipData}
                            formData={formData}
                            setFormData={setFormData}
                            propertyName={agentDashboardMapping.designations.urlName}
                            setFormDataToPersist={setFormDataToPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass=""
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid>
            <Grid item container spacing={2} md={12} className="content subTitle">
                <Grid item md={2}></Grid>
                <Grid item md={10} className="info-text">
                    All designations will appear on SothebysHomes.com
                    <br /> A maximum of 3 designations (the first 3 checked above) will
                    appear on SothebysRealty.com. Those marked * will never appear on
                    SothebysRealty.com
                </Grid>
            </Grid>
            {/* <Grid item container className="content noBorder" md={12} spacing={2}>
                <FormRow
                    label="SPECIALIZATIONS"
                    control={
                        <CustomChips
                            chipsData={specializationChipData}
                            formData={formData}
                            setFormData={setFormData}
                            propertyName={agentDashboardMapping.specializations.urlName}
                            setFormDataToPersist={setFormDataToPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass=""
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={10}
                />
            </Grid> */}
        </div>
    );
};

export default Expertise;
