import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import './zeusHeader.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    navigationDetails,
    openCloseMobileMenu,
} from '../../slices/navigation/navigationSlice';
import { agentDashboardDetails } from '../../../features/agents/agentDashboard/agentSlice';
import { ImageData } from '../../models/images/sharedModel';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../auth/authService';
import { userRoles } from '../../constants/user/roles';
import { userIsInRole } from '../../auth/permissions/permissionsService';
import { parentPageConstants } from '../../constants/section/sectionConstants';

import GloablSearch from './globalSearch';
import { AgentDescription } from '../../../features/agents/agentDashboard/agentModels';
import { getData } from '../../../utils/storageService';

const ZeusHeader: FunctionComponent = () => {
    const { titleToLoad, toRedirect, forceBackButton } =
        useAppSelector(navigationDetails);
    const { agentDetails } = useAppSelector(agentDashboardDetails);
    const agentDetail = agentDetails.descriptionDetails as AgentDescription;
    const photo = agentDetails.agentPhoto as ImageData[];
    const history = useHistory();
    const userData = useMemo(() => getUser(), []);
    const dispatch = useAppDispatch();

    const parentPage = useCallback((): boolean => {
        if (forceBackButton) {
            return false;
        } else {
            return parentPageConstants.includes(history.location.pathname);
        }
    }, [forceBackButton, history.location.pathname]);

    const profileRedirect = () => {
        if (userIsInRole(userData, userRoles.agent.key)) {
            if (userData.agentIds?.length) {
                history.push(`/eng/agent/${userData.agentIds[0]}`);
            } else {
                history.push('/eng/agents');
            }
        } else {
            history.push(`/eng/user/${userData.userId}`);
        }
    };

    const openMobileMenu = () => {
        dispatch(openCloseMobileMenu(true));
    };

    const displayListingForPrint = () => {
        return getData('listingStatus') ? getData('listingStatus') === '11' : false;
    };

    return (
        <div
            className={
                'customHolder' + (displayListingForPrint() ? ' screen-display' : '')
            }
        >
            <div className="header">
                <div className="title">
                    <div className="hamburger">
                        <FontAwesomeIcon icon={faBars} onClick={openMobileMenu} />
                    </div>
                    {toRedirect.url?.length && !parentPage() ? (
                        <FontAwesomeIcon
                            onClick={() => history.goBack()}
                            className="goPrevious"
                            icon={faArrowLeft}
                        />
                    ) : (
                        ''
                    )}
                    {titleToLoad}
                </div>
                <div className="rightContent">
                    <GloablSearch />
                    <div className="profile" onClick={profileRedirect}>
                        {photo &&
                        photo.length > 0 &&
                        photo[0].urls.mediumUrl !== '' &&
                        agentDetail.userId === userData.userId ? (
                            <img src={photo[0].urls.mediumUrl} alt="Agent profile" />
                        ) : (
                            <img
                                src="/tempProfile/dummy-profile-pic.png"
                                alt="Agent profile"
                            />
                        )}
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ZeusHeader;
