import React, { useState, FunctionComponent, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './leftPanel.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { cmrDetails, updateCurrentElement, updateSortOrder } from '../../cmrSlice';
import { SortingOrder } from '../../cmrModels';
import { dynamicSort } from '../../../../utils/urlUtils';
import { reorderBlockSectionBasedOnIndex } from './logic';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import AdvertisementsComponent from '../rightPanel/advertisements';
import InTheNewsComponent from '../rightPanel/inTheNews';
import ShowingsComponent from '../rightPanel/showings';
import ViewsOnWebComponent from '../rightPanel/viewsOnWeb';
import CmrOpenHouseComponent from '../rightPanel/openHouse';
import NotesComponent from '../rightPanel/notes';
import AdditionalPromotionComponent from '../rightPanel/additionalPromotion';
import FeaturePlacmentComponent from '../rightPanel/featuredPlacement';
import SocialCampaignsComponent from '../rightPanel/socialCampaigns';
import EmailCampaignsComponent from '../rightPanel/emailCampaigns';
import { panelDetails } from '../../cmrConstant';
import { useWindowDimensions } from '../../../../shared/component/Window';
import Block from './Block';
import Container from './Container';
import { googleAnalyticsAction } from '../../../../shared/functions/googleAnalytics/ga';

// componentMapping key is created based on sectionId ,
// make sectionId of component
const componentMapping = {
    [panelDetails.viewsOnWeb.id]: ViewsOnWebComponent, // View on Map
    [panelDetails.inTheNews.id]: InTheNewsComponent, // In the news
    [panelDetails.advertisements.id]: AdvertisementsComponent, // Advertisements
    [panelDetails.showings.id]: ShowingsComponent, // Showings
    [panelDetails.openHouses.id]: CmrOpenHouseComponent, // Open Houses
    [panelDetails.emailCampaigns.id]: EmailCampaignsComponent, // Email Campaigns
    [panelDetails.socialCampaigns.id]: SocialCampaignsComponent, // Social Campaigns
    [panelDetails.featuredPlacements.id]: FeaturePlacmentComponent, // Featured Placements
    [panelDetails.additionalPromotion.id]: AdditionalPromotionComponent, // Additional Promotion
    [panelDetails.notes.id]: NotesComponent, // Notes
};

export const sortableOptions = {
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    ghostClass: 'ghost',
    group: 'shared',
    handle: '.drag',
};

export const clickHandler = (sectionId: number): void => {
    setTimeout(() => {
        googleAnalyticsAction('Drag And Drop', 'Click', 'Drag And Drop Click');
        const elem = document.getElementById('scrollId' + sectionId);
        if (elem !== null) {
            elem.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, 500);
};

/*
 adding type to the state object 
 * - container only for section id 4,5,9
 * - text for rest  
*/
const LeftPanel: FunctionComponent = () => {
    const { sortOrder, graph, panelInfo } = useAppSelector(cmrDetails);
    const [blocks, setBlocks] = useState<SortingOrder[]>([]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const blockData = sortOrder.map((sortData) => {
            return {
                ...sortData,
                type: panelInfo.containerText.container.includes(sortData.sectionId)
                    ? 'container'
                    : 'text',
            };
        });
        setBlocks(blockData);
    }, [sortOrder, panelInfo.containerText.container]);

    const blocksUpdatePost = (blocksValue: SortingOrder[]) => {
        const removedData: SortingOrder[] = [];
        const dataSet = blocksValue.map((data: SortingOrder) => {
            /*
                        *Three object
                        ** 1 -- for showing to open house
                        ** 2 -- for open house to showing
                        ** 3 -- for additional promotion to load all except showing, open house and views on web
                    for findig whether the particular section should not be available if there need to remove from child section and push to the end and do the sorting
                    */
            [
                panelInfo.showings,
                panelInfo.openHouses,
                panelInfo.additionalPromotion,
            ].forEach((value) => {
                if (
                    data.sectionId === value.sectionId &&
                    data.childSections &&
                    data.childSections.length > 0
                ) {
                    const toRemove = data.childSections.filter((section: SortingOrder) =>
                        value.sectionId === panelInfo.additionalPromotion.sectionId
                            ? panelInfo.additionalPromotion.dndRule.includes(
                                  section.sectionId,
                              )
                            : section.sectionId !== value.dndRule,
                    );
                    const diff = data.childSections.filter(
                        (findDiffSection: SortingOrder) =>
                            !toRemove.includes(findDiffSection),
                    );
                    data.childSections = [];
                    if (diff.length > 0)
                        data.childSections = reorderBlockSectionBasedOnIndex(
                            data.childSections.concat(diff),
                        );
                    if (toRemove.length > 0) {
                        removedData.push(...toRemove);
                    }
                }
            });
            if (!data.childSections) {
                data.childSections = [];
            }
            return data;
        });

        //Iterating two value - old value and removed value
        const newDataSet = [...dataSet, ...removedData];
        if (removedData.length > 0) {
            return [...newDataSet.sort(dynamicSort('sortOrder'))];
        } else {
            return [...reorderBlockSectionBasedOnIndex(newDataSet)];
        }
    };

    const saveInDB = () => {
        googleAnalyticsAction('Drag And Drop', 'Drag And Drop', 'Drag And Drop');
        const updatedData = blocksUpdatePost(blocks);
        if (updatedData) {
            const saveOrder = [...updatedData, ...graph];
            dispatch(updateSortOrder(saveOrder));
        }
    };

    const reOrderUpdated = async () => {
        googleAnalyticsAction('Drag And Drop', 'Stack', 'Drag And Drop Stacking');
        saveInDB();
    };
    return (
        <div className="dragHolder">
            <ReactSortable
                list={blocks.map((x) => ({ ...x, chosen: true }))}
                setList={setBlocks}
                {...sortableOptions}
                onEnd={saveInDB}
                className="sort"
            >
                {blocks.map((block, blockIndex) => (
                    <BlockWrapper
                        key={block.id}
                        block={block}
                        blockIndex={[blockIndex]}
                        setBlocks={setBlocks}
                        reorderUpdate={reOrderUpdated}
                    />
                ))}
            </ReactSortable>
        </div>
    );
};

function BlockWrapper({
    block,
    blockIndex,
    setBlocks,
    reorderUpdate,
}: {
    block: SortingOrder;
    blockIndex: number[];
    setBlocks: React.Dispatch<React.SetStateAction<SortingOrder[]>>;
    reorderUpdate(): Promise<void>;
}) {
    const { currentOrderElement } = useAppSelector(cmrDetails);
    const dispatch = useAppDispatch();
    const { width } = useWindowDimensions();

    const [sectionID, setSectionID] = useState(-1);
    const toggleIcon = (id: number) => {
        setSectionID(sectionID === id ? -1 : id);
    };
    useEffect(() => {
        if (width > 1023) {
            setSectionID(-1);
        }
    }, [width]);
    if (!block) return null;
    if (block.type === 'container') {
        const ComponentToLoad = componentMapping[block.sectionId];
        return (
            <>
                {width > 1023 ? (
                    <Block
                        className="block"
                        block={block}
                        clickHandler={(sectionId: number) => {
                            dispatch(updateCurrentElement(sectionId));
                            clickHandler(sectionId);
                        }}
                        currentOrderElement={currentOrderElement}
                        showContainer={true}
                        blockIndex={blockIndex}
                        setBlocks={setBlocks}
                        reorderUpdate={reorderUpdate}
                        componentMapping={componentMapping}
                    />
                ) : (
                    <div className="block" data-sectionid={block.sectionId}>
                        <Accordion
                            onClick={() => {
                                dispatch(updateCurrentElement(block.sectionId));
                                clickHandler(block.sectionId);
                            }}
                            onChange={() => {
                                toggleIcon(block.sectionId);
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        className="expand"
                                        icon={
                                            sectionID === block.sectionId
                                                ? faMinus
                                                : faPlus
                                        }
                                    />
                                }
                                aria-controls="panel1a-content"
                                className="blocksummary"
                            >
                                <Block
                                    block={block}
                                    clickHandler={(sectionId: number) => {
                                        dispatch(updateCurrentElement(sectionId));
                                        clickHandler(sectionId);
                                        toggleIcon(sectionId);
                                    }}
                                    currentOrderElement={currentOrderElement}
                                    showContainer={false}
                                />
                            </AccordionSummary>
                            <AccordionDetails className="blockDetails">
                                <div className="leftHolderContent">
                                    {
                                        <ComponentToLoad
                                            expanded={
                                                sectionID === block.sectionId
                                                    ? true
                                                    : false
                                            }
                                            sectionId={block.sectionId}
                                        />
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Container
                            block={block}
                            setBlocks={setBlocks}
                            blockIndex={blockIndex}
                            reorderUpdate={reorderUpdate}
                            componentMapping={componentMapping}
                        />
                    </div>
                )}
            </>
        );
    } else {
        const ComponentToLoad = componentMapping[block.sectionId];
        return (
            <>
                {width > 1023 ? (
                    <Block
                        className="block"
                        block={block}
                        clickHandler={(sectionId: number) => {
                            dispatch(updateCurrentElement(sectionId));
                            clickHandler(sectionId);
                        }}
                        currentOrderElement={currentOrderElement}
                        showContainer={false}
                    />
                ) : (
                    <Accordion
                        className="block"
                        onClick={() => {
                            dispatch(updateCurrentElement(block.sectionId));
                            clickHandler(block.sectionId);
                        }}
                        onChange={() => {
                            toggleIcon(block.sectionId);
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    className="expand"
                                    icon={
                                        sectionID === block.sectionId ? faMinus : faPlus
                                    }
                                />
                            }
                            aria-controls="panel1a-content"
                            className="blocksummary"
                        >
                            <Block
                                className="block"
                                block={block}
                                clickHandler={(sectionId: number) => {
                                    dispatch(updateCurrentElement(sectionId));
                                    clickHandler(sectionId);
                                    toggleIcon(sectionId);
                                }}
                                currentOrderElement={currentOrderElement}
                                showContainer={false}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="leftHolderContent">
                                {
                                    <ComponentToLoad
                                        expanded={
                                            sectionID === block.sectionId ? true : false
                                        }
                                        sectionId={block.sectionId}
                                    />
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
            </>
        );
    }
}

export default LeftPanel;
