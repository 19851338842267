import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DomainModel,
    PurchaseRequest,
    ReleaseRequest,
    ShowCaseModel,
    PrivateSiteUser,
    UpdateShowcaseData,
    RenewDomainRequest,
    RedeemDomainRequest,
} from './showcaseWebsiteModels';
import {
    checkEligibility,
    createShowcaseSite,
    deleteShowcaseSite,
    getDomainDetails,
    getShowcaseSiteDetails,
    getUsersDetails,
    postUserDetails,
    putUserDetails,
    redeemDomain,
    registerDomain,
    releaseDomain,
    renewDomain,
    updateShowcaseSiteDetails,
} from './showcaseWebsiteApi';
import { userData } from './showcaseWebsiteConstants';
import loDash from 'lodash';
import { AppThunk, RootState } from '../../../../../../app/store';
import Logger from '../../../../../../utils/logging/logger';
import {
    setError,
    setSuccess,
    setWarning,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import {
    setCurrentFormData,
    setInitialFormState,
    updateMainListingData,
} from '../../../listingSlice';
import { handleCustomError } from '../../../../../../utils/api/customError';
import { jsonToFormData } from '../../../../../../utils/urlUtils';
import { addMarketing } from '../../../../../../shared/api/marketing/marketingApis';
import moment from 'moment';
import { editFormComponent } from '../../../listingConstants';

export interface ShowcaseWebsiteState {
    domainData: DomainModel;
    showCaseData: ShowCaseModel;
    usersData: PrivateSiteUser[];
    eligibilityStatus: boolean;
    purchaseStatus: boolean;
    renewStatus: boolean;
    redeemStatus: boolean;
    releaseStatus: boolean;
}

export const initialDomainState: DomainModel = {
    orderId: 0,
    domainUrl: '',
    entityId: '',
    created: '',
    expirationDate: '',
    createdBy: '',
    userId: '',
    siteType: 0,
    status: 0,
    goDaddyStatus: '',
    renewDeadline: '',
    userEmail: '',
    officeId: '',
    canceledDate: '',
    id: '',
    isRenewable: false,
    isRedeemable: false,
};
export const initialShowcaseState: ShowCaseModel = {
    id: '',
    domainId: '',
    domainUrl: '',
    listingId: '',
    showPrice: false,
    headline: '',
    subheadline: '',
    showcaseVideoUrl: '',
    displayOptions: 1,
    publish: false,
    googleAnalyticsTrackingId: '',
    hotjarTrackingId: '',
    googleTagManager: '',
    privateSite: false,
    privateListingAccessEmailOption: 1,
    autoGenerateCode: false,
    alreadyOwned: false,
    customTabs: [],
};

const initialState: ShowcaseWebsiteState = {
    domainData: initialDomainState,
    showCaseData: initialShowcaseState,
    usersData: userData,
    eligibilityStatus: false,
    purchaseStatus: false,
    renewStatus: false,
    redeemStatus: false,
    releaseStatus: false,
};

export const showCaseSlice = createSlice({
    name: 'showCase',
    initialState: initialState,
    reducers: {
        updateShowcaseWebsiteData: (state, action: PayloadAction<UpdateShowcaseData>) => {
            return {
                ...state,
                [action.payload.property]: action.payload.value,
            };
        },
        addUserData: (state, action: PayloadAction<PrivateSiteUser>) => {
            state.usersData = [...state.usersData, action.payload];
        },
        updateUserData: (state, action: PayloadAction<PrivateSiteUser>) => {
            state.usersData = state.usersData.map((user: PrivateSiteUser) =>
                user.id === action.payload.id ? { ...user, ...action.payload } : user,
            );
        },
        resetShowcaseWebsiteState: (state) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const getData =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            let showcaseSiteResponse = await getShowcaseSiteDetails(listingId);
            if (showcaseSiteResponse?.id) {
                showcaseSiteResponse = {
                    ...showcaseSiteResponse,
                    customTabs: loDash.orderBy(showcaseSiteResponse.customTabs || [], [
                        'orderNo',
                    ]),
                };
                dispatch(
                    updateShowcaseWebsiteData({
                        property: 'showCaseData',
                        value: showcaseSiteResponse,
                    }),
                );
                const domainResponse = await getDomainDetails(
                    showcaseSiteResponse.domainId,
                );
                if (domainResponse.id !== '') {
                    dispatch(
                        updateShowcaseWebsiteData({
                            property: 'domainData',
                            value: domainResponse,
                        }),
                    );
                }
                dispatch(setInitialFormState({ initialData: showcaseSiteResponse }));
            }
        } catch (e) {
            const status = handleCustomError(e);
            if (status === 404) {
                Logger.error('Domain / showcase site is not available for this listing');
                dispatch(
                    setWarning(
                        'Domain / showcase site is not available for this listing',
                    ),
                );
            } else {
                Logger.error('Error in getting the showcase site details', e);
                dispatch(setError('Error in getting the showcase site details'));
            }
        }
    };

export const checkEligible =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await checkEligibility(listingId);
            if (response.status === 200) {
                dispatch(
                    updateShowcaseWebsiteData({
                        property: 'eligibilityStatus',
                        value: true,
                    }),
                );
            }
        } catch (e) {
            const status = handleCustomError(e);
            if (status === 403) {
                dispatch(
                    updateShowcaseWebsiteData({
                        property: 'eligibilityStatus',
                        value: false,
                    }),
                );
                dispatch(setWarning('Listing is not eligible for showcase site'));
            } else {
                Logger.error('Error in checking the listing eligibility', e);
                dispatch(setError('Error in checking the listing eligibility'));
            }
        }
    };

export const purchase =
    (purchaseData: PurchaseRequest, showcaseSiteData: ShowCaseModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'purchaseStatus',
                    value: true,
                }),
            );
            const response = await registerDomain(purchaseData);
            if (response.successful) {
                const updatedShowcaseSiteData = {
                    ...showcaseSiteData,
                    domainId: response.domainId,
                    domainUrl: purchaseData.domain,
                    listingId: purchaseData.entityId,
                };
                const { id, ...updatedShowcaseSite } = updatedShowcaseSiteData;
                await createShowcaseSite(updatedShowcaseSite);
                const marketingRequestData = jsonToFormData({
                    additionalPromotionId: 1,
                    date: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
                    description: 'Showcase website created',
                    file: undefined,
                    id: '',
                    isFile: false,
                    marketingTypeId: 16,
                    type: 'Showcase Website',
                    url:
                        purchaseData.domain.search(/^http[s]?:\/\//) === -1
                            ? `http://${purchaseData.domain}`
                            : purchaseData.domain,
                });
                await addMarketing(
                    'marketing',
                    purchaseData.entityId,
                    marketingRequestData,
                );
                const domainMain = {
                    domainInfo: {
                        domainUrl: purchaseData.domain,
                        privateSite: false,
                        publishedSite: false,
                    },
                };
                dispatch(updateMainListingData(domainMain));
                dispatch(setSuccess('Domain is purchased successfully'));
            } else if (response.code === '500') {
                dispatch(setError(response.message));
            } else {
                Logger.error('Purchase domain response', response);
                dispatch(setError('Domain is not purchased successfully'));
            }
        } catch (e) {
            Logger.error('Error in creating a showcase site', e);
            dispatch(setError('Error in creating a showcase site'));
        } finally {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'purchaseStatus',
                    value: false,
                }),
            );
        }
    };

export const updateShowCase =
    (showCaseData: ShowCaseModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await updateShowcaseSiteDetails(showCaseData);
            if (response.status === 204) {
                dispatch(
                    updateShowcaseWebsiteData({
                        property: 'showCaseData',
                        value: showCaseData,
                    }),
                );
                dispatch(setSuccess('Showcase site details are updated successfully'));
                dispatch(setInitialFormState({ initialData: showCaseData }));
            }
        } catch (e) {
            Logger.error('Error in updating the showcase site details', e);
            dispatch(setError('Error in updating the showcase site details '));
        }
    };

export const renew =
    (request: RenewDomainRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'renewStatus',
                    value: true,
                }),
            );
            const response = await renewDomain(request);
            if (response.status === 200) {
                dispatch(setSuccess('Domain is renewed'));
            }
        } catch (e) {
            dispatch(setError('Error in renewing the domain'));
            Logger.error('Error in renewing the domain');
        } finally {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'renewStatus',
                    value: false,
                }),
            );
        }
    };
export const redeem =
    (request: RedeemDomainRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'redeemStatus',
                    value: true,
                }),
            );
            const response = await redeemDomain(request);
            if (response.status === 200) {
                dispatch(setSuccess('Domain is redeemed successfully'));
            }
        } catch (e) {
            dispatch(setError('Error in redeeming the domain'));
            Logger.error('Error in redeeming the domain');
        } finally {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'redeemStatus',
                    value: false,
                }),
            );
        }
    };

export const release =
    (
        release: ReleaseRequest,
        privateStatus: boolean,
        publishStatus: boolean,
        listingId: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'releaseStatus',
                    value: true,
                }),
            );
            const response = await releaseDomain(release);
            if (response.status === 204) {
                const domainData = {
                    domainInfo: {
                        domainUrl: '',
                        privateSite: privateStatus,
                        publishedSite: publishStatus,
                    },
                };
                dispatch(updateMainListingData(domainData));
                dispatch(setSuccess('Domain is released successfully'));
                const deleteResponse = await deleteShowcaseSite(listingId);
                if (deleteResponse.status === 204) {
                    dispatch(
                        updateShowcaseWebsiteData({
                            property: 'showCaseData',
                            value: initialShowcaseState,
                        }),
                    );
                    dispatch(setInitialFormState({ initialData: initialShowcaseState }));
                    dispatch(
                        setCurrentFormData({
                            formData: initialShowcaseState,
                            listingEditFormName: editFormComponent.showCaseWebsite,
                        }),
                    );
                    dispatch(
                        updateShowcaseWebsiteData({
                            property: 'domainData',
                            value: initialDomainState,
                        }),
                    );
                }
            }
        } catch (e) {
            Logger.error('Error in releasing the domain / deleting the showcase site', e);
            dispatch(
                setError('Error in releasing the domain / deleting the showcase site'),
            );
        } finally {
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'releaseStatus',
                    value: false,
                }),
            );
        }
    };

export const getUsers =
    (showcaseWebsiteId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getUsersDetails(showcaseWebsiteId);
            if (response) {
                const users = response.map((user: PrivateSiteUser) => {
                    return { ...user, customDate: !!user.endDate };
                });
                dispatch(
                    updateShowcaseWebsiteData({
                        property: 'usersData',
                        value: users,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Error in getting showcase site users', e);
            dispatch(setError('Error in getting showcase site users'));
        }
    };

export const postUser =
    (userData: PrivateSiteUser): AppThunk =>
    async (dispatch) => {
        try {
            const { id, ...user } = userData;
            const response = await postUserDetails(user);
            if (response) {
                dispatch(
                    addUserData({
                        ...response,
                        customDate:
                            response.startDate != null && response.endDate != null,
                    }),
                );
                dispatch(setSuccess('Showcase site user is saved successfully'));
            }
        } catch (e) {
            Logger.error('Error in saving the showcase site user', e);
            dispatch(setError('Error in saving the showcase site user'));
        }
    };

export const putUser =
    (userData: PrivateSiteUser, isDisabled?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const response = await putUserDetails(userData);
            if (response.status === 204) {
                dispatch(
                    updateUserData({
                        ...userData,
                        customDate:
                            userData.startDate != null && userData.endDate != null,
                    }),
                );
                dispatch(
                    setSuccess(
                        `Showcase site user is ${
                            isDisabled ? 'disabled ' : 'updated '
                        }successfully`,
                    ),
                );
            }
        } catch (e) {
            Logger.error('Error in updating the showcase site user', e);
            dispatch(setError('Error in updating the showcase site user'));
        }
    };

export const {
    updateShowcaseWebsiteData,
    resetShowcaseWebsiteState,
    addUserData,
    updateUserData,
} = showCaseSlice.actions;
export const listingShowCase = (state: RootState): ShowcaseWebsiteState =>
    state.listing.showcaseWebsite;

export default showCaseSlice.reducer;
