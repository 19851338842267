import { AgentDNDItem } from '../../../../models/agent/agentModel';
import update from 'immutability-helper';

export const reorderItems = (
    startIndex: number,
    endIndex: number,
    agents: AgentDNDItem[],
): AgentDNDItem[] => {
    const stateValue = [...agents];
    const dragItem = stateValue[startIndex];
    const agentsList = update(stateValue, {
        $splice: [
            [startIndex, 1],
            [endIndex, 0, dragItem],
        ],
    });
    const orderedAgents = updateDisplayOrder(agentsList);
    return [...orderedAgents];
};

const updateDisplayOrder = (agents: AgentDNDItem[]): AgentDNDItem[] => {
    const clone = JSON.parse(JSON.stringify(agents));
    const ordered = clone.map((a: AgentDNDItem, i: number) => {
        a.displayOrder = i + 1;
        return a;
    });
    return ordered;
};
