import { FunctionComponent } from 'react';
import Header from '../../../shared/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faInfo } from '@fortawesome/free-solid-svg-icons';
import { dateFormatMMDDYYY, sortByDate } from '../../../../../utils/urlUtils';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, displayReportToggle } from '../../../cmrSlice';
import { SectionProps, TitleSummaryData } from '../../../cmrModels';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import './index.scss';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';

const InTheNews: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder } = useAppSelector(cmrDetails);
    const sortedNews = sortByDate(paneldata.inTheNews as TitleSummaryData[], 'date');
    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);

    const ga = () => {
        googleAnalyticsAction(
            'view detail',
            'Click',
            'In the News Continue Reading - Builder',
        );
    };
    return (
        <div className="news-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'In the News Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={
                    hideDisplayOnReport(9, sortOrder, props.sectionId) ? true : false
                }
            />
            <div className="inner-container">
                <div className="newsNotes">
                    <FontAwesomeIcon className="info" icon={faInfo} /> To change or edit
                    the content below, please contact your local marketing department.
                </div>
                <div className="data-container">
                    {sortedNews && sortedNews.length > 0
                        ? sortedNews.map((news: TitleSummaryData, index: number) => (
                              <div key={index}>
                                  <div key={news.description + index} className="news">
                                      {news.date &&
                                      dateFormatMMDDYYY(news.date.toString()) ? (
                                          <div className="date">
                                              {dateFormatMMDDYYY(news.date.toString())}
                                          </div>
                                      ) : (
                                          ''
                                      )}
                                      {news.publicationName &&
                                      news.publicationName.trim().length > 0 ? (
                                          <div className="publication publicationName">
                                              {news.publicationName}
                                          </div>
                                      ) : (
                                          ''
                                      )}
                                      {news.description &&
                                      news.description.trim().length > 0 ? (
                                          <div className="description">
                                              {news.description}
                                          </div>
                                      ) : (
                                          ''
                                      )}
                                      {news.link && news.link.trim().length > 0 ? (
                                          <a
                                              href={news.link}
                                              target="_blank"
                                              rel="noreferrer"
                                          >
                                              <div className="link" onClick={ga}>
                                                  Continue Reading
                                                  <span className="arrow">
                                                      <FontAwesomeIcon
                                                          icon={faLongArrowAltRight}
                                                          size="2x"
                                                      />
                                                  </span>
                                              </div>
                                          </a>
                                      ) : (
                                          ''
                                      )}
                                  </div>
                                  <hr className="border" />
                              </div>
                          ))
                        : ''}
                </div>
            </div>
        </div>
    );
};
export default InTheNews;
