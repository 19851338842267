import { DataFlowResponse } from '../../../../../../utils/api/apiModels';
import {
    getCall,
    packageAuthHeader,
    packageGet,
    packagePost,
    postCall,
    postCallRaw,
    postFormData,
    putCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import {
    DeleteData,
    TotalImageCount,
    ImageSaveResult,
    ShowcaseImageSaveRequest,
    ShowcaseImageSaveResponse,
    UpdateShowcaseImageRequest,
} from './photoEditorModels';
import {
    TagSave,
    CaptionDetails,
    DownloadPackage,
    PackageProps,
    ShareDetails,
    PhotoCredit,
    Filter,
    ImageTypes,
    dndSave,
} from '../../../../../../shared/models/images/sharedModel';

export function saveImages(request: FormData): Promise<ImageSaveResult> {
    return postFormData<ImageSaveResult>(`image/save`, request);
}
export function getPhotoCredits(id: string): Promise<PhotoCredit> {
    return getCall<PhotoCredit>(`listing/${id}/photocredit`);
}

export function deleteImages(request: DeleteData): Promise<DataFlowResponse> {
    return postCall<DeleteData, DataFlowResponse>(`image/delete`, request);
}

export function savePhotoCredits(id: string, request: PhotoCredit): Promise<Response> {
    return putCallRaw<PhotoCredit>(`listing/${id}/photocredit`, request);
}

export function toggleSuppressPhotoImport(id: string): Promise<Response> {
    return putCallRaw(`listing/${id}/togglesuppressphotoimport`, null);
}

export function saveImagesPackage(
    url: string,
    request: PackageProps | DownloadPackage,
): Promise<string> {
    return packagePost<PackageProps | DownloadPackage>(url, request).then((res) =>
        res.text(),
    );
}

export function saveReorderImage(listingId: string, data: dndSave[]): Promise<Response> {
    return putCallRaw(`image/${listingId}/reorder`, data);
}

export function getTotalImageCount(
    entityId: string,
    entityType: string,
): Promise<TotalImageCount[]> {
    return getCall<TotalImageCount[]>(`image/${entityId}/count?entityType=${entityType}`);
}

export function isPackage(url: string): Promise<Response> {
    return packageGet(url);
}

export function shareImages(request: ShareDetails): Promise<Response> {
    return postCallRaw('share/Send', request);
}

export function saveCaptionDetails(
    id: string,
    request: CaptionDetails,
): Promise<Response> {
    return putCallRaw(`image/${id}/caption`, request);
}

export function getImageTypes(
    entityId: string,
    entityType: string,
): Promise<ImageTypes[]> {
    return getCall<ImageTypes[]>(`image/${entityId}/${entityType}/types`);
}

export function getFilter(): Promise<Filter[]> {
    // EntityTypeId = 1 for listing
    return getCall<Filter[]>(`image/1/tags`);
}

export function getTags(entityId: string, imageId: string): Promise<Filter[]> {
    // EntityTypeId = 1 for listing
    return getCall<Filter[]>(`image/1/${entityId}/${imageId}/tags`);
}

export function saveTags(
    entityId: string,
    imageId: string,
    request: TagSave,
): Promise<Response> {
    // EntityTypeId = 1 for listing
    return putCallRaw(`image/1/${entityId}/${imageId}/tags`, request);
}

export function saveMovedImages(
    showcaseImage: ShowcaseImageSaveRequest,
): Promise<ShowcaseImageSaveResponse> {
    return fetch(`${process.env.REACT_APP_PHOTOURL}/api/image/crop`, {
        method: 'POST',
        headers: {
            ...packageAuthHeader,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(showcaseImage || {}),
    }).then((response) => {
        return response.json();
    });
}

export function updateShowcaseImage(
    updateShowcase: UpdateShowcaseImageRequest,
    entityId: string,
    entityType: string,
    imageType: string,
): Promise<Response> {
    return putCallRaw(
        `featuredImage/${entityId}/${entityType}/${imageType}/featured`,
        updateShowcase,
    );
}

export function updateValidate(id: string): Promise<Response> {
    return putCallRaw(`listing/${id}/update?validate=true`, { validate: true });
}

export function getPhotographerByListing(id: string): Promise<string[]> {
    return getCall<string[]>(`image/${id}/photographers`);
}
