import { combineReducers } from '@reduxjs/toolkit';
import OfficeList from '../../features/offices/officeListSlice';
import OfficeDetail from '../../features/offices/office/officeSlice';
import OfficeSettings from '../../features/offices/office/drawer/screens/settings/officeSettingsSlice';
import { OfficesState } from '../../features/offices/officeListModels';
import { OfficeState } from '../../features/offices/office/officeModels';
import { OfficeSettingsState } from '../../features/offices/office/drawer/screens/settings/officeSettingsModels';

interface OfficeRootState {
    list: OfficesState;
    details: OfficeState;
    settings: OfficeSettingsState;
}
export default combineReducers<OfficeRootState>({
    list: OfficeList,
    details: OfficeDetail,
    settings: OfficeSettings,
});
