import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import Logger from '../../../utils/logging/logger';
import { setError } from '../messaging/messagingSlice';
import { NavigationState, redirectDate } from '../../models/navigation/navigationModel';

const initialNaviationState: NavigationState = {
    titleToLoad: 'Home',
    toRedirect: { url: '', title: '' },
    showMobileMenu: false,
    //to enabled when we need back button on parent page
    forceBackButton: false,
};

export const navigationSlice = createSlice({
    name: 'navigationSlice',
    initialState: initialNaviationState,
    reducers: {
        setTitleToLoad: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                titleToLoad: action.payload,
            };
        },
        setBackToUrl: (state, action: PayloadAction<redirectDate>) => {
            const toRedirectData = {
                url: action.payload.url,
                title: action.payload.title,
            };
            return {
                ...state,
                toRedirect: toRedirectData,
                forceBackButton: action.payload.forceBackButton
                    ? action.payload.forceBackButton
                    : false,
            };
        },
        openCloseMobileMenu: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                showMobileMenu: action.payload,
            };
        },
    },
});

/*
For Fetching the loaded page title
*/
export const setTitle =
    (data: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setTitleToLoad(data));
        } catch (e) {
            Logger.error(`Error setting Page Title: ${JSON.stringify(e)}`);
            dispatch(setError(`Error setting Page Title`));
        }
    };

/*
For Fetching the loaded page title
*/
export const setBackTo =
    (data: redirectDate): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setBackToUrl(data));
        } catch (e) {
            Logger.error(`Error setting Previous Page: ${JSON.stringify(e)}`);
            dispatch(setError(`Error setting  Previous Page`));
        }
    };

export const { setTitleToLoad, setBackToUrl, openCloseMobileMenu } =
    navigationSlice.actions;
export const navigationDetails = (state: RootState): NavigationState => state.navigation;
export default navigationSlice.reducer;
