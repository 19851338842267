import {
    deleteCall,
    getCall,
    postCallRaw,
    postFormData,
    putCallRaw,
} from '../../../utils/api/apiWrapper';
import { ImageDeleteModel } from '../mediaOutlets/component/createAndEdit/createEditMediaOutletModel';
import {
    AdvertisementCopyModel,
    CreateAdvertisementResponseModel,
} from './advertisementModels';

export function getAdvertisement(id: string): Promise<CreateAdvertisementResponseModel> {
    return getCall<CreateAdvertisementResponseModel>(`advertisement/${id}`);
}

export function createAdvertisementApi(
    data: FormData,
): Promise<CreateAdvertisementResponseModel> {
    return postFormData<CreateAdvertisementResponseModel>('advertisement', data);
}

export function deleteAdvertisementImageApi(data: ImageDeleteModel): Promise<Response> {
    return putCallRaw(`advertisement/file`, data);
}

export function deleteAdvertisementApi(id: string): Promise<Response> {
    return deleteCall(`advertisement/${id}`);
}

export function getEditionApi(id: string): Promise<string[]> {
    return getCall<string[]>(`advertisement/edition?mediaOutletId=${id}`);
}

export function copyAdvertismentApi(copyData: AdvertisementCopyModel): Promise<Response> {
    return putCallRaw(`advertisement/copy`, copyData);
}

export function exportAdvertisementApi(advId: string, from: string): Promise<Response> {
    let url = `advertisement/${advId}/advertisementExport`;
    if (from === 'citReport') {
        url = `advertisement/${advId}/citExport`;
    }
    return postCallRaw<string>(url, advId);
}
