import { getUser } from '../../auth/authService';
import { userIsInAnyRole } from '../../auth/permissions/permissionsService';
import { userRoles } from '../../constants/user/roles';
import { PaginationRequest } from '../../models/pagination/paginationModels';

//if the logged in user is manager, do not send agent id and agent role
export function isManager(listingsRequest: PaginationRequest): PaginationRequest {
    if (userIsInAnyRole(getUser(), [userRoles.branchManager.key])) {
        listingsRequest.agentIds = [];
        if (listingsRequest.roleIds && listingsRequest.roleIds.length > 0) {
            listingsRequest.roleIds = listingsRequest.roleIds.filter(
                (data) => data !== userRoles.agent.key.toLocaleLowerCase(),
            );
        }
        return listingsRequest;
    } else {
        return listingsRequest;
    }
}
