import { ListingsTypeData } from '../../models/listingCommission/listingCommissionModels';

export const initialSearchData = {
    address1: '',
    officeName: '',
    agentName: '',
    emptyBuyerOrListingCommission: false,
    isBuyerCommissionNotOffered: false,
};

export const initialListingCommissionResponse = (
    businessPurposeTypeId: string,
): ListingsTypeData => {
    return {
        tableAction: {
            currentPage: 1,
            itemsPerPage: 100,
            sortColumn: 'listingDate',
            sortDirection: 'desc',
            status: ['Active', 'ComingSoon', 'Draft'],
            businessPurposeTypeId: businessPurposeTypeId,
            address1: '',
            officeName: '',
            agentName: '',
            emptyBuyerOrListingCommission: false,
            isBuyerCommissionNotOffered: false,
        },
        results: [],
        totalRecords: 0,
    };
};
