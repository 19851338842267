import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import { jsonToFormData } from '../../../utils/urlUtils';
import { IndividualUpdateModel } from '../advertisementListModels';
import { ImageDeleteModel } from '../mediaOutlets/component/createAndEdit/createEditMediaOutletModel';
import {
    copyAdvertismentApi,
    createAdvertisementApi,
    deleteAdvertisementImageApi,
    exportAdvertisementApi,
    getAdvertisement,
    getEditionApi,
} from './advertisementApi';
import { returnFile } from '../../../utils/api/fileResults';

import {
    AdvertisementState,
    CreateAdvertisementModel,
    CreateAdvertisementResponseModel,
    AdvertisementFileType,
    AdvertisementCopyModel,
} from './advertisementModels';
import lodash from 'lodash';
import {
    getAdvertisementList,
    updateIndividualAdvertisementFromList,
} from '../advertisementListSlice';

const advertisementState: AdvertisementState = {
    isLoading: false,
    advertisementData: null,
    advSearch: false,
    editionList: [],
};

export const advertisementSlice = createSlice({
    name: 'advertisement',
    initialState: advertisementState,
    reducers: {
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        updateSearchAction: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                advSearch: action.payload,
            };
        },
        setAdvertisementData: (
            state,
            action: PayloadAction<CreateAdvertisementResponseModel>,
        ) => {
            return {
                ...state,
                advertisementData: { ...action.payload },
            };
        },
        updateIndividualAdvertisement: (
            state,
            action: PayloadAction<
                | IndividualUpdateModel<ImageDeleteModel>
                | IndividualUpdateModel<CreateAdvertisementResponseModel>
            >,
        ) => {
            if (action.payload.from && action.payload.from === 'imageDelete') {
                const imageDelete = action.payload.data as ImageDeleteModel;
                if (state.advertisementData) {
                    if (AdvertisementFileType.Image === imageDelete.fileType) {
                        state.advertisementData.adImageUrl = '';
                        state.advertisementData.adImageFile = undefined;
                    } else if (AdvertisementFileType.TearSheet === imageDelete.fileType) {
                        state.advertisementData.tearSheetFileName = '';
                        state.advertisementData.tearsheetFile = undefined;
                    }
                }
            }
        },
        resetDetailViewState: (state) => {
            return {
                ...state,
                advertisementData: null,
            };
        },
        setEditionData: (state, action: PayloadAction<string[]>) => {
            const defaultEditionList = action.payload;
            const newObjectList = defaultEditionList.map((option) => ({
                title: option,
            }));

            return {
                ...state,
                editionList: [...newObjectList],
            };
        },
    },
});

export const getIndividualAdvertisement =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getAdvertisement(id);
            if (response.id !== '') {
                dispatch(setAdvertisementData(response));
                dispatch(setSuccess('Advertisement fetched Successfully'));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch advertisement`));
            Logger.error(`Failed to fetch advertisement ${JSON.stringify(exception)}`);
        }
    };

export const createAdvertisement =
    (data: CreateAdvertisementModel, edit: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await createAdvertisementApi(jsonToFormData(data));
            if (response) {
                dispatch(setAdvertisementData(response));
                const advertisementData: IndividualUpdateModel<CreateAdvertisementResponseModel> =
                    {
                        from: 'advUpdate',
                        data: response,
                    };
                dispatch(updateIndividualAdvertisementFromList(advertisementData));
                dispatch(updateIndividualAdvertisement(advertisementData));
                dispatch(
                    setSuccess(
                        `Advertisement ${edit ? 'updated' : 'created'} Successfully`,
                    ),
                );
                return response.id;
            }
        } catch (exception) {
            dispatch(setError(`Failed to ${edit ? 'updated' : 'create'} advertisement`));
            Logger.error(
                `Failed to ${edit ? 'updated' : 'create'} advertisement ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const deleteAdvertisementImages =
    (dataToBeDeleted: ImageDeleteModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await deleteAdvertisementImageApi(dataToBeDeleted);
            if (response.status === 204) {
                const deleteImage: IndividualUpdateModel<ImageDeleteModel> = {
                    from: 'imageDelete',
                    data: dataToBeDeleted,
                };
                dispatch(updateIndividualAdvertisementFromList(deleteImage));
                dispatch(updateIndividualAdvertisement(deleteImage));
                dispatch(setSuccess(`File removed from server`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete file from server`));
            Logger.error(
                `Failed to delete file from server ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

/**
 * function get the edition based on the selected media outlet on advertisement create
 * @param id
 * @returns
 */
export const getEdition =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getEditionApi(id);
            if (response) {
                dispatch(setEditionData(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch edition based on media outlet`));
            Logger.error(
                `Failed to fetch edition based on media outlet ${JSON.stringify(
                    exception,
                )}`,
            );
        }
    };

/**
 * function save the copy of the advertisment that is created
 * @param id
 * @returns
 */
export const copyAdvertisment =
    (copyData: AdvertisementCopyModel): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setProgress(true));
            const response = await copyAdvertismentApi(copyData);
            if (response.status === 204) {
                const advTableAction = getState().advertisement.advList.advTableAction;
                dispatch(getAdvertisementList({ ...advTableAction, currentPage: 1 }));
                dispatch(setSuccess(`Successfully copied the selected advertisement`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to copy the selected advertisement`));
            Logger.error(
                `Failed to copy the selected advertisement ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

// Function to check if the current form data is equivalent or not with old data.
export const isFormDataChanged = (
    currentState: CreateAdvertisementModel,
    initialState: CreateAdvertisementResponseModel | null,
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState?.hasOwnProperty(key) &&
                !lodash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

/**
 * export the searched results as excel
 * @param data
 * @returns
 */
export const exportAdvertisement =
    (advId: string, from: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            await returnFile(exportAdvertisementApi(advId, from));
        } catch (e) {
            Logger.error('Failed to export advertisement', e);
            dispatch(setError('Failed to export advertisement'));
        } finally {
            dispatch(setProgress(false));
        }
    };

export const {
    setProgress,
    setAdvertisementData,
    resetDetailViewState,
    updateIndividualAdvertisement,
    updateSearchAction,
    setEditionData,
} = advertisementSlice.actions;

export const advertisement = (state: RootState): AdvertisementState =>
    state.advertisement.advCreate;

export default advertisementSlice.reducer;
