import { PaginationResponse } from '../../../../../../../../../../shared/models/pagination/paginationModels';
import { isManager } from '../../../../../../../../../../shared/utils/shared/sharedUtils';
import { postCall } from '../../../../../../../../../../utils/api/apiWrapper';
import { ListingsResponse } from '../../../../../../../../../listings/listingListModels';
import {
    AssignListingSearchRequestModel,
    AssignListingToSpotRequest,
} from '../../../../spotTypeModels';

export function searchListingsApi(
    assignListingToSpot: AssignListingSearchRequestModel,
): Promise<PaginationResponse<ListingsResponse>> {
    //update agent id and agent role for manager
    const data = isManager(assignListingToSpot);
    assignListingToSpot.agentIds = data.agentIds
        ? data.agentIds
        : assignListingToSpot.agentIds;
    assignListingToSpot.roleIds = data.roleIds;
    return postCall<
        AssignListingSearchRequestModel,
        PaginationResponse<ListingsResponse>
    >(`listing/search`, assignListingToSpot);
}

export function assignListingToSpotApi(
    assignListingToSpot: Partial<AssignListingToSpotRequest>,
): Promise<AssignListingToSpotRequest> {
    return postCall<Partial<AssignListingToSpotRequest>, AssignListingToSpotRequest>(
        `Spot/listing`,
        assignListingToSpot,
    );
}
