import { Grid, Switch, TextField } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState, lazy } from 'react';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import {
    getCommonLookups,
    lookups,
} from '../../../../../../shared/slices/lookups/lookupsSlice';
import {
    editFormComponent,
    officeRegion as officeRegionList,
} from '../../../officeConstants';
import {
    DrawerContainerProps,
    OfficeChipData,
    OfficeDescription,
} from '../../../officeModels';
import { setCurrentOfficeEditFormData } from '../../../officeSlice';
import {
    displayErrorForOfficeEditScreen,
    getOfficeDescriptionFieldsData,
} from '../../../officeUtils';
import './description.scss';
import {
    CommonLookups,
    OfficeLookups,
    LookupInfo,
} from '../../../../../../shared/models/lookups/lookupsModels';
import {
    mlsSliceState,
    getAdditionalMls,
} from '../../../../../../shared/slices/additionalMls/additionalMlsSlice';

import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import { createOffice, updateChipsData } from '../../../officeApi';
import { getOffices } from '../../../../officeListSlice';
import { officeListInitialSearchRequest } from '../../../../officeListConstants';
import { useHistory } from 'react-router';
import moment from 'moment';
import { MAXINTEGERVALUE } from '../../../../../../utils/constants';
import Logger from '../../../../../../utils/logging/logger';

import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import OfficeChips from './chips';
import CustomModal from '../../../../../../shared/component/modal';
const OfficeMls = lazy(() => import('./OfficeMls'));

const Description: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
    closeSlider,
}) => {
    const dispatch = useAppDispatch();
    const { common, officeLook } = useAppSelector(lookups);
    const { additionalMls, isData } = useAppSelector(mlsSliceState);
    const { states, statuses } = common as CommonLookups;
    const { officeSalesRegions, officeSpecializations } = officeLook as OfficeLookups;
    let description: OfficeDescription = getOfficeDescriptionFieldsData(
        editFormData.data.officeDetails,
        states,
        officeSalesRegions,
    );
    const {
        formData,
        setFormData,
        handleNumberChange,
        handleTextChange,
        handleFloatChange,
    } = useFormData(description);
    const [isOpen, setOpen] = useState(false);
    let updatedOfficeEditFormData: OfficeDescription;
    const historyLocation = useHistory();
    const [save, setSave] = useState<boolean>(false);
    const [specializationChipData, setSpecializationChipData] = useState<
        OfficeChipData[]
    >([]);

    useEffect(() => {
        if (!additionalMls.length && editFormData.data.officeDetails.id && !isData) {
            dispatch(getAdditionalMls(editFormData.data.officeDetails.id));
        }
    }, [dispatch]);

    useEffect(() => {
        setFormDataToPersist();
    }, [formData.publish, formData.officeSpecializations]);

    useEffect(() => {
        const data: OfficeChipData[] = officeSpecializations?.map((item) => {
            const selectedData = formData?.officeSpecializations?.includes(
                parseInt(item.id),
            );
            return { ...item, selected: selectedData ? true : false };
        });
        setSpecializationChipData(data);
    }, [
        formData.officeSpecializations,
        setSpecializationChipData,
        officeSpecializations,
    ]);
    const switchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, publish: event.target.checked });
    };

    // Office Edit Screen Fields

    const renderDropDown = (label: string, property: string, list: LookupInfo[]) => {
        return (
            <FormRow
                label={label}
                control={
                    <DropDownSearch
                        id={
                            formData[property as keyof typeof formData] as number | string
                        }
                        items={list}
                        name={property}
                        handleDropDownChange={handleDropDownChange}
                        handleBlur={() => setFormDataToPersist()}
                    />
                }
                controlClass="customHolder"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
        );
    };

    const handleDropDownChange = (data: LookupInfo, name: string) => {
        //TODO: Convert all the lookups related field to string type to avoid console warnings as well as below conversions
        if (name === 'officeRegionId') {
            setFormData({
                ...formData,
                officeRegion: data.name,
                officeRegionId: data.id,
            });
        } else if (name === 'salesRegionId') {
            setFormData({
                ...formData,
                salesRegion: data.name,
                salesRegionId: Number(data.id),
            });
        } else if (name === 'regionId') {
            setFormData({
                ...formData,
                state: data.name,
                regionId: Number(data.id),
            });
        } else {
            setFormData({
                ...formData,
                status: data.name,
                statusId: Number(data.id),
            });
        }
    };

    const renderField = (
        label: string,
        property: string,
        disabledInput?: boolean,
        isNumber?: boolean,
        isPhoneNumber?: boolean,
        maximumLength?: number,
        isUrl?: boolean,
        isZip?: boolean,
    ) => {
        return (
            <FormRow
                label={label}
                control={
                    <TextField
                        InputProps={{
                            disableUnderline: true,
                            className: `inputField ${
                                disabledInput ? 'disabledInput' : ''
                            }`,
                        }}
                        value={formData[property as keyof typeof formData] || ''}
                        onChange={(event) =>
                            isPhoneNumber
                                ? handleNumberChange(event, true)
                                : isNumber
                                ? handleNumberChange(event)
                                : isUrl
                                ? handleTextChange(event, maximumLength, true)
                                : isZip
                                ? handleFloatChange(event)
                                : handleTextChange(event)
                        }
                        name={property}
                        onBlur={() => setFormDataToPersist()}
                        disabled={disabledInput ? disabledInput : false}
                        inputProps={{
                            maxLength: maximumLength ? maximumLength : MAXINTEGERVALUE,
                        }}
                    />
                }
                controlClass="customHolder"
                labelXs={12}
                labelSm={12}
                labelMd={2}
                controlXs={12}
                controlSm={12}
                controlMd={4}
            />
        );
    };

    const enableOrDisableField = (): boolean => {
        return editFormData.data.officeDetails.id ? true : false;
    };

    // Office Edit Screen Form Methods

    const setFormDataToPersist = () => {
        if (editFormData.data.officeDetails.id) {
            dispatch(
                setCurrentOfficeEditFormData({
                    formData,
                    officeEditFormName: editFormComponent.description,
                }),
            );
        }
    };

    const onSave = async () => {
        const result = displayErrorForOfficeEditScreen({
            name: formData.name || '',
            officeRegion: formData.officeRegion,
            salesRegion: formData.salesRegion || '',
            address1: formData.address1 || '',
            city: formData.city,
            state: formData.state || '',
            zip: formData.zip,
            phoneNumber: formData.phoneNumber || '',
            fax: formData.fax || '',
            email: formData.email || '',
        });
        if (result.isValid) {
            const {
                id,
                salesRegion,
                status,
                state,
                officeRegionId,
                stateId,
                officeSpecializations,
                ...officeDescription
            } = updatedOfficeEditFormData;
            setSave(true);
            try {
                const response = await createOffice({
                    ...officeDescription,
                    createdDate: moment().format('YYYY-MM-DDTHH:mm'),
                    history: '',
                });
                if (response?.id) {
                    dispatch(setSuccess('Office created successfully'));
                    if (officeSpecializations?.length) {
                        const updatedOfficeSpecsResponse = await updateChipsData(
                            response?.id,
                            officeSpecializations,
                        );
                        if (updatedOfficeSpecsResponse.status === 200) {
                            dispatch(
                                setSuccess('Office specializations updated successfully'),
                            );
                        }
                    }
                    dispatch(getOffices(officeListInitialSearchRequest));
                    dispatch(getCommonLookups());
                    onClear();
                    historyLocation.push(
                        `/eng/office/${response?.id.replace(/['"]+/g, '')}`,
                    );
                }
            } catch (e) {
                dispatch(setError('Error occured while creating the office'));
                Logger.error('Error occured while creating the office', e);
            }
        } else {
            dispatch(setError(result.errorMessage));
        }
        setSave(false);
    };

    const onClear = () => {
        description = getOfficeDescriptionFieldsData(
            editFormData.data.officeDetails,
            states,
            officeSalesRegions,
        );
        setFormData({ ...description });
        if (closeSlider) closeSlider();
    };

    return (
        <div className="office-detail-desc-section">
            <div className="content">
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField(
                        'PRIMARY MLS NAME',
                        'primaryMlsName',
                        false,
                        false,
                        false,
                        50,
                    )}
                    {renderDropDown('STATUS', 'statusId', statuses)}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField('PRIMARY MLS ID', 'mlsNumber', false, false, false, 50)}
                    <FormRow
                        label="PUBLISH"
                        control={
                            <div className="customSwitch">
                                <Switch
                                    className="switchIcon"
                                    name="publish"
                                    checked={formData?.publish || false}
                                    onChange={(e) => switchChange(e)}
                                />
                            </div>
                        }
                        controlClass=""
                        labelXs={12}
                        labelSm={12}
                        labelMd={2}
                        controlXs={12}
                        controlSm={12}
                        controlMd={4}
                    />
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {enableOrDisableField() ? (
                        <>
                            <Grid item md={2}></Grid>
                            <Grid
                                item
                                md={4}
                                className="formControlLabel addMls"
                                onClick={() => setOpen(true)}
                            >
                                Additional MLSs
                            </Grid>
                            <CustomModal
                                width="max"
                                show={isOpen}
                                onClose={() => setOpen(false)}
                            >
                                <OfficeMls
                                    additionalMls={additionalMls}
                                    officeId={editFormData.data.officeDetails?.id || ''}
                                />
                            </CustomModal>
                            {renderField(
                                'LOCATION CODE',
                                'locationCode',
                                enableOrDisableField(),
                                true,
                                false,
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField(
                        'NAME*',
                        'name',
                        enableOrDisableField(),
                        false,
                        false,
                        50,
                    )}
                    {renderDropDown('OFFICE REGION*', 'officeRegionId', officeRegionList)}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderDropDown('SALES REGION*', 'salesRegionId', officeSalesRegions)}
                </Grid>
            </div>
            <div className="content">
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField('ADDRESS 1*', 'address1', false, false, false, 255)}
                    {renderField('ADDRESS 2', 'address2', false, false, false, 255)}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField('CITY*', 'city', false, false, false, 255)}
                    {renderDropDown('STATE*', 'regionId', states)}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField('ZIP*', 'zip', false, false, false, 5, false, true)}
                </Grid>
            </div>
            <div className="content">
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField(
                        'OFFICE PHONE*',
                        'phoneNumber',
                        enableOrDisableField(),
                        false,
                        true,
                        10,
                    )}
                    {renderField('FAX*', 'fax', enableOrDisableField(), false, true, 10)}
                </Grid>
            </div>
            <div className="content">
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField('EMAIL*', 'email', false, false, false, 255)}
                    {renderField(
                        'FACEBOOK URL',
                        'facebookUrl',
                        false,
                        false,
                        false,
                        255,
                        true,
                    )}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField(
                        'LINKEDIN URL',
                        'linkedInUrl',
                        false,
                        false,
                        false,
                        255,
                        true,
                    )}
                    {renderField(
                        'TWITTER URL',
                        'twitterUrl',
                        false,
                        false,
                        false,
                        255,
                        true,
                    )}
                </Grid>
                <Grid item container className="section" md={12} spacing={2}>
                    {renderField(
                        'INSTAGRAM URL',
                        'instagramUrl',
                        false,
                        false,
                        false,
                        255,
                        true,
                    )}
                </Grid>
            </div>
            {specializationChipData.length ? (
                <div className="content">
                    <Grid
                        container
                        item
                        className="section small-fields"
                        md={12}
                        spacing={2}
                    >
                        <FormRow
                            label="SPECIALIZATION"
                            control={
                                <OfficeChips
                                    chipsData={specializationChipData}
                                    formData={formData}
                                    setFormData={setFormData}
                                    propertyName={'officeSpecializations'}
                                    setFormDataToPersist={setFormDataToPersist}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass=""
                            labelXs={null}
                            labelSm={null}
                            labelMd={2}
                            controlXs={null}
                            controlSm={null}
                            controlMd={10}
                        />
                    </Grid>
                </div>
            ) : null}

            {!editFormData.data.officeDetails?.id?.length && (
                <Grid item container md={12} className="buttons">
                    <button
                        className="customButton button"
                        onClick={onSave}
                        disabled={save}
                    >
                        Save
                    </button>
                    <button className="customButton button" onClick={onClear}>
                        Cancel
                    </button>
                </Grid>
            )}
        </div>
    );
};

export default Description;
