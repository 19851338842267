import React, {
    FunctionComponent,
    useEffect,
    ChangeEvent,
    useState,
    useMemo,
    useRef,
} from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { TextField, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { ListingDetailsData, ErrorProps } from '../listingDetailsModels';
import 'antd/dist/antd.css';
import { ListingState } from '../../../../listingModels';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks';
import FormRow from '../../../../../../../shared/component/formFields/FormRow';
import { LookupInfo } from '../../../../../../../shared/models/lookups/lookupsModels';
import DropDownSearch from '../../../../../../../shared/component/formFields/DropDownSearch';
import { currencyFormat, focusElement } from '../../../../../../../utils/urlUtils';
import {
    CommonLookups,
    ListingLookups,
} from '../../../../../../../shared/models/lookups/lookupsModels';
import { setError } from '../../../../../../../shared/slices/messaging/messagingSlice';
import {
    listingStatuses,
    listingTypeDetails,
    dealTypeList,
    editFormComponent,
    lotSizeTypes,
    dealTypes as dealTypesText,
    listingTypes,
} from '../../../../listingConstants';
import { useTooltipStyle } from '../../../../../../../shared/component/toolTip/toolTipStyle';
import {
    fetchTridentValidateDetails,
    listingDetail,
    saveToggle,
    setCurrentFormData,
} from '../../../../listingSlice';
import {
    faCircleInfo,
    faCircleQuestion,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwnerInfo from '../../ownerInfo/OwnerInfo';
import {
    mlsSliceState,
    getAdditionalMls,
} from '../../../../../../../shared/slices/additionalMls/additionalMlsSlice';
import {
    AdditionalMls,
    MlsSave,
} from '../../../../../../../shared/models/additionalMls/additionalMlsModels';
import { mlsFor } from '../../../../../../../shared/constants/additionalMls/mlsConstants';
import AdditionalMlsList from '../../../../../../../shared/component/additionalMls/index';
import { disableListingPriceEdit } from '../../../../../utils';
import RadioButtonToggle from '../../../../../../../shared/component/formFields/RadioButtonToggle';
import { getUser } from '../../../../../../../shared/auth/authService';
import {
    fetchUserPermissions,
    userIsInAnyRole,
    userIsInRole,
    shouldDisableField,
} from '../../../../../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../../../../../shared/constants/user/roles';
import { OwnerInfoModel } from '../../../../../../../shared/models/ownerInfoModel/ownerInfoModel';

interface SaleProps {
    formData: ListingDetailsData;
    setFormData: React.Dispatch<React.SetStateAction<ListingDetailsData>>;
    handleTextChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
    ) => void;
    handleNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    listingInfo: ListingState;
    setFormDataToPersist: (ownerInfo?: OwnerInfoModel[]) => void;
    errorState: ErrorProps;
    setErrorState: React.Dispatch<React.SetStateAction<ErrorProps>>;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCurrencyChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    commonLookups: CommonLookups;
    listingLookups: ListingLookups;
    handleFloatChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
}

const Sale: FunctionComponent<SaleProps> = ({
    formData,
    setFormData,
    handleTextChange,
    handleNumberChange,
    handleCheckboxChange,
    handleCurrencyChange,
    listingInfo,
    setFormDataToPersist,
    errorState,
    setErrorState,
    commonLookups,
    listingLookups,
    handleFloatChange,
}) => {
    const [isOpen, setOpen] = useState(false);
    const tooltipClass = useTooltipStyle();
    const dispatch = useAppDispatch();
    const { additionalMls, isData } = useAppSelector(mlsSliceState);
    const { data } = useAppSelector(listingDetail);
    const user = useMemo(() => getUser(), []);
    const isUserSysAdminOrPowerUser = useMemo(
        () => userIsInAnyRole(user, [userRoles.systemAdmin.key, userRoles.powerUser.key]),
        [user],
    );
    const officesList = commonLookups.offices;
    const officeIds = useMemo(
        () =>
            userIsInAnyRole(user, [userRoles.systemAdmin.key])
                ? []
                : fetchUserPermissions(user, 'officeIds'),
        [user],
    );
    let filteredOffices = officesList.map((o) => {
        return {
            id: o.id,
            name: o.name,
        };
    });
    filteredOffices = userIsInRole(user, userRoles.systemAdmin.key)
        ? filteredOffices
        : filteredOffices.filter((office) => {
              return officeIds.includes(office.id);
          });
    const inputRefs = useRef<HTMLInputElement[] | HTMLSelectElement[]>([]);

    /**
     * disable status field when listing status is not draft or coming soon.
     * above logic will be over writter for sysadmin
     * @param data
     * @returns
     */
    const getListingDisabledStatus = (statusId: number | null): boolean => {
        //7 is draft, 8 is coming soon, 11 is private
        let showStatus = true;
        if (statusId) {
            showStatus =
                statusId === 7 || statusId === 8 || statusId === 11 ? true : false;
        }
        const result = isUserSysAdminOrPowerUser || showStatus ? true : false;
        return !result;
    };

    const handleZipChange = () => {
        if (formData.zip) {
            if (formData.zip.toString().length !== 5) {
                setFormData({ ...formData, zip: '' });
                setErrorState({ ...errorState, zip: true });
            } else {
                setErrorState({ ...errorState, zip: false });
            }
        }
        setFormDataToPersist();
    };

    const handleCurrentPriceChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = e.target;
        const numVal = parseInt(value.replace(/[$,]+/g, ''));
        if (
            listingInfo.data.listingType &&
            listingInfo.data.listingType === listingTypeDetails.sale
        ) {
            setFormData({
                ...formData,
                prices: [
                    {
                        price: numVal,
                        rentalPeriod: formData.prices[0].rentalPeriod,
                    },
                ],
                [name]: numVal,
            });
        }
    };

    const renderRow = (
        label: string,
        control: JSX.Element,
        prefix = '',
        suffix = '',
        controlClass = '',
        labelXs?: GridSize | null,
        labelSm?: GridSize | null,
        labelMd?: GridSize | null,
        controlXs?: GridSize | null,
        controlSm?: GridSize | null,
        controlMd?: GridSize | null,
    ) => {
        return (
            <FormRow
                label={label}
                control={control}
                prefix={prefix}
                suffix={suffix}
                controlClass={controlClass}
                labelXs={labelXs}
                labelSm={labelSm}
                labelMd={labelMd}
                controlXs={controlXs}
                controlSm={controlSm}
                controlMd={controlMd}
            />
        );
    };

    const setStatus = (value: unknown) => {
        switch (Number(value)) {
            case listingStatuses.pending:
                if (formData.contractDate?.length) {
                    setFormData({
                        ...formData,
                        listingStatusId: Number(value),
                    });
                } else {
                    dispatch(
                        setError(
                            'Error: Status cannot be set to Pending if no Contract date is set.',
                        ),
                    );
                }
                break;
            case listingStatuses.sold:
                if (formData.closedDate?.length) {
                    setFormData({
                        ...formData,
                        listingStatusId: Number(value),
                    });
                } else {
                    dispatch(
                        setError(
                            'Error: Status cannot be set to Sold if no Closed date is set.',
                        ),
                    );
                }
                break;
            case listingStatuses.comingSoon:
                if (formData.projectedListDate?.length) {
                    setFormData({
                        ...formData,
                        listingStatusId: Number(value),
                    });
                } else {
                    dispatch(
                        setError(
                            'Error: Status cannot be set to Coming soon if no Projected list date is set.',
                        ),
                    );
                }
                break;
            default:
                setFormData({
                    ...formData,
                    listingStatusId: Number(value),
                });
                break;
        }
    };

    const handleDropDownSearch = (data: LookupInfo, name: string) => {
        if (name === 'regionId') {
            setFormData({
                ...formData,
                regionId: Number(data.id),
                state: data.name,
            });
        } else if (name === 'listingStatusId') {
            setStatus(data.id);
        } else if (name === 'propertyTypeId') {
            setFormData({
                ...formData,
                propertyTypeId: Number(data.id),
            });
        } else if (name === 'officeId') {
            setFormData({
                ...formData,
                officeId: data.id,
            });
        } else if (name === 'dealTypeId') {
            let dealType = '';
            switch (Number(data.id)) {
                case 1:
                    dealType = dealTypesText.listSide;
                    break;
                case 2:
                    dealType = dealTypesText.bothSides;
                    break;
                case 3:
                    dealType = dealTypesText.saleSide;
                    break;
                default:
                    dealType = '';
                    break;
            }
            setFormData({
                ...formData,
                dealTypeId: Number(data.id),
                dealType: dealType,
            });
        } else if (name === 'listingType') {
            setFormData({
                ...formData,
                listingType:
                    Number(data.id) === 1
                        ? listingTypeDetails.sale
                        : listingTypeDetails.rental,
            });
        }
    };

    const setMlsDataToPersist = (additionalMlsData: AdditionalMls[]) => {
        const saveData: MlsSave = {
            data: additionalMlsData.filter((mls) => mls.active),
            mlsFor: mlsFor.listing,
            id: listingInfo.data.id,
        };
        dispatch(
            setCurrentFormData({
                formData: saveData,
                listingEditFormName: editFormComponent.additionalMls,
            }),
        );
    };

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData, dispatch]);

    useEffect(() => {
        if (!additionalMls.length && listingInfo.data.officeId && !isData) {
            dispatch(
                getAdditionalMls(
                    listingInfo.data.officeId,
                    mlsFor.listing,
                    listingInfo.data.id,
                ),
            );
        }
    }, [additionalMls, listingInfo, isData]);

    useEffect(() => {
        const { name, refIndex } = listingInfo.editForm?.field || {};
        if (name && name !== '' && (refIndex || 0) >= 0) {
            (inputRefs.current[refIndex || 0] as HTMLInputElement | HTMLSelectElement) &&
                (
                    inputRefs.current[refIndex || 0] as
                        | HTMLInputElement
                        | HTMLSelectElement
                ).scrollIntoView({
                    behavior: 'smooth',
                });
        }
    }, [listingInfo.editForm?.field]);

    //static trident validation message
    const getValidationMessage = () => {
        return (
            <div>
                Listing {!listingInfo.tridentValidation?.sentToTrident ? 'not' : ''} sent
                to Trident{' '}
                {!listingInfo.tridentValidation ? (
                    <FontAwesomeIcon icon={faSpinner} />
                ) : (
                    ''
                )}
                {listingInfo.tridentValidation &&
                listingInfo.tridentValidation?.validationMessages.length > 0 ? (
                    <>
                        <br /> To send this listing to Trident add:
                        <ol>
                            {listingInfo.tridentValidation?.validationMessages.map(
                                (data) => {
                                    return <li>{data}</li>;
                                },
                            )}
                        </ol>
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    /**Trident validation */
    const makeTridentApiCall = () => {
        if (listingInfo && listingInfo.data.id && !listingInfo.tridentValidation) {
            dispatch(fetchTridentValidateDetails(listingInfo.data.id));
        }
    };

    const handleLotSizeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const typeName = lotSizeTypes.find((l) => l.id === value)?.name;
        if (typeName) {
            setFormData({ ...formData, [name || '']: value, lotSizeUnit: typeName });
        }
    };

    const handleOwnerInfo = (updatedOwners: OwnerInfoModel[]) => {
        setFormData({ ...formData, ownerInfo: updatedOwners });
        setFormDataToPersist(updatedOwners);
    };

    const getPriceOnRequest = (label: string) => {
        return (
            <Grid item container alignItems="center" md={6} sm={6} spacing={3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputProps={{
                                'aria-label': 'Price Upon Request',
                            }}
                            checked={
                                formData.priceUponRequest
                                    ? formData.priceUponRequest
                                    : false
                            }
                            onChange={handleCheckboxChange}
                            name="priceUponRequest"
                            onBlur={() => setFormDataToPersist()}
                            classes={{
                                root: 'checkBoxRoot',
                                checked: '',
                            }}
                            id="priceUponRequestCheckbox"
                            onClick={() => focusElement('priceUponRequestCheckbox')}
                        />
                    }
                    label={<div className="checkboxLabel">{label}</div>}
                    labelPlacement="end"
                />
            </Grid>
        );
    };

    return (
        <div className="saleContainer">
            <div className="section">
                {formData.listingType === listingTypeDetails.sale ? (
                    <>
                        <Grid
                            item
                            container
                            className="content"
                            alignItems="center"
                            spacing={2}
                            md={12}
                            sm={12}
                        >
                            {formData.listingStatusId === listingStatuses.sold
                                ? renderRow(
                                      'SOLD PRICE',
                                      <TextField
                                          value={
                                              formData.soldPrice
                                                  ? currencyFormat(formData.soldPrice)
                                                  : ''
                                          }
                                          onChange={handleCurrencyChange}
                                          InputProps={{
                                              disableUnderline: true,
                                              'aria-label': 'soldPrice',
                                              className: `inputBaseRoot ${
                                                  disableListingPriceEdit()
                                                      ? 'inputDisabled'
                                                      : ''
                                              }`,
                                              startAdornment: '$',
                                          }}
                                          inputProps={{ maxLength: 13 }}
                                          onBlur={() => setFormDataToPersist()}
                                          name="soldPrice"
                                          disabled={disableListingPriceEdit()}
                                      />,
                                      '',
                                      '',
                                      'formControlRoot',
                                      null,
                                      2,
                                      2,
                                      null,
                                      4,
                                      4,
                                  )
                                : renderRow(
                                      'PRICE',
                                      <TextField
                                          value={currencyFormat(formData.currentPrice)}
                                          onChange={handleCurrentPriceChange}
                                          InputProps={{
                                              disableUnderline: true,
                                              'aria-label': 'currentPrice',
                                              className: `inputBaseRoot ${
                                                  disableListingPriceEdit()
                                                      ? 'inputDisabled'
                                                      : ''
                                              }`,
                                              startAdornment: '$',
                                          }}
                                          inputProps={{ maxLength: 11 }}
                                          onBlur={() => setFormDataToPersist()}
                                          name="currentPrice"
                                          disabled={disableListingPriceEdit()}
                                      />,
                                      '',
                                      '',
                                      'formControlRoot',
                                      null,
                                      2,
                                      2,
                                      null,
                                      4,
                                      4,
                                  )}
                            {formData.listingStatusId === listingStatuses.sold ? (
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    md={6}
                                    sm={6}
                                    spacing={3}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                inputProps={{
                                                    'aria-label':
                                                        'Display Sold Price for Select Sale',
                                                }}
                                                checked={
                                                    formData.displayOfferPriceForSelectSold
                                                }
                                                onChange={handleCheckboxChange}
                                                name="displayOfferPriceForSelectSold"
                                                onBlur={() => setFormDataToPersist()}
                                                classes={{
                                                    root:
                                                        listingInfo.data.salesRegionId ===
                                                            20 ||
                                                        listingInfo.data.salesRegionId ===
                                                            9
                                                            ? 'disable-check-box'
                                                            : 'enable-check-box',
                                                }}
                                                disabled={
                                                    listingInfo.data.salesRegionId ===
                                                        20 ||
                                                    listingInfo.data.salesRegionId === 9
                                                        ? true
                                                        : false
                                                }
                                                id="displayOfferPriceForSelectSoldCheckbox"
                                                onClick={() =>
                                                    focusElement(
                                                        'displayOfferPriceForSelectSoldCheckbox',
                                                    )
                                                }
                                            />
                                        }
                                        label={
                                            <div
                                                className={
                                                    listingInfo.data.salesRegionId === 20
                                                        ? 'checkboxLabel disabledLabel'
                                                        : 'checkboxLabel'
                                                }
                                            >
                                                DISPLAY SOLD PRICE FOR SELECT SALE
                                            </div>
                                        }
                                        labelPlacement="end"
                                    />
                                </Grid>
                            ) : (
                                getPriceOnRequest('PRICE UPON REQUEST')
                            )}
                        </Grid>
                        {formData.listingStatusId === listingStatuses.sold ? (
                            <Grid
                                item
                                container
                                className="content"
                                alignItems="center"
                                spacing={2}
                                md={12}
                            >
                                {renderRow(
                                    'LAST ASKING PRICE',
                                    <TextField
                                        value={
                                            formData.currentPrice
                                                ? currencyFormat(formData.currentPrice)
                                                : ''
                                        }
                                        onChange={handleCurrencyChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            'aria-label': 'lastAskingPrice',
                                            className: `inputBaseRoot ${
                                                disableListingPriceEdit()
                                                    ? 'inputDisabled'
                                                    : ''
                                            }`,
                                            startAdornment: '$',
                                        }}
                                        inputProps={{ maxLength: 13 }}
                                        onBlur={() => setFormDataToPersist()}
                                        name="lastAskingPrice"
                                        disabled={disableListingPriceEdit()}
                                        inputRef={(r) => {
                                            if (r) inputRefs.current[3] = r;
                                        }}
                                    />,
                                    '',
                                    '',
                                    'formControlRoot',
                                    null,
                                    2,
                                    2,
                                    null,
                                    4,
                                    4,
                                )}
                            </Grid>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'TITLE*',
                        <TextField
                            value={formData.title ? formData.title : ''}
                            onChange={(e) => handleTextChange(e, 200)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'title',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="title"
                            inputRef={(r) => {
                                if (r) inputRefs.current[0] = r;
                            }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    <Grid item container alignItems="center" md={6} sm={6} spacing={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Show Address',
                                    }}
                                    checked={listingInfo.data.showAddress}
                                    onChange={(e) =>
                                        dispatch(
                                            saveToggle(
                                                listingInfo.data.id,
                                                e.target.checked,
                                                e.target.name,
                                            ),
                                        )
                                    }
                                    name="showAddress"
                                    classes={{
                                        root: 'checkBoxRoot',
                                        checked: '',
                                    }}
                                />
                            }
                            label={<div className="checkboxLabel">SHOW ADDRESS</div>}
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'ADDRESS 1*',
                        <TextField
                            value={formData.address1 ? formData.address1 : ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'address1',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="address1"
                            inputRef={(r) => {
                                if (r) inputRefs.current[1] = r;
                            }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'ADDRESS 2',
                        <TextField
                            value={formData.address2 ? formData.address2 : ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'address2',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="address2"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'CITY*',
                        <TextField
                            value={formData.city ? formData.city : ''}
                            onChange={(e) => handleTextChange(e, 80)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'city',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="city"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {/* {renderRow(
                        'STATE*',
                        regionList,
                        '',
                        '',
                        'formControlRoot  inputBaseRoot dropdownBaseRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )} */}
                    {renderRow(
                        'STATE*',
                        <DropDownSearch
                            id={formData.regionId || ''}
                            items={commonLookups.states}
                            name="regionId"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'ZIP*',
                        <TextField
                            value={formData.zip ? formData.zip : ''}
                            onChange={handleFloatChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'zip',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 5 }}
                            onBlur={handleZipChange}
                            name="zip"
                            error={errorState.zip}
                            helperText={errorState.zip ? 'Invalid' : ''}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'zip-error-message',
                                },
                            }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'NEIGHBORHOOD',
                        <TextField
                            value={formData.neighborhood ? formData.neighborhood : ''}
                            onChange={(e) => handleTextChange(e, 80)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'city',
                                className: shouldDisableField()
                                    ? 'inputBaseRoot inputDisabled'
                                    : 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="neighborhood"
                            disabled={shouldDisableField()}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                {formData.listingType === listingTypeDetails.rental && formData.listingStatusId !== listingStatuses.sold 
                ? (
                    <Grid
                        item
                        container
                        className="content"
                        alignItems="center"
                        spacing={2}
                        md={12}
                    >
                        {getPriceOnRequest('PRICES UPON REQUEST')}
                    </Grid>
                ) : ''}
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {/* {renderRow(
                        'STATUS',
                        statusList,
                        '',
                        '',
                        getListingDisabledStatus(data.listingStatusId)
                            ? 'formControlRoot inputDisabled inputBaseRoot dropdownBaseRoot'
                            : 'formControlRoot  inputBaseRoot dropdownBaseRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )} */}
                    {renderRow(
                        'STATUS',
                        <DropDownSearch
                            id={formData.listingStatusId || ''}
                            items={listingLookups.statuses}
                            name="listingStatusId"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                            disabled={getListingDisabledStatus(data.listingStatusId)}
                        />,
                        '',
                        '',
                        getListingDisabledStatus(data.listingStatusId)
                            ? 'formControlRoot inputDisabled'
                            : 'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {formData.listingStatusId === listingStatuses.pending ? (
                        <Grid
                            item
                            container
                            alignItems="center"
                            md={6}
                            sm={6}
                            spacing={3}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'Show Pending as Active',
                                        }}
                                        checked={formData.showPendingAsActive}
                                        onChange={handleCheckboxChange}
                                        name="showPendingAsActive"
                                        onBlur={() => setFormDataToPersist()}
                                        classes={{
                                            root: 'checkBoxRoot',
                                            checked: '',
                                        }}
                                        id="showPendingAsActive"
                                        onClick={() =>
                                            focusElement('showPendingAsActive')
                                        }
                                    />
                                }
                                label={
                                    <div className="checkboxLabel">
                                        SHOW PENDING AS ACTIVE
                                    </div>
                                }
                                labelPlacement="end"
                            />
                        </Grid>
                    ) : (
                        ''
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'PRIMARY MLS',
                        <TextField
                            value={formData.mlsNumber ? formData.mlsNumber : ''}
                            onChange={handleTextChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'mlsNumber',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="mlsNumber"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {/* {renderRow(
                        'DEAL TYPE*',
                        dealTypes,
                        '',
                        '',
                        'formControlRoot  inputBaseRoot dropdownBaseRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )} */}
                    {renderRow(
                        'DEAL TYPE*',
                        <DropDownSearch
                            id={formData.dealTypeId || ''}
                            items={dealTypeList}
                            name="dealTypeId"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content tooltipContent"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item md={2} sm={2}></Grid>
                    <Grid
                        item
                        md={4}
                        sm={4}
                        className="formControlLabel addMls"
                        onClick={() => setOpen(!isOpen)}
                    >
                        Additional MLSs
                    </Grid>
                    {renderRow(
                        'DEAL NUMBER',
                        <TextField
                            value={
                                formData.tridentDealNumber
                                    ? formData.tridentDealNumber
                                    : ''
                            }
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'tridentDealNumber',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="tridentDealNumber"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {!data.tridentDealNumber || data.tridentDealNumber === '' ? (
                        <span className="listingDetailTooltip">
                            <Tooltip
                                onOpen={makeTridentApiCall}
                                title={getValidationMessage()}
                                classes={{
                                    tooltip: tooltipClass.tooltip,
                                }}
                                arrow
                                placement="top"
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                                </span>
                            </Tooltip>
                        </span>
                    ) : (
                        <></>
                    )}
                </Grid>
                {isOpen ? (
                    <AdditionalMlsList
                        additionalMls={additionalMls}
                        setMlsDataToPersist={setMlsDataToPersist}
                    />
                ) : (
                    ''
                )}
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'RFGID',
                        <TextField
                            value={formData.rfgId ? formData.rfgId : ''}
                            disabled={shouldDisableField()}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'rfgId',
                                className: shouldDisableField()
                                    ? 'inputBaseRoot inputDisabled'
                                    : 'inputBaseRoot',
                            }}
                            onChange={handleTextChange}
                            onBlur={() => setFormDataToPersist()}
                            name="rfgId"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {/* {renderRow(
                        'TYPE',
                        typesList,
                        '',
                        '',
                        shouldDisableField()
                            ? 'formControlRoot inputDisabled inputBaseRoot dropdownBaseRoot'
                            : 'formControlRoot  inputBaseRoot dropdownBaseRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )} */}
                    {renderRow(
                        'TYPE',
                        <DropDownSearch
                            id={
                                formData.listingType === listingTypeDetails.sale
                                    ? '1'
                                    : '2'
                            }
                            items={listingTypes}
                            name="listingType"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                            disabled={shouldDisableField()}
                        />,
                        '',
                        '',
                        shouldDisableField()
                            ? 'formControlRoot inputDisabled'
                            : 'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'TAX MAP',
                        <TextField
                            value={formData.taxMapNumber ? formData.taxMapNumber : ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'taxMapNumber',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="taxMapNumber"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content tooltipContent"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'INTERIOR',
                        <TextField
                            value={formData.interiorSize ? formData.interiorSize : ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'interiorSize',
                                className: 'inputBaseRoot',
                                endAdornment: 'Sq.Ft',
                            }}
                            inputProps={{ maxLength: 13 }}
                            onBlur={() => setFormDataToPersist()}
                            name="interiorSize"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'OUTDOOR SPACE',
                        <TextField
                            value={formData.exteriorSize ? formData.exteriorSize : ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'exteriorSize',
                                className: 'inputBaseRoot',
                                endAdornment: 'Sq.Ft',
                            }}
                            inputProps={{ maxLength: 13 }}
                            onBlur={() => setFormDataToPersist()}
                            name="exteriorSize"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    <span className="listingDetailTooltip">
                        <Tooltip
                            title="Usable exterior space for amenities such as terraces, balconies, patios, etc..."
                            arrow
                            placement="top"
                            classes={{
                                tooltip: tooltipClass.tooltip,
                            }}
                        >
                            <span className="icon">
                                <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                            </span>
                        </Tooltip>
                    </span>
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'LOT SIZE',
                        <TextField
                            value={formData.lotSize || ''}
                            name="lotSize"
                            onChange={handleFloatChange}
                            onBlur={() => setFormDataToPersist()}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'lotSize',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 10 }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'LOT SIZE TYPE',
                        <RadioButtonToggle
                            id={formData.lotSizeTypeId || ''}
                            name="lotSizeTypeId"
                            items={lotSizeTypes}
                            handleRadioButtonChange={handleLotSizeTypeChange}
                            handleBlur={() => setFormDataToPersist()}
                            formClass={{
                                label: 'formControlLabel',
                            }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'TOTAL ROOMS',
                        <TextField
                            value={formData.rooms || ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'rooms',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 10 }}
                            onBlur={() => setFormDataToPersist()}
                            name="rooms"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'BEDROOMS',
                        <TextField
                            value={formData.bedrooms ? formData.bedrooms : ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'bedrooms',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 10 }}
                            onBlur={() => setFormDataToPersist()}
                            name="bedrooms"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'FULL BATHS',
                        <TextField
                            value={formData.fullBaths ? formData.fullBaths : ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'fullBaths',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 10 }}
                            onBlur={() => setFormDataToPersist()}
                            name="fullBaths"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'HALF BATHS',
                        <TextField
                            value={formData.halfBaths ? formData.halfBaths : ''}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'halfBaths',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 10 }}
                            onBlur={() => setFormDataToPersist()}
                            name="halfBaths"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
            </div>
            <div className="section endSection">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {/* {renderRow(
                        'PROPERTY TYPE',
                        propTypes,
                        '',
                        '',
                        'formControlRoot  inputBaseRoot dropdownBaseRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )} */}
                    {renderRow(
                        'PROPERTY TYPE',
                        <DropDownSearch
                            id={formData.propertyTypeId || ''}
                            items={listingLookups.propertyTypes}
                            name="propertyTypeId"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                            emitElement={(e) => {
                                if (inputRefs && e) inputRefs.current[2] = e;
                            }}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                    {renderRow(
                        'OFFICE',
                        <DropDownSearch
                            id={formData.officeId || ''}
                            items={filteredOffices}
                            name="officeId"
                            handleBlur={() => setFormDataToPersist()}
                            handleDropDownChange={handleDropDownSearch}
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'COUNTY',
                        <TextField
                            value={formData.county || ''}
                            onChange={handleTextChange}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'county',
                                className: 'inputBaseRoot',
                            }}
                            onBlur={() => setFormDataToPersist()}
                            name="county"
                        />,
                        '',
                        '',
                        'formControlRoot',
                        null,
                        2,
                        2,
                        null,
                        4,
                        4,
                    )}
                </Grid>
                <OwnerInfo
                    ownerInfo={formData.ownerInfo || []}
                    handleOwnerInfo={handleOwnerInfo}
                    listingStatusId={formData.listingStatusId}
                />
            </div>
        </div>
    );
};

export default Sale;
