import { FunctionComponent } from 'react';
import { DrawerContainerProps, AgentDescription } from '../../../agentModels';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
    ImageData,
    ImageDetail,
    ImageDeleteData,
} from '../../../../../../shared/models/images/sharedModel';
import { minPhotoDim } from '../../../agentConstants';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import { deleteAgentPhoto, uploadAgentPhoto } from '../../../agentSlice';
import PhotoTemplate from '../../../../../../shared/component/photo/PhotoTemplate';

const Photo: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const photo = editFormData.agentDetails.agentPhoto as ImageData[];
    const dispatch = useAppDispatch();

    const validateImageSize = (width: number, height: number): boolean => {
        return width >= minPhotoDim.minWidth && height >= minPhotoDim.minHeight;
    };

    const handleUpload = (imageData: ImageDetail, isCropper = false) => {
        if (isCropper) {
            deleteHandler(false);
        }
        dispatch(uploadAgentPhoto(imageData, isCropper));
    };

    const deleteHandler = (showToaster = true) => {
        const deleteData: ImageDeleteData = {
            ids: [photo[0]?.guid],
            imageType: imageTypes.associates,
            shouldValidate: false,
        };
        dispatch(deleteAgentPhoto(deleteData, showToaster));
    };

    const photoUploadInstructions = () => {
        return (
            <div>
                Photo must be a jpg/jpeg in 840x1120 pixel portrait format, at 72 dpi
                minimum resolution. Photo must be in color, not in black and white.
                <br /> Composition: It's best when a person/people are in the center of
                the image or slightly off-center.
                <br /> Cropping: It's best to have chest-up/waist-up cropping for a
                person. The portrait should have at least 200px around the head.
            </div>
        );
    };

    return (
        <PhotoTemplate
            entityId={
                (editFormData.agentDetails.descriptionDetails as AgentDescription)
                    .profileId
            }
            entityType={entityTypes.profile.value}
            imageType={imageTypes.associates}
            photoUrl={photo[0]?.guid?.length ? photo[0].urls.originalUrl : ''}
            isUploading={editFormData.flags.isAgentPhotoUploading}
            infoText={photoUploadInstructions()}
            handleUpload={handleUpload}
            deleteHandler={deleteHandler}
            validateImageSize={validateImageSize}
            imageCrop={true}
        />
    );
};

export default Photo;
