import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { FunctionComponent, useEffect, ChangeEvent } from 'react';
import { PrivateSiteUser } from '../../showcaseWebsiteModels';
import { showCaseWebsiteStyle } from '../../style';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import './index.scss';
import { postUser, putUser } from '../../showcaseWebsiteSlice';
import { useAppDispatch, useFormData } from '../../../../../../../../app/hooks';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import { datePickerFormat } from '../../../../../../../../utils/urlUtils';
import { setError } from '../../../../../../../../shared/slices/messaging/messagingSlice';
interface GenerateTokenProps {
    formEdit: boolean;
    handleCancel?: (id?: string | boolean) => void;
    initialState: PrivateSiteUser;
    showcaseSiteId: string;
}

const GenerateToken: FunctionComponent<GenerateTokenProps> = ({
    formEdit,
    handleCancel,
    initialState,
    showcaseSiteId,
}) => {
    const classes = showCaseWebsiteStyle();
    const {
        formData,
        setFormData,
        handleTextChange,
        handleDateChange,
        handleNumberChange,
    } = useFormData(initialState);
    const dispatch = useAppDispatch();
    const addAccessCode = () => {
        validateStartAndEndDates('POST');
    };
    const updateAccessCode = () => {
        validateStartAndEndDates('PUT');
    };

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData, dispatch]);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const handleCustomDate = () => {
        setFormData({
            ...formData,
            customDate: !formData.customDate,
            startDate: '',
            endDate: '',
        });
    };

    const savePrivateSiteUser = (privateSiteUser: PrivateSiteUser, method: string) => {
        if (method === 'POST') {
            dispatch(postUser(privateSiteUser));
            setFormData(initialState);
        } else dispatch(putUser(privateSiteUser));
        handleCancel?.(method === 'POST' ? true : formData?.id);
    };

    const validateStartAndEndDates = (method: string) => {
        let isBefore = false;
        let isAfter = false;
        let privateSiteUser: PrivateSiteUser = {
            ...formData,
            showcaseSiteId: showcaseSiteId,
        };
        if (
            privateSiteUser.customDate &&
            privateSiteUser.startDate &&
            privateSiteUser.endDate
        ) {
            isBefore = moment(privateSiteUser.startDate).isSameOrBefore(
                privateSiteUser.endDate,
                'day',
            );
            isAfter = moment(privateSiteUser.endDate).isSameOrAfter(
                privateSiteUser.startDate,
                'day',
            );
            if (isBefore && isAfter) {
                privateSiteUser = {
                    ...privateSiteUser,
                    startDate: moment(privateSiteUser.startDate)
                        .set({ hours: 0, minutes: 0, seconds: 0 })
                        .format('YYYY-MM-DDTHH:mm'),
                    endDate: moment(privateSiteUser.endDate)
                        .set({ hours: 23, minutes: 59, seconds: 59 })
                        .format('YYYY-MM-DDTHH:mm'),
                };
                savePrivateSiteUser(privateSiteUser, method);
            } else dispatch(setError('End date is lesser than the start date'));
        } else {
            savePrivateSiteUser(privateSiteUser, method);
        }
    };

    return (
        <>
            <Grid item container spacing={2}>
                <Grid item container alignItems="center">
                    <FormRow
                        label="ACCESS CODE"
                        control={
                            <TextField
                                value={formData.accessCode}
                                size="small"
                                onChange={handleTextChange}
                                name="accessCode"
                                InputProps={{
                                    disableUnderline: true,
                                    autoComplete: 'off',
                                    className: 'inputFields',
                                }}
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={4}
                        controlMd={8}
                        labelSm={4}
                        controlSm={8}
                    />
                </Grid>
                <Grid item container alignItems="center">
                    <FormRow
                        label="CLIENT NAME"
                        control={
                            <TextField
                                value={formData.name}
                                size="small"
                                onChange={handleTextChange}
                                name="name"
                                InputProps={{
                                    disableUnderline: true,
                                    autoComplete: 'off',
                                    className: 'inputFields',
                                }}
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={4}
                        controlMd={8}
                        labelSm={4}
                        controlSm={8}
                    />
                </Grid>
                <Grid item container alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{
                                    root: classes.checkBox,
                                    checked: classes.checked,
                                }}
                                name="approved"
                                checked={formData.approved}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="Approved"
                        className="approved"
                    />
                </Grid>
                <Grid item container className="contentHeader">
                    EXPIRATION PREFERENCES
                </Grid>
                <Grid item container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    classes={{
                                        root: classes.checkBox,
                                        checked: classes.checked,
                                    }}
                                    checked={formData.customDate}
                                    onChange={handleCustomDate}
                                    name="customDate"
                                />
                            }
                            label="Set custom dates"
                            className="customDate"
                        />
                    </Grid>
                    {formData.customDate ? (
                        <>
                            <Grid item xs={12} md={1} className="contentHeader">
                                START DATE
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={3}
                                className="dateSelect formControlRoot"
                            >
                                <DatePicker
                                    className="datePickerCommon"
                                    placeholder=""
                                    name="startDate"
                                    value={
                                        formData.startDate !== '' &&
                                        formData.startDate !== null
                                            ? moment(formData.startDate)
                                            : undefined
                                    }
                                    format={datePickerFormat()}
                                    onChange={(date: Moment | null) => {
                                        handleDateChange('startDate', date);
                                    }}
                                    showToday={false}
                                    style={{
                                        padding: '5px 10px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1} className="contentHeader">
                                END DATE
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={3}
                                className="dateSelect formControlRoot"
                            >
                                <DatePicker
                                    className="datePickerCommon"
                                    placeholder=""
                                    name="endDate"
                                    value={
                                        formData.endDate !== '' &&
                                        formData.endDate !== null
                                            ? moment(formData.endDate)
                                            : undefined
                                    }
                                    format={datePickerFormat()}
                                    onChange={(date: Moment | null) => {
                                        handleDateChange('endDate', date);
                                    }}
                                    showToday={false}
                                    style={{
                                        padding: '5px 10px',
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        ''
                    )}
                </Grid>
                <Grid item container alignItems="center">
                    <FormRow
                        label="EMAIL"
                        control={
                            <TextField
                                value={formData.email}
                                size="small"
                                onChange={handleTextChange}
                                name="email"
                                InputProps={{
                                    disableUnderline: true,
                                    autoComplete: 'off',
                                    className: 'inputFields',
                                }}
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={4}
                        controlMd={8}
                        labelSm={4}
                        controlSm={8}
                    />
                </Grid>
                <Grid item container alignItems="center">
                    <FormRow
                        label="PHONE"
                        control={
                            <TextField
                                value={formData.phone}
                                size="small"
                                onChange={(e) => handleNumberChange(e, true)}
                                name="phone"
                                InputProps={{
                                    disableUnderline: true,
                                    autoComplete: 'off',
                                    className: 'inputFields',
                                }}
                            />
                        }
                        controlClass="formControlRoot"
                        labelMd={4}
                        controlMd={8}
                        labelSm={4}
                        controlSm={8}
                    />
                </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end">
                {!formEdit ? (
                    <>
                        <Button
                            onClick={() => handleCancel?.(true)}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => addAccessCode()}
                            className="generateAccessCodeButton"
                            disabled={
                                !formData.name ||
                                !formData.accessCode ||
                                (formData.customDate &&
                                    (!formData.startDate || !formData.endDate))
                            }
                        >
                            Add
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={() => handleCancel?.(formData?.id)}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => updateAccessCode()}
                            className="generateAccessCodeButton"
                            disabled={
                                !formData.name ||
                                !formData.accessCode ||
                                (formData.customDate &&
                                    (!formData.startDate || !formData.endDate))
                            }
                        >
                            Update
                        </Button>
                    </>
                )}
            </Grid>
        </>
    );
};

export default GenerateToken;
