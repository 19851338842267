import React, {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    CircularProgress,
    Input,
    makeStyles,
    TextField,
    Tooltip,
} from '@material-ui/core';
import './videoTemplate.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { VideoFieldsProps, VideoModel } from '../../models/video/videoModels';
import { videoFields, videoStatuses } from '../../constants/video/videoConstants';
import { useTooltipStyle } from '../toolTip/toolTipStyle';

interface VideoTemplateProps {
    fileInputName: string;
    tooltipMessage?: string;
    videoSectionTitle?: string;
    data: VideoModel;
    videoFields?: VideoFieldsProps;
    reuploadInterval: number;
    index: number;
    uploadAction(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ): void;
    cancelAction(video: VideoModel, index: number): void;
    downloadAction(video: VideoModel, from: string): void;
    deleteAction(video: VideoModel): void;
    updateInterval(remainingInterval: number, fileInputName: string): void;
    updateFields?(video: VideoModel): void;
}

const useStyles = makeStyles({
    root: {
        width: '70%',
        color: '#666',
        fontFamily: 'MercuryTextG4Roman',
        fontSize: '14px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        border: '1px solid #d6d6d6',
        marginLeft: '100px',
    },
});

const VideoTemplate: FunctionComponent<VideoTemplateProps> = (props) => {
    const classes = useStyles();
    const [copy, setCopy] = useState<boolean>(false);
    const tooltipClass = useTooltipStyle();
    const interval = useRef<NodeJS.Timeout>();

    const emitAction = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.uploadAction(event, props.index);
    };

    const videoDownload = (video: VideoModel) => {
        props.downloadAction(video, props.fileInputName);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(
            `<iframe src='${props.data.url}' allowfullscreen frameborder=0></iframe>`,
        );
        setCopy(true);
        setTimeout(() => {
            setCopy(false);
        }, 5000);
    };

    const cancelVideoUpload = (video: VideoModel) => {
        props.cancelAction(video, props.index);
    };

    const deleteVideo = (video: VideoModel) => {
        props.deleteAction(video);
    };

    const updateFieldsEvent = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string,
        currentVideo: VideoModel,
    ) => {
        let video;
        if (fieldName === videoFields.title) {
            video = { ...currentVideo, caption: e.target.value };
        } else {
            video = { ...currentVideo, description: e.target.value };
        }
        props.updateFields?.(video);
    };

    useEffect(() => {
        interval.current && clearInterval(interval.current);
        if (props.reuploadInterval > 0) {
            interval.current = setInterval(() => {
                const remainingInterval = props.reuploadInterval - 10000;
                props.updateInterval(remainingInterval, props.fileInputName);
            }, 10000);
        }
        return () => {
            interval.current && clearInterval(interval.current);
        };
    }, [props.reuploadInterval]);

    return (
        <>
            <div className="videoText">
                <div className="title">
                    {props?.videoSectionTitle || ''}
                    {props?.tooltipMessage ? (
                        <Tooltip
                            title={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.tooltipMessage,
                                    }}
                                />
                            }
                            arrow
                            placement="bottom"
                            classes={{
                                tooltip: tooltipClass.tooltip,
                            }}
                        >
                            <span className="tooltip-icon">Video Specs & Details</span>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </div>
                {props.data.status === videoStatuses.inProgress ? (
                    <button
                        onClick={() => cancelVideoUpload(props.data)}
                        className="delete"
                    >
                        <FontAwesomeIcon className="deleteIcon" icon={faXmark} />
                        Cancel
                    </button>
                ) : props.data.status === videoStatuses.completed ||
                  props.data.status === videoStatuses.failed ? (
                    <button onClick={() => deleteVideo(props.data)} className="delete">
                        <FontAwesomeIcon className="deleteIcon" icon={faTrashAlt} />
                        Delete
                    </button>
                ) : (
                    <></>
                )}
            </div>
            <div
                className={
                    props?.data?.status === videoStatuses.completed
                        ? 'videoAction hasVideo'
                        : 'videoAction'
                }
            >
                {props?.data?.status === videoStatuses.completed ? (
                    <iframe
                        src={props?.data?.url || ''}
                        title="Video"
                        className="videoSection"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="encrypted-media"
                    ></iframe>
                ) : props?.data?.status === videoStatuses.inProgress ||
                  props?.data?.status === videoStatuses.failed ||
                  props?.reuploadInterval > 0 ? (
                    <div className="smallOverlay">
                        <div className="text">
                            <span className="loading-text">
                                {props.data.status === videoStatuses.inProgress
                                    ? 'Uploading...'
                                    : props.data.status === videoStatuses.failed
                                    ? 'Failed!'
                                    : 'Hold on...'}
                            </span>
                            <span>
                                {props.data.status === videoStatuses.inProgress ||
                                props.reuploadInterval ? (
                                    <CircularProgress style={{ color: '#002349' }} />
                                ) : (
                                    <></>
                                )}
                            </span>
                            <span className="customColor">
                                {props.data.status === videoStatuses.inProgress
                                    ? 'You must remain on this page until the current upload is completed.'
                                    : props.data.status === videoStatuses.failed
                                    ? 'Video upload is failed, please delete and re-upload the video'
                                    : 'Please wait for three minutes to re-upload the video'}
                            </span>
                        </div>
                    </div>
                ) : props?.data?.status === videoStatuses.started ? (
                    <label className="videoUpload">
                        <FontAwesomeIcon className="plus" icon={faPlus} />
                        <Input
                            className="video"
                            name={props.fileInputName}
                            type="file"
                            inputProps={{ accept: 'video/*' }}
                            onChange={emitAction}
                        />
                    </label>
                ) : (
                    <></>
                )}
            </div>
            {props.data && Object.keys(props.data).length > 0 && props.data.url ? (
                <div className="videoDetails">
                    {props?.videoFields?.showTitle ? (
                        <div className="holder">
                            <span className="leftContent title">Title</span>
                            <span className="rightContent title">
                                <TextField
                                    className={classes.root + ' videoCaption'}
                                    name={props.fileInputName + 'title'}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    size="small"
                                    onBlur={(e) =>
                                        updateFieldsEvent(
                                            e,
                                            videoFields.title,
                                            props.data,
                                        )
                                    }
                                    defaultValue={
                                        props?.data?.caption ? props.data.caption : ''
                                    }
                                />
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                    {props?.videoFields?.showDescription ? (
                        <div className="holder">
                            <span className="leftContent description">Description</span>
                            <span className="rightContent description">
                                <TextField
                                    className={classes.root + ' videoCaption'}
                                    name={props.fileInputName + 'title'}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    size="small"
                                    onBlur={(e) =>
                                        updateFieldsEvent(
                                            e,
                                            videoFields.description,
                                            props.data,
                                        )
                                    }
                                    defaultValue={
                                        props?.data?.description
                                            ? props.data.description
                                            : ''
                                    }
                                />
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="holder">
                        <span className="leftContent link">Video URL</span>
                        <span className="rightContent link italic">
                            <a href={props.data.url} target="_blank" rel="noreferrer">
                                Link
                            </a>
                        </span>
                    </div>
                    <div className="holder">
                        <span className="leftContent link">Embed Code</span>
                        <span className="rightContent link italic">
                            <Tooltip
                                title={copy ? 'Copied' : 'Copy to clipboard'}
                                placement="top-start"
                            >
                                <button onClick={copyToClipboard} className="link">
                                    Link
                                </button>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="holder">
                        <span className="leftContent link">Brightcove Video</span>
                        <span className="rightContent link italic vidoeText">
                            {props.data.videoId}
                        </span>
                    </div>
                    <div className="holder">
                        <span className="leftContent link">Video Download</span>
                        <span className="rightContent link italic">
                            <button
                                onClick={() => videoDownload(props.data)}
                                className="link"
                            >
                                Link
                            </button>
                        </span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default VideoTemplate;
