import {
    minImageDimLandscape,
    minImageDimPortrait,
    ranges,
    imageSizes,
    selectionSizes,
} from '../../constants/photos/photoConstants';
import { ImageData, DimensionProps } from '../../models/images/sharedModel';
import { imageTypes } from '../../constants/imageTypes';
import * as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function validateImageSize(width: number, height: number): boolean {
    if (width > height) {
        // Landscape Image
        return (
            width >= minImageDimLandscape.minCropWidth &&
            height >= minImageDimLandscape.minCropHeight
        );
    } else {
        // Portrait Image
        return (
            width >= minImageDimPortrait.minCropWidth &&
            height >= minImageDimPortrait.minCropHeight
        );
    }
}

export function isImageLarge(width: number, height: number): boolean {
    return width > 3600 || height > 3600;
}

export function formatSize(size: number, precision = 2): string {
    if (size) {
        const kilobytes = size;
        if (isNaN(kilobytes) || !isFinite(kilobytes)) return '-';

        if (typeof precision === 'undefined') precision = 2;
        const units = ['KB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(kilobytes) / Math.log(1024));
        return (
            (kilobytes / Math.pow(1024, Math.floor(number)))
                .toFixed(precision)
                .replace(/\.?0+$/, '') +
            ' ' +
            units[number]
        );
    }
    return '';
}

export function bytesToSize(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const updateOrder = (data: ImageData[]): ImageData[] => {
    const updated = data.map((image: ImageData, index) => {
        return { ...image, orderNo: index + 1 };
    });
    return updated;
};

export function validateAspectRatio(width: number, height: number): boolean {
    const ratio = width / height;
    if (width > height) {
        // Landscape Image
        return ratio >= ranges.landscape.minRange && ratio <= ranges.landscape.maxRange;
    } else {
        // Portrait Image
        return ratio >= ranges.portrait.minRange && ratio <= ranges.portrait.maxRange;
    }
}

export const getPhotoId = (
    image: ImageData,
    dimensions: string,
    currentImageType: string,
): string => {
    const id =
        `${currentImageType}/${image.guid}` + (dimensions !== '' ? `_${dimensions}` : '');
    const imageType = image.type.split('/');
    if (imageType[1] === 'jpeg' || imageType[1] === 'jpg') {
        return id + '.jpg';
    } else if (imageType[1] === 'png') {
        return id + '.png';
    } else if (imageType[1] === 'pdf') {
        return id + '.pdf';
    }
    return '';
};

export const toDataURL = (url: string): Promise<string> => {
    return fetch(url)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            return URL.createObjectURL(blob);
        });
};

export const urlToFileUsingFetch = async (
    url: string,
    fileName: string,
    mimeType: string,
): Promise<File> => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new File([buffer], fileName, { type: mimeType });
};

/**
 *
 * @param imageType current imageType
 * @returns imageType required for api call
 */
export const getCurrentImageType = (imageType: string): string => {
    switch (imageType) {
        case imageTypes.UploadedImages:
            return imageTypes.photographerUpload;
        case imageTypes.photographerUpload:
            return imageTypes.UploadedImages;
        case imageTypes.buildingFloorplans:
            return imageTypes.BuildingFloorPlans;
        case imageTypes.BuildingFloorPlans:
            return imageTypes.buildingFloorplans;
        default:
            return imageType;
    }
};

/**
 * This method resizes the image to <= 1800x1200 for landscape or <= 1200x1800 for portrait
 * while maintaining aspect ratio of original image
 * @param imgWidth width of the image to be resized
 * @param imgHeight height of the image to be resized
 * @returns resized dimensions of the image
 */
export const dynamicResize = (imgWidth: number, imgHeight: number): DimensionProps => {
    const isLandscape = imgWidth > imgHeight ? true : false;
    // aspectRatio will always be > 1 since longer side/ shorter side
    const aspectRatio = isLandscape ? imgWidth / imgHeight : imgHeight / imgWidth;
    // i.e aspect ratio is proper 3x2 for landscape or 2x3 for portrait
    if (aspectRatio === 1.5) {
        return isLandscape
            ? { width: 1800, height: 1200 }
            : { width: 1200, height: 1800 };
    }
    // rounding off two 2 decimal places
    const reqdRatio = Math.round((aspectRatio + Number.EPSILON) * 100) / 100;
    if (!isLandscape) {
        // fixing height(longer side) first as 1800px and finding width accordingly and rounding off to nearest integer
        const newWidth = Math.round(1800 / reqdRatio);
        // if newWidth is more than 1200, fix width as 1200 and find height accordingly
        if (newWidth > 1200) {
            const newHeight = Math.round(1200 * reqdRatio);
            return { width: 1200, height: newHeight };
        }
        return { width: newWidth, height: 1800 };
    } else if (isLandscape) {
        // fix width(longer side) first as 1800 and find height accordingly
        const newHeight = Math.round(1800 / reqdRatio);
        // if newHeight is more than 1200, then fix height as 1200 and find width
        if (newHeight > 1200) {
            const newWidth = Math.round(1200 * reqdRatio);
            return { width: newWidth, height: 1200 };
        }
        return { width: 1800, height: newHeight };
    }
    return isLandscape ? { width: 1800, height: 1200 } : { width: 1200, height: 1800 };
};

/**
 *
 * @param id rfgid of listing or any development id
 * @param add1 address1
 * @param add2 address2
 * @returns concatenates parameters and returns string
 */
export const getStreetAddress = (
    id: string | null,
    add1: string | null,
    add2: string | null,
): string => {
    const entityId = id ? `${id} - ` : '';
    const address = add1 ? add1 : '' + add2 ? `, ${add2}` : '';
    // removing special characters for package
    return (entityId + address).replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');
};

/**
 *
 * @param isLandscape if the current image is landscape or portrait
 * @param currentDimension if the image selected for share/download is small or large
 * @returns image dimension value based on selection
 */
export const getImageDimension = (isLandscape: boolean, selection: string): string => {
    switch (selection) {
        case selectionSizes.small:
            return isLandscape ? imageSizes.smallLandscape : imageSizes.smallPortrait;
        case selectionSizes.medium:
            return isLandscape ? imageSizes.mediumLandscape : imageSizes.mediumPortrait;
        default:
            return '';
    }
};

/**
 * Method to check if an image exists on the given url
 * @param url The url to check
 * @param callback Function executed when the image exists or doesn't exists on the url
 */
export const ifImageExists = (url: string, callback: (exists: boolean) => void): void => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
        callback(true);
    } else {
        img.onload = () => {
            callback(true);
        };
        img.onerror = () => {
            callback(false);
        };
    }
};

/**
 * Used for counting number of pages in a pdf (required for floorplan pdf upload)
 * @param file pdf file
 */
export const getPdfPageCount = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const fileArray = new Uint8Array(buffer);
    return pdfjs.getDocument(fileArray).promise.then((doc) => {
        return doc.numPages;
    });
};
