import { Tab, Tabs } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { PostAdd } from '@material-ui/icons';
import { TabPanel } from '../../../../../../../../shared/component/tabPanel/TabPanel';
import { CustomTabEditor } from './CustomEditor';
import { CustomTabModel } from '../../showcaseWebsiteModels';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndTab from './dragAndDrop/dndTab';
import update from 'immutability-helper';
import './index.scss';
import { useWindowDimensions } from '../../../../../../../../shared/component/Window';

interface CustomTabProps {
    initialState: CustomTabModel;
    customTabs: CustomTabModel[];
    updateCTDetails(data: CustomTabModel[]): void;
}

const CustomTab: FunctionComponent<CustomTabProps> = ({
    initialState,
    customTabs,
    updateCTDetails,
}) => {
    const apiKeyOfTinyMce = process.env.REACT_APP_TINY_MCE;
    const [tabId, setTabId] = useState<number>(0);
    const { width } = useWindowDimensions();
    const sendSelectedTab = (currentTabId: number | undefined) => {
        if (currentTabId === -1) {
            addNewTab();
        } else {
            setTabId(currentTabId || 0);
        }
    };

    const [tabs, setTabs] = useState<CustomTabModel[]>([]);

    const addNewTab = () => {
        const updatedTabs = updateOrder([...tabs, { ...initialState }]);
        setTabs(updatedTabs);
    };

    const updateOrder = (tabData: CustomTabModel[]) => {
        const result = tabData.map((data: CustomTabModel, index: number) => {
            data.tabId = index;
            data.orderNo = index + 1;
            return data;
        });
        return result;
    };

    const deleteSelectedTab = (
        e: React.MouseEvent<SVGElement, MouseEvent>,
        tabId: number | undefined,
    ) => {
        e.stopPropagation();
        e.preventDefault();
        const updatedTabs = tabs.filter((data) => data.tabId !== tabId);
        if (updatedTabs) {
            setTabId(0);
            updateCTDetails(updatedTabs);
        }
    };

    useEffect(() => {
        if (customTabs.length > 0) {
            const updatedCustomTabData = customTabs.map((data, index) => {
                return {
                    ...data,
                    tabId: index,
                };
            });
            setTabs(updatedCustomTabData);
        } else {
            setTabs([
                {
                    tabId: 0,
                    orderNo: 1,
                    title: '',
                    body: '',
                },
            ]);
        }
    }, [customTabs]);

    const handleTitleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        position: number,
    ) => {
        tabs[position].title = event.target.value;
        updateCTDetails(tabs);
    };

    const handleEditorChange = (value: string, position: number) => {
        tabs[position].body = value;
        updateCTDetails(tabs);
    };

    const moveTab = (dragIndex: number, hoverIndex: number, isDropped: boolean) => {
        if (dragIndex !== hoverIndex) {
            const updatedTabsWithTabIds = reorderTabs(dragIndex, hoverIndex);
            setTabs([...updatedTabsWithTabIds]);
        }
        if (isDropped) {
            updateCTDetails(tabs);
        }
    };

    const reorderTabs = (startIndex: number, endIndex: number): CustomTabModel[] => {
        const currentTabs = [...tabs];
        const draggedTab = currentTabs[startIndex];
        const updatedTabsWithoutTabIds = update(currentTabs, {
            $splice: [
                [startIndex, 1],
                [endIndex, 0, draggedTab],
            ],
        });
        const updatedTabsWithTabIds = updateTabIds(updatedTabsWithoutTabIds);
        return updatedTabsWithTabIds;
    };

    const updateTabIds = (customTabs: CustomTabModel[]): CustomTabModel[] => {
        const updatedTabsWithoutTabIds: CustomTabModel[] = JSON.parse(
            JSON.stringify(customTabs),
        );
        const updatedTabsWithTabIds: CustomTabModel[] = updatedTabsWithoutTabIds.map(
            (ctm: CustomTabModel, i: number) => {
                ctm.orderNo = i + 1;
                ctm.tabId = i;
                return ctm;
            },
        );
        return updatedTabsWithTabIds;
    };

    return (
        <div className="customTab">
            {tabs?.length > 0 ? (
                <>
                    <DndProvider backend={HTML5Backend}>
                        <Tabs
                            value={tabId}
                            TabIndicatorProps={{ style: { background: 'transparent' } }}
                            className="tabs"
                            variant="scrollable"
                            scrollButtons={width > 767 ? 'auto' : 'on'}
                        >
                            {tabs.map((tab, index) => {
                                return (
                                    <DndTab
                                        tabId={tabId}
                                        key={index}
                                        index={index}
                                        tab={tab}
                                        moveTab={moveTab}
                                        sendSelectedTab={sendSelectedTab}
                                        deleteSelectedTab={deleteSelectedTab}
                                    ></DndTab>
                                );
                            })}
                            {tabs?.length < 4 ? (
                                <Tab
                                    icon={<PostAdd />}
                                    onClick={() => sendSelectedTab(-1)}
                                    value={-1}
                                />
                            ) : (
                                ''
                            )}
                        </Tabs>
                    </DndProvider>
                    {tabs.map((tab, index) => {
                        return (
                            <TabPanel
                                id="custom-tab"
                                index={tab.tabId}
                                key={index}
                                value={tabId}
                            >
                                <CustomTabEditor
                                    position={index}
                                    value={tab}
                                    apiKeyOfTinyMce={apiKeyOfTinyMce}
                                    handleTitleChange={handleTitleChange}
                                    handleEditorChange={handleEditorChange}
                                    key={index}
                                ></CustomTabEditor>
                            </TabPanel>
                        );
                    })}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CustomTab;
