import { Order } from '../../../shared/models/customTable/customTableModels';
import { PaginationRequest } from '../../../shared/models/pagination/paginationModels';

export interface AdvertisementState {
    isLoading: boolean;
    advSearch: boolean;
    advertisementData: CreateAdvertisementResponseModel | null;
    editionList: EditionList[];
}
export interface AdvertisementTableActionType {
    [key: string]: string | Order | number | boolean;
}
export interface AdvertisementCommon {
    mediaOutletId: string;
    location: string;
    edition: string;
    type: string;
    frequency: string;
    publish: boolean;
    runDate: string;
    reservationDeadline: string;
    totalCost: number;

    notes: string;
}
export interface CreateAdvertisementModel extends AdvertisementCommon {
    name: string;
    tearsheetFile?: File | null;
    adImageFile?: File | null;
    tearSheetUrl?: string;
    adImageUrl?: string;
    mediaOutletName?: string;
    tearSheetFileName?: string;
}

export interface CreateAdvertisementResponseModel extends CreateAdvertisementModel {
    id?: string;
    createdDate?: string;
    lastUpdated?: string;
    createdBy?: string;
    lastUpdatedBy?: string;
}
export interface AdvertisementListModel extends AdvertisementCommon {
    id: string;
    name: string;
    mediaOutletName: string;
    tearSheetUrl: string;
    adImageUrl: string;
}

export interface AdvertisementSearchRequest extends PaginationRequest {
    name?: string;
    edition?: string;
    mediaOutletId?: string;
    runStartDate?: string;
    runEndDate?: string;
    deadlineStartDate?: string;
    deadlineEndDate?: string;
}

export enum AdvertisementFileType {
    Image = 1,
    TearSheet,
}

/**
 * model that is used for advertisement copy holds count of copy, frequency and
 * array of object to create copy
 */
export interface AdvertisementCopyModel {
    advertisementId: string;
    count: number;
    frequency: string;
    advertisementCopies: AdvertisementCopy[];
}

/**
 * model that is used to have fields for advertisement copy
 */
export interface AdvertisementCopy {
    name: string;
    edition: string;
    reservationDeadline: string;
    runDate: string;
}

export interface EditionList {
    title: string;
}

export interface CreateEditAdvertisement {
    id: string;
    displayError: boolean;
    isValid: boolean;
}
