import { combineReducers } from '@reduxjs/toolkit';
import MediaOutletReducer from '../../features/advertisements/mediaOutlets/mediaOutletListSlice';
import AdvertisementListReducer from '../../features/advertisements/advertisementListSlice';
import AdvertisementDetailsReducer from '../../features/advertisements/advertisementHolder/advertisementSlice';
import { MediaOutletStateInterface } from '../../features/advertisements/mediaOutlets/mediaOutletListModels';
import { AdvertisementListState } from '../../features/advertisements/advertisementListModels';
import { AdvertisementState } from '../../features/advertisements/advertisementHolder/advertisementModels';
import { SpotTypeState } from '../../features/advertisements/advertisementHolder/viewAdvertisement/component/spotTable/spotTypeModels';
import SpotTypeReducer from '../../features/advertisements/advertisementHolder/viewAdvertisement/component/spotTable/spotTypeSlice';
import PressItemReducer from '../../features/advertisements/pressItem/pressListSlice';
import { PressItemStateInterface } from '../../features/advertisements/pressItem/pressListModels';

interface AdvertisementRootState {
    mediaOutletList: MediaOutletStateInterface;
    advList: AdvertisementListState;
    advCreate: AdvertisementState;
    spotType: SpotTypeState;
    pressItemList: PressItemStateInterface;
}

export default combineReducers<AdvertisementRootState>({
    mediaOutletList: MediaOutletReducer,
    advList: AdvertisementListReducer,
    advCreate: AdvertisementDetailsReducer,
    spotType: SpotTypeReducer,
    pressItemList: PressItemReducer,
});
