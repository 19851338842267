export function generateRandomAccessCode(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$@#!';
    const n = charset.length;
    let retVal = '';
    for (let i = 0; i < length; ++i) {
        retVal += charset.charAt(Math.random() * n);
    }
    return retVal;
}

export const privateSiteUserAuditsData = [];
export const userData = [
    {
        id: '',
        showcaseSiteId: '',
        name: '',
        accessCode: '',
        startDate: '',
        endDate: '',
        phone: '',
        email: '',
        createdDate: '',
        approved: false,
        privateSiteUserAudits: privateSiteUserAuditsData,
        customDate: false,
    },
];
export const domainStatuses = ['ACTIVE', 'PENDING', 'EXPIRED', 'CANCELLED'];
