import Grid from '@material-ui/core/Grid/Grid';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getUser } from '../../../../../shared/auth/authService';
import ComponentWithLoader from '../../../../../shared/component/loader/ComponentWithLoader';
import { Order } from '../../../../../shared/models/customTable/customTableModels';
import { CommonLookups } from '../../../../../shared/models/lookups/lookupsModels';
import { lookups } from '../../../../../shared/slices/lookups/lookupsSlice';
import { setBackTo } from '../../../../../shared/slices/navigation/navigationSlice';
import { price, soldTable } from '../../../../listings/listingListConstants';
import { ListingsResponse } from '../../../../listings/listingListModels';
import Sold from '../../../../listings/shared/table/sold';
import {
    AgentDashboardState,
    AgentDescription,
    AgentSearchSelect,
    AgentSelect,
} from '../../agentModels';
import {
    exportSelectSoldsReports,
    fetchAgentSelectSalesListing,
    selectSalelistingOptionUpdate,
} from '../../agentSlice';
import SearchSelectSale from '../searchSelectSale/SearchSelectSale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const SelectSale: FunctionComponent<{
    businessPurposeTypeId: number;
    stateData: AgentDashboardState;
}> = ({ businessPurposeTypeId, stateData }) => {
    const dispatch = useAppDispatch();
    const { common } = useAppSelector(lookups);
    const user = useMemo(() => getUser(), []);
    const { offices } = common as CommonLookups;
    const filteredTable = soldTable.filter(
        (data) => data.key !== 'businessPurposeTypeId',
    );
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [salesRegionId, setSalesRegionId] = useState<number[]>([]); //store salesregion id based on logged in user
    const agentDetails = stateData.agentDetails.descriptionDetails as AgentDescription;
    const orderBy = (
        stateData.agentDetails[
            businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
        ] as AgentSelect
    ).selectSalesTableAction.sortColumn;

    const orders = (
        stateData.agentDetails[
            businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
        ] as AgentSelect
    ).selectSalesTableAction.sortDirection;

    const listings = (
        stateData.agentDetails[
            businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
        ] as AgentSelect
    ).selectSalesList.results as ListingsResponse[];

    const searchSelect = stateData.agentDetails[
        businessPurposeTypeId === 1
            ? 'agentSearchSelectSales'
            : 'agentSearchSelectRentals'
    ] as AgentSearchSelect;

    const handleRequestSort = (property: string) => {
        const { sortColumn, sortDirection } = (
            stateData.agentDetails[
                businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
            ] as AgentSelect
        ).selectSalesTableAction;
        const isAsc = sortColumn === property && sortDirection === 'asc';
        let direction: Order = isAsc ? 'desc' : 'asc';
        let sortProptery = property;
        //for sold section sorting for address/title or soldprice/current price
        if (
            property === 'useAddressAsTitle' ||
            property === 'displayOfferPriceForSelectSold'
        ) {
            direction = sortDirection === 'asc' ? 'desc' : 'asc';
            sortProptery = property === 'useAddressAsTitle' ? 'address1' : 'soldPrice';
        }
        const searchedData = stateData.agentDetails[
            businessPurposeTypeId === 1
                ? 'agentSearchSelectSales'
                : 'agentSearchSelectRentals'
        ] as AgentSearchSelect;
        dispatch(
            fetchAgentSelectSalesListing(
                (stateData.agentDetails.descriptionDetails as AgentDescription).agentId,
                (stateData.agentDetails.descriptionDetails as AgentDescription).officeId,
                businessPurposeTypeId, //businesspurposeid
                1,
                sortProptery,
                direction,
                searchedData,
            ),
        );
    };

    const handleNavigation = (id: string) => {
        dispatch(setBackTo({ url: '/eng/listings', title: 'Listings' }));
        // history.push(`/eng/listing/${id}`);
    };

    const handleSoldCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        listing: ListingsResponse,
        from: string,
    ) => {
        const { name, checked } = event.target;
        dispatch(
            selectSalelistingOptionUpdate(
                listing,
                name,
                checked,
                from,
                (stateData.agentDetails.descriptionDetails as AgentDescription).agentId,
            ),
        );
    };

    const handleSoldRadioButtonChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        listing: ListingsResponse,
        from: string,
    ) => {
        const { name } = e.target;
        dispatch(
            selectSalelistingOptionUpdate(
                listing,
                name,
                !listing[name as keyof typeof listing],
                from,
            ),
        );
    };

    const loadMore = () => {
        const { sortColumn, sortDirection, currentPage } = (
            stateData.agentDetails[
                businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
            ] as AgentSelect
        ).selectSalesTableAction;
        const { results, totalRecords } = (
            stateData.agentDetails[
                businessPurposeTypeId === 1 ? 'agentSelectSales' : 'agentSelectRentals'
            ] as AgentSelect
        ).selectSalesList;
        const searchedData = stateData.agentDetails[
            businessPurposeTypeId === 1
                ? 'agentSearchSelectSales'
                : 'agentSearchSelectRentals'
        ] as AgentSearchSelect;
        if (results.length !== totalRecords)
            dispatch(
                fetchAgentSelectSalesListing(
                    (stateData.agentDetails.descriptionDetails as AgentDescription)
                        .agentId,
                    (stateData.agentDetails.descriptionDetails as AgentDescription)
                        .officeId,
                    businessPurposeTypeId, //businesspurposeid
                    currentPage + 1,
                    sortColumn,
                    sortDirection,
                    searchedData,
                ),
            );
    };

    /**
     * function to find the office sales region
     */
    useEffect(() => {
        if (offices && offices.length) {
            const filteredOff = offices
                .filter(
                    (data) =>
                        (stateData.agentDetails.descriptionDetails as AgentDescription)
                            .officeId === data.id,
                )
                .map(({ salesRegionId }) => salesRegionId);
            setSalesRegionId(filteredOff);
        }
    }, [offices, setSalesRegionId]);

    const onSearch = useCallback(
        (data: AgentSearchSelect, isCleared?: boolean) => {
            if (businessPurposeTypeId === 1) {
                dispatch(
                    fetchAgentSelectSalesListing(
                        agentDetails.agentId,
                        agentDetails.officeId,
                        1, //businesspurpose
                        1, //currentpage
                        (stateData.agentDetails.descriptionDetails as AgentDescription)
                            .sortField === 'price'
                            ? 'soldPrice'
                            : 'closedDate',
                        'desc',
                        data,
                    ),
                );
            } else {
                dispatch(
                    fetchAgentSelectSalesListing(
                        agentDetails.agentId,
                        agentDetails.officeId,
                        2, //businesspurpose
                        1, //currentpage
                        (stateData.agentDetails.descriptionDetails as AgentDescription)
                            .sortField === 'price'
                            ? 'soldPrice'
                            : 'closedDate',
                        'desc',
                        data,
                    ),
                );
            }
        },

        [dispatch, businessPurposeTypeId],
    );

    const exportSelectSolds = () => {
        const data = {
            agentIds: [agentDetails.agentId],
            officeIds: [agentDetails.officeId],
            roleIds: ['cb9d9788-ed11-481c-ab75-8e67b5ad798c'], //agent Role,
            status: ['Sold'],
            agentName: agentDetails.firstName + ' ' + agentDetails.lastName,
        };
        dispatch(exportSelectSoldsReports(data));
    };
    return (
        <div className="select-sales-container">
            <Grid
                container
                item
                md={12}
                className="totalRecords"
                justifyContent="space-between"
            >
                <Grid item md={6} sm={12}>
                    Total Records:{' '}
                    {
                        (
                            stateData.agentDetails[
                                businessPurposeTypeId === 1
                                    ? 'agentSelectSales'
                                    : 'agentSelectRentals'
                            ] as AgentSelect
                        ).selectSalesList.totalRecords
                    }
                </Grid>
                <Grid
                    container
                    item
                    md={6}
                    sm={12}
                    justifyContent="flex-end"
                    className="exportHolder"
                >
                    <Grid>
                        <button className="customButton" onClick={exportSelectSolds}>
                            Export
                        </button>
                    </Grid>
                    <Grid>
                        <button
                            className="transparentButton"
                            onClick={() => setOpenSearch(!openSearch)}
                        >
                            Search{' '}
                            <FontAwesomeIcon
                                icon={openSearch ? faAngleUp : faAngleDown}
                            />
                        </button>
                    </Grid>
                </Grid>
            </Grid>
            {openSearch ? (
                <SearchSelectSale searchData={searchSelect} onSearch={onSearch} />
            ) : (
                ''
            )}
            <ComponentWithLoader
                showLoader={stateData.flags.selectSoldsLoading}
                showOverlay={true}
                styleClass="loaderClass"
            />
            <Grid item xs={12} md={12}>
                <Sold
                    isLoading={stateData.flags.selectSoldsLoading}
                    from="selectSales"
                    tableColumn={filteredTable}
                    listings={listings}
                    columnSpan={10}
                    order={orders}
                    //sortcolumn hardcoded since the field have two data
                    //in that particular section
                    orderBy={
                        orderBy === 'address1'
                            ? 'useAddressAsTitle'
                            : orderBy === 'soldPrice'
                            ? 'displayOfferPriceForSelectSold'
                            : orderBy
                    }
                    handleRequestSort={handleRequestSort}
                    handleNavigation={handleNavigation}
                    handleSoldCheckboxChange={handleSoldCheckboxChange}
                    handleSoldRadioButtonChange={handleSoldRadioButtonChange}
                    price={price}
                    loadMore={loadMore}
                    user={user}
                    salesRegionId={salesRegionId}
                    agentId={[
                        (stateData.agentDetails.descriptionDetails as AgentDescription)
                            .agentId,
                    ]}
                />
            </Grid>
        </div>
    );
};

export default SelectSale;
