import { TableHeadCell } from '../../../shared/models/customTable/customTableModels';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { CreateEditPressModel } from './component/createAndEdit/createEditPressModels';
import { PressItemSearchRequest } from './pressListModels';

export const pressItemTableHeadCells: TableHeadCell[] = [
    {
        id: 'date',
        label: 'Date',
        dbColMapping: 'Date',
        isDate: true,
    },
    {
        id: 'pressType',
        label: 'Type',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'listingCount',
        label: 'Listings',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'title',
        label: 'Title',
        dbColMapping: 'Title',
    },
    {
        id: 'agentCount',
        label: 'Agents',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'publish',
        label: 'Published',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'publish',
    },
    {
        id: 'url',
        label: 'Media Link',
        dbColMapping: '',
        customIcon: true,
        actionType: 'redirect',
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
];

export const PressTypes: LookupInfo[] = [
    {
        id: '1',
        name: 'Press Release',
    },
    {
        id: '2',
        name: 'Listings in the Media',
    },
    {
        id: '3',
        name: 'Company in the Media',
    },
];

export const initialPressSearch: PressItemSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    listingId: '',
    agentId: '',
    officeId: '',
    title: '',
    pressTypeId: 0,
    startDate: '',
    endDate: '',
    publish: true,
    sortDirection: 'desc',
    sortColumn: 'Date',
    mediaOutletId: '',
};

export const initialSelectedPress: CreateEditPressModel = {
    id: '',
    isValid: false,
    displayError: false,
};
