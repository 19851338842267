import { combineReducers } from '@reduxjs/toolkit';
import { OtherMarketingState } from '../../features/otherMarketing/otherMarketingModel';
import OtherMarketingListReducer from '../../features/otherMarketing/otherMarketingListSlice';

interface OtherMarketingRootState {
    otherMarketingList: OtherMarketingState;
}

export default combineReducers<OtherMarketingRootState>({
    otherMarketingList: OtherMarketingListReducer,
});
