import { AppThunk } from '../../../app/store';
import { editFormComponent } from './developmentDetailsConstants';
import {
    DevelopmentDetailsData,
    DevelopmentDetailsState,
    UpdateDevelopmentRequest,
} from './developmentDetailsModel';
import loDash from 'lodash';
import {
    closeEditForm,
    saveDevAmenities,
    updateDevelopment,
    updateDevelopmentEditFormName,
    updateMainDevelopmentData,
} from './developmentDetailsSlice';
import {
    developmentPhotoForms,
    savePhotoReorder,
} from './drawer/screens/developmentPhotosEdit/developmentPhotosSlice';
import { ImageData, Image } from '../../../shared/models/images/sharedModel';
import _ from 'lodash';
import { setError } from '../../../shared/slices/messaging/messagingSlice';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../shared/slices/loader/loaderSlice';
import { DevelopmentDetailEvents } from '../../../shared/models/loader/loaderModels';
import { displayErrorForDevelopmentEditScreen } from './drawer/screens/developmentDetailsEdit/developmentDetailsEditUtils';

const isFormDataChanged = (
    currentState: Partial<DevelopmentDetailsData>,
    initialState: Partial<DevelopmentDetailsData>,
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !loDash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

export const removeDetailsUnneededFields = (
    data: DevelopmentDetailsData,
): UpdateDevelopmentRequest => {
    return _.omit(data, [
        'images',
        'floorPlanImages',
        'videoDetail',
        'agents',
        'listings',
        'amenities',
    ]);
};

const updateMainStateOnImageReorder = (
    imageList: ImageData[],
    stateData: DevelopmentDetailsState,
) => {
    const mainStateList: DevelopmentDetailsData = JSON.parse(JSON.stringify(stateData));
    imageList.forEach((x) => {
        const findIndex = mainStateList.images.findIndex((d: Image) =>
            d.imageUrl.includes(x.guid),
        );
        if (findIndex !== -1) {
            mainStateList.images[findIndex].orderNo = x.orderNo;
        }
    });
    mainStateList.images = loDash.sortBy(mainStateList.images, ['orderNo']);
    return mainStateList;
};

// Gets data from the current listing form open in the side drawer component
// and saves it to the db
export const saveDevelopmentEditFormData =
    (state: DevelopmentDetailsState, editFormName?: string): AppThunk =>
    async (dispatch) => {
        let isChanged = true;
        let isValid = true;
        if (state.editForm?.currentFormData) {
            const oldStateAfterRemoval = removeDetailsUnneededFields(state.data);
            const newState = state.editForm?.currentFormData as DevelopmentDetailsData;
            const newStateAfterRemoval = removeDetailsUnneededFields(newState);
            dispatch(addLoadingEvent(DevelopmentDetailEvents.DEVELOPMENT_SAVE));
            switch (state.editForm?.developmentEditFormName) {
                case editFormComponent.developmentDetails:
                case editFormComponent.developmentAdCopy:
                    const {
                        title,
                        officeId,
                        address1,
                        city,
                        regionId,
                        zip,
                        listDate,
                        expirationDate,
                        agreementDate,
                        websiteUrl,
                        totalUnits,
                        totalDollarValue,
                        minimumPricePerUnit,
                    } = newStateAfterRemoval;
                    const result = displayErrorForDevelopmentEditScreen({
                        title,
                        officeId,
                        address1,
                        city,
                        regionId,
                        zip,
                        listDate,
                        expirationDate,
                        agreementDate,
                        websiteUrl,
                        totalUnits,
                        totalDollarValue,
                        minimumPricePerUnit,
                    });
                    isChanged = isFormDataChanged(
                        newStateAfterRemoval,
                        oldStateAfterRemoval,
                    );
                    if (isChanged) {
                        isValid = result.isValid;
                        if (isValid) {
                            dispatch(
                                updateDevelopment(
                                    newStateAfterRemoval as UpdateDevelopmentRequest,
                                ),
                            );
                        } else {
                            dispatch(setError(result.errorMessage));
                        }
                    }
                    break;
                case editFormComponent.developmentFeatures:
                    isChanged = isFormDataChanged(
                        newStateAfterRemoval,
                        oldStateAfterRemoval,
                    );
                    if (isChanged) {
                        dispatch(
                            updateDevelopment(
                                newStateAfterRemoval as UpdateDevelopmentRequest,
                            ),
                        );
                        if (state.isFeaturesUpdated)
                            dispatch(
                                saveDevAmenities(state.data.amenities, state.data.id),
                            );
                    }
                    break;
                case developmentPhotoForms.buildings:
                    dispatch(
                        savePhotoReorder(
                            state.data.id,
                            state.editForm?.currentFormData as ImageData[],
                        ),
                    );
                    const mainStateList: DevelopmentDetailsData =
                        updateMainStateOnImageReorder(
                            state.editForm?.currentFormData as ImageData[],
                            state as DevelopmentDetailsState,
                        );
                    dispatch(
                        updateMainDevelopmentData({
                            data: mainStateList as DevelopmentDetailsData,
                        }),
                    );
                    break;
                case developmentPhotoForms.buildingFloorplans:
                    dispatch(
                        savePhotoReorder(
                            state.data.id,
                            state.editForm?.currentFormData as ImageData[],
                        ),
                    );
                    break;
            }
            dispatch(removeLoadingEvent(DevelopmentDetailEvents.DEVELOPMENT_SAVE));
        }
        if (isValid) {
            if (editFormName) dispatch(updateDevelopmentEditFormName({ editFormName }));
            else dispatch(closeEditForm());
        }
    };
