import { FunctionComponent } from 'react';
import { faEdit, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyFormat, dateFormatMMDDYYY } from '../../../../../../utils/urlUtils';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';
import { SocialCampaignData } from '../../../../../../shared/models/marketing/marketingModels';

interface SocialCampaignReadOnlyProps {
    socialCampaign: SocialCampaignData;
    deleteIt(id: string | undefined): void;
    onEditClick(social: SocialCampaignData): void;
}

const ReadOnly: FunctionComponent<SocialCampaignReadOnlyProps> = (props) => {
    const { socialCampaign } = props;
    const ga = () => {
        googleAnalyticsAction('view detail', 'Click', 'Social Campaign - Builder');
    };
    return (
        <div className="table-data">
            {socialCampaign.fromDate &&
            dateFormatMMDDYYY(socialCampaign.fromDate.toString()) ? (
                <div className="data-dates data-text large">
                    {dateFormatMMDDYYY(socialCampaign.fromDate.toString())}{' '}
                    {socialCampaign.toDate &&
                    dateFormatMMDDYYY(socialCampaign.toDate.toString()) ? (
                        <span>
                            - {dateFormatMMDDYYY(socialCampaign.toDate.toString())}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <div className="data-dates data-text large"></div>
            )}

            {socialCampaign.description &&
            socialCampaign.description.trim().length > 0 ? (
                <div className="data-type data-text large">
                    {socialCampaign.description}
                </div>
            ) : (
                <div className="data-type data-text large"></div>
            )}
            {socialCampaign.potentialReach && !isNaN(socialCampaign.potentialReach) ? (
                <div className="data-potential-reach data-text small">
                    {currencyFormat(socialCampaign.potentialReach)}
                </div>
            ) : (
                <div className="data-potential-reach data-text small"></div>
            )}
            {socialCampaign.views && !isNaN(socialCampaign.views) ? (
                <div className="data-views data-text small">
                    {currencyFormat(socialCampaign.views)}
                </div>
            ) : (
                <div className="data-views data-text small"></div>
            )}
            {socialCampaign.engagements && !isNaN(socialCampaign.engagements) ? (
                <div className="data-engagements data-text large">
                    {currencyFormat(socialCampaign.engagements)}
                </div>
            ) : (
                <div className="data-engagements data-text large"></div>
            )}
            {socialCampaign.clicks && !isNaN(socialCampaign.clicks) ? (
                <div className="data-clicks data-text small">
                    {currencyFormat(socialCampaign.clicks)}
                </div>
            ) : (
                <div className="data-clicks data-text small"></div>
            )}

            <div className="social-campaigns-icons">
                {socialCampaign.url ? (
                    <a href={socialCampaign.url} target="_blank" rel="noreferrer">
                        <span className="file icon" onClick={ga}>
                            <FontAwesomeIcon icon={faFile} size="sm" />
                        </span>
                    </a>
                ) : (
                    ''
                )}
                <span className="edit icon">
                    <FontAwesomeIcon
                        icon={faEdit}
                        size="sm"
                        onClick={() => props.onEditClick(socialCampaign)}
                    />
                </span>
                <span className="trash icon">
                    <FontAwesomeIcon
                        icon={faTrash}
                        size="sm"
                        onClick={() => props.deleteIt(socialCampaign.marketingId)}
                    />
                </span>
            </div>
        </div>
    );
};

export default ReadOnly;
