import { FunctionComponent } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../../app/hooks';
import { schedulerDetails } from '../schedulerSlice';
import { Schedules, ScheduleListingsProps } from '../schedulerModels';
import { ReportEmailSchedule } from '../schedulerConstants';

const ScheduleListings: FunctionComponent<ScheduleListingsProps> = ({
    editClickHandler,
    deleteItem,
}) => {
    const { schedules } = useAppSelector(schedulerDetails);
    const fetchSchedule = (scheduleId: number) => {
        switch (scheduleId) {
            case ReportEmailSchedule.Once:
                return 'Send Now';
            case ReportEmailSchedule.WeeklyMonday:
                return 'Weekly (every Monday)';
            case ReportEmailSchedule.SemiMonthly:
                return 'Semi Monthly';
            case ReportEmailSchedule.Monthly:
                return 'Monthly';
            case ReportEmailSchedule.WeeklyFriday:
                return 'Weekly (every Friday)';
            default:
                return '';
        }
    };

    const getRecipientList = (recipients: string): string[] => {
        if (recipients && recipients.length > 0) {
            return recipients.split(',');
        }
        return [];
    };
    return (
        <div className="scheduleListingHolder">
            <div className="scheduleListingHeader">Current Schedule for the listing</div>
            <div className="scheduleListingContent">
                <div className="schedulerTable">
                    <div className="tableHead">
                        <div className="section">RECIPIENT (S):</div>
                        <div className="section">EMAIL (S):</div>
                        <div className="section">SCHEDULE</div>
                        <div className="icon"></div>
                    </div>
                    <div className="tableBody">
                        {schedules && schedules.length > 0
                            ? schedules.map((sched: Schedules, index: number) => (
                                  <div className="content" key={index}>
                                      <div className="section">
                                          {sched.recipientNames &&
                                          sched.recipientNames.trim().length > 0
                                              ? sched.recipientNames
                                              : ''}
                                      </div>
                                      <div className="section name">
                                          {sched.recipients.trim() &&
                                          getRecipientList(sched.recipients.trim())
                                              .length > 0
                                              ? getRecipientList(
                                                    sched.recipients.trim(),
                                                )?.map((rec: string, index: number) => (
                                                    <div key={index}>{rec}</div>
                                                ))
                                              : ''}
                                      </div>
                                      <div className="section">
                                          {sched.schedule
                                              ? fetchSchedule(sched.schedule)
                                              : ''}
                                      </div>
                                      <div className="icon-container">
                                          <span className="icon action">
                                              <FontAwesomeIcon
                                                  className="arrow"
                                                  icon={faEdit}
                                                  onClick={() =>
                                                      editClickHandler(sched.id || '')
                                                  }
                                              />
                                          </span>
                                          <span className="icon lasticon action">
                                              <FontAwesomeIcon
                                                  className="drag"
                                                  icon={faTrashAlt}
                                                  onClick={() =>
                                                      deleteItem(sched.id || '')
                                                  }
                                              />
                                          </span>
                                      </div>
                                  </div>
                              ))
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScheduleListings;
