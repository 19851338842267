import React, { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import { Radio, RadioGroup, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { ListingDetailsData, ErrorProps } from './listingDetailsModels';
import './listingDetails.scss';
import { DrawerContainerProps } from '../../../listingModels';
import { loadListingDetailsData } from '../../../listingUtils';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import { setCurrentFormData } from '../../../listingSlice';
import {
    editFormComponent,
    // listingStatuses,
    listingTypeDetails,
} from '../../../listingConstants';
import Sale from './listingType/Sale';
import { lookups as lookupsSlice } from '../../../../../../shared/slices/lookups/lookupsSlice';
import {
    CommonLookups,
    ListingLookups,
} from '../../../../../../shared/models/lookups/lookupsModels';
import { shouldDisableField } from '../../../../../../shared/auth/permissions/permissionsService';
import { OwnerInfoModel } from '../../../../../../shared/models/ownerInfoModel/ownerInfoModel';

const useStyles = makeStyles({
    radio: {
        '&$checked': {
            color: '#002349',
        },
        '&&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checked: {},
});

const ListingDetails: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const listingDetailsData: ListingDetailsData = loadListingDetailsData(
        editFormData.data,
    );
    const { common, listing } = useAppSelector(lookupsSlice);
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const {
        formData,
        setFormData,
        handleTextChange,
        handleNumberChange,
        handleCheckboxChange,
        handleCurrencyChange,
        handleFloatChange,
    } = useFormData(listingDetailsData);

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    /**
     * below useEffect is to reload listingDetailsData / formData in case fields change in other edit screen that have
     * dependency with listingDetail screen, need to re run loadListingDetailsData method through this useEffect
     */
    useEffect(() => {
        setFormData(loadListingDetailsData(editFormData.data));
    }, [editFormData.data]);

    const [errorState, setErrorState] = useState<ErrorProps>({
        zip: false,
    });

    const setFormDataToPersist = (ownerInfo?: OwnerInfoModel[]) => {
        /* let formValid = false;
        if (
            formData.address1?.length &&
            formData.city?.length &&
            formData.state?.length &&
            formData.zip?.toString().length === 5 &&
            !errorState.zip &&
            formData.title.length &&
            formData.dealTypeId &&
            (formData.listingStatusId === listingStatuses.sold ||
                validationForOwnerFields(ownerInfo || formData.ownerInfo || []))
        )
            formValid = true; */
        const updatedFormData = {
            ...formData,
            isListingTypeChanged:
                listingDetailsData.listingType !== formData.listingType ? true : false,
            ownerInfo: ownerInfo || formData.ownerInfo,
        };
        dispatch(
            setCurrentFormData({
                formData: updatedFormData,
                listingEditFormName: editFormComponent.listingDetails,
            }),
        );
    };

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="mainMenuContainer">
            <Grid item container md={12} className="info-text">
                * Indicates Required Fields
            </Grid>
            <span className="radioHolder">
                <RadioGroup
                    row
                    onChange={handleTypeChange}
                    onBlur={() => setFormDataToPersist}
                    name="listingType"
                    value={formData.listingType}
                >
                    <FormControlLabel
                        className="formLabel"
                        control={
                            <Radio
                                value={listingTypeDetails.sale}
                                inputProps={{ 'aria-label': 'sale' }}
                                disabled={shouldDisableField()}
                                classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                }}
                            />
                        }
                        label={<div className="label">Sale</div>}
                    />
                    <FormControlLabel
                        className="formLabel"
                        control={
                            <Radio
                                value={listingTypeDetails.rental}
                                inputProps={{ 'aria-label': 'rental' }}
                                disabled={shouldDisableField()}
                                classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                }}
                            />
                        }
                        label={<div className="label">Rental</div>}
                    />
                </RadioGroup>
            </span>
            <Sale
                formData={formData}
                setFormData={setFormData}
                handleTextChange={handleTextChange}
                handleNumberChange={handleNumberChange}
                handleCheckboxChange={handleCheckboxChange}
                handleCurrencyChange={handleCurrencyChange}
                listingInfo={editFormData}
                setFormDataToPersist={setFormDataToPersist}
                errorState={errorState}
                setErrorState={setErrorState}
                commonLookups={common as CommonLookups}
                listingLookups={listing as ListingLookups}
                handleFloatChange={handleFloatChange}
            />
        </div>
    );
};

export default ListingDetails;
