import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
    DesignVault,
    DesignVaultTemplate,
    DesignVaultTemplateFetchResponse,
    PropertyGuidResponse,
} from './designVaultModel';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../shared/slices/loader/loaderSlice';
import Logger from '../../utils/logging/logger';
import { fetchDesignVaultTemplate, fetchPropertyGuid } from './designVaultApi';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import { DesignVaultEvents } from '../../shared/models/loader/loaderModels';
import {
    manageDesignVaultOfficeTemplate,
} from './manage/officeDesignVaultManagerApi';

const initialVaultTemplateState: DesignVault = {
    isLoading: false,
    templates: [],
    propertyGuid: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    officeId: '',
};

export const dvTemplateSlice = createSlice({
    name: 'designVaultTemplate',
    initialState: initialVaultTemplateState,
    reducers: {
        loadDVTemplates: (
            state,
            action: PayloadAction<DesignVaultTemplateFetchResponse>,
        ) => {
            state.templates = action.payload;
        },
        alterDVTemplateData: (
            state,
            action: PayloadAction<any>
        ) => {
            const templates: DesignVaultTemplateFetchResponse = [];
            action.payload.templates.forEach((template: DesignVaultTemplate)=> {
                if(template.id === action.payload['templateId']) {
                    templates.push({...template, isEnabled : action.payload['flag']});
                } else {
                    templates.push(template);
                }

            });
            state.templates = templates;
        },
        loadPropertyGuid: (state, action: PayloadAction<PropertyGuidResponse>) => {
            state.propertyGuid = action.payload.propertyGuid;
            state.address1 = action.payload.address1;
            state.address2 = action.payload.address2;
            state.city = action.payload.city;
            state.country = action.payload.country;
            state.officeId = action.payload.officeId;
        },
        setDefaultValues: (state, action: PayloadAction<undefined>) => {
            return {
                ...state,
                templates: [],
                propertyGuid: '',
                address1: '',
                address2: '',
                city: '',
                country: '',
                officeId: ''
            };
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

/*
For Fetching the most commonly used templates
*/
export const getTemplates =
    (officeId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await fetchDesignVaultTemplate(officeId);
            dispatch(loadDVTemplates(response));
        } catch (e) {
            Logger.error(`Error getting Design Vault Templates: ${JSON.stringify(e)}`);
            dispatch(setError(`Error getting Design Vault Templates`));
        } finally {
            dispatch(setLoading(false));
        }
    };

/*
For Fetching the Property GUID from listing ID
*/
export const getPropertyGuid =
    (listingNumber: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await fetchPropertyGuid(listingNumber);
            if (response.status !== 200 && response.responseText) {
                if (response.status === 404) {
                    dispatch(
                        setError(
                            `This property is not in the sir.com system. Please check that it meets sir.com requirements.​`,
                        ),
                    );
                    dispatch(setDefaultValues(undefined));
                } else {
                    dispatch(setDefaultValues(undefined));
                    throw Error(response.responseText);
                }
            } else {
                dispatch(loadPropertyGuid(response));
            }
        } catch (e) {
            dispatch(
                setError(
                    `This property is not in the sir.com system. Please check that it meets sir.com requirements.​​​​​​​​`,
                ),
            );
            dispatch(setDefaultValues(undefined));
            Logger.error(
                `Error getting property GUID for listing ${listingNumber}: ${JSON.stringify(
                    e,
                )}`,
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

export const manageOfficeTemplate =
    (officeId: string, templateId: number, flag: boolean, templates: DesignVaultTemplateFetchResponse): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(DesignVaultEvents.DESIGN_VAULT_OFFICE_TEMPLATE_ADD));
            const response = await manageDesignVaultOfficeTemplate(officeId, templateId, flag);
            if(response.status == 200) {
                dispatch(alterDVTemplateData({templates, templateId, flag}));
            }
        } catch (e) {
            Logger.error(
                `Error ${flag ? 'enabling' : 'disabling'} Office Design Vault Templates: ${JSON.stringify(e)}`,
            );
            dispatch(setError(`Error ${flag ? 'enabling' : 'disabling'} Office Design Vault Templates`));
        } finally {
            dispatch(
                removeLoadingEvent(DesignVaultEvents.DESIGN_VAULT_OFFICE_TEMPLATE_ADD),
            );
        }
    };

export const { loadDVTemplates, loadPropertyGuid, setDefaultValues, setLoading, alterDVTemplateData } =
    dvTemplateSlice.actions;
export const templates = (state: RootState): DesignVault => state.designVaultTemplate;
export default dvTemplateSlice.reducer;
