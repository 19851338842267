import { SaleTeamTestimonialModel } from '../../../shared/models/testimonial/testimonial';
import { getCall, postCall, putCallRaw } from '../../../utils/api/apiWrapper';
import {
    SalesTeamDetail,
    SalesTeamDescription,
    SalesTeamAssistant,
} from './salesTeamModels';

export function getSalesTeamDetails(id: string): Promise<SalesTeamDetail> {
    return getCall<SalesTeamDetail>(`SalesTeam/${id}`);
}

export function createSalesTeam(
    request: SalesTeamDescription,
): Promise<SalesTeamDescription> {
    return postCall(`SalesTeam`, request);
}

export function updateSalesTeamDescriptionApi(
    description: SalesTeamDescription,
): Promise<Response> {
    return putCallRaw<SalesTeamDescription>(`SalesTeam/${description.id}`, description);
}

export function getSalesTeamAssistants(id: string): Promise<SalesTeamAssistant[]> {
    return getCall<SalesTeamAssistant[]>(`salesteamassistants/${id}/assistants`);
}

export function getSalesTeamTestimonialsApi(
    teamId: string,
): Promise<SaleTeamTestimonialModel[]> {
    return getCall<SaleTeamTestimonialModel[]>(
        `SalesTeamTestimonial/${teamId}/testimonials`,
    );
}

export function saveSalesTeamTestimonialsApi(
    teamId: string,
    data: SaleTeamTestimonialModel[],
): Promise<SaleTeamTestimonialModel[]> {
    return postCall<SaleTeamTestimonialModel[], SaleTeamTestimonialModel[]>(
        `SalesTeamTestimonial/${teamId}/testimonials`,
        data,
    );
}
