import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
    CircularProgress,
    Grid,
    Checkbox,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core';
import loDash from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import VideoTemplate from '../../video/VideoTemplate';
import ComponentWithLoader from '../../loader/ComponentWithLoader';
import { VideoModel, CustomKey, VideoAction } from '../../../models/video/videoModels';
import {
    updateStatus,
    updateVideos,
    uploadVideo,
    videos as videosSlice,
} from '../../../../features/listings/listing/drawer/screens/videos/videoSlice';
import {
    videoTypes,
    getVideoCancellationStatus,
    uploadAction,
} from '../../../constants/video/videoConstants';
import {
    getVideos,
    updateVideo,
    deleteVideo,
    downloadVideo,
} from '../../../slices/video/videoSlice';
import { entityTypes } from '../../../constants/entityTypes';
import { updateMainListingData } from '../../../../features/listings/listing/listingSlice';
import { getAbortController } from '../../../../utils/urlUtils';
import { setError } from '../../../slices/messaging/messagingSlice';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
});

interface VideosUploadProps {
    listingId: string;
    from: string;
}

const VideosUpload: FunctionComponent<VideosUploadProps> = ({ listingId, from }) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { videos, isLoading, downloadedVideo } = useAppSelector(videosSlice);
    const [propertyVideo, setPropertyVideo] = useState<VideoModel>(videos[0]);
    const [SecVideo, setSecVideo] = useState<VideoModel>(videos[1]);
    const [showcaseSiteVideo, setshowcaseSiteVideo] = useState<VideoModel>(videos[2]);
    const [addVideoOne, setAddVideoOne] = useState<VideoModel>(videos[3]);
    const [addVideoTwo, setAddVideoTwo] = useState<VideoModel>(videos[4]);
    const [addVideoThree, setAddVideoThree] = useState<VideoModel>(videos[5]);
    const [videoDownloaded, setVideoDownloaded] = useState('noVideo');
    const [useShowcaseVideo, setUseShowcaseVideo] = useState<boolean>(false);

    //to upload the selected video
    const uploadIt = async (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const inputEvent = event as ChangeEvent<HTMLInputElement>;
        if (!inputEvent.target.files) return;
        const uploadedFile = inputEvent.target.files[0];
        const name = event.target.name;
        const data = uploadAction(uploadedFile, name, listingId);
        if (name === 'secondVideo' && useShowcaseVideo) {
            // upload only if showcase hero video is not present
            if (!showcaseHeroVideoCheck()) {
                const showcaseData = uploadAction(
                    uploadedFile,
                    'showcaseVideo',
                    listingId,
                );
                uploadHandler(showcaseData, videoTypes.showcaseVideo.index);
            }
        }
        //to remove the data when sending it to upload video
        uploadHandler(data, index);
        dispatch(getVideos(listingId, entityTypes.listing.key));
    };

    const uploadHandler = (data: VideoAction, index: number) => {
        const { fromName, videoType, ...updatedData } = data;
        dispatch(
            updateStatus({
                videoType: videoType,
                displayOrder: updatedData.displayOrder,
            }),
        );
        dispatch(
            uploadVideo(
                updatedData,
                fromName,
                getAbortController(
                    getVideoCancellationStatus(
                        videos,
                        videoType,
                        updatedData.displayOrder,
                    ),
                    index,
                ),
            ),
        );
    };

    const showcaseHeroVideoCheck = (): boolean => {
        if (showcaseSiteVideo.url || showcaseSiteVideo.videoId) {
            dispatch(
                setError(
                    'Showcase Hero Video already exists, please delete it and try uploading again',
                ),
            );
            return true;
        }
        return false;
    };

    const checkBoxHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (!showcaseHeroVideoCheck()) {
            setUseShowcaseVideo(event.target.checked);
        }
    };

    const cancelVideo = (video: VideoModel, index: number) => {
        const abortController = getAbortController(
            getVideoCancellationStatus(videos, video.videoTypeId, video.displayOrder),
            index,
        );
        abortController.abort();
        deleteIt(video);
    };
    useEffect(() => {
        const currentVideos = videos.filter((item) => item.id);
        if (currentVideos.length > 0) {
            const videoToUpdate = currentVideos.reduce((prev, curr) =>
                prev.videoTypeId < curr.videoTypeId ? prev : curr,
            );
            const mainStateUpdate: CustomKey = {
                videoDetail: {
                    id: videoToUpdate.id,
                    url: videoToUpdate.url ? videoToUpdate.url : '',
                    entityId: videoToUpdate.entityId,
                    entityTypeId: videoToUpdate.entityTypeId,
                    videoId: videoToUpdate.videoId,
                    videoTypeId: videoToUpdate.videoTypeId,
                    reference: videoToUpdate.reference,
                    status: videoToUpdate.status,
                    caption: videoToUpdate.caption,
                    description: videoToUpdate.description,
                    displayOrder: videoToUpdate.displayOrder,
                    reuploadInterval: 0,
                    isCancelled: false,
                },
            };
            if (from === 'listing') {
                dispatch(updateMainListingData(mainStateUpdate));
            }
        } else {
            const mainStateUpdate: CustomKey = {
                videoDetail: null,
            };
            if (from === 'listing') {
                dispatch(updateMainListingData(mainStateUpdate));
            }
        }
    }, [dispatch, videos]);
    //after upload continous api call to get the results
    useEffect(() => {
        const interval = setInterval(() => {
            const objIndex = loDash.findIndex(videos, function (o) {
                return o.status === 2;
            });
            if (objIndex !== -1) {
                dispatch(getVideos(listingId, entityTypes.listing.key));
            } else {
                clearInterval(interval);
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [videos, dispatch, listingId]);
    //initial api call to get the results
    useEffect(() => {
        dispatch(getVideos(listingId, entityTypes.listing.key));
    }, [dispatch, listingId]);
    //to validate whether the image is being downloaded or not
    useEffect(() => {
        if (downloadedVideo.from !== '') {
            setVideoDownloaded(downloadedVideo.from);
        }
    }, [downloadedVideo]);
    //set status on the inital api call
    useEffect(() => {
        const findIfVideoAvailable = (
            data: VideoModel[],
            videoType: number,
            order: number,
        ) => {
            const video = data.filter((data) => {
                if (order === 0) {
                    return data.videoTypeId === videoType;
                } else {
                    return data.videoTypeId === videoType && data.displayOrder === order;
                }
            });
            return video[0];
        };
        setPropertyVideo({ ...findIfVideoAvailable(videos, 1, 0) });
        setSecVideo({ ...findIfVideoAvailable(videos, 2, 0) });
        setshowcaseSiteVideo({ ...findIfVideoAvailable(videos, 3, 0) });
        setAddVideoOne({ ...findIfVideoAvailable(videos, 4, 1) });
        setAddVideoTwo({ ...findIfVideoAvailable(videos, 4, 2) });
        setAddVideoThree({ ...findIfVideoAvailable(videos, 4, 3) });
    }, [videos]);
    //to download the video
    const downloadIt = (video: VideoModel, from: string) => {
        dispatch(downloadVideo(video, from));
    };
    //to delete the video
    const deleteIt = (video: VideoModel) => {
        dispatch(deleteVideo(video));
    };
    const updateFields = (currentVideo: VideoModel) => {
        dispatch(updateVideo(currentVideo, entityTypes.listing.key));
    };
    const updateInterval = (remainingInterval: number, fileInputName: string) => {
        dispatch(
            updateVideos({
                videoId: '',
                videoTypeId:
                    fileInputName === 'propertyVideo'
                        ? videoTypes.propertyVideo.key
                        : fileInputName === 'secondVideo'
                        ? videoTypes.secondVideo.key
                        : fileInputName === 'showcaseVideo'
                        ? videoTypes.showcaseVideo.key
                        : videoTypes.additionalVideoOne.key,
                reuploadInterval: remainingInterval,
                isCancelled: true,
            }),
        );
    };
    const videoComponent = () => {
        return (
            <div className="videoTourContainer">
                <ComponentWithLoader
                    showLoader={downloadedVideo.from === videoDownloaded ? true : false}
                    showOverlay={true}
                    loadingText="Video is being downloaded"
                />
                <Grid className="checkboxHolder" item md={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label':
                                        'Use 15 second video as Showcase hero video',
                                }}
                                checked={useShowcaseVideo}
                                onChange={checkBoxHandler}
                                name="useShowcaseVideo"
                                classes={{ root: classes.root }}
                                id="useShowcaseVideo"
                            />
                        }
                        label={
                            <div className="checkboxLabel">
                                USE 15 SECOND VIDEO AS SHOWCASE HERO VIDEO (ONLY
                                APPLICABLE PRIOR TO UPLOAD)
                            </div>
                        }
                        labelPlacement="end"
                    />
                </Grid>
                <div className="videoTourHolder">
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder one"
                        >
                            <VideoTemplate
                                fileInputName="propertyVideo"
                                tooltipMessage="<div><div>Property video will be displayed on agent websites, showcase websites, sothebysrealty.com, and partner websites that will display property videos.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li><li>Copyright-free or licensed music required</li></ul></div></div>"
                                videoSectionTitle="Property Video"
                                data={propertyVideo}
                                reuploadInterval={videos[0].reuploadInterval}
                                index={videoTypes.propertyVideo.index}
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateInterval={updateInterval}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder two"
                        >
                            <VideoTemplate
                                fileInputName="secondVideo"
                                tooltipMessage="<div><div>15 second video will be displayed on sothebysrealty.com only.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li><li>Must be exactly 15 seconds</li></ul></div></div>"
                                videoSectionTitle="15 Second Video"
                                data={SecVideo}
                                reuploadInterval={videos[1].reuploadInterval}
                                index={videoTypes.secondVideo.index}
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateInterval={updateInterval}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder one"
                        >
                            <VideoTemplate
                                fileInputName="showcaseVideo"
                                tooltipMessage="<div><div>Showcase website hero video will be displayed on showcase websites only.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li><li>Recommended 15 seconds</li><li>No sound</li></ul></div></div>"
                                videoSectionTitle="showcase Website Hero Video"
                                data={showcaseSiteVideo}
                                reuploadInterval={videos[2].reuploadInterval}
                                index={videoTypes.showcaseVideo.index}
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateInterval={updateInterval}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder one"
                        >
                            <VideoTemplate
                                fileInputName="additionalVideoOne"
                                videoSectionTitle="Additional Showcase Videos"
                                tooltipMessage="<div><div>Additional showcase videos will be displayed on showcase websites only.</div></br><div><div>Video Upload Guidelines</div><ul><li>High resolution, minimum 720p</li><li>Aspect ratio: 16:9</li><li>No logos or graphics</li><li>No text</li><li>No still images or slideshows of images</li></ul></div></div>"
                                data={addVideoOne}
                                videoFields={{ showTitle: true }}
                                reuploadInterval={videos[3].reuploadInterval}
                                index={videoTypes.additionalVideoOne.index}
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateInterval={updateInterval}
                                updateFields={updateFields}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder two"
                        >
                            <VideoTemplate
                                fileInputName="additionalVideoTwo"
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                data={addVideoTwo}
                                videoFields={{ showTitle: true }}
                                reuploadInterval={videos[4].reuploadInterval}
                                index={videoTypes.additionalVideoTwo.index}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateInterval={updateInterval}
                                updateFields={updateFields}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            className="videoActionHolder one"
                        >
                            <VideoTemplate
                                fileInputName="additionalVideoThree"
                                uploadAction={uploadIt}
                                cancelAction={cancelVideo}
                                data={addVideoThree}
                                videoFields={{ showTitle: true }}
                                reuploadInterval={videos[4].reuploadInterval}
                                index={videoTypes.additionalVideoThree.index}
                                downloadAction={downloadIt}
                                deleteAction={deleteIt}
                                updateFields={updateFields}
                                updateInterval={updateInterval}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className="loading">
                <CircularProgress />
            </div>
        );
    };

    return <>{isLoading ? renderLoader() : videoComponent()}</>;
};
export default VideosUpload;
