import { initializeAdCopy } from '../../../../../../shared/utils/listing/adCopyUtil';
import { AdCopySizeDetail, LookupDetail } from './adCopyModels';

export const internetAdCopySize: AdCopySizeDetail = {
    id: 1,
    name: 'Internet Ad Copy',
    showHeadline: false,
    bodyLength: 4000,
};
export const englishLanguage: LookupDetail = {
    id: 1,
    name: 'English',
};
export const initialAdCopy = initializeAdCopy();
