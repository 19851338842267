import { SalesTeamResponse } from './salesTeamListModels';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { Order, TableHeadCell } from '../../shared/models/customTable/customTableModels';

export const initialSalesTeamData: PaginationResponse<SalesTeamResponse> = {
    currentPage: 1,
    totalRecords: 0,
    recordsPerPage: 20,
    results: [],
};

export const salesTeamListInitialSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    sortColumn: 'Name',
    sortDirection: 'asc' as Order,
    teamName: '',
    agentName: '',
    officeId: '',
};

export const salesTeamTableHeadCells: TableHeadCell[] = [
    {
        id: 'imageUrlId',
        label: '',
        dbColMapping: '',
        doSort: false,
        isImage: true,
    },
    {
        id: 'teamName',
        label: 'Team Name',
        dbColMapping: 'Name',
    },
    {
        id: 'officeName',
        label: 'Office Name',
        dbColMapping: 'Office',
    },
    {
        id: 'canShareProperties',
        label: 'Share Properties',
        dbColMapping: 'CanShareProperties',
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: '',
        icon: true,
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
];
