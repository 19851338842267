import React, { FunctionComponent } from 'react';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import 'flickity/css/flickity.css';
import 'flickity-fullscreen/fullscreen.css';
import 'flickity-fullscreen/fullscreen';
import { CarouselData } from '../../models/images/sharedModel';
import './flickityslider.scss';

interface FlickitySliderProps {
    images: CarouselData[];
    flkty: React.MutableRefObject<Flickity | null>;
    isFullScreen: boolean;
    options?: FlickityOptions;
    disableDefaultFullScreen?: boolean;
}

const FlickitySlider: FunctionComponent<FlickitySliderProps> = ({
    images,
    flkty,
    isFullScreen,
    options,
    disableDefaultFullScreen,
}) => {
    const flickityOptions = {
        pageDots: false,
        fullscreen: disableDefaultFullScreen ? false : true,
        setGallerySize: false,
        accessibility: false,
        arrowShape: { x0: 10, x1: 60, y1: 50, x2: 65, y2: 45, x3: 20 },
        prevNextButtons: false,
        ...options,
    };

    return (
        <>
            {images.length > 0 ? (
                <button
                    className={`flickity-button flickity-prev-next-button previous ${
                        isFullScreen ? 'fullscreen-previous' : ''
                    } ${disableDefaultFullScreen ? 'custom-fullscreen-previous' : ''}`}
                    type="button"
                    aria-label="Previous"
                    onClick={() => flkty.current?.previous(false, true)}
                >
                    <svg className="flickity-button-icon" viewBox="0 0 100 100">
                        <path
                            d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z"
                            className="arrow"
                        ></path>
                    </svg>
                </button>
            ) : (
                ''
            )}
            <Flickity
                flickityRef={(c) => {
                    flkty.current = c;
                }}
                options={flickityOptions}
                className={'custom-carousel'}
                elementType={'div'}
            >
                {images.map((data: CarouselData, index: number) => {
                    return data.imageUrl.includes('.pdf') ? (
                        <object
                            aria-label="Pdf floor plan"
                            data={`${data?.imageUrl}#toolbar=0&navpanes=0`}
                            type="application/pdf"
                            className="flickity-pdf"
                        ></object>
                    ) : (
                        <img
                            key={index}
                            className="tst"
                            src={data.imageUrl}
                            alt={'flickity'}
                        />
                    );
                })}
            </Flickity>
            {images.length > 0 ? (
                <button
                    className={`flickity-button flickity-prev-next-button next ${
                        isFullScreen ? 'fullscreen-next' : ''
                    } ${disableDefaultFullScreen ? 'custom-fullscreen-next' : ''}`}
                    type="button"
                    aria-label="Next"
                    onClick={() => flkty.current?.next(false, true)}
                >
                    <svg className="flickity-button-icon" viewBox="0 0 100 100">
                        <path
                            d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z"
                            className="arrow"
                            transform="translate(100, 100) rotate(180) "
                        ></path>
                    </svg>
                </button>
            ) : (
                ''
            )}
        </>
    );
};
export default FlickitySlider;
