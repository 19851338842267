import { FunctionComponent, useState } from 'react';
import { SectionProps, ShowcaseImageSaveRequest } from '../../photoEditorModels';
import {
    photoDetails,
    saveImageDetails,
    updateOrderNo,
    updateTotalImageCount,
    deleteImageDetails,
    updateAllImages,
    saveImageSharePackage,
    downloadImagePackage,
    imageDownload,
    fetchImageTags,
    updateFlag,
    saveImageCaption,
    saveImageTags,
    moveImages,
} from '../../photoEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import PhotoTab from '../../../../../../../../shared/component/photos/PhotoTab';
import { imageTypes } from '../../../../../../../../shared/constants/imageTypes';
import { UpdateFlag } from '../../../../../../../../shared/models/flag/flagModel';
import { setCurrentFormData, listingDetail } from '../../../../../listingSlice';
import {
    ImageData,
    ImageUpdate,
    OrderNoUpdate,
} from '../../../../../../../../shared/models/images/sharedModel';

const ShowcaseHeroImages: FunctionComponent<SectionProps> = ({
    data,
    currentSection,
}) => {
    const photoInfo = useAppSelector(photoDetails);
    const {
        data: { salesRegionId },
    } = useAppSelector(listingDetail);
    const dispatch = useAppDispatch();
    const { currentOrderNo, allImages, flags, types, filter, imageTags } = photoInfo;
    const [imageSelectCount, setImageSelectCount] = useState<number>(0);

    const maxAllowedPhotos = parseInt(types.vanity.maxPhotos) - types.vanity.photosLoaded;

    let activeOrderNo = currentOrderNo.vanity;

    const [sendMainPhotos, setMainPhotos] = useState<boolean>(false);

    const updateOrderNumber = (data: OrderNoUpdate) => {
        dispatch(updateOrderNo(data));
    };

    const updateImageCount = (data: OrderNoUpdate) => {
        dispatch(updateTotalImageCount(data));
    };

    const updateImages = (imageData: ImageUpdate) => {
        dispatch(updateAllImages(imageData));
    };

    const flagUpdate = (data: UpdateFlag, imageType?: string) => {
        dispatch(updateFlag(data));
        if (imageType === imageTypes.MainPhotos) {
            setMainPhotos(true);
        }
        if (imageType === imageTypes.ShowcaseHeroImages) {
            setMainPhotos(false);
        }
    };

    const setFormData = (images: ImageData[], formName: string) => {
        dispatch(
            setCurrentFormData({
                formData: images,
                listingEditFormName: formName,
            }),
        );
    };

    const saveShowcaseImages = async (selectedImagesList: ImageData[]) => {
        const count =
            selectedImagesList.length <= maxAllowedPhotos
                ? selectedImagesList.length
                : maxAllowedPhotos;
        for (let i = 0; i < count; i++) {
            activeOrderNo++;
            const showcaseImage: ShowcaseImageSaveRequest = {
                x: 0,
                y: 0,
                width: selectedImagesList[i].size.width,
                height: selectedImagesList[i].size.height,
                newImageType: imageTypes.ShowcaseHeroImages,
                sourceKey: `photos/${selectedImagesList[i].guid}.jpg`,
            };
            await dispatch(
                moveImages(showcaseImage, selectedImagesList[i], activeOrderNo, false),
            );
            setImageSelectCount(0);
        }
        setMainPhotos(false);
    };

    const getMaxAllowedPhotos = (): number => {
        return parseInt(types.vanity.maxPhotos) - types.vanity.photosLoaded;
    };

    const disableUpload = () => {
        return (
            types.vanity.photosLoaded >= parseInt(types.vanity.maxPhotos) ||
            flags.selectionFromMainPhoto
        );
    };

    return (
        <>
            <PhotoTab
                from="listing"
                imageOrderNo={currentOrderNo.vanity}
                images={sendMainPhotos ? allImages.photos : allImages.vanity}
                isUploading={flags.isUploading}
                currentImageType={imageTypes.ShowcaseHeroImages}
                entityId={data.editFormData.data.id}
                saveImageDetails={saveImageDetails}
                updateOrderNumber={updateOrderNumber}
                updateImageCount={updateImageCount}
                currentImageDetails={types.vanity}
                filter={filter}
                flags={flags}
                deleteImageDetails={deleteImageDetails}
                updateImages={updateImages}
                saveImageSharePackage={saveImageSharePackage}
                downloadImagePackage={downloadImagePackage}
                imageDownload={imageDownload}
                fetchImageTags={fetchImageTags}
                flagUpdate={flagUpdate}
                saveImageCaption={saveImageCaption}
                imageTags={imageTags}
                saveImageTags={saveImageTags}
                entityType="listing"
                setFormData={setFormData}
                currentSection={currentSection}
                saveShowcaseImages={saveShowcaseImages}
                imageSelectCount={imageSelectCount}
                setImageSelectCount={setImageSelectCount}
                maxAllowedPhotos={getMaxAllowedPhotos()}
                disableUpload={disableUpload()}
                salesRegionId={salesRegionId}
                office={data.editFormData.data.office}
            />
        </>
    );
}; 
export default ShowcaseHeroImages;
