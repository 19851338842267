import Highcharts from 'highcharts';
import React from 'react';
import {
    Analytics,
    AnalyticsCounts,
    AnalyticsGraph,
    SiteDetails,
    GraphElement,
    OtherAddedWebsites,
    TotalViewCount,
    ChartOptions,
    WebsiteGraphData,
    GraphData,
    GraphDataLogic,
} from '../../../features/cmr/cmrModels';
import { GraphDate } from '../../../features/cmrReport/cmrReportModel';
import { sortByDate, total, dateFormatUTC } from '../../../utils/urlUtils';
import { googleAnalyticsAction } from '../googleAnalytics/ga';

export const seriesColors = [
    '#002349',
    '#999999',
    '#666666',
    '#B28E3B',
    '#001731',
    '#000000',
    '#F2F2F2',
    '#D6D6D6',
    '#435366',
    '#0E6BD3',
    '#8F6A15',
    '#AF9C6F',
    '#99B7E0',
    '#617083',
    '#174079',
    '#B9D5F2',
    '#F6D892',
    '#ADA799',
    '#645125',
    '#B88206',
    '#B5830E',
    '#858076',
];

export const getInitialChartOptions = (
    setFromDate: React.Dispatch<React.SetStateAction<string | null>>,
    setToDate: React.Dispatch<React.SetStateAction<string | null>>,
    from: string,
    action: string,
    label: string,
): ChartOptions => {
    const initialChartOptions: ChartOptions = {
        chart: {
            type: 'spline',
            zoomType: 'xy',
            backgroundColor: '#fafafa',
            width: null,
            height: null || 0,
            marginRight: 100,
            style: {
                fontFamily: 'BentonSansBook',
            },
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: null,
            },
            zoomType: 'x',
            max: new Date(dateFormatUTC()),
            events: {
                setExtremes: function (e: { min: number; max: number }) {
                    setFromDate(Highcharts.dateFormat('%b %d, %Y', e.min));
                    setToDate(Highcharts.dateFormat('%b %d, %Y', e.max));
                },
            },
            startOnTick: false,
            endOnTick: true,
            tickmarkPlacement: 'on',
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,
            max: 200,
        },

        plotOptions: {
            series: {
                animation: false,
                cursor: 'pointer',
                states: {
                    hover: {
                        lineWidthPlus: 0,
                    },
                },
                showInLegend: false,
            },
        },
        title: {
            text: null,
        },
        series: [],
        credits: {
            enabled: false,
        },
        tooltip: {
            headerFormat:
                '<span style="font-size:12px; font-family:BentonSansMedium;color:#002349">{series.name}</span><br>',
            pointFormat:
                '<br><span style="font-size:12px; font-family:BentonSansRegular;color:#002349">{point.x:%a, %b %e, %Y}</span><br><span style="font-size:12px; font-family:BentonSansMedium;color:#002349">{point.y:.f} </span><br/>',
            borderWidth: 3,
            borderRadius: 6,
        },
        rangeSelector: {
            inputEnabled: false,
            verticalAlign: 'top',
            buttonPosition: {
                align: 'right',
            },
            enabled: true,
            allButtonsEnabled: true,
            buttons: [
                {
                    type: 'all',
                    text: 'All',
                    dataGrouping: {
                        forced: true,
                        units: [['month', [1]]],
                    },
                    events: {
                        click: () => {
                            googleAnalyticsAction(from, action, label + 'All');
                        },
                    },
                },
                {
                    type: 'day',
                    count: 30,
                    text: 'Last 30 days',
                    dataGrouping: {
                        forced: true,
                        units: [['day', [1]]],
                    },
                    events: {
                        click: () => {
                            googleAnalyticsAction(from, action, label + 'Last 30 days');
                        },
                    },
                },
                {
                    type: 'day',
                    count: 7,
                    text: 'Last 7 days',
                    dataGrouping: {
                        forced: true,
                        units: [['day', [1]]],
                    },
                    events: {
                        click: () => {
                            googleAnalyticsAction(from, action, label + 'Last 7 days');
                        },
                    },
                },
            ],
            buttonTheme: {
                height: 15,
                paddingRight: 5,
                paddingLeft: 5,
                width: 70,
                states: {
                    select: {
                        style: {
                            color: '#002349',
                            fontSize: '11px',
                            fontFamily: 'BentonSansRegular',
                        },
                    },
                },
            },
            selected: 3,
        },
    };
    return initialChartOptions;
};

export const getTotalViewCount = (analytics: Analytics): TotalViewCount => {
    const counts = analytics.counts || [];
    const sirSite: AnalyticsCounts | undefined = counts.find(
        (c) => c.siteName === 'sothebysrealty.com',
    );
    const showcaseSites = counts.filter((a) => a.isShowcase);
    const firstTwoRows: AnalyticsCounts[] = [];
    if (sirSite) {
        firstTwoRows.push(sirSite);
    }
    firstTwoRows.push(...showcaseSites);

    const otherSites = counts.filter(
        (analytic) =>
            analytic.totalViews > 0 &&
            analytic.siteName !== 'sothebysrealty.com' &&
            !analytic.isShowcase,
    );

    const viewTotalCount = [...firstTwoRows, ...otherSites].reduce(
        (sum: number, current: AnalyticsCounts) => sum + current.totalViews,
        0,
    );

    return {
        viewTotalCount,
        otherSites,
        firstTwoRows,
    };
};

export const hasZeroViews = (siteName: string, viewsOnWeb: Analytics): boolean => {
    return viewsOnWeb.counts &&
        viewsOnWeb.counts.length > 0 &&
        viewsOnWeb.counts.findIndex(
            (site: AnalyticsCounts) =>
                site.siteName === siteName && site.totalViews === 0,
        ) > -1
        ? true
        : false;
};

export const getOtherAddedSites = (
    viewsOnWeb: Analytics,
    maxIndex: number,
): OtherAddedWebsites => {
    const otherSites: AnalyticsCounts[] = [];
    const addedSites: AnalyticsCounts[] = [];
    if (viewsOnWeb.counts && viewsOnWeb.counts.length > 0) {
        //sothebys and showcase site excluded
        const cloneViews = viewsOnWeb.counts.filter((data: AnalyticsCounts) => {
            if (data.siteName === 'sothebysrealty.com' && !data.isShowcase) {
                return false;
            } else if (data.isShowcase) {
                return false;
            } else {
                return true;
            }
        });
        //adjusting maxIndex based on excluded sites, since excluded sites will be added later
        maxIndex = maxIndex - (viewsOnWeb.counts.length - cloneViews.length);
        cloneViews.forEach((site: AnalyticsCounts, index: number) => {
            if (
                site.totalViews > 0 &&
                site.totalViews <= 10 &&
                index <= maxIndex &&
                site.siteName !== 'sothebysrealty.com' &&
                !site.isShowcase &&
                site.listTrac
            ) {
                otherSites.push(site);
            }
            if (
                index > maxIndex &&
                site.listTrac &&
                site.siteName !== 'sothebysrealty.com' &&
                !site.isShowcase
            ) {
                otherSites.push(site);
            }
            if (!site.listTrac && site.id) {
                addedSites.push(site);
            }
        });
    }
    addedSites.sort((a: AnalyticsCounts, b: AnalyticsCounts) => {
        return b.totalViews - a.totalViews;
    });
    return { first: otherSites, second: addedSites };
};

export const updateDetails = (
    detail: SiteDetails[],
    viewsOnWeb: Analytics,
): SiteDetails[] => {
    const updatedDetails: SiteDetails[] = [];
    detail.map((site: SiteDetails) => updatedDetails.push(site));
    if (viewsOnWeb.counts && viewsOnWeb.counts.length > 0) {
        const { counts } = viewsOnWeb;
        // collected all the sites not present in details array
        const result = counts.filter((count: AnalyticsCounts) => {
            return count.listTrac
                ? detail.findIndex(
                      (site: SiteDetails) => site.siteName === count.siteName,
                  ) === -1
                : false;
        });
        // making views as zero for all those sites for that date
        if (result.length > 0) {
            result.map((count: SiteDetails) =>
                updatedDetails.push({
                    siteName: count.siteName,
                    totalViews: 0,
                } as SiteDetails),
            );
        }
    }
    return updatedDetails;
};

export const addZeroes = (
    viewsOnWeb: Analytics,
    sorted: AnalyticsGraph[],
): AnalyticsGraph[] => {
    const updatedSorted: AnalyticsGraph[] = [];
    if (sorted && sorted.length > 0) {
        sorted.forEach((data: AnalyticsGraph) => {
            const updatedDetails = updateDetails(data.details, viewsOnWeb);
            updatedSorted.push({
                details: updatedDetails,
                eventDate: data.eventDate,
            });
        });
    }
    return updatedSorted;
};

export const otherSitesGraphData = (
    viewsOnWeb: Analytics,
    otherSites: AnalyticsCounts[],
): AnalyticsGraph[] => {
    const otherSortedSites: AnalyticsGraph[] = [];
    viewsOnWeb.graph?.forEach((site: AnalyticsGraph) => {
        const detailArray: SiteDetails[] = [];
        site.details.forEach((detail: SiteDetails) => {
            const name = otherSites.find(
                (element: AnalyticsCounts) => element.siteName === detail.siteName,
            );
            if (name) {
                detailArray.push(detail);
            }
        });
        if (detailArray.length > 0) {
            otherSortedSites.push({
                details: detailArray,
                eventDate: site.eventDate,
            });
        }
    });
    return otherSortedSites;
};

export const getGraphData = (
    sorted: AnalyticsGraph[],
    availableSiteData: WebsiteGraphData[],
    maxElement: GraphElement,
    siteName: string,
    isReport: boolean,
): GraphData => {
    sorted.forEach((data: AnalyticsGraph) => {
        const index = availableSiteData.findIndex(
            (element: WebsiteGraphData) => element.name === siteName,
        );
        if (index > -1) {
            availableSiteData[index].data.push([
                dateFormatUTC(data.eventDate),
                total(data.details, 'totalViews'),
            ]);
            availableSiteData[index].timeStamp.push([
                data.eventDate,
                total(data.details, 'totalViews'),
            ]);
        } else {
            availableSiteData.push({
                name: siteName,
                data: [
                    [dateFormatUTC(data.eventDate), total(data.details, 'totalViews')],
                ],
                timeStamp: [[data.eventDate, total(data.details, 'totalViews')]],
                dataGrouping: {
                    enabled: false,
                },
                color: seriesColors[siteName === 'Visits' ? 0 : 1],
                opacity: isReport ? (siteName === 'Visits' ? 1 : 0.1) : 1,
            });
        }
        if (maxElement.totalView < total(data.details, 'totalViews')) {
            maxElement = {
                totalView: total(data.details, 'totalViews'),
                name: siteName,
            };
        }
    });
    return { first: availableSiteData, second: maxElement };
};

// List of sites that have logos, to simplify logo code and fix intermittent
// issues where they don't show up (esp when printing)
const logoMap = [
    'sothebysrealty.com',
    'homefinder',
    'homes.com',
    'jamesedition',
    'luxuryestate.com',
    'mansion global',
    'realtor.com',
    'trulia',
    'wall street journal',
    'zillow',
    'har.com',
];

export const loadLogo = (count: AnalyticsCounts): string => {
    if (count.isShowcase) {
        return '/logos/sothebysrealty.com.png';
    } else if (logoMap.indexOf(count.siteName) > -1) {
        return `/logos/${count.siteName}.png`;
    } else if (
        count.logo &&
        count.logo.length > 0 &&
        count.id &&
        count.id.length > 0 &&
        !count.listTrac
    ) {
        return count.logo;
    }
    return '';
};

export const loadAltLogo = (count: AnalyticsCounts): string => {
    const logoPath = loadLogo(count);
    if (!logoPath.length) {
        return '';
    }
    return logoPath.replace('/logos/', '/logos/alt');
};

export const graph = (
    viewsOnWeb: Analytics,
    otherSites: AnalyticsCounts[],
): GraphDataLogic => {
    let sorted: AnalyticsGraph[] = [];
    let otherSortedSites: AnalyticsGraph[] = [];

    sorted = sortByDate(viewsOnWeb.graph as AnalyticsGraph[], 'eventDate', true) || [];
    if (viewsOnWeb.counts && viewsOnWeb.counts.length > 0) {
        sorted = addZeroes(viewsOnWeb, sorted);
    }
    if (otherSites && otherSites.length > 0) {
        otherSortedSites = otherSitesGraphData(viewsOnWeb, otherSites);
        if (otherSortedSites.length > 0) {
            otherSortedSites =
                sortByDate(otherSortedSites as AnalyticsGraph[], 'eventDate', true) || [];
        }
    }
    let maxElement: GraphElement = {
        name: '',
        totalView: 10,
    };
    const maxDate: GraphDate = {
        date: '',
    };
    const minDate: GraphDate = {
        date: '',
    };
    let availableSiteData: WebsiteGraphData[] = [];
    if (sorted) {
        const { first, second } = getGraphData(
            sorted,
            availableSiteData,
            maxElement,
            'Visits',
            true,
        );
        availableSiteData = first;
        maxElement = second;
    }
    if (otherSortedSites && otherSortedSites.length > 0) {
        const { first, second } = getGraphData(
            otherSortedSites,
            availableSiteData,
            maxElement,
            'All Other Websites',
            true,
        );
        availableSiteData = first;
        maxElement = second;
    }

    let colorIndex = 2;
    sorted.forEach((data: AnalyticsGraph, index: number) => {
        data.details.forEach((detail: SiteDetails) => {
            const ind = otherSites.findIndex(
                (other: AnalyticsCounts) => detail.siteName === other.siteName,
            );
            if (ind === -1) {
                const index = availableSiteData.findIndex(
                    (element: WebsiteGraphData) =>
                        element.name.toLowerCase() === detail.siteName.toLowerCase() &&
                        !hasZeroViews(detail.siteName.toLowerCase(), viewsOnWeb),
                );
                if (index > -1) {
                    availableSiteData[index].data.push([
                        dateFormatUTC(data.eventDate),
                        detail.totalViews,
                    ]);
                    availableSiteData[index].timeStamp.push([
                        data.eventDate,
                        detail.totalViews,
                    ]);
                } else {
                    availableSiteData.push({
                        name: detail.siteName,
                        data: [[dateFormatUTC(data.eventDate), detail.totalViews]],
                        timeStamp: [[data.eventDate, detail.totalViews]],
                        dataGrouping: {
                            enabled: false,
                        },
                        color: seriesColors[colorIndex],
                        opacity: 1,
                    });
                    colorIndex++;
                }
            }
            if (colorIndex === seriesColors.length - 1) {
                colorIndex = 2;
            }
            if (maxElement.totalView < detail.totalViews) {
                maxElement = {
                    totalView: detail.totalViews,
                    name: detail.siteName,
                };
            }
            if (maxDate.date === '') {
                maxDate.date = data.eventDate;
            }
            if (new Date(maxDate.date) < new Date(data.eventDate)) {
                maxDate.date = data.eventDate;
            }
            if (minDate.date === '') {
                minDate.date = data.eventDate;
            }
            if (new Date(minDate.date) > new Date(data.eventDate)) {
                minDate.date = data.eventDate;
            }
        });
    });

    return {
        sorted: sorted,
        otherSortedSites: otherSortedSites,
        maxDate: maxDate,
        minDate: minDate,
        availableSiteData: availableSiteData,
        maxElement: maxElement,
    };
};

export const formatEventDate = (analytics: Analytics | null): Analytics | null => {
    if (!analytics) return null;
    const { counts, graph } = analytics;
    const updatedGraphData = graph
        ? graph.map((g) => {
              return { ...g, eventDate: g.eventDate.split('T')[0] };
          })
        : null;
    return { counts: counts, graph: updatedGraphData };
};
