import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import Logger from '../../../utils/logging/logger';
import { setError, setSuccess } from '../messaging/messagingSlice';
import { getPropertyMarketingList } from '../../../features/listings/listing/marketing/propertyMarketingListSlice';

import {
    AdvSpotList,
    AdvSpotRequest,
    AgentListingSearchModel,
    ListingAssignment,
    RequestSpotModel,
    SpotBookingLisingTableActionType,
    SpotBookingState,
    SpotMOList,
    SpotMORequest,
    SpotStatsModel,
} from '../../models/spotBooking/spotBookingModel';
import {
    assignListingToSpotFromListingApi,
    fetchAdvSpotListApi,
    fetchIndiSpotStatisticsApi,
    fetchMOForAgentApi,
    searchAgentListingsApi,
    spotRequestApi,
} from '../../api/spotBooking/spotBooking';
import { PaginationResponse } from '../../models/pagination/paginationModels';
import { sortBy } from 'lodash';
import { SpotAssignedListingsResponse } from '../../../features/advertisements/advertisementHolder/viewAdvertisement/component/spotTable/spotTypeModels';

const defaultState = {
    currentPage: 1,
    totalRecords: 0,
    recordsPerPage: 20,
    results: [],
};

const initialSpotBookingState: SpotBookingState = {
    loader: {
        isLoading: false,
        isMOLoading: false,
        isAdvLoading: false,
        isSearching: false,
    },
    spotMediaOuletList: defaultState,
    advSpotList: defaultState,
    agentSelectedSpotStats: undefined,
    searchedListings: { ...defaultState, recordsPerPage: 200 },
    tableAction: {
        currentPage: 1,
        itemsPerPage: 20,
        status: ['Active', 'Pending'],
        sortColumn: 'address1',
        sortDirection: 'asc',
    },
};

export const spotBookingSlice = createSlice({
    name: 'spotBookingSlice',
    initialState: initialSpotBookingState,
    reducers: {
        setLoader: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
            return {
                ...state,
                loader: { ...state.loader, ...action.payload },
            };
        },
        setMOList: (state, action: PayloadAction<PaginationResponse<SpotMOList>>) => {
            let data = [...state.spotMediaOuletList.results, ...action.payload.results];
            if (state.spotMediaOuletList.currentPage >= action.payload.currentPage) {
                data = action.payload.results;
            }
            return {
                ...state,
                spotMediaOuletList: {
                    ...state.spotMediaOuletList,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: data,
                },
            };
        },
        setAdvSpotList: (
            state,
            action: PayloadAction<PaginationResponse<AdvSpotList>>,
        ) => {
            const sorted = sortBy(action.payload.results, 'runDate');
            return {
                ...state,
                advSpotList: {
                    ...state.advSpotList,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: sorted,
                },
            };
        },
        resetAdvSpotList: (state) => {
            return {
                ...state,
                advSpotList: { ...defaultState },
            };
        },
        removeSpotAfterBook: (state, action: PayloadAction<string>) => {
            const updatedAdvSpotList = state.advSpotList.results.filter(
                (data) => data.spotId !== action.payload,
            );
            return {
                ...state,
                advSpotList: {
                    ...state.advSpotList,
                    results: [...updatedAdvSpotList],
                    totalRecords: state.advSpotList.totalRecords - 1,
                },
            };
        },
        updateAgentSelectedSpotStats: (
            state,
            action: PayloadAction<SpotStatsModel | undefined>,
        ) => {
            return {
                ...state,
                agentSelectedSpotStats: action.payload,
            };
        },
        setSearchedListings: (
            state,
            action: PayloadAction<PaginationResponse<SpotAssignedListingsResponse>>,
        ) => {
            let data = [...state.searchedListings.results, ...action.payload.results];
            if (state.searchedListings.currentPage >= action.payload.currentPage) {
                data = action.payload.results;
            }
            return {
                ...state,
                searchedListings: {
                    ...state.searchedListings,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: data,
                },
            };
        },
        updateTableAction: (
            state,
            action: PayloadAction<SpotBookingLisingTableActionType>,
        ) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
    },
});

/*
For Fetching the media outlet list
*/
export const fetchMediaOutletList =
    (data: SpotMORequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader({ isMOLoading: true }));
            const response = await fetchMOForAgentApi(data);
            if (response.results.length) {
                dispatch(setMOList(response));
            }
        } catch (e) {
            Logger.error(`Error in fetching media outlet: ${JSON.stringify(e)}`);
            dispatch(setError(`Error in fetching media outlet`));
        } finally {
            dispatch(setLoader({ isMOLoading: false }));
        }
    };

/**
 * to fetch the advertisement and spot data based on the media outlet id
 */
export const fetchAdvertisementSpotList =
    (data: AdvSpotRequest): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setLoader({ isAdvLoading: true }));
            const response = await fetchAdvSpotListApi(data);
            if (response.results.length) {
                dispatch(setAdvSpotList(response));
            } else {
                //when we do not get any response or all spots booked removed the previous state data
                if (getState().spotBooking.advSpotList.results.length > 0) {
                    dispatch(setAdvSpotList(defaultState));
                }
            }
        } catch (e) {
            Logger.error(
                `Error in fetching advertisement and spot list: ${JSON.stringify(e)}`,
            );
            dispatch(setError(`Error in fetching advertisement and spot list`));
        } finally {
            dispatch(setLoader({ isAdvLoading: false }));
        }
    };

export const assignListingToSpotFromListing =
    (assignListing: ListingAssignment, from: string, listingId?: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader({ isAdvLoading: true, isLoading: true }));
            const response = await assignListingToSpotFromListingApi(assignListing);
            if (response) {
                if (from === 'listing') {
                    dispatch(removeSpotAfterBook(assignListing.spotTypeId));
                    if (listingId) {
                        dispatch(getPropertyMarketingList(listingId));
                    }
                } else if (from === 'paidAdv') {
                    dispatch(fetchSpotStatsandListing(assignListing.spotTypeId));
                }

                dispatch(setSuccess('Listing has been assigned to Spot successfully'));
            }
        } catch (exception) {
            if (exception && (exception as Error).message) {
                dispatch(setError((exception as Error).message));
            } else {
                dispatch(setError('Can not assign listing to the Spot'));
            }

            Logger.error(
                `Can not assign listing to the Spot ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setLoader({ isAdvLoading: false, isLoading: false }));
        }
    };

export const spotRequest =
    (requestData: RequestSpotModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader({ isAdvLoading: true, isLoading: true }));
            const response = await spotRequestApi(requestData);
            if (response.status === 204) {
                dispatch(
                    setSuccess(
                        `Successfully requested the spot. Marketing staff will contact you for more information`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Cannot request a spot, please try agein later`));
            Logger.error(`Failed to request a spot ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setLoader({ isAdvLoading: false, isLoading: false }));
        }
    };

export const fetchSpotStatsandListing =
    (spotId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader({ isSearching: true }));
            const response = await fetchIndiSpotStatisticsApi(spotId);
            if (response.id !== '') {
                dispatch(updateAgentSelectedSpotStats(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to request spot stats`));
            Logger.error(
                `Failed to request a spot stats and listing details ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(setLoader({ isSearching: false }));
        }
    };

export const searchListings =
    (searchListingsRequest: AgentListingSearchModel, loader = true): AppThunk =>
    async (dispatch) => {
        try {
            if (loader) dispatch(setLoader({ isSearching: true }));
            //to get the last advertised date for listings
            const request = { ...searchListingsRequest, isLastAdvertisedRequired: true };
            const response = await searchAgentListingsApi(request);
            if (response.currentPage || response.recordsPerPage) {
                const searchedListings: SpotAssignedListingsResponse[] =
                    response.results.map((listing) => {
                        return {
                            agentName: listing.agentName,
                            address1: listing.address1 || '',
                            address2: listing.address2 || '',
                            businessPurposeTypeId:
                                listing.businessPurposeTypeId.toString(),
                            currentPrice: listing?.currentPrice || 0,
                            id: '',
                            isValid: listing.isValid,
                            publish: listing.publish,
                            listingId: listing.listingId,
                            mlsNumber: listing?.mlsNumber || '',
                            rfgid: '',
                            status: listing.status,
                            listingNumber: listing?.listingNumber || '',
                            officeName: listing?.officeName || '',
                            lastAdvertised: listing.lastAdvertised || '',
                        };
                    });
                const listings: PaginationResponse<SpotAssignedListingsResponse> = {
                    currentPage: response.currentPage,
                    totalRecords: response.totalRecords,
                    recordsPerPage: response.recordsPerPage,
                    results: searchedListings,
                };
                dispatch(setSearchedListings(listings));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the listings`));
        } finally {
            dispatch(setLoader({ isSearching: false }));
        }
    };
export const {
    setMOList,
    setLoader,
    setAdvSpotList,
    removeSpotAfterBook,
    updateAgentSelectedSpotStats,
    setSearchedListings,
    updateTableAction,
} = spotBookingSlice.actions;
export const spotBookingDetails = (state: RootState): SpotBookingState =>
    state.spotBooking;
export default spotBookingSlice.reducer;
