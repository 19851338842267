import { Fragment, FunctionComponent, useState } from 'react';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, deleteData, displayReportToggle } from '../../../cmrSlice';
import { sortByDate } from '../../../../../utils/urlUtils';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import { AdvertisementData, SectionProps } from '../../../cmrModels';
import './index.scss';
import ViewAdvertisment from './components/ViewAdvertisement';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import CustomAdvertisement from '../../../../../shared/component/marketing/advertisement/CustomAdvertisement';

const Advertisements: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const listingId = listingdata.id;

    const sortedAds = sortByDate(
        paneldata.advertisements as AdvertisementData[],
        'issueDate',
    );
    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);

    const [edit, setEdit] = useState('');

    const editAdvertisement = (id: string) => {
        googleAnalyticsAction('Edit', 'Click', 'Advertisement Edit');
        setEdit(id);
    };

    const cancelClick = () => {
        setEdit('');
    };

    const deleteAdvertisement = (id: string) => {
        googleAnalyticsAction('Delete', 'Click', 'Advertisement Delete');
        const url = `listing/${listingId}/marketing/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'id'));
    };

    return (
        <div className="adv-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Advertisement Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={
                    hideDisplayOnReport(9, sortOrder, props.sectionId) ? true : false
                }
            />
            <div className="inner-container">
                <div className="data-container">
                    <CustomAdvertisement
                        formEdit={false}
                        listingId={listingId}
                        cancelClick={cancelClick}
                        sectionId={props.sectionId}
                    ></CustomAdvertisement>
                    <div className="table-heading">
                        <div className="col-1">
                            <div className="date heading-text">date</div>
                            <div className="publications heading-text">publication</div>
                        </div>
                        <div className="links heading-text">link</div>
                        <div className="empty heading-text"></div>
                    </div>
                    <div className="inner-data-container">
                        {sortedAds &&
                            sortedAds.map((adv: AdvertisementData) => (
                                <Fragment key={'editData' + adv.id}>
                                    {edit === adv.id ? (
                                        <CustomAdvertisement
                                            advertisement={adv}
                                            formEdit={true}
                                            cancelClick={cancelClick}
                                            listingId={listingId}
                                            sectionId={props.sectionId}
                                        ></CustomAdvertisement>
                                    ) : (
                                        <ViewAdvertisment
                                            advertisement={adv}
                                            editAdvertisement={editAdvertisement}
                                            deleteAdvertisement={deleteAdvertisement}
                                        />
                                    )}
                                </Fragment>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Advertisements;
