import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    setSuccess,
    setError,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import { OfficeSettingsState, OfficeSettings } from './officeSettingsModels';
import { getOfficeSettings, saveOfficeSettings } from './officeSettingsApi';
import Logger from '../../../../../../utils/logging/logger';

const initialState: OfficeSettingsState = {};

export const officeSettingsSlice = createSlice({
    name: 'officeSettings',
    initialState: initialState,
    reducers: {
        updateSettings: (state, action: PayloadAction<OfficeSettings>) => {
            state.settings = action.payload;
        },
        resetOfficeSettingState: (state) => {
            state.settings = undefined;
        },
    },
});

export const getSettings =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const settings = await getOfficeSettings(id);
            dispatch(updateSettings(settings));
        } catch (exception) {
            Logger.error(`Failed to get office settings`, exception);
            dispatch(setError(`Failed to get office settings`));
        }
    };

export const saveSettings =
    (id: string, settings: OfficeSettings): AppThunk =>
    async (dispatch) => {
        try {
            const updatedSettings = await saveOfficeSettings(id, settings);
            dispatch(updateSettings(updatedSettings));
            dispatch(setSuccess('Office settings saved successfully'));
        } catch (exception) {
            Logger.error(`Failed to save office settings`, exception);
            dispatch(setError(`Failed to save office settings`));
        }
    };

export const { updateSettings, resetOfficeSettingState } = officeSettingsSlice.actions;

export const officeSettings = (state: RootState): OfficeSettingsState =>
    state.office.settings;

export default officeSettingsSlice.reducer;
