interface TabPanelProps {
    children?: React.ReactNode;
    index: number | undefined;
    value: number;
    id: string;
    className?: string;
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, id, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-${index}`}
            aria-labelledby={`${id}-${index}`}
            {...other}
            className={className?.length ? className : ''}
        >
            {children}
        </div>
    );
};
