import { FunctionComponent, useState } from 'react';
import {
    addAgent,
    deleteAgent,
    selectSaleToggle,
    updateAgent,
    updateOrderedAgents,
} from '../agentSectionSlice';
import { splitErrorMessage } from './listAgentConstants';
import { DrawerContainerProps, ListingAgent } from '../../../../listingModels';
import { useAppDispatch } from '../../../../../../../app/hooks';
import {
    addAgentData,
    checkDealType,
    filterListingAgents,
    findTotalSplit,
} from '../agentSearchAndAdd/agentFunction';
import AgentSearchAndAdd from '../agentSearchAndAdd/AgentSearchAndAdd';
import { toggleAgentSelectSale, toggleSuppress } from '../../../../listingSlice';
import { AgentSearchResult } from '../../../../../../../shared/models/agent/agentModel';

const ListAgent: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const listingId = editFormData.data.id;
    const listingAgents = filterListingAgents(editFormData.data.listingAgents, 1);
    const suppressAgent = editFormData.data.suppressAgentImport;
    const displayOrder = listingAgents.length + 1;
    const listingType = editFormData.data.listingType;
    const dealType = checkDealType(editFormData.data.dealType, 1);
    const [error, setError] = useState<string>('');
    let totalSplit =
        listingAgents.length > 0
            ? listingAgents.reduce((acc, current) => acc + current.split, 0)
            : 0;

    const sendSelectedAgent = (agent: AgentSearchResult) => {
        if (agent.agentId !== '' && listingAgents.length < 20) {
            const add = addAgentData(
                agent,
                1,
                displayOrder,
                editFormData.data.listingAgents,
            );
            dispatch(addAgent(listingId, add));
        }
    };

    const sendOrderedAgents = (sellerSideAgents: ListingAgent[]) => {
        const buyerSideAgents = filterListingAgents(editFormData.data.listingAgents, 2);
        dispatch(
            updateOrderedAgents(listingId, [...sellerSideAgents, ...buyerSideAgents], 1),
        );
    };

    const updateFormFields = (agent: ListingAgent, id?: string) => {
        const update: ListingAgent = { ...agent, id: id, split: Number(agent.split) };
        if (totalSplit === 100) {
            dispatch(updateAgent(listingId, update, true, totalSplit));
        } else if (totalSplit < 100) {
            dispatch(updateAgent(listingId, update, false, totalSplit));
        }
    };

    const totalSplitHandler = (agentId: string, split: number) => {
        const totalSplitAmount = findTotalSplit(listingAgents, agentId, split);
        totalSplit = totalSplitAmount;
        if (totalSplitAmount > 100) {
            setError(splitErrorMessage);
        }
    };

    const updateSelectSale = (agentId: string, selectSale: boolean) => {
        dispatch(toggleAgentSelectSale(agentId));
        dispatch(selectSaleToggle(listingId, agentId, selectSale));
    };

    const deleteSelectedAgent = (agent: ListingAgent) => {
        dispatch(deleteAgent(listingId, agent));
    };

    const handleSuppressAgent = (status: boolean) => {
        dispatch(toggleSuppress(listingId, status));
    };

    return (
        <AgentSearchAndAdd
            title={'LIST SIDE'}
            initialState={listingAgents}
            total={totalSplit}
            dealType={dealType}
            listingType={listingType}
            suppressAgent={suppressAgent}
            handleSuppressAgent={handleSuppressAgent}
            sendSelectedAgent={sendSelectedAgent}
            sendOrderedAgents={sendOrderedAgents}
            updateFormFields={updateFormFields}
            updateSelectSale={updateSelectSale}
            deleteSelectedAgent={deleteSelectedAgent}
            totalSplitHandler={totalSplitHandler}
            errorMessage={error}
        />
    );
};

export default ListAgent;
