import { TableHeadCell } from '../../../../../../shared/models/customTable/customTableModels';
import { PaginationRequest } from '../../../../../../shared/models/pagination/paginationModels';
import { initialAssignListingSearch } from './assignListingReserveSpots/reservationHolder/component/assignListing/assignListingConstant';
import {
    AssignListingSearchRequestModel,
    SpotListingSearchRequest,
    SpotCreateRequest,
} from './spotTypeModels';

export const initialSpotTypesData: SpotCreateRequest = {
    advertisementId: '',
    name: '',
    description: '',
    numberOfSpots: 0,
    numberOfWaitListSpots: 0,
    billingCodeType: '',
    canAgentRequest: false,
    costPerSpot: 0,
    agentCost: 0,
    officeCost: 0,
    minimumPrice: 0,
    officeIds: [],
};

export const spotTypesTableHeadCells: TableHeadCell[] = [
    {
        id: 'name',
        label: 'Spot Name',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'numberOfSpots',
        label: 'Number of Spots',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'numberOfSpotsReserved',
        label: 'Number of Spots Reserved',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'availableSpots',
        label: 'Number of Available Spots',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'canAgentRequest',
        label: 'Can Agent Request',
        dbColMapping: '',
        customIcon: true,
        actionType: 'agentRequest',
        doSort: false,
    },
    {
        id: 'costPerSpot',
        label: 'Cost Per Spot',
        dbColMapping: '',
        doSort: false,
        isPrice: true,
    },
    {
        id: 'viewAssignments',
        label: 'View Assignments',
        dbColMapping: '',
        customIcon: true,
        actionType: 'viewAssignments',
        doSort: false,
    },
    {
        id: 'bookOpenSpots',
        label: 'Book Open Spots',
        dbColMapping: '',
        customIcon: true,
        actionType: 'bookOpenSpots',
        doSort: false,
    },
    {
        id: 'reserveSpots',
        label: 'Reserve Spots',
        dbColMapping: '',
        customIcon: true,
        actionType: 'reserveSpots',
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
];
export const spotTypeAssignedListingsTableHeadCells: TableHeadCell[] = [
    {
        id: 'address1',
        label: 'Address',
        dbColMapping: 'Address1',
    },
    {
        id: 'currentPrice',
        label: 'Current Price',
        dbColMapping: 'CurrentPrice',
        isPrice: true,
    },
    {
        id: 'businessPurposeTypeId',
        label: 'Type',
        dbColMapping: 'BusinessPurposeTypeId',
    },
    {
        id: 'isValid',
        label: 'Data Complete',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'isValid',
    },
    {
        id: 'mlsNumber',
        label: 'MLS Number',
        dbColMapping: 'MlsNumber',
    },
    {
        id: 'rfgid',
        label: 'RFGID',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: 'Status',
    },
    {
        id: 'agentName',
        label: 'Agent Name',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'reservedSectionGroupText',
        label: 'Group Indicator',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'id',
        label: 'Remove Listing',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'delete',
    },
];

export const reservedSpotListTableHeadCells: TableHeadCell[] = [
    {
        id: 'reservedFor',
        label: 'Agent/Office',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'details',
        label: 'Office Name / Agent Name',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'numberOfSpotsReserved',
        label: 'No Of Spots Reserved',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'reservedSectionGroupText',
        label: 'Group Indicator',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'assignListing',
        label: 'Assign Listing',
        dbColMapping: '',
        customIcon: true,
        actionType: 'assignListing',
        doSort: false,
    },
    {
        id: 'edit',
        label: 'Edit Reservation',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
    {
        id: 'id',
        label: 'Remove Reservation',
        dbColMapping: '',
        customIcon: true,
        actionType: 'delete',
        doSort: false,
    },
];

export const OfficeEntity = 3;
export const AgentEntity = 4;

export const initialSpotTableAction: AssignListingSearchRequestModel = {
    ...initialAssignListingSearch,
    sortColumn: 'address1',
    sortDirection: 'asc',
};

export const initialAssignedListingsTableAction: SpotListingSearchRequest = {
    currentPage: 1,
    itemsPerPage: 10,
    sortColumn: 'currentPrice',
    sortDirection: 'desc',
};

export const initialAgentCostTableAction: PaginationRequest = {
    currentPage: 1,
    itemsPerPage: 10,
};
