import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../agentModels';
import SelectSale from '../../../shared/selectSale/SelectSale';

const SelectRentals: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    return (
        <div className="select-rentals-container">
            <SelectSale businessPurposeTypeId={2} stateData={editFormData} />
        </div>
    );
};

export default SelectRentals;
