import React, { FunctionComponent, useEffect } from 'react';
import { useFormData } from '../../../../../app/hooks';
import { Grid, TextField } from '@material-ui/core';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { SearchComponentProps, RangeSearch } from '../../advancedSearchModels';
import SearchFieldWrapper from './SearchFieldWrapper';

const NumberRangeSearch: FunctionComponent<SearchComponentProps> = ({
    criteria,
    onCriteriaAdd,
}) => {
    const initialState: RangeSearch = { start: '', end: '' };
    const { formData, setFormData, handleTextChange } = useFormData(initialState);

    useEffect(() => {
        if (criteria.values?.length) {
            setFormData({
                start: criteria.values[0] || '',
                end: criteria.endValue || '',
            });
        }
    }, [criteria.values, setFormData]);

    const handleCriteriaUpdate = () => {
        onCriteriaAdd({
            ...criteria,
            values: [formData.start || ''],
            endValue: formData.end,
        });
    };

    const renderRow = (label: string, name: string, property: string): JSX.Element => {
        return (
            <FormRow
                label={label}
                showLabel={false}
                control={
                    <TextField
                        id={`${label}-field.fieldName`}
                        name={name}
                        className="text-search"
                        onBlur={handleCriteriaUpdate}
                        onChange={handleTextChange}
                        value={formData[property as keyof typeof formData] as string}
                        InputProps={{
                            disableUnderline: true,
                            className: 'inputBaseRoot',
                        }}
                        placeholder={label}
                    />
                }
                labelMd={12}
                controlMd={6}
            />
        );
    };

    const component = (): JSX.Element => {
        return (
            <Grid
                item
                container
                className="content"
                alignItems="center"
                spacing={2}
                md={12}
            >
                {renderRow('Min', 'start', 'start')}
                {renderRow('Max', 'end', 'end')}
            </Grid>
        );
    };

    return <SearchFieldWrapper control={component()} label="" />;
};

export default NumberRangeSearch;
