export const qcMessages = [
    {
        justificationName: 'Aesthetic',
        translation: 'Photo is not an aesthetic fit for Property Display Feed.',
    },
    {
        justificationName: 'Appear Blurry',
        translation:
            'One or more photos appear blurred, overexposed or stretched. Photo size(s) and/or ratio(s) may be incorrect.',
    },
    {
        justificationName: 'Audio Track Missing',
        translation:
            'Add a fully licensed or royalty-free background audio track to the video.',
    },
    {
        justificationName: 'Brand Aesthetic',
        translation: 'Please remove any photos that do not meet the brand aesthetic.',
    },
    {
        justificationName: 'Branding Violation',
        translation:
            'Remove or edit out any non-compliant branding elements from the video.',
    },
    {
        justificationName: 'Clear/Crisp',
        translation: 'Photo is not clear/crisp enough for the Property Display Feed.',
    },
    {
        justificationName: 'Copy',
        translation:
            'Photo contains text and/or graphics, which is not allowed in the Property Display Feed.',
    },
    {
        justificationName: 'Copy on Images',
        translation: 'One or more photos have text and/or graphics that are not allowed.',
    },
    {
        justificationName: 'Detailed Listing Description',
        translation: 'Provide a detailed listing description.',
    },
    {
        justificationName: 'Development Logo',
        translation: 'Upload a development logo.',
    },
    {
        justificationName: 'Does Not Have sufficient media',
        translation: 'Please add more photos.',
    },
    {
        justificationName: 'Doesnt have sufficient media',
        translation: 'Please add more photos.',
    },
    {
        justificationName: 'Duplicates',
        translation: 'Remove duplicate image(s).',
    },
    {
        justificationName: 'Exclusive Agent',
        translation:
            'The agent submitted is not the exclusive listing agent for this development.',
    },
    {
        justificationName: 'Exclusive Listing Agreement',
        translation: 'Upload a signed Exclusive Listing Agreement.',
    },
    {
        justificationName: 'External Website',
        translation: 'Provide an external website for this development.',
    },
    {
        justificationName: 'Feature was not manually approved',
        translation: 'Other - Please Contact App Support (Feature not approved).',
    },
    {
        justificationName: 'General - DNF',
        translation:
            'This property does not meet the criteria for Property Display Feed, no other display will be affected.',
    },
    {
        justificationName: 'Improper Property Lines',
        translation:
            'The property lines within one or more listing photo(s) are not formatted properly.',
    },
    {
        justificationName: 'Improper Watermarks',
        translation:
            'The property lines within one or more listing photo(s) are not formatted properly.',
    },
    {
        justificationName: 'Incorrect Aspect Ratio',
        translation:
            'The video content is not in a 16:9 aspect ratio (imagery considered only).',
    },
    {
        justificationName: 'Incorrect Edit Point',
        translation: 'Video has bad transitions, beginning and/or ending points.',
    },
    {
        justificationName: 'Invalid Boat Slip',
        translation:
            'Boat Slip does not qualify for global exposure on SIR.com unless they meet other criteria.',
    },
    {
        justificationName: 'Invalid Property subtype',
        translation: 'Other - Please Contact App Support (Property Sub-Type).',
    },
    {
        justificationName: 'Invalid URL',
        translation: 'The URL provided does not function.',
    },
    {
        justificationName: 'Land Criteria Not Met',
        translation: 'Display Criteria is not met (Land).',
    },
    {
        justificationName: 'Listing Appears Commercial',
        translation:
            'Listing appears to be Commercial and will not be featured on the main brand site.',
    },
    {
        justificationName: 'Logo not compliant',
        translation: 'The provided logo is not compliant with brand standards.',
    },
    {
        justificationName: 'Logo use is not compliant',
        translation: 'The provided logo is not compliant with brand standards.',
    },
    {
        justificationName: 'Low Resolution Video',
        translation: 'Minimum video resolution not met (720p).',
    },
    {
        justificationName: 'Map/Satellite Image',
        translation: 'One or more photos contain a map/satellite image.',
    },
    {
        justificationName: 'Name alone',
        translation: `The name "Sotheby's" alone may not refer to any real estate business.`,
    },
    {
        justificationName: 'No Abbreviation',
        translation: 'It is not acceptable to abbreviate any part of your company name.',
    },
    {
        justificationName: 'Non-compliant photo',
        translation: 'One or more photos are non-compliant.',
    },
    {
        justificationName: 'Non-Compliant Rendering',
        translation: 'Rendering submitted is not acceptable for display.',
    },
    {
        justificationName: 'On-Screen Text or Logos',
        translation: 'Videos should contain on-screen text or logos.',
    },
    {
        justificationName: 'Other',
        translation: 'Other - Reach out to QC',
    },
    {
        justificationName: 'Out-dated Brand Logo',
        translation: 'The provided brand logo is out-dated.',
    },
    {
        justificationName: 'Photo #1',
        translation: 'Photo #1 is not compliant.',
    },
    {
        justificationName: 'Photo Number 1',
        translation: 'Photo #1 is not compliant.',
    },
    {
        justificationName: 'Photo Quality',
        translation: 'Minimum number of high-resolution photos not provided.',
    },
    {
        justificationName: 'Photo Sequencing',
        translation: 'Photo sequence is not appropriate to the listing description.',
    },
    {
        justificationName: 'Poor Edit Point',
        translation: 'Video has bad transitions, beginning and/or ending points.',
    },
    {
        justificationName: 'Potentially Unlicensed Audio Track',
        translation:
            'Contact and confirm with QC If there is full/unrestricted rights to the song(s) used in the video.',
    },
    {
        justificationName: 'Select Another Photo',
        translation:
            'Photo 1 for this listing does not meet Property Display Feed standards, but there are other photos associated with this listing that do. Select a different image as photo #1 for consideration.',
    },
    {
        justificationName: 'Shaky Cam',
        translation: 'Video has shaky camera work.',
    },
    {
        justificationName: 'Show on Internet is false',
        translation: 'Property is not published.',
    },
    {
        justificationName: 'Staging/Curb Appeal',
        translation: 'Curb appeal and/or staging does not meet brand standard.',
    },
    {
        justificationName: 'Still Image Usage',
        translation: 'Video contains still-image(s).',
    },
    {
        justificationName: 'Two images in one',
        translation: 'One or more photos have multiple images combined into one.',
    },
    {
        justificationName: 'Video Length',
        translation:
            'Videos are not required to be a specific length, but we highly advise you create a version of this video that is between 1.5 - 3 minutes.',
    },
    {
        justificationName: 'Wrong Size',
        translation: 'One or more photos are too small.',
    },
];
