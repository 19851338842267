import React, { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import ShowingsTemplate from '../../../../../../shared/component/listing/showingsTemplate/ShowingsTemplate';
import { Showing } from '../../../../../../shared/models/openHouseShowings/openHouseShowingsModels';
import { sortByDate } from '../../../../../../utils/urlUtils';
import { DrawerContainerProps } from '../../../listingModels';
import {
    deleteShowing,
    getShowings,
    postOrPutShowing,
    showings as showingsSlice,
    updateShowingActiveId,
} from './showingsSlice';
import './showings.scss';

const Showings: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const initialState: Showing = {
        listingId: editFormData.data.id,
        listingNumber: editFormData.data.id,
        showingDate: '',
        buyer: '',
        buyerAgents: '',
        location: '',
        notes: '',
        feedback: '',
        count: 0,
    };
    const { showings, showingActiveId } = useAppSelector(showingsSlice);
    const sortedShowings = sortByDate(showings as Showing[], 'showingDate');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!showings.length) {
            dispatch(getShowings(editFormData.data.id));
        }
    }, [showings, editFormData.data.id, dispatch]);

    const deleteIt = (id: string) => {
        dispatch(deleteShowing(id));
    };

    const editClickHandler = (currentEditId: string) => {
        if (showingActiveId === currentEditId) {
            dispatch(updateShowingActiveId(''));
        } else if (currentEditId) {
            dispatch(updateShowingActiveId(currentEditId));
        }
    };
    const handleSaveClick = (showingData: Showing) => {
        dispatch(postOrPutShowing(showingData));
    };
    return (
        <div className="showings-container-listing">
            <ShowingsTemplate
                useGa={false}
                showings={sortedShowings}
                activeEditId={showingActiveId}
                deleteIt={deleteIt}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
            />
        </div>
    );
};

export default Showings;
