import { DatePicker } from 'antd';
import { Input } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useState, MouseEvent } from 'react';
import { useAppDispatch, useFormData } from '../../../../app/hooks';
import { useFileManagement } from '../../../marketing/hooks';
import {
    saveEmailCampaign,
    deleteMarketingFile,
} from '../../../../features/cmr/cmrSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';
import { EmailCampaignData } from '../../../models/marketing/marketingModels';
import { datePickerFormat } from '../../../../utils/urlUtils';

interface CustomEmailCampaignProps {
    /* Email campaign data passed from parent component during edit */
    emailCampaign?: EmailCampaignData;
    /* Emit function when cancel button is clicked */
    cancelClick(): void;
    /* Indicates whether the form is in edit mode */
    formEdit?: boolean;
    /* Guid of the listing */
    listingId?: string;
    /* If defined, use it for CMR, otherwise for property marketing */
    sectionId?: number;
}

const initialEmailCampaign: EmailCampaignData = {
    id: '',
    date: '',
    description: '',
    views: 0,
    clicks: 0,
    audience: '',
    emailsSent: 0,
    isFile: false,
    file: undefined,
    url: '',
};

const CustomEmailCampaign: FunctionComponent<CustomEmailCampaignProps> = ({
    emailCampaign,
    formEdit,
    cancelClick,
    listingId,
    sectionId,
}) => {
    const dispatch = useAppDispatch();
    const {
        formData,
        setFormData,
        handleTextChange,
        handleDateChange,
        handleNumberChange,
        removeSpaces,
    } = useFormData(emailCampaign || initialEmailCampaign);
    const {
        fileData,
        handleFileUpload,
        handleUrlChange,
        ensureHttp,
        setFileData,
        handleFileClear,
    } = useFileManagement(formData);
    const [active, setActive] = useState(true);

    useEffect(() => {
        setFormData(formData);
        setFileData(fileData);
        const { description, date } = formData;
        setActive(!description || description === '' || date === '');
    }, [formData, fileData, setFileData, setFormData]);

    const deleteFile = () => {
        dispatch(deleteMarketingFile(formData, sectionId));
    };

    const save = (e: MouseEvent<HTMLButtonElement>) => {
        const saveRequest = { ...formData, ...fileData };
        dispatch(
            saveEmailCampaign(listingId || '', saveRequest, sectionId ? true : false),
        );
        setFormData(initialEmailCampaign);
        handleFileClear(e, false);
        if (sectionId) {
            if (!formEdit) googleAnalyticsAction('Add', 'Click', 'Email Campaign Add');
            else googleAnalyticsAction('Edit', 'Click', 'Email Campaign Edit Save');
        }
        cancelClick();
    };

    const renderFileUploadFields = () => {
        return (
            <>
                <span className="actionHolder">
                    <Input
                        className={formEdit ? 'cmrUrlInput' : 'cmrInput'}
                        placeholder="Url"
                        name="url"
                        onChange={handleUrlChange}
                        onBlur={ensureHttp}
                        inputProps={{ 'aria-label': 'Url' }}
                        value={fileData.url || ''}
                        disableUnderline={sectionId ? false : true}
                    />
                </span>
                <span className="actionHolder ecFileHolder">
                    {!fileData.file && !fileData.isFile ? (
                        <label className="ecFile">
                            Upload File
                            <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                            <Input
                                className={
                                    formEdit ? 'cmrUrlInput file' : 'cmrInput file'
                                }
                                name="fileUpload"
                                inputProps={{
                                    'aria-label': 'File Upload',
                                    accept: '.jpg,.png,.pdf',
                                }}
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </label>
                    ) : (
                        <label className="ecFile">
                            <button
                                onClick={(e) =>
                                    handleFileClear(e, formEdit || false, deleteFile)
                                }
                                className="removeFile"
                            >
                                Remove File
                            </button>
                            <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                        </label>
                    )}

                    <span className="spec">
                        File Format: JPG, PNG, PDF | File Size: 50MB
                    </span>
                </span>
            </>
        );
    };

    return (
        <div>
            {formEdit && sectionId ? <div className="overlayEditForm" /> : ''}
            <div className={formEdit ? 'edit-form-container' : 'form-container'}>
                <div className={formEdit ? 'edit-email-campaign' : 'add-email-campaign'}>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <DatePicker
                            value={
                                formData.date && formData.date !== ''
                                    ? moment(formData.date, 'YYYY-MM-DDTHH:mm:ss')
                                    : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('date', date);
                            }}
                            name="date"
                            placeholder="Date"
                            showToday={false}
                            className={
                                formEdit
                                    ? 'datePickerCommon cmrEditDate'
                                    : 'datePickerCommon cmrDate'
                            }
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput editName' : 'cmrInput'}
                            placeholder="Name"
                            name="description"
                            value={formData.description || ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            onBlur={(e) => removeSpaces(e, 255)}
                            inputProps={{ 'aria-label': 'Name' }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={
                                formEdit ? 'cmrEditInput editEmailSent' : 'cmrInput'
                            }
                            placeholder="Emails sent"
                            name="emailsSent"
                            value={formData.emailsSent || ''}
                            onChange={handleNumberChange}
                            inputProps={{ 'aria-label': 'Emails sent', maxLength: 10 }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput editViews' : 'cmrInput'}
                            placeholder="Total Views"
                            name="views"
                            onChange={handleNumberChange}
                            value={formData.views || ''}
                            inputProps={{ 'aria-label': 'Total Views', maxLength: 10 }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput editClicks' : 'cmrInput'}
                            placeholder="Total clicks"
                            name="clicks"
                            onChange={handleNumberChange}
                            value={formData.clicks || ''}
                            inputProps={{ 'aria-label': 'Total clicks', maxLength: 10 }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={
                                formEdit ? 'cmrEditInput editAudience' : 'cmrInput'
                            }
                            placeholder="Target Audience"
                            name="audience"
                            onChange={(e) => handleTextChange(e, 500)}
                            onBlur={(e) => removeSpaces(e, 500)}
                            value={formData.audience || ''}
                            inputProps={{ 'aria-label': 'Target Audience' }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>

                    {!formEdit ? renderFileUploadFields() : ''}
                </div>

                {formEdit ? (
                    <div className="editFileHolder">{renderFileUploadFields()}</div>
                ) : (
                    ''
                )}

                {!formEdit ? (
                    <div className="addButtonHolder">
                        <button
                            disabled={active ? true : false}
                            className={`addButton ${active ? 'active' : 'unActive'}`}
                            onClick={save}
                        >
                            Add
                        </button>
                    </div>
                ) : (
                    <div className={formEdit ? 'editButtonHolder' : ''}>
                        <button className="actionButton cancel" onClick={cancelClick}>
                            Cancel
                        </button>
                        <button
                            disabled={active ? true : false}
                            className={
                                !active
                                    ? 'actionButton saveActive'
                                    : 'actionButton saveInActive'
                            }
                            onClick={save}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomEmailCampaign;
