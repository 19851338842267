import { Checkbox, Grid, TextField } from '@material-ui/core';
import type { Identifier, XYCoord } from 'dnd-core';
import { ChangeEvent, FC, useEffect } from 'react';
import { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { showCaseWebsiteStyle } from '../../../showcaseWebsite/style';
import { useFormData } from '../../../../../../../../app/hooks';
import { ListingAgent } from '../../../../../listingModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { itemTypes } from '../../../../../../../../shared/constants/dragAndDrop/itemTypes';

export interface GridItemProps {
    index: number;
    gridItem: ListingAgent;
    moveGrid: (dragIndex: number, hoverIndex: number, isDropped: boolean) => void;
    updateFormFields: (agent: ListingAgent, id?: string) => void;
    updateSelectSale: (agentId: string, selectSale: boolean) => void;
    deleteSelectedAgent: (agent: ListingAgent, id?: string) => void;
    totalSplitHandler: (agentId: string, split: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const GridItem: FC<GridItemProps> = ({
    index,
    gridItem,
    moveGrid,
    updateFormFields,
    updateSelectSale,
    deleteSelectedAgent,
    totalSplitHandler,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: itemTypes.listingAgent,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveGrid(dragIndex, hoverIndex, false);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }, // Remove from here
        drop(item: DragItem) {
            const dragIndex = item.index;
            const hoverIndex = index;
            moveGrid(dragIndex, hoverIndex, true);
        },
    });

    const id = gridItem.id;

    const [, drag] = useDrag({
        // Changed
        type: itemTypes.listingAgent,
        item: () => {
            return { id, index };
        },
        collect: (monitor: DragSourceMonitor) => ({
            //change
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const classes = showCaseWebsiteStyle();
    const { formData, setFormData, handleTextChange } = useFormData(gridItem);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, agentId: string) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        updateSelectSale(formData.agentId, e.target.checked);
    };

    useEffect(() => {
        setFormData(gridItem);
    }, [setFormData, gridItem]);

    return (
        <Grid
            className="agent-bottom"
            item
            container
            md={12}
            data-handler-id={handlerId}
            key={gridItem.id}
            ref={ref}
            spacing={2}
        >
            <Grid item md={1} sm={1} className="dndBody">
                {index + 1}
            </Grid>
            <Grid item md={2} sm={2} className="dndBody">
                {gridItem.name}
            </Grid>
            <Grid item md={4} sm={4} className="dndBody">
                {gridItem.office}
            </Grid>
            <Grid item md={2} sm={2} className="dndBody">
                <TextField
                    size="small"
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: 'off',
                    }}
                    // value={Number(formData.split).toFixed(2)}
                    value={formData.split}
                    onChange={(e) => {
                        totalSplitHandler(formData.agentId, Number(e.target.value));
                        handleTextChange(e);
                    }}
                    onBlur={() => updateFormFields(formData, id)}
                    name="split"
                    className="inputBaseRoot split "
                />
            </Grid>
            <Grid item md={2} sm={2} className="dndBody">
                <Checkbox
                    classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                    }}
                    name="selectSale"
                    onChange={(e) => handleCheckboxChange(e, formData.agentId)}
                    checked={formData.selectSale}
                />
            </Grid>
            <Grid item md={1} sm={1}>
                <FontAwesomeIcon
                    icon={faTrash}
                    className="delete"
                    onClick={() => deleteSelectedAgent(formData, id)}
                />
            </Grid>
        </Grid>
    );
};

export default GridItem;
