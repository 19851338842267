import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button, TextField } from '@material-ui/core';
import { useAppDispatch, useDebounce, useAppSelector } from '../../../../app/hooks';
import {
    GlobalSearchResponse,
    GlobalSearchCriteriaCalledFrom,
} from '../../../models/globalSearch/globalSearchModel';
import {
    fetchMoreSearchResults,
    globalSearch,
    setDefaultStateValue,
    updateViewMore,
} from '../../../slices/globalSearch/globalSearchSlice';
import { Link, useHistory } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
    dataToBind,
    formGlobalSearchViewData,
    globalSearchRedirect,
} from '../../globalSearchDetail/globalSearchUtils';
import { setBackTo, setTitle } from '../../../slices/navigation/navigationSlice';
import DOMPurify from 'dompurify';
const GloablSearch: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const {
        loader,
        data: {
            all: { results },
        },
    } = useAppSelector(globalSearch);
    const [search, setSearch] = useState<string>('');
    const debouncedSearchTerm: string = useDebounce<string>(search, 500);
    const [showResults, setShowResults] = useState<boolean>(false);
    const history = useHistory();
    const ref = useDetectClickOutside({ onTriggered: () => setShowResults(false) });
    const [formattedResults, setFormattedResults] = useState<GlobalSearchResponse[]>([]);

    useEffect(() => {
        if (debouncedSearchTerm !== '') {
            const fetch = async () => {
                try {
                    const searchObject: GlobalSearchCriteriaCalledFrom = {
                        from: 'all',
                        currentPage: 1,
                        itemsPerPage: 100,
                        text: debouncedSearchTerm,
                        status: '',
                        entityType: '',
                    };
                    await dispatch(fetchMoreSearchResults(searchObject));
                    setShowResults(true);
                } catch (e) {
                    console.error(e);
                } finally {
                    dispatch(updateViewMore(false));
                }
            };
            fetch();
        }
    }, [dispatch, debouncedSearchTerm]);

    const redirect = (data: GlobalSearchResponse) => {
        const redirect = globalSearchRedirect(data);
        if (redirect.type === 'listing') dispatch(setTitle(`Listings`));
        if (redirect.type === 'office') dispatch(setTitle(`Offices`));
        if (redirect.type === 'salesTeam') dispatch(setTitle(`Sales Teams`));
        if (redirect.type === 'application') dispatch(setTitle(`Applications`));
        if (redirect.type === 'agent') dispatch(setTitle(`Agents`));
        // history.push(redirect.url);
        setSearch('');
        setShowResults(false);
        dispatch(setBackTo({ url: '', title: '' }));
    };

    const detailRedirect = () => {
        dispatch(updateViewMore(true));
        dispatch(setDefaultStateValue());
        setShowResults(false);
        setSearch('');
        history.push('/eng/searchDetailed');
    };

    const fetchTopResult = useCallback((results: GlobalSearchResponse[]) => {
        const finalResult = formGlobalSearchViewData(results);
        setFormattedResults(finalResult.slice(0, 5));
    }, []);

    useEffect(() => {
        if (results.length === 0) {
            setShowResults(false);
        } else {
            fetchTopResult(results);
        }
    }, [results, fetchTopResult]);

    return (
        <div className="searchConatiner">
            <TextField
                className="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                }}
            />
            <FontAwesomeIcon className="searchIcon" icon={faSearch} />
            <div
                className={`globalSearch-results ${showResults ? 'show-results' : ''}`}
                ref={ref}
            >
                {results.length && !loader ? (
                    <>
                        <div>
                            {formattedResults.map(
                                (data: GlobalSearchResponse, index: number) => (
                                    <Link
                                        to={globalSearchRedirect(data).url}
                                        onClick={() => redirect(data)}
                                        key={index}
                                        className="searchResults"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    dataToBind(data),
                                                ),
                                            }}
                                        ></span>
                                    </Link>
                                ),
                            )}
                        </div>
                        {results?.length > 5 ? (
                            <div className="detailsView">
                                <Button
                                    className="view-more-button"
                                    disableRipple={true}
                                    onClick={() => detailRedirect()}
                                >
                                    View More
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <div>No Data available based on the search criteria</div>
                )}
            </div>
        </div>
    );
};
export default GloablSearch;
