import { postCall, deleteCall } from '../../../../../../utils/api/apiWrapper';
import { ManagerPost, ManagerSaveResponse } from './managerModels';

export function saveManager(
    officeId: string,
    managerData: ManagerPost,
): Promise<ManagerSaveResponse> {
    return postCall<ManagerPost, ManagerSaveResponse>(
        `office/${officeId}/manager`,
        managerData,
    );
}

export function deleteManager(officeId: string): Promise<Response> {
    return deleteCall(`office/${officeId}/manager`);
}
