import { combineReducers } from '@reduxjs/toolkit';
import { DevelopmentDetailsState } from '../../features/developments/developmentDetails/developmentDetailsModel';
import { DevelopmentListState } from '../../features/developments/developmentListModel';
import DevelopmentListReducer from '../../features/developments/developmentListSlice';
import DevelopmentDetailsReducer from '../../features/developments/developmentDetails/developmentDetailsSlice';
import { DevelopmentPhotosState } from '../../features/developments/developmentDetails/drawer/screens/developmentPhotosEdit/developmentPhotosModels';
import DevelopmentPhotos from '../../features/developments/developmentDetails/drawer/screens/developmentPhotosEdit/developmentPhotosSlice';

interface DevelopmentState {
    developmentList: DevelopmentListState;
    developmentDetails: DevelopmentDetailsState;
    developmentPhotos: DevelopmentPhotosState;
}

export default combineReducers<DevelopmentState>({
    developmentList: DevelopmentListReducer,
    developmentDetails: DevelopmentDetailsReducer,
    developmentPhotos: DevelopmentPhotos,
});
