import { FunctionComponent, useState } from 'react';
import { Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import './invited.scss';

interface InvitedProps {
    photographers: string[];
}

const Invited: FunctionComponent<InvitedProps> = ({ photographers }) => {
    const [invite, setInvite] = useState<boolean>(false);
    return (
        <Grid className="invitedSection">
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                className="invited"
            >
                <Grid item container className="toggleInvite">
                    Invited Photographers / Videographers
                    {invite ? (
                        <ArrowDropDownIcon
                            className="arrowIcon"
                            onClick={() => setInvite(!invite)}
                        />
                    ) : (
                        <ArrowRightIcon
                            className="arrowIcon"
                            onClick={() => setInvite(!invite)}
                        />
                    )}
                </Grid>
                {invite && (
                    <>
                        {photographers.length ? (
                            <Grid
                                item
                                container
                                className="content"
                                alignItems="center"
                                spacing={2}
                                md={12}
                            >
                                <Grid item container md={12} className="formControlLabel">
                                    <Grid item md={3} sm={3}>
                                        Sno.
                                    </Grid>
                                    <Grid item md={7} sm={7}>
                                        Email
                                    </Grid>
                                </Grid>
                                {photographers.map((photographer: string, index) => (
                                    <Grid item container key={index} sm={12} md={12}>
                                        <Grid
                                            item
                                            className="formControlData"
                                            md={3}
                                            sm={3}
                                        >
                                            {index + 1}
                                        </Grid>
                                        <Grid
                                            item
                                            className="formControlData"
                                            md={7}
                                            sm={7}
                                        >
                                            {photographer}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Grid
                                item
                                container
                                className="content"
                                alignItems="center"
                                spacing={2}
                                md={12}
                            >
                                <Grid className="formControlData">
                                    No Photographers Invited
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Invited;
