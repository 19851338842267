import { FunctionComponent, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../../../shared/models/lookups/lookupsModels';
import {
    useAppDispatch,
    useFormData,
    useAppSelector,
} from '../../../../../../../../app/hooks';
import {
    setCurrentFormData,
    updateListingFeaturesFormName,
} from '../../../../../listingSlice';
import {
    fetchAmenities,
    fetchListingDevelopmentList,
    listingFeaturesSliceState,
} from '../../listingFeaturesSlice';
import AmenitySection from '../shared/AmenitySection';
import { lookups } from '../../../../../../../../shared/slices/lookups/lookupsSlice';
import { DevelopmentLookups } from '../../../../../../../../shared/models/lookups/lookupsModels';
import { getAmenitiesForSection } from '../../../../../../../../shared/utils/listing/featureUtils';
import { BuildingFeatures } from '../../../../../../../../shared/models/listing/amenityFeatures';
import { ListingFeatureDrawerProps } from '../../listingFeaturesModels';
import _ from 'lodash';
import { userRoles } from '../../../../../../../../shared/constants/user/roles';
import { Order } from '../../../../../../../../shared/models/customTable/customTableModels';

const BuildingAmenities: FunctionComponent<ListingFeatureDrawerProps> = ({
    editFormData,
    sectionName,
}) => {
    const {
        development,
        buildingId,
        year,
        amenities: listingAmenities,
        listingAgents,
        officeId,
    } = editFormData.data;
    const initialData: BuildingFeatures = {
        development,
        buildingId,
        year,
    };
    const { formData, setFormData, handleNumberChange } = useFormData(initialData);

    const dispatch = useAppDispatch();
    const { amenities, developmentList } = useAppSelector(listingFeaturesSliceState);
    const { developmentLook } = useAppSelector(lookups);
    const devLookups = developmentLook as DevelopmentLookups;

    const sectionAmenities = getAmenitiesForSection(amenities, sectionName);

    useEffect(() => {
        dispatch(updateListingFeaturesFormName(sectionName));
    }, [sectionName]);

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    useEffect(() => {
        if (!amenities.length) {
            dispatch(fetchAmenities());
        }
    }, [amenities, dispatch]);

    /**
     * make api call to fetch the development list based on the listig agents
     */
    useEffect(() => {
        if (listingAgents.length > 0 && officeId) {
            const agent = _.sortBy(listingAgents, 'displayOrder');
            if (agent[0].agentId !== '') {
                const data = {
                    address: '',
                    agentIds: _.map(listingAgents, 'agentId'),
                    city: '',
                    currentPage: 1,
                    itemsPerPage: 1000,
                    officeId: '',
                    officeIds: [officeId],
                    publish: false,
                    regionId: 0,
                    roleIds: [userRoles.agent.key],
                    sortColumn: 'Title',
                    sortDirection: 'desc' as Order,
                    statusId: 1, //active
                    validity: false,
                };
                dispatch(fetchListingDevelopmentList(data));
            }
        }
    }, [dispatch, listingAgents, officeId]);

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentFormData({
                formData,
                listingEditFormName: sectionName,
            }),
        );
    };

    const handleDevelopmentChange = (data: LookupInfo, name: string) => {
        const development = devLookups?.buildings?.find((b) => b.id === data.id);
        if (development) {
            setFormData({
                ...formData,
                [name || '']: data.id,
                development: development.name,
            });
        }
    };

    return (
        <div className="sectionContainer">
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="DEVELOPMENT"
                        control={
                            <DropDownSearch
                                id={formData.buildingId || ''}
                                items={developmentList}
                                name="buildingId"
                                handleDropDownChange={handleDevelopmentChange}
                                handleBlur={setFormDataToPersist}
                                disabled={
                                    developmentList && developmentList.length > 0
                                        ? false
                                        : true
                                }
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass={`formControlRoot ${
                            developmentList && developmentList.length === 0
                                ? 'inputDisabled'
                                : ''
                        }`}
                        labelMd={2}
                        controlMd={4}
                    />
                    <FormRow
                        label="YEAR BUILT"
                        control={
                            <TextField
                                value={formData.year || ''}
                                name="year"
                                onChange={handleNumberChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'year',
                                    className: 'inputBaseRoot',
                                }}
                                inputProps={{ maxLength: 4 }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
            </div>
            <AmenitySection
                listingId={editFormData.data.id}
                listingAmenities={listingAmenities}
                amenitiesForSection={sectionAmenities}
            />
            <div className="info-text">
                NOTE: Not all the amenities and features associated with this listing will
                be displayed on every website. Each syndication website has its own set of
                features and amenities that it chooses to display. Features and amenities
                that are not currently displayed on a website may be in the future.
            </div>
        </div>
    );
};

export default BuildingAmenities;
