import { FunctionComponent, useState, useEffect } from 'react';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import { ShareDetails, PackageProps } from '../../../../models/images/sharedModel';
import { selectionSizes } from '../../../../constants/photos/photoConstants';
import './sharePhotos.scss';
import { ImageData } from '../../../../models/images/sharedModel';
import { useFormData, useAppDispatch } from '../../../../../app/hooks';
import { getPhotoId, getImageDimension } from '../../../../utils/photos/photoUtils';
import { validateEmail } from '../../../../../utils/urlUtils';
import { AppThunk } from '../../../../../app/store';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';

interface ShareFormProps {
    selectedImagesList: ImageData[];
    entityId: string;
    sharing: boolean;
    resetAction: React.Dispatch<React.SetStateAction<string>>;
    clearHandler: () => void;
    currentImageType: string;
    saveImageSharePackage: (
        url: string,
        request: PackageProps,
        shareData: ShareDetails,
    ) => AppThunk;
    entityType: string;
    office: string | null;
}

const ShareForm: FunctionComponent<ShareFormProps> = ({
    selectedImagesList,
    // data,
    sharing,
    resetAction,
    clearHandler,
    currentImageType,
    entityId,
    saveImageSharePackage,
    entityType,
    office,
}) => {
    const initialShareData: ShareDetails = {
        emailTo: '',
        message: '',
        title: `Id #${entityId}`,
        url: '',
    };
    const { formData, setFormData, handleTextChange, removeSpaces } =
        useFormData(initialShareData);
    const [dimensions, setDimensions] = useState<string>('');
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const exitShare = () => {
        resetAction('');
        clearHandler();
    };

    const submitHandler = async () => {
        if (selectedImagesList.length > 0) {
            const idsToBeShared: string[] = [];
            selectedImagesList.forEach((img: ImageData) => {
                if (img.urls.originalUrl.includes('pdf')) {
                    idsToBeShared.push(getPhotoId(img, '', currentImageType));
                } else {
                    idsToBeShared.push(
                        getPhotoId(
                            img,
                            getImageDimension(img.islandscape, dimensions),
                            currentImageType,
                        ),
                    );
                }
            });
            const url = `${process.env.REACT_APP_PHOTOURL}/api/asset/package`;
            const data: PackageProps = {
                files: idsToBeShared,
            };
            await dispatch(saveImageSharePackage(url, data, formData));
            setFormData(initialShareData);
            exitShare();
        }
    };

    useEffect(() => {
        setFormData(formData);
        const { emailTo, title } = formData;
        setDisabled(emailTo === '' || title === '' || !validateEmail(formData.emailTo));
    }, [formData, setFormData]);

    return (
        <div className="shareDetailsContainer">
            {sharing ? (
                <div className="circularOverlay">
                    <span className="sharingText">Sharing images</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            <span className="actionHolder">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                    sm={12}
                >
                    <Grid item container sm={6} md={6}>
                        <TextField
                            className="shareInput"
                            placeholder="Email"
                            name="emailTo"
                            value={formData.emailTo}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'Email',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 500 }}
                            onChange={handleTextChange}
                            onBlur={removeSpaces}
                            required={true}
                        />
                    </Grid>
                    <Grid item container sm={6} md={6}>
                        <TextField
                            className="shareInput"
                            placeholder="Title"
                            name="title"
                            value={formData.title}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'Title',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 500 }}
                            onChange={handleTextChange}
                            onBlur={removeSpaces}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                    sm={12}
                >
                    <Grid item container sm={12} md={12}>
                        <TextField
                            className="shareInputMessage"
                            value={formData.message}
                            InputProps={{
                                disableUnderline: true,
                                'aria-label': 'message',
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 500 }}
                            name="message"
                            placeholder="Message"
                            onChange={handleTextChange}
                            onBlur={removeSpaces}
                            multiline
                        />
                    </Grid>
                </Grid>
            </span>
            <div className="buttonsHolder">
                <div className="sizeButtonHolder">
                    <span className="sizeText">Choose Size:</span>
                    <button
                        className={
                            dimensions === selectionSizes.small
                                ? 'sizeButton sizeButtonSelected'
                                : 'sizeButton'
                        }
                        onClick={() => setDimensions(selectionSizes.small)}
                    >
                        900X600
                    </button>
                    <button
                        className={
                            dimensions === selectionSizes.medium
                                ? 'sizeButton sizeButtonSelected'
                                : 'sizeButton'
                        }
                        onClick={() => setDimensions(selectionSizes.medium)}
                    >
                        1800X1200
                    </button>
                    <button
                        className={
                            dimensions === ''
                                ? 'sizeButton sizeButtonSelected'
                                : 'sizeButton'
                        }
                        onClick={() => setDimensions('')}
                    >
                        Original
                    </button>
                </div>
                <div className="submitButtonHolder">
                    <button
                        className="actionButton cancel"
                        onClick={() => {
                            exitShare();
                            googleAnalyticsAction(
                                'Cancel',
                                'Click',
                                `${
                                    entityType === 'listing'
                                        ? 'Listing Edit'
                                        : 'Development Edit'
                                }: Image share cancel clicked, Image type: ${currentImageType}`,
                            );
                            googleAnalyticsAction(
                                'Cancel',
                                'Click',
                                `${
                                    entityType === 'listing'
                                        ? 'Listing Edit'
                                        : 'Development Edit'
                                }: Image share cancel clicked, Image type: ${currentImageType}, Office: ${office}`,
                            );
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className={
                            isDisabled
                                ? 'actionButton submitDisabled'
                                : 'actionButton submitEnabled'
                        }
                        onClick={submitHandler}
                        disabled={isDisabled}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShareForm;
