import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
    SalesTeamState,
    SalesTeamListUpdate,
    SalesTeamTableActionType,
    SalesTeamResponse,
    TeamSearch,
} from './salesTeamListModels';
import { getSalesTeams } from './salesTeamListApi';
import { initialSalesTeamData } from './salesTeamListConstants';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import Logger from '../../utils/logging/logger';
import { setError } from '../../shared/slices/messaging/messagingSlice';

const salesTeamState: SalesTeamState = {
    data: initialSalesTeamData,
    isLoading: false,
    tableAction: {
        currentPage: 1,
        itemsPerPage: 20,
        sortColumn: 'Name',
        sortDirection: 'asc',
        teamName: '',
        agentName: '',
        officeId: '',
        status: 0,
        officeIds: [], // Check this
    },
    isSearched: false,
};

export const salesTeamSlice = createSlice({
    name: 'salesTeam',
    initialState: salesTeamState,
    reducers: {
        updateSalesTeams: (
            state,
            action: PayloadAction<PaginationResponse<SalesTeamResponse>>,
        ) => {
            const results = [...state.data.results, ...action.payload.results];
            let data = {
                ...state.data,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (state.data.results.length !== 0 || action.payload.results.length !== 0) {
                data = {
                    ...data,
                    results:
                        state.data.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                data: data,
            };
        },
        updateSalesTeamList: (state, action: PayloadAction<SalesTeamListUpdate>) => {
            const { results } = state.data;
            const { salesTeamId } = action.payload;
            const findIndex = results.findIndex((x) => x.id === salesTeamId);
            const newValue = {
                ...state.data.results[findIndex],
                ...action.payload,
            };
            state.data.results[findIndex] = newValue;
        },
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setInitialState: (state) => {
            return {
                ...state,
                data: initialSalesTeamData,
                isLoading: false,
            };
        },
        updateSalesTableAction: (
            state,
            action: PayloadAction<SalesTeamTableActionType>,
        ) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        updatedIsSearched: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSearched: action.payload,
            };
        },
    },
});

export const fetchSalesTeams =
    (request: TeamSearch): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            if (request && request.status === 0) {
                delete request.status;
            }
            const response = await getSalesTeams(request);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(updateSalesTeams(response));
            }
        } catch (exception) {
            Logger.error('Failed to retrieve the sales team list');
            dispatch(setError(`Failed to retrieve the sales team list`));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const {
    updateSalesTeams,
    updateLoader,
    setInitialState,
    updateSalesTeamList,
    updateSalesTableAction,
    updatedIsSearched,
} = salesTeamSlice.actions;

export const salesTeam = (state: RootState): SalesTeamState => state.salesTeam.list;

export default salesTeamSlice.reducer;
