import { SortingOrder } from '../../cmrModels';

export const getHeaderDetails = (
    sectionId: number | null,
    sortOrder: SortingOrder[],
): { id: string; displayName: string; displaySection: boolean } => {
    const headerDetails = { id: '', displayName: '', displaySection: false };
    if (sortOrder.length > 0 && sectionId) {
        const result = sortOrder.find((element) => element.sectionId === sectionId);
        if (result) {
            headerDetails.id = result.id;
            headerDetails.displayName = result.displayName;
            headerDetails.displaySection = result.displaySection;
        } else {
            sortOrder.map((element) => {
                const childResult = element.childSections.find(
                    (child) => child.sectionId === sectionId,
                );
                if (childResult) {
                    headerDetails.id = childResult.id;
                    headerDetails.displayName = childResult.displayName;
                    headerDetails.displaySection = childResult.displaySection;
                }
                return headerDetails;
            });
        }
    }
    return headerDetails;
};

export const hideDisplayOnReport = (
    id: number,
    sortOrder: SortingOrder[],
    currentId?: number,
): boolean => {
    if (id === 9) {
        const stackedAdv = sortOrder.filter((element) => {
            return (
                element.sectionId === id &&
                element.childSections.length > 0 &&
                element.childSections.find((child) => child.sectionId === currentId)
            );
        });
        return stackedAdv.length > 0 ? true : false;
    }
    if (id === 4 || 5) {
        const stackedOs = sortOrder.filter((element) => {
            return element.sectionId === id && element.childSections.length > 0;
        });
        return stackedOs.length > 0 ? true : false;
    }
    return false;
};
