import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../officeModels';
import OfficeDesignVaultManager from '../../../../../designVault/manage/OfficeDesignVaultManager';

const DesignVaultManage: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const {
        data: { officeDetails },
    } = editFormData;

    return (
        <div className="managerContainer">
            <OfficeDesignVaultManager
                officeId={officeDetails.id ? officeDetails.id : ''}
            />
        </div>
    );
};

export default DesignVaultManage;
