import { makeStyles } from '@material-ui/core/styles';

export const ToggleButtonStyle = makeStyles({
    selectSaleActive: {
        background: '#002349',
        borderRadius: 0,
        border: 0,
        color: 'white',
        fontSize: '12px',
        lineHeight: '15px',
        fontFamily: 'MercuryTextG4Roman',
        letterSpacing: '0.02px',
        '&:hover': {
            backgroundColor: '#002349',
        },
        textTransform: 'capitalize',
    },
    selectSaleInactive: {
        background: 'white',
        borderRadius: 0,
        border: '1px solid',
        color: '#002349',
        fontSize: '12px',
        lineHeight: '15px',
        fontFamily: 'MercuryTextG4Roman',
        letterSpacing: '0.02px',
        '&:hover': {
            backgroundColor: 'white',
        },
        textTransform: 'capitalize',
    },
});
