import {
    AgentDescription,
    Accolades,
    AgentAssistant,
    AgentDescriptionUpdateRequest,
    AgentLookups,
    Biography,
    ExpertiseSection,
    AgentDetailsForAssistant,
    SelectSoldsUpdate,
    AgentDataCompleteModel,
    SelectSoldsExportModel,
} from './agentModels';
import {
    getCall,
    putCall,
    postCall,
    putCallRaw,
    postCallRaw,
    postExternalApiCall,
} from '../../../utils/api/apiWrapper';
import { v4 as uuidv4 } from 'uuid';
import { ListingsRequestAPI, ListingsResponse } from '../../listings/listingListModels';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import { AgentTestimonialsModel } from '../../../shared/models/testimonial/testimonial';

export function getAgentDetails(
    id: string,
    name: string,
): Promise<AgentDescription | Accolades | AgentAssistant[] | AgentDetailsForAssistant[]> {
    return getCall<
        AgentDescription | Accolades | AgentAssistant[] | AgentDetailsForAssistant[]
    >(`agent/${id}/${name}`);
}

export function updateAgentDescriptionApi(
    description: AgentDescriptionUpdateRequest,
    agentId: string,
    profileId: string,
): Promise<Response> {
    return putCall<AgentDescriptionUpdateRequest, Response>(
        `agent/${agentId}/profile/${profileId}`,
        description,
    );
}

export function getAgentLookupsApi(regionId: number): Promise<AgentLookups> {
    return getCall<AgentLookups>(`agent/${regionId}/lookups`);
}

export function updateAgentBio(request: Biography): Promise<Biography> {
    return request.id?.length
        ? putCall(`agent/agentBio`, request)
        : postCall<Biography, Biography>(`agent/agentBio`, { ...request, id: uuidv4() });
}

export function updateExpertiseData(request: ExpertiseSection): Promise<Response> {
    return putCallRaw(
        `profile/${request.profileId}/areaofexpertise`, // if this?
        request.areaOfExpertise,
    );
}

export function updateChipsData(
    profileId: string,
    urlName: string,
    data: { ids: number[] } | string | null,
): Promise<Response> {
    return postCallRaw(`agent/${profileId}/${urlName}`, data);
}

export function updateCmsSelectSolds(request: SelectSoldsUpdate): Promise<Response> {
    return postExternalApiCall<SelectSoldsUpdate, Response>(
        `${process.env.REACT_APP_AGENTCMS_URL}api/siteoptions/selectsolds`,
        request,
        '',
    );
}

// export function getAgentWebsiteDetails(
//     profileId: string,
//     name: string,
// ): Promise<Response> {
//     return getExternalApiCall<Response>(
//         `${process.env.REACT_APP_AGENTCMS_URL}api/agent/${profileId}/${name}`,
//     );
// }

export function getAgentSelectListingsFromApi(
    listingsRequest: ListingsRequestAPI,
): Promise<PaginationResponse<ListingsResponse>> {
    const url = `listing/search`;
    return postCall<ListingsRequestAPI, PaginationResponse<ListingsResponse>>(
        url,
        listingsRequest,
    );
}

export function getAgentDataCompleteStatus(
    agentId: string,
): Promise<AgentDataCompleteModel> {
    return getCall<AgentDataCompleteModel>(`agent/${agentId}/validate`);
}

export function exportSelectSoldsReportsApi(
    request: SelectSoldsExportModel,
): Promise<Response> {
    return postCallRaw<SelectSoldsExportModel>('selectSold/export', request);
}

export function getAgentTestimonialsApi(
    profileId: string,
): Promise<AgentTestimonialsModel[]> {
    return getCall<AgentTestimonialsModel[]>(`agent/${profileId}/testimonials`);
}

export function saveAgentTestimonialsApi(
    profileId: string,
    data: AgentTestimonialsModel[],
): Promise<AgentTestimonialsModel[]> {
    return postCall<AgentTestimonialsModel[], AgentTestimonialsModel[]>(
        `agent/${profileId}/testimonials`,
        data,
    );
}
