import { TableHeadCell } from '../../shared/models/customTable/customTableModels';
import {
    CreateEditOtherMarketingModel,
    ListingSearchKeys,
    OtherMarketingSearchRequest,
    SelectedListingByField,
} from './otherMarketingModel';
import { OtherMarketing } from '../../shared/models/marketing/marketingModels';

export const initialOtherMarketingSearch: OtherMarketingSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    description: '',
    marketingTypeId: '',
    additionalPromotionTypeId: '',
    publish: true,
    startDate: '',
    endDate: '',
    agentId: '',
    listingId: '',
    sortDirection: 'desc',
    sortColumn: 'Date',
};

export const initialOtherMarketingData: OtherMarketing = {
    marketingTypeId: '',
    additionalPromotionId: '',
    date: '',
    description: '',
    publish: true,
    actualCost: 0,
    agentCost: 0,
    officeCost: 0,
    billingCode: '',
    cit: true,
    isAgentPaid: false,
    isFile: false,
    file: undefined,
    url: '',
    agentMarketingCosts: [],
    listings: [],
    // email campaign
    audience: '',
    emailsSent: 0,
    views: 0,
    clicks: 0,
    // social campaign
    toDate: '',
    potentialReach: 0,
    engagements: 0,
};

export const otherMarketingListTableHeadCells: TableHeadCell[] = [
    {
        id: 'publish',
        label: 'Published',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'publish',
    },
    {
        id: 'date',
        label: 'Date',
        dbColMapping: 'Date',
        doSort: true,
        isDate: true,
    },
    {
        id: 'marketingType',
        label: 'MarketingType',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'description',
        label: 'Description',
        dbColMapping: 'Description',
        doSort: true,
    },
    {
        id: 'createdBy',
        label: 'Created By',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'mediaLink',
        label: 'Link',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'view',
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'edit',
    },
];

export const omSearchFields = ['agent', 'rfgId', 'mlsNo', 'address1'];

export const initialListingSearchKeys: ListingSearchKeys = {
    rfgId: 0,
    mlsNo: 0,
    address1: 0,
};

export const initializeSelectedListing: SelectedListingByField = {
    rfgId: undefined,
    mlsNo: undefined,
    address1: undefined,
};

export const initialSelectedOM: CreateEditOtherMarketingModel = {
    id: '',
    isValid: false,
    displayError: false,
};
