import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MlsDetailsState, MlsImportMessage, MlsListingDetails } from './mlsDetailsModels';
import { AppThunk, RootState } from '../../../app/store';
import { getMlsApiSaveUrl, getMlsApiUrlDetails } from './mlsUrlService';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import { getMlsDetails, importMlsListing } from './mlsDetailsApi';
import { getUser } from '../../../shared/auth/authService';

const initialState: MlsDetailsState = {
    isLoading: false,
};

export const mlsDetailsSlice = createSlice({
    name: 'mlsDetails',
    initialState: initialState,
    reducers: {
        updateLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        updateDetails: (state, action: PayloadAction<MlsListingDetails>) => {
            state.details = action.payload;
        },
    },
});

export const fetchMlsDetails =
    (
        listingId: string | null,
        officeIdentifier: number | null,
        dataSource: string | null,
    ): AppThunk =>
    async (dispatch) => {
        try {
            if (!listingId) return;

            dispatch(updateLoadingStatus(true));
            const urlDetails = getMlsApiUrlDetails(
                listingId,
                officeIdentifier,
                dataSource,
            );
            const url = urlDetails.url;
            if (url === '') {
                dispatch(setError('No MLS source found for this listing'));
                dispatch(updateLoadingStatus(false));
                dispatch(updateDetails({ listingId: listingId }));
            } else {
                const details = await getMlsDetails(url);
                dispatch(updateDetails(details));
            }
        } catch (error: any) {
            if (error.toString().indexOf('404') > 0) {
                dispatch(setError(`Listing ${listingId} not found in the MLS`));
            } else {
                dispatch(setError('Error getting MLS details for listing'));
            }
            Logger.error('Error getting MLS details for listing');
            dispatch(updateDetails({ listingId: listingId || '' }));
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const importMlsDetails =
    (
        listingId: string | null,
        officeIdentifier: number | null,
        dataSource: string | null,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingStatus(true));
            const url = getMlsApiSaveUrl(listingId, officeIdentifier, dataSource);
            if (url === '') {
                dispatch(setError('No MLS source found for this listing'));
                dispatch(updateLoadingStatus(false));
            } else {
                const user = getUser();
                const message: MlsImportMessage = {
                    listingKey: listingId || '',
                    forceImport: true,
                    source: 'Zeus UI Manual import',
                    user: user.userName,
                };
                await importMlsListing(url, message);
                dispatch(
                    setSuccess(
                        'MLS data has been queued up to import.  Please check back shortly for updates.',
                    ),
                );
            }
        } catch (error: any) {
            if (error.message === '404') {
                dispatch(setError(`Listing ${listingId} not found in the MLS`));
            } else {
                dispatch(setError('Error importing MLS details for listing'));
            }
            Logger.error(
                `Error importing MLS details for listing: ${JSON.stringify(error)}`,
            );
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const { updateLoadingStatus, updateDetails } = mlsDetailsSlice.actions;
export const mlsDetails = (state: RootState): MlsDetailsState => state.listing.mlsDetails;

export default mlsDetailsSlice.reducer;
