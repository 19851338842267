import { FunctionComponent, useMemo } from 'react';
import { DrawerContainerProps } from '../../../salesTeamModels';
import { useAppDispatch } from '../../../../../../app/hooks';
import Testimonials from '../../../../../../shared/component/testimonials';
import {
    SaleTeamTestimonialModel,
    TestimonialModel,
} from '../../../../../../shared/models/testimonial/testimonial';

import { getUser } from '../../../../../../shared/auth/authService';
import { saveSalesTeamTestimonials } from '../../../salesTeamSlice';
import { Grid } from '@material-ui/core';

const SalesTeamTestimonials: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const stateTestimonialList = editFormData.data.testimonials;
    const teamId = editFormData.data.id;
    const user = useMemo(() => getUser(), []);

    const saveCall = (from: string, data: TestimonialModel | TestimonialModel[]) => {
        if (from === 'add') {
            const salesTestimonial = {
                ...data,
                salesTeamId: teamId || '',
                displayOrder: stateTestimonialList.length + 1,
            };
            dispatch(
                saveSalesTeamTestimonials(
                    from,
                    teamId || '',
                    [
                        ...stateTestimonialList,
                        salesTestimonial as SaleTeamTestimonialModel,
                    ],
                    user,
                ),
            );
        } else if (from === 'edit') {
            const findIndex = stateTestimonialList.findIndex(
                (x) => x.id === (data as SaleTeamTestimonialModel).id,
            );
            const testimonialList = [...stateTestimonialList];
            testimonialList[findIndex] = data as SaleTeamTestimonialModel;
            dispatch(
                saveSalesTeamTestimonials(from, teamId || '', testimonialList, user),
            );
        } else if (from === 'delete') {
            const remainingTestimonials = stateTestimonialList
                .filter((x) => x.id !== (data as SaleTeamTestimonialModel).id)
                .map((data, index) => {
                    return {
                        ...data,
                        displayOrder: index + 1,
                    };
                });
            dispatch(
                saveSalesTeamTestimonials(
                    from,
                    teamId || '',
                    remainingTestimonials,
                    user,
                ),
            );
        } else {
            dispatch(
                saveSalesTeamTestimonials(
                    from,
                    teamId || '',
                    data as SaleTeamTestimonialModel[],
                    user,
                ),
            );
        }
    };

    return (
        <>
            <Grid item container md={12} className="info-text">
                Note: Data entered in the Testimonials will only be shown in the Team site
            </Grid>
            <Testimonials
                saveCall={saveCall}
                stateTestimonialList={stateTestimonialList}
            />
        </>
    );
};
export default SalesTeamTestimonials;
