import { postCall } from '../../../utils/api/apiWrapper';
import {
    GlobalSearchAPIResponse,
    GlobalSearchCriteria,
} from '../../models/globalSearch/globalSearchModel';

export function getDataForGlobalSearch(
    data: GlobalSearchCriteria,
): Promise<GlobalSearchAPIResponse> {
    const url = 'search/global';
    return postCall<GlobalSearchCriteria, GlobalSearchAPIResponse>(url, data);
}
