import { FunctionComponent, useEffect } from 'react';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import {
    developmentPhotoDetails,
    developmentPhotoFormMap,
    developmentPhotoForms,
    fetchImages,
    fetchTotalImagesCount,
    fetchImageTypes,
    fetchFilter,
} from './developmentPhotosSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { CircularProgress } from '@material-ui/core';
import { updateDevelopmentEditFormName } from '../../../developmentDetailsSlice';
import { saveDevelopmentEditFormData } from '../../../developmentSave';
import { editFormComponent } from '../../../developmentDetailsConstants';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';

const DevelopmentPhotosEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const developmentPhotoInfo = useAppSelector(developmentPhotoDetails);
    const dispatch = useAppDispatch();
    const selectedSection =
        editFormData.editForm?.developmentEditFormName || developmentPhotoForms.buildings;

    // Corresponding selected section from navbar to be rendered
    const RenderSection = editFormData
        ? developmentPhotoFormMap.get(selectedSection)
        : null;

    // flag corresponding to the particular section to limit number of get calls
    const imagesFlag =
        developmentPhotoInfo.allImagesFlags[
            imageTypes[
                selectedSection as keyof typeof imageTypes
            ] as keyof typeof developmentPhotoInfo.allImagesFlags
        ];

    const handleNavClick = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        formName: string,
    ) => {
        event.preventDefault();
        dispatch(saveDevelopmentEditFormData(editFormData));
        dispatch(
            updateDevelopmentEditFormName({
                editFormName: editFormComponent.developmentPhotos,
                developmentEditFormName: formName,
            }),
        );
    };

    // useEffect(() => {
    //     if (!developmentPhotoInfo.flags.creditsFlag) {
    //         dispatch(fetchPhotoCredits(editFormData.data.id));
    //     }
    // }, [editFormData.data.id, developmentPhotoInfo.flags.creditsFlag, dispatch]);

    //  CHECK IF FILTER REQUIRED HERE OR NOT

    useEffect(() => {
        if (!developmentPhotoInfo.flags.filterFlag) {
            dispatch(fetchFilter());
        }
    }, [developmentPhotoInfo.flags.filterFlag, dispatch]);

    useEffect(() => {
        if (!imagesFlag) {
            dispatch(
                fetchImages(
                    'building',
                    editFormData.data.id,
                    imageTypes[selectedSection as keyof typeof imageTypes],
                ),
            );
        }
    }, [selectedSection, dispatch, imagesFlag, editFormData.data.id]);

    useEffect(() => {
        if (!developmentPhotoInfo.flags.imageCountFlag) {
            dispatch(fetchTotalImagesCount(editFormData.data.id, 'building'));
        }
    }, [developmentPhotoInfo.flags.imageCountFlag, dispatch, editFormData.data.id]);

    useEffect(() => {
        if (!developmentPhotoInfo.flags.imageTypesFlag) {
            dispatch(fetchImageTypes(editFormData.data.id, 'building'));
        }
    }, [developmentPhotoInfo.flags.imageTypesFlag, dispatch, editFormData.data.id]);

    const renderNavItem = (title: string, formName: string) => {
        return (
            <span
                className={
                    formName === selectedSection ? 'nav-link selected' : 'nav-link'
                }
                onClick={(e) => {
                    handleNavClick(e, formName);
                    googleAnalyticsAction(
                        'Tab Change',
                        'Click',
                        `Development Edit: Photos Tab clicked, Tab: ${
                            title.split(' (')[0]
                        }`,
                    );
                    googleAnalyticsAction(
                        'Tab Change',
                        'Click',
                        `Development Edit: Photos Tab clicked, Tab: ${
                            title.split(' (')[0]
                        }, Office: ${editFormData.data.office}`,
                    );
                }}
            >
                {title}
            </span>
        );
    };

    const renderTopNav = () => {
        return (
            <div className="top-nav">
                {renderNavItem(
                    `BUILDINGS (${developmentPhotoInfo.imagesCount.buildings})`,
                    developmentPhotoForms.buildings,
                )}
                {renderNavItem(
                    `BUILDING FLOORPLANS (${developmentPhotoInfo.imagesCount.buildingFloorplans})`,
                    developmentPhotoForms.buildingFloorplans,
                )}
            </div>
        );
    };
    // return <Grid container> Development Photos Edit Page</Grid>;

    return (
        <>
            {renderTopNav()}
            {!imagesFlag ? (
                <div className="circularOverlay">
                    <span className="loadingText">Loading...</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            {RenderSection ? (
                <RenderSection
                    editFormData={editFormData}
                    currentSection={selectedSection}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default DevelopmentPhotosEdit;
