import { getToken } from '../../shared/auth/authService';
import { OpenAIRequest } from '../../shared/models/openAiModel/openAiModel';

const apiUrl = process.env.REACT_APP_APIURL;
const tridentApiUrl = process.env.REACT_APP_TRIDENTAPIURL;
const openAiUrl = process.env.REACT_APP_OPENAIURL;
let authHeader: HeadersInit;

export const getCall = async <T>(
    urlSuffix: string,
    isAuthHeaderChange?: boolean,
): Promise<T> => {
    return await makeAPICall<T>(`${apiUrl}/${urlSuffix}`, {
        headers: {
            Authorization: getRequestHeadersForApi(isAuthHeaderChange),
        },
    });
};

export const getCallRaw = async (urlSuffix: string): Promise<Response> => {
    return await makeAPICall<Response>(
        `${apiUrl}/${urlSuffix}`,
        {
            headers: {
                Authorization: getRequestHeadersForApi(),
            },
        },
        'raw',
    );
};

export const getApiCall = async <T>(fullUrl: string): Promise<T> => {
    return await makeAPICall<T>(fullUrl);
};

export const postExternalApiCall = async <TRequest, TResponse>(
    url: string,
    data: TRequest,
    responseFormat: string,
): Promise<TResponse> => {
    const requestOptions = loadRequestBody(data, 'POST');
    return await makeAPICall<TResponse>(url, requestOptions, responseFormat);
};

export const getExternalApiCall = async <T>(url: string): Promise<T> => {
    return await makeAPICall<T>(url, {}, 'raw');
};

export const postCall = async <TRequest, TResponse>(
    urlSuffix: string,
    data: TRequest,
): Promise<TResponse> => {
    const requestOptions = loadRequestBody(data, 'POST');
    return await makeAPICall<TResponse>(`${apiUrl}/${urlSuffix}`, requestOptions);
};

export const postCallRaw = async <TRequest>(
    urlSuffix: string,
    data: TRequest,
    isAuthHeaderChange?: boolean,
): Promise<Response> => {
    const requestOptions = loadRequestBody(data, 'POST', isAuthHeaderChange);
    return await makeAPICall<Response>(`${apiUrl}/${urlSuffix}`, requestOptions, 'raw');
};

export const postFormData = async <TResponse>(
    urlSuffix: string,
    data: FormData,
    isAuthHeaderChange?: boolean,
): Promise<TResponse> => {
    const requestOptions = loadRequestFormData(data, 'POST', isAuthHeaderChange);
    return await makeAPICall<TResponse>(`${apiUrl}/${urlSuffix}`, requestOptions);
};

export const putCall = async <TRequest, TResponse>(
    urlSuffix: string,
    data: TRequest,
): Promise<TResponse> => {
    const requestOptions = loadRequestBody(data, 'PUT');
    return await makeAPICall<TResponse>(`${apiUrl}/${urlSuffix}`, requestOptions);
};

/**
 * Use in case if response has no body
 * @param urlSuffix
 * @param data
 * @param isAuthHeaderChange
 * @returns
 */
export const putCallRaw = async <TRequest>(
    urlSuffix: string,
    data: TRequest,
    isAuthHeaderChange?: boolean,
): Promise<Response> => {
    const requestOptions = loadRequestBody(data, 'PUT', isAuthHeaderChange);
    return await makeAPICall<Response>(`${apiUrl}/${urlSuffix}`, requestOptions, 'raw');
};

export const putFormData = async <TResponse>(
    urlSuffix: string,
    data: FormData,
): Promise<TResponse> => {
    const requestOptions = loadRequestFormData(data, 'PUT');
    return await makeAPICall<TResponse>(`${apiUrl}/${urlSuffix}`, requestOptions);
};

export const deleteCall = async (urlSuffix: string): Promise<Response> => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            Authorization: getRequestHeadersForApi(),
        },
    };
    return await makeAPICall<Response>(`${apiUrl}/${urlSuffix}`, requestOptions, 'raw');
};

export const deleteCallWithResponse = async <TResponse>(
    urlSuffix: string,
): Promise<TResponse> => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            Authorization: getRequestHeadersForApi(),
        },
    };
    return await makeAPICall<TResponse>(`${apiUrl}/${urlSuffix}`, requestOptions);
};

const loadRequestBody = <TRequest>(
    data: TRequest,
    method: string,
    isAuthHeaderChange?: boolean,
) => {
    return {
        method: method,
        headers: {
            ...authHeader,
            'Content-Type': 'application/json',
            Authorization: getRequestHeadersForApi(isAuthHeaderChange),
        },
        body: JSON.stringify(data || {}),
    };
};

const loadRequestFormData = (
    data: FormData,
    method: string,
    isAuthHeaderChange?: boolean,
) => {
    return {
        method: method,
        headers: {
            ...authHeader,
            Authorization: getRequestHeadersForApi(isAuthHeaderChange),
        },
        body: data,
    };
};

export const getBrightCoveVideo = async (url: string): Promise<Response> => {
    return await fetch(url).then((response) => {
        if (!response.ok) {
            // throw API error
            throw new Error(response.statusText);
        }
        return response;
    });
};

//temporary token fix
export const packageAuthHeader = { Authorization: `Bearer App-MLSImport` };

//temporary token fix
const loadPackageRequestBody = <TRequest>(data: TRequest, method: string) => {
    return {
        method: method,
        headers: { ...packageAuthHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(data || {}),
    };
};

// Photographer Packages API Calls

export const packageGet = async (url: string): Promise<Response> => {
    return await fetch(url, { headers: packageAuthHeader }).then((response) => {
        return response;
    });
};

export const packagePost = async <TRequest>(
    url: string,
    data: TRequest,
): Promise<Response> => {
    const requestOptions = loadPackageRequestBody(data, 'POST');
    return await fetch(url, requestOptions).then((response) => {
        if (!response.ok) {
            // throw API error
            throw new Error(response.statusText);
        }
        return response;
    });
};

export const tridentPostCall = async <TRequest, TResponse>(
    urlSuffix: string,
    data: TRequest,
): Promise<TResponse> => {
    const requestOptions = loadRequestBody(data, 'POST');
    return await makeAPICall<TResponse>(
        `${tridentApiUrl}/${urlSuffix}`,

        requestOptions,
    );
};

export const tridentGetCall = async <TResponse>(
    urlSuffix: string,
): Promise<TResponse> => {
    return await makeAPICall<TResponse>(`${tridentApiUrl}/${urlSuffix}`);
};

export const makeAPICall = async <T>(
    url: string,
    options = {},
    responseFormat = 'json',
): Promise<T> => {
    return await fetch(url, options).then((response) => {
        if (!response.ok) {
            if (responseFormat !== 'json') {
                throw new Error(response.status.toString());
            } else {
                return response.text().then((text) => {
                    throw new Error(text);
                });
            }
        }
        return responseFormat === 'json' ? response.json() : response;
    });
};

const getRequestHeadersForApi = (isAuthHeaderChange?: boolean) => {
    return isAuthHeaderChange
        ? `Bearer ${getToken('login-token')}`
        : `Bearer ${getToken()}`;
};

export const makeOpenAICall = async <T>(data: OpenAIRequest): Promise<T> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
        body: JSON.stringify(data || {}),
    };
    return await fetch(`${openAiUrl}v1/chat/completions`, requestOptions).then(
        (response) => {
            return response.json();
        },
    );
};
