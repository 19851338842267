import { serialize } from 'object-to-formdata';
import loDash from 'lodash';
import { LookupInfo } from '../shared/models/lookups/lookupsModels';
import { DimensionProps } from '../shared/models/images/sharedModel';
import { useEffect, useRef } from 'react';
import { DateDifferenceModel } from '../shared/models/listing/dateModel';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';
import { videoTypes } from '../shared/constants/video/videoConstants';

const abortControllers: (AwsAbortController | undefined)[] = [];

export const validURL = (str: string): boolean => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    );
    return !!pattern.test(str);
};

export const dateFormatMMDDYYY = (
    date?: string,
    type?: string,
    twoDigitYear?: boolean,
): string | null => {
    if (date === null) {
        return null;
    }
    const dateCheck = new Date(date || '');
    if (dateCheck.toDateString() !== 'Invalid Date') {
        const day = ('0' + dateCheck.getDate()).slice(-2);
        const month = ('0' + (dateCheck.getMonth() + 1)).slice(-2);
        const year =
            twoDigitYear === true
                ? dateCheck.getFullYear().toString().substring(2)
                : dateCheck.getFullYear().toString();
        if (type === 'dot') {
            return month + '.' + day + '.' + year;
        } else if (type === 'dash') {
            return month + '-' + day + '-' + year;
        } else {
            return month + '/' + day + '/' + year;
        }
    } else {
        return null;
    }
};

export const currencyFormat = (price: number | null): string => {
    if (price === 0) {
        return '0';
    } else if (!price) {
        return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
    });
    return formatter.format(price);
};

export const currencyFormatWithDecimal = (price: number | null): string => {
    if (price === 0) {
        return '0';
    } else if (!price) {
        return '';
    }
    const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
    });
    let val;
    if (Number.isInteger(price)) {
        val = formatter.format(price);
    } else {
        if (price.toString().endsWith('.')) {
            const decimalLength = (price + '').split('.');
            val = formatter.format(parseInt(decimalLength[0].replaceAll(',', ''))) + '.';
        } else {
            const decimalLength = (price + '').split('.');
            if (decimalLength && decimalLength[1] && decimalLength[1].length > 0) {
                val =
                    formatter.format(parseInt(decimalLength[0].replaceAll(',', ''))) +
                    '.' +
                    decimalLength[1];
            } else {
                val =
                    formatter.format(parseInt(decimalLength[0].replaceAll(',', ''))) +
                    '.' +
                    decimalLength[1].slice(0, 2);
            }
        }
    }
    return val;
};

export const sizeUnit = (sizeFormat: string | null): string => {
    return sizeFormat === 'Sq. Ft' ? 'Sq. Ft' : 'Acre (s)';
};
/*
    12Hr format, AM/PM
*/
export const timeFormatHHMM = (date: string): string | null => {
    const dateCheck = new Date(date);
    if (dateCheck.toDateString() !== 'Invalid Date') {
        return dateCheck.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
    } else {
        return null;
    }
};

export const dateFormatDayMonthYear = (date: string): string | null => {
    const dateCheck = new Date(date);
    if (dateCheck.toDateString() !== 'Invalid Date') {
        const month = dateCheck.toLocaleString('en-us', { month: 'long' });
        const weekDay = dateCheck.toLocaleDateString('en-us', { weekday: 'long' });
        const day = ('0' + dateCheck.getDate()).slice(-2);
        const year = dateCheck.getUTCFullYear();
        return weekDay + ', ' + month + ' ' + day + ', ' + year;
    } else {
        return null;
    }
};

export const dynamicSort = <K extends string, T extends Record<K, number>>(
    property: K,
): ((a: T, b: T) => number) => {
    return function (a: T, b: T) {
        return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
};

export const sortByDate = <K extends string, T extends Record<K, string>>(
    items: T[],
    property: K,
    asc?: boolean,
): T[] | null => {
    let sortedData: T[] = [];
    if (!items || !items.length) {
        return null;
    } else {
        sortedData = loDash.sortBy(items, function (item: T) {
            return new Date(item[property]);
        });
        if (asc === true) {
            return sortedData;
        } else {
            return sortedData.reverse();
        }
    }
};

export const dateFormatMonthYear = (date: string): string | null => {
    const dateCheck = new Date(date);
    if (dateCheck.toDateString() !== 'Invalid Date') {
        const month = dateCheck.toLocaleString('en-us', { month: 'short' });
        const day = ('0' + dateCheck.getDate()).slice(-2);
        const year = dateCheck.getUTCFullYear();
        return month + ' ' + day + ', ' + year;
    } else {
        return null;
    }
};

export const dateFormatUTC = (dateValue?: string): number => {
    const date = dateValue ? new Date(dateValue) : new Date();
    const now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
    );
    return now_utc;
};

export const total = <K extends string, T extends Record<K, number>>(
    collection: T[] | null,
    field: K,
): number => {
    if (collection && collection.length > 0) {
        return collection
            .map((element: T) => element[field])
            .reduce((prev: number, curr: number) => prev + curr, 0);
    }
    return 0;
};

export const rate = (num: number | undefined, den: number | undefined): number => {
    num = num || 0;
    den = den || 0;
    return (num / den) * 100;
};

export const phoneNoFormat = (no?: string, character?: string): string => {
    const spaceRemoved = ('' + no).replace(/\D/g, '');
    const matched = spaceRemoved.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (matched && !character) {
        return matched[1] + '.' + matched[2] + '.' + matched[3];
    } else if (matched && character) {
        return matched[1] + character + matched[2] + character + matched[3];
    } else {
        return '';
    }
};

export const validateEmail = (email: string): RegExpMatchArray | null => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const jsonToFormData = <T>(data: T): FormData => {
    const options = {
        /**
         * include array indices in FormData keys |  defaults to false
         */
        indices: false,
        /**
         * treat null values like undefined values and ignore them | defaults to false
         */
        nullsAsUndefineds: false,
        /**
         * convert true or false to 1 or 0 respectively | defaults to false
         */
        booleansAsIntegers: false,
        /**
         * store arrays even if they're empty | defaults to false
         */
        allowEmptyArrays: false,
        /**
         * don't include array notation in FormData keys for Files in arrays | defaults to false
         */
        noFilesWithArrayNotation: false,
        /**
         * use dots instead of brackets for object notation in FormData keys | defaults to false
         */
        dotsForObjectNotation: false,
    };
    return serialize(
        data,
        options, // optional
    );
};

export const passwordRegEx = {
    upperCase: /(?=.*?[A-Z])/,
    lowerCase: /(?=.*?[a-z])/,
    digits: /(?=.*?[0-9])/,
    specialChar: /(?=.*?[#?!@$%^&*-])/,
    minLength: /.{8,}/,
};

export const validatePassword = (pass: string): boolean => {
    if (
        passwordRegEx.lowerCase.test(pass) &&
        passwordRegEx.upperCase.test(pass) &&
        passwordRegEx.digits.test(pass) &&
        passwordRegEx.specialChar.test(pass) &&
        passwordRegEx.minLength.test(pass)
    ) {
        return true;
    }
    return false;
};

export const focusElement = (fieldId: string): void => {
    const focussedElement = document.getElementById(fieldId);
    if (focussedElement !== null) {
        focussedElement.focus();
    }
};

export const displayData = (
    data: number | string | boolean | null,
    isDate?: boolean,
    twoDigitYear?: boolean,
    showTime?: boolean,
): string | null => {
    if (data === null || data === '' || data === 0) {
        return '-';
    } else {
        return isDate === true
            ? twoDigitYear === true
                ? dateFormatMMDDYYY(data as string, 'slash', true) +
                  `${showTime ? `, ${timeFormatHHMM(data as string)}` : ''}`
                : dateFormatMMDDYYY(data as string) +
                  `${showTime ? `, ${timeFormatHHMM(data as string)}` : ''}`
            : (data as string);
    }
};

export const calculateRemainingChars = (val: string, maxLength?: number): number => {
    return (maxLength || 0) > 0 ? (maxLength || 0) - val.length : 0;
};

export const bedsAndBath = (
    bedrooms?: number | null,
    fullBaths?: number | null,
    halfBaths?: number | null,
): string => {
    const beds = bedrooms || 0;
    const fullBath = fullBaths || 0;
    const halfBath = halfBaths || 0;
    const baths = fullBath + halfBath;

    return (!!beds ? beds : '-') + '/' + (!!baths ? baths : '-');
};

export const findIdFromLookups = (name: unknown, lookups: LookupInfo[]): string => {
    const id = lookups.find((l) => {
        return l.name === name;
    })?.id;
    return id === undefined ? '' : id;
};

export const findNameFromLookups = (id: unknown, lookups: LookupInfo[]): string => {
    const name = lookups.find((l) => {
        return l.id === id;
    })?.name;
    return name === undefined ? '' : name;
};

export const getDimensions = (uploadedFile: File): Promise<DimensionProps> => {
    const imageDimensions = new Image();
    imageDimensions.src = window.URL.createObjectURL(uploadedFile);
    const promise = new Promise<DimensionProps>((resolve, reject) => {
        imageDimensions.onload = () => {
            const width = imageDimensions.width;
            const height = imageDimensions.height;
            resolve({ width, height });
        };
        imageDimensions.onerror = reject;
    });
    return promise;
};

//to store previous useEffect Value
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const calcDate = (
    date1: string | Date,
    date2: string | Date,
): DateDifferenceModel => {
    /*
     * calcDate() : Calculates the difference between two dates
     * @date1 : "First Date in the format MM-DD-YYYY"
     * @date2 : "Second Date in the format MM-DD-YYYY"
     * return : Array
     */

    //new date instance
    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
        calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
        calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    //Retrieve the date, month and year
    const calcFormatTmp =
        calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
    //Convert to an array and store
    const calcFormat = calcFormatTmp.split('-');
    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(+calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(+calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(+calcFormat[2]) - 1970);

    //Set up custom text
    const yrsTxt = ['year', 'years'];
    const mnthsTxt = ['month', 'months'];
    const daysTxt = ['day', 'days'];

    //Convert to days and sum together
    const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;
    const total_secs = total_days * 24 * 60 * 60;
    const total_mins = total_days * 24 * 60;
    const total_hours = total_days * 24;
    const total_weeks = total_days >= 7 ? total_days / 7 : 0;

    //display result with custom text
    const result =
        (years_passed === 1
            ? years_passed + ' ' + yrsTxt[0] + ' '
            : years_passed > 1
            ? years_passed + ' ' + yrsTxt[1] + ' '
            : '') +
        (months_passed === 1
            ? months_passed + ' ' + mnthsTxt[0] + ' '
            : months_passed > 1
            ? months_passed + ' ' + mnthsTxt[1] + ' '
            : '') +
        (days_passed === 1
            ? days_passed + ' ' + daysTxt[0]
            : days_passed > 1
            ? days_passed + ' ' + daysTxt[1]
            : '');

    //return the result
    return {
        total_days: Math.round(total_days),
        total_weeks: Math.round(total_weeks),
        total_hours: Math.round(total_hours),
        total_minutes: Math.round(total_mins),
        total_seconds: Math.round(total_secs),
        result: result.trim(),
    };
};

export const baths = (fullBaths: number, halfBaths: number) => {
    return !!(fullBaths + halfBaths) ? fullBaths + halfBaths : '-';
};

//calculate DOM
export const dateDiffDOM = (date: string, removeHypen = false): string => {
    const currentDate = new Date();
    const rep = calcDate(date, currentDate);
    if (removeHypen) {
        return rep.total_days + ' DOM';
    } else {
        return ' - ' + rep.total_days + ' DOM';
    }
};

/**to form link */
export const displayLink = (data: number | string | boolean | null): string => {
    if (data === null || data === '' || data === 0) {
        return '-';
    } else {
        return `<a href=${data as string} target=${'_blank'}>${data as string}</a>`;
    }
};

/**date picket format */
export const datePickerFormat = (): string[] => {
    return [
        'M-D-YYYY',
        'M/D/YYYY',
        'M/D/YY',
        'M-D-YY',
        'MM/DD/YY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'MM/DD/YYYY',
    ];
};

export const getAbortController = (
    isCancelled: boolean,
    index: number,
): AwsAbortController => {
    if (isCancelled || !abortControllers[index])
        abortControllers[index] = new AwsAbortController();
    return abortControllers[index] as AwsAbortController;
};

// Temporary methods until the fix from back-end
export const displayValueFromLookupForAudit = (
    notes: string | null,
    fieldName: string,
    value: string,
): string => {
    let displayValue = value;
    if (notes?.includes('video')) {
        switch (fieldName) {
            case 'VideoTypeId':
                displayValue = displayVideoTypeFromLookup(Number(value));
                break;
            case 'Status':
                displayValue = displayVideoStatusFromLookup(value);
                break;
        }
    }
    return displayValue;
};

export const displayFieldNameForAudit = (
    notes: string | null,
    fieldName: string,
): string => {
    let displayFieldName = fieldName;
    if (notes && notes.includes('video') && fieldName === 'Status')
        displayFieldName = 'Video Status';
    return displayFieldName;
};

export const displayVideoStatusFromLookup = (status: string): string => {
    return status === '1'
        ? 'Started'
        : status === '2'
        ? 'In Progress'
        : status === '3'
        ? 'Completed'
        : 'Failed';
};

export const displayVideoTypeFromLookup = (videoTypeId: number): string => {
    let videoType = '';
    switch (videoTypeId) {
        case videoTypes.propertyVideo.key:
            videoType = 'Property Video';
            break;
        case videoTypes.secondVideo.key:
            videoType = '15 Second Video';
            break;
        case videoTypes.showcaseVideo.key:
            videoType = 'Showcase Hero Video';
            break;
        case videoTypes.additionalVideoOne.key:
            videoType = 'Additional Video';
            break;
        case videoTypes.agentVideo.key:
            videoType = 'Agent Video';
            break;
        case videoTypes.salesTeamVideo.key:
            videoType = 'Sales Team Video';
            break;
        default:
            videoType = 'Building Video';
            break;
    }
    return videoType;
};

export const numberCommaDisplay = (value: number): string => {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
