import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../app/store';
import { ListingAdCopy } from '../../../../../../shared/models/listing/adCopyModel';
import { AdCopyEvents } from '../../../../../../shared/models/loader/loaderModels';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { getActiveTabListing } from '../../../../listingListSlice';
import { CustomKey } from '../../../listingModels';
import { updateMainListingData, updateListingValidStatus } from '../../../listingSlice';
import {
    createAdCopy,
    getAdCopies,
    getLookups,
    saveListingTitle,
    updateAdCopy,
    updateSuppressMlsImport,
} from './adCopyApi';
import { initialAdCopy } from './adCopyConstants';
import { AdCopyDetails, AdCopyState, LookupData } from './adCopyModels';

const adCopyState: AdCopyState = {
    sizes: [],
    languages: [],
    suppressAdCopyImport: false,
};

export const adCopySlice = createSlice({
    name: 'adCopy',
    initialState: adCopyState,
    reducers: {
        addAdCopy: (state, action: PayloadAction<ListingAdCopy>) => {
            state.adCopies = [action.payload, ...(state.adCopies || [])];
        },
        addAdCopies: (state, action: PayloadAction<AdCopyDetails>) => {
            state.adCopies = action.payload.adCopyData;
            state.suppressAdCopyImport = action.payload.suppressAdCopyImport;
        },
        addLookups: (state, action: PayloadAction<LookupData>) => {
            const { sizes, languages } = action.payload;
            state.sizes = sizes;
            state.languages = languages;
        },
        updateAdCopyList: (state, action: PayloadAction<ListingAdCopy>) => {
            const adCopy = action.payload;
            // If the ad copy has no headline or body, we can delete it
            if (!adCopy.headline?.length && !adCopy.body?.length) {
                state.adCopies = (state.adCopies || []).filter((a) => a.id !== adCopy.id);
            } else {
                state.adCopies = (state.adCopies || []).map((a) =>
                    a.id === adCopy.id ? adCopy : a,
                );
            }
        },
        toggleSuppressMlsImport: (state) => {
            state.suppressAdCopyImport = !state.suppressAdCopyImport;
        },
        resetAdCopyState: (state) => {
            if (state.adCopies) {
                delete state.adCopies;
            }
            state = {
                sizes: [],
                languages: [],
                suppressAdCopyImport: false,
            };
        },
    },
});

export const fetchAdCopies =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(AdCopyEvents.AD_COPY_GET));
            const adCopyDetails = await getAdCopies(listingId);
            dispatch(addAdCopies(adCopyDetails));
        } catch {
            Logger.error(`Error getting ad copies for listing: ${listingId}`);
            dispatch(setError('Error getting ad copies'));
        } finally {
            dispatch(removeLoadingEvent(AdCopyEvents.AD_COPY_GET));
        }
    };

export const fetchLookupData = (): AppThunk => async (dispatch) => {
    const lookups = await getLookups();
    dispatch(addLookups(lookups));
};

export const saveAdCopy =
    (request: ListingAdCopy, bodyLength: number, remainingChars: number): AppThunk =>
    async (dispatch) => {
        let adCopyData: ListingAdCopy = initialAdCopy;
        try {
            dispatch(addLoadingEvent(AdCopyEvents.AD_COPY_SAVE));
            if (remainingChars >= 0) {
                if (request.id) {
                    adCopyData = await updateAdCopy(request);
                    dispatch(updateAdCopyList(request));
                } else {
                    //to delete the id fields
                    delete request.id;
                    adCopyData = await createAdCopy(request);
                    if (!adCopyData.id) {
                        throw Error('Error creating ad copy');
                    } else {
                        dispatch(addAdCopy(adCopyData));
                    }
                }
                dispatch(
                    updateListingValidStatus({
                        entityIsValid: adCopyData.entityIsValid,
                        validationMessages: adCopyData.validationMessages,
                    }),
                );
                dispatch(setSuccess('Ad copy saved successfully'));
            } else {
                dispatch(
                    setError(
                        `Ad Copy is over ${bodyLength} characters and will not be saved, please reduce the character count to save your changes`,
                    ),
                );
            }
        } catch {
            Logger.error(`Error saving ad copy: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving ad copy'));
        } finally {
            dispatch(removeLoadingEvent(AdCopyEvents.AD_COPY_SAVE));
        }
    };

export const saveSuppressAdCopyImport =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(toggleSuppressMlsImport());
            await updateSuppressMlsImport(listingId);
            //to update listing in the listing list section
            dispatch(getActiveTabListing(listingId, 'suppressAdCopyImport'));
            dispatch(setSuccess('MLS import flag updated successfully'));
        } catch {
            Logger.error('Error toggling suppress ad copy MLS import');
            dispatch(setError('Error toggling suppress ad copy MLS import'));
        }
    };

export const updateListingTitle =
    (listingId: string, title: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(AdCopyEvents.LISTING_TITLE_SAVE));
            const response = await saveListingTitle(listingId, title);
            const mainStateUpdate: CustomKey = {
                title: title,
            };
            dispatch(updateMainListingData(mainStateUpdate));
            dispatch(setSuccess('Listing title is updated'));

            dispatch(updateListingValidStatus(response));
        } catch (err) {
            Logger.error(`Error updating listing title: ${JSON.stringify(err)}`);
            dispatch(setError('Error updating listing title'));
        } finally {
            dispatch(removeLoadingEvent(AdCopyEvents.LISTING_TITLE_SAVE));
        }
    };

export const {
    addAdCopy,
    addAdCopies,
    addLookups,
    updateAdCopyList,
    toggleSuppressMlsImport,
    resetAdCopyState,
} = adCopySlice.actions;

export const adCopy = (state: RootState): AdCopyState => state.listing.adCopy;

export default adCopySlice.reducer;
