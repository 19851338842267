import { entityTypes } from '../../../shared/constants/entityTypes';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import { initializeVideoData } from '../../../shared/functions/video/video';
import { TableHeadCell } from '../../../shared/models/customTable/customTableModels';
import { DevelopmentDetailsData, DevelopmentUnits } from './developmentDetailsModel';
import DevelopmentAdcopyEdit from './drawer/screens/developmentAdcopyEdit/DevelopmentAdcopyEdit';
import DevelopmentAgentsEdit from './drawer/screens/developmentAgentsEdit/DevelopmentAgentsEdit';
import DevelopmentAssociatedListingEdit from './drawer/screens/developmentAssociatedListings/DevelopmentAssociatedListings';
import DevelopmentDetailsEdit from './drawer/screens/developmentDetailsEdit/DevelopmentDetailsEdit';
import DevelopmentFeaturesEdit from './drawer/screens/developmentFeaturesEdit/DevelopmentFeaturesEdit';
import DevelopmentLogo from './drawer/screens/developmentLogo/DevelopmentLogo';
import DevelopmentPhotosEdit from './drawer/screens/developmentPhotosEdit/DevelopmentPhotosEdit';
import DevelopmentVideosEdit from './drawer/screens/developmentVideosEdit/DevelopmentVideosEdit';

export const initialDevelopmentUnits: DevelopmentUnits = {
    buildingId: '',
    numberOfActiveUnits: 0,
    totalActiveUnits: 0,
    numberOfPendingUnits: 0,
    totalPendingUnits: 0,
    numberOfSoldUnits: 0,
    totalSoldUnits: 0,
};

export const initialDevelopmentDetails: DevelopmentDetailsData = {
    id: '',
    title: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    office: '',
    officeId: '',
    statusId: 0,
    status: '',
    siraDevelopmentId: '',
    interiorSize: 0,
    exteriorSize: 0,
    lotSize: 0,
    lotSizeTypeId: 0,
    yearBuilt: 0,
    adCopyBody: '',
    adCopyHeadline: '',
    websiteUrl: '',
    listDate: '',
    expirationDate: '',
    agreementDate: '',
    lastUpdated: '',
    maxFinancing: 0,
    minimumPricePerUnit: 0,
    totalDollarValue: 0,
    totalUnits: 0,
    isExclusiveListingAgent: false,
    images: [],
    floorPlanImages: [],
    logo: [],
    videoDetail: initializeVideoData(
        entityTypes.building.key,
        videoTypes.buildingVideo.key,
        1,
    ),
    agents: [],
    listings: [],
    ownerFirstName: '',
    ownerLastName: '',
    regionId: 0,
    latitude: null,
    longitude: null,
    amenities: [],
    publish: false,
    units: initialDevelopmentUnits,
};

export const editFormComponent = {
    developmentDetails: 'DevelopmentDetailsComp',
    developmentLogo: 'DevelopmentLogo',
    developmentFeatures: 'DevelopmentFeatures',
    developmentAgents: 'DevelopmentAgents',
    developmentAdCopy: 'DevelopmentAdCopy',
    developmentPhotos: 'DevelopmentPhotos',
    developmentVideos: 'DevelopmentVideos',
    developmentAssociatedListings: 'DevelopmentAssociatedListings',
};

export const editFormMap = new Map([
    [
        editFormComponent.developmentDetails,
        {
            component: DevelopmentDetailsEdit,
            title: 'Development Details',
        },
    ],
    [
        editFormComponent.developmentLogo,
        {
            component: DevelopmentLogo,
            title: 'Development Logo',
        },
    ],
    [
        editFormComponent.developmentFeatures,
        {
            component: DevelopmentFeaturesEdit,
            title: 'Development Features',
        },
    ],
    [
        editFormComponent.developmentAgents,
        {
            component: DevelopmentAgentsEdit,
            title: 'Development Agents',
        },
    ],
    [
        editFormComponent.developmentAdCopy,
        {
            component: DevelopmentAdcopyEdit,
            title: 'Development Ad Copy',
        },
    ],
    [
        editFormComponent.developmentPhotos,
        {
            component: DevelopmentPhotosEdit,
            title: 'Development Photos',
        },
    ],
    [
        editFormComponent.developmentVideos,
        {
            component: DevelopmentVideosEdit,
            title: 'Development Videos',
        },
    ],
    [
        editFormComponent.developmentAssociatedListings,
        {
            component: DevelopmentAssociatedListingEdit,
            title: 'Development Associated Listings',
        },
    ],
]);

export const associatedListingsTableHeadCells: TableHeadCell[] = [
    {
        id: 'address1',
        label: 'Address',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'currentPrice',
        label: 'Current Price',
        dbColMapping: '',
        doSort: false,
        isPrice: true,
    },
    {
        id: 'businessPurposeTypeId',
        label: 'Type',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'isValid',
        label: 'Data Complete',
        dbColMapping: '',
        customIcon: true,
        actionType: 'isValid',
        doSort: false,
    },
    {
        id: 'mlsNumber',
        label: 'MLS Number',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'listingId',
        label: 'Remove Listing',
        dbColMapping: '',
        customIcon: true,
        actionType: 'delete',
        doSort: false,
    },
];

export const minLogoDim = {
    minWidth: 70,
    minHeight: 70,
};
