import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    ActivityAndReportingState,
    MarketingReminder,
    DashListingDetails,
} from './activityAndReportingModels';
import {
    getMarketingReminders,
    saveMarketingReminderOptOut,
    getDashListingDetails,
    sendListingToDashQueue,
} from './activityAndReportingApi';
import { MarketingReminderOptOutStatusModel } from '../listingModels';
import { AppThunk, RootState } from '../../../../app/store';
import Logger from '../../../../utils/logging/logger';
import { setError, setSuccess } from '../../../../shared/slices/messaging/messagingSlice';

const initialState: ActivityAndReportingState = {
    marketingReminder: null,
    dashDetails: {
        lastError: null,
        lastExported: null,
        maxUpdatedDate: null,
    },
    invalidListing: false,
    isLoading: false,
};

export const activityAndReportingSlice = createSlice({
    name: 'activityAndReporting',
    initialState: initialState,
    reducers: {
        updateLoadingStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setMarketingReminder: (state, action: PayloadAction<MarketingReminder[]>) => {
            return {
                ...state,
                marketingReminder: action.payload,
            };
        },
        removeOptedOutMarketingRemainder: (state, action: PayloadAction<number>) => {
            const data = (state.marketingReminder || []).map(
                (reminder: MarketingReminder) =>
                    reminder.id === action.payload
                        ? { ...reminder, userOptedOut: !reminder.userOptedOut }
                        : reminder,
            );
            return {
                ...state,
                marketingReminder: data,
            };
        },
        updateDashListing: (state, action: PayloadAction<DashListingDetails>) => {
            return {
                ...state,
                dashDetails: action.payload,
                invalidListing: false,
            };
        },
        setInvalidListing: (state) => {
            return {
                ...state,
                invalidListing: true,
                dashDetails: initialState.dashDetails,
            };
        },
    },
});

export const fetchMarketingReminder =
    (id: string, userId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingStatus(true));
            const marketingReminders = await getMarketingReminders(id, userId);
            if (marketingReminders.length > 0) {
                dispatch(setMarketingReminder(marketingReminders));
            }
        } catch (e) {
            Logger.error('Error fetching marketing reminders');
            dispatch(setError('Error fetching Marketing Reminders'));
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const marketingRemainderStatusUpdate =
    (data: MarketingReminderOptOutStatusModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveMarketingReminderOptOut(data);
            if (response.status === 200) {
                dispatch(removeOptedOutMarketingRemainder(data.marketingReminderId));
                dispatch(setSuccess(`Notification Removed.`));
            }
        } catch (error) {
            Logger.error('Error remainders');
            dispatch(setError('Error opting out reminder'));
        }
    };

export const fetchDashListingDetails =
    (listingNumber: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingStatus(true));
            const response = await getDashListingDetails(listingNumber);
            dispatch(updateDashListing(response));
        } catch {
            dispatch(setInvalidListing());
            dispatch(setError('Error fetching DASH listing details'));
            Logger.error('Error fetching DASH listing details');
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const sendToDash =
    (listingNumber: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingStatus(true));
            await sendListingToDashQueue(listingNumber);
            dispatch(
                setSuccess(
                    'Listing queued successfully.  Check back shortly to see its status.',
                ),
            );
        } catch {
            dispatch(setError('Error sending to Dash'));
            Logger.error('Error sending to Dash');
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

export const {
    updateLoadingStatus,
    setMarketingReminder,
    removeOptedOutMarketingRemainder,
    updateDashListing,
    setInvalidListing,
} = activityAndReportingSlice.actions;
export const activityAndReporting = (state: RootState): ActivityAndReportingState =>
    state.listing.activityReport;

export default activityAndReportingSlice.reducer;
