import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import {
    postCall,
    postFormData,
    deleteCall,
    getCall,
    postCallRaw,
} from '../../../utils/api/apiWrapper';
import { PressItemEdit } from './component/createAndEdit/createEditPressModels';
import { PressItemSearchRequest, PressItemList } from './pressListModels';
import { AgentsResponse } from '../../agents/agentListModels';
import {
    ListingSearchRequest,
    SearchedListingListModel,
} from '../../../shared/models/listing/commonModel';

export function searchListings(
    request: ListingSearchRequest,
): Promise<PaginationResponse<SearchedListingListModel>> {
    return postCall<ListingSearchRequest, PaginationResponse<SearchedListingListModel>>(
        `listing/search`,
        request,
    );
}

export function getPressItemFromApi(
    pressItemRequest: PressItemSearchRequest,
): Promise<PaginationResponse<PressItemList>> {
    return postCall<PressItemSearchRequest, PaginationResponse<PressItemList>>(
        `Press/search`,
        pressItemRequest,
    );
}

export function createEditPressItem(data: FormData): Promise<PressItemList> {
    return postFormData<PressItemList>('Press', data);
}

export function deletePressItemApi(id: string): Promise<Response> {
    return deleteCall(`Press/${id}`);
}

export function getAgentsForListing(id: string): Promise<AgentsResponse[]> {
    return getCall<AgentsResponse[]>(`Press/${id}/detailsummary`);
}

export function getPressItemDetails(id: string): Promise<PressItemEdit> {
    return getCall<PressItemEdit>(`Press/${id}/pressdetail`);
}

export function exportPressItemSearchResults(
    request: PressItemSearchRequest,
): Promise<Response> {
    const url = `Press/search/export`;
    return postCallRaw<PressItemSearchRequest>(url, request);
}
