import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    getAdvertisementLookupsApi,
    getCommonLookupsApi,
    getListingLookupsApi,
    getOfficeLookupsApi,
    getDashRolesLookupsApi,
    getOtherMarketingLookupsApi,
    getDevelopmentLookupsApi,
} from '../../api/lookups/lookupsApi';
import { marketingTypes, statuses } from '../../constants/lookups/lookupsConstants';
import {
    LookupsKeyState,
    LookupsState,
    UpdateLookups,
} from '../../models/lookups/lookupsModels';
const lookupsState: LookupsState | LookupsKeyState = {
    common: {
        languages: [],
        offices: [],
        salutations: [],
        states: [],
        statuses: [],
        notifications: [],
    },
    listing: {
        propertyTypes: [],
        statuses: [],
    },
    officeLook: {
        availableManagers: [],
        officeSpecializations: [],
        officeSalesRegions: [],
    },
    advertisementLook: {
        advertisementTypes: [],
        billingCodes: [],
        advertisementFrequencyTypes: [],
    },
    dashRolesLook: {
        dashRoleType: [],
    },
    otherMarketingLook: {
        marketingTypes: marketingTypes,
        additionalPromotionTypes: [],
        creditMemoTypes: [],
    },
    developmentLook: {
        buildings: [],
        statuses: [],
    },
};

export const lookupsSlice = createSlice({
    name: 'lookups',
    initialState: lookupsState,
    reducers: {
        updateLookups: (state, action: PayloadAction<UpdateLookups>) => {
            state[action.payload.key as keyof typeof state] = action.payload.value;
        },
    },
});

export const getCommonLookups = (): AppThunk => async (dispatch) => {
    const response = await getCommonLookupsApi();
    const { languages, offices, salutations, states, notifications } = response;
    if (
        languages.length ||
        offices.length ||
        salutations.length ||
        states.length ||
        notifications.length
    ) {
        dispatch(
            updateLookups({
                key: 'common',
                value: { ...response, statuses: statuses },
            }),
        );
    }
};

export const getListingLookups = (): AppThunk => async (dispatch) => {
    const response = await getListingLookupsApi();
    const { propertyTypes, statuses } = response;
    if (propertyTypes.length > 0 || statuses.length > 0) {
        dispatch(
            updateLookups({
                key: 'listing',
                value: response,
            }),
        );
    }
};

export const getOfficeLookups = (): AppThunk => async (dispatch) => {
    const response = await getOfficeLookupsApi();
    const { availableManagers, officeSalesRegions, officeSpecializations } = response;
    if (
        availableManagers.length ||
        officeSalesRegions.length ||
        officeSpecializations.length
    ) {
        dispatch(
            updateLookups({
                key: 'officeLook',
                value: response,
            }),
        );
    }
};

export const getAdvertisementLookups = (): AppThunk => async (dispatch) => {
    const response = await getAdvertisementLookupsApi();
    const { advertisementFrequencyTypes, advertisementTypes, billingCodes } = response;
    if (
        advertisementFrequencyTypes.length ||
        advertisementTypes.length ||
        billingCodes.length
    ) {
        dispatch(
            updateLookups({
                key: 'advertisementLook',
                value: response,
            }),
        );
    }
};

export const getDashRolesLookups = (): AppThunk => async (dispatch) => {
    const response = await getDashRolesLookupsApi();
    const { dashRoleType } = response;
    if (dashRoleType.length) {
        dispatch(
            updateLookups({
                key: 'dashRolesLook',
                value: response,
            }),
        );
    }
};

export const getOtherMarketingLookups = (): AppThunk => async (dispatch) => {
    const response = await getOtherMarketingLookupsApi();
    const { additionalPromotionTypes, creditMemoTypes } = response;
    if (additionalPromotionTypes.length || creditMemoTypes.length) {
        dispatch(
            updateLookups({
                key: 'otherMarketingLook',
                value: { ...response, marketingTypes: marketingTypes },
            }),
        );
    }
};

export const getDevelopmentLookups =
    (officeIds: string[]): AppThunk =>
    async (dispatch) => {
        const response = await getDevelopmentLookupsApi({ officeIds: officeIds });
        const { buildings, statuses } = response;
        if (buildings.length || statuses.length) {
            dispatch(
                updateLookups({
                    key: 'developmentLook',
                    value: response,
                }),
            );
        }
    };

export const { updateLookups } = lookupsSlice.actions;

export const lookups = (state: RootState): LookupsState | LookupsKeyState =>
    state.lookups;

export default lookupsSlice.reducer;
