import { Grid, Typography } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DrawerContainerProps } from '../../../salesTeamModels';
import './members.scss';
import {
    AgentDNDItem,
    AgentSearchResult,
} from '../../../../../../shared/models/agent/agentModel';
import { useAppDispatch, useDebounce } from '../../../../../../app/hooks';
import {
    addAgentToSalesTeam,
    updateAgentDisplayOrderFromSalesTeam,
    deleteAgentFromSalesTeam,
    setSearchedMembers,
    fetchAgent,
} from '../../../salesTeamSlice';
import { itemTypes } from '../../../../../../shared/constants/dragAndDrop/itemTypes';
import { reorderItems } from '../../../../../../shared/functions/entity/agent/dragAndDrop/AgentDNDFunction';

import GridItem from '../../../../../../shared/component/entity/agent/dragAndDrop/GridItem';
import SearchAutoComplete from '../../../../../../shared/component/searchAutoComplete';

const Members: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
    const { data, searchedMembers, flags } = editFormData;
    const { id, teamMembers } = data;
    const { searchingMembers } = flags;
    const [salesTeam, setSalesTeam] = useState<AgentDNDItem[]>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (debouncedSearchTerm !== '') dispatch(fetchAgent(debouncedSearchTerm));
    }, [dispatch, debouncedSearchTerm]);

    const moveItem = (
        dragIndex: number,
        hoverIndex: number,
        isDropped: boolean,
        entity: string,
    ) => {
        if (entity === itemTypes.salesTeamMember) {
            if (dragIndex !== hoverIndex) {
                setSalesTeam(reorderItems(dragIndex, hoverIndex, salesTeam));
            }
            if (isDropped)
                dispatch(
                    updateAgentDisplayOrderFromSalesTeam(
                        id || '',
                        salesTeam,
                        teamMembers,
                    ),
                );
        }
    };

    const addSelectedAgentToSalesTeam = (selectedAgent: AgentSearchResult | null) => {
        if (selectedAgent) {
            const agentId = selectedAgent.agentId;
            dispatch(addAgentToSalesTeam(id || '', agentId));
        }
        dispatch(setSearchedMembers([]));
    };

    const deleteItem = (agentId: string, entity: string) => {
        if (entity === itemTypes.salesTeamMember)
            dispatch(deleteAgentFromSalesTeam(id || '', agentId));
    };

    useEffect(() => {
        const agents = teamMembers.map((teamMember) => {
            return {
                agentId: teamMember.id,
                name: teamMember.name,
                office: teamMember.office,
                displayOrder: teamMember.displayOrder,
            };
        });
        setSalesTeam(agents);
    }, [teamMembers]);

    const autoCompleteBlur = () => {
        if (searchedMembers.length) dispatch(setSearchedMembers([]));
    };

    return (
        <Grid container className="members-container">
            <Grid item xs={12} md={12}>
                <Typography className="sales-team-note">
                    Sales Team may be represented by any number of agents. To select an
                    agent, type their last name into the textbox below. A filtered list of
                    agents will appear below. Click the name of the agent to add them to
                    the list and Team members order can be updated via Drag and Drop
                </Typography>
                <Typography className="sales-team-note dragNote">
                    Note: 2 or more team members must always be assigned to a team for it
                    to be active, even if the change is temporary a team will be
                    deactivated if there are less than two members.
                </Typography>

                <SearchAutoComplete
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    results={searchedMembers}
                    userSelected={addSelectedAgentToSalesTeam}
                    blurEvent={autoCompleteBlur}
                    isLoading={searchingMembers}
                    from="salesTeamMember"
                />
            </Grid>
            {salesTeam?.length ? (
                <Grid container className="sales-team-agents-container" spacing={2}>
                    <DndProvider backend={HTML5Backend}>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12} md={1} className="sales-team-agent-header">
                                ORDER
                            </Grid>
                            <Grid item xs={12} md={4} className="sales-team-agent-header">
                                AGENT NAME
                            </Grid>
                            <Grid item xs={12} md={6} className="sales-team-agent-header">
                                OFFICE NAME
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                        </Grid>
                        {salesTeam.map((s, i) => (
                            <GridItem
                                key={s.agentId}
                                index={i}
                                item={s}
                                moveItem={moveItem}
                                deleteItem={deleteItem}
                                entity={itemTypes.salesTeamMember}
                            />
                        ))}
                    </DndProvider>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};
export default Members;
