import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import { MarketingEvents } from '../../shared/models/loader/loaderModels';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../shared/slices/loader/loaderSlice';
import Logger from '../../utils/logging/logger';
import { jsonToFormData } from '../../utils/urlUtils';
import {
    getListingMarketing,
    getAgentMarketing,
    deleteEntityMarketing,
} from './marketingApi';
import { MarketingState } from './marketingModels';
import {
    getMarketingTypes,
    getMarketing,
    addMarketing,
    updateMarketing,
    deleteFile,
} from '../../shared/api/marketing/marketingApis';
import {
    EmailCampaignData,
    MarketingData,
    MarketingFields,
    MarketingFileData,
    marketingTypeIds,
    MarketingTypes,
    SocialCampaignData,
} from '../../shared/models/marketing/marketingModels';

const initialState: MarketingState = {
    marketingList: null,
    drawerOpen: false,
    formModified: false,
    listingId: '',
};

const mapMarketingTypeToUrl = (marketingTypeId?: number | undefined) => {
    const idAsString = marketingTypeId?.toString();
    if (idAsString === marketingTypeIds.emailCampaign.toString()) {
        return 'emailcampaign';
    } else if (idAsString === marketingTypeIds.socialCampaign.toString()) {
        return 'socialcampaign';
    } else {
        return 'marketing';
    }
};

export const marketingSlice = createSlice({
    name: 'marketing',
    initialState: initialState,
    reducers: {
        setMarketingList: (state, action: PayloadAction<MarketingFields[]>) => {
            state.marketingList = action.payload;
        },
        setListingId: (state, action: PayloadAction<string>) => {
            state.listingId = action.payload;
        },
        toggleDrawer: (state, action: PayloadAction<boolean>) => {
            state.drawerOpen = action.payload;

            // Reset data when closing the edit form
            if (!action.payload) {
                state.marketing = null;
                state.formModified = false;
                state.listingId = '';
            }
        },
        setMarketingTypes: (state, action: PayloadAction<MarketingTypes[]>) => {
            state.marketingTypes = action.payload.filter((m) => m.isTopLevel);
            state.additionalPromotionTypes = action.payload.filter((m) => !m.isTopLevel);
        },
        setMarketing: (state, action: PayloadAction<MarketingData>) => {
            state.marketing = action.payload;
        },
        setMarketingFile: (state, action: PayloadAction<MarketingFileData>) => {
            const data: MarketingData | EmailCampaignData | SocialCampaignData = {
                ...state.marketing,
                ...action.payload,
            } as MarketingData;
            state.marketing = data;
        },
        setFormModified: (state, action: PayloadAction<boolean>) => {
            state.formModified = action.payload;
        },
        updateMarketingList: (
            state,
            action: PayloadAction<{
                editing: boolean;
                marketing: MarketingData | EmailCampaignData | SocialCampaignData;
            }>,
        ) => {
            const { editing, marketing } = action.payload;
            if (editing) {
                state.marketingList = (state.marketingList || []).map((lm) => {
                    return lm.id === marketing.id ? { ...lm, ...marketing } : lm;
                });
            } else {
                const listingMarketing: MarketingFields = {
                    id: marketing.id || '',
                    description: marketing.description || '',
                    date: marketing.date || '',
                    marketingTypeId: marketing.marketingTypeId || 0,
                    isFile: marketing.isFile,
                };
                state.marketingList = (state.marketingList || []).concat([
                    listingMarketing,
                ]);
            }
        },
        removeMarketing: (state, action: PayloadAction<string>) => {
            state.marketingList =
                state.marketingList?.filter((lm) => lm.marketingId !== action.payload) ||
                [];
        },
    },
});

/**
 * Get all marketing associated with the given listing
 * @param id ID of the listing to retrieve marketing for
 */
export const fetchListingMarketing =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(MarketingEvents.MARKETING_GET));
            const marketing = await getListingMarketing(id);
            dispatch(setMarketingList(marketing));
            dispatch(setListingId(id));
        } catch (e) {
            Logger.error('Error fetching listing marketing');
            dispatch(setError('Error fetching listing marketing'));
        } finally {
            dispatch(removeLoadingEvent(MarketingEvents.MARKETING_GET));
        }
    };

/**
 * Fetches all marketing types in the system
 */
export const fetchMarketingTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(addLoadingEvent(MarketingEvents.MARKETING_GET));
        const marketingTypes = await getMarketingTypes();
        dispatch(setMarketingTypes(marketingTypes));
    } catch (e) {
        Logger.error('Error fetching marketing types');
        dispatch(setError('Error fetching marketing types'));
    } finally {
        dispatch(removeLoadingEvent(MarketingEvents.MARKETING_GET));
    }
};

/**
 * Fetches data for the marketing record with the given ID
 * @param id ID of the marketing item to retrieve
 * @param marketingTypeId ID of the marketing type of the item we're retrieving
 */
export const fetchMarketing =
    (id: string, marketingTypeId?: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(MarketingEvents.MARKETING_GET));
            const urlName = mapMarketingTypeToUrl(marketingTypeId);
            const marketing = await getMarketing(urlName, id);
            dispatch(setMarketing(marketing));
        } catch (e) {
            Logger.error('Error fetching marketing');
            dispatch(setError('Error fetching marketing'));
        } finally {
            dispatch(removeLoadingEvent(MarketingEvents.MARKETING_GET));
        }
    };

/**
 * Saves a marketing record, and ensure it's associated with the given entity
 * @param entityType Type of entity marketing is being added to (listing, agent)
 * @param entityId The ID of the entity the marketing is associated with (listing or agent ID)
 * @param marketing The marketing data to persist
 */
export const saveMarketing =
    (
        entityType: string,
        entityId: string,
        marketing:
            | MarketingData
            | EmailCampaignData
            | SocialCampaignData
            | null
            | undefined,
    ): AppThunk =>
    async (dispatch) => {
        try {
            if (marketing === null) {
                throw new Error('No marketing data sent');
            }

            dispatch(addLoadingEvent(MarketingEvents.MARKETING_SAVE));
            const data = jsonToFormData(marketing);
            const editing = (marketing?.id?.length || 0) > 0;
            const urlName = mapMarketingTypeToUrl(marketing?.marketingTypeId);

            let saveResult = marketing;
            if (editing) {
                saveResult = await updateMarketing(urlName, marketing?.id || '', data);
            } else {
                saveResult = await addMarketing(urlName, entityId, data, entityType);
            }

            dispatch(updateMarketingList({ editing, marketing: saveResult }));
            dispatch(setSuccess('Marketing saved successfully'));
        } catch (e) {
            Logger.error('Error saving marketing');
            dispatch(setError('Error saving marketing'));
        } finally {
            dispatch(removeLoadingEvent(MarketingEvents.MARKETING_SAVE));
        }
    };

/**
 * Delets a marketing file from AWS and empties the appropriate db fields
 * @param fileData Request containing data needed to delete the file
 */
export const deleteMarketingFile =
    (fileData: MarketingFields): AppThunk =>
    async (dispatch) => {
        try {
            const marketingId = fileData.marketingId || fileData.id;
            if (marketingId) {
                const response = await deleteFile(marketingId);
                if (response.status === 204) {
                    dispatch(setSuccess('File successfully deleted'));
                    dispatch(
                        setMarketingFile({
                            file: undefined,
                            isFile: false,
                            url: undefined,
                        }),
                    );
                } else {
                    throw Error('failed');
                }
            }
        } catch (e) {
            dispatch(setError('Failed to delete file from server'));
        }
    };

/**
 * Deletes a marketing item from an entity
 * @param entityName Name of the entity  to delete the marketing from (agent or listing)
 * @param entityId ID of the entity to delete the marketing from (agent or listing ID)
 * @param marketingId ID of the marketing item (or email/social campaign) being removed
 */
export const deleteMarketingFromEntity =
    (entityName: string, entityId: string, marketingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(MarketingEvents.MARKETING_DELETE));
            if (
                window.confirm(
                    `Are you sure you want to remove this marketing item from the ${entityName}?`,
                )
            ) {
                const response = await deleteEntityMarketing(
                    entityName,
                    entityId,
                    marketingId,
                );
                if (response.status === 204) {
                    dispatch(
                        setSuccess(
                            `Marketing successfully removed from the ${entityName}`,
                        ),
                    );
                    dispatch(removeMarketing(marketingId));
                } else {
                    throw Error('Marketing delete failed');
                }
            }
        } catch (e) {
            Logger.error('Failed to delete marketing item');
            dispatch(setError('Failed to delete marketing item'));
        } finally {
            dispatch(removeLoadingEvent(MarketingEvents.MARKETING_DELETE));
        }
    };

/**
 * Get all marketing associated with the given agent
 * @param id ID of the agent to retrieve marketing for
 */
export const fetchAgentMarketing =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(MarketingEvents.MARKETING_GET));
            const marketing = await getAgentMarketing(id);
            dispatch(setMarketingList(marketing));
            dispatch(setListingId(id));
        } catch (e) {
            Logger.error('Error fetching listing marketing');
            dispatch(setError('Error fetching listing marketing'));
        } finally {
            dispatch(removeLoadingEvent(MarketingEvents.MARKETING_GET));
        }
    };

export const {
    setMarketingList,
    setListingId,
    toggleDrawer,
    setMarketingTypes,
    setMarketing,
    setMarketingFile,
    setFormModified,
    updateMarketingList,
    removeMarketing,
} = marketingSlice.actions;
export const marketingDetails = (state: RootState): MarketingState => state.marketing;

export default marketingSlice.reducer;
