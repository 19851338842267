import { postCall, tridentPostCall } from '../../utils/api/apiWrapper';
import {
    AgentListingListModel,
    AgentListModelResponse,
    AgentProductionModel,
    AgentScheduleModel,
} from './myBusinessModel';

export function setAgentListingsListApi(
    request: Partial<AgentListingListModel>,
): Promise<AgentListModelResponse> {
    return postCall<Partial<AgentListingListModel>, AgentListModelResponse>(
        `listing/search`,
        request,
    );
}

export function getAgentScheduleApi(tridentAgentId: number): Promise<AgentScheduleModel> {
    return tridentPostCall<{ AgentId: string }, AgentScheduleModel>(`agent/schedule`, {
        AgentId: tridentAgentId.toString(),
    });
}

export function getAgentProductionApi(
    tridentAgentId: number,
): Promise<AgentProductionModel[]> {
    return tridentPostCall<{ AgentId: string }, AgentProductionModel[]>(
        `agent/production`,
        {
            AgentId: tridentAgentId.toString(),
        },
    );
}
