import { FunctionComponent } from 'react';
import { showCaseWebsiteStyle } from './style';
import NewDomain from './newDomain';
import DomainDetails from './domainDetails';
import { listingShowCase } from './showcaseWebsiteSlice';
import { DrawerContainerProps } from '../../../listingModels';
import { useAppSelector } from '../../../../../../app/hooks';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';

const ShowcaseWebsite: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const classes = showCaseWebsiteStyle();
    const { purchaseStatus, renewStatus, redeemStatus, releaseStatus } =
        useAppSelector(listingShowCase);
    return (
        <div className={classes.showcaseFormContainer}>
            <ComponentWithLoader
                showLoader={
                    purchaseStatus || renewStatus || redeemStatus || releaseStatus
                }
                showOverlay={true}
                styleClass={classes.loaderClass}
                loadingText={`${
                    purchaseStatus
                        ? 'Purchasing'
                        : renewStatus
                        ? 'Renewing'
                        : redeemStatus
                        ? 'Redeeming'
                        : releaseStatus
                        ? 'Releasing'
                        : ''
                } domain...`}
            />
            {editFormData.data.domainInfo?.domainUrl ? <DomainDetails /> : <NewDomain />}
        </div>
    );
};

export default ShowcaseWebsite;
