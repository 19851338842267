import {
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { FunctionComponent, useEffect } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
    Order,
    TableColumnCell,
    TableHeadCell,
} from '../../models/customTable/customTableModels';
import { currencyFormat, displayData, phoneNoFormat } from '../../../utils/urlUtils';
import './customTable.scss';
import { status } from '../../constants/lookups/lookupsConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
interface CustomTableProps {
    /* api load status */
    isLoading?: boolean;
    /* Indicates the total number of columns in the table header */
    tableHeadCells: TableHeadCell[];
    /* Current page number */
    page?: number;
    /* Current sorting order either ascending or descending */
    order?: Order;
    /* Indicates the field used for sorting */
    orderBy?: string;
    /* Indicates the total number of cells for every row created dynamically */
    data: TableColumnCell[][];
    /* Handle sorting functionality for columns */
    handleRequestSort?: (property: string) => void;
    /* Navigate to the respective agent / listing / office / sales team based on the ID */
    handleNavigation?: (id: string) => void;
    /* load more data for infinite scrolling */
    loadMore?: () => void;
    /* emit custom icon action */
    customIconAction?: (data: TableColumnCell) => void;
    customComponentAction?: (data: TableColumnCell) => void;
    /**new tab redirection */
    navigationUrl?: string;
    /**custom classname */
    customClassName?: string;
}

const CustomTable: FunctionComponent<CustomTableProps> = ({
    isLoading,
    tableHeadCells,
    page,
    order,
    orderBy,
    data,
    handleRequestSort,
    handleNavigation,
    loadMore,
    customIconAction,
    customComponentAction,
    navigationUrl,
    customClassName,
}) => {
    const { ref } = useInView({
        triggerOnce: true,
        onChange: (inView: boolean) => {
            if (inView) {
                loadMore?.();
            }
        },
    });

    useEffect(() => {
        if (page === 1) {
            const tableClass = document.getElementById('custom-table');
            tableClass?.scrollTo(0, 0);
        }
    }, [page]);

    const action = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        data: TableColumnCell,
    ) => {
        e.preventDefault();
        e.stopPropagation();
        if (data.actionType && customIconAction) {
            customIconAction(data);
        }
    };

    const deactivate = (
        event: React.ChangeEvent<HTMLInputElement>,
        column: TableColumnCell,
    ) => {
        event.preventDefault();
        event.stopPropagation();
        customComponentAction?.(column);
    };

    const handleRequestSortCustom = (id: string) => {
        if (id && handleRequestSort) {
            handleRequestSort(id);
        }
    };

    const handleNavigationCustom = (url: string) => {
        if (url && handleNavigation) {
            handleNavigation(url);
        }
    };

    const returnNeededData = (c: TableColumnCell) => {
        if (c.checkbox) {
            if (c.column) {
                return <FontAwesomeIcon icon={faCheck} className="green" />;
            } else {
                return <FontAwesomeIcon icon={faXmark} className="red" />;
            }
        } else if (c.icon) {
            return (
                <FiberManualRecordIcon
                    className={`status-${
                        c.column === status.active || c.column === true
                            ? 'active'
                            : 'inactive'
                    }`}
                />
            );
        } else if (c.customIcon) {
            return (
                <FontAwesomeIcon
                    className={
                        customClassName === 'assignListing' && c.customIcon === faPlus
                            ? 'plus fileIcon'
                            : 'fileIcon'
                    }
                    icon={c.customIcon}
                    onClick={(e) => action(e, c)}
                />
            );
        } else if (c.customComponent) {
            return (
                <div className="customSwitch">
                    <Switch
                        className="deactivate"
                        checked={(c.column as number) === 1 ? true : false}
                        onChange={(e) => deactivate(e, c)}
                        disabled={(c.column as number) === 2}
                    />
                </div>
            );
        } else if (c.column) {
            if (c.isPhone) {
                return phoneNoFormat(c.column as string);
            } else if (c.isPrice) {
                return `$${currencyFormat(c.column as number)}`;
            } else if (c.isDate) {
                return displayData(c.column, true);
            } else if (c.isImage) {
                return <img src={c.column as string} alt="profile" />;
            } else if (c.isLink && (c.column as string) !== '') {
                return (
                    <a
                        href={c.column as string}
                        target="_blank"
                        className="customLink"
                        rel="noreferrer"
                    >
                        Link
                    </a>
                );
            } else {
                return displayData(c.column);
            }
        } else {
            return <>-</>;
        }
    };
    return (
        <>
            <TableContainer
                className={
                    customClassName
                        ? `${customClassName} table-container`
                        : 'table-container'
                }
                id="custom-table"
            >
                <Table stickyHeader size="small">
                    <TableHead className="table-header">
                        <TableRow className="table-row">
                            {tableHeadCells.map(
                                (tableHeadCell: TableHeadCell, index: number) => (
                                    <TableCell
                                        key={index}
                                        sortDirection={
                                            orderBy === tableHeadCell.id ? order : false
                                        }
                                        className="table-cell"
                                    >
                                        {tableHeadCell.doSort !== undefined &&
                                        !tableHeadCell.doSort ? (
                                            tableHeadCell.label
                                        ) : (
                                            <TableSortLabel
                                                active={orderBy === tableHeadCell.id}
                                                direction={
                                                    orderBy === tableHeadCell.dbColMapping
                                                        ? order
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleRequestSortCustom(
                                                        tableHeadCell.dbColMapping,
                                                    )
                                                }
                                            >
                                                {tableHeadCell.label}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                ),
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                        {data?.length > 0 ? (
                            data.map((col, index) => (
                                <TableRow
                                    id={`row-${index}`}
                                    key={index}
                                    className="table-row"
                                    ref={index === data.length - 1 ? ref : null}
                                >
                                    {col.map((c, index) => (
                                        <TableCell
                                            className={
                                                c.key &&
                                                handleNavigation &&
                                                navigationUrl === undefined
                                                    ? 'table-cell link'
                                                    : navigationUrl &&
                                                      navigationUrl.length > 0
                                                    ? 'table-cell exteranl'
                                                    : 'table-cell'
                                            }
                                            key={index}
                                            onClick={(e) =>
                                                handleNavigationCustom(c.key as string)
                                            }
                                        >
                                            {navigationUrl && navigationUrl.length > 0 ? (
                                                <Link
                                                    to={`${navigationUrl}${
                                                        c.key as string
                                                    }`}
                                                    onClick={() =>
                                                        handleNavigationCustom(
                                                            c.key as string,
                                                        )
                                                    }
                                                >
                                                    {returnNeededData(c)}
                                                </Link>
                                            ) : (
                                                returnNeededData(c)
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : !isLoading ? (
                            <TableRow>
                                <TableCell colSpan={25} className="noResults">
                                    No records found
                                </TableCell>
                            </TableRow>
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CustomTable;
