import { FunctionComponent } from 'react';
import { ImageData, Filter } from '../../../../models/images/sharedModel';
import { AppThunk } from '../../../../../app/store';
import './imageTags.scss';

import TagChips from './tagChips/TagChips';
interface ImageTagsProps {
    cancelHandler(): void;
    image: ImageData | undefined;
    currentImageType: string;
    isFullScreen: boolean;
    imageTags: Filter[];
    saveImageTags: (
        image: ImageData,
        chipData: Filter | undefined,
        currentImageType: string,
    ) => AppThunk;
    entityType: string;
    office: string | null;
}

const ImageTags: FunctionComponent<ImageTagsProps> = ({
    cancelHandler,
    image,
    currentImageType,
    isFullScreen,
    imageTags,
    saveImageTags,
    entityType,
    office,
}) => {
    return (
        <div className="tagsHolder">
            <div className="imageTagsHolder">
                <TagChips
                    cancelHandler={cancelHandler}
                    tagsData={imageTags}
                    image={image}
                    currentImageType={currentImageType}
                    isFullScreen={isFullScreen}
                    saveImageTags={saveImageTags}
                    entityType={entityType}
                    office={office}
                />
            </div>
        </div>
    );
};

export default ImageTags;
