import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import {
    getPressItemFromApi,
    createEditPressItem,
    deletePressItemApi,
    getPressItemDetails,
    searchListings,
    exportPressItemSearchResults,
} from './pressListApi';

import {
    PressItemList,
    PressItemSearchRequest,
    PressItemStateInterface,
    PressTableActionType,
} from './pressListModels';
import {
    PressItemEdit,
    FeatureChipData,
} from './component/createAndEdit/createEditPressModels';
import { currencyFormat, findNameFromLookups } from '../../../utils/urlUtils';
import { initialPressSearch, PressTypes } from './pressListConstants';
import {
    ListingSearchRequest,
    SearchedListingListModel,
} from '../../../shared/models/listing/commonModel';
import { AgentSearchResult } from '../../../shared/models/agent/agentModel';
import { getAgentsByLastName } from '../../../shared/api/agent/agentApi';
import { OfficeSearch, OfficesResponse } from '../../../shared/models/office/officeModel';
import { getOfficesFromApi } from '../../../shared/api/office/officeApi';
import { returnFile } from '../../../utils/api/fileResults';
import { orderBy } from 'lodash';

const PressItemState: PressItemStateInterface = {
    pressItems: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    isLoading: false,
    tableAction: initialPressSearch,
    individualPressItem: null,
    searchLoader: false,
    searchedAgents: [],
    searchedListings: [],
    searchedOffices: [],
    isSearched: false,
};

export const pressItemSlice = createSlice({
    name: 'pressItem',
    initialState: PressItemState,
    reducers: {
        updatePressItemList: (
            state,
            action: PayloadAction<PaginationResponse<PressItemList>>,
        ) => {
            const results = [...state.pressItems.results, ...action.payload.results];
            let pressItems = {
                ...state.pressItems,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (
                state.pressItems.results.length !== 0 ||
                action.payload.results.length !== 0
            ) {
                pressItems = {
                    ...pressItems,
                    results:
                        state.pressItems.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                pressItems: pressItems,
            };
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        removeDeletedPressItem: (state, action: PayloadAction<string>) => {
            const updatedList = state.pressItems.results.filter((item: PressItemList) => {
                return item.id !== action.payload;
            });
            const totalRecords = state.pressItems.totalRecords;
            return {
                ...state,
                pressItems: {
                    ...state.pressItems,
                    results: updatedList,
                    totalRecords: totalRecords - 1,
                },
            };
        },
        updateTableAction: (state, action: PayloadAction<PressTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: { ...finalData },
            };
        },
        setIndividualPressItem: (state, action: PayloadAction<PressItemEdit | null>) => {
            const agentIds: string[] = [];
            const listingIds: string[] = [];
            const selectedAgents: FeatureChipData[] = [];
            const selectedListings: FeatureChipData[] = [];
            const selectedMediaOutlet: FeatureChipData[] = [];
            if (action.payload?.entityDetails.length) {
                action.payload.entityDetails.forEach((item) => {
                    if (item.agentId) {
                        agentIds.push(item.agentId);
                        selectedAgents.push({
                            id: item.agentId,
                            name: `${item.lastName}, ${item.firstName}`,
                            selected: true,
                        });
                    } else if (item.listingId) {
                        listingIds.push(item.listingId);
                        const add1 = item?.address1;
                        const add2 = item?.address2 ? ', ' + item?.address2 : '';
                        const listingStatus = item?.status ? ' - ' + item?.status : '';
                        const price = item?.price
                            ? ' - (' + currencyFormat(item.price) + ')'
                            : '';
                        selectedListings.push({
                            id: item.listingId,
                            name: add1 + add2 + listingStatus + price,
                            selected: true,
                        });
                    }
                });
            }
            if (action.payload?.mediaOutletId) {
                selectedMediaOutlet.push({
                    id: action.payload?.mediaOutletId,
                    name: action.payload?.mediaOutletName,
                    selected: true,
                });
            }
            return {
                ...state,
                individualPressItem: action.payload
                    ? {
                          ...action.payload,
                          agentIds: agentIds,
                          listingIds: listingIds,
                          selectedAgents: selectedAgents,
                          selectedListings: selectedListings,
                          selectedMediaOutlet: selectedMediaOutlet,
                      }
                    : action.payload,
            };
        },
        updateIndividualPressItem: (state, action: PayloadAction<PressItemList>) => {
            const { pressAgentListing, pressListings } = action.payload;
            const updatedPressItems: PressItemList[] = state.pressItems.results.map(
                (item) =>
                    item.id === action.payload.id
                        ? {
                              ...item,
                              ...action.payload,
                              listingCount: pressListings?.length
                                  ? pressListings.length
                                  : 0,
                              agentCount: pressAgentListing?.length
                                  ? pressAgentListing.length
                                  : 0,
                              pressType: findNameFromLookups(
                                  action.payload.pressTypeId.toString(),
                                  PressTypes,
                              ),
                          }
                        : item,
            );
            return {
                ...state,
                pressItems: {
                    ...state.pressItems,
                    results: updatedPressItems,
                },
            };
        },
        setSearchLoader: (state, action: PayloadAction<boolean>) => {
            state.searchLoader = action.payload;
        },
        setListing: (state, action: PayloadAction<SearchedListingListModel[]>) => {
            return {
                ...state,
                searchedListings: action.payload,
            };
        },
        setAgent: (state, action: PayloadAction<AgentSearchResult[]>) => {
            return {
                ...state,
                searchedAgents: action.payload,
            };
        },
        setOffice: (state, action: PayloadAction<OfficesResponse[]>) => {
            return {
                ...state,
                searchedOffices: action.payload,
            };
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            state.isSearched = action.payload;
        },
    },
});

export const getPressItems =
    (pressItemRequest: PressItemSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            //remove publish key if it is false
            if (!pressItemRequest.publish) {
                delete pressItemRequest.publish;
            }
            const response = await getPressItemFromApi(pressItemRequest);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(updatePressItemList(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the press list`));
            Logger.error(
                `Failed to retrieve the press list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const createPressItem =
    (data: FormData, isEdit: boolean | undefined): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await createEditPressItem(data);
            if (response && !isEdit) {
                const tableAction = getState().advertisement.pressItemList.tableAction;
                dispatch(
                    getPressItems({
                        ...tableAction,
                        currentPage: 1,
                        itemsPerPage: 20,
                    }),
                );
                dispatch(setSuccess(`Press item created`));
            } else if (response && isEdit) {
                dispatch(updateIndividualPressItem(response));
                dispatch(setSuccess(`Successfully updated press item`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to ${isEdit ? 'update' : 'create'} press item`));
            Logger.error(
                `Failed to ${isEdit ? 'update' : 'create'} press item ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const deletePressItem =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deletePressItemApi(id);
            if (response.status === 200 || response.status === 204) {
                dispatch(removeDeletedPressItem(id));
                dispatch(setSuccess(`Press item deleted.`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete press item`));
            Logger.error(`Failed to delete press item ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setProgress(false));
        }
    };

export const getPressItemById =
    (id: string, from?: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getPressItemDetails(id);
            if (response) {
                dispatch(setIndividualPressItem(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get press item details`));
            Logger.error(`Failed to get press item details ${JSON.stringify(exception)}`);
        } finally {
            if (from && from === 'press') dispatch(setProgress(false));
        }
    };

/**
 * function will get the listing list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchListing =
    (listingSearchRequest: ListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await searchListings(listingSearchRequest);
            if (response.results.length > 0) {
                dispatch(setListing(orderBy(response.results, 'status', 'asc')));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the listing list`));
            Logger.error(`Failed to fetch the listing list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * function fetched agent based on the agent name entered in autocomplete
 * @param data
 * @returns
 */
export const fetchAgent =
    (data: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getAgentsByLastName(data);
            if (response.length > 0) {
                dispatch(setAgent(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the agent list`));
            Logger.error(`Failed to fetch the agent list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * function will get the office list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchOffice =
    (officeSearchRequest: OfficeSearch): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getOfficesFromApi(officeSearchRequest);
            if (response.results.length > 0) {
                dispatch(setOffice(response.results));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the office list`));
            Logger.error(`Failed to fetch office list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * export the searched results as excel
 * @param data
 * @returns
 */
export const exportPressSearchedItem =
    (request: PressItemSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            await returnFile(exportPressItemSearchResults(request));
        } catch (e) {
            Logger.error('Failed to export searched press items', e);
            dispatch(setError('Failed to export searched press items'));
        } finally {
            dispatch(setProgress(false));
        }
    };

export const {
    updatePressItemList,
    setProgress,
    removeDeletedPressItem,
    updateTableAction,
    setIndividualPressItem,
    updateIndividualPressItem,
    setSearchLoader,
    setListing,
    setAgent,
    setOffice,
    setIsSearched,
} = pressItemSlice.actions;

export const pressItem = (state: RootState): PressItemStateInterface =>
    state.advertisement.pressItemList;

export default pressItemSlice.reducer;
