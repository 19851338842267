import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { useAppDispatch } from '../../../../../../app/hooks';
import { AdCopyState, LookupDetail } from './adCopyModels';
import { saveSuppressAdCopyImport } from './adCopySlice';

interface HeaderControlProps {
    adCopyDetails: AdCopyState;
    listingId: string;
    languageId: number;
    handleLanguageChange: (
        e: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
}

const HeaderControls: FunctionComponent<HeaderControlProps> = ({
    adCopyDetails,
    handleLanguageChange,
    listingId,
    languageId,
}) => {
    const [suppressImport, setSuppressImport] = useState(false);
    const dispatch = useAppDispatch();

    const renderDropDownItens = (items: LookupDetail[]) => {
        return items.map((lookup) => (
            <MenuItem value={lookup.id} key={lookup.id}>
                {lookup.name}
            </MenuItem>
        ));
    };

    const handleSuppressMlsChange = (checked: boolean) => {
        setSuppressImport(checked);
        dispatch(saveSuppressAdCopyImport(listingId));
    };

    useEffect(() => {
        setSuppressImport(adCopyDetails.suppressAdCopyImport);
    }, [adCopyDetails]);

    return (
        <Grid container className="top-controls-container">
            <Grid item xs={12} sm={6} className="language-container">
                <FormControl fullWidth className="form-controls-container">
                    <FormControlLabel
                        control={
                            <Select
                                labelId="language-select-label"
                                id="language-select"
                                name="languageId"
                                value={
                                    adCopyDetails.languages.length > 1 ? languageId : ''
                                }
                                onChange={handleLanguageChange}
                            >
                                {renderDropDownItens(adCopyDetails.languages)}
                            </Select>
                        }
                        label="Select a Language:"
                        labelPlacement="start"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className="suppressmls-container">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={suppressImport}
                            onChange={(e, c) => handleSuppressMlsChange(c)}
                            name="suppressMlsImport"
                            color="default"
                        />
                    }
                    label="Suppress MLS Import"
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    );
};

export default HeaderControls;
