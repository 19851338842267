import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import {
    AgentDetailUpdate,
    AgentDashboardState,
    NameMapping,
    AgentDescription,
    AgentLookups,
    AgentAssistant,
    Biography,
    ExpertiseSection,
    AgentDetails,
    AgentSelectSaleDisplaySetting,
    AgentSelect,
    AgentSearchSelect,
    SelectSoldsExportModel,
} from './agentModels';
import {
    getAgentDetails,
    getAgentLookupsApi,
    updateAgentDescriptionApi,
    updateAgentBio,
    updateExpertiseData,
    updateChipsData,
    updateCmsSelectSolds,
    getAgentSelectListingsFromApi,
    exportSelectSoldsReportsApi,
    getAgentTestimonialsApi,
    saveAgentTestimonialsApi,
} from './agentApi';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import {
    agentDashboardMapping,
    initialAgentSelectSaleSearch,
    initialAgentSelectSale,
} from './agentConstants';
import { getImages, savePhoto, deletePhoto } from '../../../shared/api/images/sharedApi';
import { editFormComponent } from './agentConstants';
import loDash, { sortBy } from 'lodash';
import { CommonLookups, LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import {
    addAgentAssistantApi,
    deleteAgentAssistantApi,
} from './drawer/screens/assistants/assistantsApi';
import { ImageDetail, ImageDeleteData } from '../../../shared/models/images/sharedModel';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../shared/slices/loader/loaderSlice';
import { PhotoEditorEvents } from '../../../shared/models/loader/loaderModels';
import {
    findIdFromLookups,
    findNameFromLookups,
    jsonToFormData,
} from '../../../utils/urlUtils';
import { postAgentVideoMetaData } from './drawer/screens/video/videoApi';
import { s3Upload } from '../../../shared/functions/video/s3Upload/s3Upload';
import {
    ingestVideo,
    shareVideo,
} from '../../../shared/api/video/brightcove/brightcoveApi';
import {
    DownloadedVideoStatus,
    VideoModel,
} from '../../../shared/models/video/videoModels';
import { getVideos, updateVideo } from '../../../shared/slices/video/videoSlice';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { UpdateFlag } from '../../../shared/models/flag/flagModel';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import { initializeVideoData } from '../../../shared/functions/video/video';
import { statuses } from '../../../shared/constants/lookups/lookupsConstants';
import { imageTypes } from '../../../shared/constants/imageTypes';
import { getImageData } from '../../../shared/functions/photo/utils';
import { updateAgentList } from '../agentListSlice';
import {
    MlsSave,
    AdditionalMls,
} from '../../../shared/models/additionalMls/additionalMlsModels';
import { saveMlsData } from '../../../shared/slices/additionalMls/additionalMlsSlice';
import {
    UserSearchRequest,
    UserSearchResponse,
} from '../../../shared/models/users/userModels';
import { searchUsers } from '../../../shared/api/users/usersApi';
import { ListingResponseFrom, ListingsResponse } from '../../listings/listingListModels';
import { Order } from '../../../shared/models/customTable/customTableModels';
import { radioStatusUpdate } from '../../listings/listingListApi';
import { getDomainInfoBasedOnEntity } from '../../../shared/api/domain/domain';
import { GetDomainInfo } from '../../../shared/models/domain/domain';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';
import { AgentTestimonialsModel } from '../../../shared/models/testimonial/testimonial';
import { updateAgentCmsTestimonialsList } from '../../../shared/api/testimonials/testimonialsApi';
import { User } from '../../../shared/auth/authModels';
import { displayErrorForAgentEditScreen } from './agentUtils';
import { returnFile } from '../../../utils/api/fileResults';
import {
    resetTabDataInListingList,
    setActiveTabState,
} from '../../listings/listingListSlice';

const initialStateAgentData: AgentDetails = {
    descriptionDetails: {
        agentId: '',
        profileId: '',
        primaryMls: '',
        tridentId: 0,
        licenseNumber: '',
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        regionId: 0,
        state: '',
        zip: '',
        officeId: '',
        webOfficeId: '',
        webOffice: '',
        tridentOfficeId: '',
        tridentOffice: '',
        statusId: 1,
        status: '',
        publish: false,
        startDate: '',
        agentTypeId: 0,
        type: '',
        brandEmail: '',
        personalEmail: '',
        alternateEmail: '',
        sothebysRealtyEmail: '',
        mobilePhone: '',
        workPhone: '',
        homePhone: '',
        fax: '',
        websiteUrl: '',
        facebookUrl: '',
        twitterUrl: '',
        instagramUrl: '',
        youtubeUrl: '',
        linkedInUrl: '',
        salutationTypeId: 0,
        salutation: '',
        areaOfExpertise: '',
        team: '',
        salesTeamId: '',
        lastUpdated: '',
        userId: '',
        preferredFirstName: '',
        preferredLastName: '',
        createdDate: '',
        sortField: '',
        dashStaffId: '',
        defaultSelectSale: null,
    },
    agentAccolades: {
        agentId: '',
        accolades: '',
        accoladesTitle: '',
    },
    agentAssistants: [
        {
            id: '',
            agentId: '',
            brandEmail: '',
            firstName: '',
            lastName: '',
            userId: '',
        },
    ],
    agentPhoto: [],
    agentBiography: [
        {
            id: '',
            profileId: '',
            languageId: 1,
            body: '',
            headline: '',
        },
    ],
    agentTestimonials: [],
    agentVideo: [
        initializeVideoData(entityTypes.profile.key, videoTypes.agentVideo.key, 1),
    ],
    agentLanguages: [],
    agentDesignations: [],
    agentSpecializations: [],
    agentDetailsForAssistant: [],
    searchedUsers: [],
    agentSelectSales: initialAgentSelectSale,
    agentSelectRentals: initialAgentSelectSale,
    agentSearchSelectSales: initialAgentSelectSaleSearch,
    agentSearchSelectRentals: initialAgentSelectSaleSearch,
    agentDomainInfo: undefined,
    agentSalesTeamDomainInfo: undefined,
    agentDataCompleteDetails: undefined,
};

//this slice will split to respective slices once the designs are finalized
export const initialAgentDashboardState: AgentDashboardState = {
    agentDetails: initialStateAgentData,
    editForm: {
        formOpen: false,
        editFormName: '',
        agentEditFormName: '',
    },
    lookups: {
        agentLanguages: [],
        agentOffices: [],
        agentTypes: [],
        assistantTypes: [],
        designations: [],
        specializations: [],
    },
    flags: {
        isAgentPhotoUploading: false,
        isSiteLoading: true,
        searchingAssistants: false,
        selectSoldsLoading: false,
    },
    statuses: {
        downloadedVideo: { from: '', progress: 0 },
    },
    initialFormState: null,
};

const isFormDataChanged = (
    currentState:
        | AgentDescription
        | ExpertiseSection
        | VideoModel[]
        | AdditionalMls[]
        | AgentSelectSaleDisplaySetting,
    initialState:
        | AgentDescription
        | VideoModel[]
        | AdditionalMls[]
        | AgentSelectSaleDisplaySetting,
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !loDash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

const validateToUpdateList = (
    currentState: AgentDescription,
    initialState: AgentDescription,
) => {
    const keyToVal = ['agentTypeId', 'publish', 'primaryMls', 'webOffice', 'status'];
    const curr = keyToValidate(currentState, keyToVal);
    const init = keyToValidate(initialState, keyToVal);
    if (!loDash.isEqual(init, curr)) {
        return {
            agentId: currentState.agentId,
            agentType: currentState.type || '',
            mlsNumber: currentState.primaryMls || '',
            officeName: currentState.webOffice || '',
            publish: currentState.publish,
            status: currentState.status || '',
        };
    } else {
        return null;
    }
};

export const keyToValidate = (
    data: AgentDescription,
    keyToVal: string[],
): Partial<AgentDescription> => {
    return loDash.pickBy(data, (value, key) => loDash.includes(keyToVal, key));
};

// Gets data from the current agent details open in the side drawer component
// and saves it to the db
export const saveAgentEditFormData =
    (agentState: AgentDashboardState, editFormName?: string): AppThunk =>
    async (dispatch) => {
        let isChanged = true;
        let isValid = true;
        if (agentState.editForm?.currentFormData) {
            switch (agentState.editForm?.agentEditFormName) {
                case editFormComponent.description:
                    const oldState = agentState.agentDetails
                        .descriptionDetails as AgentDescription;
                    const newState = agentState.editForm
                        ?.currentFormData as AgentDescription;
                    const {
                        firstName,
                        lastName,
                        agentTypeId,
                        tridentOfficeId,
                        address1,
                        city,
                        regionId,
                        zip,
                        workPhone,
                        brandEmail,
                    } = newState;
                    const result = displayErrorForAgentEditScreen({
                        firstName: firstName || '',
                        lastName: lastName || '',
                        agentTypeId,
                        tridentOfficeId,
                        address1: address1 || '',
                        city: city || '',
                        regionId,
                        zip: zip || '',
                        workPhone: workPhone || '',
                        brandEmail: brandEmail || '',
                    });
                    isChanged = isFormDataChanged(newState, oldState);
                    if (isChanged) {
                        isValid = result.isValid;
                        if (isValid) {
                            dispatch(updateAgentDescription(newState));
                            if (newState.sortField !== oldState.sortField) {
                                dispatch(
                                    updateAgentSelectSaleDisplayDetails(newState, {
                                        sortField: newState.sortField,
                                    }),
                                );
                                // clearing listings in solds tab to fetch listings based on updated sortField
                                dispatch(resetTabDataInListingList('sold'));
                                dispatch(setActiveTabState(0));
                            }
                            const toUpdateList = validateToUpdateList(newState, oldState);
                            if (toUpdateList && Object.keys(toUpdateList).length > 0)
                                dispatch(updateAgentList(toUpdateList));
                        } else {
                            dispatch(setError(result.errorMessage));
                        }
                    }
                    break;
                case editFormComponent.biography:
                    dispatch(
                        saveAgentBio(agentState.editForm?.currentFormData as Biography),
                    );
                    break;
                case editFormComponent.expertise:
                    if (
                        isFormDataChanged(
                            agentState.editForm?.currentFormData as ExpertiseSection,
                            agentState.agentDetails
                                .descriptionDetails as AgentDescription,
                        )
                    ) {
                        dispatch(
                            updateExpertiseSection(
                                agentState.editForm?.currentFormData as ExpertiseSection,
                            ),
                        );
                    }
                    dispatch(
                        updateChips(
                            agentState.editForm?.currentFormData as ExpertiseSection,
                            agentDashboardMapping.languages.urlName,
                        ),
                    );
                    dispatch(
                        updateChips(
                            agentState.editForm?.currentFormData as ExpertiseSection,
                            agentDashboardMapping.designations.urlName,
                        ),
                    );
                    dispatch(
                        updateChips(
                            agentState.editForm?.currentFormData as ExpertiseSection,
                            agentDashboardMapping.specializations.urlName,
                        ),
                    );
                    break;
                case editFormComponent.video:
                    const newVideoState = agentState.editForm
                        ?.currentFormData as VideoModel;
                    if (
                        isFormDataChanged(
                            [{ ...newVideoState }],
                            (agentState.agentDetails as AgentDetails)
                                .agentVideo as VideoModel[],
                        )
                    ) {
                        dispatch(updateVideo(newVideoState, entityTypes.profile.key));
                    }
                    break;
                case editFormComponent.additionalMls:
                    if (
                        isFormDataChanged(
                            (agentState.editForm?.currentFormData as MlsSave).data,
                            agentState.initialFormState as AdditionalMls[],
                        )
                    ) {
                        dispatch(
                            saveMlsData(
                                (agentState.editForm?.currentFormData as MlsSave).data,
                                (agentState.editForm?.currentFormData as MlsSave).mlsFor,
                                (agentState.editForm?.currentFormData as MlsSave).id,
                                (agentState.editForm?.currentFormData as MlsSave)
                                    .profileId,
                            ),
                        );
                    }
                    break;
            }
        }
        if (isValid) {
            if (editFormName) dispatch(updateEditFormName(editFormName));
            else dispatch(closeEditForm());
        }
    };

export const agentDashboardSlice = createSlice({
    name: 'agentDashboardDetails',
    initialState: initialAgentDashboardState,
    reducers: {
        updateAgentDetails: (state, action: PayloadAction<AgentDetailUpdate>) => {
            state.agentDetails[
                action.payload
                    .name as keyof typeof initialAgentDashboardState.agentDetails
            ] = action.payload.data;
        },
        setInitialState: (state) => {
            return {
                ...state,
                ...initialAgentDashboardState,
            };
        },
        // Sets the name of the component to open in the side drawer
        updateEditFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                editFormName: action.payload,
                agentEditFormName: '',
            };
        },
        // Fired when the user wants to close the side drawer component
        closeEditForm: (state, action: PayloadAction) => {
            state.editForm = {
                formOpen: false,
                editFormName: '',
                agentEditFormName: '',
            };
        },
        // Sets the data currently being edited in the agent edit form open
        // in the side drawer component
        setCurrentAgentEditFormData: (
            state,
            action: PayloadAction<{
                formData:
                    | AgentDescription
                    | AgentAssistant
                    | Biography
                    | ExpertiseSection
                    | VideoModel
                    | MlsSave;
                agentEditFormName: string;
            }>,
        ) => {
            state.editForm = {
                editFormName: state.editForm?.editFormName || '',
                formOpen: state.editForm?.formOpen || false,
                currentFormData: action.payload.formData,
                agentEditFormName: action.payload.agentEditFormName,
            };
        },
        addAgentLookups: (state, action: PayloadAction<AgentLookups>) => {
            state.lookups = action.payload;
        },
        updateFlag: (state, action: PayloadAction<UpdateFlag>) => {
            state.flags[
                action.payload.property as keyof typeof initialAgentDashboardState.flags
            ] = action.payload.value;
        },
        updateVideoStatus: (state, action: PayloadAction<{ videoTypeId: number }>) => {
            const agentDetails = state.agentDetails as AgentDetails;
            const agentVideos = agentDetails.agentVideo as VideoModel[];
            const videos = agentVideos.map((video) => {
                return video.videoTypeId === action.payload.videoTypeId
                    ? { ...video, status: 2 }
                    : video;
            });
            state.agentDetails[
                'agentVideo' as keyof typeof initialAgentDashboardState.agentDetails
            ] = videos;
        },
        updateDownloadedAgentVideoStatus: (
            state,
            action: PayloadAction<DownloadedVideoStatus>,
        ) => {
            return {
                ...state,
                statuses: {
                    downloadedVideo: {
                        from: action.payload.from,
                        progress: action.payload.progress,
                    },
                },
            };
        },
        setAgentInitialFormState: (
            state,
            action: PayloadAction<{
                initialData: AdditionalMls[];
            }>,
        ) => {
            state.initialFormState = action.payload.initialData;
        },
        setSearchedUsers: (state, action: PayloadAction<UserSearchResponse[]>) => {
            return {
                ...state,
                agentDetails: {
                    ...state.agentDetails,
                    searchedUsers: action.payload,
                },
            };
        },

        //to update listing list if action happend on the list
        updateSoldListingDetails: (state, action: PayloadAction<ListingResponseFrom>) => {
            const { listing, from } = action.payload;
            const list =
                from === 'solds'
                    ? (state.agentDetails.agentSelectSales as AgentSelect).selectSalesList
                          .results
                    : (state.agentDetails.agentSelectRentals as AgentSelect)
                          .selectSalesList.results;
            if (list) {
                const findIndex = list.findIndex(
                    (x) => x.listingId === listing.listingId,
                );
                list[findIndex] = listing;
                if (from === 'solds') {
                    (
                        state.agentDetails.agentSelectSales as AgentSelect
                    ).selectSalesList.results = list;
                } else {
                    (
                        state.agentDetails.agentSelectRentals as AgentSelect
                    ).selectSalesList.results = list;
                }
            }
        },
        updateAgentDomainDetails: (
            state,
            action: PayloadAction<{ data: GetDomainInfo | undefined; siteType: number }>,
        ) => {
            if (action.payload.siteType === 1) {
                state.agentDetails.agentDomainInfo = action.payload.data;
            } else {
                state.agentDetails.agentSalesTeamDomainInfo = action.payload.data;
            }
        },
    },
});

export const fetchAgentDetails =
    (id: string, names: NameMapping): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await getAgentDetails(id, names.urlName);
            if (response) {
                let data = response;
                const { common } = getState().lookups;
                if (names.stateName === 'descriptionDetails') {
                    let descriptionData = response as AgentDescription;
                    descriptionData = {
                        ...descriptionData,
                        sortField: descriptionData.sortField
                            ? descriptionData.sortField
                            : 'price',
                    };
                    const offices = (common as CommonLookups).offices;
                    const salutations = (common as CommonLookups).salutations;
                    const states = (common as CommonLookups).states;
                    const regionId = Number(
                        findIdFromLookups(descriptionData.state, states),
                    );
                    const agentLookups = await getAgentLookupsApi(regionId);
                    dispatch(addAgentLookups(agentLookups));
                    data = {
                        ...descriptionData,
                        statusId: Number(
                            findIdFromLookups(descriptionData.status, statuses),
                        ),
                        agentTypeId: Number(
                            findIdFromLookups(
                                descriptionData.type,
                                agentLookups.agentTypes,
                            ),
                        ),
                        tridentOfficeId: findIdFromLookups(
                            descriptionData.tridentOffice,
                            offices,
                        ),
                        webOfficeId: findIdFromLookups(
                            descriptionData.webOffice,
                            offices,
                        ),
                        regionId: regionId,
                        salutationTypeId: Number(
                            findIdFromLookups(descriptionData.salutation, salutations),
                        ),
                    };
                } else if (names.stateName === 'agentAssistants') {
                    data = response as AgentAssistant[];
                }
                dispatch(updateAgentDetails({ name: names.stateName, data: data }));
            } else {
                dispatch(setError(`Failed to load agent ${names.stateName}`));
            }
        } catch (e) {
            Logger.error(`Failed to load agent ${names.stateName}`, e);
            dispatch(setError(`Failed to load agent ${names.stateName}`));
        } finally {
            dispatch(
                updateFlag({
                    property: 'isSiteLoading',
                    value: false,
                }),
            );
        }
    };

export const getAgentPhoto =
    (entityType: string, entityId: string, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getImages(entityType, entityId, imageType);
            if (response) {
                if (imageType === imageTypes.associates) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.photo.stateName,
                            data: response,
                        }),
                    );
                }
            } else {
                dispatch(setError(`Failed to get agent photo or logo`));
            }
        } catch (e) {
            Logger.error(`Failed to get agent photo or logo`, e);
            dispatch(setError(`Failed to get agent photo or logo`));
        }
    };

export const addAgentAssistant =
    (agentId: string, agentAssistant: AgentAssistant): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await addAgentAssistantApi(agentId, agentAssistant);
            if (response !== '') {
                const agentAssistants = getState().agent.details.agentDetails
                    .agentAssistants as AgentAssistant[];
                dispatch(
                    updateAgentDetails({
                        name: agentDashboardMapping.assistants.stateName,
                        data: [
                            ...agentAssistants,
                            {
                                ...agentAssistant,
                                id: response,
                            },
                        ],
                    }),
                );
                dispatch(setSuccess('Agent assistant is added'));
            } else {
                dispatch(setError(`Agent assistant is not added`));
            }
        } catch (exception) {
            Logger.error(`Failed to add an agent assistant`, exception);
            dispatch(setError(`Failed to add an agent assistant`));
        }
    };

export const deleteAgentAssistant =
    (agentId: string, agentAssistantId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await deleteAgentAssistantApi(agentId, agentAssistantId);
            if (response) {
                const agentAssistants = getState().agent.details.agentDetails
                    .agentAssistants as AgentAssistant[];
                const filteredAgentAssistants = agentAssistants.filter(
                    (agent: AgentAssistant) => {
                        return agent.id !== agentAssistantId;
                    },
                );
                dispatch(
                    updateAgentDetails({
                        name: agentDashboardMapping.assistants.stateName,
                        data: filteredAgentAssistants,
                    }),
                );
                dispatch(setSuccess('Agent assistant is deleted'));
            } else {
                dispatch(setError('Agent assistant is not deleted'));
            }
        } catch (exception) {
            Logger.error(`Failed in deleting an agent assistant`, exception);
            dispatch(setError(`Failed in deleting an agent assistant`));
        }
    };

export const updateAgentDescription =
    (agentDescription: AgentDescription): AppThunk =>
    async (dispatch, getState) => {
        try {
            const {
                agentId,
                profileId,
                tridentId,
                state,
                webOffice,
                tridentOffice,
                status,
                startDate,
                type,
                salutationTypeId,
                areaOfExpertise,
                team,
                lastUpdated,
                tridentOfficeId,
                ...description
            } = agentDescription;
            const { offices } = getState().lookups.common as CommonLookups;
            const response = await updateAgentDescriptionApi(
                description,
                agentId,
                profileId,
            );
            if (response) {
                dispatch(
                    updateAgentDetails({
                        name: agentDashboardMapping.description.stateName,
                        data: {
                            ...agentDescription,
                            webOffice: findNameFromLookups(
                                agentDescription.webOfficeId,
                                offices,
                            ),
                        },
                    }),
                );
                dispatch(setSuccess('Agent description is updated'));
            } else {
                dispatch(setError('Agent description is not updated'));
            }
        } catch {
            dispatch(setError('Error occurred while updating the Agent Description'));
        }
    };

export const updateAgentSelectSaleDisplayDetails =
    (description: AgentDescription, data: AgentSelectSaleDisplaySetting): AppThunk =>
    async (dispatch) => {
        try {
            const request = {
                entityType: 1,
                entityIdentifier: description.profileId,
                selectSoldsPage: {
                    sortField: data.sortField,
                },
            };
            const response = await updateCmsSelectSolds(request);
            if (response.status === 200 || response.status === 204) {
                dispatch(setSuccess('Select sale display preference is updated'));
            } else {
                dispatch(
                    setError(
                        'Error occurred while updating agent Select sale display preference',
                    ),
                );
            }
            dispatch(setSuccess('Select sale display preference is updated'));
        } catch {
            dispatch(
                setError(
                    'Error occurred while updating agent Select sale display preference',
                ),
            );
        }
    };

export const saveAgentBio =
    (request: Biography): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                const response = await updateAgentBio(request);
                if (response.id) {
                    const initialAgentBio = getState().agent.details.agentDetails
                        .agentBiography as Biography[];
                    const updatedData = request.id?.length
                        ? initialAgentBio.map((item) =>
                              item.id === request.id ? { ...item, ...request } : item,
                          )
                        : [...initialAgentBio, response];
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.biography.stateName,
                            data: updatedData,
                        }),
                    );
                    dispatch(setSuccess('Agent biography updated successfully'));
                }
            }
        } catch {
            Logger.error(
                `Error saving agent biography details: ${JSON.stringify(request)}`,
            );
            dispatch(setError('Error saving agent biography'));
        }
    };

export const updateExpertiseSection =
    (request: ExpertiseSection): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                const response = await updateExpertiseData(request);
                if (response.status === 200) {
                    const initialAgentDescription = getState().agent.details.agentDetails
                        .descriptionDetails as AgentDescription;
                    const updatedData = {
                        ...initialAgentDescription,
                        areaOfExpertise: request.areaOfExpertise,
                    };
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.description.stateName,
                            data: updatedData,
                        }),
                    );
                    dispatch(setSuccess('Data updated successfully'));
                }
            }
        } catch {
            Logger.error(`Error saving data: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving data'));
        }
    };

const getUpdatedLookupData = (lookups: LookupInfo[], ids: number[]) => {
    const updatedLookups: LookupInfo[] = [];
    ids.forEach((id: number) => {
        const isSelected = lookups.find((lookup: LookupInfo) => Number(lookup.id) === id);
        if (isSelected) {
            updatedLookups.push({ id: id.toString(), name: isSelected.name });
        }
    });
    return updatedLookups;
};

export const updateChips =
    (request: ExpertiseSection, urlName: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                const response = await updateChipsData(
                    request.profileId,
                    urlName,
                    request[urlName as keyof typeof request],
                );
                if (response.status === 200) {
                    let updatedData;
                    switch (urlName) {
                        case agentDashboardMapping.languages.urlName:
                            updatedData = getUpdatedLookupData(
                                getState().agent.details.lookups.agentLanguages,
                                request.languages.ids,
                            );
                            dispatch(
                                updateAgentDetails({
                                    name: agentDashboardMapping.languages.stateName,
                                    data: updatedData as LookupInfo[],
                                }),
                            );
                            break;
                        case agentDashboardMapping.designations.urlName:
                            updatedData = getUpdatedLookupData(
                                getState().agent.details.lookups.designations,
                                request.designations.ids,
                            );
                            dispatch(
                                updateAgentDetails({
                                    name: agentDashboardMapping.designations.stateName,
                                    data: updatedData as LookupInfo[],
                                }),
                            );
                            break;
                        case agentDashboardMapping.specializations.urlName:
                            updatedData = getUpdatedLookupData(
                                getState().agent.details.lookups.specializations,
                                request.specializations.ids,
                            );
                            dispatch(
                                updateAgentDetails({
                                    name: agentDashboardMapping.specializations.stateName,
                                    data: updatedData as LookupInfo[],
                                }),
                            );
                            break;
                        default:
                            return '';
                    }
                    dispatch(setSuccess('Data updated successfully'));
                }
            }
        } catch {
            Logger.error(`Error saving data: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving data'));
        }
    };

export const uploadAgentPhoto =
    (uploadedImage: ImageDetail, cropper = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateFlag({
                    property: 'isAgentPhotoUploading',
                    value: true,
                }),
            );
            const data = jsonToFormData(uploadedImage);
            const response = await savePhoto(data);
            if (response) {
                const newPhoto = getImageData(uploadedImage, response);
                if (uploadedImage.imageType === imageTypes.associates) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.photo.stateName,
                            data: [newPhoto],
                        }),
                    );
                    dispatch(
                        setSuccess(
                            cropper
                                ? 'Agent photo cropped successfully'
                                : 'Agent photo is uploaded',
                        ),
                    );
                }
                // Agent list slice update
                const currentState = getState().agent.details.agentDetails
                    .descriptionDetails as AgentDescription;
                const sliceData = {
                    agentId: currentState.agentId,
                    agentType: currentState.type || '',
                    mlsNumber: currentState.primaryMls || '',
                    officeName: currentState.webOffice || '',
                    publish: currentState.publish,
                    status: currentState.status || '',
                    imageExtension:
                        response.originalUrl.substring(response.originalUrl.length - 4) ||
                        '',
                    imageUrlId:
                        response.originalUrl.slice(
                            response.originalUrl.indexOf('associates/') + 11,
                            response.originalUrl.lastIndexOf('.'),
                        ) || '',
                };
                dispatch(updateAgentList(sliceData));
            }
        } catch (e) {
            Logger.error(`Failed in uploading the agent photo or logo: ${uploadedImage}`);
            dispatch(setError('Failed in uploading the agent photo or logo'));
        } finally {
            dispatch(
                updateFlag({
                    property: 'isAgentPhotoUploading',
                    value: false,
                }),
            );
        }
    };

export const deleteAgentPhoto =
    (deletedImage: ImageDeleteData, toaster = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
            const response = await deletePhoto(deletedImage);
            if (response) {
                if (deletedImage.imageType === imageTypes.associates) {
                    dispatch(
                        updateAgentDetails({
                            name: agentDashboardMapping.photo.stateName,
                            data: [],
                        }),
                    );
                    if (toaster) {
                        dispatch(setSuccess(`Agent photo is deleted`));
                    }
                }
                // Agent list slice update
                const currentState = getState().agent.details.agentDetails
                    .descriptionDetails as AgentDescription;
                const sliceData = {
                    agentId: currentState.agentId,
                    agentType: currentState.type || '',
                    mlsNumber: currentState.primaryMls || '',
                    officeName: currentState.webOffice || '',
                    publish: currentState.publish,
                    status: currentState.status || '',
                    imageUrlId: '',
                    imageExtension: '',
                };
                dispatch(updateAgentList(sliceData));
            }
        } catch (e) {
            Logger.error(
                `Failed in deleting the agent photo or logo: ${JSON.stringify(e)}`,
            );
            dispatch(setError(`Failed in deleting the agent photo or logo`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
        }
    };

/*
 * Agent Video
 */

export const uploadAgentVideo =
    (
        agentId: string,
        profileId: string,
        file: File,
        abortController: AwsAbortController,
    ): AppThunk =>
    async (dispatch) => {
        try {
            const s3Details = await postAgentVideoMetaData(agentId, profileId, {
                videoType: videoTypes.agentVideo.value,
                fileName: file.name,
            });
            if (s3Details) {
                dispatch(getVideos(profileId, entityTypes.profile.key));
                const s3Uploaded = await s3Upload(file, s3Details, abortController);
                if (s3Uploaded === 'success') {
                    const apiUrl = process.env.REACT_APP_APIURL;
                    const masterURL = {
                        master: {
                            url: s3Details.api_Request_Url,
                        },
                        callbacks: [`${apiUrl}/brightcove/callback`],
                    };
                    const injestResponse = await ingestVideo(
                        masterURL,
                        s3Details.video_Id,
                    );
                    if (injestResponse) {
                        const sira = [{ id: '5699924528001' }];
                        await shareVideo(s3Details.video_Id, sira);
                    } else {
                        dispatch(setError('Injest request is failed'));
                    }
                }
            } else {
                dispatch(setError('Video is not created in Brightcove'));
            }
        } catch (err) {
            Logger.error(`Video is not uploaded: ${JSON.stringify(err)}`);
            dispatch(setError('Video is not uploaded'));
        }
    };

export const getUsers =
    (searchTerm: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateFlag({ property: 'searchingAssistants', value: true }));
            const request: UserSearchRequest = {
                currentPage: 1,
                itemsPerPage: 30,
                email: searchTerm,
            };
            const response = await searchUsers(request);
            if (
                response.currentPage ||
                response.totalRecords ||
                response.recordsPerPage ||
                response.results.length > 0
            ) {
                const agentDetails = getState().agent.details
                    .agentDetails as AgentDetails;
                const { descriptionDetails } = agentDetails;
                const { userId } = descriptionDetails;
                let existingUserIds = agentDetails.agentAssistants.map(
                    (u) => u.userId || '',
                );
                existingUserIds = [...existingUserIds, userId];
                dispatch(
                    setSearchedUsers(
                        response.results.filter((result: UserSearchResponse) => {
                            return existingUserIds.every((userId) => {
                                return userId !== result.userId;
                            });
                        }),
                    ),
                );
            }
        } catch (e) {
            Logger.error(`Failed to get the users: ${JSON.stringify(e)}`);
            dispatch(setError('Failed to get the users'));
        } finally {
            dispatch(updateFlag({ property: 'searchingAssistants', value: false }));
        }
    };

export const fetchAgentSelectSalesListing =
    (
        agentId: string,
        officeId: string,
        businessPurposeTypeId: number,
        currentPage: number,
        sortColumn?: string,
        sortDirection?: Order,
        search?: AgentSearchSelect,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: true,
                }),
            );
            let requestData = {
                currentPage: currentPage,
                itemsPerPage: 20,
                status: ['Sold'],
                businessPurposeTypeId: businessPurposeTypeId,
                agentIds: [agentId],
                officeIds: [officeId],
                roleIds: ['cb9d9788-ed11-481c-ab75-8e67b5ad798c'], //agent Role
                sortDirection: sortDirection ? sortDirection : undefined,
                sortColumn: sortColumn ? sortColumn : undefined,
            };
            if (search) {
                const copiedSearch = { ...search };
                Object.keys(copiedSearch).forEach((key) => {
                    if (
                        search[key as keyof typeof initialAgentSelectSaleSearch] ===
                            false ||
                        search[key as keyof typeof initialAgentSelectSaleSearch] === '' ||
                        search[key as keyof typeof initialAgentSelectSaleSearch] === 0
                    ) {
                        delete copiedSearch[
                            key as keyof typeof initialAgentSelectSaleSearch
                        ];
                    }
                });
                if (Object.keys(copiedSearch).length > 0) {
                    requestData = { ...requestData, ...copiedSearch };
                }
            }
            const response = await getAgentSelectListingsFromApi(requestData);
            if (response) {
                let oldState: ListingsResponse[] = [];
                if (response.currentPage !== 1) {
                    if (businessPurposeTypeId === 1) {
                        oldState = (
                            getState().agent.details.agentDetails
                                .agentSelectSales as AgentSelect
                        ).selectSalesList.results;
                    } else {
                        oldState = (
                            getState().agent.details.agentDetails
                                .agentSelectRentals as AgentSelect
                        ).selectSalesList.results;
                    }
                }
                const data = {
                    selectSalesTableAction: {
                        currentPage: currentPage,
                        itemsPerPage: 20,
                        sortDirection: sortDirection ? sortDirection : undefined,
                        sortColumn: sortColumn ? sortColumn : undefined,
                    },
                    selectSalesList: {
                        currentPage: response.currentPage,
                        totalRecords: response.totalRecords,
                        recordsPerPage: response.recordsPerPage,
                        results:
                            response.currentPage === 1
                                ? response.results
                                : [...oldState, ...response.results],
                    },
                };
                if (businessPurposeTypeId === 1) {
                    dispatch(
                        updateAgentDetails({ name: 'agentSelectSales', data: data }),
                    );
                    if (search)
                        dispatch(
                            updateAgentDetails({
                                name: 'agentSearchSelectSales',
                                data: search as AgentSearchSelect,
                            }),
                        );
                } else {
                    dispatch(
                        updateAgentDetails({ name: 'agentSelectRentals', data: data }),
                    );
                    if (search)
                        dispatch(
                            updateAgentDetails({
                                name: 'agentSearchSelectRentals',
                                data: search as AgentSearchSelect,
                            }),
                        );
                }
            }
        } catch (e) {
            Logger.error(
                `Failed to get the agent select sale/rental details: ${JSON.stringify(
                    e,
                )}`,
            );
            dispatch(setError(`Failed to get the agent select sale/rental details`));
        } finally {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: false,
                }),
            );
        }
    };

export const selectSalelistingOptionUpdate =
    (
        listing: ListingsResponse,
        name: string,
        status: boolean,
        from: string,
        agentId?: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: true,
                }),
            );
            await radioStatusUpdate(listing.listingId, name, status, agentId);
            const newListing = { ...listing, [name as keyof typeof listing]: status };
            const data = {
                listing: newListing,
                from: listing.businessPurposeTypeId === 1 ? 'solds' : 'rentals',
            };
            dispatch(updateSoldListingDetails(data));
            dispatch(setSuccess(`Updated Successfully`));
        } catch (e) {
            Logger.error('Failed to update the listings options' + e);
            dispatch(setError(`Failed to update the listings options`));
        } finally {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: false,
                }),
            );
        }
    };

export const fetchAgentDomainInfo =
    (profileId: string, siteType: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getDomainInfoBasedOnEntity(profileId, siteType);
            if (response && response.domainUrl) {
                dispatch(
                    updateAgentDomainDetails({ data: response, siteType: siteType }),
                );
            } else {
                dispatch(
                    updateAgentDomainDetails({ data: undefined, siteType: siteType }),
                );
            }
        } catch {
            Logger.error(
                `Cannot fetch sales team domain information: ${JSON.stringify({
                    teamId: profileId,
                    siteType: siteType,
                })}`,
            );
        }
    };
/**
 * This method is called to get agent details in Listing solds page
 * @param agentId agent guid
 */
export const fetchAgentDescriptionDetails =
    (agentId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getAgentDetails(agentId, 'detailsummary');
            let descriptionData = response as AgentDescription;
            descriptionData = {
                ...descriptionData,
                sortField: descriptionData.sortField
                    ? descriptionData.sortField
                    : 'price',
            };
            if (response) {
                dispatch(
                    updateAgentDetails({
                        name: 'descriptionDetails',
                        data: descriptionData,
                    }),
                );
            }
        } catch (e) {
            Logger.error(`Failed to get agent details`, e);
        }
    };

/**
 * function to export the report based on the selected
 * option and save the data in excel
 * @param request
 * @param from
 * @returns
 */
export const exportSelectSoldsReports =
    (request: SelectSoldsExportModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: true,
                }),
            );
            await returnFile(exportSelectSoldsReportsApi(request));
        } catch (exception) {
            dispatch(setError(`Failed to export the agent select solds report list`));
        } finally {
            dispatch(
                updateFlag({
                    property: 'selectSoldsLoading',
                    value: false,
                }),
            );
        }
    };

export const getAgentTestimonials =
    (profileId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getAgentTestimonialsApi(profileId);
            if (response) {
                dispatch(
                    updateAgentDetails({
                        name: agentDashboardMapping.testimonials.stateName,
                        data: response,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to fetch agent testimonials' + e);
            dispatch(setError(`Failed to fetch agent testimonials`));
        }
    };

export const saveTestimonials =
    (
        from: string,
        profileId: string,
        data: AgentTestimonialsModel[],
        user: User,
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveAgentTestimonialsApi(
                profileId,
                sortBy(data, 'displayOrder'),
            );
            if (response) {
                const cmsList = data.map((list) => ({
                    displayOrder: list.displayOrder,
                    testimony: list.testimony,
                    author: list.author,
                }));

                updateAgentCmsTestimonialsList(
                    1,
                    profileId,
                    sortBy(cmsList, 'displayOrder'),
                    user.oktaId,
                );
                dispatch(
                    updateAgentDetails({
                        name: agentDashboardMapping.testimonials.stateName,
                        data: response,
                    }),
                );

                if (from === 'add') {
                    dispatch(setSuccess(`Testimonial have been added successfully`));
                } else if (from === 'edit') {
                    dispatch(setSuccess(`Testimonial have been edited successfully`));
                } else if (from === 'delete') {
                    dispatch(setSuccess(`Testimonial have been deleted successfully`));
                } else {
                    dispatch(setSuccess(`Testimonial have been reordered successfully`));
                }
            }
        } catch (e) {
            Logger.error('Failed to fetch agent testimonials' + e);
            dispatch(setError(`Failed to fetch agent testimonials`));
        }
    };
export const {
    updateAgentDetails,
    setInitialState,
    updateEditFormName,
    closeEditForm,
    setCurrentAgentEditFormData,
    addAgentLookups,
    updateFlag,
    updateVideoStatus,
    updateDownloadedAgentVideoStatus,
    setAgentInitialFormState,
    setSearchedUsers,
    updateSoldListingDetails,
    updateAgentDomainDetails,
} = agentDashboardSlice.actions;

export const agentDashboardDetails = (state: RootState): AgentDashboardState =>
    state.agent.details;

export default agentDashboardSlice.reducer;
