import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../officeModels';
import { saveOfficePhoto, deleteOfficePhoto } from '../../../officeSlice';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
    ImageData,
    ImageDetail,
    ImageDeleteData,
} from '../../../../../../shared/models/images/sharedModel';
import { minPhotoDim } from '../../../officeConstants';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import PhotoTemplate from '../../../../../../shared/component/photo/PhotoTemplate';

const OfficePhoto: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const photo = editFormData.data.officePhoto as ImageData[];
    const dispatch = useAppDispatch();

    const validateImageSize = (width: number, height: number): boolean => {
        return width >= minPhotoDim.minWidth && height >= minPhotoDim.minHeight;
    };

    const handleUpload = (imageData: ImageDetail) => {
        dispatch(saveOfficePhoto(imageData));
    };

    const deleteHandler = () => {
        const deleteData: ImageDeleteData = {
            ids: [photo[0]?.guid],
            imageType: imageTypes.offices,
            shouldValidate: false,
        };
        dispatch(deleteOfficePhoto(deleteData));
    };

    return (
        <PhotoTemplate
            entityId={editFormData.data.officeDetails?.id || ''}
            entityType={entityTypes.office.value}
            imageType={imageTypes.offices}
            photoUrl={
                photo.length && photo[0]?.guid?.length ? photo[0].urls.originalUrl : ''
            }
            isUploading={editFormData.flags.isPhotoUploading}
            infoText="Photo must be a jpg/jpeg and in 2000x1200 pixel landscape format at 72 dpi resolution."
            handleUpload={handleUpload}
            deleteHandler={deleteHandler}
            validateImageSize={validateImageSize}
        />
    );
};

export default OfficePhoto;
