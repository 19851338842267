import {
    MarketingRemindersConstant,
    ListingData,
    QcStatusDetails,
} from './listingModels';
import ListingDetails from './drawer/screens/listingDetails/ListingDetails';
import Showings from './drawer/screens/showings/Showings';
import OpenHouse from './drawer/screens/openHouse/OpenHouse';
import ShowcaseWebsite from './drawer/screens/showcaseWebsite/ShowcaseWebsite';
import AdCopy from './drawer/screens/adCopy/AdCopyForm';
import MainListingForm from './drawer/screens/listingFeatures/components/mainForm/MainListingForm';
import VideoContainer from './drawer/screens/videos/Video';
import ListAgent from './drawer/screens/listingAgents/listAgent/ListAgent';
import SellAgent from './drawer/screens/listingAgents/sellAgent/SellAgent';
import VirtualTour from './drawer/screens/virtualTour/VirtualTour';
import Photos from './drawer/screens/photos/components/photoMenu/MainPhotoMenu';
import { initializeVideoData } from '../../../shared/functions/video/video';
import Dates from './drawer/screens/dates/index';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import Prices from './drawer/screens/prices/index';

export const MarketingReminderStaticId: MarketingRemindersConstant = {
    photosTooSmall: 1,
    propertyNearExpiration: 2,
    addressNotValid: 3,
    mlsImportSuppressed: 4,
    notASelectSaleRental: 5,
    notDisplayingAddress: 6,
    propertyNotPublished: 7,
    showcaseSitePrivate: 8,
    showcaseSiteNotPublished: 9,
    lessThanTenImages: 10,
    noVideos: 11,
    noVirtualTour: 12,
    missingMlsNumber: 13,
    noShowcaseSite: 14,
    notDisplayingPrice: 15,
    moreThanHundredImages: 16,
    adCopy4000Characters: 17,
    missingBuyerCommission: 18,
};

export const editFormComponent = {
    listingDetails: 'ListingDetails',
    additionalMls: 'AdditionalMls',
    listingFeatures: 'ListingFeatures',
    prices: 'Prices',
    dates: 'Dates',
    listAgent: 'ListAgent',
    sellAgent: 'SellAgent',
    adCopy: 'AdCopy',
    photos: 'Photos',
    videos: 'Videos',
    virtualTour: 'VirtualTour',
    showCaseWebsite: 'ShowcaseWebsite',
    openHouse: 'OpenHouse',
    showings: 'Showings',
};

export const editFormMap = new Map([
    [
        editFormComponent.listingDetails,
        {
            component: ListingDetails,
            title: 'Listing Details',
        },
    ],
    [
        editFormComponent.showings,
        {
            component: Showings,
            title: 'Showings',
        },
    ],
    [
        editFormComponent.openHouse,
        {
            component: OpenHouse,
            title: 'Open House',
        },
    ],
    [
        editFormComponent.showCaseWebsite,
        {
            component: ShowcaseWebsite,
            title: 'Showcase Website',
        },
    ],
    [
        editFormComponent.adCopy,
        {
            component: AdCopy,
            title: 'Ad Copy',
        },
    ],
    [
        editFormComponent.listingFeatures,
        {
            component: MainListingForm,
            title: 'Listing Features',
        },
    ],
    [
        editFormComponent.videos,
        {
            component: VideoContainer,
            title: 'Videos',
        },
    ],
    [
        editFormComponent.listAgent,
        {
            component: ListAgent,
            title: 'Listing Agents',
        },
    ],
    [
        editFormComponent.sellAgent,
        {
            component: SellAgent,
            title: 'Sell Agents',
        },
    ],
    [
        editFormComponent.virtualTour,
        {
            component: VirtualTour,
            title: 'Virtual Tour',
        },
    ],
    [
        editFormComponent.photos,
        {
            component: Photos,
            title: 'Photos',
        },
    ],
    [
        editFormComponent.dates,
        {
            component: Dates,
            title: 'Dates',
        },
    ],
    [
        editFormComponent.prices,
        {
            component: Prices,
            title: 'Prices',
        },
    ],
]);
export const initialStateDataConstant: ListingData = {
    status: '',
    publishedDate: '',
    isValid: false,
    lastUpdated: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    id: '',
    images: [],
    videoDetail: initializeVideoData(
        entityTypes.listing.key,
        videoTypes.propertyVideo.key,
        0,
    ),
    listingType: '',
    prices: [
        {
            price: 0,
            rentalPeriod: '',
        },
    ],
    rooms: 0,
    bedrooms: 0,
    fullBaths: 0,
    halfBaths: 0,
    interiorSize: 0,
    exteriorSize: 0,
    lotSize: 0,
    lotSizeUnit: '',
    listingDate: '',
    expirationDate: '',
    mlsNumber: '',
    rfgId: '',
    domainInfo: null,
    listingNumber: '',
    latitude: 0,
    longitude: 0,
    suppressMlsImport: true,
    listingAgents: [
        {
            id: '',
            agentId: '',
            sideTypeId: 0,
            selectSale: false,
            name: '',
            office: '',
            split: 0,
            displayOrder: 0,
            title: '',
            agentType: '',
            workPhone: '',
            mobilePhone: '',
            url: '',
            licenseNumber: '',
        },
    ],
    showAddress: false,
    publish: false,
    priceUponRequest: null,
    dealType: '',
    title: '',
    internetAdCopy: '',
    ownerInfo: null,
    propertyType: '',
    county: '',
    amenities: [],
    listingStatusId: 0,
    buyerCommission: null,
    noFeeRental: null,
    displayOfferPriceForSelectSold: false,
    propertyTypeId: null,
    office: '',
    officeId: '',
    commonCharges: 0,
    maintenance: 0,
    realEstateTax: 0,
    taxesAreMonthly: false,
    listingCommission: null,
    lastAskingPrice: null,
    soldPrice: null,
    salesRegionId: 0,
    listingRentalDetails: null,
    currentPrice: 0,
    regionId: null,
    development: '',
    buildingId: '',
    year: 0,
    lotSizeTypeId: 0,
    virtualTourUrl: '',
    interactiveFloorPlanUrl: '',
    virtualTour3dUrl: '',
    suppressAgentImport: false,
    dataSource: '',
    closedDate: '',
    newListingUntil: '',
    contractDate: '',
    canceledDate: '',
    createdDate: '',
    projectedListDate: '',
    lastPublishedDate: '',
    priorBrokerSale: false,
    initialPrice: 0,
    tridentPrice: 0,
    mlsPrice: 0,
    ignoreTridentPrice: false,
    // displayPrice: 0,
    doNotDisplayTaxesOnWebsite: false,
    displayBuyerCommissionOnWebsite: false,
    maxFinancing: 0,
    tridentOffice: '',
    tridentOfficeId: '',
    tridentDealNumber: '',
    dashId: '',
    taxMapNumber: '',
    floorplanImages: [],
    neighborhood: null,
    showPendingAsActive: false,
    isListingTypeChanged: false,
    useAddressAsTitle: false,
    showMap: false,
    buyerCommissionType: 1,
    listingCommissionType: 1,
    officeIdentifier: null,
};

export const dealTypes = {
    listSide: 'Our List/OB Sale',
    bothSides: 'Our List/Our Sale',
    saleSide: 'OB List/Our Sale',
};

export const listingStatuses = {
    active: 1,
    expired: 2,
    pending: 3,
    sold: 4,
    deleted: 6,
    comingSoon: 7,
    draft: 8,
    tempOffMarket: 9,
    permOffMarket: 10,
    private: 11,
};

export const listingTypeDetails = {
    sale: 'Sale',
    rental: 'Rental',
};

export const rentalDetails = {
    price: 'price',
    yearInfo: 'yearInfo',
};

export const taxSettings: LookupInfo[] = [
    { id: 'true', name: 'Monthly' },
    { id: 'false', name: 'Annual' },
];

export const buyerCommissionTypes: LookupInfo[] = [
    { id: '1', name: 'Percentage' },
    { id: '2', name: 'Dollar' },
    { id: '3', name: 'Buyer Commission Not Offered *' },
];

export const buyerCommissionRentalTypes: LookupInfo[] = [
    { id: '1', name: 'Percentage' },
    { id: '2', name: 'Dollar' },
];

export const listingCommissionTypes: LookupInfo[] = [
    { id: '1', name: 'Percentage' },
    { id: '2', name: 'Dollar' },
];

export const flipTaxList: LookupInfo[] = [
    { id: '1', name: 'No' },
    { id: '2', name: 'Paid By Buyer' },
    { id: '3', name: 'Paid By Seller' },
];

export const dealTypeList: LookupInfo[] = [
    { id: '1', name: 'Our List/OB Sale' },
    { id: '2', name: 'Our List/Our Sale' },
    { id: '3', name: 'OB List/Our Sale' },
];

export const lotSizeTypes: LookupInfo[] = [
    { id: '1', name: 'Acres' },
    { id: '2', name: 'Sq. Ft' },
];

export const listingDescriptionSections: LookupInfo[] = [
    { id: '1', name: 'Basics' },
    { id: '2', name: 'Interior' },
    { id: '3', name: 'Exterior' },
    { id: '4', name: 'Domain' },
];

export const listingTypes: LookupInfo[] = [
    { id: '1', name: 'Sale' },
    { id: '2', name: 'Rental' },
];

export const listingValidations = {
    listingTitleRequired: {
        id: 1,
        fieldName: 'title',
        editScreen: editFormComponent.listingDetails,
        refIndex: 0,
    },
    currentPriceRequired: {
        id: 2,
        fieldName: 'currentPrice',
        editScreen: editFormComponent.prices,
        refIndex: 1,
    },
    initialPriceRequired: {
        id: 3,
        fieldName: 'initialPrice',
        editScreen: editFormComponent.prices,
        refIndex: 0,
    },
    lastAskingPriceRequired: {
        id: 4,
        fieldName: 'lastAskingPrice',
        editScreen: editFormComponent.listingDetails,
        refIndex: 3,
    },
    oneRentalTermIsRequired: {
        id: 5,
        fieldName: '',
        editScreen: editFormComponent.prices,
    },
    adCopyRequired: {
        id: 6,
        fieldName: '',
        editScreen: editFormComponent.adCopy,
    },
    agentInfoRequired: {
        id: 7,
        fieldName: '',
        editScreen: editFormComponent.listAgent,
    },
    expirationDateRequired: {
        id: 8,
        fieldName: 'expirationDate',
        editScreen: editFormComponent.dates,
        refIndex: 1,
    },
    addressRequired: {
        id: 9,
        fieldName: 'address1',
        editScreen: editFormComponent.listingDetails,
        refIndex: 1,
    },
    propertyTypeRequired: {
        id: 10,
        fieldName: 'propertyTypeId',
        editScreen: editFormComponent.listingDetails,
        refIndex: 2,
    },
    photoRequired: {
        id: 11,
        fieldName: '',
        editScreen: editFormComponent.photos,
    },
    agentPlaceholder: {
        id: 12,
        fieldName: '',
        editScreen: '',
    },
    officePlaceholder: {
        id: 13,
        fieldName: '',
        editScreen: '',
    },
    salesTeamPlaceholder: {
        id: 14,
        fieldName: '',
        editScreen: '',
    },
    logoRequired: {
        id: 15,
        fieldName: '',
        editScreen: '',
    },
    exclusiveListingAgentRequired: {
        id: 16,
        fieldName: '',
        editScreen: '',
    },
    agreementDateRequired: {
        id: 17,
        fieldName: 'agreementDate',
        editScreen: editFormComponent.dates,
        refIndex: 2,
    },
    listDateRequired: {
        id: 18,
        fieldName: 'listingDate',
        editScreen: editFormComponent.dates,
        refIndex: 0,
    },
    minimumPricePerUnitRequired: {
        id: 19,
        fieldName: '',
        editScreen: '',
    },
    totalUnitsRequired: {
        id: 20,
        fieldName: '',
        editScreen: '',
    },
    totalDollarValueRequired: {
        id: 21,
        fieldName: '',
        editScreen: '',
    },
    websiteUrlRequired: {
        id: 22,
        fieldName: '',
        editScreen: '',
    },
    associatedListingsRequired: {
        id: 23,
        fieldName: '',
        editScreen: '',
    },
    closedDateRequired: {
        id: 24,
        fieldName: 'closedDate',
        editScreen: editFormComponent.dates,
        refIndex: 3,
    },
};

// for testing in local, to be removed once tested in staging
export const qcTestData: QcStatusDetails = {
    isApproved: false,
    rejectReasons: [
        'Appear Blurry',
        'Exclusive Agent',
        'Exclusive Listing Agreement',
        'General - DNF',
        'Invalid List Price',
        'Select Another Photo',
        'Video Length',
    ],
};
