import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { calculateRemainingChars } from '../../../utils/urlUtils';
import './renderRemainingChar.scss';

interface RenderRemainingCharProps {
    value: string;
    maxLength?: number;
}
const RenderRemainingChar: FunctionComponent<RenderRemainingCharProps> = ({
    value,
    maxLength,
}) => {
    const remainingChar = calculateRemainingChars(value, maxLength);
    const spanClass = remainingChar <= 0 ? 'alert' : '';
    return maxLength || 0 ? (
        <Grid item container spacing={2} sm={12} md={12}>
            <Grid item md={12}>
                <div className="remain-char">
                    <span className={spanClass}>
                        {remainingChar} characters remaining (This character count limit
                        includes spaces and HTML formatting)
                    </span>
                </div>
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};
export default RenderRemainingChar;
