import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { Syndication, SyndicationFetchResponse } from './listingSyndicationModels';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../shared/slices/loader/loaderSlice';
import {
    fetchListingSyndicationBylistingNumber,
    updateSelectedListingValue,
} from '../listingSyndication/listingSyndicationApi';
import { SyndicationEvents } from '../../shared/models/loader/loaderModels';
import Logger from '../../utils/logging/logger';
import { setSuccess, setError } from '../../shared/slices/messaging/messagingSlice';

const initialSyndicationState: SyndicationFetchResponse = {
    id: '',
    listingNumber: '',
    listingSyndication: [],
    sendToSothebysRealtyOnly: false,
    optAll: false,
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    sothebysRealtyOnlyName: '',
    sothebysRealtyOnlyListingUrl: '',
    sothebysRealtyOnlyHyperLinkStatus: '',
    status: 200,
    responseText: '',
};

export const syndicationSlice = createSlice({
    name: 'syndication',
    initialState: initialSyndicationState,
    reducers: {
        loadSyndication: (state, action: PayloadAction<SyndicationFetchResponse>) => {
            state.optAll = getOptAll(action.payload.listingSyndication);
            state.id = action.payload.id;
            state.listingSyndication = action.payload.listingSyndication;
            state.sendToSothebysRealtyOnly = action.payload.sendToSothebysRealtyOnly;
            state.sothebysRealtyOnlyListingUrl =
                action.payload.sothebysRealtyOnlyListingUrl;
            state.sothebysRealtyOnlyName = capitalize(
                action.payload.sothebysRealtyOnlyName,
            );
            state.listingNumber = action.payload.listingNumber;
            state.address1 = action.payload.address1;
            state.address2 = action.payload.address2;
            state.city = action.payload.city;
            state.country = action.payload.country;
            state.zip = action.payload.zip;
            state.state = action.payload.state;
            state.sothebysRealtyOnlyHyperLinkStatus =
                action.payload.sothebysRealtyOnlyHyperLinkStatus;
        },
        clearListing: (state) => {
            state.listingSyndication = [];
        },
        toggleListing: (state, action: PayloadAction<number>) => {
            state.listingSyndication = state.listingSyndication.map((s) =>
                s.dataConsumerId === action.payload ? { ...s, selected: !s.selected } : s,
            );
            state.optAll = getOptAll(state.listingSyndication);
            state.sendToSothebysRealtyOnly = false;
        },
        updateOptAll: (state, action: PayloadAction<boolean>) => {
            state.optAll = action.payload;
            state.sendToSothebysRealtyOnly = false;
            state.listingSyndication = state.listingSyndication.map((s, index) => {
                return { ...s, selected: action.payload };
            });
        },
        updateSoTheBysRealty: (state, action: PayloadAction<boolean>) => {
            state.sendToSothebysRealtyOnly = action.payload;
            if (action.payload) {
                state.optAll = !action.payload;
                state.listingSyndication = state.listingSyndication.map((s, index) => {
                    return { ...s, selected: !action.payload };
                });
            }
        },
    },
});

function capitalize(input: string) {
    const words = input.split(' ');
    const CapitalizedWords: string[] = [];
    words.forEach((element) => {
        CapitalizedWords.push(
            element[0].toUpperCase() + element.slice(1, element.length),
        );
    });
    return CapitalizedWords.join(' ');
}
function getOptAll(syndications: Syndication[]): boolean {
    // When loading syndications if any are selected, default
    // the optAll slider to "on"
    return syndications.length > 0
        ? syndications.filter((s) => s.selected).length === syndications.length
        : false;
}

/*
For Fetching the listing Details with @listingNumber
*/
export const getListing =
    (listingNumber: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(SyndicationEvents.LISTING_SYNDICATION_GET));
            const response = await fetchListingSyndicationBylistingNumber(listingNumber);
            const listingNo = getState().listing.listing.data.listingNumber;
            if (response.status !== 200 && response.responseText) {
                if (response.status === 401) {
                    dispatch(setError(response.responseText));
                } else {
                    throw Error('Invalid Listing No');
                }
            } else {
                if (!response.listingNumber) {
                    response.listingNumber = listingNo;
                }
                dispatch(loadSyndication(response));
            }
        } catch (e) {
            Logger.error(`Error Invalid Listing Number: ${listingNumber}`);
            dispatch(setError('Invalid Listing Number: ' + listingNumber));
        } finally {
            dispatch(removeLoadingEvent(SyndicationEvents.LISTING_SYNDICATION_GET));
        }
    };

/*
For updating the listing Option using @listingNumber , @channelId, @status
*/
export const saveCall =
    (requestListing: SyndicationFetchResponse): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(SyndicationEvents.LISTING_SYNDICATION_GET));
            await updateSelectedListingValue(requestListing);
            dispatch(setSuccess(`Saved!`));
        } catch (e) {
            Logger.error(`Failed to update the listing`);
            dispatch(setError(`Failed to Updated the Listing Details`));
        } finally {
            dispatch(removeLoadingEvent(SyndicationEvents.LISTING_SYNDICATION_GET));
        }
    };
export const {
    loadSyndication,
    clearListing,
    toggleListing,
    updateOptAll,
    updateSoTheBysRealty,
} = syndicationSlice.actions;
export const selectListing = (state: RootState): SyndicationFetchResponse =>
    state.listingSyndication;
export default syndicationSlice.reducer;
