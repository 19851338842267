import React, { FunctionComponent, useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { LookupInfo } from '../../models/lookups/lookupsModels';

interface MultiSelectDropDownProps {
    id: string;
    label: string;
    options: LookupInfo[];
    handleSelection: (selectedItems: LookupInfo[]) => void;
    defaultValues?: LookupInfo[];
    className?: string;
    clearSelection?: boolean | null;
    disableMS?: boolean;
    defaultWidth?: boolean;
}

const MultiSelectDropDown: FunctionComponent<MultiSelectDropDownProps> = ({
    id,
    label,
    options,
    handleSelection,
    defaultValues,
    className,
    clearSelection,
    disableMS,
    defaultWidth = true,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [selectedVals, setSelectedVals] = useState<LookupInfo[]>([]);

    useEffect(() => {
        if (defaultValues?.length) {
            setSelectedVals(defaultValues);
        }
    }, [defaultValues?.length]);

    const handleSelectionMulti = (vals: LookupInfo[]) => {
        setSelectedVals(vals);
        handleSelection(vals);
    };

    useEffect(() => {
        if (typeof clearSelection === 'boolean') {
            setSelectedVals([]);
        }
    }, [clearSelection]);

    return (
        <Autocomplete
            className={className}
            multiple
            id={id}
            options={options}
            value={selectedVals}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => handleSelectionMulti(value)}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#001731' }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            style={{ width: defaultWidth ? 500 : 'inherit' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={label}
                />
            )}
            disabled={disableMS ? disableMS : false}
        />
    );
};

export default MultiSelectDropDown;
