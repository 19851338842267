import { getCall } from '../../../utils/api/apiWrapper';
import { AgentSearchResult } from '../../models/agent/agentModel';

export function getAgentsByLastName(
    name: string,
    salesTeam?: boolean,
): Promise<AgentSearchResult[]> {
    const url = 'Agent/searchbyname?name=';
    return !salesTeam
        ? getCall<AgentSearchResult[]>(`${url}${name}`)
        : getCall<AgentSearchResult[]>(`${url}${name}&forSalesTeam=true`);
}
