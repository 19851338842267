import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import Logger from '../../utils/logging/logger';
import {
    getAgentProductionApi,
    getAgentScheduleApi,
    setAgentListingsListApi,
} from './myBusinessApi';
import {
    AgentListingListModel,
    AgentListAddress,
    MyBusinessState,
    AgentScheduleModel,
    AgentProductionModel,
} from './myBusinessModel';

const initialMyBusinessState: MyBusinessState = {
    agentSoldListingsList: [],
    agentStaleListingsList: [],
    agentSchedule: null,
    agentProduction: [],
};

export const myBusinessSlice = createSlice({
    name: 'myBusiness',
    initialState: initialMyBusinessState,
    reducers: {
        setAgentSoldListingsList: (state, action: PayloadAction<AgentListAddress[]>) => {
            state.agentSoldListingsList = action.payload;
        },
        setAgentStaleListingsList: (state, action: PayloadAction<AgentListAddress[]>) => {
            state.agentStaleListingsList = action.payload;
        },
        setAgentSchedule: (state, action: PayloadAction<AgentScheduleModel>) => {
            state.agentSchedule = action.payload;
        },
        setAgentProduction: (state, action: PayloadAction<AgentProductionModel[]>) => {
            state.agentProduction = action.payload;
        },
    },
});

export const fetchAgentListing =
    (data: Partial<AgentListingListModel>, from: string): AppThunk =>
    async (dispatch) => {
        try {
            const apiResponse = await setAgentListingsListApi(data);
            if (apiResponse) {
                if (from === 'sold') {
                    dispatch(setAgentSoldListingsList(apiResponse.results));
                } else if (from === 'stale') {
                    dispatch(setAgentStaleListingsList(apiResponse.results));
                }
            }
        } catch (e) {
            dispatch(setError(`Failed to fetch agent old ${from} listing`));
            Logger.error(`Failed to fetch agent old ${from} listing`, e);
        }
    };

export const fetchAgentSchedules =
    (tridentAgentId: number): AppThunk =>
    async (dispatch) => {
        try {
            const apiResponse = await getAgentScheduleApi(tridentAgentId);
            if ('nextThreshold' in apiResponse) {
                dispatch(setAgentSchedule(apiResponse));
            } else {
                throw Error('');
            }
        } catch (e) {
            dispatch(setError(`Failed to fetch agent schedule`));
            Logger.error(`Failed to fetch agent schedule`, e);
        }
    };

export const fetchAgentProduction =
    (tridentAgentId: number): AppThunk =>
    async (dispatch) => {
        try {
            const apiResponse = await getAgentProductionApi(tridentAgentId);
            if (apiResponse?.length) {
                dispatch(setAgentProduction(apiResponse));
            } else {
                throw Error('');
            }
        } catch (e) {
            dispatch(setError(`Failed to fetch agent production`));
            Logger.error(`Failed to fetch agent production`, e);
        }
    };
export const {
    setAgentSoldListingsList,
    setAgentStaleListingsList,
    setAgentSchedule,
    setAgentProduction,
} = myBusinessSlice.actions;
export const myBusinessData = (state: RootState): MyBusinessState => state.myBusiness;

export default myBusinessSlice.reducer;
