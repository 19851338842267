import { getApiCall } from '../../../utils/api/apiWrapper';
import { AuditModel } from '../../models/audit/auditModels';

export function getHistoryData(
    entity: string,
    id: string,
    pageNumber: number,
    pageSize: number,
): Promise<AuditModel[]> {
    const auditUrl = process.env.REACT_APP_APIS_URL;
    return getApiCall<AuditModel[]>(
        `${auditUrl}/auditing/${entity}/${id}/audits?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
}
