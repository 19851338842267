import { faEdit, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';
import { EmailCampaignData } from '../../../../../../shared/models/marketing/marketingModels';
import { currencyFormat, dateFormatMMDDYYY } from '../../../../../../utils/urlUtils';

interface EmailCampaignListProps {
    email: EmailCampaignData;
    onEditClick(id: string | undefined): void;
    deleteIt(id: string | undefined): void;
}

const EmailCampaignList: FunctionComponent<EmailCampaignListProps> = ({
    email,
    onEditClick,
    deleteIt,
}) => {
    const ga = () => {
        googleAnalyticsAction('Change Display', 'Click', 'Email Campaign - Builder');
    };
    return (
        <div className="table-data">
            {email.date && dateFormatMMDDYYY(email.date.toString()) ? (
                <div className="data-date date-text large">
                    {dateFormatMMDDYYY(email.date.toString())}
                </div>
            ) : (
                <div className="data-date date-text small"></div>
            )}
            {email.description && email.description.trim().length > 0 ? (
                <div className="data-name data-text large">{email.description}</div>
            ) : (
                <div className="data-name data-text large"></div>
            )}
            {email.emailsSent && !isNaN(email.emailsSent) ? (
                <div className="data-emails-sent data-text small">
                    {currencyFormat(email.emailsSent)}
                </div>
            ) : (
                <div className="data-emails-sent data-text small"></div>
            )}
            {email.views && !isNaN(email.views) ? (
                <div className="data-views data-text small">
                    {currencyFormat(email.views)}
                </div>
            ) : (
                <div className="data-views data-text small"></div>
            )}
            {email.clicks && !isNaN(email.clicks) ? (
                <div className="data-clicks data-text small">
                    {currencyFormat(email.clicks)}
                </div>
            ) : (
                <div className="data-clicks data-text small"></div>
            )}
            {email.audience && email.audience.trim().length > 0 ? (
                <div className="data-audience data-text small">{email.audience}</div>
            ) : (
                <div className="data-audience data-text small"></div>
            )}
            <div className="email-campaigns-icons small">
                {email.url ? (
                    <a href={email.url} target="_blank" rel="noreferrer">
                        <span className="file icon" onClick={ga}>
                            <FontAwesomeIcon icon={faFile} size="sm" />
                        </span>
                    </a>
                ) : (
                    ''
                )}
                <span className="edit icon">
                    <FontAwesomeIcon
                        icon={faEdit}
                        size="sm"
                        onClick={() => onEditClick(email.marketingId)}
                    />
                </span>
                <span className="trash icon">
                    <FontAwesomeIcon
                        icon={faTrash}
                        size="sm"
                        onClick={() => deleteIt(email.marketingId)}
                    />
                </span>
            </div>
        </div>
    );
};

export default EmailCampaignList;
