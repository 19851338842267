import { Order, TableHeadCell } from '../../shared/models/customTable/customTableModels';

export const officeListInitialSearchRequest = {
    sortColumn: 'Name',
    sortDirection: 'asc' as Order,
    status: 0,
    officeName: '',
    currentPage: 1,
    ids: [],
    itemsPerPage: 20,
};

export const officeTableHeadCells: TableHeadCell[] = [
    {
        id: 'valid',
        label: 'Data Complete',
        dbColMapping: '',
        checkbox: true,
        doSort: false,
    },
    {
        id: 'publish',
        label: 'Publish',
        dbColMapping: '',
        checkbox: true,
        doSort: false,
    },
    {
        id: 'officeName',
        label: 'Office Name',
        dbColMapping: 'Name',
    },
    {
        id: 'locationCode',
        label: 'Location Code',
        dbColMapping: 'TridentLocationCode',
    },
    {
        id: 'manager',
        label: 'Manager',
        dbColMapping: 'ManagerFirstName',
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
        dbColMapping: 'PhoneNumber',
        isPhone: true,
    },
    {
        id: 'region',
        label: 'Region',
        dbColMapping: 'OfficeRegion',
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: 'Status',
        icon: true,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
];
