import {
    AgentAssistant,
    AgentDetailsForAssistant,
    AgentSearchSelect,
    AgentSelect,
} from './agentModels';
import Assistants from './drawer/screens/assistants/Assistants';
import Expertise from './drawer/screens/expertiseLangDesigSpec';
import AgentBiography from './drawer/screens/biography';
import Description from './drawer/screens/description/Description';
import Photo from './drawer/screens/photo';
import Video from './drawer/screens/video/Video';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';
import SelectRentals from './drawer/screens/selectRentals';
import SelectSolds from './drawer/screens/selectSolds';
import AgentTestimonials from './drawer/screens/agentTestimonials/AgentTestimonials';

export const agentDashboardMapping = {
    description: {
        urlName: 'detailsummary',
        stateName: 'descriptionDetails',
    },
    profileDetails: {
        urlName: 'cms',
        stateName: 'agentWebsiteProfileDetails',
    },
    accolades: {
        urlName: 'accolades',
        stateName: 'agentAccolades',
    },
    assistants: {
        urlName: 'assistants',
        stateName: 'agentAssistants',
    },
    photo: {
        urlName: '',
        stateName: 'agentPhoto',
    },
    biography: {
        urlName: 'agentBio',
        stateName: 'agentBiography',
    },
    testimonials: {
        urlName: '-',
        stateName: 'agentTestimonials',
    },
    video: {
        urlName: '-',
        stateName: 'agentVideo',
    },
    languages: {
        urlName: 'languages',
        stateName: 'agentLanguages',
    },
    specializations: {
        urlName: 'specializations',
        stateName: 'agentSpecializations',
    },
    designations: {
        urlName: 'designations',
        stateName: 'agentDesignations',
    },
    agentDetailsForAssistant: {
        urlName: 'agentDetails',
        stateName: 'agentDetailsForAssistant',
    },
    agentDataComplete: {
        urlName: 'validate',
        stateName: 'agentDataCompleteDetails',
    },
};

export const agentInfoConstant: CustomFormedData[] = [
    {
        title: 'PREFERRED FIRST NAME',
        value: 'preferredFirstName',
    },
    {
        title: 'PREFERRED Last NAME',
        value: 'preferredLastName',
    },
    {
        title: 'TEAM',
        value: 'team',
        isLink: true,
    },
    {
        title: 'MLS ID',
        value: 'primaryMls',
    },
    {
        title: 'TRIDENT ID',
        value: 'tridentId',
    },
    {
        title: 'AGENT ID',
        value: 'agentId',
    },
    {
        title: 'DASH STAFF ID',
        value: 'dashStaffId',
    },
    {
        title: 'SALUTATION',
        value: 'salutation',
    },
    {
        title: 'LICENSE NUMBER',
        value: 'licenseNumber',
    },
    {
        title: 'STATUS',
        value: 'status',
    },
    {
        title: 'START DATE',
        value: 'startDate',
        isDate: true,
    },
    {
        title: 'TYPE',
        value: 'type',
    },
];

export const contactsConstant: CustomFormedData[] = [
    {
        title: 'ADDRESS 1',
        value: 'address1',
    },
    {
        title: 'ADDRESS 2',
        value: 'address2',
    },
    {
        title: 'CITY',
        value: 'city',
    },
    {
        title: 'STATE',
        value: 'state',
    },
    {
        title: 'ZIP',
        value: 'zip',
    },
    {
        title: 'TRIDENT OFFICE',
        value: 'tridentOffice',
    },
    {
        title: 'WEB OFFICE',
        value: 'webOffice',
    },
];

export const phoneConstant: CustomFormedData[] = [
    {
        title: 'Work Phone',
        value: 'workPhone',
        isNum: true,
    },
    {
        title: 'Mobile Phone',
        value: 'mobilePhone',
        isNum: true,
    },
    {
        title: 'Home Phone',
        value: 'homePhone',
        isNum: true,
    },
    {
        title: 'Fax',
        value: 'fax',
        isNum: true,
    },
];

export const emailConstant: CustomFormedData[] = [
    {
        title: 'Brand Email',
        value: 'brandEmail',
    },
    {
        title: 'Personal Email',
        value: 'personalEmail',
    },
    {
        title: 'Alternate Email',
        value: 'alternateEmail',
    },
    {
        title: 'SothebysRealty Email',
        value: 'sothebysRealtyEmail',
    },
    {
        title: 'Website Url',
        value: 'websiteUrl',
        isExternal: true,
    },
    {
        title: 'SIR.com Url',
        value: 'agentRedirectUrl',
        displayText: 'SIR.com Agent URL',
        isLink: true,
    },
];

export const socialNetworkConstant: CustomFormedData[] = [
    {
        title: 'LinkedIn',
        value: 'linkedInUrl',
        isExternal: true,
    },
    {
        title: 'Facebook',
        value: 'facebookUrl',
        isExternal: true,
    },
    {
        title: 'Twitter X',
        value: 'twitterUrl',
        isExternal: true,
    },
    {
        title: 'Instagram',
        value: 'instagramUrl',
        isExternal: true,
    },
    {
        title: 'Youtube',
        value: 'youtubeUrl',
        isExternal: true,
    },
];

export const expertiseConstant: CustomFormedData[] = [
    {
        title: '',
        value: 'areaOfExpertise',
    },
];

/**
 *
 * @param dataType number corresponding to the data value
 * @param data data value to which custom formed data has to be returned
 */

export const getCustomValues = (
    dataType: number,
    data: LookupInfo | AgentAssistant | AgentDetailsForAssistant,
): string => {
    let customData;
    switch (dataType) {
        case 1:
            customData = data as LookupInfo;
            return `${customData.name}`;
        case 2:
            customData = data as AgentAssistant;
            return `${customData.brandEmail}`;
        case 3:
            customData = data as AgentAssistant;
            return `${customData.firstName} ${customData.lastName}`;
        case 4:
            customData = data as AgentDetailsForAssistant;
            return `${customData.agentName}`;
        default:
            return '';
    }
};

export const editFormComponent = {
    description: 'Description',
    assistants: 'Assistants',
    expertise: 'Expertise',
    biography: 'AgentBiography',
    photo: 'Photo',
    video: 'Video',
    additionalMls: 'AdditionalMls',
    selectSales: 'SelectSolds',
    selectRentals: 'SelectRentals',
    agentTestimonials: 'AgentTestimonials',
};

export const editFormMap = new Map([
    [
        editFormComponent.description,
        {
            component: Description,
            title: 'Description',
        },
    ],
    [
        editFormComponent.assistants,
        {
            component: Assistants,
            title: 'Assistants',
        },
    ],
    [
        editFormComponent.expertise,
        {
            component: Expertise,
            title: 'Other Specializations/Languages/Designations',
        },
    ],
    [
        editFormComponent.biography,
        {
            component: AgentBiography,
            title: 'Biography',
        },
    ],
    [
        editFormComponent.photo,
        {
            component: Photo,
            title: 'Photo',
        },
    ],
    [
        editFormComponent.video,
        {
            component: Video,
            title: 'Video',
        },
    ],
    [
        editFormComponent.selectSales,
        {
            component: SelectSolds,
            title: 'Agent Select Sales',
        },
    ],
    [
        editFormComponent.selectRentals,
        {
            component: SelectRentals,
            title: 'Agent Select Rentals',
        },
    ],
    [
        editFormComponent.agentTestimonials,
        {
            component: AgentTestimonials,
            title: 'Agent Testimonials',
        },
    ],
]);
export const minPhotoDim = {
    minWidth: 840,
    minHeight: 1120,
};

export const soldListingDirectionOption: LookupInfo[] = [
    {
        id: 'asc',
        name: 'Ascending (Least Expensive or Oldest)',
    },
    {
        id: 'desc',
        name: 'Descending (Most Expensive or Newest)',
    },
];

export const soldListingPriceOption: LookupInfo[] = [
    {
        id: 'closedDate',
        name: 'Closed Date',
    },
    {
        id: 'price',
        name: 'Price',
    },
];

export const initialAgentSelectSaleSearch: AgentSearchSelect = {
    isSelectSale: false,
    published: false,
    isValid: false,
    address1: '',
    rfgid: '',
    mlsNumber: '',
    soldPrice: 0,
};

export const initialAgentSelectSale: AgentSelect = {
    selectSalesTableAction: {
        currentPage: 1,
        itemsPerPage: 20,
        sortDirection: 'desc',
        sortColumn: 'closedDate',
    },
    selectSalesList: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
};
