import { FunctionComponent, useEffect, useState } from 'react';
import ScheduleListings from './scheduleListings';
import ScheduleMarketReport from './scheduleMarketReport';
import SentEmailsCom from './sentEmails';
import { useAppDispatch } from '../../../../app/hooks';
import { useParams } from 'react-router-dom';
import { SchedulerInter, SchedulerDetailsProps } from './schedulerModels';
import { deleteScheduleById, getSchedulerDetails } from './schedulerSlice';
import './scheduler.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Scheduler: FunctionComponent<SchedulerInter> = ({ closeScheduler }) => {
    const dispatch = useAppDispatch();
    const [getId, setId] = useState<string>('');
    const { listingId } = useParams<SchedulerDetailsProps>();

    useEffect(() => {
        if (listingId) {
            dispatch(getSchedulerDetails(listingId));
        }
    }, [listingId, dispatch]);

    const scrollToTop = () => {
        const holderElement: HTMLElement | null = document.getElementById(
            'scheduleMarketReportHolder',
        );
        if (holderElement !== null) {
            holderElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const editClickHandler = (id: string) => {
        setId(id);
        scrollToTop();
    };

    const deleteItem = (id: string) => {
        dispatch(deleteScheduleById(id));
    };

    return (
        <div className="scheduleHolder">
            <div className="scheduleHeader">
                <span>Client Marketing - Schedule Report</span>
                <button className="backButton" onClick={closeScheduler}>
                    Back <FontAwesomeIcon className="arrow" icon={faChevronRight} />
                </button>
            </div>
            <div className="scheduleBody">
                <ScheduleMarketReport
                    closeScheduler={closeScheduler}
                    id={getId}
                    resetId={() => setId('')}
                    scrollToTop={scrollToTop}
                />
                <ScheduleListings
                    editClickHandler={editClickHandler}
                    deleteItem={deleteItem}
                />
                <SentEmailsCom />
            </div>
        </div>
    );
};

export default Scheduler;
