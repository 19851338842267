import React, { FunctionComponent, useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { listingFeaturesSliceState, toggleAmenityFlag } from '../../listingFeaturesSlice';
import './featureChips.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import { updateAmenities } from '../../../../../listingSlice';
import {
    ListingAmenity,
    ListingAmenityWithSelected,
} from '../../../../../../../../shared/models/listing/amenityModels';
import Lodash from 'lodash';

interface FeatureChipsProps {
    chips: ListingAmenityWithSelected[];
    listingId: string;
    handleAddAndDelete?: (selectedFeatures: ListingAmenity[], isAdded: boolean) => void;
}

const FeatureChips: FunctionComponent<FeatureChipsProps> = ({
    chips,
    listingId,
    handleAddAndDelete,
}) => {
    const dispatch = useAppDispatch();
    const { amenities } = useAppSelector(listingFeaturesSliceState);
    const [extChips, setExtChips] = useState<ListingAmenityWithSelected[]>(chips);

    useEffect(() => {
        setExtChips(chips);
    }, [chips]);

    const getAmenities = (ids: number[]): ListingAmenity[] => {
        return amenities.filter((f) => ids.indexOf(f.amenityId) > -1);
    };

    const handleChipToggle = (ids: number[], isAdded: boolean) => {
        const updatedFeatures = getAmenities(ids);
        if (listingId === '') {
            handleAddAndDelete?.(updatedFeatures, isAdded);
        } else {
            dispatch(
                updateAmenities({ features: updatedFeatures, addingUpdating: isAdded }),
            );
            dispatch(toggleAmenityFlag(true));
        }
    };

    return (
        <div className="chip-list sectionContainer">
            {extChips?.length
                ? extChips.map((data) =>
                      data.selected ? (
                          <Chip
                              key={data.amenityId}
                              label={data.amenityName}
                              className="selected"
                              onDelete={() => handleChipToggle([data.amenityId], false)}
                          />
                      ) : (
                          <Chip
                              key={data.amenityId}
                              label={data.amenityName}
                              onClick={() => handleChipToggle([data.amenityId], true)}
                          />
                      ),
                  )
                : ''}
        </div>
    );
};

const featureChipsPropsAreEqual = (
    prevProps: Readonly<React.PropsWithChildren<FeatureChipsProps>>,
    nextProps: Readonly<React.PropsWithChildren<FeatureChipsProps>>,
) => {
    return Lodash.isEqual(prevProps, nextProps);
};

const MemoizedFeatureChips = React.memo(FeatureChips, featureChipsPropsAreEqual);

export default MemoizedFeatureChips;
