import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import TestimonialsAdd from './add/TestimonialsAdd';
import TestimonialList from './list/TestimonialList';
import { TestimonialModel } from '../../models/testimonial/testimonial';

interface TestimonialHolderModel {
    stateTestimonialList: TestimonialModel[];
    saveCall: (from: string, data: TestimonialModel | TestimonialModel[]) => void;
}

const Testimonials: FunctionComponent<TestimonialHolderModel> = ({
    stateTestimonialList,
    saveCall,
}) => {
    const initialAddData = {
        id: '',
        author: '',
        testimony: '',
        displayOrder: 0,
    };
    const save = (from: string, data: TestimonialModel) => {
        saveCall(from, data as TestimonialModel);
    };

    const cancel = () => {
        console.log('ss');
    };

    const dnd = (from: string, data: TestimonialModel[]) => {
        saveCall(from, data as TestimonialModel[]);
    };

    return (
        <Grid container className="testimonials">
            <Grid item md={12}>
                <TestimonialsAdd
                    from="add"
                    data={initialAddData}
                    save={save}
                    cancel={cancel}
                />
            </Grid>
            <Grid item md={12}>
                <TestimonialList
                    stateTestimonialList={stateTestimonialList}
                    update={save}
                    dnd={dnd}
                />
            </Grid>
        </Grid>
    );
};

export default Testimonials;
