import { SearchedListingAgentModel } from './otherMarketingModel';
import _ from 'lodash';
import { jsonToFormData } from '../../utils/urlUtils';
import { LookupInfo } from '../../shared/models/lookups/lookupsModels';
import { AgentSelectedToBeBilled } from '../../shared/models/agentBilling/agentBilling';
import { additionalPromotion } from '../../shared/constants/otherMarketing/otherMarketingConts';
import { OtherMarketing } from '../../shared/models/marketing/marketingModels';

/**
 * function used to create a default object that will be used to form the billing information on frontend
 * @param agent
 * @returns
 */
export const formAgentBillingObject = (
    agent: SearchedListingAgentModel,
): AgentSelectedToBeBilled => {
    return {
        listingId: agent.listingId.length ? agent.listingId : null,
        agentId: agent.agentId,
        agentName: agent.firstName + ' ' + agent.lastName,
        creditMemo: '',
        actualCost: 0,
        officeCost: 0,
        agentCost: 0,
        agentPaid: false,
        cit: true,
    };
};

/**
 * function that is used to calcuate the billing details(agent, office and actual cost)
 * for agent based on the parent cost
 * @param data
 * @param agentData
 * @returns
 */
export const updateAgentBillingCost = (
    data: OtherMarketing,
    agentData: AgentSelectedToBeBilled[],
): AgentSelectedToBeBilled[] => {
    return agentData.map((agentDetail: AgentSelectedToBeBilled) => {
        return {
            ...agentDetail,
            agentCost:
                data.agentCost !== 0 ? _.round(data.agentCost / agentData.length, 2) : 0,
            officeCost:
                data.officeCost !== 0
                    ? _.round(data.officeCost / agentData.length, 2)
                    : 0,
            actualCost:
                data.actualCost !== 0
                    ? _.round(data.actualCost / agentData.length, 2)
                    : 0,
        };
    });
};

/**
 * to validate the save other marketing form on create and edit
 * @param data
 * @param ignoreField used when property marketing edit happens
 * @returns
 */
export const validateAdditionalMarketingForm = (
    data: OtherMarketing,
    ignoreField?: boolean,
): { isValid: boolean; errorMessage: string } => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;

    if (!data.marketingTypeId)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Marketing Type,',
        };
    if (data.marketingTypeId === additionalPromotion && !data.additionalPromotionId)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Additional Promotion Type,',
        };
    if (!data.date)
        result = { isValid: false, errorMessage: result.errorMessage + 'Date,' };
    if (!data.description)
        result = { isValid: false, errorMessage: result.errorMessage + 'Description,' };
    if (!data.billingCode)
        result = { isValid: false, errorMessage: result.errorMessage + 'Billing Code,' };

    if (!result.errorMessage) {
        if (!ignoreField) {
            if (!data.agentMarketingCosts.length)
                result = {
                    isValid: false,
                    errorMessage:
                        'Additional marketing can be created with atleast one agent',
                };
            else if (data.agentMarketingCosts.length) {
                const showError = data.agentMarketingCosts.some((data) => {
                    if (
                        data.cit &&
                        data.officeCost &&
                        (!data.creditMemo || !data.actualCost)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (showError) {
                    result = {
                        isValid: false,
                        errorMessage:
                            'Having an Office Cost for an item that is marked for CIT produces a Credit Memo. Credit Memos require that an agent charge be created and Credit Memo type be selected. Please ensure there is an amount in the Agent Cost column and select a credit type in the Credit Memo column. Thank you.',
                    };
                }
            }
        }
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};

/**
 * Function to check if the current form data is equivalent or not with old data.
 * @param currentState
 * @param initialState
 * @returns
 */
export const isFormDataChanged = (
    currentState: OtherMarketing,
    initialState: OtherMarketing | null,
): boolean => {
    let flag = true;
    for (const [key, value] of Object.entries(currentState)) {
        if (initialState && initialState.hasOwnProperty(key)) {
            if (_.isEqual(value, initialState[key as keyof typeof initialState])) {
                flag = true;
            } else {
                flag = false;
                break;
            }
        }
    }
    return flag;
};

/**
 * common function used in save and updated OM
 */

export const generateData = (formData: OtherMarketing): FormData => {
    const billingInfo = formData.agentMarketingCosts;
    const otherInfo = _.omit(formData, ['agentMarketingCosts', 'listings']);
    const jsonData = jsonToFormData(otherInfo);
    jsonData.append('agentMarketingCosts', JSON.stringify(billingInfo));
    return jsonData;
};

/**
 * get the marketing name based on id
 */
export const getMarketingName = (id: string, marketingTypes: LookupInfo[]): string => {
    const type = marketingTypes.find((data) => data.id === id); // Changed for now, since API sends back data in numeric type
    return type ? type.name : '';
};
