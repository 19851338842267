import { TableHeadCell } from '../../../shared/models/customTable/customTableModels';

export const mediaOutletTableHeadCells: TableHeadCell[] = [
    {
        id: 'logo',
        label: '',
        dbColMapping: '',
        doSort: false,
        isImage: true,
    },
    {
        id: 'name',
        label: 'Name',
        dbColMapping: 'Name',
        doSort: true,
    },
    {
        id: 'description',
        label: 'Description',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'mediaKit',
        label: 'Media Kit',
        dbColMapping: '',
        customIcon: true,
        actionType: 'redirect',
        doSort: false,
    },
    {
        id: 'image',
        label: 'Image',
        dbColMapping: '',
        customIcon: true,
        actionType: 'redirect',
        doSort: false,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
    {
        id: 'statusId',
        label: '',
        dbColMapping: '',
        actionType: 'delete',
        doSort: false,
        customComponent: true,
    },
];

export enum MediaOutletFileType {
    Image = 1,
    MediaKit,
    Logo,
}
