import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { setError } from '../../shared/slices/messaging/messagingSlice';

import Logger from '../../utils/logging/logger';
import { getNewsApi, getStatsApi } from './homePageApi';
import {
    UpdateFlag,
    HomePageState,
    News,
    Stats,
    OfficeStatsModel,
} from './homePageModel';

const initialHomePageState: HomePageState = {
    news: [],
    stats: undefined,
    loader: {
        statsLoader: false,
        newsLoader: false,
    },
};

export const homePageSlice = createSlice({
    name: 'homePage',
    initialState: initialHomePageState,
    reducers: {
        setProgress: (state, action: PayloadAction<UpdateFlag>) => {
            state.loader[
                action.payload.property as keyof typeof initialHomePageState.loader
            ] = action.payload.value;
        },
        setNews: (state, action: PayloadAction<News[]>) => {
            return {
                ...state,
                news: action.payload,
            };
        },
        setStats: (state, action: PayloadAction<Stats>) => {
            return {
                ...state,
                stats: action.payload,
            };
        },
    },
});

export const getNews = (): AppThunk => async (dispatch) => {
    try {
        dispatch(
            setProgress({
                property: 'newsLoader',
                value: true,
            }),
        );
        const response = await getNewsApi();
        if (response.length > 0) {
            dispatch(setNews(response));
        }
    } catch (exception) {
        dispatch(setError(`Failed to get the news for carousel`));
        Logger.error(`Failed to get the news for carousel ${JSON.stringify(exception)}`);
    } finally {
        dispatch(
            setProgress({
                property: 'newsLoader',
                value: false,
            }),
        );
    }
};

export const getStats =
    (requestDetials: OfficeStatsModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setProgress({
                    property: 'statsLoader',
                    value: true,
                }),
            );
            const response = await getStatsApi(requestDetials);
            if (response) {
                dispatch(setStats(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get office stats`));
            Logger.error(`Failed to get office stats ${JSON.stringify(exception)}`);
        } finally {
            dispatch(
                setProgress({
                    property: 'statsLoader',
                    value: false,
                }),
            );
        }
    };

export const { setProgress, setNews, setStats } = homePageSlice.actions;
export const homePageData = (state: RootState): HomePageState => state.homePage;

export default homePageSlice.reducer;
