import { LookupInfo } from '../../models/lookups/lookupsModels';

export const status = {
    active: 'Active',
    inactive: 'Inactive',
};

export const statuses: LookupInfo[] = [
    { id: '1', name: status.active },
    { id: '2', name: status.inactive },
];

export const marketingTypes: LookupInfo[] = [
    { id: '14', name: 'Advertisement' },
    { id: '12', name: 'Email Campaign' },
    { id: '15', name: 'Social Campaign' },
    { id: '10', name: 'Featured Placement' },
    { id: '16', name: 'Additional Promotion' },
];
