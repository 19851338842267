import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DevelopmentPhotosState,
    TotalImageCount,
    DeleteData,
    AddImage,
} from './developmentPhotosModels';
import { typesInitialState } from './developmentPhotosConstants';
import { AppThunk, RootState } from '../../../../../../app/store';
import BuildingFloorplans from './components/BuildingFloorplans';
import Buildings from './components/Buildings';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import { getImages } from '../../../../../../shared/api/images/sharedApi';
import { PhotoEditorEvents } from '../../../../../../shared/models/loader/loaderModels';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { UpdateFlag } from '../../../../../../shared/models/flag/flagModel';
import {
    getTotalImageCount,
    getImageTypes,
    saveImages,
    deleteImages,
    saveImagesPackage,
    shareImages,
    getTags,
    saveCaptionDetails,
    saveTags,
    saveReorderImage,
    updateThumbnailImage,
    getFilter,
} from './developmentPhotosApi';
import {
    ImageData,
    ImageUrls,
    ImageDetail,
    PackageProps,
    ShareDetails,
    CaptionDetails,
    UpdateCaptionDetails,
    TagSave,
    UpdateFilter,
    OrderNoUpdate,
    ImageTypes,
    Filter,
    ImageUpdate,
} from '../../../../../../shared/models/images/sharedModel';
import { jsonToFormData } from '../../../../../../utils/urlUtils';
import { packageAuthHeader } from '../../../../../../utils/api/apiWrapper';
import {
    toDataURL,
    getCurrentImageType,
    getStreetAddress,
} from '../../../../../../shared/utils/photos/photoUtils';
import { DevelopmentCustomKey } from '../../../developmentDetailsModel';
import { updateMainDevelopmentData } from '../../../developmentDetailsSlice';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import loDash from 'lodash';

export const developmentPhotoForms = {
    buildings: 'Buildings',
    buildingFloorplans: 'buildingFloorplans',
};
export const developmentPhotoFormMap = new Map([
    [developmentPhotoForms.buildings, Buildings],
    [developmentPhotoForms.buildingFloorplans, BuildingFloorplans],
]);

const initialState: DevelopmentPhotosState = {
    allImages: {
        buildings: [],
        buildingFloorplans: [],
    },
    allImagesFlags: {
        buildings: false,
        buildingFloorplans: false,
    },
    currentOrderNo: {
        buildings: 0,
        buildingFloorplans: 0,
    },
    imagesCount: {
        buildings: 0,
        buildingFloorplans: 0,
    },
    flags: {
        isUploading: false,
        imageCountFlag: false,
        creditsFlag: false,
        sharing: false,
        savingCrop: false,
        imageTypesFlag: false,
        downloading: false,
        filterFlag: false,
        savingShowcaseImages: false,
        selectionFromMainPhoto: false,
        photographers: false,
    },
    types: typesInitialState,
    filter: [],
    imageTags: [],
    photographers: [],
};

const developmentPhotoSlice = createSlice({
    name: 'developmentPhotoDetails',
    initialState: initialState,
    reducers: {
        updateAllImages: (state, action: PayloadAction<ImageUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = action.payload.data;
            if (state.allImages[property].length > 0) {
                state.currentOrderNo[property] =
                    state.allImages[property][
                        state.allImages[property].length - 1
                    ].orderNo;
            }
        },
        updateAllImagesFlag: (state, action: PayloadAction<string>) => {
            state.allImagesFlags[
                action.payload as keyof typeof initialState.allImagesFlags
            ] = true;
        },
        deleteImagesData: (state, action: PayloadAction<DeleteData>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = state.allImages[property].filter(
                (image: ImageData) => {
                    return action.payload.ids.find((id: string) => id === image.guid)
                        ? false
                        : true;
                },
            );
            state.allImages[property] = state.allImages[property].map(
                (image: ImageData, index) => {
                    return { ...image, orderNo: index + 1 };
                },
            );
            state.currentOrderNo[property] = state.allImages[property].length;
        },
        updateOrderNo: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.currentOrderNo;
            state.currentOrderNo[property] = action.payload.data;
        },
        // updatePhotoCredits: (state, action: PayloadAction<PhotoCredit>) => {
        //     state.photoCredit = action.payload;
        // },
        updateFlag: (state, action: PayloadAction<UpdateFlag>) => {
            state.flags[action.payload.property as keyof typeof initialState.flags] =
                action.payload.value;
        },
        addImages: (state, action: PayloadAction<AddImage>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = [
                ...state.allImages[property],
                action.payload.data,
            ];
        },
        updateTotalImageCount: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.imagesCount;
            state.imagesCount[property] = action.payload.data;
        },
        updateCaption: (state, action: PayloadAction<UpdateCaptionDetails>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = state.allImages[property].map((item) =>
                item.guid === action.payload.id
                    ? action.payload.isFloorPlanCaption
                        ? { ...item, floorplancaption: action.payload.caption }
                        : { ...item, caption: action.payload.caption }
                    : item,
            );
        },
        updateImageDataByOrderNo: (state, action: PayloadAction<AddImage>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = state.allImages[property].map((item) =>
                item.orderNo === action.payload.data.orderNo
                    ? { ...item, ...action.payload.data }
                    : item,
            );
        },
        loadImageTypes: (state, action: PayloadAction<ImageTypes[]>) => {
            action.payload.forEach((item) => {
                const property = getCurrentImageType(
                    item.alias,
                ) as keyof typeof state.types;
                state.types[property] = item;
            });
        },
        updateFilterTags: (state, action: PayloadAction<Filter[]>) => {
            state.filter = [...action.payload];
        },
        loadImageTags: (state, action: PayloadAction<Filter[]>) => {
            state.imageTags = [...action.payload];
        },
        updateImageTags: (state, action: PayloadAction<TagSave>) => {
            state.imageTags = state.imageTags.map((item) => {
                return item.isSelectable
                    ? action.payload.ids.find((id) => id === parseInt(item.id))
                        ? { ...item, isSelected: true }
                        : { ...item, isSelected: false }
                    : item;
            });
        },
        updatePhotosCountInType: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.types[property] = {
                ...state.types[property],
                photosLoaded: state.types[property].photosLoaded + action.payload.data,
            };
        },
        updateFilterTag: (state, action: PayloadAction<UpdateFilter>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = state.allImages[property].map((item) =>
                item.guid === action.payload.image.guid
                    ? action.payload.tagData
                        ? {
                              ...item,
                              tagName: action.payload.tagData.name,
                              tagId: parseInt(action.payload.tagData.id),
                          }
                        : {
                              ...item,
                              tagName: null,
                              tagId: null,
                          }
                    : item,
            );
        },
        resetPhotoState: (state) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const fetchImages =
    (entityType: string, entityId: string, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_GET));
            const response = await getImages(
                entityType,
                entityId,
                getCurrentImageType(imageType),
            );
            if (response) {
                const imagesUpdateData: ImageUpdate = {
                    data: response,
                    imageType: imageType,
                };
                dispatch(updateAllImages(imagesUpdateData));
                dispatch(updateAllImagesFlag(imageType));
            }
        } catch (e) {
            Logger.error('Failed to fetch Images');
            dispatch(setError(`Failed to fetch Images`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_GET));
        }
    };

export const fetchTotalImagesCount =
    (entityId: string, entityType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getTotalImageCount(entityId, entityType);
            if (response) {
                response.forEach((item: TotalImageCount) => {
                    const totalCount: OrderNoUpdate = {
                        imageType: getCurrentImageType(item.imageType.alias),
                        data: item.count,
                    };
                    dispatch(updateTotalImageCount(totalCount));
                });
                dispatch(updateFlag({ property: 'imageCountFlag', value: true }));
            }
        } catch (e) {
            Logger.error('Failed to Fetch Total Images Count');
            dispatch(setError(`Failed to Fetch Total Images Count`));
        }
    };

export const fetchImageTypes =
    (entityId: string, entityType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getImageTypes(entityId, entityType);
            if (response) {
                dispatch(loadImageTypes(response));
                dispatch(updateFlag({ property: 'imageTypesFlag', value: true }));
            }
        } catch (e) {
            Logger.error('Failed to fetch Image Types');
            dispatch(setError(`Failed to fetch Image Types`));
        }
    };

export const saveImageDetails =
    (request: ImageDetail, oldImage?: ImageData): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            const currentListingImages =
                getState().development.developmentDetails.data.images; //check update
            dispatch(
                updateFlag({
                    property: oldImage ? 'savingCrop' : 'isUploading',
                    value: true,
                }),
            );
            const data = jsonToFormData({
                ...request,
                imageType: getCurrentImageType(request.imageType),
            });
            const response = await saveImages(data);
            if (response) {
                const newImage: ImageData = {
                    caption: oldImage ? oldImage.caption : null,
                    entityId: request.entityId,
                    entityType: request.entityType,
                    floorplancaption: oldImage ? oldImage.floorplancaption : null,
                    guid: request.id,
                    isPublished: oldImage ? oldImage.isPublished : true,
                    isVanity: oldImage ? oldImage.isVanity : false,
                    lastModified: '',
                    orderNo: request.orderNo,
                    size: {
                        width: request.width,
                        height: request.height,
                        sizeKb: request.size,
                    },
                    type: request.mimeType,
                    urls: response as ImageUrls,
                    islandscape: request.width > request.height ? true : false,
                    tagId: oldImage ? oldImage.tagId : null,
                    tagName: oldImage ? oldImage.tagName : null,
                };
                if (oldImage) {
                    const deleteData: DeleteData = {
                        ids: [oldImage.guid],
                        shouldValidate: false,
                        imageType: getCurrentImageType(request.imageType),
                    };

                    await deleteImages(deleteData);
                    dispatch(
                        updateImageDataByOrderNo({
                            data: newImage,
                            imageType: request.imageType,
                        }),
                    );
                    dispatch(setSuccess(`Image Cropped and saved successfully`));
                } else {
                    if (response.entityIsValid !== null) {
                        const mainStateUpdate: DevelopmentCustomKey = {
                            isValid: response.entityIsValid || false,
                        };
                        dispatch(updateMainDevelopmentData(mainStateUpdate));
                    }
                    if (request.imageType === imageTypes.Buildings) {
                        const updatedImageList = [
                            ...currentListingImages,
                            {
                                imageUrl: `${process.env.REACT_APP_PHOTOURL}/buildings/${newImage.guid}_1200x900.jpg`,
                                isLandscape: newImage.islandscape,
                                orderNo: newImage.orderNo,
                                isSmall: false,
                            },
                        ];
                        const buildingPhotosUpdate: DevelopmentCustomKey = {
                            images: loDash.sortBy(updatedImageList, ['orderNo']),
                        };
                        dispatch(updateMainDevelopmentData(buildingPhotosUpdate));
                    }
                    if (request.imageType === imageTypes.buildingFloorplans) {
                        const currentFPImages =
                            getState().development.developmentDetails.data
                                .floorPlanImages; //check update
                        const updatedImageList = [
                            ...currentFPImages,
                            {
                                imageUrl: `${process.env.REACT_APP_PHOTOURL}/building-floorplans/${newImage.guid}_1200x900.jpg`,
                                isLandscape: newImage.islandscape,
                                orderNo: newImage.orderNo,
                                isSmall: false,
                            },
                        ];
                        const buildingFPPhotosUpdate: DevelopmentCustomKey = {
                            floorPlanImages: loDash.sortBy(updatedImageList, ['orderNo']),
                        };
                        dispatch(updateMainDevelopmentData(buildingFPPhotosUpdate));
                    }
                    dispatch(addImages({ data: newImage, imageType: request.imageType }));
                    dispatch(
                        updatePhotosCountInType({
                            data: 1,
                            imageType: request.imageType,
                        }),
                    );
                    dispatch(setSuccess('Photos saved successfully'));
                }
            }
        } catch (e) {
            Logger.error(`Error saving photos for: ${request.id}`);
            dispatch(
                setError(oldImage ? 'Error saving cropped image' : 'Error saving Photos'),
            );
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            dispatch(
                updateFlag({
                    property: oldImage ? 'savingCrop' : 'isUploading',
                    value: false,
                }),
            );
        }
    };

export const deleteImageDetails =
    (deleteData: DeleteData): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
            const currentListingImages =
                getState().development.developmentDetails.data.images;
            const response = await deleteImages({
                ...deleteData,
                imageType: getCurrentImageType(deleteData.imageType),
            });
            if (response.entityIsValid !== null) {
                const mainStateUpdate: DevelopmentCustomKey = {
                    isValid: response.entityIsValid || false,
                };
                dispatch(updateMainDevelopmentData(mainStateUpdate));
            }
            if (deleteData.imageType === imageTypes.Buildings) {
                const updatedImageList = currentListingImages
                    .filter(
                        (im) => !deleteData.ids.find((id) => im.imageUrl.includes(id)),
                    )
                    .map((img, i) => {
                        return { ...img, orderNo: i + 1 };
                    });

                const buildingPhotosUpdate: DevelopmentCustomKey = {
                    images: updatedImageList,
                };
                dispatch(updateMainDevelopmentData(buildingPhotosUpdate));
            }
            if (deleteData.imageType === imageTypes.buildingFloorplans) {
                const currentPLImages =
                    getState().development.developmentDetails.data.floorPlanImages;
                const updatedImageList = currentPLImages
                    .filter(
                        (im) => !deleteData.ids.find((id) => im.imageUrl.includes(id)),
                    )
                    .map((img, i) => {
                        return { ...img, orderNo: i + 1 };
                    });

                const listingFPPhotosUpdate: DevelopmentCustomKey = {
                    floorPlanImages: updatedImageList,
                };
                dispatch(updateMainDevelopmentData(listingFPPhotosUpdate));
            }
            dispatch(deleteImagesData(deleteData));
            dispatch(
                updatePhotosCountInType({
                    data: -deleteData.ids.length,
                    imageType: deleteData.imageType,
                }),
            );
            dispatch(setSuccess(`Images deleted successfully`));
        } catch (e) {
            dispatch(setError(`Failed To Delete Images`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
        }
    };

function handleDownload(response: string) {
    const downloadUrl = `${process.env.REACT_APP_PHOTOURL}/${response}`;
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${response}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

const ifPackageExists = async (
    response: string,
    isDownload: boolean,
): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
        let count = 0;
        const existUrl = `${process.env.REACT_APP_PHOTOURL}/api/asset/exists?key=${response}`;
        const handleError = function (intervalId: NodeJS.Timeout, res: Response) {
            if (++count === 36) {
                window.clearInterval(intervalId);
                Promise.reject(`Error zipping images ${res}`);
                reject(res.status);
            }
        };
        const intervalId = setInterval(function () {
            fetch(existUrl + `&ts=${Date.now()}`, { headers: packageAuthHeader }).then(
                function (res) {
                    if (res.status !== 200) {
                        handleError(intervalId, res);
                    } else {
                        window.clearInterval(intervalId);
                        if (isDownload) {
                            handleDownload(response);
                        }
                        Promise.resolve();
                        resolve(res.status);
                    }
                },
                function (res) {
                    handleError(intervalId, res);
                },
            );
        }, 5000);
    });
};

export const saveImageSharePackage =
    (url: string, request: PackageProps, shareData: ShareDetails): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request && shareData) {
                dispatch(updateFlag({ property: 'sharing', value: true }));
                const { id } = getState().development.developmentDetails.data;
                const requestData = { ...request, fileName: id };
                const response = await saveImagesPackage(url, requestData);
                if (response.length > 0) {
                    const res = await ifPackageExists(response, false);
                    if (res === 200) {
                        const shareUrl = `${process.env.REACT_APP_PHOTOURL}/${response}`;
                        const shareResponse = await shareImages({
                            ...shareData,
                            url: shareUrl,
                        });
                        if (shareResponse.status === 200) {
                            dispatch(setSuccess('Images shared successfully'));
                        }
                    }
                } else {
                    throw Error('Error zipping images');
                }
                dispatch(updateFlag({ property: 'sharing', value: false }));
            }
        } catch (e) {
            Logger.error(`Error zipping images`);
            dispatch(setError('Error zipping images'));
            dispatch(updateFlag({ property: 'sharing', value: false }));
        }
    };

export const downloadImagePackage =
    (url: string, request: PackageProps): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateFlag({ property: 'downloading', value: true }));
            const { id, address1, address2 } =
                getState().development.developmentDetails.data;
            const requestData = {
                ...request,
                fileName: getStreetAddress(id, address1, address2),
            };
            const response = await saveImagesPackage(url, requestData);
            if (response.length > 0) {
                const res = await ifPackageExists(response, true);
                if (res === 200) {
                    dispatch(setSuccess('Starting to download'));
                }
            }
            dispatch(updateFlag({ property: 'downloading', value: false }));
        } catch (e) {
            Logger.error(`Failed to download images: ${e}`);
            dispatch(setError(`Failed to download images`));
            dispatch(updateFlag({ property: 'downloading', value: false }));
        }
    };

export const imageDownload =
    (url: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const a = document.createElement('a');
            a.href = await toDataURL(url);
            const { id, address1, address2 } =
                getState().development.developmentDetails.data;
            a.download = `${getStreetAddress(id, address1, address2)}.${
                url.split('.')[url.split('.').length - 1]
            }`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            dispatch(setSuccess('Image downloading'));
        } catch (e) {
            Logger.error('Failed to download Image');
            dispatch(setError(`Failed to download Image`));
        }
    };

export const fetchFilter = (): AppThunk => async (dispatch) => {
    try {
        const response = await getFilter();
        if (response) {
            dispatch(updateFilterTags(response));
            dispatch(updateFlag({ property: 'filterFlag', value: true }));
        }
    } catch (e) {
        Logger.error('Failed to fetch Filter details');
        dispatch(setError(`Failed to fetch Filter details`));
    }
};

export const fetchImageTags =
    (entityId: string, imageId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getTags(entityId, imageId);
            if (response) {
                dispatch(loadImageTags(response));
            }
        } catch (e) {
            Logger.error('Failed to fetch Image tags');
            dispatch(setError(`Failed to fetch Image tags`));
        }
    };

export const saveImageCaption =
    (id: string, data: CaptionDetails, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveCaptionDetails(id, data);
            if (response.status === 204) {
                const toUpdate: UpdateCaptionDetails = {
                    id: id,
                    imageType: imageType,
                    caption: data.caption,
                    isFloorPlanCaption: data.isFloorPlanCaption,
                };
                dispatch(updateCaption(toUpdate));
                !data.isFloorPlanCaption
                    ? dispatch(setSuccess('Caption Updated'))
                    : dispatch(setSuccess('Floor Plan Title Updated'));
            }
        } catch (e) {
            Logger.error(`Failed to save image caption: ${e}`);
            dispatch(setError(`Failed to save image caption`));
        }
    };

export const saveImageTags =
    (
        image: ImageData,
        chipData: Filter | undefined,
        currentImageType: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            const requestData: TagSave = {
                ids: chipData ? [parseInt(chipData.id)] : [],
            };
            const response = await saveTags(image.entityId, image.guid, requestData);
            if (response.status !== 200) {
                throw Error('Error saving image tags');
            } else {
                dispatch(updateImageTags(requestData));
                dispatch(
                    updateFilterTag({
                        image: image,
                        tagData: chipData,
                        imageType: currentImageType,
                    }),
                );
                dispatch(setSuccess('Image tags saved successfully'));
            }
        } catch (e) {
            Logger.error(`Error saving image tags: ${JSON.stringify(chipData)}`);
            dispatch(setError('Error saving image tags'));
        }
    };

export const savePhotoReorder =
    (id: string, data: ImageData[]): AppThunk =>
    async (dispatch) => {
        try {
            const finalData = data.map((data) => {
                return {
                    id: data.guid,
                    orderNo: data.orderNo,
                };
            });
            await saveReorderImage(id, finalData);
            dispatch(setSuccess('Image orders have been updated'));
        } catch (e) {
            Logger.error(`Failed to save image reorder: ${e}`);
            dispatch(setError(`Failed to save image reorder`));
        }
    };

export const updateThumbnail =
    (listingId: string, imageId: string): AppThunk =>
    async (dispatch) => {
        try {
            await updateThumbnailImage(listingId, imageId);
        } catch (e) {
            Logger.error(`Failed to save image reorder: ${e}`);
            dispatch(setError(`Failed to save image reorder`));
        }
    };

export const {
    updateAllImages,
    deleteImagesData,
    updateAllImagesFlag,
    updateOrderNo,
    addImages,
    updateTotalImageCount,
    updateFlag,
    updateCaption,
    updateImageDataByOrderNo,
    loadImageTypes,
    updateFilterTags,
    loadImageTags,
    updateImageTags,
    updatePhotosCountInType,
    updateFilterTag,
    resetPhotoState,
} = developmentPhotoSlice.actions;
export const developmentPhotoDetails = (state: RootState): DevelopmentPhotosState =>
    state.development.developmentPhotos;

export default developmentPhotoSlice.reducer;
