import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import { setError, setSuccess } from '../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../utils/logging/logger';

import { getNotes, saveNote } from './notesApi';
import { NotesState, NoteDetail } from './notesModel';

const initialState: NotesState = {
    loading: false,
    notes: [],
};

export const notesSlice = createSlice({
    name: 'notes',
    initialState: initialState,
    reducers: {
        updateNotes: (state, action: PayloadAction<NoteDetail[]>) => {
            state.notes = action.payload;
        },
        addNewNote: (state, action: PayloadAction<NoteDetail>) => {
            state.notes = [action.payload, ...state.notes];
        },
        updateLoadingState: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const fetchNotes =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoadingState(true));
            const response = await getNotes(id);
            dispatch(updateNotes(response));
        } catch {
            Logger.error(`Error getting notes for listing: ${id}`);
            dispatch(setError('Error getting notes for listing'));
        } finally {
            dispatch(updateLoadingState(false));
        }
    };

export const addNote =
    (id: string, request: Partial<NoteDetail>): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveNote(id, request);
            dispatch(addNewNote(response));
            setSuccess('Note saved successfully');
        } catch {
            Logger.error(`Error saving note for listing: ${id}`);
            dispatch(setError('Error saving note for listing'));
        }
    };

export const { updateNotes, addNewNote, updateLoadingState } = notesSlice.actions;
export const listingNotes = (state: RootState): NotesState => state.listing.notes;

export default notesSlice.reducer;
