import { Detail } from './header/details/detailsModel';
import { StatusData } from './header/status/statusModels';
import {
    MarketingReminderStaticId,
    dealTypeList,
    editFormComponent,
    dealTypes,
    listingStatuses,
} from './listingConstants';
import {
    DatesModel,
    Image,
    ListingAgent,
    ListingData,
    OnSirComModel,
    DealTypeChange,
} from './listingModels';
import { Coordinates } from '../../../shared/component/map/mapModels';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { OpenHouse } from '../../../shared/models/openHouseShowings/openHouseShowingsModels';
import { ListingDetailsData } from './drawer/screens/listingDetails/listingDetailsModels';
import { PricesModel } from './drawer/screens/prices/priceModel';
import { findIdFromLookups } from '../../../utils/urlUtils';
import { ListingAddress } from '../../../shared/models/listing/addressModel';
import {
    disableAdCopyEdit,
    disableListingAgentsEdit,
    disableListingDatesEdit,
    disableListingPriceEdit,
    disableShowcaseEdit,
} from '../utils';
import { qcMessages } from './header/status/statusConstants';
import {
    initializeOwnerInfoData,
    validationForOwnerFields,
} from '../../../shared/functions/ownerInfo/ownerInfoFunction';

export const validateReminder = (data: ListingData, reminderId: number): boolean => {
    switch (reminderId) {
        case MarketingReminderStaticId.photosTooSmall:
            return data.images.find((image: Image) => image.isSmall) ? true : false;
        case MarketingReminderStaticId.propertyNearExpiration:
            const { expirationDate } = data;
            if (!expirationDate) {
                return false;
            } else {
                const currentDate = new Date();
                const priorMonth = new Date(expirationDate);
                priorMonth.setMonth(priorMonth.getMonth() - 1);
                return currentDate >= priorMonth &&
                    currentDate <= new Date(expirationDate)
                    ? true
                    : false;
            }
        case MarketingReminderStaticId.addressNotValid:
            return !data.address1 && data.zip?.length !== 5 ? true : false;
        case MarketingReminderStaticId.mlsImportSuppressed: //
            return data?.suppressMlsImport || false;
        case MarketingReminderStaticId.notASelectSaleRental: //
            return data.listingAgents && data.listingAgents.length > 0
                ? data.listingAgents.find(
                      (item: ListingAgent) => item.selectSale === true,
                  )
                    ? false
                    : true
                : true;
        case MarketingReminderStaticId.notDisplayingAddress: //
            return !data.showAddress;
        case MarketingReminderStaticId.propertyNotPublished: //
            return !data.publish;
        case MarketingReminderStaticId.showcaseSitePrivate:
            return !data.domainInfo
                ? false
                : data.domainInfo && data.domainInfo.privateSite
                ? true
                : false;
        case MarketingReminderStaticId.showcaseSiteNotPublished:
            return !data.domainInfo
                ? false
                : data.domainInfo && !data.domainInfo.publishedSite
                ? true
                : false;
        case MarketingReminderStaticId.lessThanTenImages: //
            return data.images.length < 10;
        case MarketingReminderStaticId.noVideos:
            return !data.videoDetail
                ? true
                : data.videoDetail && !data.videoDetail.url
                ? true
                : false;
        case MarketingReminderStaticId.noVirtualTour:
            return !data.virtualTourUrl ? true : false;
        case MarketingReminderStaticId.missingMlsNumber:
            return !data.mlsNumber ? true : false;
        case MarketingReminderStaticId.noShowcaseSite:
            return !data.domainInfo
                ? true
                : data.domainInfo && !data.domainInfo.domainUrl
                ? true
                : false;
        case MarketingReminderStaticId.notDisplayingPrice: //
            return data.priceUponRequest ? data.priceUponRequest : false;
        case MarketingReminderStaticId.moreThanHundredImages:
            return data.images.length + data.floorplanImages.length > 100 ? true : false;
        case MarketingReminderStaticId.adCopy4000Characters:
            return data.internetAdCopy?.length > 4000;
        case MarketingReminderStaticId.missingBuyerCommission:
            // if (data.listingCommission === null) {
            //     return true;
            // } else
            if (data.buyerCommissionType !== 3 && data.buyerCommission === null) {
                return true;
            } else {
                return false;
            }
        default:
            return false;
    }
};

export const loadAddressData = (listingData: ListingData): ListingAddress => {
    const data = {
        address1: listingData.address1,
        address2: listingData.address2,
        city: listingData.city,
        state: listingData.state,
        zip: listingData.zip,
    };

    return data;
};

export const loadStatusData = (
    listingData: ListingData,
    isValid: boolean,
): StatusData => {
    const data = {
        status: listingData.status,
        isValid: isValid,
        lastUpdated: listingData.lastUpdated,
        listingNumber: listingData.listingNumber,
        isPublished: listingData.publish,
        listingId: listingData.id,
    };

    return data;
};

export const loadDetailsData = (listingData: ListingData): Detail => {
    const data = {
        listingType: listingData.listingType,
        prices: listingData.prices,
        bedrooms: listingData.bedrooms,
        fullBaths: listingData.fullBaths,
        halfBaths: listingData.halfBaths,
        interiorSize: listingData.interiorSize,
        listingDate: listingData.listingDate,
        expirationDate: listingData.expirationDate,
        mlsNumber: listingData.mlsNumber,
        rfgId: listingData.rfgId,
        domainInfo: listingData.domainInfo,
        listingNumber: listingData.listingNumber,
        lotSize: listingData.lotSize,
        lotSizeTypeId: listingData.lotSizeTypeId,
        tridentDealNumber: listingData.tridentDealNumber,
        closedDate: listingData.closedDate,
        listingStatusId: listingData.listingStatusId,
        dashId: listingData.dashId,
    };

    return data;
};

export const loadCoordinatesData = (listingData: ListingData): Coordinates => {
    const data = {
        latitude: listingData.latitude,
        longitude: listingData.longitude,
    };

    return data;
};

export const oHType = (value: OpenHouse): string => {
    let toReturn = '';
    if (value.byAppointmentOnly != null && value.byAppointmentOnly) {
        toReturn = toReturn.concat('By Appointment Only, ');
    }
    if (value.doNotShowTime != null && value.doNotShowTime) {
        toReturn = toReturn.concat("Don't display time, ");
    }
    if (value.brokerOnly != null && value.brokerOnly) {
        toReturn = toReturn.concat('Broker Only, ');
    }
    if (value.isVirtual != null && value.isVirtual) {
        toReturn = toReturn.concat(`Virtual - ${value.virtualUrl}`);
    }
    return toReturn.length > 0 ? toReturn.replace(/,\s*$/, '') : '-';
};

export const loadListingDetailsData = (data: ListingData): ListingDetailsData => {
    const listingData: ListingDetailsData = {
        listingType: data.listingType,
        listingStatusId: data.listingStatusId,
        currentPrice: data.currentPrice,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        zip: data.zip,
        latitude: data.latitude,
        longitude: data.longitude,
        mlsNumber: data.mlsNumber,
        interiorSize: data.interiorSize,
        rooms: data.rooms,
        bedrooms: data.bedrooms,
        fullBaths: data.fullBaths,
        halfBaths: data.halfBaths,
        listingDate: data.listingDate,
        expirationDate: data.expirationDate,
        regionId: data.regionId,
        prices: data.prices,
        state: data.state,
        displayOfferPriceForSelectSold: data.displayOfferPriceForSelectSold,
        propertyTypeId: data.propertyTypeId,
        officeId: data.officeId,
        county: data.county,
        priceUponRequest: data.priceUponRequest,
        lastAskingPrice: data.lastAskingPrice,
        soldPrice: data.soldPrice,
        closedDate: data.closedDate,
        contractDate: data.contractDate,
        projectedListDate: data.projectedListDate,
        title: data.title,
        dealTypeId: Number(findIdFromLookups(data.dealType, dealTypeList)),
        tridentDealNumber: data.tridentDealNumber,
        taxMapNumber: data.taxMapNumber,
        exteriorSize: data.exteriorSize,
        lotSize: data.lotSize,
        lotSizeTypeId: data.lotSizeTypeId,
        neighborhood: data.neighborhood,
        lotSizeUnit: data.lotSizeUnit,
        showPendingAsActive: data.showPendingAsActive,
        rfgId: data.rfgId,
        publishedDate: data.publishedDate,
        lastPublishedDate: data.lastPublishedDate,
        isListingTypeChanged: data.isListingTypeChanged,
        ownerInfo: data.ownerInfo || [initializeOwnerInfoData(1)],
    };
    return listingData;
};

export function filterLookups(lookupInfo: LookupInfo[], id?: string): string {
    const result = lookupInfo.find((prop) => {
        return prop.id === id;
    })?.name;
    return result ? result : '';
}
export const onSirCom = (listingData: ListingData): OnSirComModel => {
    let redirectUrl = '';
    if (listingData.status && listingData.status.toLowerCase() === 'sold') {
        redirectUrl = `https://www.sothebysrealty.com/eng/sold/detail/180-l-85298-${listingData.listingNumber}`;
    } else {
        const identifier = listingData.rfgId || listingData.mlsNumber;
        redirectUrl = `https://www.sothebysrealty.com/id/${identifier}`;
    }
    const data = {
        status:
            listingData.publish &&
            listingData.isValid &&
            listingData.propertyType?.toLowerCase() !== 'commercial'
                ? 'Yes'
                : 'No',
        link: redirectUrl,
    };

    return data;
};

export const fetchListingPrices = (data: ListingData): PricesModel => {
    return {
        initialPrice: data.initialPrice,
        tridentPrice: data.tridentPrice,
        mlsPrice: data.mlsPrice,
        currentPrice: data.currentPrice,
        soldPrice: data.soldPrice,
        lastAskingPrice: data.lastAskingPrice,
        buyerCommission: data.buyerCommission,
        listingCommission: data.listingCommission,
        commonCharges: data.commonCharges,
        maintenance: data.maintenance,
        realEstateTax: data.realEstateTax,
        taxesAreMonthly: data.taxesAreMonthly,
        ignoreTridentPrice: data.ignoreTridentPrice,
        priceUponRequest: data.priceUponRequest,
        displayOfferPriceForSelectSold: data.displayOfferPriceForSelectSold,
        doNotDisplayTaxesOnWebsite: data.doNotDisplayTaxesOnWebsite,
        displayBuyerCommissionOnWebsite: data.displayBuyerCommissionOnWebsite,
        maxFinancing: data.maxFinancing,
        flipTaxAmount: data.flipTaxAmount,
        flipTaxSide: data.flipTaxSide,
        listingBudget: data.listingBudget,
        buyerCommissionType:
            data.buyerCommissionType === null ? 1 : data.buyerCommissionType, //defaulted to % for null since the field is nullable
        listingCommissionType:
            data.listingCommissionType === null ? 1 : data.listingCommissionType, //defaulted to % for null since the field is nullable
    };
};

export const generateListingEditScreens = (dealType: string): string[] => {
    return [
        editFormComponent.listingDetails,
        editFormComponent.listingFeatures,
        ...(!disableListingPriceEdit() ? [editFormComponent.prices] : []),
        ...(!disableListingDatesEdit() ? [editFormComponent.dates] : []),
        ...((dealType === dealTypes.listSide || dealType === dealTypes.bothSides) &&
        !disableListingAgentsEdit()
            ? [editFormComponent.listAgent]
            : []),
        ...((dealType === dealTypes.saleSide || dealType === dealTypes.bothSides) &&
        !disableListingAgentsEdit()
            ? [editFormComponent.sellAgent]
            : []),
        ...(!disableAdCopyEdit() ? [editFormComponent.adCopy] : []),
        editFormComponent.photos,
        editFormComponent.videos,
        editFormComponent.virtualTour,
        ...(!disableShowcaseEdit() ? [editFormComponent.showCaseWebsite] : []),
        editFormComponent.openHouse,
        editFormComponent.showings,
    ];
};

export const displayAddress = (address: ListingAddress): string => {
    return address && Object.values(address).filter(Boolean).join(', ');
};

export const validateStatusUpdate = (
    currentStatus: number | null,
    updatedFullList: ListingDetailsData,
): boolean => {
    if (currentStatus !== updatedFullList.listingStatusId) {
        return true;
    } else {
        return false;
    }
};

export const formListingDataInitiaObject = (data: ListingData): DatesModel => {
    return {
        listingDate: data.listingDate ? data.listingDate : '',
        expirationDate: data.expirationDate ? data.expirationDate : '',
        closedDate: data.closedDate ? data.closedDate : '',
        newListingUntil: data.newListingUntil ? data.newListingUntil : '',
        contractDate: data.contractDate ? data.contractDate : '',
        canceledDate: data.canceledDate ? data.canceledDate : '',
        createdDate: data.createdDate ? data.createdDate : '',
        projectedListDate: data.projectedListDate ? data.projectedListDate : '',
        publishedDate: data.publishedDate ? data.publishedDate : '',
        lastPublishedDate: data.lastPublishedDate ? data.lastPublishedDate : '',
        isFormValid: false,
    };
};

/**
 *
 * @param rejectReasons rejected reasons coming from api for qc rejected listings
 * @returns array of translated messages corresponding to rejected messages
 */
export const getQcMessages = (rejectReasons?: string[] | null): string[] | null => {
    if (rejectReasons && rejectReasons.length) {
        const translations: string[] = [];
        rejectReasons.forEach((reason) => {
            const message = qcMessages.find(
                (item) =>
                    item.justificationName.toLocaleLowerCase().trim() ===
                    reason.toLowerCase().trim(),
            );
            if (message) {
                translations.push(message.translation);
            }
        });
        return translations;
    }
    return null;
};

/**
 * updates agents in list side or sell side based on deal type change
 * @param data old dealTypeId and newDealTypeId
 * @param currentAgents initial list of agents from respective sides
 * @returns updated list of agents based on new dealType
 */
export const validateDealTypeChange = (
    data: DealTypeChange,
    currentAgents: ListingAgent[],
): ListingAgent[] => {
    // Our List/OB Sale to OB List/Our Sale
    if (data.oldDealType === 1 && data.newDealType === 3) {
        // move all existing agents from list side (sideTypeId:1) to sell side (sideTypeId:2)
        const updatedAgents = currentAgents.map((agent) => {
            return { ...agent, sideTypeId: 2 };
        });
        return updatedAgents;
    }
    //  OB List/Our Sale to Our List/OB Sale
    if (data.oldDealType === 3 && data.newDealType === 1) {
        // move all existing agents from sell side (sideTypeId:2) to list side (sideTypeId:1)
        const updatedAgents = currentAgents.map((agent) => {
            return { ...agent, sideTypeId: 1 };
        });
        return updatedAgents;
    }
    // both sides (Our List/Our Sale) to a single side ( OB List/Our Sale or Our List/OB Sale )
    if (data.oldDealType === 2 && (data.newDealType === 1 || data.newDealType === 3)) {
        // move agents to appropriate side, checking common agents not being repeated
        const sellAgents: ListingAgent[] = [];
        const listAgents: ListingAgent[] = [];
        currentAgents.forEach((agent) => {
            if (agent.sideTypeId === 1) {
                listAgents.push(agent);
            } else if (agent.sideTypeId === 2) {
                sellAgents.push(agent);
            }
        });

        // move to list side (sideTypeId:1)
        if (data.newDealType === 1) {
            const repeatAgentsFromSellSide: ListingAgent[] = [];
            const agentsToBeMoved: ListingAgent[] = [];
            sellAgents.forEach((sa) => {
                if (listAgents.some((la) => sa.agentId === la.agentId)) {
                    repeatAgentsFromSellSide.push(sa);
                } else {
                    agentsToBeMoved.push(sa);
                }
            });

            const updatedAgentsToBeMoved = agentsToBeMoved.map((agent) => {
                return { ...agent, sideTypeId: 1 };
            });

            return updateSplit([...listAgents, ...updatedAgentsToBeMoved]);
        }
        // move to sell side (sideTypeId:2)
        if (data.newDealType === 3) {
            const repeatAgentsFromListSide: ListingAgent[] = [];
            const agentsToBeMoved: ListingAgent[] = [];
            listAgents.forEach((la) => {
                if (sellAgents.some((sa) => la.agentId === sa.agentId)) {
                    repeatAgentsFromListSide.push(la);
                } else {
                    agentsToBeMoved.push(la);
                }
            });

            const updatedAgentsToBeMoved = agentsToBeMoved.map((agent) => {
                return { ...agent, sideTypeId: 2 };
            });

            return updateSplit([...sellAgents, ...updatedAgentsToBeMoved]);
        }
    }
    // single side ( OB List/Our Sale or Our List/OB Sale ) to both sides (Our List/Our Sale)
    if ((data.oldDealType === 1 || data.oldDealType === 3) && data.newDealType === 2) {
        if (data.oldDealType === 1) {
            // copy agents to sell side (sideTypeId:2)
            const sellAgents = currentAgents.map((a) => {
                return { ...a, sideTypeId: 2 };
            });
            return [...currentAgents, ...sellAgents];
        }
        if (data.oldDealType === 3) {
            // copy agents to list side (sideTypeId:1)
            const listAgents = currentAgents.map((a) => {
                return { ...a, sideTypeId: 1 };
            });
            return [...currentAgents, ...listAgents];
        }
    }
    return currentAgents;
};

const updateSplit = (agents: ListingAgent[]) => {
    const equalSplit = 100 / agents.length;
    return agents.map((a) => {
        return { ...a, split: equalSplit };
    });
};

export const displayErrorForListingEditScreen = (
    formData: ListingDetailsData | PricesModel,
    editScreen: string,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let newState;
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;
    switch (editScreen) {
        case editFormComponent.listingDetails:
            newState = formData as ListingDetailsData;

            if (!newState.title)
                result = { isValid: false, errorMessage: result.errorMessage + 'Title,' };
            if (!newState.address1)
                result = {
                    isValid: false,
                    errorMessage: result.errorMessage + 'Address1,',
                };
            if (!newState.city)
                result = { isValid: false, errorMessage: result.errorMessage + 'City,' };
            if (!newState.regionId)
                result = { isValid: false, errorMessage: result.errorMessage + 'State,' };
            if (!newState.zip)
                result = { isValid: false, errorMessage: result.errorMessage + 'Zip,' };
            if (!newState.dealTypeId)
                result = {
                    isValid: false,
                    errorMessage: result.errorMessage + 'Deal type,',
                };

            if (!result.errorMessage) {
                if (newState?.zip?.toString().length !== 5)
                    result = {
                        isValid: false,
                        errorMessage: 'Zip should be five digits',
                    };
                else if (!validationForOwnerFields(newState.ownerInfo || []))
                    // For sold / deleted listings, there is no need to validate the owner info fields
                    result = {
                        isValid:
                            newState.listingStatusId === listingStatuses.sold ||
                            newState.listingStatusId === listingStatuses.deleted
                                ? true
                                : false,
                        errorMessage: 'Owner information is required',
                    };
                else if (newState.title && /[\\|]/.test(newState.title)) {
                    result = {
                        isValid: false,
                        errorMessage:
                            'The special character "|" is not allowed in listing titles, removing or replacing it is required',
                    };
                } else
                    result = {
                        isValid: true,
                        errorMessage: '',
                    };
            }
            break;
        case editFormComponent.prices:
            newState = formData as PricesModel;
            if (!newState.initialPrice)
                result = {
                    isValid: false,
                    errorMessage: result.errorMessage + 'Initial Price,',
                };
            if (!newState.currentPrice)
                result = {
                    isValid: false,
                    errorMessage: result.errorMessage + 'Current Price,',
                };

            if (!result.errorMessage) {
                if (
                    newState.listingCommissionType === 1 &&
                    Number(newState.listingCommission) < 0
                )
                    result = {
                        isValid: false,
                        errorMessage:
                            'Listing commission should be less than or equal to 1%',
                    };
                else if (
                    newState.listingCommissionType === 1 &&
                    Number(newState.listingCommission) > 100
                )
                    result = {
                        isValid: false,
                        errorMessage:
                            'Listing commission should be less than or equal to 100%',
                    };
                else if (
                    newState.listingCommissionType === 2 &&
                    Number(newState.listingCommission) < 0
                )
                    result = {
                        isValid: false,
                        errorMessage: 'listing commission should be greater than 0',
                    };
                else if (
                    newState.buyerCommissionType === 1 &&
                    Number(newState.buyerCommission) < 0
                )
                    result = {
                        isValid: false,
                        errorMessage:
                            'Buyer commission should be less than or equal to 1%',
                    };
                else if (
                    newState.buyerCommissionType === 1 &&
                    Number(newState.buyerCommission) > 100
                )
                    result = {
                        isValid: false,
                        errorMessage:
                            'Buyer commission should be less than or equal to 100%',
                    };
                else if (
                    newState.buyerCommissionType === 2 &&
                    Number(newState.buyerCommission) < 0
                )
                    result = {
                        isValid: false,
                        errorMessage: 'Buyer commission should be greater than 0',
                    };
                else if (Number(newState.maxFinancing) > 100)
                    result = {
                        isValid: false,
                        errorMessage:
                            'Max financing should be less than or equal to 100%',
                    };
                else if (Number(newState.flipTaxAmount) > 100)
                    result = {
                        isValid: false,
                        errorMessage:
                            'Flip tax amount should be less than or equal to 100%',
                    };
                else {
                    result = {
                        isValid: true,
                        errorMessage: '',
                    };
                }
            }
            break;
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};

export const getListingListName = (listingStatusId: number) => {
    switch (listingStatusId) {
        case 1:
            return 'active';
        case 2:
            return 'expired';
        case 3:
            return 'pending';
        case 7:
            return 'comingSoon';
        case 8:
            return 'draft';
        case 9:
            return 'offMarket';
        case 11:
            return 'privateListing';
        default:
            return '';
    }
};
