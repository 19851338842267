import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
    cmrDetails,
    editSelectedListingImage,
    fetchListingImages,
} from '../../cmr/cmrSlice';
import { setFlagStatus } from './scheduler/schedulerSlice';
import './index.scss';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { ImageVideoModel, ListingImage } from '../cmrModels';
import Scheduler from './scheduler/Scheduler';
import { useWindowDimensions } from '../../../shared/component/Window';
import { googleAnalyticsAction } from '../../../shared/functions/googleAnalytics/ga';
import ComponentWithLoader from '../../../shared/component/loader/ComponentWithLoader';
import { schedulerDetails } from './scheduler/schedulerSlice';
import { NIL as NIL_UUID } from 'uuid';

const Header: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { listingdata, listingId, listingImages } = useAppSelector(cmrDetails);
    const {
        flags: { isSending },
    } = useAppSelector(schedulerDetails);
    const viewReport = () => {
        googleAnalyticsAction('View Report', 'Click', 'View Report');
        window.open(`/ListingMarketingReport/${listingId}?calledfrom=report`, '_blank');
    };
    const { width, height } = useWindowDimensions();

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            width: `90%`,
        };
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            item: {
                backgroundColor: '#d6d6d6',
                textAlign: 'center',
            },
            paper: {
                position: 'absolute',
                width: 800,
                backgroundColor: theme.palette.background.paper,
                border: '2px solid #000',
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 3),
            },
            resPaper: {
                position: 'absolute',
                width: 800,
                backgroundColor: theme.palette.background.paper,
                border: '2px solid #000',
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 2, 2),
            },
            imageList: {
                width: 800,
                height: 450,
            },
            resImageList: {
                width: 800,
                height: 320,
            },
            videoItem: {
                width: '100%',
                height: '100%',
            },
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.paper,
            },
            cont2: {
                border: '2px solid #f00',
            },
            cont: {
                border: '2px solid #0f0',
            },
            listingImage: {
                width: 'auto',
                height: '100%',
                cursor: 'pointer',
            },
            iframeOverlay: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                cursor: 'pointer',
            },
        }),
    );

    const [style, setStyle] = React.useState('cont');

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [modalScheduler, openModalScheduler] = useState(false);
    const [column, setColumn] = useState<number>(4);

    const handleOpen = () => {
        googleAnalyticsAction('Report Image', 'Click', 'Select Image Menu Open');
        if (listingImages.length === 0) {
            dispatch(fetchListingImages(listingId));
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateImage = (from: string, listingImage?: ListingImage) => {
        let data: ImageVideoModel = {
            listingNumber: listingId,
            isVideoSelected: false,
            selectedListingImage: '',
            isLandscape: false,
        };
        if (from === 'image' && listingImage) {
            googleAnalyticsAction('Report Image', 'Click', 'Select Image');
            data = {
                ...data,
                imageId: listingImage?.assetId,
                selectedListingImage: listingImage?.url || '',
                isLandscape: listingImage?.landscapeRatio,
            };
        } else {
            googleAnalyticsAction('Report Video', 'Click', 'Selected Video');
            data.isVideoSelected = true;
        }
        setStyle(classes.cont2);
        dispatch(editSelectedListingImage(data));
        setOpen(false);
    };

    useEffect(() => {
        if (width > 426 && width <= 768) {
            setColumn(3);
        } else if (width <= 426) {
            setColumn(2);
        } else {
            setColumn(4);
        }
    }, [width]);

    useEffect(() => {
        if (listingImages.length !== 0) setOpen(true);
    }, [listingImages]);

    const body = (
        <div
            style={modalStyle}
            className={width <= 426 ? classes.resPaper : classes.paper}
        >
            <div className={classes.root}>
                <ImageList
                    rowHeight={160}
                    className={height < 450 ? classes.resImageList : classes.imageList}
                    cols={column}
                >
                    {!listingdata.videoSelected &&
                    (listingdata.videoUrl || '').trim().length > 0 ? (
                        <ImageListItem
                            className={style}
                            classes={{
                                item: classes.item,
                            }}
                            cols={1}
                            onClick={() => updateImage('video')}
                        >
                            <iframe
                                src={listingdata.videoUrl}
                                title="Video"
                                className={classes.videoItem}
                            ></iframe>
                            <div className={classes.iframeOverlay}></div>
                        </ImageListItem>
                    ) : (
                        ''
                    )}
                    {listingImages.map((item) => (
                        <ImageListItem
                            className={style}
                            classes={{
                                item: classes.item,
                            }}
                            key={item.url}
                            cols={1}
                            onClick={() => updateImage('image', item)}
                        >
                            <img
                                src={item.url}
                                alt={item.caption}
                                className={classes.listingImage}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
        </div>
    );

    const openScheduler = () => {
        googleAnalyticsAction('Email', 'Click', 'Email Report Menu Open');
        openModalScheduler(true);
        dispatch(setFlagStatus({ property: 'emailSent', value: false }));
    };

    const closeScheduler = () => {
        googleAnalyticsAction('Email', 'Click', 'Email Report Menu Close');
        openModalScheduler(false);
    };

    const scheduler = (
        <div className="scheduler">
            <Scheduler closeScheduler={closeScheduler} />
        </div>
    );

    const isDefaultImage = (): boolean => {
        return listingdata.photoUrl.includes(NIL_UUID);
    };

    return (
        <div className="header-container">
            <ComponentWithLoader
                showLoader={isSending}
                showOverlay={true}
                styleClass="loaderClass"
            />
            <div className="image-container">
                {listingdata.videoSelected &&
                listingdata.videoUrl &&
                listingdata.videoUrl.trim().length > 0 ? (
                    <iframe
                        frameBorder="0"
                        allowFullScreen={true}
                        src={listingdata.videoUrl + '&muted&playsinline'}
                        title="Video"
                        className="cmrVideoPlayer"
                    ></iframe>
                ) : listingdata.photoUrl &&
                  listingdata.photoUrl.trim().length > 0 &&
                  !isDefaultImage() ? (
                    <img
                        className={
                            listingdata.photoIsLandscape ? 'landscape' : 'portrait'
                        }
                        src={listingdata.photoUrl}
                        alt=""
                        onClick={handleOpen}
                    ></img>
                ) : (
                    <div className="no-image-text"></div>
                )}
                {listingdata.photoUrl || listingdata.videoUrl ? (
                    <button
                        className={
                            listingdata.videoSelected &&
                            listingdata.videoUrl &&
                            listingdata.videoUrl.trim().length > 0
                                ? 'imageActionText videoActionText'
                                : 'imageActionText'
                        }
                        onClick={handleOpen}
                    >
                        Click here to change
                    </button>
                ) : (
                    ''
                )}
                <hr className="line" />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </div>
            <div className="details-container">
                <div className="top">
                    <div className="title">Report Builder</div>
                    {listingdata.streetAddress &&
                    listingdata.streetAddress.trim().length > 0 ? (
                        <div className="address">{listingdata.streetAddress}</div>
                    ) : (
                        ''
                    )}
                    <div className="city">
                        {listingdata.city && listingdata.city.trim().length > 0 ? (
                            <span>
                                {listingdata.city},{'\u00A0'}
                            </span>
                        ) : (
                            ''
                        )}
                        {listingdata.state && listingdata.state.trim().length > 0 ? (
                            <span>{listingdata.state}</span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="bottom">
                    <div className="content">
                        Drag and drop sections to rearrange order on report and choose to
                        display or not.
                    </div>
                    <div className="buttons">
                        <button
                            className="settings action-button"
                            onClick={openScheduler}
                        >
                            Email Report
                        </button>
                        <Modal
                            open={modalScheduler}
                            onClose={closeScheduler}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            {scheduler}
                        </Modal>
                        <button className="report action-button" onClick={viewReport}>
                            View Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
