import React, { FunctionComponent, useEffect } from 'react';
import {
    useAppDispatch,
    useFormData,
    useAppSelector,
} from '../../../../../../../../app/hooks';
import { Grid, TextField, Tooltip } from '@material-ui/core';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import RadioButtonToggle from '../../../../../../../../shared/component/formFields/RadioButtonToggle';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setCurrentFormData } from '../../../../../listingSlice';
import { lotSizeTypes } from '../../../../../listingConstants';
import { fetchAmenities, listingFeaturesSliceState } from '../../listingFeaturesSlice';
import { LotFeatures } from '../../../../../../../../shared/models/listing/amenityFeatures';
import { ListingFeatureDrawerProps } from '../../listingFeaturesModels';
import { useTooltipStyle } from '../../../../../../../../shared/component/toolTip/toolTipStyle';
import { getAmenitiesForSection } from '../../../../../../../../shared/utils/listing/featureUtils';
import AmenitySection from '../shared/AmenitySection';

const LotAmenities: FunctionComponent<ListingFeatureDrawerProps> = ({
    editFormData,
    sectionName,
}) => {
    const {
        lotSize,
        lotSizeTypeId,
        lotSizeUnit,
        exteriorSize,
        amenities: listingAmenities,
    } = editFormData.data;
    const initialData: LotFeatures = {
        lotSize,
        lotSizeTypeId,
        lotSizeUnit,
        exteriorSize,
    };
    const { formData, setFormData, handleNumberChange, handleFloatChange } =
        useFormData(initialData);
    const { amenities } = useAppSelector(listingFeaturesSliceState);
    const dispatch = useAppDispatch();
    const tooltipClass = useTooltipStyle();

    const sectionAmenities = getAmenitiesForSection(amenities, sectionName);

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    useEffect(() => {
        if (!amenities.length) {
            dispatch(fetchAmenities());
        }
    }, [amenities, dispatch]);

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentFormData({
                formData,
                listingEditFormName: sectionName,
            }),
        );
    };

    const handleLotSizeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const typeName = lotSizeTypes.find((l) => l.id === value)?.name;
        if (typeName) {
            setFormData({ ...formData, [name || '']: value, lotSizeUnit: typeName });
        }
    };

    const extTooltipText =
        'Usable exterior space for amenities such as terraces, balconies, patios, etc...';

    return (
        <div className="sectionContainer">
            <div className="section">
                <Grid
                    item
                    container
                    className="content tooltipContent"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="LOT SIZE"
                        control={
                            <TextField
                                value={formData.lotSize || ''}
                                name="lotSize"
                                onChange={handleFloatChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'lotSize',
                                    className: 'inputBaseRoot',
                                }}
                                inputProps={{ maxLength: 10 }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                    <FormRow
                        label="OUTDOOR SPACE"
                        control={
                            <TextField
                                value={formData.exteriorSize ? formData.exteriorSize : ''}
                                onChange={handleNumberChange}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'exteriorSize',
                                    className: 'inputBaseRoot',
                                    endAdornment: 'Sq.Ft',
                                }}
                                inputProps={{ maxLength: 13 }}
                                onBlur={setFormDataToPersist}
                                name="exteriorSize"
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                    <span className="exteriorTooltip">
                        <Tooltip
                            title={extTooltipText}
                            classes={{
                                tooltip: tooltipClass.tooltip,
                            }}
                            arrow
                            placement="top"
                        >
                            <span className="icon">
                                <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                            </span>
                        </Tooltip>
                    </span>
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label=""
                        control={
                            <RadioButtonToggle
                                id={formData.lotSizeTypeId || ''}
                                name="lotSizeTypeId"
                                items={lotSizeTypes}
                                handleRadioButtonChange={handleLotSizeTypeChange}
                                handleBlur={() => setFormDataToPersist()}
                                formClass={{
                                    label: 'formControlLabel',
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass=""
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
            </div>
            <AmenitySection
                listingId={editFormData.data.id}
                listingAmenities={listingAmenities}
                amenitiesForSection={sectionAmenities}
            />
            <div className="info-text">
                NOTE: Not all the amenities and features associated with this listing will
                be displayed on every website. Each syndication website has its own set of
                features and amenities that it chooses to display. Features and amenities
                that are not currently displayed on a website may be in the future.
            </div>
        </div>
    );
};

export default LotAmenities;
