import { Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GlobalSearchCriteriaCalledFrom } from '../../models/globalSearch/globalSearchModel';
import {
    fetchMoreSearchResults,
    globalSearch,
} from '../../slices/globalSearch/globalSearchSlice';
import { setTitle } from '../../slices/navigation/navigationSlice';
import { globalTabId, searchTabs } from './globalSearchUtils';
import './index.scss';
import { TabPanel } from '../tabPanel/TabPanel';
import { useWindowDimensions } from '../Window';
import ViewGlobalResults from './ViewGlobalResults';
import ComponentWithLoader from '../loader/ComponentWithLoader';

const useStyles = makeStyles({
    tabs: {
        '& .MuiTab-root': {
            color: '#999',
            opacity: 1,
            minWidth: 'unset',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#b28e3a',
        },
    },
});

const GlobalSearchDetail: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<number>(0); //tabs
    const [activeTab, setActiveTab] = useState<string>('all');
    const { width } = useWindowDimensions();
    const classes = useStyles();
    const { viewMoreClicked, data, loader, textSearchedFor } =
        useAppSelector(globalSearch);
    useEffect(() => {
        dispatch(setTitle(`Global Search Results Page`));
    }, [dispatch]);

    //to set the tab to all when the view more is clicked
    useEffect(() => {
        if (viewMoreClicked) {
            setValue(0);
            setActiveTab('all');
        }
    }, [viewMoreClicked]);

    const handleChange = (event: unknown, newValue: number) => {
        setValue(newValue);
        setActiveTab(searchTabs[newValue]);
        let tab = '';
        if (newValue === globalTabId.active.id && data['all'].totalRecords === 0) {
            tab = 'all';
        } else if (
            newValue === globalTabId.active.id &&
            data['active'].totalRecords === 0 &&
            data['active'].tableAction.text.length === 0
        ) {
            tab = 'active';
        } else if (
            newValue === globalTabId.pending.id &&
            data['pending'].totalRecords === 0 &&
            data['pending'].tableAction.text.length === 0
        ) {
            tab = 'pending';
        } else if (
            newValue === globalTabId.sold.id &&
            data['sold'].totalRecords === 0 &&
            data['sold'].tableAction.text.length === 0
        ) {
            tab = 'sold';
        } else if (
            newValue === globalTabId.expired.id &&
            data['expired'].totalRecords === 0 &&
            data['expired'].tableAction.text.length === 0
        ) {
            tab = 'expired';
        } else if (
            newValue === globalTabId.offMarket.id &&
            data['offMarket'].totalRecords === 0 &&
            data['offMarket'].tableAction.text.length === 0
        ) {
            tab = 'offMarket';
        } else if (
            newValue === globalTabId.comingSoon.id &&
            data['comingSoon'].totalRecords === 0 &&
            data['comingSoon'].tableAction.text.length === 0
        ) {
            tab = 'comingSoon';
        } else if (
            newValue === globalTabId.draft.id &&
            data['draft'].totalRecords === 0 &&
            data['draft'].tableAction.text.length === 0
        ) {
            tab = 'draft';
        } else if (
            newValue === globalTabId.agents.id &&
            data['agents'].totalRecords === 0 &&
            data['agents'].tableAction.text.length === 0
        ) {
            tab = 'agents';
        } else if (
            newValue === globalTabId.salesTeams.id &&
            data['salesTeams'].totalRecords === 0 &&
            data['salesTeams'].tableAction.text.length === 0
        ) {
            tab = 'salesTeams';
        } else if (
            newValue === globalTabId.offices.id &&
            data['offices'].totalRecords === 0 &&
            data['offices'].tableAction.text.length === 0
        ) {
            tab = 'offices';
        } else if (
            newValue === globalTabId.applications.id &&
            data['applications'].totalRecords === 0 &&
            data['applications'].tableAction.text.length === 0
        ) {
            tab = 'applications';
        } else if (
            newValue === globalTabId.privateListing.id &&
            data['privateListing'].totalRecords === 0 &&
            data['privateListing'].tableAction.text.length === 0
        ) {
            tab = 'privateListing';
        }

        if (tab?.length) {
            const { text } = data['all'].tableAction;
            makeApiCall(text, tab);
        }
    };

    const loadMore = () => {
        const { text } = data['all'].tableAction;
        makeApiCall(text, activeTab, true);
    };

    const makeApiCall = (
        searchText: string,
        calledFrom: string,
        incrementPage = false,
    ) => {
        const { currentPage, entityType, status } = data[calledFrom].tableAction;
        const searchObject: GlobalSearchCriteriaCalledFrom = {
            from: calledFrom,
            currentPage: incrementPage ? currentPage + 1 : currentPage,
            itemsPerPage: 100,
            text: `"${searchText}"`,
            status: status,
            entityType: entityType,
        };
        dispatch(fetchMoreSearchResults(searchObject));
    };
    return (
        <Grid container className="fullPage searchResults">
            <ComponentWithLoader
                showLoader={loader}
                showOverlay={true}
                styleClass="loaderClass"
            />
            <Grid item xs={12} className="glo-title">
                Search Results{' '}
                {viewMoreClicked && textSearchedFor && textSearchedFor.length > 0
                    ? `for ${textSearchedFor}`
                    : ''}
            </Grid>
            <Grid container item xs={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { background: '#b28e3a' } }}
                    className={classes.tabs}
                    variant="scrollable"
                    scrollButtons={width > 767 ? 'auto' : 'on'}
                >
                    <Tab
                        className="customHeading"
                        id="simple-tab-0"
                        label="All"
                        value={globalTabId.all.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-0"
                        label="Active"
                        value={globalTabId.active.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-0"
                        label="Private"
                        value={globalTabId.privateListing.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-1"
                        label="Pending"
                        value={globalTabId.pending.id}
                    />

                    <Tab
                        className="customHeading"
                        id="simple-tab-2"
                        label="Sold"
                        value={globalTabId.sold.id}
                    />

                    <Tab
                        className="customHeading"
                        id="simple-tab-3"
                        label="Expired"
                        value={globalTabId.expired.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-4"
                        label="Off-Market"
                        value={globalTabId.offMarket.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-5"
                        label="Coming Soon"
                        value={globalTabId.comingSoon.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-6"
                        label="Draft"
                        value={globalTabId.draft.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-6"
                        label="Agents"
                        value={globalTabId.agents.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-6"
                        label="SalesTeams"
                        value={globalTabId.salesTeams.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-6"
                        label="Offices"
                        value={globalTabId.offices.id}
                    />
                    <Tab
                        className="customHeading"
                        id="simple-tab-6"
                        label="Applications"
                        value={globalTabId.applications.id}
                    />
                </Tabs>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.all.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="all"
                        list={data.all.results}
                        totalRecords={data.all.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.active.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.active.results}
                        totalRecords={data.active.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.privateListing.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.privateListing.results}
                        totalRecords={data.privateListing.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.pending.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.pending.results}
                        totalRecords={data.pending.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>

                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.sold.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.sold.results}
                        totalRecords={data.sold.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>

                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.expired.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.expired.results}
                        totalRecords={data.expired.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.offMarket.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.offMarket.results}
                        totalRecords={data.offMarket.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.comingSoon.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.comingSoon.results}
                        totalRecords={data.comingSoon.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.draft.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.draft.results}
                        totalRecords={data.draft.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.agents.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.agents.results}
                        totalRecords={data.agents.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.salesTeams.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.salesTeams.results}
                        totalRecords={data.salesTeams.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.offices.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.offices.results}
                        totalRecords={data.offices.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
                <TabPanel
                    id="simple-tab"
                    value={value}
                    index={globalTabId.applications.id}
                    className="tab-panel-holder"
                >
                    <ViewGlobalResults
                        from="others"
                        list={data.applications.results}
                        totalRecords={data.applications.totalRecords}
                        loadMore={loadMore}
                        viewMoreClicked={viewMoreClicked}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default GlobalSearchDetail;
