import { Fab } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import { useOktaAuth } from '@okta/okta-react';
import { FunctionComponent, useMemo } from 'react';
import { getUser } from '../../auth/authService';
import { userIsInAnyRole } from '../../auth/permissions/permissionsService';
import { userRoles } from '../../constants/user/roles';
import './floatingButton.scss';
import { getData } from '../../../utils/storageService';

const FloatingButton: FunctionComponent = () => {
    const { authState } = useOktaAuth();
    const user = useMemo(() => getUser(), []);
    const isAgent = useMemo(() => userIsInAnyRole(user, [userRoles.agent.key]), [user]);
    const handleFloatingButtonClick = () => {
        // window.open('/feedback', '_blank');
        window.open(
            'https://sirapplications.atlassian.net/servicedesk/customer/portal/2/group/13',
            '_blank',
        );
    };

    const displayListingForPrint = () => {
        return getData('listingStatus') ? getData('listingStatus') === '11' : false;
    };

    return authState?.isAuthenticated && !isAgent ? (
        <>
            <Fab
                color="primary"
                aria-label="addModel"
                className={
                    'float-button' + (displayListingForPrint() ? ' screen-display' : '')
                }
                onClick={handleFloatingButtonClick}
            >
                <MessageIcon />
            </Fab>
        </>
    ) : (
        <></>
    );
};

export default FloatingButton;
