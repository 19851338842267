import React, { FunctionComponent, useEffect } from 'react';
import {
    useAppDispatch,
    useFormData,
    useAppSelector,
} from '../../../../../../../../app/hooks';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import { ListingFeatureDrawerProps } from '../../listingFeaturesModels';
import { InteriorFeatures } from '../../../../../../../../shared/models/listing/amenityFeatures';
import { fetchAmenities, listingFeaturesSliceState } from '../../listingFeaturesSlice';
import AmenitySection from '../shared/AmenitySection';
import { getAmenitiesForSection } from '../../../../../../../../shared/utils/listing/featureUtils';
import { setCurrentFormData } from '../../../../../listingSlice';

const InteriorAmenities: FunctionComponent<ListingFeatureDrawerProps> = ({
    editFormData,
    sectionName,
}) => {
    const {
        rooms,
        bedrooms,
        fullBaths,
        halfBaths,
        interiorSize,
        amenities: listingAmenities,
    } = editFormData.data;
    const initialData: InteriorFeatures = {
        rooms,
        bedrooms,
        fullBaths,
        halfBaths,
        interiorSize,
    };
    const { formData, setFormData, handleNumberChange } = useFormData(initialData);

    const dispatch = useAppDispatch();
    const { amenities } = useAppSelector(listingFeaturesSliceState);

    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    useEffect(() => {
        if (!amenities.length) {
            dispatch(fetchAmenities());
        }
    }, [amenities, dispatch]);

    const sectionAmenities = getAmenitiesForSection(amenities, sectionName);

    const setFormDataToPersist = () => {
        dispatch(
            setCurrentFormData({
                formData,
                listingEditFormName: sectionName,
            }),
        );
    };

    return (
        <div className="sectionContainer">
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="FULL BATHS"
                        control={
                            <TextField
                                value={formData.fullBaths || ''}
                                name="fullBaths"
                                onChange={handleNumberChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'fullBaths',
                                    className: 'inputBaseRoot',
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                    <FormRow
                        label="HALF BATHS"
                        control={
                            <TextField
                                value={formData.halfBaths || ''}
                                name="halfBaths"
                                onChange={handleNumberChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'halfBaths',
                                    className: 'inputBaseRoot',
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="TOTAL ROOMS"
                        control={
                            <TextField
                                value={formData.rooms || ''}
                                name="rooms"
                                onChange={handleNumberChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'rooms',
                                    className: 'inputBaseRoot',
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                    <FormRow
                        label="BEDROOMS"
                        control={
                            <TextField
                                value={formData.bedrooms || ''}
                                name="bedrooms"
                                onChange={handleNumberChange}
                                onBlur={setFormDataToPersist}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'bedrooms',
                                    className: 'inputBaseRoot',
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <FormRow
                        label="INTERIOR"
                        control={
                            <TextField
                                value={formData.interiorSize ? formData.interiorSize : ''}
                                onChange={handleNumberChange}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'interiorSize',
                                    className: 'inputBaseRoot',
                                    endAdornment: 'Sq.Ft',
                                }}
                                inputProps={{ maxLength: 13 }}
                                onBlur={setFormDataToPersist}
                                name="interiorSize"
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelMd={2}
                        controlMd={4}
                    />
                </Grid>
            </div>
            <AmenitySection
                listingId={editFormData.data.id}
                listingAmenities={listingAmenities}
                amenitiesForSection={sectionAmenities}
            />
            <div className="info-text">
                NOTE: Not all the amenities and features associated with this listing will
                be displayed on every website. Each syndication website has its own set of
                features and amenities that it chooses to display. Features and amenities
                that are not currently displayed on a website may be in the future.
            </div>
        </div>
    );
};

export default InteriorAmenities;
