import { Grid } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { FunctionComponent, useRef } from 'react';
import { DrawerContainerProps, OfficeDescription } from '../../../officeModels';
import { useAppDispatch, useFormData, useAppSelector } from '../../../../../../app/hooks';
import './history.scss';
import { setCurrentOfficeEditFormData } from '../../../officeSlice';
import { editFormComponent } from '../../../officeConstants';
import { getOfficeDescriptionFieldsData } from '../../../officeUtils';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import {
    CommonLookups,
    OfficeLookups,
} from '../../../../../../shared/models/lookups/lookupsModels';

import FormRow from '../../../../../../shared/component/formFields/FormRow';
import RenderRemainingChar from '../../../../../../shared/component/editor/RenderRemainingChar';
import { calculateRemainingChars } from '../../../../../../utils/urlUtils';

const History: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const { common, officeLook } = useAppSelector(lookups);
    const officeDetails: OfficeDescription = getOfficeDescriptionFieldsData(
        editFormData.data.officeDetails,
        (common as CommonLookups).states,
        (officeLook as OfficeLookups).officeSalesRegions,
    );
    const dispatch = useAppDispatch();
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const { formData, handleEditorChange } = useFormData(officeDetails);
    const setFormDataToPersist = () => {
        dispatch(
            setCurrentOfficeEditFormData({
                formData,
                officeEditFormName: editFormComponent.history,
            }),
        );
    };

    const checkAndCallPersist = () => {
        const remainingChar = calculateRemainingChars(formData.history || '', 8000);
        if (remainingChar >= 0) {
            setFormDataToPersist();
        }
    };
    return (
        <Grid container className="history-container">
            <Grid item container sm={12} md={12}>
                <FormRow
                    label=""
                    control={
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_MCE}
                            init={{
                                placeholder: '',
                                height: 240,
                                statusbar: false,
                                menubar: false,
                                plugins: ['link', 'lists', 'paste'],
                                branding: false,
                                paste_as_text: true,
                                setup: (editor) => {
                                    editor.on('PostProcess', function (ed) {
                                        // we are cleaning empty paragraphs
                                        ed.content = ed.content
                                            .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                            .replace(/(<br \/>)/gi, '<br>')
                                            .replace(/(\r\n|\n|\r)/gi, '<br>')
                                            .replace(/(<br>){1,}/g, '<br>');
                                    });
                                },
                                toolbar: 'bold | italic',
                                content_style:
                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                            }}
                            value={formData.history || ''}
                            onInit={(event, editor) => (editorRef.current = editor)}
                            onEditorChange={(newValue) => {
                                handleEditorChange('history', newValue);
                            }}
                            onBlur={checkAndCallPersist}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="history-form-control"
                    labelXs={12}
                    labelSm={12}
                    labelMd={12}
                    controlXs={12}
                    controlSm={12}
                    controlMd={12}
                />
            </Grid>
            <RenderRemainingChar value={formData.history || ''} maxLength={8000} />
        </Grid>
    );
};
export default History;
