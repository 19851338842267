import { OtherMarketingListingData } from '../../../features/listings/listing/marketing/propertyMarketingListModels';

export const additionalPromotion = '16';
export const emailCampaign = '12';
export const socialCampaign = '15';

export const initialOtherMarketingListingData: OtherMarketingListingData = {
    marketingTypeId: '',
    additionalPromotionId: '',
    date: '',
    description: '',
    publish: true,

    actualCost: 0,
    agentCost: 0,
    officeCost: 0,
    billingCode: '',
    cit: true,
    isAgentPaid: false,

    isFile: false,
    file: undefined,
    url: '',
    // email campaign
    audience: '',
    emailsSent: 0,
    views: 0,
    clicks: 0,
    // social campaign
    toDate: '',
    potentialReach: 0,
    engagements: 0,
    agentMarketingCosts: [],
};
