import { AuthorizationResponse } from '../../shared/auth/authModels';
import { postCall } from '../../utils/api/apiWrapper';
import {
    UserSearchRequest,
    UserSearchResponse,
} from '../../shared/models/users/userModels';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';

export function getMasqueradedUserData(
    request: UserSearchResponse,
): Promise<AuthorizationResponse> {
    return postCall<UserSearchResponse, AuthorizationResponse>(
        'user/masquerade',
        request,
    );
}

export function getUsersToMasquerade(
    request: UserSearchRequest,
): Promise<PaginationResponse<UserSearchResponse>> {
    return postCall<UserSearchRequest, PaginationResponse<UserSearchResponse>>(
        `user/masqueradesearch`,
        request,
    );
}
