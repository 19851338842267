import React, { FunctionComponent, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import {
    cmrDetails,
    displayReportToggle,
    deleteData,
    saveOpenHouse,
} from '../../../cmrSlice';
import './index.scss';
import Header from '../../../shared/index';
import { sortByDate } from '../../../../../utils/urlUtils';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import { OpenHouseData, SectionProps } from '../../../cmrModels';
import 'antd/dist/antd.css';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import OpenHouseTemplate from '../../../../../shared/component/listing/openHouseTemplate/OpenHouseTemplate';

const CmrOpenHouse: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingId, listingdata } = useAppSelector(cmrDetails);
    const initialState: OpenHouseData = {
        listingId: listingdata.id,
        listingNumber: listingId,
        startDateTime: '',
        endDateTime: '',
        section: '',
        showingAgent: '',
        byAppointmentOnly: false,
        doNotShowTime: false,
        brokerOnly: false,
        isVirtual: false,
        virtualUrl: '',
        notes: '',
        attendees: 0,
    };
    const sortedOpenHouses = sortByDate(
        paneldata.openHouses as OpenHouseData[],
        'startDateTime',
    );
    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const deleteIt = (id: string | undefined) => {
        if (id) {
            googleAnalyticsAction('Delete', 'Click', 'Open House Delete');
            const url = `OpenHouse/${id}`;
            dispatch(deleteData(url, props.sectionId, id, 'id'));
        }
    };

    const [activeEditId, setActiveEditId] = useState<string>('');

    const editClickHandler = (currentEditId: string | undefined) => {
        if (activeEditId === currentEditId) {
            setActiveEditId('');
        } else if (currentEditId) {
            googleAnalyticsAction('Edit', 'Click', 'Open House Edit');
            setActiveEditId(currentEditId);
        }
    };

    const handleSaveClick = (openHouseData: OpenHouseData) => {
        dispatch(saveOpenHouse(openHouseData));
    };

    return (
        <div className="crmOpenHouse">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Open House Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={hideDisplayOnReport(4, sortOrder) ? true : false}
            />
            <OpenHouseTemplate
                useGa={true}
                sortedOpenHouses={sortedOpenHouses}
                activeEditId={activeEditId}
                deleteIt={deleteIt}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
                isCmr={true}
            />
        </div>
    );
};
export default CmrOpenHouse;
