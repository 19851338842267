import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getAmenities,
    saveListingAmenities,
    saveListingFeatureData as saveListingAmenityData,
} from './listingFeaturesApi';
import { ListingFeaturesState } from './listingFeaturesModels';
import BuildingAmenities from './components/amenityForms/BuildingAmenities';
import InteriorAmenities from './components/amenityForms/InteriorAmenities';
import LotAmenities from './components/amenityForms/LotAmenities';
import GeneralAmenities from './components/amenityForms/GeneralAmenities';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import { ListingDetailEvents } from '../../../../../../shared/models/loader/loaderModels';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { ListingAmenity } from '../../../../../../shared/models/listing/amenityModels';
import {
    BuildingFeatures,
    InteriorFeatures,
    LotFeatures,
} from '../../../../../../shared/models/listing/amenityFeatures';
import { amenitySections } from '../../../../../../shared/constants/listing/amenities';
import {
    DevelopmentListModel,
    DevelopmentSearchRequest,
} from '../../../../../developments/developmentListModel';
import { getDevelopmentListApi } from '../../../../../developments/developmentListApi';
import { PaginationResponse } from '../../../../../../shared/models/pagination/paginationModels';

export const listingAmenityFormMap = new Map([
    [amenitySections.building, BuildingAmenities],
    [amenitySections.interior, InteriorAmenities],
    [amenitySections.lot, LotAmenities],
    [amenitySections.room, GeneralAmenities],
    [amenitySections.construction, GeneralAmenities],
    [amenitySections.parking, GeneralAmenities],
    [amenitySections.outdoor, GeneralAmenities],
    [amenitySections.association, GeneralAmenities],
    [amenitySections.green, GeneralAmenities],
]);

const listingFeaturesState: ListingFeaturesState = {
    amenities: [],
    amenitiesChanged: false,
    developmentList: [],
};

const listingFeaturesSlice = createSlice({
    name: 'listingFeatures',
    initialState: listingFeaturesState,
    reducers: {
        updateFeatureList: (state, action: PayloadAction<ListingAmenity[]>) => {
            state.amenities = action.payload;
        },
        toggleAmenityFlag: (state, action: PayloadAction<boolean>) => {
            state.amenitiesChanged = action.payload;
        },
        setListingDevelopmentList: (
            state,
            action: PayloadAction<PaginationResponse<DevelopmentListModel> | null>,
        ) => {
            if (action.payload) {
                const devList = action.payload.results.map((data) => {
                    return {
                        id: data.id,
                        name: data.title,
                    };
                });
                return {
                    ...state,
                    developmentList: [...devList],
                };
            } else {
                return {
                    ...state,
                    developmentList: [],
                };
            }
        },
    },
});

export const saveListingAmenityDetails =
    (
        id: string,
        request: LotFeatures | InteriorFeatures | BuildingFeatures,
        featureName: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(ListingDetailEvents.LISTING_SAVE));
            const response = await saveListingAmenityData(id, request, featureName);
            if (response.status !== 204) {
                throw Error(`Error saving listing ${featureName} data`);
            } else {
                dispatch(setSuccess(`Listing ${featureName} data saved successfully`));
            }
        } catch {
            Logger.error(`Error saving ${featureName} data for: ${id}`);
            dispatch(setError(`Error saving ${featureName} data`));
        } finally {
            dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_SAVE));
        }
    };

/** Gets all amenities in the system and loads them to the state */
export const fetchAmenities = (): AppThunk => async (dispatch) => {
    try {
        dispatch(addLoadingEvent(ListingDetailEvents.LISTING_GET));
        const response = await getAmenities();
        if (response.length > 0) dispatch(updateFeatureList(response));
    } catch {
        Logger.error('Error getting amenities');
        dispatch(setError('Error getting amenities'));
    } finally {
        dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_GET));
    }
};

export const saveAmenities =
    (amenities: ListingAmenity[], listingId: string): AppThunk =>
    async (dispatch, getState) => {
        if (getState().listing.listingFeatures.amenitiesChanged) {
            try {
                dispatch(addLoadingEvent(ListingDetailEvents.LISTING_SAVE));
                await saveListingAmenities(amenities, listingId);
                dispatch(toggleAmenityFlag(false));
                dispatch(setSuccess('Listing amenities saved successfully'));
            } catch {
                Logger.error(`Error saving listing amenities: ${listingId}`);
                dispatch(setError('Error saving listing amenities'));
            } finally {
                dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_SAVE));
            }
        }
    };

/**
 * function that will fetch the list of development that has been created already
 * @param data
 * @returns
 */
export const fetchListingDevelopmentList =
    (data: DevelopmentSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            //remove publish key if it is false
            if (!data.publish) {
                delete data.publish;
            }
            //remove valid key if it is false
            if (!data.validity) {
                delete data.validity;
            }
            const response = await getDevelopmentListApi({
                ...data,
            });
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            )
                dispatch(setListingDevelopmentList(response));
        } catch (exception) {
            dispatch(setError(`Failed to get development list`));
            Logger.error(`Failed to get development list ${JSON.stringify(exception)}`);
        } finally {
        }
    };

export const { updateFeatureList, toggleAmenityFlag, setListingDevelopmentList } =
    listingFeaturesSlice.actions;
export const listingFeaturesSliceState = (state: RootState): ListingFeaturesState =>
    state.listing.listingFeatures;

export default listingFeaturesSlice.reducer;
