import { AgentNames } from '../../../shared/models/lookups/lookupsModels';
import { getCall, postCall } from '../../../utils/api/apiWrapper';
import { ListingDraftRentalPeriod, ListingDraftRequest } from './listingDraftModels';

export function getAgentNamesByIdsApi(agentIds: string[]): Promise<AgentNames[]> {
    return postCall<string[], AgentNames[]>(`agent/lookups`, agentIds);
}

export function getOfficeSalesRegionRentalPeriodApi(
    officeId: string,
): Promise<ListingDraftRentalPeriod[]> {
    return getCall<ListingDraftRentalPeriod[]>(`listingDraft/rentalperiods/${officeId}`);
}

export function saveListingDraftApi(
    listingDraft: ListingDraftRequest,
): Promise<ListingDraftRequest> {
    return postCall<ListingDraftRequest, ListingDraftRequest>(
        `listingDraft`,
        listingDraft,
    );
}
