import { getCall, postCall, postFormData } from '../../../utils/api/apiWrapper';
import {
    ImageData,
    ImageSaveResult,
    ImageDeleteData,
} from '../../models/images/sharedModel';
import { DataFlowResponse } from '../../../utils/api/apiModels';

export function getImages(
    entityType: string,
    entityId: string,
    imageType: string,
): Promise<ImageData[]> {
    return getCall<ImageData[]>(
        `image/${entityId}?imageType=${imageType}&entityType=${entityType}`,
    );
}

export function savePhoto(request: FormData): Promise<ImageSaveResult> {
    return postFormData<ImageSaveResult>(`image/save`, request);
}

export function deletePhoto(request: ImageDeleteData): Promise<DataFlowResponse> {
    return postCall<ImageDeleteData, DataFlowResponse>(`image/delete`, request);
}
