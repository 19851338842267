import {
    UserToken,
    UserClaims,
    UserIdClaims,
    User,
    AuthorizationResponse,
} from './authModels';
import Logger from '../../utils/logging/logger';
import { getData, storeData, removeData } from '../../utils/storageService';
import { getUserAuthorizationDetails } from './authApis';
import { getMasqueradedUserData } from '../../features/masquerade/masqueradeApi';
import { UserSearchResponse, CurrentUserData } from '../models/users/userModels';
import OktaAuth from '@okta/okta-auth-js';

export const tokenStorageKey = 'zeus-token';
export const userKey = 'zeus-user';
export const originalUser = 'original-user';
export const sessionTimeOut = 'sessionTimeout';
export const loginTokenKey = 'login-token';
export const oktaSharedTransactionStorage = 'okta-shared-transaction-storage';
export const oktaTokenStorage = 'okta-token-storage';
export const oktaOriginalUriStorage = 'okta-original-uri-storage';
export const oktaCacheStorage = 'okta-cache-storage';
export const pageHasBeenForceRefresh = 'page-has-been-force-refreshed';

/** Gets the user's current access token from local storage.  If expired,
 * it will return an empty one, which will start the process of re-authenticating.
 */
export function getToken(key?: string): string {
    const tokenString = getData(key ? key : tokenStorageKey);
    if (!tokenString) {
        return '';
    }
    const userToken: UserToken = JSON.parse(tokenString);
    return userToken?.token;
}

/** Gets the current user info from local storage */
export function getUser(): User {
    return parseUser(userKey);
}

/**
 * Retrieves info for the currently logged in user (as
 * opposed to the masqueraded user when in progress)
 */
export function getCurrentUserData(): CurrentUserData {
    const key = isMasquerading() ? originalUser : userKey;
    const user = parseUser(key);
    const currentUserData: CurrentUserData = {
        firstName: user.firstName,
        lastName: user.lastName,
        profileId: user.profileId,
    };
    return currentUserData;
}

function parseUser(key: string): User {
    const user = getData(key);
    return user !== '' ? JSON.parse(user) : new User();
}

function convertHashToClaimsString(hash?: string): string {
    if (!hash?.length) {
        return '';
    }
    const base64Url = hash.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const claimsString = atob(base64);
    // Commented for now
    // Logger.debug(`Raw claims: ${claimsString}`);
    return claimsString;
}

/**
 * Loads the user data from the access token claims, gets authorization
 * details from the system, and stores the user data in local storage
 * @param token The user's access token
 */
export async function saveUser(userToken: UserToken): Promise<void> {
    if (!userToken.token) {
        return;
    }

    const accessClaimsString = convertHashToClaimsString(userToken.token);
    let claims: UserClaims = JSON.parse(accessClaimsString);

    const idClaimsString = convertHashToClaimsString(userToken.idToken);
    const idClaims: UserIdClaims = JSON.parse(idClaimsString);

    claims = { ...claims, ...idClaims };
    Logger.debug(`User claims: ${JSON.stringify(claims)}`);
    Logger.debug(`ID claims: ${JSON.stringify(idClaims)}`);

    let user = new User(claims);
    try {
        const response: AuthorizationResponse = await getUserAuthorizationDetails(user);
        user = { ...user, ...response };
    } catch (e) {
        Logger.error(`Error getting user details for ${user.userName}: ${e}`);
    } finally {
        const key = isMasquerading() ? originalUser : userKey;
        storeData<User>(key, user);
        storeData<UserToken>(tokenStorageKey, userToken);
        Logger.debug(`User: ${JSON.stringify(user)}`);
    }
}

/**
 * fetches masquerade user details from the authorization and user end points and updates
 * in the local storage for zeus-user and also moves the zeus-user data to logged-in-user
 * @param formData This is the object that contains user email
 */
export const onMasquerade = async (formData: UserSearchResponse): Promise<void> => {
    let rep = {};
    try {
        const response: AuthorizationResponse = await getMasqueradedUserData(formData);
        rep = { ...rep, ...response };

        // Swap details of the user if we get a successful response
        const loggedInUser = JSON.parse(getData(userKey));
        storeData(originalUser, loggedInUser);
        storeData(userKey, rep);
        window.location.href =
            window.location.protocol + '//' + window.location.host + '/eng/home';
    } catch (e) {
        Logger.error(
            `Failed to fetch details of Masqueraded User ${formData.email}: ${e}`,
        );
    }
};

/**
 * cancel the masqueraded functionality
 * @param originalLoggedUser This is a string that holds the data of the original loggedin user
 */
export const cancelMasquerade = (): void => {
    storeData(userKey, parseUser(originalUser));
    removeData(originalUser);
    window.location.href =
        window.location.protocol + '//' + window.location.host + '/eng/home';
};

/**
 * Checks to see if user is currently masquerading as someone else
 * @returns true if currently masquereading, else false
 */
export const isMasquerading = (): boolean => {
    return getData(originalUser).length > 0;
};

/**
 * Log out the user & cancel the masquerade (if any)
 * @returns void
 */
export const logOut = async (oktaAuth: OktaAuth): Promise<void> => {
    await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
    if (getData(originalUser).length > 0) {
        removeData(originalUser);
    }
    removeData(tokenStorageKey);
    removeData(userKey);
    removeData(sessionTimeOut);
};

/**
 * Works as a soft logout, we dont want user to logout of other active okta sessions
 */
export const clearLocalStorage = (): any => {
    const keys = [
        tokenStorageKey,
        userKey,
        sessionTimeOut,
        loginTokenKey,
        oktaSharedTransactionStorage,
        oktaTokenStorage,
        oktaOriginalUriStorage,
        oktaCacheStorage,
        pageHasBeenForceRefresh,
        originalUser,
    ];
    keys.forEach((k) => removeData(k));
};
