import { postCall } from '../../utils/api/apiWrapper';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { TeamSearch, SalesTeamResponse } from './salesTeamListModels';

export function getSalesTeams(
    searchRequest: TeamSearch,
): Promise<PaginationResponse<SalesTeamResponse>> {
    return postCall<TeamSearch, PaginationResponse<SalesTeamResponse>>(
        `SalesTeam/search`,
        searchRequest,
    );
}
