import React, { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import { ListingFeatureDrawerProps } from '../../listingFeaturesModels';
import { fetchAmenities, listingFeaturesSliceState } from '../../listingFeaturesSlice';
import AmenitySection from '../shared/AmenitySection';
import { getAmenitiesForSection } from '../../../../../../../../shared/utils/listing/featureUtils';

const GeneralAmenities: FunctionComponent<ListingFeatureDrawerProps> = ({
    editFormData,
    sectionName,
}) => {
    const dispatch = useAppDispatch();
    const { amenities } = useAppSelector(listingFeaturesSliceState);

    const listingAmenities = editFormData.data.amenities;
    const sectionAmenities = getAmenitiesForSection(amenities, sectionName);

    useEffect(() => {
        if (!amenities.length) {
            dispatch(fetchAmenities());
        }
    }, [amenities, dispatch]);

    return (
        <div className="sectionContainer">
            <AmenitySection
                listingId={editFormData.data.id}
                listingAmenities={listingAmenities}
                amenitiesForSection={sectionAmenities}
            />
            <div className="info-text">
                NOTE: Not all the amenities and features associated with this listing will
                be displayed on every website. Each syndication website has its own set of
                features and amenities that it chooses to display. Features and amenities
                that are not currently displayed on a website may be in the future.
            </div>
        </div>
    );
};

export default GeneralAmenities;
