import { FunctionComponent, useState } from 'react';
import { OpenHouseData } from '../../../../../features/cmr/cmrModels';
import {
    dateFormatMMDDYYY,
    currencyFormat,
    timeFormatHHMM,
} from '../../../../../utils/urlUtils';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';
import { makeStyles } from '@material-ui/core';

export interface OpenHouseListProps {
    useGa: boolean;
    oH: OpenHouseData;
    deleteIt(id: string | undefined): void;
    editClickHandler(id: string | undefined): void;
}

const useStyles = makeStyles({
    arrow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
});

const ListTemplate: FunctionComponent<OpenHouseListProps> = ({
    useGa,
    oH,
    deleteIt,
    editClickHandler,
}) => {
    const [activeId, setActiveId] = useState<string>('');
    const classes = useStyles();

    const moreClickHandler = (currentId: string | undefined) => {
        if (useGa)
            googleAnalyticsAction('view detail', 'Click', 'Open House View More expand');
        if (currentId === activeId) {
            return setActiveId('');
        } else if (currentId) setActiveId(currentId);
    };
    return (
        <div className="rows">
            <div className="row-1">
                {oH.startDateTime && dateFormatMMDDYYY(oH.startDateTime.toString()) ? (
                    <div className="data-date date-text">
                        {dateFormatMMDDYYY(oH.startDateTime.toString())}
                    </div>
                ) : (
                    <div className="data-date date-text"></div>
                )}
                {oH.showingAgent && oH.showingAgent.trim().length > 0 ? (
                    <div className="data-agent data-text">{oH.showingAgent}</div>
                ) : (
                    <div className="data-agent data-text"></div>
                )}
                {oH.startDateTime && timeFormatHHMM(oH.startDateTime.toString()) ? (
                    <div className="data-from date-text time">
                        {timeFormatHHMM(oH.startDateTime.toString())}
                    </div>
                ) : (
                    <div className="data-from date-text time"></div>
                )}
                {oH.endDateTime && timeFormatHHMM(oH.endDateTime.toString()) ? (
                    <div className="data-to date-text to-time">
                        {timeFormatHHMM(oH.endDateTime.toString())}
                    </div>
                ) : (
                    <div className="data-to date-text to-time"></div>
                )}
                <div className="more data-text" onClick={() => moreClickHandler(oH.id)}>
                    {activeId === oH.id ? (
                        <ArrowDropDownIcon className={classes.arrow} />
                    ) : (
                        <ArrowRightIcon className={classes.arrow} />
                    )}
                </div>
                <div className="openHouse-icons">
                    <span className="edit icon">
                        <FontAwesomeIcon
                            icon={faEdit}
                            size="sm"
                            onClick={() => editClickHandler(oH.id)}
                        />
                    </span>
                    <span className="trash icon">
                        <FontAwesomeIcon
                            icon={faTrash}
                            size="sm"
                            onClick={() => deleteIt(oH.id)}
                        />
                    </span>
                </div>
            </div>
            {activeId === oH.id && (
                <div className="row-2">
                    <div className="more-container">
                        <div className="more-row">
                            <span className="more-title-text">Attendees</span>
                            {oH.attendees && !isNaN(oH.attendees) ? (
                                <div className="more-content">
                                    {currencyFormat(oH.attendees)}
                                </div>
                            ) : (
                                <div className="more-content empty">-</div>
                            )}
                        </div>
                        <div className="more-row">
                            <span className="more-title-text">Notes</span>
                            {oH.notes && oH.notes.trim().length > 0 ? (
                                <div className="more-content">{oH.notes}</div>
                            ) : (
                                <div className="more-content empty">-</div>
                            )}
                        </div>
                        {!useGa && (
                            <div className="more-row">
                                <span className="more-title-text">OH Section</span>
                                {oH.section && oH.section.trim().length > 0 ? (
                                    <div className="more-content">{oH.section}</div>
                                ) : (
                                    <div className="more-content empty">-</div>
                                )}
                            </div>
                        )}
                        <div className="more-row">
                            <span className="more-title-text">Type</span>
                            <div className="selectContent">
                                {oH.byAppointmentOnly && (
                                    <span className="more-content">
                                        By Appointment Only
                                    </span>
                                )}
                                {oH.doNotShowTime && (
                                    <span className="more-content">
                                        Don't display time
                                    </span>
                                )}
                                {oH.brokerOnly && (
                                    <span className="more-content">Broker Only</span>
                                )}
                                {oH.isVirtual && (
                                    <span className="more-content">Virtual</span>
                                )}
                                {!oH.byAppointmentOnly &&
                                    !oH.doNotShowTime &&
                                    !oH.brokerOnly &&
                                    !oH.isVirtual && (
                                        <span className="more-content">-</span>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListTemplate;
