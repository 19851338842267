import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { MessageDetails } from '../../models/messaging/messagingModels';

const initialState: MessageDetails = {
    open: false,
    severity: 'success',
    message: '',
};

export const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        setSuccess: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                open: true,
                message: action.payload,
                severity: 'success',
            };
        },
        setError: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                open: true,
                message: action.payload,
                severity: 'error',
            };
        },
        setWarning: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                open: true,
                message: action.payload,
                severity: 'warning',
            };
        },
        closeMessage: (state) => {
            return {
                ...state,
                open: false,
            };
        },
    },
});

export const { setSuccess, setError, closeMessage, setWarning } = messagingSlice.actions;
export const selectMessageDetails = (state: RootState): MessageDetails => state.messaging;

export default messagingSlice.reducer;
