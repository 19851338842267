import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import { VideoModel } from '../../../../../../shared/models/video/videoModels';
import {
    deleteVideo,
    downloadVideo,
    getVideos,
} from '../../../../../../shared/slices/video/videoSlice';
import { useAppDispatch } from '../../../../../../app/hooks';
import {
    updateDevVideoStatus,
    updateMainDevelopmentData,
    uploadDevVideo,
} from '../../../developmentDetailsSlice';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';
import VideoTemplate from '../../../../../../shared/component/video/VideoTemplate';
import { videoTypes } from '../../../../../../shared/constants/video/videoConstants';
import { getAbortController } from '../../../../../../utils/urlUtils';

const DevelopmentVideosEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const { videoDetail, id } = editFormData.data;
    const [developmentVideo, setDevelopmentVideo] = useState<VideoModel>(videoDetail);

    const uploadIt = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const inputEvent = event as ChangeEvent<HTMLInputElement>;
        if (!inputEvent.target.files) return;
        const uploadedFile = inputEvent.target.files[0];
        const data = {
            developmentId: id,
            file: uploadedFile,
            displayOrder: 1,
        };
        dispatch(updateDevVideoStatus(2));
        dispatch(
            uploadDevVideo(data, getAbortController(developmentVideo.isCancelled, index)),
        );
    };
    const cancelVideo = (video: VideoModel, index: number) => {
        const abortController = getAbortController(developmentVideo.isCancelled, index);
        abortController.abort();
        deleteIt(video);
    };
    //to download the video
    const downloadIt = (video: VideoModel, from: string) => {
        dispatch(downloadVideo(video, from));
    };
    //to delete the video
    const deleteIt = (video: VideoModel) => {
        dispatch(deleteVideo(video));
    };

    //after upload continous api call to get the results
    useEffect(() => {
        const interval = setInterval(() => {
            if (videoDetail.status === 2) {
                dispatch(getVideos(id, entityTypes.building.key));
            } else {
                clearInterval(interval);
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [videoDetail, dispatch, id]);

    useEffect(() => {
        setDevelopmentVideo(videoDetail);
    }, [videoDetail]);

    useEffect(() => {
        dispatch(getVideos(id, entityTypes.building.key));
    }, [dispatch, id]);

    const updateInterval = (remainingInterval: number, fileInputName: string) => {
        if (fileInputName === 'developmentVideo')
            dispatch(
                updateMainDevelopmentData({
                    videoDetail: {
                        ...videoDetail,
                        reuploadInterval: remainingInterval,
                    },
                }),
            );
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6} sm={6} className="videoActionHolder one">
                <VideoTemplate
                    fileInputName="developmentVideo"
                    videoSectionTitle="Development Video"
                    data={developmentVideo}
                    reuploadInterval={videoDetail.reuploadInterval}
                    index={videoTypes.buildingVideo.index}
                    uploadAction={uploadIt}
                    cancelAction={cancelVideo}
                    downloadAction={downloadIt}
                    deleteAction={deleteIt}
                    updateInterval={updateInterval}
                />
            </Grid>
        </Grid>
    );
};

export default DevelopmentVideosEdit;
