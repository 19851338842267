import {
    ListingSearchRequest,
    SearchedListingListModel,
} from '../../shared/models/listing/commonModel';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { deleteCall, getCall, postCall, postFormData } from '../../utils/api/apiWrapper';
import {
    OtherMarketingListModel,
    OtherMarketingSearchRequest,
    SearchedListingAgentModel,
} from './otherMarketingModel';
import { OtherMarketing } from '../../shared/models/marketing/marketingModels';
import { isManager } from '../../shared/utils/shared/sharedUtils';

export function getOtherMarketingListApi(
    otherMarketingSearchRequest: OtherMarketingSearchRequest,
): Promise<PaginationResponse<OtherMarketingListModel>> {
    return postCall<
        OtherMarketingSearchRequest,
        PaginationResponse<OtherMarketingListModel>
    >(`Marketing/search`, otherMarketingSearchRequest);
}

export function getListingListApi(
    data: ListingSearchRequest,
): Promise<PaginationResponse<SearchedListingListModel>> {
    //update agent id and agent role for manager
    const dataNew = isManager(data);
    data.agentIds = dataNew.agentIds ? dataNew.agentIds : data.agentIds;
    data.roleIds = dataNew.roleIds;
    return postCall<ListingSearchRequest, PaginationResponse<SearchedListingListModel>>(
        `listing/search`,
        data,
    );
}

export function getListingAgentListApi(
    listingId: string,
): Promise<SearchedListingAgentModel[]> {
    return getCall<SearchedListingAgentModel[]>(`Marketing/listing/${listingId}/agents`);
}

export function saveOtherMarketingApi(data: FormData): Promise<OtherMarketing> {
    return postFormData<OtherMarketing>('marketing', data);
}

export function deleteOtherMarketingApi(id: string): Promise<Response> {
    return deleteCall(`marketing/${id}/details`);
}
