import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    selectMessageDetails,
    closeMessage,
} from '../../slices/messaging/messagingSlice';
import './messageToast.scss';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MessageToast: FunctionComponent = () => {
    const timeout = useRef<NodeJS.Timeout>();
    const messageDetails = useAppSelector(selectMessageDetails);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        if (timeout.current) clearTimeout(timeout.current);
        dispatch(closeMessage());
    };

    /*
    Due to the editor in listing ad copy section, toast message goes away quickly. In order to fix that, written useEffect separately, and removed autoHideDuration property from Snackbar.
    */
    useEffect(() => {
        if (messageDetails.open) {
            timeout.current = setTimeout(() => {
                handleClose();
            }, 6000);
        }
    }, [messageDetails.open]);

    const renderMessage = () => {
        return (
            <div className="message-toast">
                <Snackbar open={messageDetails.open} className="customSnack">
                    <Alert onClose={handleClose} severity={messageDetails.severity}>
                        {messageDetails.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    };

    return messageDetails.open ? renderMessage() : null;
};
