import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { cmrDetails, fetchPanelDatabySort, updateCurrentElement } from '../../cmrSlice';
import AdvertisementsComponent from './advertisements';
import InTheNewsComponent from './inTheNews';
import ShowingsComponent from './showings';
import ViewsOnWebComponent from './viewsOnWeb';
import CmrOpenHouseComponent from './openHouse';
import NotesComponent from './notes';
import AdditionalPromotionComponent from './additionalPromotion';
import FeaturePlacmentComponent from './featuredPlacement';
import SocialCampaignsComponent from './socialCampaigns';
import EmailCampaignsComponent from './emailCampaigns';
import { panelDetails, basedOnSectionId } from '../../cmrConstant';

// componentMapping key is created based on sectionId ,
// make sectionId of component
const componentMapping = {
    [panelDetails.viewsOnWeb.id]: ViewsOnWebComponent, // View on Map
    [panelDetails.inTheNews.id]: InTheNewsComponent, // In the news
    [panelDetails.advertisements.id]: AdvertisementsComponent, // Advertisements
    [panelDetails.showings.id]: ShowingsComponent, // Showings
    [panelDetails.openHouses.id]: CmrOpenHouseComponent, // Open Houses
    [panelDetails.emailCampaigns.id]: EmailCampaignsComponent, // Email Campaigns
    [panelDetails.socialCampaigns.id]: SocialCampaignsComponent, // Social Campaigns
    [panelDetails.featuredPlacements.id]: FeaturePlacmentComponent, // Featured Placements
    [panelDetails.additionalPromotion.id]: AdditionalPromotionComponent, // Additional Promotion
    [panelDetails.notes.id]: NotesComponent, // Notes
};

const RightPanel: FunctionComponent = () => {
    const { currentOrderElement, sortOrder, paneldata, listingId } =
        useAppSelector(cmrDetails);
    const [renderComponent, setRenderComponent] = useState<number[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // load first element
        if (currentOrderElement !== 0 && sortOrder) {
            const orderElement = sortOrder.filter((val) => {
                return val.sectionId === currentOrderElement;
            });
            // to set parent as current element if orderelement is not available
            if (orderElement && orderElement.length === 0) {
                sortOrder.forEach((data) => {
                    if (data.childSections.length > 0) {
                        const isAvailable = data.childSections.find((val) => {
                            return val.sectionId === currentOrderElement;
                        });
                        if (isAvailable) {
                            dispatch(updateCurrentElement(data.sectionId));
                        }
                    }
                });
            }
            // to load main content
            if (
                orderElement &&
                orderElement.length > 0 &&
                !paneldata[
                    basedOnSectionId(orderElement[0].sectionId)
                        .panelStateName as keyof typeof paneldata
                ]
            ) {
                dispatch(fetchPanelDatabySort(listingId, orderElement[0]));
            }
            // to load child data if stacked
            if (
                orderElement &&
                orderElement.length > 0 &&
                orderElement[0].childSections.length > 0
            ) {
                orderElement[0].childSections.forEach((data) => {
                    // Below condition is to check whether the display name is the key of panelData object
                    if (
                        !paneldata[
                            basedOnSectionId(data.sectionId)
                                .panelStateName as keyof typeof paneldata
                        ]
                    ) {
                        dispatch(fetchPanelDatabySort(listingId, data));
                    }
                });
            }
        }
    }, [currentOrderElement, sortOrder, dispatch, listingId]);

    useEffect(() => {
        if (currentOrderElement && sortOrder) {
            const filterComponent = sortOrder.filter(
                (value) => value.sectionId === currentOrderElement,
            );
            if (filterComponent.length > 0) {
                const currentComponent = [];
                currentComponent.push(filterComponent[0].sectionId);
                filterComponent[0].childSections.forEach((filterValue) => {
                    if (filterValue.sectionId <= 10)
                        currentComponent.push(filterValue.sectionId);
                });
                setRenderComponent([...currentComponent]);
            }
        }
    }, [currentOrderElement, sortOrder]);
    return (
        <div>
            {renderComponent.map((componentName: number, index: number) => {
                const Component =
                    componentMapping[componentName as keyof typeof componentMapping];
                return <Component sectionId={componentName} key={'main' + index} />;
            })}
        </div>
    );
};

export default RightPanel;
