import { bedsAndBath, currencyFormat, dateFormatMMDDYYY } from '../../utils/urlUtils';
import {
    TableColumn,
    ListingsResponse,
    ListingsRequest,
    ManageList,
    ManageListSub,
} from './listingListModels';
import loDash from 'lodash';
import { Order } from '../../shared/models/customTable/customTableModels';
import {
    fetchUserPermissions,
    getUserRoleIds,
    userIsInAnyRole,
    userIsInRole,
} from '../../shared/auth/permissions/permissionsService';
import { User } from '../../shared/auth/authModels';
import { userRoles } from '../../shared/constants/user/roles';
import { LookupInfo, OfficeLookupInfo } from '../../shared/models/lookups/lookupsModels';
import { getUser } from '../../shared/auth/authService';

export const getTableData = (
    tableOption: TableColumn,
    tableData: ListingsResponse,
): string => {
    if (tableOption.isImage) {
        const image = tableData?.listingSearchImageResult
            ? tableData.listingSearchImageResult.imageUrl
            : '/img/s-grey.png';
        const imageClass =
            tableData?.listingSearchImageResult &&
            !tableData?.listingSearchImageResult.isLandscape
                ? 'portrait'
                : '';
        return `<div class="list-image ${imageClass}"><img src=${image} loading="lazy" /></div>`;
    }
    if (tableOption.isNormal) {
        if (tableOption.key === 'businessPurposeTypeId') {
            return tableData?.businessPurposeTypeId === 1 ? 'Sale' : 'Rental';
        } else {
            const data = tableData?.[tableOption.key as keyof typeof tableData];
            if (data) {
                return data.toString();
            } else {
                return '-';
            }
        }
    }

    if (tableOption.isPrice) {
        let price = '-';
        const data = tableData?.[tableOption.key as keyof typeof tableData];
        if (data) {
            price = '$' + currencyFormat(+data);
        }
        return price;
    }
    if (tableOption.isDate) {
        let date = '-';
        const isDate = tableData?.[tableOption.key as keyof typeof tableData];
        if (isDate) {
            const customDate = dateFormatMMDDYYY(isDate as string, 'dash', true);
            date = customDate ? customDate : '-';
        }

        return date;
    }
    if (tableOption.isBedAndBath) {
        const bedBaths = bedsAndBath(
            tableData.bedrooms,
            tableData.fullBaths,
            tableData.halfBaths,
        );
        return bedBaths;
    }
    if (tableOption.isPricePerSq) {
        const price =
            tableData?.currentPrice &&
            tableData?.interiorSize &&
            tableData?.businessPurposeTypeId === 1
                ? '$' +
                  currencyFormat(
                      loDash.round(+tableData.currentPrice / tableData.interiorSize),
                  )
                : '-';
        return price.toString();
    }

    return '';
};

//get the total difference in date between two dates
export const dateDiff = (date: string, data: string): string => {
    const dateSent = new Date(date);
    const currentDate = new Date();
    const difference = currentDate.getTime() - dateSent.getTime();

    return Math.ceil(difference / (1000 * 3600 * 24)) + ' ' + data;
};

export const initializeListingsRequest = (
    sortColumn: string | undefined,
    sortDirection: Order | undefined,
    page: number,
    itemsPerPage: number,
    status: string,
    user: User,
    businessPurposeTypeId: string,
): ListingsRequest => {
    return {
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        currentPage: page,
        itemsPerPage: itemsPerPage,
        status: status,
        businessPurposeTypeId: businessPurposeTypeId,
        agentIds: userIsInRole(user, userRoles.agent.key)
            ? fetchUserPermissions(user, 'agentIds')
            : [],
        officeIds: fetchUserPermissions(user, 'officeIds'),
        roleIds: getUserRoleIds(user),
    };
};

//to add last asking and office price to sold listing radio
export const withPriceAdded = (
    data: ListingsResponse,
    price: LookupInfo[],
): LookupInfo[] => {
    const clonedPrice = JSON.parse(JSON.stringify(price));
    const updatedPrice = clonedPrice.map((pr: LookupInfo) => {
        if (pr.id === 'askingPrice')
            pr.name =
                pr.name +
                ` ($${currencyFormat(data.lastAskingPrice || data.currentPrice)})`;
        if (pr.id === 'soldPrice')
            pr.name = pr.name + ` ($${currencyFormat(data.offerPrice)})`;
        return pr;
    });
    return updatedPrice;
};

/**
 * Allow listing publish with Agents with below role access - Publish Listing
 */
export const verifyPublishAccess = (
    user: User,
    listingAgents?: string[],
    listingOffice?: string[],
): boolean => {
    //agent should be able to edit his own listing and not other agent listing
    //below condition is added for the coming soon listing section since agent can see other
    //agent listing
    const isAgent = userIsInRole(user, userRoles.agent.key);
    if (isAgent) {
        const agentIds: string[] = fetchUserPermissions(user, 'agentIds');
        //validate if listing agent details is passed
        if (listingAgents && listingAgents.length > 0) {
            //check if the logged in agent is part of the listing
            if (
                validateListingAccess(listingAgents, true, agentIds) &&
                userIsInAnyRole(user, [userRoles.publishListing.key])
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return userIsInAnyRole(user, [userRoles.publishListing.key]) ? true : false;
        }
    } else {
        //non agent
        //admin validation
        if (userIsInRole(user, userRoles.systemAdmin.key)) {
            return true;
        } else {
            //validate user listing edit role
            if (userIsInRole(user, userRoles.publishListing.key)) {
                const officeIds: string[] = fetchUserPermissions(user, 'officeIds');
                //if office is passed validate office based permission
                if (listingOffice) {
                    if (validateListingAccess(listingOffice, true, officeIds)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    //else return true based on listing publish acccess alone
                    //eg grid and table publish button
                    return true;
                }
            } else {
                return false;
            }
        }
    }
};

/**
 * hide listing edit for the Agent with below role access - Hide Draft
 */
export const verifyListingEdit = (
    listingAgents?: string[],
    listingOffice?: string[],
): boolean => {
    const user = getUser();
    //agent should be able to edit his own listing and not other agent listing
    //below condition is added for the coming soon listing section since agent can see other
    //agent listing
    const isAgent = userIsInRole(user, userRoles.agent.key);
    if (isAgent) {
        const agentIds: string[] = fetchUserPermissions(user, 'agentIds');
        //validate if listing agent details is passed
        if (listingAgents && listingAgents.length > 0) {
            //check if the logged in agent is part of the listing
            if (
                validateListingAccess(listingAgents, true, agentIds) &&
                userIsInAnyRole(user, [userRoles.listingEdit.key])
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return userIsInAnyRole(user, [userRoles.listingEdit.key]) ? true : false;
        }
    } else {
        //non agent
        //admin validation
        if (userIsInRole(user, userRoles.systemAdmin.key)) {
            return true;
        } else {
            //validate user listing edit role
            if (userIsInRole(user, userRoles.listingEdit.key)) {
                const officeIds: string[] = fetchUserPermissions(user, 'officeIds');
                //if office is passed validate office based permission
                if (listingOffice) {
                    if (validateListingAccess(listingOffice, true, officeIds)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    //else return true based on listing edit acccess alone
                    //eg grid and table menu action icon validation, photos tab etc
                    return true;
                }
            } else {
                return false;
            }
        }
    }
};

/**
 * hide listing edit for price for the agent with below role - Price Edit
 */
export const disableListingPriceEdit = (): boolean => {
    const user = getUser();
    if (userIsInRole(user, userRoles.agent.key)) {
        return userIsInRole(user, userRoles.disableListingPriceEdit.key) ? true : false;
    } else {
        return false;
    }
};

/**
 * hide listing edit for dates for the agent with below role - Date Edit
 */
export const disableListingDatesEdit = (): boolean => {
    const user = getUser();
    if (userIsInRole(user, userRoles.agent.key)) {
        return userIsInRole(user, userRoles.disableListingDatesEdit.key) ? true : false;
    } else {
        return false;
    }
};

/**
 * hide listing edit for Agents for the agent with below role - Agent Edit
 */
export const disableListingAgentsEdit = (): boolean => {
    const user = getUser();
    if (userIsInRole(user, userRoles.agent.key)) {
        return userIsInRole(user, userRoles.disableListingAgentsEdit.key) ? true : false;
    } else {
        return false;
    }
};

/**
 * hide listing edit for showcase site for the agent with below role - Showcase Edit
 */
export const disableShowcaseEdit = (): boolean => {
    const user = getUser();
    return !userIsInRole(user, userRoles.systemAdmin.key) &&
        userIsInRole(user, userRoles.disableShowcaseEdit.key)
        ? true
        : false;
};

/**
 * hide listing edit for adcopy for the agent with below role - Showcase Edit
 */
export const disableAdCopyEdit = (): boolean => {
    const user = getUser();
    return !userIsInRole(user, userRoles.systemAdmin.key) &&
        userIsInRole(user, userRoles.disableAdCopyEdit.key)
        ? true
        : false;
};

/**
 * Permission to only reorder photos - Listing Photo reorder
 */
export const verifyListingPhotosReorder = (): boolean => {
    const user = getUser();
    if (userIsInRole(user, userRoles.agent.key)) {
        return userIsInRole(user, userRoles.listingPhotoReorder.key) ? true : false;
    } else {
        return false;
    }
};

/**
 * validate user access for listing edit from grid/list page dropdown
 * @param manageList
 * @returns
 */
export const validateAccess = (manageList: ManageList): ManageListSub[] => {
    let list = manageList.data;
    if (disableListingPriceEdit()) {
        list = list.filter((data) => data.toOpen !== 'price');
    }

    if (disableAdCopyEdit()) {
        list = list.filter((data) => data.toOpen !== 'adCopy');
    }
    if (disableShowcaseEdit()) {
        list = list.filter((data) => data.toOpen !== 'showcase');
    }
    return list;
};

/** get sold agent side */
export const getSide = (data: ListingsResponse, agentsIds: string[]): string => {
    const SellSideAgents =
        data && data.saleSideAgents && data.saleSideAgents.length
            ? agentsIds.filter((x) => data.saleSideAgents.includes(x))
            : [];
    const ListSideAgents =
        data && data.listSideAgents && data.listSideAgents
            ? agentsIds.filter((x) => data.listSideAgents.includes(x))
            : [];

    let side = '';
    if (SellSideAgents.length > 0 && ListSideAgents.length > 0) {
        side = 'Both';
    } else if (SellSideAgents.length > 0) {
        side = 'Buyer';
    } else if (ListSideAgents.length > 0) {
        side = 'Seller';
    }
    return side;
};

/**to validate if the logged in agent have access to listing */
export const validateListingAccess = (
    listingAgentList: string[],
    isUserAgent: boolean,
    agentIds: string[],
): boolean => {
    if (isUserAgent) {
        return loDash.intersectionWith(agentIds, listingAgentList, loDash.isEqual)
            .length > 0
            ? true
            : false;
    } else {
        return true;
    }
};

/**
 * to check if the logged in agent is the listing agent, So they can see the owner information
 * on detail page - for private and comming soon listings
 */
export const validateViewAccess = (
    listingAgentsId: string[],
    listingOfficeId: string[],
): boolean => {
    const user = getUser();
    const isAgent = userIsInRole(user, userRoles.agent.key);
    if (isAgent) {
        const agentIds: string[] = fetchUserPermissions(user, 'agentIds');
        if (agentIds.length && listingAgentsId.length) {
            return validateListingAccess(listingAgentsId, true, agentIds);
        } else {
            return false;
        }
    } else {
        //non agent
        //admin validation
        if (userIsInRole(user, userRoles.systemAdmin.key)) {
            return true;
        } else {
            //validate user listing edit role
            if (userIsInRole(user, userRoles.listingEdit.key)) {
                const officeIds: string[] = fetchUserPermissions(user, 'officeIds');
                //if office is passed validate office based permission
                if (
                    listingOfficeId &&
                    validateListingAccess(listingOfficeId, true, officeIds)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
};

export const getListingTabName = (tabValue: number | string) => {
    const val = typeof tabValue === 'string' ? tabValue.trim() : tabValue;
    switch (val) {
        case 0:
        case 'active':
            return 'Active';
        case 1:
        case 'pending':
            return 'Pending';
        case 2:
        case 'sold':
            return 'Sold';
        case 3:
        case 'expired':
            return 'Expired';
        case 4:
        case 'offMarket':
            return 'Off-Market';
        case 5:
        case 'comingSoon':
            return 'Coming Soon';
        case 6:
        case 'draft':
            return 'Draft';
        case 7:
        case 'privateListing':
            return 'Private';
        default:
            return '';
    }
};

export const getSortColumnName = (property: string) => {
    switch (property) {
        case 'propertyType':
            return 'Property Type';
        case 'currentPrice':
            return 'List Price';
        case 'offerPrice':
            return 'Contract Price';
        case 'publish':
            return 'Publish';
        case 'soldPrice':
            return 'Last Asking Price or Sold Price / Price';
        case 'address1':
            return 'Address or Title';
        case 'closedDate':
            return 'Sold Date';
        default:
            return '';
    }
};

// showing My market listings only for CA region as per SAM-1588
export const getSubCategory = (status: string, showMyMarket: boolean) => {
    const user = getUser();
    switch (status) {
        case 'coming Soon':
        case 'comingSoonMyListing':
            const isAgent = userIsInRole(user, userRoles.agent.key);
            return isAgent ? 'comingSoonMyListing' : '';
        case 'private':
        case 'myMarket':
            const isAdmin = userIsInRole(user, userRoles.systemAdmin.key);
            return showMyMarket && !isAdmin ? 'myMarket' : '';
        default:
            return '';
    }
};

/**
 *
 * @param offices office lookup
 * @returns returns list of all offices that belong to CA region, if a user has an office in CA region
 */
export const getMyMarketOffices = (offices: OfficeLookupInfo[]) => {
    let requiredOffices: string[] = [];
    const userOffices = fetchUserPermissions(getUser(), 'officeIds');
    for (const uo of userOffices) {
        // currently implemented to get My Market listings only for CA region(regionId = 7) as per SAM-1588
        const isRequiredRegion = offices.some((o) => o.id === uo && o.regionId === 7);
        if (isRequiredRegion) {
            requiredOffices = offices.filter((o) => o.regionId === 7).map((o) => o.id);
            break;
        }
    }
    return requiredOffices;
};
