import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useFormData } from '../../../app/hooks';
import { LookupInfo } from '../../models/lookups/lookupsModels';
import { OwnerInfoModel } from '../../models/ownerInfoModel/ownerInfoModel';

import FormRow from '../formFields/FormRow';
import DropDownSearch from '../formFields/DropDownSearch';
import { listingStatuses } from '../../../features/listings/listing/listingConstants';

interface OwnerInfoTemplateProps {
    index: number;
    owner: OwnerInfoModel;
    states: LookupInfo[];
    updateOwnerInfo: (index: number, formData: OwnerInfoModel) => void;
    decrementOwner: (index: number) => void;
    listingStatusId: number | null;
}
const OwnerInfoTemplate: FunctionComponent<OwnerInfoTemplateProps> = ({
    index,
    owner,
    states,
    updateOwnerInfo,
    decrementOwner,
    listingStatusId,
}) => {
    const {
        formData,
        setFormData,
        handleTextChange,
        handleFloatChange,
        handleDropDownSearch,
    } = useFormData(owner);

    const handleRadioButtonChange = (
        event: ChangeEvent<HTMLInputElement>,
        index: number,
    ) => {
        const ownerAddressDifferentFromPropertyAddress = event.target.value;
        let currentOwner;
        if (ownerAddressDifferentFromPropertyAddress === 'false') {
            currentOwner = {
                ...formData,
                address: null,
                address2: null,
                city: null,
                regionId: null,
                zip: null,
                ownerAddressDifferentFromPropertyAddress: false,
            };
        } else {
            currentOwner = {
                ...formData,
                ownerAddressDifferentFromPropertyAddress: true,
            };
        }
        setFormData(currentOwner);
        updateOwnerInfo(index, currentOwner);
    };
    useEffect(() => {
        setFormData(owner);
    }, [owner, setFormData]);
    return (
        <Grid key={index} container>
            {listingStatusId !== listingStatuses.sold &&
            listingStatusId !== listingStatuses.deleted ? (
                <Grid item xs={12} md={12} className="requiredField">
                    *Required Fields
                </Grid>
            ) : (
                <></>
            )}
            <Grid item xs={12} md={12} className="owner-info-order-label">
                {index === 0
                    ? 'Primary Owner'
                    : index === 1
                    ? 'Second Owner'
                    : 'Third Owner'}
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                className="nameFields"
                spacing={1}
            >
                <FormRow
                    label={`FIRST NAME${
                        listingStatusId !== listingStatuses.sold &&
                        listingStatusId !== listingStatuses.deleted
                            ? '*'
                            : ''
                    }`}
                    control={
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'nameInput',
                            }}
                            value={formData.firstName || ''}
                            name="firstName"
                            onBlur={() => updateOwnerInfo(index, formData)}
                            onChange={handleTextChange}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                    labelSm={2}
                    controlSm={4}
                />
                <FormRow
                    label={`LAST NAME${
                        listingStatusId !== listingStatuses.sold &&
                        listingStatusId !== listingStatuses.deleted
                            ? '*'
                            : ''
                    }`}
                    control={
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'nameInput',
                            }}
                            value={formData.lastName || ''}
                            name="lastName"
                            onBlur={() => updateOwnerInfo(index, formData)}
                            onChange={handleTextChange}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                    labelSm={2}
                    controlSm={4}
                />
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
                className="ownerInfoToggle"
            >
                <Grid item xs={12} md={4} className="ownerInfoLabel">
                    Owner address is different than property address:
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl>
                        <RadioGroup
                            className="radioHolder"
                            row
                            name="ownerAddressDifferentFromPropertyAddress"
                            value={
                                formData.ownerAddressDifferentFromPropertyAddress || false
                            }
                            onChange={(event) => handleRadioButtonChange(event, index)}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio className="checkbox" />}
                                label="Yes"
                                className="ownerInfoRadio"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio className="checkbox" />}
                                label="No"
                                className="ownerInfoRadio"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {formData.ownerAddressDifferentFromPropertyAddress ? (
                    <>
                        <Grid
                            item
                            container
                            alignItems="center"
                            className="addressFields"
                            spacing={1}
                        >
                            <FormRow
                                label={`ADDRESS 1${
                                    listingStatusId !== listingStatuses.sold &&
                                    listingStatusId !== listingStatuses.deleted
                                        ? '*'
                                        : ''
                                }`}
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            autoComplete: 'off',
                                            className: 'addressInput',
                                        }}
                                        value={formData.address || ''}
                                        name="address"
                                        onBlur={() => updateOwnerInfo(index, formData)}
                                        onChange={handleTextChange}
                                    />
                                }
                                controlClass="formControlRoot"
                                labelMd={2}
                                controlMd={4}
                                labelSm={2}
                                controlSm={4}
                            />
                            <FormRow
                                label={`ADDRESS 2${
                                    listingStatusId !== listingStatuses.sold &&
                                    listingStatusId !== listingStatuses.deleted
                                        ? '*'
                                        : ''
                                }`}
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            autoComplete: 'off',
                                            className: 'addressInput',
                                        }}
                                        value={formData.address2 || ''}
                                        name="address2"
                                        onBlur={() => updateOwnerInfo(index, formData)}
                                        onChange={handleTextChange}
                                    />
                                }
                                controlClass="formControlRoot"
                                labelMd={2}
                                controlMd={4}
                                labelSm={2}
                                controlSm={4}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            className="otherFields"
                            spacing={1}
                        >
                            <FormRow
                                label={`CITY${
                                    listingStatusId !== listingStatuses.sold &&
                                    listingStatusId !== listingStatuses.deleted
                                        ? '*'
                                        : ''
                                }`}
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            autoComplete: 'off',
                                            className: 'cityInput',
                                        }}
                                        value={formData.city || ''}
                                        name="city"
                                        onBlur={() => updateOwnerInfo(index, formData)}
                                        onChange={handleTextChange}
                                    />
                                }
                                controlClass="formControlRoot"
                                labelMd={2}
                                controlMd={4}
                                labelSm={2}
                                controlSm={4}
                            />
                            <FormRow
                                label={`STATE${
                                    listingStatusId !== listingStatuses.sold &&
                                    listingStatusId !== listingStatuses.deleted
                                        ? '*'
                                        : ''
                                }`}
                                control={
                                    states?.length > 0 ? (
                                        <DropDownSearch
                                            id={formData.regionId || ''}
                                            items={states}
                                            name="regionId"
                                            handleDropDownChange={handleDropDownSearch}
                                            handleBlur={() =>
                                                updateOwnerInfo(index, formData)
                                            }
                                        />
                                    ) : (
                                        // <FormControl fullWidth>
                                        //     <Select
                                        //         value={formData.regionId || ''}
                                        //         onChange={(event) =>
                                        //             handleSelectChange(event, index)
                                        //         }
                                        //         disableUnderline
                                        //         className="selectState formControlRoot  inputBaseRoot dropdownBaseRoo"
                                        //     >
                                        //         {states.map((region: LookupInfo) => (
                                        //             <MenuItem
                                        //                 value={region.id}
                                        //                 key={region.id}
                                        //             >
                                        //                 {region.name}
                                        //             </MenuItem>
                                        //         ))}
                                        //     </Select>
                                        // </FormControl>
                                        <></>
                                    )
                                }
                                controlClass="formControlRoot"
                                labelMd={2}
                                controlMd={4}
                                labelSm={2}
                                controlSm={4}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            className="otherFields"
                            spacing={1}
                        >
                            <FormRow
                                label={`ZIP${
                                    listingStatusId !== listingStatuses.sold &&
                                    listingStatusId !== listingStatuses.deleted
                                        ? '*'
                                        : ''
                                }`}
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            autoComplete: 'off',
                                            className: 'zipInput',
                                        }}
                                        inputProps={{ maxLength: 5 }}
                                        value={formData.zip || ''}
                                        name="zip"
                                        onBlur={() => updateOwnerInfo(index, formData)}
                                        onChange={handleFloatChange}
                                    />
                                }
                                controlClass="formControlRoot"
                                labelMd={2}
                                controlMd={4}
                                labelSm={2}
                                controlSm={4}
                            />
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                {index > 0 ? (
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        xs={12}
                        md={12}
                    >
                        <button
                            className="customButton"
                            onClick={() => decrementOwner(index)}
                        >
                            Delete
                        </button>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};
export default OwnerInfoTemplate;
