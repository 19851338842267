import { OfficeIds } from '../models/pagination/paginationModels';

export interface UserToken {
    token: string;
    idToken?: string;
    expires: Date;
    refresh_token?: string;
}

/** The consolidated list of user claims we want, from the access
 * and ID tokens
 */
export interface UserClaims {
    /** The user's first name */
    firstName: string;

    /** The user's last name */
    lastName: string;

    /** The user's email */
    email: string;

    /** The user's type (ex: Agent, Staff, Contractor) */
    employeeType: string;

    /** The user's unique Okta ID */
    uid: string;

    /** The user's username */
    sAMAccountName: string;

    /** The list of the user's member claims */
    memberOf: string[];

    /** The office(s) the user is assigned to (maps to
     * Trident IDs) */
    officeids?: number[];

    /** is the user associated with development (mainly used for agent) */
    isAssociatedWithBuilding?: boolean;
}

/** Represents claims included with the
 * user's ID token
 */
export interface UserIdClaims {
    /** The office(s) the user is assigned to (maps to
     * Trident IDs) */
    officeids?: number[];

    /** The primary office of the user */
    officePrimaryID?: number;

    /** The user's metro ID (should be 607 for SIR) */
    metroPrimaryID?: string;

    /** The list of metro IDs the user is associated with */
    metroids?: string[];
}

/** Object containing data around the currently logged in user */
export class User {
    constructor(claims?: UserClaims) {
        this.firstName = claims?.firstName || '';
        this.lastName = claims?.lastName || '';
        this.email = claims?.email || '';
        this.employeeType = claims?.employeeType || '';
        this.oktaId = claims?.uid || '';
        this.userName = claims?.sAMAccountName || '';
        this.memberOf = claims?.memberOf || [];
        this.tridentOfficeIds = claims?.officeids || [];
        this.userId = '';
        this.profileId = '';
        this.isAssociatedWithBuilding = claims?.isAssociatedWithBuilding;
    }

    /** The user's first name */
    firstName: string;

    /** The user's last name */
    lastName: string;

    /** The user's email */
    email: string;

    /** The user's employee type from the claims */
    employeeType: string;

    /** The user ID claim value, containing the unique Okta ID */
    oktaId: string;

    /** The user's username, which is the sAMAccountName in Okta and is
     * used to link the user to our db
     */
    userName: string;

    /** The list of the user's member claims */
    memberOf: string[];

    /** The office(s) the user is assigned to (maps to
     * Trident IDs) */
    tridentOfficeIds?: number[];

    /** The User trident Agent Id  */
    tridentAgentId?: number;

    /** The list of all permissions, based on the user's assigned role(s)
     * (loaded from the authorization reponse)
     */
    roles?: RolePermission[];

    /** The office(s) the user is associated with (loaded from the authorization reponse) */
    officeIds?: string[];

    /** If an agent, this is the user's agent ID(s) (loaded from the authorization reponse) */
    agentIds?: string[];

    /** If on a sales team, the IDs of the team(s) the user is associated with
     * (loaded from the authorization reponse)
     */
    salesTeamIds?: string[];

    /** The user's ID from the db */
    userId: string;

    /** The user's profile ID */
    profileId?: string;

    /**is the user associated with development(mainly used for agent) */
    isAssociatedWithBuilding?: boolean;
}

/** Object that maps to the authentication call from the API, to
 * get the permissions and other auth data for a user
 */
export interface AuthorizationResponse extends OfficeIds {
    /** The list of all permissions, based on the user's assigned role(s) */
    roles?: RolePermission[];

    /** The user's ID from the db */
    userId: string;

    /** The user's profile ID */
    profileId?: string;

    /** If an agent, this is the user's agent ID(s) */
    agentIds?: string[];

    /** If on a sales team, the IDs of the team(s) the user is associated with */
    salesTeamIds?: string[];

    /** The user's profile ID */
    email: string;

    /** The user's first name */
    firstName: string;

    /** The user's last name */
    lastName: string;

    /** The user's user name */
    userName: string;

    /** The user's Okta ID */
    oktaId: string;
}

/**
 * Maps the user's role to the permissions the role has
 */
export interface RolePermission {
    /** The ID of the role the user is associated with */
    roleId: string;

    /** The listing permissions associated with the role */
    listingPermissions: number;
}
