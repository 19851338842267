import {
    AgentDescription,
    AgentAssistant,
    CustomChipData,
    AgentDetailsForAssistant,
    AgentEditScreenRequiredFields,
} from './agentModels';
import { editFormComponent, getCustomValues } from './agentConstants';
import { phoneNoFormat, validateEmail } from '../../../utils/urlUtils';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';

export const createNeededData = (
    base: CustomFormedData[],
    data: AgentDescription,
): CustomFormedData[] => {
    const newformattedData = base.map((baseData: CustomFormedData) => {
        return {
            title: baseData.title,
            value: baseData.isNum
                ? phoneNoFormat(
                      data[baseData.value as keyof typeof data]
                          ? data[baseData.value as keyof typeof data]?.toString()
                          : '',
                  )
                : data[baseData.value as keyof typeof data],
            isDate: baseData.isDate ? true : false,
            isNum: baseData.isNum ? true : false,
            isLink: baseData.isLink ? true : false,
            isExternal: baseData?.isExternal || false,
            displayText: baseData?.displayText || '',
        };
    });

    return newformattedData;
};

export const getCustomData = (
    data: (LookupInfo | AgentAssistant | AgentDetailsForAssistant)[],
    dataType: number,
): CustomFormedData[] => {
    if (data.length > 0) {
        const customData = data.map((item, index) => {
            return {
                //title stored as empty string since no subtitle are required unless if it is agent assistant where value is displayed in 2 lines so title utilized in that case
                title: dataType === 2 ? getCustomValues(3, item) : '',
                value:
                    getCustomValues(dataType, item) +
                    `${index !== data.length - 1 ? ', ' : ''}`,
                isDate: false,
            };
        });
        return customData;
    }
    return [];
};

export const getSelectedIds = (data: CustomChipData[]): number[] => {
    const ids: number[] = [];
    data.forEach((item) => {
        if (item.selected) {
            ids.push(Number(item.id));
        }
    });
    return ids;
};

export const generateAgentEditScreens = (): string[] => {
    return [
        editFormComponent.description,
        editFormComponent.photo,
        editFormComponent.expertise,
        editFormComponent.biography,
        editFormComponent.video,
        editFormComponent.assistants,
        editFormComponent.selectSales,
        editFormComponent.selectRentals,
        editFormComponent.agentTestimonials,
    ];
};

export const displayErrorForAgentEditScreen = (
    formData: AgentEditScreenRequiredFields,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;
    if (!formData.firstName)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'First Name,',
        };
    if (!formData.lastName)
        result = { isValid: false, errorMessage: result.errorMessage + 'Last Name,' };
    if (!formData.agentTypeId)
        result = { isValid: false, errorMessage: result.errorMessage + 'Agent Type,' };
    if (!formData.tridentOfficeId)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Trident Office,',
        };
    if (!formData.address1)
        result = { isValid: false, errorMessage: result.errorMessage + 'Address1,' };
    if (!formData.city)
        result = { isValid: false, errorMessage: result.errorMessage + 'City,' };
    if (!formData.regionId)
        result = { isValid: false, errorMessage: result.errorMessage + 'State,' };
    if (!formData.zip)
        result = { isValid: false, errorMessage: result.errorMessage + 'Zip,' };
    if (!formData.workPhone)
        result = { isValid: false, errorMessage: result.errorMessage + 'Work Phone,' };
    if (!formData.brandEmail)
        result = { isValid: false, errorMessage: result.errorMessage + 'Brand Email,' };

    if (!result.errorMessage) {
        if (formData.zip.toString().length !== 5)
            result = { isValid: false, errorMessage: 'Zip should be five digits' };
        else if (formData.workPhone.toString().length !== 10)
            result = { isValid: false, errorMessage: 'Work phone should be 10 digits' };
        else if (!validateEmail(formData.brandEmail))
            result = { isValid: false, errorMessage: 'Brand email is not valid' };
        else result = { isValid: true, errorMessage: '' };
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};
