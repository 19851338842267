import { ListingState, ListingData, DatesModel, Image } from './listingModels';
import { PricesModel, RentalTermsModel } from './drawer/screens/prices/priceModel';
import { ShowCaseModel } from './drawer/screens/showcaseWebsite/showcaseWebsiteModels';
import { ListingDetailsData } from './drawer/screens/listingDetails/listingDetailsModels';
import { VirtualTourUrls } from './drawer/screens/virtualTour/virtualTourModels';
import { isEqual, omit, sortBy } from 'lodash';
import { AppThunk } from '../../../app/store';
import { editFormComponent, dealTypeList } from './listingConstants';
import {
    saveAmenities,
    saveListingAmenityDetails,
} from './drawer/screens/listingFeatures/listingFeaturesSlice';

import { setError } from '../../../shared/slices/messaging/messagingSlice';
import {
    saveListingDates,
    saveListingDetails,
    updateListingEditAction,
    updateMainListingData,
    saveRentalPrices,
    saveListingPrices,
    updateListingEditFormName,
    closeListingEditForm,
    fetchListingValidDetails,
    updateListingEditFormNameAndField,
    fetchListingLastUpdated,
} from './listingSlice';
import { updateShowCase } from './drawer/screens/showcaseWebsite/showcaseWebsiteSlice';
import {
    photoEditorForms,
    savePhotoReorder,
} from './drawer/screens/photos/photoEditorSlice';
import { saveVirtualTour } from './drawer/screens/virtualTour/virtualTourSlice';
import { ImageData } from '../../../shared/models/images/sharedModel';
import { updateOwnerInfoState } from './drawer/screens/ownerInfo/ownerInfoSlice';
import { listingTypeDetails } from './listingConstants';
import {
    BuildingFeatures,
    InteriorFeatures,
    LotFeatures,
} from '../../../shared/models/listing/amenityFeatures';
import { amenitySections } from '../../../shared/constants/listing/amenities';
import {
    MlsSave,
    AdditionalMls,
} from '../../../shared/models/additionalMls/additionalMlsModels';
import { saveMlsData } from '../../../shared/slices/additionalMls/additionalMlsSlice';
import {
    validateStatusUpdate,
    validateDealTypeChange,
    displayErrorForListingEditScreen,
} from './listingUtils';
import { getUser } from '../../../shared/auth/authService';
import { refreshActiveTab } from '../listingListSlice';
import { OwnerInfoModel } from '../../../shared/models/ownerInfoModel/ownerInfoModel';
import { findIdFromLookups } from '../../../utils/urlUtils';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../shared/slices/loader/loaderSlice';
import { ListingDetailEvents } from '../../../shared/models/loader/loaderModels';

const isFormDataChanged = (
    currentState:
        | ListingDetailsData
        | ShowCaseModel
        | BuildingFeatures
        | InteriorFeatures
        | OwnerInfoModel
        | VirtualTourUrls
        | DatesModel
        | PricesModel
        | RentalTermsModel
        | LotFeatures
        | AdditionalMls[],
    initialState: ListingData | ShowCaseModel | OwnerInfoModel | AdditionalMls[],
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

const updateMainStateOnImageReorder = (
    imageList: ImageData[],
    stateData: ListingData,
) => {
    const mainStateList: ListingData = JSON.parse(JSON.stringify(stateData));
    imageList.forEach((x) => {
        const findIndex = mainStateList.images.findIndex((d: Image) =>
            d.imageUrl.includes(x.guid),
        );
        if (findIndex !== -1) {
            mainStateList.images[findIndex].orderNo = x.orderNo;
        }
    });
    mainStateList.images = sortBy(mainStateList.images, ['orderNo']);
    return mainStateList;
};

// Gets data from the current listing form open in the side drawer component
// and saves it to the db
export const saveListingEditFormData =
    (state: ListingState, editFormName?: string): AppThunk =>
    (dispatch, getState) => {
        const listingEditFormName = state.editForm?.listingEditFormName;
        let isChanged = true;
        let isValid = true;
        const oldState = state.data;
        const newState = state.editForm?.currentFormData;

        dispatch(addLoadingEvent(ListingDetailEvents.LISTING_SAVE));

        switch (listingEditFormName) {
            case editFormComponent.listingDetails:
                isChanged = isFormDataChanged(newState as ListingDetailsData, oldState);
                if (isChanged) {
                    const result = displayErrorForListingEditScreen(
                        newState as ListingDetailsData,
                        editFormComponent.listingDetails,
                    );
                    isValid = result.isValid;
                    if (isValid) {
                        const updateListingDetailsRequest = omit(
                            newState as ListingDetailsData,
                            ['ownerInfo'],
                        );
                        isFormDataChanged(updateListingDetailsRequest, oldState) &&
                            dispatch(
                                saveListingDetails(
                                    oldState.id,
                                    updateListingDetailsRequest,
                                ),
                            );
                        const oldDealType = findIdFromLookups(
                            oldState.dealType,
                            dealTypeList,
                        );
                        const currentAgents =
                            getState().listing.listing.data.listingAgents;
                        const updatedAgents = validateDealTypeChange(
                            {
                                oldDealType: Number(oldDealType),
                                newDealType: (newState as ListingDetailsData).dealTypeId,
                            },
                            currentAgents,
                        );
                        dispatch(updateMainListingData({ listingAgents: updatedAgents }));

                        /**
                         * to update the content of draft tab when the status of a draft listing is
                         * updated
                         */
                        if (
                            validateStatusUpdate(
                                oldState.listingStatusId,
                                newState as ListingDetailsData,
                            )
                        ) {
                            //make call to refresh the draft list
                            const user = getUser();
                            dispatch(
                                refreshActiveTab(
                                    user,
                                    oldState.id,
                                    oldState.listingStatusId,
                                    (newState as ListingDetailsData).listingStatusId,
                                ),
                            );
                        }

                        // Owner Info API Call
                        const oldOwnerInfo = oldState.ownerInfo || [];
                        const newOwnerInfo =
                            (newState as ListingDetailsData).ownerInfo || [];
                        if (!isEqual(newOwnerInfo, oldOwnerInfo))
                            dispatch(updateOwnerInfoState(oldState.id, newOwnerInfo));
                        dispatch(updateListingEditAction(true));
                    } else {
                        dispatch(setError(result.errorMessage));
                    }
                }
                break;
            case editFormComponent.showCaseWebsite:
                isChanged = isFormDataChanged(
                    newState as ShowCaseModel,
                    state.initialFormState as ShowCaseModel,
                );
                if (isChanged) {
                    dispatch(updateShowCase(newState as ShowCaseModel));
                    const { domainUrl, privateSite, publish } = newState as ShowCaseModel;
                    const domainData = {
                        domainInfo: {
                            domainUrl: domainUrl,
                            privateSite: privateSite,
                            publishedSite: publish,
                        },
                    };
                    dispatch(updateMainListingData(domainData));
                }
                break;
            case amenitySections.building:
                isChanged = isFormDataChanged(newState as BuildingFeatures, oldState);
                if (isChanged) {
                    dispatch(
                        saveListingAmenityDetails(
                            oldState.id,
                            newState as BuildingFeatures,
                            'building',
                        ),
                    );
                    dispatch(updateMainListingData(newState as BuildingFeatures));
                }
                dispatch(saveAmenities(oldState.amenities, oldState.id));
                break;
            case amenitySections.interior:
                isChanged = isFormDataChanged(newState as InteriorFeatures, oldState);
                if (isChanged) {
                    dispatch(
                        saveListingAmenityDetails(
                            oldState.id,
                            newState as InteriorFeatures,
                            'interior',
                        ),
                    );
                    dispatch(updateMainListingData(newState as InteriorFeatures));
                    dispatch(updateListingEditAction(true));
                }

                dispatch(saveAmenities(oldState.amenities, oldState.id));
                break;
            case amenitySections.lot:
                isChanged = isFormDataChanged(newState as LotFeatures, oldState);
                if (isChanged) {
                    dispatch(
                        saveListingAmenityDetails(
                            oldState.id,
                            newState as LotFeatures,
                            'lot',
                        ),
                    );
                    dispatch(updateMainListingData(newState as LotFeatures));
                }
                dispatch(saveAmenities(oldState.amenities, oldState.id));
                break;
            case amenitySections.association:
            case amenitySections.construction:
            case amenitySections.green:
            case amenitySections.outdoor:
            case amenitySections.parking:
            case amenitySections.room:
                dispatch(saveAmenities(oldState.amenities, oldState.id));
                break;
            case editFormComponent.virtualTour:
                isChanged = isFormDataChanged(newState as VirtualTourUrls, oldState);
                if (isChanged)
                    dispatch(saveVirtualTour(oldState.id, newState as VirtualTourUrls));
                break;
            case photoEditorForms.mainPhotos:
                const mainPhotos = getState().listing.photos.allImages.photos;
                dispatch(savePhotoReorder(oldState.id, mainPhotos));
                const mainStateList: ListingData = updateMainStateOnImageReorder(
                    mainPhotos,
                    oldState,
                );
                dispatch(updateMainListingData(mainStateList as ListingData));
                dispatch(updateListingEditAction(true));
                break;
            case photoEditorForms.floorPlan:
                const floorplanImages = getState().listing.photos.allImages.floorplans;
                dispatch(savePhotoReorder(oldState.id, floorplanImages));
                break;
            case photoEditorForms.showcaseHeroImages:
                const showcaseImages = getState().listing.photos.allImages.vanity;
                dispatch(savePhotoReorder(oldState.id, showcaseImages));
                break;
            case photoEditorForms.photographerUploadedImages:
                const photographerImages =
                    getState().listing.photos.allImages.photographers;
                dispatch(savePhotoReorder(oldState.id, photographerImages));
                break;
            case editFormComponent.dates:
                const newDateState = newState as DatesModel;
                isChanged = isFormDataChanged(newDateState, oldState);
                if (isChanged) {
                    isValid = newDateState.isFormValid;
                    if (isValid) {
                        dispatch(saveListingDates(oldState.id, newState as DatesModel));
                        dispatch(updateListingEditAction(true));
                    } else {
                        if (!newDateState.listingDate)
                            dispatch(setError('Listing date is required'));
                        else if (!newDateState.expirationDate)
                            dispatch(setError('Expiration date is required'));
                    }
                }
                break;
            case editFormComponent.prices:
                // Sale Price
                const newSalePrice = newState as PricesModel;
                isChanged = isFormDataChanged(newSalePrice, oldState);
                if (oldState.listingType === listingTypeDetails.sale && isChanged) {
                    const result = displayErrorForListingEditScreen(
                        newSalePrice,
                        editFormComponent.prices,
                    );
                    isValid = result.isValid;
                    if (isValid) dispatch(saveListingPrices(oldState.id, newSalePrice));
                    else dispatch(setError(result.errorMessage));
                }
                // Rental Price
                const newRentalPrice = newState as RentalTermsModel;
                isChanged = isFormDataChanged(newRentalPrice, oldState);
                if (oldState.listingType === listingTypeDetails.rental && isChanged) {
                    dispatch(saveRentalPrices(oldState.id, newRentalPrice));
                }
                break;

            case editFormComponent.additionalMls:
                if (
                    isFormDataChanged(
                        (newState as MlsSave).data,
                        state.initialFormState as AdditionalMls[],
                    )
                ) {
                    dispatch(
                        saveMlsData(
                            (newState as MlsSave).data,
                            (newState as MlsSave).mlsFor,
                            (newState as MlsSave).id,
                        ),
                    );
                }
                break;
        }

        if (isValid) {
            if (editFormName) dispatch(updateListingEditFormName(editFormName));
            else {
                const statusId =
                    (newState as ListingDetailsData)?.listingStatusId ||
                    oldState.listingStatusId;
                dispatch(closeListingEditForm());
                dispatch(
                    updateListingEditFormNameAndField({
                        formOpen: false,
                        editFormName: '',
                    }),
                );
                setTimeout(() => {
                    dispatch(fetchListingValidDetails(oldState.id));
                    dispatch(fetchListingLastUpdated(oldState.id, statusId || 0));
                }, 5000);
            }
        }
        dispatch(removeLoadingEvent(ListingDetailEvents.LISTING_SAVE));
    };
