import { deleteCall, postCall } from '../../../../../../utils/api/apiWrapper';
import { AgentAssistant } from '../../../agentModels';

export function addAgentAssistantApi(
    agentId: string,
    agentAssistant: AgentAssistant,
): Promise<string> {
    return postCall<AgentAssistant, string>(`agent/${agentId}/assistant`, agentAssistant);
}

export function deleteAgentAssistantApi(
    agentId: string,
    agentAssistantId: string,
): Promise<Response> {
    return deleteCall(`agent/${agentId}/assistant/${agentAssistantId}`);
}
