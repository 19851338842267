import { Grid, TextField } from '@material-ui/core';
import { ChangeEvent, FunctionComponent } from 'react';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../shared/models/lookups/lookupsModels';
import { DevelopmentDetailsData } from '../../../developmentDetails/developmentDetailsModel';
import { CreateDevelopmentRequest } from '../createDevelopmentModel';

interface AddressProps {
    formData: CreateDevelopmentRequest | DevelopmentDetailsData;
    handleTextChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
    ) => void;
    handleDropDownChange: (data: LookupInfo, name: string) => void;
    handleNumberChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    states: LookupInfo[];
    setFormDataToPersistInState?: () => void;
    handleFloatChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
}

const Address: FunctionComponent<AddressProps> = ({
    formData,
    handleTextChange,
    handleDropDownChange,
    handleNumberChange,
    states,
    setFormDataToPersistInState,
    handleFloatChange,
}) => {
    return (
        <>
            <Grid item container alignItems="center">
                <FormRow
                    id="address1"
                    label="Address 1*"
                    controlClass="formControlRoot"
                    control={
                        <TextField
                            value={formData.address1 || ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="address1"
                            id="address1"
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    label="Address 2"
                    control={
                        <TextField
                            value={formData.address2 || ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="address2"
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
            <Grid item container alignItems="center">
                <FormRow
                    id="city"
                    label="City*"
                    controlClass="formControlRoot"
                    control={
                        <TextField
                            value={formData.city || ''}
                            onChange={(e) => handleTextChange(e, 80)}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="city"
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    id="state"
                    label="State*"
                    control={
                        <DropDownSearch
                            id={formData.regionId || ''}
                            items={states}
                            name="regionId"
                            handleDropDownChange={handleDropDownChange}
                            handleBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
            <Grid item container alignItems="center">
                <FormRow
                    id="zip"
                    label="Zip*"
                    controlClass="formControlRoot"
                    control={
                        <TextField
                            value={formData.zip || ''}
                            onChange={handleFloatChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            inputProps={{ maxLength: 5 }}
                            name="zip"
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
        </>
    );
};

export default Address;
