import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
    DevelopmentListModel,
    DevelopmentListState,
    DevelopmentListUpdate,
    DevelopmentSearchRequest,
    DevelopmentTableActionType,
    SearchedListingListDevModel,
} from './developmentListModel';
import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import { ListingSearchRequest } from '../../shared/models/listing/commonModel';
import {
    getDevelopmentListApi,
    getListingListApi,
    getOfficeState,
    saveDevelopmentApi,
} from './developmentListApi';
import Logger from '../../utils/logging/logger';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { initialDevelopmentSearch } from './developmentListConst';
import { OfficeDetailsSummary } from '../offices/office/officeModels';
import { loadUserPermissions } from '../listings/advancedSearch/advancedSearchUtils';
import { CreateDevelopmentRequest } from './createDevelopment/create/createDevelopmentModel';
import { ImageDetail } from '../../shared/models/images/sharedModel';
import { v4 as uuidv4 } from 'uuid';
import { entityTypes } from '../../shared/constants/entityTypes';
import { imageTypes } from '../../shared/constants/imageTypes';
import { getDimensions, jsonToFormData } from '../../utils/urlUtils';
import { savePhoto } from '../../shared/api/images/sharedApi';
import { orderBy } from 'lodash';

const developmentListInitialState: DevelopmentListState = {
    data: {
        currentPage: 1,
        recordsPerPage: 20,
        totalRecords: 0,
        results: [],
    },
    isLoading: false,
    searchLoader: false,
    searchedListings: [],
    developmentOfficeSelected: null,
    isSearched: false,
    developmentTableAction: initialDevelopmentSearch,
};

export const developmentListSlice = createSlice({
    name: 'developmentListApp',
    initialState: developmentListInitialState,
    reducers: {
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setSearchLoader: (state, action: PayloadAction<boolean>) => {
            state.searchLoader = action.payload;
        },
        setListing: (state, action: PayloadAction<SearchedListingListDevModel[]>) => {
            return {
                ...state,
                searchedListings: action.payload,
            };
        },
        setDevelopmentList: (
            state,
            action: PayloadAction<PaginationResponse<DevelopmentListModel>>,
        ) => {
            const results = [...state.data.results, ...action.payload.results];
            let developments = {
                ...state.data,
                currentPage: action.payload.currentPage,
                recordsPerPage: action.payload.recordsPerPage,
                totalRecords: action.payload.totalRecords,
            };
            if (state.data.results.length !== 0 || action.payload.results.length !== 0) {
                developments = {
                    ...developments,
                    results:
                        state.data.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                data: developments,
            };
        },
        updateTableAction: (state, action: PayloadAction<DevelopmentTableActionType>) => {
            const finalData = { ...state.developmentTableAction, ...action.payload };
            return {
                ...state,
                developmentTableAction: finalData,
            };
        },
        developmentOfficeSelection: (
            state,
            action: PayloadAction<OfficeDetailsSummary | null>,
        ) => {
            return {
                ...state,
                developmentOfficeSelected: action.payload,
            };
        },
        updateDevelopmentList: (state, action: PayloadAction<DevelopmentListUpdate>) => {
            const { results } = state.data;
            const { developmentId } = action.payload;
            const findIndex = results.findIndex((x) => x.id === developmentId);
            const newValue = {
                ...state.data.results[findIndex],
                ...action.payload,
            };
            state.data.results[findIndex] = newValue;
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            state.isSearched = action.payload;
        },
    },
});

/**
 * function that will fetch the list of development that has been created already
 * @param data
 * @returns
 */
export const fetchDevelopmentList =
    (data: DevelopmentSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const userPermission = loadUserPermissions();
            //remove publish key if it is false
            if (!data.publish) {
                delete data.publish;
            }
            //remove valid key if it is false
            if (!data.validity) {
                delete data.validity;
            }
            const response = await getDevelopmentListApi({
                ...data,
                ...userPermission,
            });
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            )
                dispatch(setDevelopmentList(response));
        } catch (exception) {
            dispatch(setError(`Failed to get development list`));
            Logger.error(`Failed to get development list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function to save the create development in DB and then update the list based on the
 * created one
 * @param data
 * @returns
 */
export const createDevelopment =
    (data: CreateDevelopmentRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await saveDevelopmentApi(data);
            if (response.id !== '') {
                const dimension = await getDimensions(data?.logo as File);
                const image: ImageDetail = {
                    id: uuidv4(),
                    entityId: response.id,
                    entityType: entityTypes.building.value,
                    imageType: imageTypes.buildingLogos,
                    caption: '',
                    floorplancaption: '',
                    size: data?.logo?.size?.toString() || '',
                    width: dimension.width,
                    height: dimension.height,
                    orderNo: 1,
                    mimeType: data?.logo?.type || '',
                    file: data?.logo,
                    shouldValidate: false,
                    allowOnlyOneImage: false,
                    fromImportOrPhotographer: false,
                    tagId: null,
                };
                const imageFormData = jsonToFormData(image);
                await savePhoto(imageFormData);
                dispatch(setSuccess(`Development is created successfully`));
                return response.id;
            }
        } catch (exception) {
            dispatch(setError(`Failed to create a development or upload the logo`));
            Logger.error(
                `Failed to create a development or upload the logo ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function will get the listing list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchListing =
    (listingSearchRequest: ListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await getListingListApi({
                ...listingSearchRequest,
                address1: `"${listingSearchRequest.address1}"`,
            });
            if (response.results.length > 0) {
                dispatch(setListing(orderBy(response.results, 'status', 'asc')));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the listing list`));
            Logger.error(`Failed to fetch the listing list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * function to fetch the state data based on the office that is selected and it will
 * update the state
 * @param officeId
 * @returns
 */
export const fetchOfficeState =
    (officeId: string): AppThunk =>
    async (dispatch) => {
        try {
            if (officeId) {
                const response = await getOfficeState(officeId);
                if (response !== null) {
                    dispatch(developmentOfficeSelection(response));
                }
            }
        } catch (e) {
            Logger.error(`Error fetching listing valid status: ${JSON.stringify(e)}`);
        }
    };

export const {
    updateLoader,
    setSearchLoader,
    setListing,
    setDevelopmentList,
    updateTableAction,
    developmentOfficeSelection,
    setIsSearched,
} = developmentListSlice.actions;

export const developmentList = (state: RootState): DevelopmentListState =>
    state.development.developmentList;

export default developmentListSlice.reducer;
