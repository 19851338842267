import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { DevelopmentDetailsData } from '../../../developmentDetails/developmentDetailsModel';
import { CreateDevelopmentRequest } from '../createDevelopmentModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useTooltipStyle } from '../../../../../shared/component/toolTip/toolTipStyle';

interface FeaturesProps {
    formData: CreateDevelopmentRequest | DevelopmentDetailsData;
    handleRadioButtonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNumberChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isPhone?: boolean,
    ) => void;
    setFormDataToPersistInState?: () => void;
    handleFloatChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
}

const Features: FunctionComponent<FeaturesProps> = ({
    formData,
    handleRadioButtonChange,
    handleNumberChange,
    setFormDataToPersistInState,
    handleFloatChange,
}) => {
    const tooltipClass = useTooltipStyle();
    return (
        <>
            <Grid className="devFeatures" item container md={12} alignItems="center">
                <FormRow
                    label="Interior"
                    control={
                        <TextField
                            value={formData.interiorSize}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                                endAdornment: 'Sq.Ft',
                            }}
                            name="interiorSize"
                            inputProps={{ maxLength: 4 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    label="Outdoor Space"
                    control={
                        <TextField
                            value={formData.exteriorSize}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                                endAdornment: 'Sq.Ft',
                            }}
                            name="exteriorSize"
                            inputProps={{ maxLength: 4 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <span className="exteriorTooltip">
                    <Tooltip
                        title="Usable exterior space for amenities such as terraces, balconies, patios, etc..."
                        classes={{
                            tooltip: tooltipClass.tooltip,
                        }}
                        arrow
                        placement="top"
                    >
                        <span className="icon">
                            <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                        </span>
                    </Tooltip>
                </span>
            </Grid>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    label="Lot Size"
                    control={
                        <TextField
                            value={formData.lotSize}
                            onChange={handleFloatChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="lotSize"
                            inputProps={{ maxLength: 10 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <Grid item md={2} className="headline field-label">
                    Lot Size Type
                </Grid>
                <Grid item md={4}>
                    <RadioGroup
                        className="radioHolder"
                        row
                        name="lotSizeTypeId"
                        value={formData.lotSizeTypeId}
                        onChange={handleRadioButtonChange}
                        onBlur={setFormDataToPersistInState}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio className="checkbox" />}
                            label="Acreage"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio className="checkbox" />}
                            label="Sq. Ft"
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    label="Year Built"
                    control={
                        <TextField
                            value={formData.yearBuilt}
                            onChange={handleNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="yearBuilt"
                            inputProps={{ maxLength: 4 }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
        </>
    );
};

export default Features;
