import { FunctionComponent, useState, Fragment } from 'react';
import { AnalyticsCounts } from '../../../../cmrModels';
import { currencyFormat, total } from '../../../../../../utils/urlUtils';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import WebsiteForm from './WebsiteForm';
import _ from 'lodash';

interface WebsiteListProps {
    analyticData: AnalyticsCounts[];
    otherSites: AnalyticsCounts[];
    addedSites: AnalyticsCounts[];
    deleteItem(id: string | undefined): void;
    cancelClick(): void;
    listingId: string;
    sectionId: number;
    editItem(id: string | undefined): void;
    editId: string;
}

const styles = makeStyles(() => ({
    closeButton: {
        position: 'absolute',
        right: '25px',
        top: '5px',
    },
}));

const WebsiteList: FunctionComponent<WebsiteListProps> = ({
    analyticData,
    otherSites,
    addedSites,
    deleteItem,
    listingId,
    sectionId,
    cancelClick,
    editItem,
    editId,
}) => {
    let sitesData: AnalyticsCounts[] = [];
    if (
        analyticData &&
        analyticData.length > 0 &&
        addedSites &&
        addedSites.length > 0 &&
        otherSites &&
        otherSites.length > 0
    ) {
        const sirSite: AnalyticsCounts | undefined = analyticData.find(
            (c) => c.siteName === 'sothebysrealty.com',
        );
        const showcaseSites = analyticData.filter((a) => a.isShowcase);
        if (sirSite) {
            sitesData.push(sirSite);
        }
        sitesData.push(...showcaseSites);
        const dif = _.differenceWith(analyticData, otherSites, _.isEqual);
        sitesData = [
            ..._.uniq([...sitesData, ..._.orderBy(dif, ['totalViews'], ['desc'])]),
        ] as AnalyticsCounts[];
    } else {
        sitesData = _.differenceWith(analyticData, otherSites, _.isEqual);
    }

    const [otherSiteOpen, setOtherSiteOpen] = useState<boolean>(false);

    const classes = styles();

    const getLinkData = (site: AnalyticsCounts, allowGa = false) => {
        return (
            <div className="data-publications">
                {!site.deepLink ? (
                    `${site.siteName}`
                ) : (
                    <div>
                        <a href={site.deepLink} target="_blank" rel="noreferrer">
                            {allowGa ? (
                                <span onClick={ga}>{site.siteName}</span>
                            ) : (
                                `${site.siteName}`
                            )}
                        </a>
                        {!site.deepLink.endsWith('.com') ? (
                            <span className="siteInfo">(Links to Listing page)</span>
                        ) : (
                            <span className="siteInfo">
                                (Links to Home page - no direct listing link)
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const otherSitesList = (
        <div className="viewGraph otherSites">
            <div className="table-data">
                <div className="data-col-1">
                    <div className="data-publications">All Other Websites</div>
                </div>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => setOtherSiteOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            {otherSites.map((site: AnalyticsCounts, index: number) => (
                <div className="table-data" key={'web' + index}>
                    <div className="data-col-1">{getLinkData(site)}</div>
                    <div className="data-links">{currencyFormat(site.totalViews)}</div>
                </div>
            ))}
        </div>
    );
    const ga = () => {
        googleAnalyticsAction(
            'Change Display',
            'Click',
            'Views on Web Redirection - Builder',
        );
    };

    return (
        <div className="inner-data-container">
            {sitesData && sitesData.length > 0
                ? sitesData.map((views: AnalyticsCounts, index: number) => {
                      return views.id && views.id.length > 0 && !views.listTrac ? (
                          <Fragment key={'editData' + views.id}>
                              {editId === views.id ? (
                                  <WebsiteForm
                                      formEdit={true}
                                      cancelClick={cancelClick}
                                      listingId={listingId}
                                      sectionId={sectionId}
                                      siteData={{
                                          ...views,
                                          listingId: listingId,
                                          isFile: views.logo ? true : false,
                                      }}
                                      addedSites={addedSites}
                                  />
                              ) : (
                                  <div className="table-data" key={'web' + index}>
                                      <div className="data-col-1">
                                          {getLinkData(views, true)}
                                      </div>
                                      <div className="data-links add-icons">
                                          <span>{currencyFormat(views.totalViews)}</span>
                                          <div className="icon-container">
                                              <span className="icon">
                                                  <FontAwesomeIcon
                                                      className="drag"
                                                      icon={faEdit}
                                                      onClick={() => {
                                                          editItem(views.id);
                                                      }}
                                                  />
                                              </span>
                                              <span className="icon">
                                                  <FontAwesomeIcon
                                                      className="drag"
                                                      icon={faTrashAlt}
                                                      onClick={(e) => {
                                                          deleteItem(views.id);
                                                      }}
                                                  />
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              )}
                          </Fragment>
                      ) : (
                          <div className="table-data" key={'web' + index}>
                              <div className="data-col-1">{getLinkData(views, true)}</div>
                              <div
                                  className={
                                      addedSites.length > 0
                                          ? 'data-links add-icons'
                                          : 'data-links'
                                  }
                              >
                                  {currencyFormat(views.totalViews)}
                                  {addedSites.length > 0 && (
                                      <div className="icon-container"></div>
                                  )}
                              </div>
                          </div>
                      );
                  })
                : ''}
            {/* {addedSites.length > 0
                ? addedSites.map((addedSite: AnalyticsCounts, index: number) => (
                      <Fragment key={'editData' + addedSite.id}>
                          {editId === addedSite.id ? (
                              <WebsiteForm
                                  formEdit={true}
                                  cancelClick={cancelClick}
                                  listingId={listingId}
                                  sectionId={sectionId}
                                  siteData={{
                                      ...addedSite,
                                      listingId: listingId,
                                      isFile: addedSite.logo ? true : false,
                                  }}
                                  addedSites={addedSites}
                              />
                          ) : (
                              <div className="table-data" key={'web' + index}>
                                  <div className="data-col-1">
                                      {getLinkData(addedSite, true)}
                                  </div>
                                  <div className="data-links add-icons">
                                      <span>{currencyFormat(addedSite.totalViews)}</span>
                                      <div className="icon-container">
                                          <span className="icon">
                                              <FontAwesomeIcon
                                                  className="drag"
                                                  icon={faEdit}
                                                  onClick={() => {
                                                      editItem(addedSite.id);
                                                  }}
                                              />
                                          </span>
                                          <span className="icon">
                                              <FontAwesomeIcon
                                                  className="drag"
                                                  icon={faTrashAlt}
                                                  onClick={(e) => {
                                                      deleteItem(addedSite.id);
                                                  }}
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          )}
                      </Fragment>
                  ))
                : ''} */}
            {otherSites.length > 0 ? (
                <div className="table-data">
                    <div className="data-col-1">
                        <div className="data-publications">
                            <span>All Other Websites</span>
                            <span className="info-icon">
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    size="sm"
                                    onClick={() => setOtherSiteOpen(true)}
                                />
                            </span>
                        </div>
                    </div>
                    <div
                        className={
                            addedSites.length > 0 ? 'data-links add-icons' : 'data-links'
                        }
                    >
                        {currencyFormat(total(otherSites, 'totalViews'))}{' '}
                        {addedSites.length > 0 && <div className="icon-container"></div>}
                    </div>
                </div>
            ) : (
                ''
            )}
            <Modal
                open={otherSiteOpen}
                onClose={() => setOtherSiteOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {otherSites.length > 0 ? otherSitesList : otherSitesList}
            </Modal>
        </div>
    );
};

export default WebsiteList;
