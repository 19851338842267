import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import {
    UserNotificationState,
    UserNotificationSearchCriteria,
    UserNotificationDetails,
} from './userNotificationModels';
import Logger from '../../../utils/logging/logger';
import { setSuccess, setError } from '../../../shared/slices/messaging/messagingSlice';
import { getNotifications, dismissNotifications } from './userNotificationApi';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';

const initialState: UserNotificationState = {
    showLoader: false,
    notifications: {
        currentPage: 0,
        recordsPerPage: 100,
        totalRecords: 0,
        results: [],
    },
    criteria: {
        currentPage: 1,
        itemsPerPage: 50,
        profileId: '',
        dismissedStatus: false,
    },
};

export const userNotificationSlice = createSlice({
    name: 'userNotifications',
    initialState,
    reducers: {
        updateNotifications: (
            state,
            action: PayloadAction<PaginationResponse<UserNotificationDetails>>,
        ) => {
            const response = action.payload;
            const combinedResults =
                response.currentPage > 1
                    ? [...state.notifications.results, ...response.results]
                    : response.results;
            state.notifications = { ...response, results: combinedResults };
            state.criteria.currentPage = response.currentPage;
        },
        updateLoader(state, action: PayloadAction<boolean>) {
            state.showLoader = action.payload;
        },
        removeDismissedNotification(state, action: PayloadAction<string[]>) {
            const filteredResults = state.notifications?.results.filter(
                (r) => !action.payload.includes(r.id),
            );
            state.notifications.results = filteredResults;
        },
    },
});

export const fetchUserNotifications =
    (criteria: UserNotificationSearchCriteria): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const notifications = await getNotifications(criteria);
            dispatch(updateNotifications(notifications));
        } catch (e) {
            Logger.error('Failed to get user notifications', e);
            dispatch(setError('Failed to get user notifications'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const dismissUserNotifications =
    (ids: string[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            await dismissNotifications(ids);
            dispatch(removeDismissedNotification(ids));
            dispatch(setSuccess('Notification(s) dismissed'));
        } catch (e) {
            Logger.error('Failed to dismiss notification', e);
            dispatch(setError('Failed to dismiss notification'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const { updateNotifications, updateLoader, removeDismissedNotification } =
    userNotificationSlice.actions;

export const userNotifications = (state: RootState): UserNotificationState =>
    state.user.notifications;

export default userNotificationSlice.reducer;
