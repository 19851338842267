import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { showCaseWebsiteStyle } from '../../style';
import GenerateToken from '../generateToken';
import AddIcon from '@material-ui/icons/AddRounded';
import { generateRandomAccessCode, userData } from '../../showcaseWebsiteConstants';
import { ShowCaseModel } from '../../showcaseWebsiteModels';
import { focusElement } from '../../../../../../../../utils/urlUtils';

interface PrivateShowcaseProps {
    handleCheckboxChange(e: ChangeEvent<HTMLInputElement>): void;
    formData: ShowCaseModel;
    updateShowCaseData(): void;
    handleRadioButtonChange(event: ChangeEvent<HTMLInputElement>): void;
    showcaseSiteId: string;
}

const PrivateShowcase: FunctionComponent<PrivateShowcaseProps> = ({
    handleCheckboxChange,
    formData,
    updateShowCaseData,
    handleRadioButtonChange,
    showcaseSiteId,
}) => {
    const classes = showCaseWebsiteStyle();
    const [newAccess, setNewAccess] = useState<boolean>(true);

    const handleNewAccessButton = () => {
        setNewAccess(!newAccess);
    };

    const handleCancel = (id?: string | boolean) => {
        if (typeof id === 'boolean') {
            setNewAccess(id);
        }
    };
    return (
        <>
            <Grid item xs={12} md={12}>
                {/* <Typography className="title">PRIVATE SHOWCASE SITE OPTIONS</Typography> */}
                <Typography className="subTitle">
                    * If selected, users will require access code to access showcase site.
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            classes={{
                                root: classes.checkBox,
                                checked: classes.checked,
                            }}
                            onChange={handleCheckboxChange}
                            name="privateSite"
                            checked={formData.privateSite}
                            onBlur={() => updateShowCaseData()}
                            id="privateSiteCheckbox"
                            onClick={() => focusElement('privateSiteCheckbox')}
                        />
                    }
                    label="Private Showcase Site"
                    className="isPrivate"
                />
            </Grid>
            {formData.privateSite ? (
                <>
                    <Grid item xs={12} md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    classes={{
                                        root: classes.checkBox,
                                        checked: classes.checked,
                                    }}
                                    onChange={handleCheckboxChange}
                                    name="autoGenerateCode"
                                    checked={formData.autoGenerateCode}
                                    onBlur={() => updateShowCaseData()}
                                    id="autoGenerateCodeCheckbox"
                                    onClick={() =>
                                        focusElement('autoGenerateCodeCheckbox')
                                    }
                                />
                            }
                            label="Auto Generate Code"
                            className="isAutoGenerateCode"
                        />
                    </Grid>
                    <Grid item container alignItems="center" className="spacing">
                        <Grid item xs={12} md={2} className="contentHeader">
                            EMAIL PREFERENCE:
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <FormControl>
                                <RadioGroup
                                    className="radioHolder"
                                    row
                                    onChange={handleRadioButtonChange}
                                    name="privateListingAccessEmailOption"
                                    value={formData.privateListingAccessEmailOption}
                                    onBlur={() => updateShowCaseData()}
                                >
                                    <FormControlLabel
                                        value={0}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.checkBox,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label="Do Not Email"
                                        className="showcaseRadio"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.checkBox,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label="Email Lead Agent"
                                        className="showcaseRadio"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.checkBox,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label="Email All Agents"
                                        className="showcaseRadio"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {newAccess ? (
                            <Button
                                startIcon={<AddIcon />}
                                className="generateAccessCodeButton"
                                onClick={handleNewAccessButton}
                            >
                                Generate New Access Code
                            </Button>
                        ) : (
                            <GenerateToken
                                formEdit={false}
                                handleCancel={handleCancel}
                                initialState={{
                                    ...userData[0],
                                    accessCode: generateRandomAccessCode(10),
                                }}
                                showcaseSiteId={showcaseSiteId}
                            />
                        )}
                    </Grid>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default PrivateShowcase;
