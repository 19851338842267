import React, { FunctionComponent } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import { Editor } from '@tinymce/tinymce-react';
import { useAppDispatch, useFormData } from '../../../../../../app/hooks';
import './developmentAdcopyEdit.scss';
import { setCurrentDevelopmentEditFormData } from '../../../developmentDetailsSlice';
import { editFormComponent } from '../../../developmentDetailsConstants';
import RenderRemainingChar from '../../../../../../shared/component/editor/RenderRemainingChar';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import { calculateRemainingChars } from '../../../../../../utils/urlUtils';

const DevelopmentAdcopyEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const apiKeyOfTinyMce = process.env.REACT_APP_TINY_MCE;
    const { formData, handleEditorChange } = useFormData(editFormData.data);
    const size = {
        id: 1,
        name: 'Internet Ad Copy',
        bodyLength: 4000,
    };

    const save = () => {
        dispatch(
            setCurrentDevelopmentEditFormData({
                formData,
                developmentEditFormName: editFormComponent.developmentAdCopy,
            }),
        );
    };

    const checkAndCallPersist = () => {
        const remainingChar = calculateRemainingChars(
            formData.adCopyBody || '',
            size.bodyLength,
        );
        if (remainingChar >= 0) {
            save();
        }
    };

    return (
        <Grid container className="developmentAdCopyEdit">
            <ComponentWithLoader showLoader={false} showOverlay={true}>
                <div className="size-header">
                    <span className="size-title">{size.name.toUpperCase()}</span>
                    <span className="info-text">Body: {size.bodyLength} characters</span>
                </div>

                <FormControl fullWidth>
                    <Editor
                        apiKey={apiKeyOfTinyMce}
                        value={formData.adCopyBody || ''}
                        onEditorChange={(newValue) => {
                            handleEditorChange('adCopyBody', newValue);
                        }}
                        onBlur={checkAndCallPersist}
                        init={{
                            placeholder: 'Ad Copy',
                            height: 240,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            setup: (editor) => {
                                editor.on('PostProcess', function (ed) {
                                    // we are cleaning empty paragraphs
                                    ed.content = ed.content
                                        .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                        .replace(/(<br \/>)/gi, '<br>')
                                        .replace(/(\r\n|\n|\r)/gi, '<br>')
                                        .replace(/(<br>){1,}/g, '<br>');
                                });
                            },
                            toolbar: 'bold | italic',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                        }}
                    ></Editor>
                </FormControl>
                <RenderRemainingChar
                    value={formData.adCopyBody || ''}
                    maxLength={size.bodyLength}
                />
            </ComponentWithLoader>
        </Grid>
    );
};

export default DevelopmentAdcopyEdit;
