import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
    DevelopmentListing,
    DevelopmentListingTable,
    DrawerContainerProps,
} from '../../../developmentDetailsModel';
import { useAppDispatch, useAppSelector, useDebounce } from '../../../../../../app/hooks';
import {
    developmentList,
    fetchListing,
    setListing,
} from '../../../../developmentListSlice';
import {
    fetchUserPermissions,
    getUserRoleIds,
} from '../../../../../../shared/auth/permissions/permissionsService';
import { getUser } from '../../../../../../shared/auth/authService';
import { associatedListingsTableHeadCells } from '../../../developmentDetailsConstants';
import { updateListings } from '../../../developmentDetailsSlice';
import {
    TableColumnCell,
    TableHeadCell,
} from '../../../../../../shared/models/customTable/customTableModels';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import './developmentAssociatedListings.scss';
import _ from 'lodash';
import { ListingSearchRequest } from '../../../../../../shared/models/listing/commonModel';
import CustomTable from '../../../../../../shared/component/customTable/CustomTable';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import MultiSelectWithSearchAutoCompleteListing from '../../../../../../shared/component/materialUI/MultiSelectWithSearchAutoCompleteListing';

const DevelopmentAssociatedListingEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const { data } = editFormData;
    const user = useMemo(() => getUser(), []);
    const { searchLoader, searchedListings } = useAppSelector(developmentList);
    const [listingsToSave, setListingsToSave] = useState<DevelopmentListing[]>([]);
    const [listingSearchTerm, setListingSearchTerm] = useState<string>('');
    const [resetMultiselect, setResetMultiselect] = useState<boolean>(false);
    const debouncedListingSearchTerm: string = useDebounce<string>(
        listingSearchTerm,
        500,
    );
    const [reset, setReset] = useState<number>(1);

    const updatedSearchedListing = _.differenceBy(
        searchedListings,
        data.listings,
        'listingId',
    );

    //to calculate the icon loaded in custom table based on the action type
    const getIcon = (
        listingTableHeadCell: TableHeadCell,
        listing: DevelopmentListing,
    ) => {
        let icon: IconProp | undefined;
        switch (listingTableHeadCell.actionType) {
            case 'delete':
                icon = faTrash;
                break;
            case 'isValid':
                icon = listing.isValid ? faCheck : faXmark;
                break;
            default:
                icon = undefined;
        }
        return icon;
    };

    const formDataForTable = (id: string, assignedListing: DevelopmentListingTable) => {
        if (id === 'businessPurposeTypeId') {
            return assignedListing.businessPurposeTypeId.toString() === '1'
                ? 'Sale'
                : 'Rental';
        } else {
            return assignedListing[id as keyof typeof assignedListing] || '';
        }
    };

    const columns = data.listings?.length
        ? data.listings.map((listing) => {
              return associatedListingsTableHeadCells.map((associatedListings) => {
                  return {
                      column: formDataForTable(associatedListings.id, listing),
                      customIcon: associatedListings.customIcon
                          ? getIcon(associatedListings, listing)
                          : undefined,
                      actionType: associatedListings.customIcon
                          ? associatedListings.actionType
                          : '',
                      isPrice: associatedListings.isPrice,
                  };
              });
          })
        : [];

    useEffect(() => {
        if (debouncedListingSearchTerm !== '') {
            const request: ListingSearchRequest = {
                currentPage: 1,
                itemsPerPage: 30,
                address1: debouncedListingSearchTerm,
                roleIds: getUserRoleIds(user),
                officeIds: fetchUserPermissions(user, 'officeIds'),
                agentIds: fetchUserPermissions(user, 'agentIds'),
            };
            dispatch(fetchListing(request));
        }
    }, [dispatch, debouncedListingSearchTerm, user]);

    /**
     * clear the stage data for agent and listing autocomplete
     */
    const autoCompleteBlur = () => {
        if (searchedListings.length) {
            dispatch(setListing([]));
        }
        setListingSearchTerm('');
        setReset(reset + 1);
    };

    /**
     * Has logic to fetch the list and add it local state
     * @param selectedListing
     */
    const addSelectedListing = (
        selectedListings:
            | {
                  id: string;
                  name: string;
              }[]
            | null,
    ) => {
        if (selectedListings && selectedListings.length > 0) {
            const idArray = _.map(selectedListings, 'id');
            const fullDetails = updatedSearchedListing
                .filter((data) => idArray.includes(data.listingId))
                .map((data) => {
                    return {
                        ...data,
                        id: data.listingId,
                        url: data?.listingSearchImageResult?.imageUrl || '',
                    };
                });
            setListingsToSave(fullDetails);
        }
    };

    /**
     * to delete the listing from the existing list
     * @param tableData
     */
    const customIconAction = (tableData: TableColumnCell) => {
        if (tableData.actionType === 'delete') {
            dispatch(updateListings(data.id, 'delete', tableData.column as string, []));
        }
    };

    /**
     * to save the listing to DB
     */
    const updateListing = () => {
        dispatch(updateListings(data.id, 'add', '', listingsToSave));
        setResetMultiselect(true);
    };

    /** update multiselect state to false based on use input */
    useEffect(() => {
        if (resetMultiselect) {
            setResetMultiselect(false);
        }
    }, [listingSearchTerm]);

    return (
        <Grid container className="developmentAssociatedListingEdit">
            <ComponentWithLoader showLoader={false} showOverlay={true}>
                <Grid container>
                    <Grid item md={2} className="headline field-label">
                        Listing Search
                    </Grid>
                    <Grid item md={10}>
                        <MultiSelectWithSearchAutoCompleteListing
                            results={updatedSearchedListing}
                            reset={reset}
                            handleSelection={addSelectedListing}
                            blurEvent={autoCompleteBlur}
                            searchTerm={listingSearchTerm}
                            setSearchTerm={setListingSearchTerm}
                            from="listing"
                            isLoading={searchLoader}
                            customWidth={true}
                            resetValue={resetMultiselect}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className="marginTop20">
                    <button className="customButton" onClick={updateListing}>
                        Add
                    </button>
                </Grid>
                <Grid container>
                    <div className="title-text">Associated Listings:</div>
                    <Grid
                        item
                        container
                        md={12}
                        className="assignListingToSpotTableContainer"
                    >
                        <ComponentWithLoader
                            showLoader={false}
                            showOverlay={true}
                            styleClass="loaderClass"
                        />
                        <CustomTable
                            tableHeadCells={associatedListingsTableHeadCells}
                            data={columns}
                            customIconAction={customIconAction}
                        ></CustomTable>
                    </Grid>
                </Grid>
            </ComponentWithLoader>
        </Grid>
    );
};

export default DevelopmentAssociatedListingEdit;
