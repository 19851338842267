import { Grid, TextField } from '@material-ui/core';
import { FunctionComponent, useRef } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import { lookups } from '../../../../../../shared/slices/lookups/lookupsSlice';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import { calculateRemainingChars } from '../../../../../../utils/urlUtils';
import { salesTeamListInitialSearchRequest } from '../../../../salesTeamListConstants';
import { fetchSalesTeams } from '../../../../salesTeamListSlice';
import { createSalesTeam } from '../../../salesTeamApi';
import {
    editFormComponent,
    phoneDisplayOptions,
    initialDescriptionData,
} from '../../../salesTeamConstants';
import { DrawerContainerProps } from '../../../salesTeamModels';
import { setCurrentSalesTeamEditFormData } from '../../../salesTeamSlice';
import {
    displayErrorForSalesTeamEditScreen,
    fetchDescFromMainState,
} from '../../../salesTeamUtils';
import './salesAgentDetails.scss';
import Logger from '../../../../../../utils/logging/logger';
import { statuses } from '../../../../../../shared/constants/lookups/lookupsConstants';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../shared/models/lookups/lookupsModels';
import RenderRemainingChar from '../../../../../../shared/component/editor/RenderRemainingChar';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import RadioButtonToggle from '../../../../../../shared/component/formFields/RadioButtonToggle';

const SalesAgentDetails: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { common } = useAppSelector(lookups);
    const { offices } = common as CommonLookups;
    const description = fetchDescFromMainState(editFormData.data, offices);
    // let updatedSalesTeamEditFormData: SalesTeamDescription;
    const historyLocation = useHistory();
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const {
        formData,
        setFormData,
        handleTextChange,
        handleNumberChange,
        handleDropDownSearch,
        handleEditorChange,
        handleRadioButtonChange,
    } = useFormData(description);

    const handleDropDown = (data: LookupInfo, name: string) => {
        if (name === 'officeId')
            setFormData({
                ...formData,
                officeId: data.id,
                office: data.name,
            });
        else
            setFormData({
                ...formData,
                statusId: Number(data.id),
                status: data.name,
            });
    };

    const setFormDataToPersist = () => {
        if (editFormData.data?.id?.length) {
            dispatch(
                setCurrentSalesTeamEditFormData({
                    formData: {
                        ...formData,
                        description:
                            formData.description === '<br>' ? '' : formData.description,
                    },
                    salesTeamEditFormName: editFormComponent.description,
                }),
            );
        }
    };

    const onClear = () => {
        setFormData({ ...initialDescriptionData });
    };

    const onSave = async () => {
        const result = displayErrorForSalesTeamEditScreen({
            name: formData.name,
            email: formData.email,
            office: formData.office || '',
            description: formData.description,
        });
        if (result.isValid) {
            const { id, office, phoneNumberDisplay, ...salesTeamDescription } = formData;
            try {
                const response = await createSalesTeam(salesTeamDescription);
                if (response?.id) {
                    dispatch(setSuccess('SalesTeam created successfully'));
                    dispatch(fetchSalesTeams(salesTeamListInitialSearchRequest));
                    historyLocation.push(
                        `/eng/salesTeam/${response?.id?.replace(/['"]+/g, '')}`,
                    );
                }
                onClear();
            } catch {
                dispatch(setError('Error when creating SalesTeam'));
                Logger.error('Error when creating SalesTeam');
            }
        } else {
            dispatch(setError(result.errorMessage));
        }
    };

    const checkAndCallPersist = () => {
        const remainingChar = calculateRemainingChars(formData.description || '', 3500);
        if (remainingChar >= 0) {
            setFormDataToPersist();
        }
    };

    return (
        <Grid container className="sales-team-detail-desc-section">
            <Grid item container md={12} className="info-text">
                * Indicates Required Fields
            </Grid>
            <Grid item container md={12} alignItems="center" justifyContent="flex-start">
                <>
                    <Grid
                        container
                        item
                        className="section small-fields"
                        md={12}
                        spacing={2}
                    >
                        <>
                            <FormRow
                                label="OFFICE*"
                                control={
                                    <DropDownSearch
                                        id={formData.officeId}
                                        items={offices}
                                        name="officeId"
                                        handleDropDownChange={handleDropDown}
                                        handleBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                            <FormRow
                                label="TEAM NAME*"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.name}
                                        onChange={(e) => handleTextChange(e, 50)}
                                        name="name"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                        <>
                            <FormRow
                                label="EMAIL*"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.email}
                                        onChange={(e) => handleTextChange(e, 255)}
                                        name="email"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                            <FormRow
                                label="SALES TEAM URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.websiteUrl}
                                        onChange={(e) => handleTextChange(e, 255, true)}
                                        name="websiteUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                        <>
                            <FormRow
                                label="MLS"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.mlsNumber}
                                        onChange={(e) => handleTextChange(e, 200)}
                                        name="mlsNumber"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                            <FormRow
                                label="PHONE NUMBER DISPLAY OPTIONS"
                                control={
                                    <DropDownSearch
                                        id={formData.phoneNoDisplayOrder}
                                        items={phoneDisplayOptions}
                                        name="phoneNoDisplayOrder"
                                        handleDropDownChange={handleDropDownSearch}
                                        handleBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                        {formData.phoneNoDisplayOrder.toString() === '3' ? (
                            <>
                                <Grid item md={2}></Grid>
                                <Grid item md={4}></Grid>
                                <FormRow
                                    label="CUSTOM PHONE NUMBER"
                                    control={
                                        <TextField
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'inputField',
                                            }}
                                            value={formData.phone}
                                            onChange={(e) => handleNumberChange(e, true)}
                                            name="phone"
                                            onBlur={setFormDataToPersist}
                                        />
                                    }
                                    controlClass="customHolder"
                                    labelXs={12}
                                    labelSm={12}
                                    labelMd={2}
                                    controlXs={12}
                                    controlSm={12}
                                    controlMd={4}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        <FormRow
                            label="STATUS"
                            control={
                                <DropDownSearch
                                    id={formData.statusId}
                                    items={statuses}
                                    name="statusId"
                                    handleDropDownChange={handleDropDown}
                                    handleBlur={setFormDataToPersist}
                                />
                            }
                            controlClass="customHolder"
                            labelXs={12}
                            labelSm={12}
                            labelMd={2}
                            controlXs={12}
                            controlSm={12}
                            controlMd={4}
                        />
                    </Grid>
                    <Grid container item md={12} spacing={2}>
                        <FormRow
                            label="DESCRIPTION*"
                            control={
                                <Editor
                                    apiKey={process.env.REACT_APP_TINY_MCE}
                                    init={{
                                        placeholder: 'Type here to add description',
                                        height: 600,
                                        statusbar: false,
                                        menubar: false,
                                        plugins: ['link', 'lists', 'paste'],
                                        branding: false,
                                        paste_as_text: true,
                                        setup: (editor) => {
                                            editor.on('PostProcess', function (ed) {
                                                // we are cleaning empty paragraphs
                                                ed.content = ed.content
                                                    .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                                    .replace(/(<br \/>)/gi, '<br>')
                                                    .replace(/(\r\n|\n|\r)/gi, '<br>')
                                                    .replace(/(<br>){1,}/g, '<br>');
                                            });
                                        },
                                        toolbar: 'bold | italic ',
                                        content_style:
                                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                                    }}
                                    value={formData.description || ''}
                                    onInit={(event, editor) =>
                                        (editorRef.current = editor)
                                    }
                                    onEditorChange={(newValue) => {
                                        handleEditorChange('description', newValue);
                                    }}
                                    onBlur={checkAndCallPersist}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot editorInput"
                            labelXs={null}
                            labelSm={null}
                            labelMd={2}
                            controlXs={null}
                            controlSm={null}
                            controlMd={10}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        md={12}
                        justifyContent="flex-end"
                        className="section"
                    >
                        <Grid item md={10}>
                            <RenderRemainingChar
                                value={formData.description}
                                maxLength={3500}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item className="section" md={12} spacing={2}>
                        <>
                            <FormRow
                                label="SHARE PROPERTIES*"
                                control={
                                    <RadioButtonToggle
                                        id={formData.canShareProperties}
                                        name="canShareProperties"
                                        items={[
                                            { id: 'true', name: 'Yes' },
                                            { id: 'false', name: 'No' },
                                        ]}
                                        handleRadioButtonChange={handleRadioButtonChange}
                                        handleBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder fullWidth"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={10}
                            />
                        </>
                    </Grid>
                    <Grid
                        container
                        item
                        className="section  small-fields"
                        md={12}
                        spacing={2}
                    >
                        <>
                            <FormRow
                                label="LINKEDIN URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.linkedInUrl}
                                        onChange={(e) => handleTextChange(e, 510, true)}
                                        name="linkedInUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />

                            <FormRow
                                label="FACEBOOK URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.facebookUrl}
                                        onChange={(e) => handleTextChange(e, 510, true)}
                                        name="facebookUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                        <>
                            <FormRow
                                label="TWITTER X URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.twitterUrl}
                                        onChange={(e) => handleTextChange(e, 510, true)}
                                        name="twitterUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                            <FormRow
                                label="INSTAGRAM URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.instagramUrl}
                                        onChange={(e) => handleTextChange(e, 510, true)}
                                        name="instagramUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                        <>
                            <FormRow
                                label="YOUTUBE URL"
                                control={
                                    <TextField
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'inputField',
                                        }}
                                        value={formData.youtubeUrl}
                                        onChange={(e) => handleTextChange(e, 510, true)}
                                        name="youtubeUrl"
                                        onBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="customHolder"
                                labelXs={12}
                                labelSm={12}
                                labelMd={2}
                                controlXs={12}
                                controlSm={12}
                                controlMd={4}
                            />
                        </>
                    </Grid>
                    {!editFormData.data?.id?.length && (
                        <Grid item container md={12} className="buttons">
                            <button className="customButton button" onClick={onSave}>
                                Save
                            </button>
                            <button className="customButton button" onClick={onClear}>
                                Clear
                            </button>
                        </Grid>
                    )}
                </>
            </Grid>
        </Grid>
    );
};
export default SalesAgentDetails;
