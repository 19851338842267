import { postFormData } from '../../utils/api/apiWrapper';
import { ShowingsUploadResponse, UploadSelectSoldsResponse } from './excelUploadModel';

export function uploadShowingsFile(
    requestData: FormData,
): Promise<ShowingsUploadResponse> {
    return postFormData<ShowingsUploadResponse>(`Showing/import/atlas`, requestData);
}

export function uploadSelectSoldsFile(
    requestData: FormData,
): Promise<UploadSelectSoldsResponse> {
    return postFormData<UploadSelectSoldsResponse>(`selectSold/import`, requestData);
}
