import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { LoaderDetails } from '../../models/loader/loaderModels';

const initialState: LoaderDetails = {
    loadingEvents: [],
    isLoading: false,
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        addLoadingEvent: (state, action: PayloadAction<string>) => {
            const ev = action.payload;
            if (state.loadingEvents.indexOf(ev) < 0) {
                state.loadingEvents.push(ev);
            }
        },
        removeLoadingEvent: (state, action: PayloadAction<string>) => {
            const ev = action.payload;
            const index: number = state.loadingEvents.indexOf(ev);
            if (index > -1) {
                state.loadingEvents.splice(index, 1);
            }
        },
        setCommonLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

export const { addLoadingEvent, removeLoadingEvent, setCommonLoader } =
    loaderSlice.actions;
export const loaderDetails = (state: RootState): LoaderDetails => state.loader;

export default loaderSlice.reducer;
