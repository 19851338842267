import { FunctionComponent } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { LookupInfo } from '../../models/lookups/lookupsModels';
import './dropDownSearch.scss';

interface DropDownSearchProps {
    id: string | number;
    name: string;
    items: LookupInfo[];
    handleDropDownChange: (data: LookupInfo, name: string) => void;
    handleBlur?: () => void;
    disabled?: boolean;
    emitElement?: (e: HTMLSelectElement) => void;
    allowClear?: boolean;
}

const DropDownSearch: FunctionComponent<DropDownSearchProps> = ({
    id,
    name,
    items,
    handleBlur,
    handleDropDownChange,
    disabled,
    emitElement,
    allowClear,
}) => {
    const findItem = () => {
        const value = items.find((item) => item.id.toString() === id?.toString());
        return value ? value : allowClear ? undefined : null;
    };
    const handleOnChange = (value: LookupInfo | null) => {
        if (value) {
            handleDropDownChange(value, name);
        } else if (allowClear && !value) {
            handleDropDownChange({ id: '', name: '' }, name);
        }
    };
    return (
        <Autocomplete
            value={findItem()}
            onChange={(e, v) => handleOnChange(v)}
            id={name}
            options={items}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) =>
                option.id.toString() === value.id.toString()
            }
            onBlur={handleBlur}
            disabled={disabled}
            disableClearable={!allowClear}
            ref={(e) => emitElement && emitElement(e as HTMLSelectElement)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        className: 'searchBox',
                    }}
                />
            )}
            className="customSearchBoxWidth dropDownSearchBox"
        />
    );
};

export default DropDownSearch;
