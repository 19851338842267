import { AgentSelectedToBeBilled } from '../../../../../../shared/models/agentBilling/agentBilling';
import {
    PaginationRequest,
    PaginationResponse,
} from '../../../../../../shared/models/pagination/paginationModels';
import {
    deleteCall,
    getCall,
    postCall,
    postFormData,
    putCallRaw,
    postCallRaw,
} from '../../../../../../utils/api/apiWrapper';
import { ImageDeleteModel } from '../../../../mediaOutlets/component/createAndEdit/createEditMediaOutletModel';
import {
    EditSpotReservationRequest,
    RequestSpotModel,
    SpotReservationRequest,
    SpotReservationResponse,
    Spot,
} from './spotTypeModels';

export function getSpotsApi(
    advertisementId: string,
    officeIds: string[],
): Promise<Spot[]> {
    return postCall<string[], Spot[]>(`Spot/${advertisementId}`, officeIds);
}
export function createSpotsApi(data: FormData): Promise<Spot> {
    return postFormData<Spot>('spot', data);
}
export function deleteSpotTypeImageApi(data: ImageDeleteModel): Promise<Response> {
    return putCallRaw(`spot/file`, data);
}

export function deleteSpotApi(
    advertisementId: string,
    spotTypeId: string,
): Promise<Response> {
    return deleteCall(`Spot/${advertisementId}/${spotTypeId}`);
}

/**handles both create and edit for reserved spot */
export function reservationSpotApi(
    data: SpotReservationRequest | EditSpotReservationRequest,
): Promise<SpotReservationResponse> {
    return postCall<
        SpotReservationRequest | EditSpotReservationRequest,
        SpotReservationResponse
    >(`Spot/reserve`, data);
}

export function getReservedSpotsApi(id: string): Promise<SpotReservationResponse[]> {
    return getCall<SpotReservationResponse[]>(`Spot/reserved/${id}`);
}

export function deleteReservedApi(
    advertisementId: string,
    spotTypeId: string,
    removeListingTo: boolean,
): Promise<Response> {
    return deleteCall(
        `Spot/Reserved/${advertisementId}/${spotTypeId}/${removeListingTo}`,
    );
}

export function fetchIndividualSpotApi(spotId: string): Promise<Spot> {
    return getCall<Spot>(`Spot/${spotId}`);
}

//update the api endpoint after it get created
export function fetchSelectedSpotAgentBillingApi(
    spotId: string,
    agentCostRequest: PaginationRequest,
): Promise<PaginationResponse<AgentSelectedToBeBilled>> {
    return postCall<PaginationRequest, PaginationResponse<AgentSelectedToBeBilled>>(
        `Spot/spotReservationCostList/${spotId}`,
        agentCostRequest,
    );
}

export function saveSelectedSpotAgentBillingApi(
    spotId: string,
    data: AgentSelectedToBeBilled[],
): Promise<Response> {
    return putCallRaw(`Spot/spotReservationCost/${spotId}`, data);
}

export function spotRequestApi(requestData: RequestSpotModel): Promise<Response> {
    return postCallRaw(`Spot/agentSpotRequest`, requestData);
}
