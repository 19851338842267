import React, { FunctionComponent, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useFormData } from '../../../../../app/hooks';
import { Grid, Radio } from '@material-ui/core';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import {
    SearchComponentProps,
    RangeSearch,
    dateRangeSearchTypeConst,
} from '../../advancedSearchModels';
import SearchFieldWrapper from './SearchFieldWrapper';
import { datePickerFormat } from '../../../../../utils/urlUtils';

const DateRangeSearch: FunctionComponent<SearchComponentProps> = ({
    criteria,
    onCriteriaAdd,
}) => {
    const initialState: RangeSearch = { start: '', end: '' };
    const dateRangeId =
        dateRangeSearchTypeConst.find((d) => d.name === 'Date Range')?.id ?? '';

    const { formData, setFormData, handleDateChange } = useFormData(initialState);
    const [selectedCheckboxVals, setSelectedCheckboxVals] = useState<string[]>(
        criteria.values,
    );
    useEffect(() => {
        if (criteria.values?.length) {
            setFormData({
                start: criteria.values[0] || '',
                end: criteria.endValue || '',
            });
        }
    }, [criteria.values, setFormData]);

    const handleCriteriaUpdate = () => {
        onCriteriaAdd({
            ...criteria,
            values: [formData.start || ''],
            endValue: formData.end || '',
        });
    };

    const renderDateRow = (label: string, property: string): JSX.Element => {
        return (
            <FormRow
                label={label}
                showLabel={false}
                control={
                    <DatePicker
                        value={
                            formData[property as keyof typeof formData] !== ''
                                ? moment(
                                      formData[
                                          property as keyof typeof formData
                                      ] as string,
                                  )
                                : undefined
                        }
                        format={datePickerFormat()}
                        onChange={(date: Moment | null) => {
                            handleDateChange(property, date);
                        }}
                        onBlur={handleCriteriaUpdate}
                        name={property}
                        showToday={false}
                        className="datePickerCommon"
                        style={{
                            padding: '5px 10px',
                        }}
                        placeholder={label}
                        disabled={
                            selectedCheckboxVals.includes(dateRangeId) ||
                            criteria.endValue
                                ? false
                                : true
                        }
                    />
                }
                labelMd={12}
                controlMd={6}
                controlClass="formControlRoot"
            />
        );
    };

    /**
     * used to calculate the radio checked status
     * @param id - from the lookup
     * @returns boolean
     */
    const setChecked = (id: string) => {
        if (
            //date range
            id === dateRangeId &&
            //for date range selected date will be date string
            selectedCheckboxVals[0] &&
            selectedCheckboxVals[0].length > 1
        ) {
            return true;
        } else {
            return selectedCheckboxVals.includes(id) ? true : false;
        }
    };

    /**
     * function to handle the radio selection and unselection.
     * @param id - from the lookup
     */
    const handleRadioCriteriaUpdate = (id: string) => {
        setSelectedCheckboxVals([id]);
        if (id !== dateRangeId) {
            setFormData({
                start: '',
                end: '',
            });
            onCriteriaAdd({ ...criteria, endValue: '', values: [id] });
        }
    };

    const renderRow = (label: string, id: string): JSX.Element => {
        return (
            <FormRow
                label={label}
                key={id}
                control={
                    <Radio
                        checked={setChecked(id)}
                        onChange={(e) => {
                            handleRadioCriteriaUpdate(id);
                        }}
                        value={id}
                        name="radio-buttons"
                        className="customRadio"
                        inputProps={{ 'aria-label': '1' }}
                    />
                }
                labelMd={4}
                controlMd={2}
            />
        );
    };

    const component = (): JSX.Element => {
        return (
            <Grid
                item
                container
                className="content"
                alignItems="center"
                spacing={2}
                md={12}
            >
                {dateRangeSearchTypeConst.map((data) => {
                    return renderRow(data.name, data.id);
                })}
                {renderDateRow('Start Date', 'start')}
                {renderDateRow('End Date', 'end')}
            </Grid>
        );
    };

    return <SearchFieldWrapper control={component()} label="" />;
};

export default DateRangeSearch;
