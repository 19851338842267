import { Grid } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useDebounce } from '../../../../../../app/hooks';
import { AgentAssistant, AgentDetails, DrawerContainerProps } from '../../../agentModels';
import {
    addAgentAssistant,
    deleteAgentAssistant,
    getUsers,
    setSearchedUsers,
} from '../../../agentSlice';
import './assistants.scss';
import { UserSearchResponse } from '../../../../../../shared/models/users/userModels';
import SearchAutoComplete from '../../../../../../shared/component/searchAutoComplete';

const Assistants: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { agentDetails, flags } = editFormData;
    const { descriptionDetails, agentAssistants, searchedUsers } =
        agentDetails as AgentDetails;
    const { searchingAssistants } = flags;
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm !== '') dispatch(getUsers(debouncedSearchTerm));
    }, [dispatch, debouncedSearchTerm]);

    const addSelectedAgentAssistant = (selectedAgent: UserSearchResponse | null) => {
        if (selectedAgent) {
            const agentAssistant = {
                agentId: descriptionDetails.agentId,
                brandEmail: selectedAgent.brandEmail,
                userId: selectedAgent.userId,
                firstName: selectedAgent.firstName,
                lastName: selectedAgent.lastName,
                agentProfileId: descriptionDetails.profileId,
                userProfileId: selectedAgent.profileId,
            };
            dispatch(addAgentAssistant(descriptionDetails.agentId, agentAssistant));
            setSearchTerm('');
        }
        dispatch(setSearchedUsers([]));
    };

    const deleteAssistant = (agentAssistantId?: string) => {
        if (agentAssistantId) {
            dispatch(deleteAgentAssistant(descriptionDetails.agentId, agentAssistantId));
        }
    };

    const autoCompleteBlur = () => {
        dispatch(setSearchedUsers([]));
    };

    return (
        <Grid container className="add-assistant-container">
            <Grid
                item
                md={12}
                container
                className="content"
                alignItems="center"
                spacing={2}
            >
                <Grid item sm={2} md={2} className="formControlLabel">
                    EMAIL
                </Grid>
                <Grid item sm={10} md={10}>
                    <SearchAutoComplete
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        results={searchedUsers}
                        userSelected={addSelectedAgentAssistant}
                        blurEvent={autoCompleteBlur}
                        isLoading={searchingAssistants}
                    />
                </Grid>
            </Grid>

            {agentAssistants?.length ? (
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item container md={12} className="formControlLabel">
                        <Grid item md={5} sm={5}>
                            Email
                        </Grid>
                        <Grid item md={5} sm={5}>
                            Name
                        </Grid>
                    </Grid>
                    {agentAssistants.map((assistant: AgentAssistant) => (
                        <Grid item container key={assistant.id} md={12} spacing={2}>
                            <Grid item sm={5} md={5} className="formControlData">
                                {assistant.brandEmail}
                            </Grid>
                            <Grid item sm={5} md={5} className="formControlData">
                                {assistant.firstName} {assistant.lastName}
                            </Grid>
                            <Grid item sm={5} md={2}>
                                <button
                                    className="button customButton"
                                    onClick={() => deleteAssistant(assistant?.id)}
                                >
                                    Delete
                                </button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};
export default Assistants;
