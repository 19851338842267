import moment from 'moment';
import { LookupInfo, UserRoleLookups } from '../../shared/models/lookups/lookupsModels';
import {
    NotificationAssignment,
    NotificationAssignmentDetails,
} from './manage/manageNotificationsModels';
import { UserNotificationDetails } from './userNotifications/userNotificationModels';

/**
 * Compares the supplied date to the current date to return
 * text indicating the length of time between the dates in
 * days, hours, or minutes
 * @param date The date to compare to the current time
 * @returns Text indicating difference in time from current
 * date to supplied date
 */
export const getTimeAgo = (date: Date): string => {
    const notificationDate = moment(date);
    const now = moment(new Date());
    const differenceInMinutes = now.diff(notificationDate, 'minutes');
    const differenceInHours = now.diff(notificationDate, 'hours');
    const differenceInDays = now.diff(notificationDate, 'days');

    if (differenceInDays > 0) {
        return `${differenceInDays} day(s) ago`;
    }
    if (differenceInHours > 0) {
        return `${differenceInHours} hour(s) ago`;
    }
    return `${differenceInMinutes} min(s) ago`;
};

/**
 * Returns copy for the assignment, based on its populated data
 * @param assignment The assignment to render the copy for
 * @param renderEmailFlag Flag indicating if we should render the send email
 * copy (or ignore it)
 * @returns UI-friendly copy describing the assignment
 */
export const renderAssignmentCopy = (
    assignment: NotificationAssignment,
    renderEmailFlag: boolean,
): string => {
    let prefix = '';
    if (assignment.officeName?.length) {
        prefix = `Assigned to office: ${assignment.officeName}`;
    }
    if (assignment.roleName?.length) {
        prefix = `Assigned to role: ${assignment.roleName}`;
    }
    if (assignment.userName?.length) {
        prefix = `Assigned to user: ${assignment.userName}`;
    }

    if (!renderEmailFlag) {
        return prefix;
    }

    const sendEmail = assignment.sendEmail ? 'true' : 'false';
    return `${prefix} | Send email: ${sendEmail}`;
};

/**
 * Maps the roles in the system to lookup objects for binding
 * @param userRoles The roles in the system to map to lookups
 * @returns List of Lookup Info objects to bind to the UI
 */
export const mapRolesToLookups = (userRoles: UserRoleLookups[]): LookupInfo[] => {
    return userRoles.map((f) => {
        return {
            id: f.key,
            name: f.value,
        };
    });
};

/**
 * Gets the list of assignments with the given field populated
 * @param assignments The list of assignments for the notification
 * @param key The property to check to see if it's populated
 * @returns List of assignments with the property populated
 */
export const getSelectedAssignmentsForEntity = (
    assignments: NotificationAssignment[],
    key: keyof NotificationAssignment,
): NotificationAssignment[] => {
    return assignments.filter((a) => ((a[key] as string)?.length || 0) > 0);
};

/**
 * function is used to calculate the redirection on the notification based on the type
 */
export const formNotificationRedirectUrl = (
    notification: UserNotificationDetails,
): string => {
    let link: string;
    switch (notification.notificationId) {
        case 1: //MLS Listing Created
        case 2: //MLS Listing Updated
        case 3: //Listing Status Changed
        case 4: //Listing Publish Flag Changed
        case 5: //Domain Published
        case 8: //Listing Draft Created
        case 9: //Listing Expiration Date Changed
        case 10: //Listing Suppress MLS Flag Turned On
        case 11: //Ad Copy Changed
        case 12: //Listing Nearing Expiration
        case 17: //Listing Invalid
        case 18: //Listing Price Changed
            link = `listing/${notification.entityId}`;
            break;
        case 6: //Employee Created
            link = `user/${notification.entityId}`;
            break;
        case 7: //Agent Created
            link = `agent/${notification.entityId}`;
            break;
        case 13: //Advertisement Nearing Deadline
        case 14: //Advertisement Created
        case 15: //Advertisement Requested By Agent
        case 16: //Spot Booked
        default:
            link = '';
    }
    return link;
};

/**
 * Adds or removes an assignment for an entity
 * @param currentAssignments The current assignments for the entity
 * @param assignment The assignment being added to or remoted from the entity
 * @param added Flag indicating if the assignment is being added (as opposed to removed)
 * @param key The key indicting the ID property on the assignment
 * @returns Updated list of assignments to associate with the entity
 */
export const updateAssignments = (
    currentAssignments: NotificationAssignment[],
    assignment: NotificationAssignment,
    added: boolean,
    key: keyof NotificationAssignment,
): NotificationAssignment[] => {
    let assignments = currentAssignments;

    if (!added) {
        // Remove office assignment for the role or user
        if (assignment.officeId) {
            assignments = assignments.filter(
                (a) =>
                    a[key] !== assignment[key] ||
                    (a[key] === assignment[key] && a.officeId !== assignment.officeId),
            );

            // If removing the last office for the particular entity, add back
            // the assignment with a null office
            if (!assignments.filter((a) => a[key] === assignment[key]).length) {
                assignments.push({
                    ...assignment,
                    officeId: undefined,
                    officeName: undefined,
                });
            }
        } else {
            // Remove all assignments for the role or user with the given email flag
            assignments = assignments.filter(
                (a) =>
                    a[key] !== assignment[key] ||
                    (a[key] === assignment[key] && a.sendEmail !== assignment.sendEmail),
            );
        }
    } else {
        // Get all assignments for the entity (role or user).  If one exists
        // with no office and we're adding an office to it, then replace the existing
        // existing assignment with the new one with the office
        const entityAssignments = assignments.filter((a) => a[key] === assignment[key]);
        const entityAssignmentsForEmail = entityAssignments.filter(
            (a) => a.sendEmail === assignment.sendEmail,
        );

        // If there are no assignments for the entity with the given
        // email flag, add it
        if (!entityAssignments.length || !entityAssignmentsForEmail.length) {
            assignments.push(assignment);
        } else if (assignment.officeId) {
            // If there is 1 assignment for the entity/email combination, and it
            // doesn't have an office, replace it with the new one with the office
            if (
                entityAssignmentsForEmail.length === 1 &&
                !entityAssignmentsForEmail[0].officeId
            ) {
                // Remove all entity assignments with the same email filter, and
                // add the new one that contains the office
                assignments = assignments.filter(
                    (a) =>
                        a[key] !== assignment[key] ||
                        a.sendEmail !== assignment.sendEmail,
                );
            }
            assignments.push(assignment);
        }
    }

    return assignments;
};

/**
 *
 * @param notificationDetails The current notification whose assignments are being edited
 * @param flag The value to update the flag to
 * @param idKey The field name of the ID used to find the correct assignment
 * @param id The ID value of the assignment being edited
 * @param flagKey The field name of the flag being updated
 */
export const updateNotificationAssignmentFlag = (
    notificationDetails: NotificationAssignmentDetails,
    flag: boolean,
    idKey: keyof NotificationAssignment,
    id: string,
    flagKey: keyof NotificationAssignment,
): NotificationAssignmentDetails => {
    const assignments = notificationDetails.assignments.map((a) => {
        return a[idKey] === id ? { ...a, [flagKey]: flag } : a;
    });
    notificationDetails.assignments = assignments;
    return notificationDetails;
};
