import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import React, { ChangeEvent, FunctionComponent, useRef } from 'react';
import { focusElement } from '../../../../../../../../utils/urlUtils';
import { ShowCaseModel } from '../../showcaseWebsiteModels';
import { showCaseWebsiteStyle } from '../../style';
import { Editor as TinyMCEEditor } from 'tinymce';
interface SiteInfoProps {
    handleCheckboxChange(e: ChangeEvent<HTMLInputElement>): void;
    formData: ShowCaseModel;
    updateShowCaseData(): void;
    handleRadioButtonChange(event: ChangeEvent<HTMLInputElement>): void;
    handleEditorChange: (
        name: string,
        value: string,
        maxLength?: number | undefined,
    ) => void;
}

const SiteInfo: FunctionComponent<SiteInfoProps> = ({
    handleCheckboxChange,
    formData,
    updateShowCaseData,
    handleRadioButtonChange,
    handleEditorChange,
}) => {
    const classes = showCaseWebsiteStyle();
    const editorRefHeadline = useRef<TinyMCEEditor | null>(null);
    const editorRefSubHeadline = useRef<TinyMCEEditor | null>(null);

    return (
        <>
            <Grid
                className="showPrice"
                item
                container
                xs={12}
                md={12}
                justifyContent="flex-end"
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            classes={{
                                root: classes.checkBox,
                                checked: classes.checked,
                            }}
                            checked={formData.showPrice}
                            onChange={handleCheckboxChange}
                            name="showPrice"
                            onBlur={() => updateShowCaseData()}
                            id="showPriceCheckbox"
                            onClick={() => focusElement('showPriceCheckbox')}
                        />
                    }
                    label="Show Price"
                    className="showPriceLabel"
                />
            </Grid>

            <Grid item container className="spacing">
                <Grid item xs={12} md={4} className="contentHeader">
                    HEADLINE
                </Grid>
                <Grid item xs={12} md={8}>
                    {/* <TextField
                        value={formData?.headline || ''}
                        className="inputFields largeText"
                        onChange={(e) => handleTextChange(e, 500)}
                        name="headline"
                        InputProps={{
                            disableUnderline: true,
                            autoComplete: 'off',
                        }}
                        multiline
                        onBlur={updateShowCaseData}
                    /> */}

                    <Editor
                        apiKey={process.env.REACT_APP_TINY_MCE}
                        init={{
                            placeholder: 'Type here to add notes.',
                            height: 100,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            toolbar: 'undo | redo',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline}',
                        }}
                        value={formData?.headline || ''}
                        onInit={(event, editor) => (editorRefHeadline.current = editor)}
                        onEditorChange={(newValue) => {
                            handleEditorChange('headline', newValue, 500);
                        }}
                        onBlur={() => updateShowCaseData()}
                    />

                    <Typography className="infoText">
                        Maximum Character Count: 500
                        <br />
                        Use Shift + Enter to add a line break.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container className="spacing">
                <Grid item xs={12} md={4} className="contentHeader">
                    SUB-HEADLINE
                </Grid>
                <Grid item xs={12} md={8}>
                    {/* <TextField
                        value={formData.subheadline ? formData.subheadline : ''}
                        className="inputFields largeText"
                        onChange={(e) => handleTextChange(e, 500)}
                        name="subheadline"
                        InputProps={{
                            disableUnderline: true,
                            autoComplete: 'off',
                        }}
                        multiline
                        onBlur={updateShowCaseData}
                    /> */}
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_MCE}
                        init={{
                            placeholder: 'Type here to add notes.',
                            height: 100,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            toolbar: 'undo | redo',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline}',
                        }}
                        value={formData.subheadline ? formData.subheadline : ''}
                        onInit={(event, editor) =>
                            (editorRefSubHeadline.current = editor)
                        }
                        onEditorChange={(newValue) => {
                            handleEditorChange('subheadline', newValue, 500);
                        }}
                        onBlur={() => updateShowCaseData()}
                    />
                    <Typography className="infoText">
                        Maximum Character Count: 500
                        <br />
                        Use Shift + Enter to add a line break.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container alignItems="baseline" className="spacing">
                <Grid item xs={12} md={4} className="contentHeader">
                    PROPERTY DETAIL OPTIONS
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl>
                        <RadioGroup
                            className="radioHolder"
                            row
                            onChange={handleRadioButtonChange}
                            name="displayOptions"
                            value={formData.displayOptions}
                            onBlur={() => updateShowCaseData()}
                        >
                            <FormControlLabel
                                value={1}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.checkBox,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label="Show Features & Amenities"
                                className="showcaseRadio"
                            />
                            <FormControlLabel
                                value={2}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.checkBox,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                disabled={
                                    formData.customTabs && formData.customTabs.length > 0
                                        ? false
                                        : true
                                }
                                label="Show Custom Tabs"
                                className="showcaseRadio"
                            />
                            <FormControlLabel
                                value={3}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.checkBox,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label="Do Not Show Details"
                                className="showcaseRadio"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default SiteInfo;
