import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import {
    getAgentsForAssistantApi,
    getUserDetailsApi,
    updateUserDetailsApi,
    saveDashRoleApi,
    getUserDashRolesApi,
    deleteDashRoleApi,
    saveConciergeUserLoginApi,
} from './userDetailsApi';
import { editFormComponent, initialUserDetails } from './userDetailsConstants';
import {
    UserDetails,
    UserDetailsState,
    DashRoleForm,
    UserDashRole,
    UserAuditSaveModel,
} from './userDetailsModels';
import lodash from 'lodash';
import { findIdFromLookups, findNameFromLookups } from '../../../utils/urlUtils';
import { CommonLookups } from '../../../shared/models/lookups/lookupsModels';
import { updateSingleUserDetailsFromList } from '../userListSlice';
import { AgentDetailsForAssistant } from '../../agents/agentDashboard/agentModels';
import { statuses } from '../../../shared/constants/lookups/lookupsConstants';
import Logger from '../../../utils/logging/logger';
import { displayErrorForUserEditScreen } from './userDetailsUtils';

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserDetails,
    reducers: {
        /* To update the flags */
        updateFlagStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                flags: { ...state.flags, isSiteLoading: action.payload },
            };
        },
        /* Save user details reducer */
        saveUserDetails: (
            state,
            action: PayloadAction<UserDetails | { agents: AgentDetailsForAssistant[] }>,
        ) => {
            return {
                ...state,
                details: { ...state.details, ...action.payload },
            };
        },
        /* User details edit form related reducers */
        updateEditFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                userEditFormName: action.payload,
            };
        },
        setCurrentUserEditFormData: (
            state,
            action: PayloadAction<{
                formData: UserDetails;
                userEditFormName: string;
            }>,
        ) => {
            return {
                ...state,
                editForm: {
                    ...state.editForm,
                    formOpen: state.editForm?.formOpen || false,
                    userEditFormName: action.payload.userEditFormName,
                    currentFormData: action.payload.formData,
                },
            };
        },
        addDashRole: (
            state,
            action: PayloadAction<{
                dashRole: UserDashRole;
            }>,
        ) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    userDashRoles: [
                        ...state.details.userDashRoles,
                        action.payload.dashRole,
                    ],
                },
            };
        },
        removeDashRole: (
            state,
            action: PayloadAction<{
                id: string;
            }>,
        ) => {
            const updatedDashRoles = state.details.userDashRoles.filter(
                (role) => role.id !== action.payload.id,
            );
            return {
                ...state,
                details: {
                    ...state.details,
                    userDashRoles: updatedDashRoles,
                },
            };
        },
        closeEditForm: (state, action: PayloadAction) => {
            state.editForm = {
                formOpen: false,
                userEditFormName: '',
            };
        },
        resetUserDetailsState: (state) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    ...initialUserDetails.details,
                },
                flags: {
                    ...state.flags,
                    ...initialUserDetails.flags,
                },
            };
        },
    },
});

/* GET, PUT User details redux thunk */

export const getUserDetails =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus(true));
            const response = await getUserDetailsApi(userId);
            if (response) {
                dispatch(saveUserDetails(response));
            } else {
                dispatch(setError(`Failed to get user details`));
            }
        } catch (e) {
            dispatch(setError(`Error occured while updating the user details`));
        } finally {
            dispatch(updateFlagStatus(false));
        }
    };

export const saveUserDetailsThunk =
    (userId: string, userDetails: UserDetails): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { common } = getState().lookups;
            const { salutations } = common as CommonLookups;
            const updatedUserDetails = {
                ...userDetails,
                salutation: findNameFromLookups(userDetails.salutation, salutations),
                status: findNameFromLookups(userDetails.status, statuses),
            };
            const response = await updateUserDetailsApi(userId, updatedUserDetails);
            if (response) {
                dispatch(saveUserDetails(updatedUserDetails));
                dispatch(
                    updateSingleUserDetailsFromList({
                        userId: userId,
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                    }),
                );
                dispatch(setSuccess('Saved User Details Successfully'));
            } else {
                dispatch(setError('Failed to save the user details'));
            }
        } catch (e) {
            dispatch(setError('Error occured while updating the user details'));
        }
    };

export const getAgentsForAssistant =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateFlagStatus(true));
            const response = await getAgentsForAssistantApi(userId);
            if (response.length >= 0) {
                dispatch(saveUserDetails({ agents: response }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the agent details for assistant`));
        } finally {
            dispatch(updateFlagStatus(false));
        }
    };

/* User details edit form related redux thunks*/

export const saveUserEditFormData =
    (state: UserDetailsState, editFormName?: string): AppThunk =>
    async (dispatch, getState) => {
        let isChanged = true;
        let isValid = true;
        if (state.editForm?.currentFormData) {
            const oldState = state.details;
            const newState = state.editForm?.currentFormData as UserDetails;
            switch (state.editForm?.userEditFormName) {
                case editFormComponent.identity:
                    const { common } = getState().lookups;
                    const { salutations, statuses } = common as CommonLookups;
                    const {
                        salutation,
                        firstName,
                        lastName,
                        preferredFirstName,
                        email,
                        title,
                        userName,
                    } = newState;
                    const result = displayErrorForUserEditScreen({
                        salutation,
                        firstName,
                        lastName,
                        preferredFirstName,
                        email,
                        title,
                        userName,
                    });
                    isChanged = isUserEditFormDataChanged(newState, {
                        ...oldState,
                        salutation: findIdFromLookups(oldState.salutation, salutations),
                        status: findIdFromLookups(oldState.status, statuses),
                    });
                    if (isChanged) {
                        isValid = result.isValid;
                        if (isValid) {
                            dispatch(saveUserDetailsThunk(oldState.id, newState));
                        } else {
                            dispatch(setError(result.errorMessage));
                        }
                    }
                    break;
                case editFormComponent.officeAccess:
                case editFormComponent.roles:
                    dispatch(saveUserDetailsThunk(oldState.id, newState));
                    break;
            }
        }
        if (isValid) {
            if (editFormName) dispatch(updateEditFormName(editFormName));
            else dispatch(closeEditForm());
        }
    };

export const saveDashRole =
    (request: DashRoleForm): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveDashRoleApi(request);
            if (response) {
                dispatch(addDashRole({ dashRole: { ...request, id: response } }));
                dispatch(setSuccess('Saved user dash role'));
            } else {
                dispatch(setError('Failed to save user dash role'));
            }
        } catch (e) {
            dispatch(setError('Error occured while saving user dash role'));
        }
    };

export const deleteDashRole =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteDashRoleApi(id);
            if (response.status === 200 || response.status === 204) {
                dispatch(removeDashRole({ id: id }));
                dispatch(setSuccess('Removed user dash role'));
            } else {
                dispatch(setError('Failed to remove user dash role'));
            }
        } catch (e) {
            dispatch(setError('Error occured while removing user dash role'));
        }
    };

export const getUserDashRoles =
    (userId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await getUserDashRolesApi(userId);
            const userDetails = getState().user.details.details;
            if (response.length) {
                dispatch(saveUserDetails({ ...userDetails, userDashRoles: response }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve user dash roles`));
        }
    };

const isUserEditFormDataChanged = (
    currentState: UserDetails,
    initialState: UserDetails,
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !lodash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

export const saveConciergeUserLogin =
    (request: UserAuditSaveModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveConciergeUserLoginApi(request);
            if (response.status === 204) {
            }
        } catch (exception) {
            Logger.error('Failed to save concierge user login details' + exception);
            dispatch(setError(`Failed to save concierge user login details`));
        } finally {
        }
    };

export const {
    updateFlagStatus,
    saveUserDetails,
    updateEditFormName,
    setCurrentUserEditFormData,
    closeEditForm,
    addDashRole,
    removeDashRole,
    resetUserDetailsState,
} = userSlice.actions;
export const userDetails = (state: RootState): UserDetailsState => state.user.details;
export default userSlice.reducer;
