import { getCall, postCall } from '../../../../../../utils/api/apiWrapper';
import { OfficeSettings } from './officeSettingsModels';

/**
 * Saves settings for the given office
 * @param id The id of the office to save settings for
 * @param settings The settings to persist for the office
 * @returns The office's settings
 */
export function saveOfficeSettings(
    id: string,
    settings: OfficeSettings,
): Promise<OfficeSettings> {
    const url = `office/${id}/settings`;
    return postCall<OfficeSettings, OfficeSettings>(url, settings);
}

/**
 * Gets the settings for the given office
 * @param id The id of the office to get settings for
 * @returns The office's settings
 */
export function getOfficeSettings(id: string): Promise<OfficeSettings> {
    const url = `office/${id}/settings`;
    return getCall<OfficeSettings>(url);
}
