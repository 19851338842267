import { FunctionComponent } from 'react';
import { Showing } from '../../../../features/cmr/cmrModels';
import FormTemplate from './features/FormTemplate';
import ListTemplate from './features/ListTemplate';

interface ShowingsTemplateProps {
    useGa: boolean;
    showings: Showing[] | null;
    activeEditId: string;
    deleteIt(id: string): void;
    editClickHandler(currentEditId: string): void;
    initialState: Showing;
    handleSaveClick(showingData: Showing): void;
    expanded?: boolean;
    isCmr?: boolean;
}

const ShowingsTemplate: FunctionComponent<ShowingsTemplateProps> = ({
    useGa,
    showings,
    activeEditId,
    deleteIt,
    editClickHandler,
    initialState,
    handleSaveClick,
    expanded,
    isCmr,
}) => {
    return (
        <div className="data-container">
            <FormTemplate
                useGa={useGa}
                formEdit={false}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
                expanded={expanded}
                isCmr={isCmr}
            />
            <div className="table-heading">
                <div className="date heading-text">date</div>
                <div className="buyer heading-text">buyer</div>
                <div className="location heading-text">Buyer Agents</div>
                <div className="notes heading-text">Number of Showings</div>
                <div className="notes heading-text">notes / feedback</div>
                <div className="empty heading-text"></div>
            </div>
            <div className="inner-data-container">
                {showings && showings.length > 0
                    ? showings.map((showing: Showing) => (
                          <div key={showing.id} className="table-data">
                              {activeEditId === showing.id ? (
                                  <FormTemplate
                                      useGa={useGa}
                                      showingData={showing}
                                      editClickHandler={editClickHandler}
                                      formEdit={true}
                                      initialState={initialState}
                                      handleSaveClick={handleSaveClick}
                                      isCmr={isCmr}
                                  />
                              ) : (
                                  <ListTemplate
                                      useGa={useGa}
                                      showing={showing}
                                      deleteIt={deleteIt}
                                      editClickHandler={editClickHandler}
                                  />
                              )}
                          </div>
                      ))
                    : ''}
            </div>
        </div>
    );
};

export default ShowingsTemplate;
