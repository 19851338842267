import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { AgentDescriptionInternetEmailProps } from '../descriptionModels';

const InternetEmail: FunctionComponent<AgentDescriptionInternetEmailProps> = ({
    formData,
    renderField,
}) => {
    return (
        <div className="section">
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('BRAND EMAIL*', 'brandEmail', formData.brandEmail !== '')}
                {renderField('PERSONAL EMAIL', 'personalEmail')}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('ALTERNATE EMAIL', 'alternateEmail')}
                {renderField(
                    'SOTHEBYSREALTY',
                    'sothebysRealtyEmail',
                    formData.sothebysRealtyEmail !== '',
                )}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('WEBSITE URL', 'websiteUrl', false, false, true)}
                {renderField('LINKEDIN URL', 'linkedInUrl', false, false, true)}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('FACEBOOK URL', 'facebookUrl', false, false, true)}
                {renderField('TWITTER X URL', 'twitterUrl', false, false, true)}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('INSTAGRAM URL', 'instagramUrl', false, false, true)}
                {renderField('YOUTUBE URL', 'youtubeUrl', false, false, true)}
            </Grid>
        </div>
    );
};

export default InternetEmail;
