import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    AdditionalMls,
    AdditionalMlsState,
    AdditionalMlsValue,
} from '../../models/additionalMls/additionalMlsModels';
import {
    fetchAdditionalMls,
    fetchMlsValues,
    updateMlsData,
    addMls,
    updateMls,
} from '../../api/additionalMls/additionalMlsApi';
import { setError, setSuccess } from '../../slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import { setInitialFormState } from '../../../features/listings/listing/listingSlice';
import { mlsFor } from '../../constants/additionalMls/mlsConstants';
import { setAgentInitialFormState } from '../../../features/agents/agentDashboard/agentSlice';

const initialMlsState: AdditionalMlsState = {
    additionalMls: [],
    additionalMlsValues: [],
    isData: false,
};

const mlsSlice = createSlice({
    name: 'mlsData',
    initialState: initialMlsState,
    reducers: {
        updateAdditionalMls: (state, action: PayloadAction<AdditionalMls[]>) => {
            return {
                ...state,
                additionalMls: [...action.payload],
            };
        },
        updateAdditionalMlsValues: (
            state,
            action: PayloadAction<AdditionalMlsValue[]>,
        ) => {
            return {
                ...state,
                additionalMlsValues: [...action.payload],
            };
        },
        resetAdditionalMlsState: (state) => {
            return {
                ...state,
                ...initialMlsState,
            };
        },
        setFlag: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isData: action.payload,
            };
        },
    },
});

const getUpdatedMlsData = (
    additionalMlsData: AdditionalMls[],
    mlsValues: AdditionalMlsValue[],
) => {
    return additionalMlsData.map((mls) => {
        const mlsItem = mlsValues.find(
            (item) => item.officeAdditionalMlsId === mls.officeAdditionalMlsId,
        );
        return { ...mls, mlsNumber: mlsItem ? mlsItem.mlsNumber : null };
    });
};

export const getAdditionalMls =
    (officeId: string, propertyId?: string, id?: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await fetchAdditionalMls(officeId);
            if (response.length) {
                if (propertyId && id) {
                    const valResponse = await fetchMlsValues(propertyId, id);
                    if (valResponse) {
                        const updatedMls = getUpdatedMlsData(response, valResponse);
                        dispatch(updateAdditionalMls(updatedMls));
                        dispatch(updateAdditionalMlsValues(valResponse));
                        if (propertyId === mlsFor.listing) {
                            dispatch(
                                setInitialFormState({
                                    initialData: updatedMls.filter((mls) => mls.active),
                                }),
                            );
                        } else if (propertyId === mlsFor.agent) {
                            dispatch(
                                setAgentInitialFormState({
                                    initialData: updatedMls.filter((mls) => mls.active),
                                }),
                            );
                        }
                    }
                } else {
                    dispatch(updateAdditionalMls(response));
                }
            }
        } catch (e) {
            Logger.error('Error getting additional mls');
            dispatch(setError('Error getting additional mls ' + officeId));
        } finally {
            dispatch(setFlag(true));
        }
    };

export const updateOfficeMls =
    (officeMls: AdditionalMls): AppThunk =>
    async (dispatch, getState) => {
        try {
            let updatedData = officeMls;
            if (officeMls.officeAdditionalMlsId.length > 0) {
                const response = await updateMls(officeMls);
                if (response.officeAdditionalMlsId.length) {
                    updatedData = response;
                } else {
                    throw Error('Failed to update mls');
                }
            } else {
                const response = await addMls({
                    mlsName: officeMls.mlsName,
                    mlsNumber: officeMls.mlsName,
                    active: officeMls.active,
                    officeId: officeMls.officeId,
                });
                if (response.length) {
                    updatedData = { ...officeMls, officeAdditionalMlsId: response };
                } else {
                    throw Error('Failed to add mls');
                }
            }
            const currentMlsData = getState().additionalMls.additionalMls;
            const updatedMls =
                officeMls.officeAdditionalMlsId.length > 0
                    ? currentMlsData.map((item) =>
                          item.officeAdditionalMlsId === updatedData.officeAdditionalMlsId
                              ? { ...item, ...updatedData }
                              : item,
                      )
                    : [...currentMlsData, updatedData];
            dispatch(updateAdditionalMls(updatedMls));
            dispatch(
                setSuccess(
                    `Mls ${
                        officeMls.officeAdditionalMlsId.length > 0
                            ? officeMls.active
                                ? 'updated'
                                : 'deleted'
                            : 'saved'
                    } successfully`,
                ),
            );
        } catch (e) {
            Logger.error(
                `Error ${
                    officeMls.officeAdditionalMlsId.length > 0
                        ? officeMls.active
                            ? 'updating'
                            : 'deleting'
                        : 'saving'
                } mls`,
            );
            dispatch(
                setError(
                    `Error ${
                        officeMls.officeAdditionalMlsId.length > 0
                            ? officeMls.active
                                ? 'updating'
                                : 'deleting'
                            : 'saving'
                    } mls ` + officeMls.officeId,
                ),
            );
        }
    };

export const saveMlsData =
    (
        request: AdditionalMls[],
        propertyId: string,
        id: string,
        profileId?: string,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await updateMlsData(request, propertyId, id, profileId);
            const currentMlsData = getState().additionalMls.additionalMls;
            if (response) {
                const updatedMls = getUpdatedMlsData(currentMlsData, response);
                dispatch(updateAdditionalMls(updatedMls));
                dispatch(updateAdditionalMlsValues(response));
                if (propertyId === mlsFor.listing) {
                    dispatch(
                        setInitialFormState({
                            initialData: updatedMls.filter((mls) => mls.active),
                        }),
                    );
                } else if (propertyId === mlsFor.agent) {
                    dispatch(
                        setAgentInitialFormState({
                            initialData: updatedMls.filter((mls) => mls.active),
                        }),
                    );
                }
                dispatch(setSuccess('Saved Additional MLS'));
            }
        } catch (e) {
            Logger.error('Error saving additional mls');
            dispatch(setError('Error saving additional mls ' + id));
        }
    };

export const {
    updateAdditionalMls,
    updateAdditionalMlsValues,
    resetAdditionalMlsState,
    setFlag,
} = mlsSlice.actions;
export const mlsSliceState = (state: RootState): AdditionalMlsState =>
    state.additionalMls;

export default mlsSlice.reducer;
