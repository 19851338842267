import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { getUser } from '../../../shared/auth/authService';
import {
    fetchUserPermissions,
    getUserRoleIds,
    userIsInRole,
} from '../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../shared/constants/user/roles';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import { IndividualUpdateModel } from '../advertisementListModels';
import {
    ImageDeleteModel,
    MediaOutletCreateEditModel,
} from './component/createAndEdit/createEditMediaOutletModel';
import {
    createEditMediaOutlets,
    deactivateMediaOutletApi,
    deleteMediaOutletImageApi,
    getMediaOutletFromApi,
    getMODetails,
} from './mediaOutletListApi';

import {
    ActionType,
    MediaOutletList,
    MediaOutletSearchRequest,
    MediaOutletStateInterface,
} from './mediaOutletListModels';
import { MediaOutletFileType } from './mediaOutletListConstants';
import { deleteAdvertisementWithMoId } from '../advertisementListSlice';
import loDash from 'lodash';

const MediaOutletState: MediaOutletStateInterface = {
    mediaOutletList: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    tableAction: {
        name: '',
        statusId: 1,
        sortDirection: 'asc',
        sortColumn: 'Name',
        currentPage: 1,
    },
    isLoading: true,
    individualMO: null,
    isSearched: false,
};

export const mediaOutletSlice = createSlice({
    name: 'mediaOutlet',
    initialState: MediaOutletState,
    reducers: {
        getMOList: (
            state,
            action: PayloadAction<PaginationResponse<MediaOutletList>>,
        ) => {
            const results = [...state.mediaOutletList.results, ...action.payload.results];
            let mediaOutletList = {
                ...state.mediaOutletList,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (
                state.mediaOutletList.results.length !== 0 ||
                action.payload.results.length !== 0
            ) {
                mediaOutletList = {
                    ...mediaOutletList,
                    results:
                        state.mediaOutletList.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                mediaOutletList: mediaOutletList,
            };
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        removeDeletedMO: (state, action: PayloadAction<string>) => {
            const updatedList = state.mediaOutletList.results.filter(
                (mediaOutlet: MediaOutletList) => {
                    return mediaOutlet.id !== action.payload;
                },
            );
            const totalRecords = state.mediaOutletList.totalRecords;
            return {
                ...state,
                mediaOutletList: {
                    ...state.mediaOutletList,
                    results: updatedList,
                    totalRecords: totalRecords - 1,
                },
            };
        },
        updateIndividualMO: (
            state,
            action: PayloadAction<
                | IndividualUpdateModel<ImageDeleteModel>
                | IndividualUpdateModel<MediaOutletList>
            >,
        ) => {
            //make single function work for both image delete and full media outlet update
            //based on from image delete and edit is splitted
            const findIndex = state.mediaOutletList.results.findIndex(
                (x) => x.id === action.payload.data.id,
            );
            if (action.payload.from && action.payload.from === 'imageDelete') {
                const imageDelete = action.payload.data as ImageDeleteModel;
                if (state.individualMO) {
                    if (MediaOutletFileType.Image === imageDelete.fileType) {
                        //to update image
                        state.mediaOutletList.results[findIndex].image = '';
                        state.individualMO.image = '';
                    } else if (MediaOutletFileType.MediaKit === imageDelete.fileType) {
                        //to update media kit
                        state.mediaOutletList.results[findIndex].mediaKit = '';
                        state.individualMO.mediaKit = '';
                    } else if (MediaOutletFileType.Logo === imageDelete.fileType) {
                        //to update logo
                        state.mediaOutletList.results[findIndex].logo = '';
                        state.individualMO.logo = '';
                    }
                }
            } else {
                const newValue = {
                    ...state.mediaOutletList.results[findIndex],
                    ...action.payload.data,
                };
                state.mediaOutletList.results[findIndex] = newValue;
            }
        },
        getIndividualMO: (
            state,
            action: PayloadAction<MediaOutletCreateEditModel | null>,
        ) => {
            return {
                ...state,
                individualMO: action.payload,
            };
        },
        // Sets the media outlet data currently being edited in the side drawer component
        setCurrentFormData: (
            state,
            action: PayloadAction<MediaOutletCreateEditModel>,
        ) => {
            state.editForm = action.payload;
        },
        resetIndividualMO: (state) => {
            return {
                ...state,
                individualMO: null,
            };
        },
        updateTableAction: (state, action: PayloadAction<ActionType>) => {
            return {
                ...state,
                tableAction: { ...action.payload },
            };
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            state.isSearched = action.payload;
        },
    },
});

const isFormDataChanged = (
    currentState: MediaOutletCreateEditModel,
    initialState: MediaOutletCreateEditModel,
): boolean => {
    let flag = true;
    for (const [key, value] of Object.entries(currentState)) {
        if (initialState.hasOwnProperty(key)) {
            if (loDash.isEqual(value, initialState[key as keyof typeof initialState])) {
                flag = true;
            } else {
                flag = false;
                break;
            }
        }
    }
    return flag;
};

// Gets data from the current MO form open in the side drawer component
// and saves it to the db
export const saveMOFormData =
    (state: MediaOutletStateInterface): AppThunk =>
    async (dispatch) => {
        if (state.editForm) {
            if (
                state.individualMO &&
                !isFormDataChanged(state.editForm, state.individualMO)
            ) {
                if (state.editForm.isFormValid) {
                    // await dispatch(
                    //     saveDetails(
                    //         state.data.id,
                    //         state.editForm?.currentFormData,
                    //     ),
                    // );
                    // dispatch(updateAction(true));
                } else {
                    // dispatch(
                    //     setError(
                    //         `${editFormComponent}: Please fill out required fields`,
                    //     ),
                    // );
                }
            }
        }
    };

export const getMediaOutletList =
    (mediaOutletRequest: MediaOutletSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await getMediaOutletFromApi(mediaOutletRequest);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(getMOList(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the media outlet list`));
            Logger.error(
                `Failed to retrieve the media outlet list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const createNewMediaOutletList =
    (data: FormData, isEdit: boolean | undefined): AppThunk =>
    async (dispatch) => {
        try {
            const response = await createEditMediaOutlets(data);
            if (response.id !== '' && !isEdit) {
                const user = getUser();
                dispatch(
                    getMediaOutletList({
                        currentPage: 1,
                        itemsPerPage: 20,
                        name: '',
                        agentIds: userIsInRole(user, userRoles.agent.key)
                            ? fetchUserPermissions(user, 'agentIds')
                            : [],
                        officeIds: fetchUserPermissions(user, 'officeIds'),
                        roleIds: getUserRoleIds(user),
                        statusId: 1,
                        sortDirection: 'asc',
                        sortColumn: 'Name',
                    }),
                );
                dispatch(setSuccess(`Successfully created new media outlet`));
            } else if (response.id !== '' && isEdit) {
                const newData: IndividualUpdateModel<MediaOutletList> = {
                    from: 'fullUpdate',
                    data: response,
                };
                dispatch(updateIndividualMO(newData));
                dispatch(setSuccess(`Successfully updated the media outlet`));
            }
        } catch (exception) {
            dispatch(
                setError(`Failed to ${isEdit ? 'update' : 'save'} the media outlet`),
            );
            Logger.error(
                `Failed to ${
                    isEdit ? 'update' : 'save'
                } the media outlet ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const deactivateMediaOutlet =
    (mediaOutletId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deactivateMediaOutletApi(mediaOutletId);
            if (response.status === 204) {
                dispatch(removeDeletedMO(mediaOutletId));
                dispatch(deleteAdvertisementWithMoId(mediaOutletId));
                dispatch(setSuccess('Media outlet is deactivated successfully'));
            }
        } catch (exception) {
            dispatch(setError(`Media outlet is not deactivated`));
            Logger.error(
                `Failed to deactivate media outlet ${JSON.stringify(exception)}`,
            );
        }
    };

export const deleteMediaOutletImages =
    (dataToBeDeleted: ImageDeleteModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteMediaOutletImageApi(dataToBeDeleted);
            if (response.status === 204) {
                const deleteImage: IndividualUpdateModel<ImageDeleteModel> = {
                    from: 'imageDelete',
                    data: dataToBeDeleted,
                };
                dispatch(updateIndividualMO(deleteImage));
                dispatch(setSuccess(`File removed from server`));
            }
        } catch (exception) {
            dispatch(setError(`Failed to delete file from server`));
            Logger.error(
                `Failed to delete file from server ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const getIndividualMediaOutlet =
    (id: string, from?: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getMODetails(id);
            if (response.id !== '') {
                dispatch(getIndividualMO(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the media outlet details`));
            Logger.error(
                `Failed to get the media outlet details ${JSON.stringify(exception)}`,
            );
        } finally {
            if (from && from === 'mediaOutlet') dispatch(setProgress(false));
        }
    };

export const {
    getMOList,
    setProgress,
    removeDeletedMO,
    updateIndividualMO,
    getIndividualMO,
    resetIndividualMO,
    updateTableAction,
    setCurrentFormData,
    setIsSearched,
} = mediaOutletSlice.actions;

export const mediaOutlet = (state: RootState): MediaOutletStateInterface =>
    state.advertisement.mediaOutletList;

export default mediaOutletSlice.reducer;
