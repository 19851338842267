import moment from 'moment';
import { FunctionComponent } from 'react';
import { getUser } from '../../../shared/auth/authService';
import {
    fetchUserPermissions,
    getUserRoleIds,
    userIsInRole,
} from '../../../shared/auth/permissions/permissionsService';
import { userRoles } from '../../../shared/constants/user/roles';
import { Order } from '../../../shared/models/customTable/customTableModels';
import {
    searchFieldTypeMap,
    SearchComponentProps,
    AdvancedSearchCriteria,
    searchFieldTypes,
    AdvancedSearchRequest,
    SavedSearchDetails,
    AdvancedSearchField,
    SavedSearchView,
    dateRangeSearchTypeConst,
} from './advancedSearchModels';
import { SecuredSearchRequest } from '../../../shared/models/pagination/paginationModels';
import _ from 'lodash';
/**
 * Maps the search criteria, based on its type, to the correct UI component
 * @param field The criteria field that is being mapped to a component
 * @returns The UI component to render for the field
 */
export const mapToComponent = (
    criteria: AdvancedSearchCriteria,
): FunctionComponent<SearchComponentProps> | undefined => {
    return searchFieldTypeMap.get(criteria.searchFieldType);
};

/**
 * Returns list of lookup info objects mapped from the user's saved
 * searches so that we can bind them to the checkbox list control
 * @param fields The list of saved searches to map to lookup info
 * @returns List of lookup info objects to bind to the control
 */
export const mapSavedSearchesToLookupInfo = (
    savedSearches?: SavedSearchDetails[],
): SavedSearchView[] => {
    return (savedSearches || []).map((s) => {
        return {
            id: s.id || '',
            name: s.name,
            isDefault: s.isDefault ? true : false,
        };
    });
};

/**
 * Renders criteria as UI-friendly summary text
 * @param filter The filter containing the criteria to render
 * @param renderAsTime Flag indicating if criteria should be rendered as time,
 * as opposed to a normal string
 */
const renderRange = (filter: AdvancedSearchCriteria, renderAsTime: boolean): string => {
    if (renderAsTime && filter.values[0].length === 1) {
        const data = dateRangeSearchTypeConst.find(
            (data) => data.id === filter.values[0],
        );
        return data ? data.name : '';
    } else {
        const startVal =
            filter.values.length && filter.values[0].length
                ? `Greater than or equal to: ${
                      renderAsTime
                          ? moment(filter.values[0]).format('MM-DD-YYYY')
                          : filter.values[0]
                  }`
                : '';
        const spacer = startVal.length ? ' ' : '';
        const endVal = filter.endValue
            ? `Less than or equal to:${
                  renderAsTime
                      ? moment(filter.endValue).format('MM-DD-YYYY')
                      : filter.endValue
              }`
            : '';
        return `${startVal}${spacer}${endVal}`;
    }
};

/**
 * Renders the selected filter criteria in a UI-friendly format
 * @param filter The filter containing the selected values being searched on
 */
export const renderFilterValues = (filter: AdvancedSearchCriteria): string => {
    switch (filter.searchFieldType) {
        case searchFieldTypes.checkbox:
        case searchFieldTypes.fieldPopulated:
        case searchFieldTypes.fieldNotPopulated:
        case searchFieldTypes.imagesLowRes:
        case searchFieldTypes.hasVideo:
        case searchFieldTypes.hasAdCopy:
        case searchFieldTypes.hasExternalDataSetting:
        case searchFieldTypes.isSelectSale:
        case searchFieldTypes.hasSyndicationSetting:
            return filter.values.length === 2
                ? 'Both'
                : filter.values.includes('1')
                ? 'true'
                : 'false';
        case searchFieldTypes.date:
        case searchFieldTypes.openHouse:
            return renderRange(filter, true);
        case searchFieldTypes.number:
        case searchFieldTypes.imageCount:
            return renderRange(filter, false);
        case searchFieldTypes.text:
            return filter.values.join(',');
        case searchFieldTypes.multiSelect:
            const names = (filter.lookups || [])
                .filter((l) => filter.values.some((v) => v === l.id))
                .map((l) => l.name);
            return names.join(',');
        default:
            return filter.values[0];
    }
};

/**
 * Loads the search request object with user, filter, and sort
 * info
 * @param criteria The criteria to search on
 * @param sortOrder The order to sort results by (asc or desc)
 * @param sortCol The column to sort results by
 * @param page The page of results to return
 * @returns The populated search request object
 */
export const loadSearchRequest = (
    criteria: AdvancedSearchCriteria[],
    sortOrder: Order,
    sortCol: string,
    page: number,
): AdvancedSearchRequest => {
    const userPermissions = loadUserPermissions();
    const request: AdvancedSearchRequest = {
        criteria: criteria,
        currentPage: page,
        itemsPerPage: 50,
        sortColumn: sortCol,
        sortDirection: sortOrder,
    };
    return { ...request, ...userPermissions };
};

/**
 * Load a secured request object with the current user's permisions
 * @returns Secured search request object containing user's permissions
 */
export const loadUserPermissions = (): SecuredSearchRequest => {
    const user = getUser();
    return {
        agentIds: userIsInRole(user, userRoles.agent.key)
            ? fetchUserPermissions(user, 'agentIds')
            : [],
        officeIds: fetchUserPermissions(user, 'officeIds'),
        roleIds: getUserRoleIds(user),
    };
};

/**
 * util function for generate the new criteria based on the selection on the office stats
 * @param type string, which is used to find the section from where it is called
 * @param fields this has the full set of fields in the advanced search
 * @param businessPurpose this is used to show the sale/rental
 * @returns new criteria with values added based on the type
 */
export const autoLoadAdvSearchDataFormation = (
    type: string,
    fields: AdvancedSearchField[],
    businessPurpose: string,
    from = 'advancedSearch',
): AdvancedSearchCriteria[] => {
    if (type === 'invalid') {
        //Incomplete Listing Data
        let list = ['isValid', 'businessPurposeTypeId', 'listingStatusId'];
        if (from === 'stats') {
            list = ['isValid', 'listingStatusId'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));
        const updatedCriteria = selectedField.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'isValid') {
                return { ...data, values: ['0'] };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else if (type === 'draft') {
        //Agent Submitted Drafts
        let list = ['listingStatusId', 'businessPurposeTypeId'];
        if (from === 'stats') {
            list = ['listingStatusId'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            return data.fieldName === 'listingStatusId'
                ? { ...data, values: ['8'] }
                : data.fieldName === 'businessPurposeTypeId' && businessPurpose === 'sale'
                ? { ...data, values: ['1'] }
                : { ...data, values: ['2'] };
        });
        return updatedCriteria;
    } else if (type === '10Photos') {
        //Listings with less than 10 photos
        let list = ['imageCount', 'businessPurposeTypeId', 'listingStatusId'];
        if (from === 'stats') {
            list = ['imageCount', 'listingStatusId'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'imageCount') {
                return { ...data, endValue: '9', values: [''] };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else if (type === 'mlsNumberMissing') {
        //Listing missing MLS number
        let list = ['mlsNumberMissing', 'businessPurposeTypeId', 'listingStatusId'];
        if (from === 'stats') {
            list = ['mlsNumberMissing', 'listingStatusId'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'mlsNumberMissing') {
                return { ...data, values: ['1'] };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else if (type === 'notPublished') {
        //Active/Valid listings not published
        let list = ['listingStatusId', 'isValid', 'publish', 'businessPurposeTypeId'];
        if (from === 'stats') {
            list = ['listingStatusId', 'isValid', 'publish'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'isValid') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'publish') {
                return { ...data, values: ['0'] };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else if (type === 'expiry') {
        //Listing Near Expiration Date
        let list = ['listingStatusId', 'expirationDate', 'businessPurposeTypeId'];
        if (from === 'stats') {
            list = ['listingStatusId', 'expirationDate'];
        }
        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1', '2'] };
            }
            if (data.fieldName === 'expirationDate') {
                return {
                    ...data,
                    values: [moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm')],
                    endValue: moment().add(28, 'd').format('YYYY-MM-DDTHH:mm'),
                };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else if (type === 'lowres') {
        //listing with low resolution image
        let list = ['listingStatusId', 'imageLowRes', 'businessPurposeTypeId'];
        if (from === 'stats') {
            list = ['listingStatusId', 'imageLowRes'];
        }

        const selectedField = fields.filter((data) => list.includes(data.fieldName));

        const updatedCriteria = selectedField?.map((data) => {
            if (data.fieldName === 'listingStatusId') {
                return { ...data, values: ['1'] };
            }
            if (data.fieldName === 'imageLowRes') {
                return {
                    ...data,
                    values: ['1'],
                };
            }
            if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'sale'
            ) {
                return { ...data, values: ['1'] };
            } else if (
                data.fieldName === 'businessPurposeTypeId' &&
                businessPurpose === 'rental'
            ) {
                return { ...data, values: ['2'] };
            } else {
                return { ...data, values: [] };
            }
        });
        return updatedCriteria;
    } else {
        return [];
    }
};

/**
 * Takes a criterion being created/edited and merges it into existing
 * list of criteria
 * @param currentCriteria Current list of criteria to add to or update
 * @param updatedCriterion The current criteria object being created or updated
 * @returns Merged list of criteria
 */
export const updateCriteriaList = (
    currentCriteria: AdvancedSearchCriteria[],
    updatedCriterion: AdvancedSearchCriteria,
): AdvancedSearchCriteria[] => {
    const criteria = currentCriteria.map((c) =>
        c.fieldName !== updatedCriterion.fieldName ? c : { ...updatedCriterion },
    );
    return criteria;
};

/**
 * Merges data from fields to the criteria object, to populate all properties
 * needed (ex: displayName)
 * @param criteria List of criteria to merge field data into
 * @param fields List of fields containnig data to add to the criteria object
 */
export const mergeCriteriaAndFieldData = (
    criteria: AdvancedSearchCriteria[],
    fields: AdvancedSearchField[],
): AdvancedSearchCriteria[] => {
    return criteria.map((c) => {
        const matchingField = fields.find((f) => f.fieldName === c.fieldName);
        return matchingField !== null ? { ...matchingField, ...c } : c;
    });
};

export const insertFieldInCorrectOrder = (
    criteria: AdvancedSearchCriteria[],
    field: AdvancedSearchCriteria,
): AdvancedSearchCriteria[] => {
    const cloneOfCriteria = [...criteria];
    const sortedCriteria = _.sortBy(criteria, 'defaultColumnOrder');

    //reverse the array and find the lowest nearest column order value
    const sortedReversedCriteria = [...sortedCriteria].reverse();
    const lowestAndNearest = sortedReversedCriteria.find(
        (x) => x.defaultColumnOrder < field.defaultColumnOrder,
    );

    //if found find the index and insert the data in the position next to it
    if (lowestAndNearest) {
        const indexOfNearest = cloneOfCriteria.findIndex(
            (x) => x.defaultColumnOrder === lowestAndNearest.defaultColumnOrder,
        );
        cloneOfCriteria.splice(indexOfNearest + 1, 0, { ...field });
        return cloneOfCriteria;
    } else {
        // if lowest is not found find the highest nearest value
        const highestAndNearest = sortedCriteria.find(
            (x) => x.defaultColumnOrder > field.defaultColumnOrder,
        );
        if (highestAndNearest) {
            //then place it in the postion before it, but if the index is 0 place it next to it
            const indexOfNearest = cloneOfCriteria.findIndex(
                (x) => x.defaultColumnOrder === highestAndNearest.defaultColumnOrder,
            );
            cloneOfCriteria.splice(indexOfNearest, 0, { ...field });
            return cloneOfCriteria;
        } else {
            //if we do not find any value just push the field at last
            return [...cloneOfCriteria, { ...field }];
        }
    }
};
