import {
    ImageData,
    ImageUrls,
    ImageDetail,
    ImageSaveResult,
} from '../../models/images/sharedModel';

export function getImageData(
    uploadedImage: ImageDetail,
    response: ImageSaveResult,
): ImageData {
    const newPhoto: ImageData = {
        caption: null,
        entityId: uploadedImage.entityId,
        entityType: uploadedImage.entityType,
        floorplancaption: null,
        guid: uploadedImage.id,
        isPublished: false,
        isVanity: false,
        lastModified: '',
        orderNo: uploadedImage.orderNo,
        size: {
            width: uploadedImage.width,
            height: uploadedImage.height,
            sizeKb: uploadedImage.size,
        },
        type: uploadedImage.mimeType,
        urls: response as ImageUrls,
        islandscape: uploadedImage.width > uploadedImage.height ? true : false,
        tagId: null,
        tagName: null,
    };
    return newPhoto;
}

export const getImageIdFromUrl = (url: string): string[] => {
    const id = url.split('/').slice(-1)[0].split('.', 1);
    return id;
};
