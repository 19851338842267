import { Grid, TextField } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { ChangeEvent, useRef } from 'react';
import { CustomTabModel } from '../../showcaseWebsiteModels';
import { Editor as TinyMCEEditor } from 'tinymce';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';

interface CustomTabEditorProps {
    value: CustomTabModel;
    apiKeyOfTinyMce: string;
    position: number;
    handleTitleChange(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        position: number,
    ): void;
    handleEditorChange: (value: string, position: number) => void;
}

export const CustomTabEditor = (props: CustomTabEditorProps): JSX.Element => {
    const { value, apiKeyOfTinyMce, position, handleTitleChange, handleEditorChange } =
        props;

    const editorRef = useRef<TinyMCEEditor | null>(null);
    const save = (position: number) => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            handleEditorChange(content, position);
        }
    };

    return (
        <>
            <Grid item container className="spacing smallSection customMarginTop">
                <FormRow
                    label="TAB HEADING"
                    control={
                        <TextField
                            size="small"
                            name={`tab_${value.orderNo}`}
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'inputFields',
                            }}
                            value={value.title}
                            onChange={(e) => handleTitleChange(e, position)}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={10}
                    labelSm={2}
                    controlSm={10}
                />
            </Grid>
            <Grid item container className="spacing smallSection customMarginTop">
                <Grid item xs={12} md={2} className="contentHeader">
                    TAB CONTENT
                </Grid>
                <Grid item xs={12} md={10}>
                    <Editor
                        apiKey={apiKeyOfTinyMce}
                        init={{
                            placeholder: 'Type here to add notes.',
                            height: 240,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            setup: (editor) => {
                                editor.on('PostProcess', function (ed) {
                                    // we are cleaning empty paragraphs
                                    ed.content = ed.content
                                        .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                        .replace(/(<br \/>)/gi, '<br>')
                                        .replace(/(\n<li>)/gi, '<li>')
                                        .replace(/(<\/li>\n)/gi, '</li>')
                                        .replace(/(\r\n|\n|\r)/gi, '<br>')
                                        .replace(/(<br>){1,}/g, '<br>');
                                });
                            },
                            toolbar: 'bold | italic | bullist | numlist',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                        }}
                        initialValue={value.body}
                        onInit={(event, editor) => (editorRef.current = editor)}
                        onBlur={() => save(position)}
                    />
                </Grid>
            </Grid>
        </>
    );
};
