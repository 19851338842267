import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import { getOfficeDetails, updateOffice, updateChipsData } from './officeApi';
import {
    OfficeDescription,
    OfficeDetailsSummary,
    OfficeState,
    OfficeStateUpdate,
} from './officeModels';
import Logger from '../../../utils/logging/logger';
import { getImages, savePhoto, deletePhoto } from '../../../shared/api/images/sharedApi';
import { ImageDetail, ImageDeleteData } from '../../../shared/models/images/sharedModel';
import { officeInitialStateData } from './officeConstants';
import loDash from 'lodash';
import { editFormComponent } from './officeConstants';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../shared/slices/loader/loaderSlice';
import { PhotoEditorEvents } from '../../../shared/models/loader/loaderModels';
import { UpdateFlag } from '../../../shared/models/flag/flagModel';
import { jsonToFormData } from '../../../utils/urlUtils';
import { getImageData } from '../../../shared/functions/photo/utils';
import moment from 'moment';
import { displayErrorForOfficeEditScreen } from './officeUtils';

export const officeInitialState: OfficeState = {
    data: officeInitialStateData,
    editForm: {
        formOpen: false,
        officeEditFormName: '',
    },
    flags: {
        isLoading: false,
        isPhotoUploading: false,
    },
    isEditDone: false,
};

const isFormDataChanged = (
    currentState: OfficeDescription,
    initialState: OfficeDetailsSummary,
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !loDash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

// Gets data from the current office details open in the side drawer component
// and saves it to the db
export const saveOfficeEditFormData =
    (officeState: OfficeState, editFormName?: string): AppThunk =>
    async (dispatch) => {
        let isChanged = true;
        let isValid = true;
        if (officeState.editForm?.currentFormData) {
            switch (officeState.editForm?.officeEditFormName) {
                case editFormComponent.description:
                    const newState = officeState.editForm
                        ?.currentFormData as OfficeDescription;
                    const {
                        name,
                        officeRegion,
                        salesRegion,
                        address1,
                        city,
                        state,
                        zip,
                        phoneNumber,
                        fax,
                        email,
                    } = newState;
                    const result = displayErrorForOfficeEditScreen({
                        name: name || '',
                        officeRegion,
                        salesRegion: salesRegion || '',
                        address1: address1 || '',
                        city,
                        state: state || '',
                        zip,
                        phoneNumber: phoneNumber || '',
                        fax: fax || '',
                        email: email || '',
                    });
                    isChanged = isFormDataChanged(
                        newState,
                        officeState.data.officeDetails,
                    );
                    if (isChanged) {
                        isValid = result.isValid;
                        if (isValid) {
                            dispatch(saveOfficeDetails({ ...newState, isValid: true }));
                            if (
                                !loDash.isEqual(
                                    newState?.officeSpecializations,
                                    officeState.data.officeDetails.officeSpecializations,
                                )
                            )
                                dispatch(saveChipData(newState));
                        } else {
                            dispatch(setError(result.errorMessage));
                        }
                    }
                    break;
                case editFormComponent.history:
                    const newHistory = (
                        officeState.editForm?.currentFormData as OfficeDescription
                    )?.history;
                    if (
                        !loDash.isEqual(
                            newHistory,
                            officeState.data.officeDetails.history,
                        )
                    ) {
                        dispatch(
                            saveOfficeDetails(
                                officeState.editForm
                                    ?.currentFormData as OfficeDescription,
                                true,
                            ),
                        );
                    }
                    break;
            }
        }
        if (isValid) {
            if (editFormName) dispatch(updateEditFormName(editFormName));
            else dispatch(closeEditForm());
        }
    };

export const officeSlice = createSlice({
    name: 'office',
    initialState: officeInitialState,
    reducers: {
        // use this to update offices/officeDetails/officePhoto
        setInitialState: (state) => {
            return {
                ...state,
                data: officeInitialStateData,
                isLoading: true,
            };
        },
        updateOfficeState: (state, action: PayloadAction<OfficeStateUpdate>) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.property]: action.payload.value,
                },
            };
        },
        updateLoader: (state, action: PayloadAction<UpdateFlag>) => {
            return {
                ...state,
                flags: {
                    ...state.flags,
                    [action.payload.property]: action.payload.value,
                },
            };
        },

        // Edit Forms
        setCurrentOfficeEditFormData: (
            state,
            action: PayloadAction<{
                formData: OfficeDescription;
                officeEditFormName: string;
            }>,
        ) => {
            return {
                ...state,
                editForm: {
                    ...state.editForm,
                    formOpen: state.editForm?.formOpen || false,
                    officeEditFormName: action.payload.officeEditFormName,
                    currentFormData: action.payload.formData,
                },
            };
        },
        // Sets the name of the component to open in the side drawer
        updateEditFormName: (state, action: PayloadAction<string>) => {
            state.editForm = {
                formOpen: true,
                officeEditFormName: action.payload,
            };
        },
        // Fired when the user wants to close the side drawer component
        closeEditForm: (state, action: PayloadAction) => {
            state.editForm = {
                formOpen: false,
                officeEditFormName: '',
            };
        },
        updateEditAction: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditDone: action.payload,
            };
        },
    },
});

// Office Details Summary Thunk

export const fetchOfficeDetails =
    (officeId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getOfficeDetails(officeId);
            if (response) {
                dispatch(
                    updateOfficeState({
                        property: 'officeDetails',
                        value: response,
                    }),
                );
            } else {
                dispatch(setError(`Failed to load office details`));
            }
        } catch (e) {
            Logger.error(`Failed to load office details`, e);
            dispatch(setError(`Failed to load office details`));
        } finally {
            dispatch(
                updateLoader({
                    property: 'isLoading',
                    value: false,
                }),
            );
        }
    };

// Office Description Edit Screen Thunks

export const saveOfficeDetails =
    (officeDetails: OfficeDescription, isHistory?: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const currentOfficeDetails = getState().office.details.data.officeDetails;
            const {
                officeRegionId,
                officeSpecializations,
                salesRegion,
                state,
                ...updatedOfficeDetails
            } = officeDetails;
            const response = await updateOffice(updatedOfficeDetails);
            if (response) {
                dispatch(
                    updateOfficeState({
                        property: 'officeDetails',
                        value: {
                            ...currentOfficeDetails,
                            ...officeDetails,
                            lastUpdated: moment().format('YYYY-MM-DDTHH:mm'),
                        },
                    }),
                );
                dispatch(
                    setSuccess(
                        `${
                            isHistory
                                ? 'Office Description is updated'
                                : 'Office details are updated'
                        }`,
                    ),
                );
                dispatch(updateEditAction(true));
            }
        } catch (e) {
            Logger.error(
                `Error occured while updating office information ${JSON.stringify(e)}`,
            );
            dispatch(
                setError(
                    `${
                        isHistory
                            ? 'Failed to update the office history'
                            : 'Failed to update the office details'
                    }`,
                ),
            );
        }
    };

// Office Photo Edit Screen Thunks

export const getOfficePhoto =
    (entityType: string, entityId: string, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getImages(entityType, entityId, imageType);
            if (response) {
                dispatch(
                    updateOfficeState({
                        property: 'officePhoto',
                        value: response,
                    }),
                );
            } else {
                dispatch(setError(`Failed to get Office photo`));
            }
        } catch (exception) {
            Logger.error(`Failed to get Office photo`, exception);
            dispatch(setError(`Failed to get Office photo`));
        }
    };

export const saveOfficePhoto =
    (request: ImageDetail): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateLoader({
                    property: 'isPhotoUploading',
                    value: true,
                }),
            );
            const data = jsonToFormData(request);
            const response = await savePhoto(data);
            if (response) {
                const newPhoto = getImageData(request, response);
                dispatch(
                    updateOfficeState({
                        property: 'officePhoto',
                        value: [newPhoto],
                    }),
                );
                dispatch(setSuccess('Photo saved successfully'));
            }
        } catch (e) {
            Logger.error(`Error saving photos for: ${request.id}`);
            dispatch(setError('Error saving Photo'));
        } finally {
            dispatch(
                updateLoader({
                    property: 'isPhotoUploading',
                    value: false,
                }),
            );
        }
    };

export const deleteOfficePhoto =
    (deleteData: ImageDeleteData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
            const response = await deletePhoto(deleteData);
            if (response) {
                dispatch(
                    updateOfficeState({
                        property: 'officePhoto',
                        value: [],
                    }),
                );
                dispatch(setSuccess(`Photo deleted successfully`));
            }
        } catch (e) {
            Logger.error(`Failed To Delete Office Photo: ${JSON.stringify(deleteData)}`);
            dispatch(setError(`Failed To Delete Office Photo`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
        }
    };

//

export const saveChipData =
    (officeDetails: OfficeDescription): AppThunk =>
    async (dispatch, getState) => {
        try {
            const currentOfficeDetail = getState().office.details.data.officeDetails;
            const chipResponse = await updateChipsData(
                officeDetails?.id || '',
                officeDetails?.officeSpecializations || [],
            );
            if (chipResponse.status === 200) {
                dispatch(
                    updateOfficeState({
                        property: 'officeDetails',
                        value: {
                            ...currentOfficeDetail,
                            officeSpecializations: officeDetails.officeSpecializations,
                        },
                    }),
                );
                dispatch(setSuccess('Office Specializations updated'));
            }
        } catch (e) {
            Logger.error(`Failed to save the office details: ${JSON.stringify(e)}`);
            dispatch(setError(`Failed to update the office details`));
        }
    };

export const {
    updateLoader,
    setInitialState,
    updateEditFormName,
    closeEditForm,
    updateOfficeState,
    setCurrentOfficeEditFormData,
    updateEditAction,
} = officeSlice.actions;

export const office = (state: RootState): OfficeState => state.office.details;

export default officeSlice.reducer;
