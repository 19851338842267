import React, { FunctionComponent } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@material-ui/core/styles';
import { DesignSlider } from './designVaultModel';

const useStyles = makeStyles((theme) => ({
    dvSlider: {
        '& .slide': {
            height: '200px',
            padding: '1px',
        },
    },
    img: {
        width: '100%',
        height: '100%',
        display: 'block',
        objectFit: 'contain',
        filter: 'drop-shadow(0 0 0.15rem #002349)',
    },
}));
const DesignImageSlider: FunctionComponent<DesignSlider> = (props: {
    images: string[];
    sliderPosition: number;
}) => {
    const classes = useStyles();
    return (
        <Carousel
            autoPlay={false}
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            selectedItem={props.sliderPosition}
            className={classes.dvSlider}
        >
            {props.images.map((data: string, index: number) => (
                <img className={classes.img} key={index} src={`/dv/${data}`} alt={data} />
            ))}
        </Carousel>
    );
};

export default DesignImageSlider;
