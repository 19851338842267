import { FunctionComponent } from 'react';
import { PhotoTabProps } from '../../models/images/sharedModel';
import {
    verifyListingEdit,
    verifyListingPhotosReorder,
} from '../../../features/listings/utils';

import UploadSection from './upload/UploadSection';
import EditorSection from './editor/EditorSection';

const PhotoTab: FunctionComponent<PhotoTabProps> = (props) => {
    return (
        <>
            {(props.from === 'listing' &&
                verifyListingEdit() &&
                !verifyListingPhotosReorder()) ||
            props.from === 'development' ? (
                <UploadSection
                    from={props.from}
                    imageOrderNo={props.imageOrderNo}
                    images={props.images}
                    isUploading={props.isUploading}
                    currentImageType={props.currentImageType}
                    entityId={props.entityId}
                    currentImageDetails={props.currentImageDetails}
                    saveImageDetails={props.saveImageDetails}
                    updateOrderNumber={props.updateOrderNumber}
                    updateImageCount={props.updateImageCount}
                    entityType={props.entityType}
                    photoCredit={props.photoCredit}
                    handlePhotoCreditChange={props.handlePhotoCreditChange}
                    handleSuppressPhotoImportChange={
                        props.handleSuppressPhotoImportChange
                    }
                    maxAllowedPhotos={props.maxAllowedPhotos}
                    disableUpload={props.disableUpload}
                    hasVideo={props.hasVideo}
                    office={props.office}
                />
            ) : (
                <></>
            )}
            <EditorSection
                from={props.from}
                currentImageType={props.currentImageType}
                currentImageDetails={props.currentImageDetails}
                images={props.images}
                entityId={props.entityId}
                filter={props.filter}
                flags={props.flags}
                deleteImageDetails={props.deleteImageDetails}
                updateImages={props.updateImages}
                saveImageSharePackage={props.saveImageSharePackage}
                downloadImagePackage={props.downloadImagePackage}
                imageDownload={props.imageDownload}
                fetchImageTags={props.fetchImageTags}
                flagUpdate={props.flagUpdate}
                saveImageDetails={props.saveImageDetails}
                saveImageCaption={props.saveImageCaption}
                imageTags={props.imageTags}
                saveImageTags={props.saveImageTags}
                setFormData={props.setFormData}
                currentSection={props.currentSection}
                saveShowcaseImages={props.saveShowcaseImages}
                imageSelectCount={props.imageSelectCount}
                setImageSelectCount={props.setImageSelectCount}
                entityType={props.entityType}
                salesRegionId={props.salesRegionId}
                office={props.office}
            />
        </>
    );
};
export default PhotoTab;
