import {
    getCall,
    getApiCall,
    putCall,
    tridentGetCall,
} from '../../../utils/api/apiWrapper';
import {
    QcStatusDetails,
    DatesModel,
    RentalDetails,
    ListingImages,
    ListingAgent,
    ListingDetailsSummary,
    TridentValidationModel,
    LastUpdatedResponse,
} from './listingModels';
import {
    ListingDetailsData,
    ListingSaveResponse,
} from './drawer/screens/listingDetails/listingDetailsModels';
import { PricesModel, RentalTermsModel } from './drawer/screens/prices/priceModel';
import { ListingSyncFlag } from '../../../shared/models/listing/syndicationModel';
import { DataFlowResponse } from '../../../utils/api/apiModels';
import { ListingAmenity } from '../../../shared/models/listing/amenityModels';

export function getListingData(listingId: string): Promise<ListingDetailsSummary> {
    return getCall<ListingDetailsSummary>(`listing/${listingId}/details`);
}

export const getListingAmenitiesApi = (listingId: string): Promise<ListingAmenity[]> => {
    return getCall<ListingAmenity[]>(`listing/${listingId}/amenityDetails`);
};

export const getListingImagesApi = (listingId: string): Promise<ListingImages> => {
    return getCall<ListingImages>(`listing/${listingId}/imageDetails`);
};

export const getListingAgentsApi = (listingId: string): Promise<ListingAgent[]> => {
    return getCall<ListingAgent[]>(`listing/${listingId}/agentDetails`);
};

export function getQcStatus(
    listingNumber: string | null,
    listingType: string | null,
): Promise<QcStatusDetails> {
    if (listingType && listingNumber) {
        const listType = listingType?.toLowerCase() === 'sale' ? 'sales' : 'rental';
        const dashUrl = process.env.REACT_APP_DASHURL;
        return getApiCall<QcStatusDetails>(
            `${dashUrl}/listing/${listType}/${listingNumber}/qcstatus`,
        );
    } else {
        return Promise.reject();
    }
}

export function getRentals(salesRegionId: number, id: string): Promise<RentalDetails[]> {
    return getCall<RentalDetails[]>(`Rental/${salesRegionId}/listing/${id}`);
}

export function updateListingDetails(
    id: string,
    request: ListingDetailsData,
): Promise<ListingSaveResponse> {
    return putCall<ListingDetailsData, ListingSaveResponse>(
        `listing/${id}/details`,
        request,
    );
}

export function updateListingDates(
    id: string,
    request: DatesModel,
): Promise<ListingSaveResponse> {
    return putCall<DatesModel, ListingSaveResponse>(`listing/${id}/dates`, request);
}

export function updateListingPrices(
    id: string,
    request: PricesModel,
): Promise<ListingSaveResponse> {
    return putCall<PricesModel, ListingSaveResponse>(`listing/${id}/prices`, request);
}

export function updateRentalPrices(
    id: string,
    request: RentalTermsModel,
): Promise<RentalTermsModel> {
    return putCall<RentalTermsModel, RentalTermsModel>(
        `listing/${id}/rentalterms`,
        request,
    );
}

export function listingSynFlagApi(id: string): Promise<ListingSyncFlag> {
    return getCall<ListingSyncFlag>(`ListingSyndication/${id}/flags`);
}

export function getListingValidationDetails(id: string): Promise<DataFlowResponse> {
    return getCall<DataFlowResponse>(`listing/${id}/validate`);
}

export function getListingLastUpdated(id: string): Promise<LastUpdatedResponse> {
    return getCall<LastUpdatedResponse>(`listing/${id}/lastUpdated`);
}

export function getTridentValidationDetails(id: string): Promise<TridentValidationModel> {
    return tridentGetCall<TridentValidationModel>(`listing/${id}/validate`);
}
