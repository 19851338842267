import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import {
    ClientMarketingSchedule,
    Schedules,
    SentEmails,
    FlagData,
} from './schedulerModels';
import {
    fetchClientMarketingSchedule,
    addScehduleData,
    fetchScheduleSentEmails,
    updateScheduleData,
    deleteScheduleData,
} from './schedulerAPI';
import { setError, setSuccess } from '../../../../shared/slices/messaging/messagingSlice';

export const initialScheduler: ClientMarketingSchedule = {
    address: '',
    fromAddress: '',
    agentNames: [],
    schedules: [],
    viewEmails: [],
    flags: {
        emailSent: false,
        isSending: false,
    },
};

export const schedulerSlice = createSlice({
    name: 'scheduler',
    initialState: initialScheduler,
    reducers: {
        loadScheduler: (state, action: PayloadAction<ClientMarketingSchedule>) => {
            state.address = action.payload.address;
            state.fromAddress = action.payload.fromAddress;
            state.agentNames = action.payload.agentNames;
            state.schedules = action.payload.schedules;
        },
        addNewSchedule: (state, action: PayloadAction<Schedules>) => {
            state.schedules = [...state.schedules, action.payload];
        },
        loadEmails: (state, action: PayloadAction<SentEmails[]>) => {
            state.viewEmails = action.payload;
        },
        updateSchedulesList: (state, action: PayloadAction<Schedules>) => {
            state.schedules = state.schedules.map((schedule: Schedules) =>
                schedule.id === action.payload.id
                    ? { ...schedule, ...action.payload }
                    : schedule,
            );
        },
        deleteSchedule: (state, action: PayloadAction<string>) => {
            state.schedules = state.schedules.filter(
                (schedule: Schedules) => schedule.id !== action.payload,
            );
        },
        setFlagStatus: (state, action: PayloadAction<FlagData>) => {
            return {
                ...state,
                flags: {
                    ...state.flags,
                    [action.payload.property as keyof typeof initialScheduler.flags]:
                        action.payload.value,
                },
            };
        },
    },
});

export const getSchedulerDetails =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const schedulerData = await fetchClientMarketingSchedule(listingId);
            if (schedulerData) {
                dispatch(loadScheduler(schedulerData));
            } else {
                throw Error('Failed');
            }
        } catch (e) {
            dispatch(setError(`Failed To Load Emails`));
        }
    };

export const addScehdule =
    (scheduleData: Schedules): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setFlagStatus({ property: 'isSending', value: true }));
            if (scheduleData) {
                const response: Schedules = await addScehduleData(scheduleData);
                if (response) {
                    dispatch(addNewSchedule(response));
                    if (response.schedule === 0) {
                        dispatch(setSuccess(`Success, Your email will be sent shortly.`));
                        dispatch(setFlagStatus({ property: 'emailSent', value: true }));
                    } else {
                        dispatch(setSuccess(`Success, Email saved.`));
                    }
                    dispatch(setFlagStatus({ property: 'isSending', value: false }));
                } else {
                    throw Error('Failed');
                }
            }
        } catch (e) {
            dispatch(setError(`Failed To Add Email`));
        } finally {
            dispatch(setFlagStatus({ property: 'isSending', value: false }));
        }
    };

export const fetchSendAudit =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const sendEmails = await fetchScheduleSentEmails(listingId);
            if (sendEmails) {
                dispatch(loadEmails(sendEmails));
            } else {
                throw Error('Failed');
            }
        } catch (e) {
            dispatch(setError(`Failed To Load Emails`));
        }
    };

export const updateSchedule =
    (updatedScheduleData: Schedules, id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setFlagStatus({ property: 'isSending', value: true }));
            if (updatedScheduleData) {
                const response = await updateScheduleData(id, updatedScheduleData);
                if (response.status === 204) {
                    dispatch(updateSchedulesList(updatedScheduleData));
                    dispatch(setSuccess(`Email Updated successfully.`));
                } else {
                    throw Error('Failed');
                }
            }
        } catch (e) {
            dispatch(setError(`Failed To Update.`));
        } finally {
            dispatch(setFlagStatus({ property: 'isSending', value: false }));
        }
    };

export const deleteScheduleById =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const res = await deleteScheduleData(id);
            if (res.status === 204) {
                dispatch(deleteSchedule(id));
                dispatch(setSuccess(`Email deleted successfully.`));
            } else {
                throw Error('Failed');
            }
        } catch (e) {
            dispatch(setError(`Failed To Delete Email`));
        }
    };

export const {
    loadScheduler,
    addNewSchedule,
    loadEmails,
    updateSchedulesList,
    deleteSchedule,
    setFlagStatus,
} = schedulerSlice.actions;
export const schedulerDetails = (state: RootState): ClientMarketingSchedule =>
    state.cmr.scheduler;

export default schedulerSlice.reducer;
