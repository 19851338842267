import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    MediaOutletSearchRequest,
    MediaOutletList,
} from '../../../features/advertisements/mediaOutlets/mediaOutletListModels';
import { getMediaOutletFromApi } from '../../../features/advertisements/mediaOutlets/mediaOutletListApi';
import { MediaOutletState } from '../../models/mediaOutlet/mediaOutletModels';
import { setError } from '../messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';

const initialMOState: MediaOutletState = {
    isMOLoading: false,
    searchedMOList: [],
};

const mediaOutletSlice = createSlice({
    name: 'mediaOutlet',
    initialState: initialMOState,
    reducers: {
        setSearchedMO: (state, action: PayloadAction<MediaOutletList[]>) => {
            return {
                ...state,
                searchedMOList: action.payload,
            };
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isMOLoading: action.payload,
            };
        },
    },
});

export const getMediaOutlet =
    (data: MediaOutletSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await getMediaOutletFromApi(data);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length >= 0
            ) {
                dispatch(setSearchedMO(response.results));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the media outlet list`));
            Logger.error(
                `Failed to get the media outlet list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setProgress(false));
        }
    };

export const { setSearchedMO, setProgress } = mediaOutletSlice.actions;
export const mediaOutlet = (state: RootState): MediaOutletState => state.mediaOutlet;

export default mediaOutletSlice.reducer;
