import React, { FunctionComponent } from 'react';
import _ from 'lodash';
import { ListingAmenity } from '../../../../../../../../shared/models/listing/amenityModels';
import AmenityGroup from './AmenityGroup';

interface AmenitySectionProps {
    /** The ID of the listing to render amenities for */
    listingId: string;

    /** All amenities in the system for the section */
    amenitiesForSection: ListingAmenity[];

    /** All amenities the listing is associated with */
    listingAmenities: ListingAmenity[];
}

const AmenitySection: FunctionComponent<AmenitySectionProps> = ({
    listingId,
    amenitiesForSection,
    listingAmenities,
}) => {
    // Group amenites by their group name and render
    const groupedAmenities = _(amenitiesForSection)
        .groupBy('groupName')
        .map((value, key) => ({
            name: key,
            amenities: value,
        }))
        .value();

    return (
        <>
            {groupedAmenities.map((ag) => (
                <AmenityGroup
                    key={ag.name}
                    listingId={listingId}
                    title={ag.name}
                    amenitiesForGroup={ag.amenities}
                    listingAmenities={listingAmenities}
                />
            ))}
        </>
    );
};

export default AmenitySection;
