import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, displayReportToggle, deleteData } from '../../../cmrSlice';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import { SectionProps } from '../../../cmrModels';
import { sortByDate } from '../../../../../utils/urlUtils';
import './index.scss';
import ReadOnly from './components/ReadOnly';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import { SocialCampaignData } from '../../../../../shared/models/marketing/marketingModels';
import CustomSocialCampaign from '../../../../../shared/component/marketing/socialCampaign/CustomSocialCampaign';

const SocialCampaigns: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const listingId = listingdata.id;
    const [editId, setEditId] = useState('');
    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const [sortedSocials, setSortedSocials] = useState(
        sortByDate(paneldata.socialCampaigns as SocialCampaignData[], 'fromDate'),
    );
    useEffect(() => {
        setSortedSocials(
            sortByDate(paneldata.socialCampaigns as SocialCampaignData[], 'fromDate'),
        );
    }, [paneldata.socialCampaigns]);
    const onEditClick = (social: SocialCampaignData) => {
        setEditId(social.id || '');
        googleAnalyticsAction('Edit', 'Click', 'Social Campaign Edit');
    };
    const deleteIt = (id: string | undefined) => {
        if (id) {
            googleAnalyticsAction('Delete', 'Click', 'Social Campaign Delete');
            const url = `listing/${listingId}/marketing/${id}`;
            dispatch(deleteData(url, props.sectionId, id, 'marketingId'));
        }
    };
    const cancelClick = () => {
        setEditId('');
    };

    return (
        <div className="social-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Social Campaign Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={
                    hideDisplayOnReport(9, sortOrder, props.sectionId) ? true : false
                }
            />
            <div className="inner-container">
                <CustomSocialCampaign
                    listingId={listingId}
                    cancelClick={cancelClick}
                    sectionId={props.sectionId}
                />
                <div className="table-heading">
                    <div className="dates heading-text ">dates</div>
                    <div className="type heading-text ">Description</div>
                    <div className="potential-reach heading-text ">
                        <span className="inner-reach">potential reach</span>
                    </div>
                    <div className="views heading-text small">views</div>
                    <div className="engagements heading-text large">engagements</div>
                    <div className="clicks heading-text small">clicks</div>
                    <div className="empty heading-text"></div>
                </div>
                <div className="inner-data-container">
                    {sortedSocials && sortedSocials.length > 0
                        ? sortedSocials.map((social: SocialCampaignData) => (
                              <Fragment key={'editData' + social.id}>
                                  {editId === social.id ? (
                                      <CustomSocialCampaign
                                          socialCampaign={social}
                                          listingId={listingId}
                                          cancelClick={cancelClick}
                                          formEdit={true}
                                          sectionId={props.sectionId}
                                      />
                                  ) : (
                                      <ReadOnly
                                          socialCampaign={social}
                                          deleteIt={deleteIt}
                                          onEditClick={onEditClick}
                                      />
                                  )}
                              </Fragment>
                          ))
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default SocialCampaigns;
