import { postCallRaw, getCall, postCall } from '../../utils/api/apiWrapper';
import {
    ReportingRequest,
    CITReportForm,
    AgentCitReportResults,
    AdvertisementMarketingRequest,
    AdvertisementMarketingReportResults,
} from './reportingModel';

export function exportReportsApi(
    request: ReportingRequest,
    from: string,
): Promise<Response> {
    let url = `agent/websiteReport`;
    if (from === 'showcase') {
        url = `showcaseSite/websiteReport`;
    }
    return postCallRaw<ReportingRequest>(url, request);
}

export function getAgentCitReport(
    request: CITReportForm,
): Promise<AgentCitReportResults[]> {
    return getCall<AgentCitReportResults[]>(
        `agent/${request.agentId}/citReport?startDate=${request.startDate}&endDate=${request.endDate}&includeMarketing=${request.includeMarketing}&includeAds=${request.includeAds}`,
    );
}

export function getAdMarketingReport(
    agentId: string,
    request: AdvertisementMarketingRequest,
): Promise<AdvertisementMarketingReportResults[]> {
    return postCall<AdvertisementMarketingRequest, AdvertisementMarketingReportResults[]>(
        `agent/${agentId}/advertisingReport`,
        request,
    );
}

export function exportCitReport(request: CITReportForm): Promise<Response> {
    return postCallRaw<CITReportForm>(
        `agent/${request.agentId}/citReportExport?startDate=${request.startDate}&endDate=${request.endDate}&includeMarketing=${request.includeMarketing}&includeAds=${request.includeAds}`,
        request,
    );
}
