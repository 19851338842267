import lodash from 'lodash';
import { OfficeLookupInfo } from '../../models/lookups/lookupsModels';
import { OfficeGroup } from '../../models/officePermission/officePermissionModels';

export const officeGroupCreation = (
    offices: OfficeLookupInfo[],
    isEdit = false,
    selectedOfficeIds?: string[],
): OfficeGroup[] => {
    const activeOffices = offices.filter(
        (off) => !off.name.includes('inactive') && typeof off.salesRegion === 'string',
    );
    const sortedOff = lodash.sortBy(activeOffices, 'salesRegion');
    const officeGroupBySalesRegion = lodash.groupBy(sortedOff, 'salesRegion');
    const officeGrpList: OfficeGroup[] = Object.keys(officeGroupBySalesRegion).map(
        (regionName) => {
            return {
                region: regionName,
                officeList: officeGroupBySalesRegion[regionName].map((obj) => ({
                    ...obj,
                    selected:
                        isEdit &&
                        selectedOfficeIds &&
                        selectedOfficeIds?.length > 0 &&
                        selectedOfficeIds?.includes(obj.id)
                            ? true
                            : false,
                })),
            };
        },
    );

    return officeGrpList;
};

export const getAllActiveOffices = (offices: OfficeLookupInfo[]): string[] => {
    const activeOffices = offices.filter(
        (off) => !off.name.includes('inactive') && typeof off.salesRegion === 'string',
    );
    return activeOffices.map((office) => office.id);
};
