import { getCall, postCall } from '../../utils/api/apiWrapper';
import { News, OfficeStatsModel, Stats } from './homePageModel';

export function getNewsApi(): Promise<News[]> {
    return getCall<News[]>(`home`);
}

export function getStatsApi(requestDetails: OfficeStatsModel): Promise<Stats> {
    return postCall(`stats`, requestDetails);
}
