import { AgentSelectedToBeBilled } from '../agentBilling/agentBilling';
import { SearchedListingListModel } from '../listing/commonModel';

/** Represents data for basic marketing items */
export interface MarketingData extends MarketingFields {
    /** ID of the optional additional marketing type, for additional promotions */
    additionalPromotionId?: number;

    /** The name of the additional marketing type (if supplied) */
    type?: string;
}

/** Represents email campaign marketing items */
export interface EmailCampaignData extends MarketingFields {
    views: number;
    clicks: number;
    audience: string;
    emailsSent: number;
}

/** Represents social campaign marketing items */
export interface SocialCampaignData extends MarketingFields {
    fromDate: string;
    toDate?: string;
    potentialReach?: number;
    views?: number;
    engagements?: number;
    clicks?: number;
}

/** Represents fields common across all marketing items */
export interface MarketingFields extends MarketingFileData {
    /** The ID of the marketing-related item (ex: the Id for regular
     * marketing items, or the ID of the email or social campaign record) */
    id?: string;

    /** The ID of the marketing item (only populated for special marketing
     * items like email/social campaigns) */
    marketingId?: string;

    /** The date of the marketing item */
    date?: string;

    /** The description of the marketing item */
    description?: string;

    /** The ID of the marketing's type */
    marketingTypeId?: number;
}

/** Represents file fields used by marketing items */
export interface MarketingFileData {
    /** Flag to indicate the Url field stores url link
     * or file name
     */
    isFile: boolean;

    /** The optional url associated with the marketing item
     * (either a GUID representing a file in our system, or a url
     * to an extenral site)
     */
    url?: string;

    /** The file attached via upload to the marketing item */
    file?: File;
}

/** Lookup values that map to the MarketingType table */
export const marketingTypeIds = {
    featuredPlacement: 10,
    emailCampaign: 12,
    advertisement: 14,
    socialCampaign: 15,
    additionalPromotion: 16,
};

/** Represents marketing type lookups from the db */
export interface MarketingTypes {
    /** The name of the marketing type */
    name: string;

    /** The ID of the marketing type */
    id: number;

    /** Flag indicating if this is a top level top (main marketing type)
     * or an additional promotion type (used for additional promotion
     * marketing items)
     */
    isTopLevel: boolean;
}

/**
 * Shared Other marketing field
 */
export interface SharedOtherMarketingField {
    /** set actualCost field */
    actualCost: number;
    /** set officeCost field */
    officeCost: number;
    /** set agentCost field */
    agentCost: number;
    /** set cit field */
    cit: boolean;
}

/**
 * Represents the other marketing create fields
 */

export interface OtherMarketing extends SharedOtherMarketingField {
    /** other marketing id field */
    id?: string;
    /** set marketingTypeId field */
    marketingTypeId: string;
    /** set additionalPromotionId field */
    additionalPromotionId?: string;
    /** set dateTime field */
    date: string;
    /** set description field */
    description: string;
    /** set publish field */
    publish: boolean;
    /** set billingCode field */
    billingCode: string;
    /** set isFile field */
    isFile: false;
    /** set file field */
    file: undefined;
    /** set url field */
    url: '';
    /** set isAgentPaid field */
    isAgentPaid: boolean;
    /** set agent billing field */
    agentMarketingCosts: AgentSelectedToBeBilled[];
    /** set listingIds field (listing number/listing id) */
    listings: SearchedListingListModel[];
    /** added when the agentMarketingCosts is updated in edit */
    isAmcChanged?: boolean;
    // email campaign
    /** set audience field */
    audience?: string;
    /** set emailsSent field */
    emailsSent?: number;
    /** set views field */
    views?: number;
    /** set date field */
    clicks?: number;
    /** set date field */
    // social campaign
    /** set toDate field */
    toDate?: string;
    /** set potentialReach field */
    potentialReach?: number;
    /** set engagements field */
    engagements?: number;
    /** createdby obtained on response */
    createdBy?: string;
}
