import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, displayReportToggle, deleteData } from '../../../cmrSlice';
import {
    currencyFormat,
    dateFormatMonthYear,
    dateFormatUTC,
    sortByDate,
} from '../../../../../utils/urlUtils';
import './index.scss';
import {
    AnalyticsCounts,
    SectionProps,
    AnalyticsGraph,
    SiteDetails,
    Analytics,
    GraphElement,
    GraphDate,
    WebsiteGraphData,
} from '../../../cmrModels';
import ToggleButton from '../../../../../shared/component/toggleButton/ToggleButton';
import Modal from '@material-ui/core/Modal';
import { getHeaderDetails } from '../util';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../../../shared/component/Window';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import WebsiteForm from './components/WebsiteForm';
import WebsiteList from './components/WebsiteList';
import {
    seriesColors,
    hasZeroViews,
    getOtherAddedSites,
    addZeroes,
    otherSitesGraphData,
    getGraphData,
    getInitialChartOptions,
    getTotalViewCount,
} from '../../../../../shared/functions/viewsOnWeb/utils';

const styles = makeStyles(() => ({
    closeGraphButton: {
        position: 'absolute',
        right: '25px',
        top: '7px',
        zIndex: 99,
    },
}));

const ViewsOnWeb: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, graph, panelInfo, listingId } =
        useAppSelector(cmrDetails);
    const [totalAnalyticViewCount, setTotalAnalyticViewCount] = useState<number>(0);
    const analytics = paneldata.viewsOnWeb as Analytics;
    const [analyticData, setAnalyticData] = useState<AnalyticsCounts[]>(
        analytics && analytics.counts ? analytics.counts : [],
    );
    const [editId, setEditId] = useState('0');
    const [open, setOpen] = useState<boolean>(false);
    const classes = styles();
    const { width, height } = useWindowDimensions();
    useEffect(() => {
        if (analytics && analytics.counts) {
            const totalViewCount = getTotalViewCount(analytics);
            const listAnalyticRecord = [
                ...totalViewCount.firstTwoRows,
                ...totalViewCount.otherSites,
            ];
            setTotalAnalyticViewCount(totalViewCount.viewTotalCount);
            setAnalyticData(listAnalyticRecord);
        }
    }, [analytics]);

    const [fromDate, setFromDate] = useState<string | null>('');
    const [toDate, setToDate] = useState<string | null>('');
    const [maxToDate, setMaxToDate] = useState('');
    const [minFromDate, setMinFromDate] = useState('');

    const initialChartOptions = getInitialChartOptions(
        setFromDate,
        setToDate,
        'Graph - Builder',
        'Click',
        'Time Period - ',
    );

    const [options, setOptions] = useState(initialChartOptions);

    let otherSites: AnalyticsCounts[] = [];
    let addedSites: AnalyticsCounts[] = [];
    if (analytics) {
        const { first, second } = getOtherAddedSites(analytics, 9);
        otherSites = first;
        addedSites = second;
    }

    let sorted: AnalyticsGraph[] = [];
    let otherSortedSites: AnalyticsGraph[] = [];
    if (analytics && analytics.graph) {
        sorted = sortByDate(analytics.graph, 'eventDate', true) || [];
        if (analytics.counts && analytics.counts.length > 0) {
            sorted = addZeroes(analytics, sorted);
        }

        if (otherSites.length > 0) {
            otherSortedSites = otherSitesGraphData(analytics, otherSites);
            if (otherSortedSites.length > 0) {
                otherSortedSites = sortByDate(otherSortedSites, 'eventDate', true) || [];
            }
        }
    }

    let maxElement: GraphElement = {
        name: '',
        totalView: 10,
    };
    const maxDate: GraphDate = {
        date: '',
    };
    const minDate: GraphDate = {
        date: '',
    };
    let availableSiteData: WebsiteGraphData[] = [];
    if (sorted) {
        const { first, second } = getGraphData(
            sorted,
            availableSiteData,
            maxElement,
            'Visits',
            false,
        );
        availableSiteData = first;
        maxElement = second;
    }
    if (otherSortedSites && otherSortedSites.length > 0) {
        const { first, second } = getGraphData(
            otherSortedSites,
            availableSiteData,
            maxElement,
            'All Other Websites',
            false,
        );
        availableSiteData = first;
        maxElement = second;
    }

    useEffect(() => {
        if (sorted) {
            let colorIndex = 2;
            sorted.forEach((data: AnalyticsGraph) => {
                data.details.forEach((detail: SiteDetails) => {
                    const ind = otherSites.findIndex(
                        (other: AnalyticsCounts) => detail.siteName === other.siteName,
                    );
                    if (ind === -1) {
                        const index = availableSiteData.findIndex(
                            (element: WebsiteGraphData) =>
                                element.name === detail.siteName &&
                                !hasZeroViews(detail.siteName, analytics),
                        );
                        if (index > -1) {
                            availableSiteData[index].data.push([
                                dateFormatUTC(data.eventDate),
                                detail.totalViews,
                            ]);
                            availableSiteData[index].timeStamp.push([
                                data.eventDate,
                                detail.totalViews,
                            ]);
                        } else {
                            availableSiteData.push({
                                name: detail.siteName,
                                data: [
                                    [dateFormatUTC(data.eventDate), detail.totalViews],
                                ],
                                timeStamp: [[data.eventDate, detail.totalViews]],
                                dataGrouping: {
                                    enabled: false,
                                },
                                color: seriesColors[colorIndex],
                                opacity: 1,
                            });
                            colorIndex++;
                        }
                    }
                    if (colorIndex === seriesColors.length - 1) {
                        colorIndex = 2;
                    }
                    if (maxElement.totalView < detail.totalViews) {
                        maxElement = {
                            totalView: detail.totalViews,
                            name: detail.siteName,
                        };
                    }
                    if (maxDate.date === '') {
                        maxDate.date = data.eventDate;
                    }
                    if (new Date(maxDate.date) < new Date(data.eventDate)) {
                        maxDate.date = data.eventDate;
                    }
                    if (minDate.date === '') {
                        minDate.date = data.eventDate;
                    }
                    if (new Date(minDate.date) > new Date(data.eventDate)) {
                        minDate.date = data.eventDate;
                    }
                });
            });
            setMaxToDate(dateFormatMonthYear(maxDate.date) || '');
            setMinFromDate(dateFormatMonthYear(minDate.date) || '');
            setOptions({
                ...options,
                series: availableSiteData,
                yAxis: {
                    ...options.yAxis,
                    max: maxElement.totalView + 5,
                },
                xAxis: {
                    ...options.xAxis,
                    max: new Date(dateFormatUTC(maxDate.date)),
                    startOnTick: true,
                },
            });
        }
    }, [analytics]);

    const viewGraph = useCallback(
        () => (
            <div className="viewGraph">
                <div className="dateRow">
                    <div className="dateRange">
                        <div className="date">
                            {fromDate && fromDate !== '' ? (
                                <span>{fromDate}</span>
                            ) : (
                                <span>{minFromDate}</span>
                            )}
                        </div>
                        <span>{'\u2013'}</span>
                        <div className="date">
                            {toDate && toDate !== '' ? (
                                <span>{toDate}</span>
                            ) : (
                                <span>{maxToDate}</span>
                            )}
                        </div>
                    </div>
                    <IconButton
                        aria-label="close"
                        className={classes.closeGraphButton}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className="chartWrapper">
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </div>
        ),
        [options, toDate, minFromDate, maxToDate, fromDate, classes.closeGraphButton],
    );

    const noData = (
        <div className="viewGraph">
            <IconButton
                aria-label="close"
                className={classes.closeGraphButton}
                onClick={() => setOpen(false)}
            >
                <CloseIcon />
            </IconButton>
            <div className="noData">No Data Available.</div>
        </div>
    );

    useEffect(() => {
        if (width >= 768 && width <= 1024) {
            setOptions((options) => ({
                ...options,
                chart: {
                    ...options.chart,
                    marginRight: 50,
                },
            }));
        } else if (width < 768) {
            setOptions((options) => ({
                ...options,
                chart: {
                    ...options.chart,
                    marginRight: 20,
                },
            }));
        } else {
            setOptions((options) => ({
                ...options,
                chart: {
                    ...options.chart,
                    marginRight: 100,
                },
            }));
        }
    }, [width, height]);

    useEffect(() => {
        const optionTags = document.getElementsByTagName('text');
        for (let i = 0; i < optionTags.length; i++) {
            if (optionTags[i].textContent === 'Zoom') {
                optionTags[i].style.display = 'none';
            }
        }
        if (width < 767) {
            const optionTags = document.getElementsByTagName('select');
            if (optionTags.length > 0) {
                for (let i = 0; i < optionTags[0].length; i++) {
                    if (optionTags[0][i].textContent === 'Zoom') {
                        optionTags[0][i].style.display = 'none';
                        optionTags[0][i].disabled = true;
                    }
                }
            }
        }
    }, [options, open, viewGraph, setOpen, width]);

    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const graphDetails = getHeaderDetails(panelInfo.graph.sectionId, graph);

    const deleteSite = (id: string) => {
        googleAnalyticsAction('Delete', 'Click', 'Views On Web Site Delete');
        const url = `ViewsOnWeb/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'id'));
    };

    const cancelClick = () => {
        setEditId('0');
    };

    const editItem = (id: string | undefined) => {
        if (id) {
            googleAnalyticsAction('Edit', 'Click', 'Views On Web Site Edit');
            setEditId(id);
        }
    };

    return (
        <div className="viwes-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Views on Web Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
            />
            <div className="inner-container">
                <div className="data-container">
                    <div className="table-heading">
                        <div className="title heading-text" onClick={() => setOpen(true)}>
                            Display graph on report
                        </div>
                        <Modal
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            {analytics &&
                            analytics.counts &&
                            analytics.counts.length > 0 &&
                            availableSiteData.length > 0
                                ? viewGraph()
                                : noData}
                        </Modal>
                        <div className="links heading-text">
                            <ToggleButton
                                isActive={graphDetails.displaySection}
                                data={graphDetails.id}
                                action={(id: string) => {
                                    googleAnalyticsAction(
                                        'Display on Report',
                                        'Slide',
                                        'Views on Web Display Graph on Report',
                                    );
                                    dispatch(displayReportToggle(id, true));
                                }}
                            />
                        </div>
                    </div>
                    <WebsiteForm
                        formEdit={false}
                        listingId={listingId}
                        sectionId={props.sectionId}
                        cancelClick={cancelClick}
                        addedSites={addedSites}
                    />
                    <div className="table-total-views">
                        <div className="col-1">
                            <div className="heading-text">
                                <b>TOTAL VIEWS</b>
                            </div>
                        </div>
                        <div
                            className={
                                addedSites.length > 0
                                    ? 'links heading-text'
                                    : 'heading-text'
                            }
                        >
                            {currencyFormat(totalAnalyticViewCount)}{' '}
                            {addedSites.length > 0 && (
                                <div className="icon-container"></div>
                            )}
                        </div>
                    </div>
                    <WebsiteList
                        analyticData={analyticData}
                        otherSites={otherSites}
                        addedSites={addedSites}
                        deleteItem={deleteSite}
                        listingId={listingId}
                        sectionId={props.sectionId}
                        cancelClick={cancelClick}
                        editItem={editItem}
                        editId={editId}
                    />
                </div>
            </div>
        </div>
    );
};
export default ViewsOnWeb;
