import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import {
    getSalesTeamDetails,
    updateSalesTeamDescriptionApi,
    getSalesTeamAssistants,
    getSalesTeamTestimonialsApi,
    saveSalesTeamTestimonialsApi,
} from './salesTeamApi';
import { editFormComponent, initialSalesTeamDetailData } from './salesTeamConstants';
import {
    SalesTeamDescription,
    SalesTeamDetail,
    SalesTeamDetailInitialState,
    SalesTeamMember,
    SalesTeamAssistant,
} from './salesTeamModels';
import loDash, { sortBy } from 'lodash';
import {
    DownloadedVideoStatus,
    VideoModel,
} from '../../../shared/models/video/videoModels';
import { UpdateFlag } from '../../../shared/models/flag/flagModel';
import { postSalesTeamVideoMetaData } from './drawer/screens/video/videoApi';
import { s3Upload } from '../../../shared/functions/video/s3Upload/s3Upload';
import {
    ingestVideo,
    shareVideo,
} from '../../../shared/api/video/brightcove/brightcoveApi';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import { getVideos, updateVideo } from '../../../shared/slices/video/videoSlice';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { ImageDetail, ImageDeleteData } from '../../../shared/models/images/sharedModel';
import { jsonToFormData } from '../../../utils/urlUtils';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../shared/slices/loader/loaderSlice';
import { PhotoEditorEvents } from '../../../shared/models/loader/loaderModels';
import { savePhoto, deletePhoto } from '../../../shared/api/images/sharedApi';
import {
    addAgentToSalesTeamApi,
    deleteAgentFromSalesTeamApi,
    updateAgentDisplayOrderFromSalesTeamApi,
} from './drawer/screens/members/membersApi';
import { updateSalesTeamList } from '../salesTeamListSlice';
import {
    deleteSalesTeamAssistantApi,
    addSalesTeamAssistantApi,
} from './drawer/screens/assistants/assistantsApi';
import {
    AgentDNDItem,
    AgentReorderRequest,
    AgentSearchResult,
} from '../../../shared/models/agent/agentModel';
import { getAgentsByLastName } from '../../../shared/api/agent/agentApi';
import {
    UserSearchRequest,
    UserSearchResponse,
} from '../../../shared/models/users/userModels';
import { searchUsers } from '../../../shared/api/users/usersApi';
import { getDomainInfoBasedOnEntity } from '../../../shared/api/domain/domain';
import { GetDomainInfo } from '../../../shared/models/domain/domain';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';
import { SaleTeamTestimonialModel } from '../../../shared/models/testimonial/testimonial';
import { User } from '../../../shared/auth/authModels';
import { updateSalesTeamCmsTestimonialsList } from '../../../shared/api/testimonials/testimonialsApi';
import { displayErrorForSalesTeamEditScreen } from './salesTeamUtils';

export const salesTeamDetailInitialState: SalesTeamDetailInitialState = {
    data: initialSalesTeamDetailData,
    editForm: {
        formOpen: false,
        editFormName: '',
        salesTeamEditFormName: '',
    },
    flags: {
        loadingDetails: false,
        uploadingLargePhoto: false,
        searchingMembers: false,
        searchingAssistants: false,
    },
    statuses: {
        downloadedVideo: { from: '', progress: 0 },
    },
    searchedMembers: [],
    searchedUsers: [],
};

const isFormDataChanged = (
    currentState: SalesTeamDescription | VideoModel[],
    initialState: SalesTeamDetail | VideoModel[],
): boolean => {
    let flag = false;
    if (currentState) {
        for (const [key, value] of Object.entries(currentState)) {
            if (
                initialState.hasOwnProperty(key) &&
                !loDash.isEqual(value, initialState[key as keyof typeof initialState])
            ) {
                flag = true;
                break;
            }
        }
    }
    return flag;
};

const validateToUpdateList = (
    currentState: SalesTeamDescription,
    initialState: SalesTeamDetail,
) => {
    const keyToVal = ['name', 'canShareProperties', 'officeId'];
    const curr = keyToValidate(currentState, keyToVal);
    const init = keyToValidate(initialState, keyToVal);
    if (!loDash.isEqual(init, curr)) {
        return {
            salesTeamId: currentState?.id || '',
            officeName: currentState?.office || '',
            teamName: currentState?.name || '',
            canShareProperties: currentState.canShareProperties,
        };
    } else {
        return null;
    }
};

export const keyToValidate = (
    data: SalesTeamDescription | SalesTeamDetail,
    keyToVal: string[],
): Partial<SalesTeamDescription | SalesTeamDetail> => {
    return loDash.pickBy(data, (value, key) => loDash.includes(keyToVal, key));
};

// // Gets data from the current agent details open in the side drawer component
// // and saves it to the db
export const saveSalesTeamEditFormData =
    (state: SalesTeamDetailInitialState, editFormName?: string): AppThunk =>
    async (dispatch) => {
        let isChanged = true;
        let isValid = true;
        if (state.editForm?.currentFormData) {
            switch (state.editForm?.salesTeamEditFormName) {
                case editFormComponent.description:
                    const newState = state.editForm
                        ?.currentFormData as SalesTeamDescription;
                    const { name, email, office, description } = newState;
                    const result = displayErrorForSalesTeamEditScreen({
                        name,
                        email,
                        office: office || '',
                        description,
                    });
                    isChanged = isFormDataChanged(
                        state.editForm?.currentFormData as SalesTeamDescription,
                        state.data,
                    );
                    if (isChanged) {
                        isValid = result.isValid;
                        if (isValid) {
                            dispatch(
                                updateSalesTeamDescription(
                                    state.editForm
                                        ?.currentFormData as SalesTeamDescription,
                                ),
                            );

                            const toUpdateList = validateToUpdateList(
                                state.editForm?.currentFormData as SalesTeamDescription,
                                state.data as SalesTeamDetail,
                            );

                            if (toUpdateList && Object.keys(toUpdateList).length > 0) {
                                dispatch(updateSalesTeamList(toUpdateList));
                            }
                        } else {
                            dispatch(setError(result.errorMessage));
                        }
                    }
                    break;
                case editFormComponent.video:
                    const videos = state.editForm?.currentFormData as VideoModel;
                    if (isFormDataChanged([{ ...videos }], state.data.videos)) {
                        dispatch(updateVideo(videos, entityTypes.salesTeam.key));
                    }
            }
        }
        if (isValid) {
            if (editFormName) dispatch(updateEditFormName(editFormName));
            else dispatch(closeEditForm());
        }
    };

export const salesTeamDetailSlice = createSlice({
    name: 'salesTeamDetail',
    initialState: salesTeamDetailInitialState,
    reducers: {
        setInitialState: (state) => {
            return {
                ...state,
                data: initialSalesTeamDetailData,
                editForm: {
                    formOpen: false,
                    editFormName: '',
                    salesTeamEditFormName: '',
                },
                flags: {
                    ...state.flags,
                    loadingDetails: false,
                },
            };
        },
        updateSalesTeamDetails: (state, action: PayloadAction<SalesTeamDetail>) => {
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        },
        // Sets the name of the component to open in the side drawer
        updateEditFormName: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                editForm: {
                    ...state.editForm,
                    formOpen: true,
                    editFormName: action.payload,
                    salesTeamEditFormName: '',
                },
            };
        },
        // Fired when the user wants to close the side drawer component
        closeEditForm: (state, action: PayloadAction) => {
            return {
                ...state,
                editForm: {
                    ...state.editForm,
                    formOpen: false,
                    editFormName: '',
                    salesTeamEditFormName: '',
                },
            };
        },
        // Sets the data currently being edited in the agent edit form open
        // in the side drawer component
        setCurrentSalesTeamEditFormData: (
            state,
            action: PayloadAction<{
                formData: SalesTeamDescription | VideoModel;
                salesTeamEditFormName: string;
            }>,
        ) => {
            return {
                ...state,
                editForm: {
                    ...state.editForm,
                    formOpen: state.editForm?.formOpen || false,
                    editFormName: state.editForm?.editFormName || '',
                    salesTeamEditFormName: action.payload.salesTeamEditFormName,
                    currentFormData: action.payload.formData,
                },
            };
        },
        // Flags
        updateFlag: (state, action: PayloadAction<UpdateFlag>) => {
            state.flags[
                action.payload.property as keyof typeof salesTeamDetailInitialState.flags
            ] = action.payload.value;
        },
        // Sales Team Video
        updateSalesTeamVideoStatus: (
            state,
            action: PayloadAction<{ videoTypeId: number }>,
        ) => {
            const videos = state.data.videos.map((video: VideoModel) => {
                return video.videoTypeId === action.payload.videoTypeId
                    ? { ...video, status: 2 } // In Progress Status
                    : video;
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    videos: videos,
                },
            };
        },
        updateSalesTeamVideo: (state, action: PayloadAction<Array<VideoModel>>) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    videos: action.payload,
                },
            };
        },
        updateDownloadedSalesTeamVideoStatus: (
            state,
            action: PayloadAction<DownloadedVideoStatus>,
        ) => {
            return {
                ...state,
                statuses: {
                    downloadedVideo: {
                        from: action.payload.from,
                        progress: action.payload.progress,
                    },
                },
            };
        },
        addSalesTeamAgent: (state, action: PayloadAction<SalesTeamMember>) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    teamMembers: [...state.data.teamMembers, action.payload],
                },
            };
        },
        updateOrderedSalesTeamAgents: (
            state,
            action: PayloadAction<SalesTeamMember[]>,
        ) => {
            if (action.payload.length > 0) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        teamMembers: action.payload,
                    },
                };
            }
        },
        deleteSalesTeamAgent: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    teamMembers: state.data.teamMembers.filter(
                        (salesTeamAgent: SalesTeamMember) => {
                            return salesTeamAgent.id !== action.payload;
                        },
                    ),
                },
            };
        },
        setSearchedMembers: (state, action: PayloadAction<AgentSearchResult[]>) => {
            return {
                ...state,
                searchedMembers: action.payload,
            };
        },
        setSearchedUsers: (state, action: PayloadAction<UserSearchResponse[]>) => {
            return {
                ...state,
                searchedUsers: action.payload,
            };
        },
        updateTeamDomainDetails: (
            state,
            action: PayloadAction<GetDomainInfo | undefined>,
        ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    teamDomainInfo: action.payload,
                },
            };
        },
    },
});

export const fetchSalesTeamDetails =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateFlag({
                    property: 'loadingDetails',
                    value: true,
                }),
            );
            const response = await getSalesTeamDetails(id);
            if (response) {
                dispatch(updateSalesTeamDetails(response));
            }
        } catch (exception) {
            Logger.error('Failed to get sales team details');
            dispatch(setError(`Failed to get sales team details`));
        } finally {
            dispatch(
                updateFlag({
                    property: 'loadingDetails',
                    value: false,
                }),
            );
        }
    };

export const fetchSalesTeamAssistants =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await getSalesTeamAssistants(id);
            if (response?.length) {
                const currentSalesTeamDetailData = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...currentSalesTeamDetailData,
                        salesTeamAssistants: response,
                    }),
                );
            }
        } catch (e) {
            Logger.error(`Failed to get SalesTeam assistans `, e);
            dispatch(setError(`Failed to get SalesTeam assistans ${e}`));
        }
    };

export const addSalesTeamAssistant =
    (salesTeamId: string, assistant: SalesTeamAssistant): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await addSalesTeamAssistantApi(salesTeamId, assistant);
            if (response !== '') {
                const currentSalesTeamDetailData = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...currentSalesTeamDetailData,
                        salesTeamAssistants: [
                            ...currentSalesTeamDetailData.salesTeamAssistants,
                            { ...assistant, id: response },
                        ],
                    }),
                );
                dispatch(setSuccess('Sales team assistant added'));
            } else {
                dispatch(setError(`Error adding sales team assistant`));
            }
        } catch (exception) {
            Logger.error(`Failed to add sales team assistant `, exception);
            dispatch(setError(`Failed to add sales team assistant`));
        }
    };

export const deleteSalesTeamAssistant =
    (salesTeamId: string, salesTeamAssistantId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await deleteSalesTeamAssistantApi(
                salesTeamId,
                salesTeamAssistantId,
            );
            if (response) {
                const currentSalesTeamDetailData = getState().salesTeam.details.data;
                const filteredAgentAssistants =
                    currentSalesTeamDetailData.salesTeamAssistants.filter(
                        (agent: SalesTeamAssistant) => {
                            return agent.id !== salesTeamAssistantId;
                        },
                    );
                dispatch(
                    updateSalesTeamDetails({
                        ...currentSalesTeamDetailData,
                        salesTeamAssistants: filteredAgentAssistants,
                    }),
                );
                dispatch(setSuccess('Sales team assistant deleted'));
            } else {
                dispatch(setError('Error deleting sales team assistant'));
            }
        } catch (exception) {
            Logger.error(`Failed to delete sales team assistant `, exception);
            dispatch(setError(`Failed to delete sales team assistant`));
        }
    };

export const updateSalesTeamDescription =
    (salesTeamDescription: SalesTeamDescription): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { office, phoneNumberDisplay, ...salesTeamDescriptionDetails } =
                salesTeamDescription;
            const response = await updateSalesTeamDescriptionApi(
                salesTeamDescriptionDetails,
            );
            const currentSalesTeamDetailData = getState().salesTeam.details.data;
            if (response.status === 204) {
                const updatedData = {
                    ...currentSalesTeamDetailData,
                    ...salesTeamDescription,
                    office: salesTeamDescription.office,
                    phoneNumberDisplay:
                        Number(salesTeamDescription.phoneNoDisplayOrder) === 1
                            ? 'Primary Agent'
                            : Number(salesTeamDescription.phoneNoDisplayOrder) === 2
                            ? 'Both Agents'
                            : 'Custom',
                };
                dispatch(updateSalesTeamDetails(updatedData));
                dispatch(setSuccess('Sales team details are updated'));
            } else {
                dispatch(setError('Sales team details are not updated'));
            }
        } catch {
            dispatch(setError('Error occured while updating the sales team details'));
        }
    };

/*
 * Sales Team Edit Screen
 */

export const uploadSalesTeamVideo =
    (teamId: string, file: File, abortController: AwsAbortController): AppThunk =>
    async (dispatch) => {
        try {
            const s3Details = await postSalesTeamVideoMetaData(teamId, {
                videoType: videoTypes.salesTeamVideo.value,
                fileName: file.name,
            });
            if (s3Details) {
                dispatch(getVideos(teamId, entityTypes.salesTeam.key));
                const s3Uploaded = await s3Upload(file, s3Details, abortController);
                if (s3Uploaded === 'success') {
                    const apiUrl = process.env.REACT_APP_APIURL;
                    const masterURL = {
                        master: {
                            url: s3Details.api_Request_Url,
                        },
                        callbacks: [`${apiUrl}/brightcove/callback`],
                    };
                    const injestResponse = await ingestVideo(
                        masterURL,
                        s3Details.video_Id,
                    );
                    if (injestResponse) {
                        const sira = [{ id: '5699924528001' }];
                        await shareVideo(s3Details.video_Id, sira);
                    } else {
                        dispatch(setError('Injest request failed'));
                    }
                }
            }
        } catch (err) {
            Logger.error(`Error in Video Upload: ${JSON.stringify(err)}`);
            dispatch(setError('Error in Video Upload'));
        }
    };

export const uploadSalesTeamPhoto =
    (request: ImageDetail, isCropped = false): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateFlag({
                    property: 'uploadingLargePhoto',
                    value: true,
                }),
            );
            const data = jsonToFormData(request);
            const response = await savePhoto(data);
            if (response) {
                const salesTeamDetails = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...salesTeamDetails,
                        largePhoto: response.originalUrl,
                    }),
                );
                dispatch(
                    setSuccess(
                        `Photo is ${isCropped ? 'cropped' : 'uploaded'} successfully`,
                    ),
                );
                // Sales Team list slice update
                const currentState = getState().salesTeam
                    .details as SalesTeamDetailInitialState;
                const sliceData = {
                    salesTeamId: currentState.data.id
                        ? currentState.data.id.toString()
                        : '',
                    officeName: currentState.data.office ? currentState.data.office : '',
                    teamName: currentState.data.name,
                    canShareProperties: currentState.data.canShareProperties,
                    imageExtension:
                        response.originalUrl.substring(response.originalUrl.length - 4) ||
                        '',
                    imageUrlId:
                        response.originalUrl.slice(
                            response.originalUrl.indexOf('salesteam-large/') + 16,
                            response.originalUrl.lastIndexOf('.'),
                        ) || '',
                };
                dispatch(updateSalesTeamList(sliceData));
            }
        } catch (e) {
            Logger.error(`Failed to upload the photo : ${JSON.stringify(e)}`);
            dispatch(setError('Photo is not uploaded'));
        } finally {
            dispatch(
                updateFlag({
                    property: 'uploadingLargePhoto',
                    value: false,
                }),
            );
        }
    };

export const deleteSalesTeamLogo =
    (deleteData: ImageDeleteData, toaster = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
            const response = await deletePhoto(deleteData);
            if (response) {
                const salesTeamDetails = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...salesTeamDetails,
                        largePhoto: '',
                    }),
                );
                // toaster messages are restrcited when cropping agent image
                // old image will be deleted first and cropped image will be saved
                if (toaster) {
                    dispatch(setSuccess('Photo is deleted successfully'));
                }
                // Sales Team list slice update
                const currentState = getState().salesTeam
                    .details as SalesTeamDetailInitialState;
                const sliceData = {
                    salesTeamId: currentState.data.id
                        ? currentState.data.id.toString()
                        : '',
                    officeName: currentState.data.office ? currentState.data.office : '',
                    teamName: currentState.data.name,
                    canShareProperties: currentState.data.canShareProperties,
                    imageUrlId: '',
                    imageExtension: '',
                };
                dispatch(updateSalesTeamList(sliceData));
            }
        } catch (e) {
            Logger.error(`Failed to delete the photo : ${JSON.stringify(e)}`);
            dispatch(setError('Photo is not deleted'));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
        }
    };

// Sales Team Members Edit Screen

export const addAgentToSalesTeam =
    (teamId: string, agentId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await addAgentToSalesTeamApi(teamId, agentId);
            if (response.id !== '') {
                dispatch(addSalesTeamAgent(response));
                dispatch(setSuccess('Sales team agent is added'));
            }
        } catch (e) {
            Logger.error(`Failed to add a sales team agent`, e);
            dispatch(setError(`Failed to add a sales team agent`));
        }
    };

export const updateAgentDisplayOrderFromSalesTeam =
    (
        teamId: string,
        updatedAgents: AgentDNDItem[],
        existingAgents: SalesTeamMember[],
    ): AppThunk =>
    async (dispatch) => {
        try {
            const salesTeamDisplayOrders: AgentReorderRequest[] = updatedAgents.map(
                (salesTeamAgent: AgentDNDItem) => {
                    return {
                        agentId: salesTeamAgent.agentId,
                        displayOrder: salesTeamAgent.displayOrder,
                    };
                },
            );
            const response = await updateAgentDisplayOrderFromSalesTeamApi(
                teamId,
                salesTeamDisplayOrders,
            );
            if (response.status === 204) {
                let members: SalesTeamMember[] = [];
                updatedAgents.forEach((agent) => {
                    let member = existingAgents.find((ex) => ex.id === agent.agentId);
                    if (member) {
                        member = { ...member, displayOrder: agent.displayOrder };
                        members = [...members, member];
                    }
                });
                dispatch(updateOrderedSalesTeamAgents(members));
                dispatch(setSuccess('Sales team display orders are updated'));
            }
        } catch (e) {
            Logger.error(`Failed to update the sales team agents orders`, e);
            dispatch(setError(`Failed to update the sales team agents orders`));
        }
    };

export const deleteAgentFromSalesTeam =
    (teamId: string, agentId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await deleteAgentFromSalesTeamApi(teamId, agentId);
            if (response) {
                dispatch(deleteSalesTeamAgent(agentId));
                dispatch(setSuccess('Sales team agent is deleted'));
            }
        } catch (e) {
            Logger.error(`Failed to delete a sales team agent`, e);
            dispatch(setError(`Failed to delete a sales team agent`));
        }
    };

export const fetchAgent =
    (searchTerm: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateFlag({ property: 'searchingMembers', value: true }));
            const response = await getAgentsByLastName(searchTerm);
            if (response.length > 0) {
                const existingAgentIds =
                    getState().salesTeam.details.data.teamMembers.map((t) => t.id);
                dispatch(
                    setSearchedMembers(
                        response.filter((agents) => {
                            return existingAgentIds.every(
                                (agentId) => agents.agentId !== agentId,
                            );
                        }),
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the agent list`));
            Logger.error(`Failed to fetch the agent list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateFlag({ property: 'searchingMembers', value: false }));
        }
    };

export const getUsers =
    (searchTerm: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateFlag({ property: 'searchingAssistants', value: true }));
            const request: UserSearchRequest = {
                currentPage: 1,
                itemsPerPage: 30,
                email: searchTerm,
            };
            const response = await searchUsers(request);
            if (
                response.currentPage ||
                response.totalRecords ||
                response.recordsPerPage ||
                response.results.length > 0
            ) {
                const existingUserIds =
                    getState().salesTeam.details.data.salesTeamAssistants.map(
                        (u) => u.userId || '',
                    );
                dispatch(
                    setSearchedUsers(
                        response.results.filter((result: UserSearchResponse) => {
                            return existingUserIds.every((userId) => {
                                return userId !== result.userId;
                            });
                        }),
                    ),
                );
            }
        } catch (e) {
            Logger.error(`Failed to get the users: ${JSON.stringify(e)}`);
            dispatch(setError('Failed to get the users'));
        } finally {
            dispatch(updateFlag({ property: 'searchingAssistants', value: false }));
        }
    };

export const fetchTeamDomainInfo =
    (teamId: string, siteType: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getDomainInfoBasedOnEntity(teamId, siteType);
            if (response && response.domainUrl) {
                dispatch(updateTeamDomainDetails(response));
            } else {
                dispatch(updateTeamDomainDetails(undefined));
            }
        } catch {
            Logger.error(
                `Cannot fetch sales team domain information: ${JSON.stringify({
                    teamId: teamId,
                    siteType: siteType,
                })}`,
            );
        }
    };

export const getSalesTeamTestimonials =
    (teamId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await getSalesTeamTestimonialsApi(teamId);
            if (response?.length) {
                const currentSalesTeamDetailData = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...currentSalesTeamDetailData,
                        testimonials: response,
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to fetch sales team testimonials' + e);
            dispatch(setError(`Failed to fetch sales team testimonials`));
        }
    };

export const saveSalesTeamTestimonials =
    (
        from: string,
        teamId: string,
        data: SaleTeamTestimonialModel[],
        user: User,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await saveSalesTeamTestimonialsApi(
                teamId,
                sortBy(data, 'displayOrder'),
            );
            if (response) {
                const cmsList = data.map((list) => ({
                    displayOrder: list.displayOrder,
                    testimony: list.testimony,
                    author: list.author,
                }));

                updateSalesTeamCmsTestimonialsList(
                    2,
                    teamId,
                    sortBy(cmsList, 'displayOrder'),
                    user.oktaId,
                );
                const currentSalesTeamDetailData = getState().salesTeam.details.data;
                dispatch(
                    updateSalesTeamDetails({
                        ...currentSalesTeamDetailData,
                        testimonials: response,
                    }),
                );

                if (from === 'add') {
                    dispatch(setSuccess(`Testimonial have been added successfully`));
                } else if (from === 'edit') {
                    dispatch(setSuccess(`Testimonial have been edited successfully`));
                } else if (from === 'delete') {
                    dispatch(setSuccess(`Testimonial have been deleted successfully`));
                } else {
                    dispatch(setSuccess(`Testimonial have been reordered successfully`));
                }
            }
        } catch (e) {
            Logger.error('Failed to fetch sales team testimonials' + e);
            dispatch(setError(`Failed to update sales team testimonials`));
        }
    };
export const {
    setInitialState,
    updateSalesTeamDetails,
    updateEditFormName,
    closeEditForm,
    setCurrentSalesTeamEditFormData,
    updateSalesTeamVideoStatus,
    updateFlag,
    updateSalesTeamVideo,
    updateDownloadedSalesTeamVideoStatus,
    addSalesTeamAgent,
    updateOrderedSalesTeamAgents,
    deleteSalesTeamAgent,
    setSearchedMembers,
    setSearchedUsers,
    updateTeamDomainDetails,
} = salesTeamDetailSlice.actions;

export const salesTeamDetail = (state: RootState): SalesTeamDetailInitialState =>
    state.salesTeam.details;

export default salesTeamDetailSlice.reducer;
