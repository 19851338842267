/**
 * function to validate the basic requirement to edit and save the developments
 * @param formData
 * @returns
 */

import { DevelopmentEditScreenRequiredFields } from '../../../developmentDetailsModel';

export const displayErrorForDevelopmentEditScreen = (
    formData: DevelopmentEditScreenRequiredFields,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;
    if (!formData.title)
        result = { isValid: false, errorMessage: result.errorMessage + 'Title,' };
    if (!formData.officeId)
        result = { isValid: false, errorMessage: result.errorMessage + 'Office,' };
    if (!formData.address1)
        result = { isValid: false, errorMessage: result.errorMessage + 'Address1,' };
    if (!formData.city)
        result = { isValid: false, errorMessage: result.errorMessage + 'City,' };
    if (!formData.regionId)
        result = { isValid: false, errorMessage: result.errorMessage + 'State,' };
    if (!formData.zip)
        result = { isValid: false, errorMessage: result.errorMessage + 'Zip,' };
    if (!formData.listDate)
        result = { isValid: false, errorMessage: result.errorMessage + 'List Date,' };
    if (!formData.expirationDate)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Expiration Date,',
        };
    if (!formData.agreementDate)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Agreement Date,',
        };
    if (!formData.websiteUrl)
        result = { isValid: false, errorMessage: result.errorMessage + 'Website URL,' };
    if (!formData.totalUnits)
        result = { isValid: false, errorMessage: result.errorMessage + 'Total Units,' };
    if (!formData.totalDollarValue)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Development Value,',
        };
    if (!formData.minimumPricePerUnit)
        result = {
            isValid: false,
            errorMessage: result.errorMessage + 'Minimum Offering Price Per Unit,',
        };

    if (!result.errorMessage) {
        if (formData?.zip?.toString().length !== 5)
            result = { isValid: false, errorMessage: 'Zip should be five digits' };
        else result = { isValid: true, errorMessage: '' };
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };

    return result;
};
