import { getApiCall, makeAPICall } from '../../../utils/api/apiWrapper';
import { MlsImportMessage, MlsListingDetails } from './mlsDetailsModels';

export function getMlsDetails(url: string): Promise<MlsListingDetails> {
    return getApiCall<MlsListingDetails>(url);
}

export function importMlsListing(
    url: string,
    message: MlsImportMessage,
): Promise<Response> {
    return makeAPICall(
        url,
        {
            method: 'POST',
            body: JSON.stringify(message),
            headers: { 'Content-Type': 'application/json' },
        },
        'raw',
    );
}
