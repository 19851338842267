import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    MasqueradeKeyState,
    MasqueradeState,
    UpdateMasqueradeState,
} from './masqueradeModels';
import { AppThunk, RootState } from '../../app/store';
import {
    UserSearchRequest,
    UserSearchResponse,
} from '../../shared/models/users/userModels';
import { getUsersToMasquerade } from './masqueradeApi';
import { User } from '../../shared/auth/authModels';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import Logger from '../../utils/logging/logger';
import { getAgentsForAssistantApi } from '../users/userDetails/userDetailsApi';

const initialMasqueradeState: MasqueradeState | MasqueradeKeyState = {
    searchedUsers: [],
    searchingUsers: false,
    assistantTo: [],
};

export const masqueradeSlice = createSlice({
    name: 'masquerade',
    initialState: initialMasqueradeState,
    reducers: {
        updateMasqueradeState: (state, action: PayloadAction<UpdateMasqueradeState>) => {
            state[action.payload.key as keyof typeof state] = action.payload.value;
        },
    },
});

export const getUsers =
    (request: UserSearchRequest, user: User): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateMasqueradeState({
                    key: 'searchingUsers',
                    value: true,
                }),
            );
            const response = await getUsersToMasquerade(request);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            ) {
                dispatch(
                    updateMasqueradeState({
                        key: 'searchedUsers',
                        value: response.results.filter((result: UserSearchResponse) => {
                            return result.brandEmail !== user.email;
                        }),
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to get the users', e);
            dispatch(setError('Failed to get the users'));
        } finally {
            dispatch(
                updateMasqueradeState({
                    key: 'searchingUsers',
                    value: false,
                }),
            );
        }
    };

export const fetchAssistantTo =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateMasqueradeState({
                    key: 'searchingUsers',
                    value: true,
                }),
            );
            const response = await getAgentsForAssistantApi(userId);
            if (response && response.length > 0) {
                dispatch(
                    updateMasqueradeState({
                        key: 'assistantTo',
                        value: response,
                    }),
                );
            } else {
                dispatch(
                    updateMasqueradeState({
                        key: 'assistantTo',
                        value: [],
                    }),
                );
            }
        } catch (e) {
            Logger.error('Failed to get the users', e);
            dispatch(setError('Failed to get the users'));
        } finally {
            dispatch(
                updateMasqueradeState({
                    key: 'searchingUsers',
                    value: false,
                }),
            );
        }
    };

export const { updateMasqueradeState } = masqueradeSlice.actions;

export const masquerade = (state: RootState): MasqueradeState | MasqueradeKeyState =>
    state.masquerade;

export default masqueradeSlice.reducer;
