import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import Logger from '../../utils/logging/logger';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../shared/slices/loader/loaderSlice';
import {
    CmrSummary,
    SortingOrder,
    Passing,
    CmrHeader,
    Notes,
    DeleteStructure,
    ReportToggleParams,
    Showing,
    SpinnerState,
    OpenHouseData,
    AdvertisementData,
    ListingImage,
    UpdatePanelItem,
    ListingImageSelected,
    WebsiteData,
    AnalyticsCounts,
    UpdateSiteData,
    ImageVideoModel,
    Analytics,
} from './cmrModels';
import {
    fetchCmrSortingByListingId,
    fetchPanelDetails,
    fetchHeaderDetails,
    toggleDisplaySection,
    updateSortingOrder,
    updateNotes,
    deleteDBData,
    addShowingsData,
    addOpenHouseData,
    addAdv,
    editAdv,
    updateShowing,
    updateOpenHouses,
    getListingImages,
    updateSelectedImage,
    addWebSiteData,
    updateWebSiteData,
} from './cmrAPI';
import {
    addMarketing,
    updateMarketing,
    getMarketingTypes,
    deleteFile,
} from '../../shared/api/marketing/marketingApis';
import { CmrEvents } from '../../shared/models/loader/loaderModels';
import { panelDetails, basedOnSectionId } from './cmrConstant';
import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import { jsonToFormData } from '../../utils/urlUtils';
import {
    MarketingData,
    EmailCampaignData,
    SocialCampaignData,
    MarketingTypes,
    MarketingFields,
} from '../../shared/models/marketing/marketingModels';
import {
    setPastList,
    setUpcomingList,
} from '../listings/listing/marketing/propertyMarketingListSlice';
import { formatEventDate } from '../../shared/functions/viewsOnWeb/utils';

export const initialCMR: CmrSummary = {
    paneldata: {
        viewsOnWeb: null,
        inTheNews: null,
        advertisements: null,
        showings: null,
        emailCampaigns: null,
        socialCampaigns: null,
        openHouses: null,
        notes: null,
        featuredPlacements: null,
        additionalPromotion: null,
    },
    listingdata: {
        photoUrl: '',
        state: '',
        streetAddress: '',
        city: '',
        listingDate: '',
        photoIsLandscape: false,
        salesRegionId: 0,
        videoUrl: '',
        videoSelected: false,
        id: '',
        officeId: 0,
    },
    listingId: '',
    sortOrder: [],
    currentOrderElement: 0,
    graph: [],
    panelSpinner: false,
    panelSpinnerRight: false,
    panelInfo: {
        /*
            DnDRule - 
            viewsOnWeb always remain Parent,
            Showing and OpenHouse and can be parent and child only to those not to other 
            AdditionalPromotion will be parent making all the tile as child except showing, Notes ,Openhouse and viewsOnWeb
        */
        // condition -> Views on Web will remain parent
        viewsOnWeb: {
            sectionId: panelDetails.viewsOnWeb.id,
            dndRule: null,
        },
        inTheNews: {
            sectionId: panelDetails.inTheNews.id,
            dndRule: null,
        },
        advertisements: {
            sectionId: panelDetails.advertisements.id,
            dndRule: null,
        },
        // condition -> showing can allow only openHouse
        showings: {
            sectionId: panelDetails.showings.id,
            dndRule: panelDetails.openHouses.id,
        },
        // condition -> OpenHouse can allow only showing
        openHouses: {
            sectionId: panelDetails.openHouses.id,
            dndRule: panelDetails.showings.id,
        },
        emailCampaigns: {
            sectionId: panelDetails.emailCampaigns.id,
            dndRule: null,
        },
        socialCampaigns: {
            sectionId: panelDetails.socialCampaigns.id,
            dndRule: null,
        },
        featuredPlacements: {
            sectionId: panelDetails.featuredPlacements.id,
            dndRule: null,
        },
        // condition -> AdditionalPromotion will not include openHouse, Showing, notes(Broker's Notes) and viewsOnWeb
        additionalPromotion: {
            sectionId: panelDetails.additionalPromotion.id,
            dndRule: [
                panelDetails.openHouses.id,
                panelDetails.showings.id,
                panelDetails.viewsOnWeb.id,
                panelDetails.notes.id,
            ],
        },
        notes: {
            sectionId: panelDetails.notes.id,
            dndRule: null,
        },
        graph: {
            sectionId: panelDetails.graph.id,
            dndRule: null,
        },
        containerText: {
            container: [
                panelDetails.openHouses.id,
                panelDetails.showings.id,
                panelDetails.additionalPromotion.id,
            ],
        },
    },
    additionalPromotionTypes: [],
    listingImage: {
        id: 0,
        caption: '',
        url: '',
        displayStatus: false,
        assetId: '',
        landscapeRatio: false,
    },
    listingImages: [],
};

export const cmrSlice = createSlice({
    name: 'cmr',
    initialState: initialCMR,
    reducers: {
        loadSortingData: (state, action: PayloadAction<SortingOrder[]>) => {
            state.sortOrder = [];
            state.graph = [];
            state.graph = [
                ...action.payload.filter((element) => {
                    return element.sectionId === state.panelInfo.graph.sectionId; // sectionId is 11 checking
                }),
            ];
            state.sortOrder = [
                ...action.payload.filter((sortingRecord) => {
                    return sortingRecord.sectionId !== state.panelInfo.graph.sectionId; // sectionId is 11 checking
                }),
            ];
            if (state.currentOrderElement === 0)
                state.currentOrderElement = state.sortOrder[0].sectionId;
        },
        updatePanelData: (state, action: PayloadAction<Passing>) => {
            state.paneldata[action.payload.name as keyof typeof initialCMR.paneldata] =
                action.payload.name === 'viewsOnWeb'
                    ? formatEventDate(action.payload.data as Analytics)
                    : action.payload.data;
        },
        updateCurrentElement: (state, action: PayloadAction<number>) => {
            state.currentOrderElement = action.payload;
        },
        updateListingId: (state, action: PayloadAction<string>) => {
            state.listingId = action.payload;
        },
        updateListingData: (state, action: PayloadAction<CmrHeader>) => {
            state.listingdata = action.payload;
        },
        updateDisplayReport: (state, action: PayloadAction<ReportToggleParams>) => {
            if (action.payload.flag) {
                const updatedGraph = state.graph;
                const index = state.graph.findIndex(
                    (element) => element.id === action.payload.id,
                );
                updatedGraph[index].displaySection = !updatedGraph[index].displaySection;
                state.graph = [...updatedGraph];
            } else {
                const updatedSortOrder = state.sortOrder;
                const index = state.sortOrder.findIndex(
                    (element) => element.id === action.payload.id,
                );
                if (index < 0) {
                    state.sortOrder.map((element: SortingOrder, currentIndex: number) => {
                        const childIndex = element.childSections.findIndex(
                            (child) => child.id === action.payload.id,
                        );
                        if (childIndex >= 0) {
                            updatedSortOrder[currentIndex].childSections[
                                childIndex
                            ].displaySection =
                                !updatedSortOrder[currentIndex].childSections[childIndex]
                                    .displaySection;
                            state.sortOrder = [...updatedSortOrder];
                        }
                        return null;
                    });
                } else {
                    updatedSortOrder[index].displaySection =
                        !updatedSortOrder[index].displaySection;
                    state.sortOrder = [...updatedSortOrder];
                }
            }
        },
        updateRecordInListById: (state, action: PayloadAction<UpdatePanelItem>) => {
            const payloadData = action.payload.data as
                | Showing
                | MarketingData
                | EmailCampaignData
                | SocialCampaignData
                | AdvertisementData
                | OpenHouseData;
            const sectionName = basedOnSectionId(action.payload.sectionId).panelStateName;
            const data = state.paneldata[
                sectionName as keyof typeof initialCMR.paneldata
            ] as (
                | Showing
                | MarketingData
                | EmailCampaignData
                | SocialCampaignData
                | AdvertisementData
                | OpenHouseData
            )[];
            const updatedData = action.payload.editing
                ? data.map((item) =>
                      item.id === action.payload.data.id
                          ? ({ ...item, ...payloadData } as
                                | Showing
                                | MarketingData
                                | EmailCampaignData
                                | SocialCampaignData
                                | AdvertisementData
                                | OpenHouseData)
                          : item,
                  )
                : [payloadData, ...data];
            (state.paneldata[sectionName as keyof typeof initialCMR.paneldata] as (
                | Showing
                | MarketingData
                | EmailCampaignData
                | SocialCampaignData
                | AdvertisementData
                | OpenHouseData
            )[]) = updatedData;
        },
        toDelete: (state, action: PayloadAction<DeleteStructure>) => {
            const data = state.paneldata[
                action.payload.name as keyof typeof initialCMR.paneldata
            ] as (
                | MarketingData
                | AdvertisementData
                | EmailCampaignData
                | OpenHouseData
                | Showing
                | SocialCampaignData
            )[];
            const updatedData = data.filter((oldPanelData) => {
                return (
                    oldPanelData[action.payload.key as keyof typeof oldPanelData] !==
                    action.payload.id
                );
            });
            (state.paneldata[
                action.payload.name as keyof typeof initialCMR.paneldata
            ] as (
                | MarketingData
                | AdvertisementData
                | EmailCampaignData
                | OpenHouseData
                | Showing
                | SocialCampaignData
            )[]) = updatedData;
        },
        loadNotesValue: (state, action: PayloadAction<Notes>) => {
            state.paneldata.notes = action.payload;
        },
        updatePanelSpinner: (state, action: PayloadAction<SpinnerState>) => {
            const { panel, value } = action.payload;
            if (panel === 'left') {
                state.panelSpinner = value;
            } else {
                state.panelSpinnerRight = value;
            }
        },
        updateMarketingTypesState: (state, action: PayloadAction<MarketingTypes[]>) => {
            state.additionalPromotionTypes = action.payload.filter((m) => !m.isTopLevel);
        },
        updateListingImageData: (state, action: PayloadAction<ListingImage>) => {
            state.listingImage = action.payload;
        },
        getselectedListingImages: (state, action: PayloadAction<ListingImage[]>) => {
            state.listingImages = action.payload;
        },
        updateselectedListingImage: (
            state,
            action: PayloadAction<ListingImageSelected>,
        ) => {
            state.listingdata.photoUrl = action.payload.selectedListingImage;
            state.listingdata.photoIsLandscape = action.payload.isLandscape;
            state.listingdata.videoSelected = false;
        },
        deleteSiteData: (state, action: PayloadAction<string>) => {
            const analyticsData = state.paneldata.viewsOnWeb as Analytics;
            (state.paneldata.viewsOnWeb as Analytics).counts =
                analyticsData.counts &&
                analyticsData.counts.filter(
                    (site: AnalyticsCounts) => site.id !== action.payload,
                );
        },
        updateSiteData: (state, action: PayloadAction<UpdateSiteData>) => {
            const analyticsCountsData = (state.paneldata.viewsOnWeb as Analytics).counts;
            (state.paneldata.viewsOnWeb as Analytics).counts = action.payload.editing
                ? analyticsCountsData &&
                  analyticsCountsData.map((item: AnalyticsCounts) =>
                      item.id === action.payload.data.id
                          ? { ...item, ...action.payload.data }
                          : item,
                  )
                : [action.payload.data, ...(analyticsCountsData as AnalyticsCounts[])];
        },
        updateselectedVideo: (state, action: PayloadAction<boolean>) => {
            state.listingdata.videoSelected = action.payload;
        },
    },
});

/* 
    For Fetching Sorting Order
    * function updateCurrentElement - will update the current right panel object display name
*/
export const sortingOrderByListingId =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(CmrEvents.DATA_GET));
            dispatch(updateListingId(listingId));
            const sortingData = await fetchCmrSortingByListingId(listingId);
            const headerDetails = await fetchHeaderDetails(listingId);
            if (headerDetails) {
                dispatch(updateListingData(headerDetails));
            }
            if (sortingData && sortingData.length > 0) {
                dispatch(loadSortingData(sortingData));
            }
        } catch (e) {
            dispatch(setError(`Invalid Listing Number`));
            Logger.error('Error Invalid Listing Number');
        } finally {
            dispatch(removeLoadingEvent(CmrEvents.DATA_GET));
        }
    };

/*
  fetching the api for panel Data object as a template 
  * param 
  * listingId - id of the listing
  * sortData - sortingOrder Interface object
   
  - output 
  - updating the panel data object 
*/
export const fetchPanelDatabySort =
    (listingId: string, sortData: SortingOrder): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(CmrEvents.DATA_GET));
            const fetchPanelData = await fetchPanelDetails(
                listingId,
                basedOnSectionId(sortData.sectionId).urlName,
            );
            const passingParam: Passing = {
                name: basedOnSectionId(sortData.sectionId).panelStateName,
                data: fetchPanelData,
            };
            dispatch(updatePanelData(passingParam));
        } catch (e) {
            dispatch(setError('Error getting panel data'));
            Logger.error('Error getting panel data');
        } finally {
            dispatch(removeLoadingEvent(CmrEvents.DATA_GET));
        }
    };

export const displayReportToggle =
    (id: string, flag?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(CmrEvents.DATA_GET));
            if (id) {
                const response = await toggleDisplaySection(id);
                const params: ReportToggleParams = {
                    id: id,
                    flag: flag,
                };
                if (response.status === 204) {
                    dispatch(updateDisplayReport(params));
                    dispatch(setSuccess('Display flag updated successfully'));
                } else {
                    throw Error('failed');
                }
            }
        } catch (e) {
            dispatch(setError('Failed To Update Toggle Details'));
            Logger.error('Error Updating Toggle Details');
        } finally {
            dispatch(removeLoadingEvent(CmrEvents.DATA_GET));
        }
    };

export const updateSortOrder =
    (sortOrderArg: SortingOrder[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true, 'left')));
            const updateSort = await updateSortingOrder(sortOrderArg);
            if (updateSort) {
                dispatch(loadSortingData(sortOrderArg));
                dispatch(setSuccess('Sort order updated successfully'));
            }
        } catch (e) {
            dispatch(setError('Failed to Update Sort Order'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false, 'left')));
        }
    };
export const deleteData =
    (url: string, sectionId: number, id: number | string, key: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            if (url) {
                const response = await deleteDBData(url);
                if (response.status === 204) {
                    const panelFound = {
                        name: basedOnSectionId(sectionId).panelStateName,
                        id: id,
                        key: key,
                    };
                    sectionId === panelDetails.viewsOnWeb.id
                        ? dispatch(deleteSiteData(id as string))
                        : dispatch(toDelete(panelFound));

                    dispatch(setSuccess('Data deleted successfully'));
                } else {
                    throw Error('failed');
                }
            }
        } catch (e) {
            dispatch(setError('Failed To Delete'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };
export const updateNotesSection =
    (id: string, remark: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            const notesUpdated = await updateNotes(id, remark);
            if (notesUpdated) {
                const notesData: Notes = {
                    id,
                    remark,
                };
                dispatch(loadNotesValue(notesData));
                dispatch(setSuccess('Notes updated successfully'));
            }
        } catch (e) {
            dispatch(setError('Failed To Update Notes'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const saveShowing =
    (showingData: Showing): AppThunk =>
    async (dispatch) => {
        try {
            if (showingData) {
                dispatch(updatePanelSpinner(new SpinnerState(true)));

                let updatedData = showingData;
                const editingItem = (showingData.id?.length || 0) > 0;

                if (editingItem) {
                    const updatedCall = await updateShowing(showingData);
                    if (updatedCall.status !== 200) {
                        throw Error('Unable to update showing');
                    }
                } else {
                    updatedData = await addShowingsData(showingData);
                    if (!updatedData.id) {
                        throw Error('Unable to add showing');
                    }
                }
                dispatch(setSuccess('Showing saved successfully'));
                dispatch(
                    updateRecordInListById({
                        sectionId: panelDetails.showings.id,
                        data: updatedData,
                        editing: editingItem,
                    }),
                );
            }
        } catch (e) {
            dispatch(setError('Failed to save showing'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const saveAdditionalPromotion =
    (listingId: string, requestData: MarketingData, isCmr: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            if (requestData) {
                const editingItem = (requestData.id || '') !== '';
                const data = jsonToFormData(requestData);
                const sectionId = panelDetails.additionalPromotion.id;

                const response: MarketingData = editingItem
                    ? await updateMarketing('marketing', requestData.id || '', data)
                    : await addMarketing('marketing', listingId, data);
                if (response.id) {
                    if (isCmr) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: response,
                                editing: editingItem,
                            }),
                        );
                    } else {
                        dispatch(setUpcomingList([]));
                        dispatch(setPastList([]));
                    }
                    dispatch(setSuccess('Additional promotion is saved successfully'));
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save Additional Promotion'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const saveEmailCampaign =
    (
        listingId: string,
        emailCampaignsData: EmailCampaignData,
        isCmr: boolean,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            if (emailCampaignsData) {
                const editingItem = (emailCampaignsData.id || '') !== '';
                const data = jsonToFormData(emailCampaignsData);
                const sectionId = panelDetails.emailCampaigns.id;

                const response = !editingItem
                    ? await addMarketing('emailcampaign', listingId, data)
                    : await updateMarketing(
                          'emailcampaign',
                          emailCampaignsData.id || '',
                          data,
                      );
                if (response.id) {
                    if (isCmr) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: response,
                                editing: editingItem,
                            }),
                        );
                    } else {
                        dispatch(setUpcomingList([]));
                        dispatch(setPastList([]));
                    }
                    dispatch(setSuccess('Email campaign is saved successfully'));
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save email campaign'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const saveSocialCampaign =
    (
        listingId: string,
        socialCampaignData: SocialCampaignData,
        isCmr: boolean,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));

            if (socialCampaignData) {
                const editingItem = (socialCampaignData.id || '') !== '';
                const formData = jsonToFormData(socialCampaignData);
                const response = editingItem
                    ? await updateMarketing(
                          'socialcampaign',
                          socialCampaignData.id || '',
                          formData,
                      )
                    : await addMarketing('socialcampaign', listingId, formData);
                const sectionId = panelDetails.socialCampaigns.id;

                if (response.id) {
                    if (isCmr) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: response,
                                editing: editingItem,
                            }),
                        );
                    } else {
                        dispatch(setUpcomingList([]));
                        dispatch(setPastList([]));
                    }
                    dispatch(setSuccess('Social campaign is saved successfully'));
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save social campaign'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const saveFeaturedPlacement =
    (listingId: string, fpData: MarketingData, isCmr: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            if (fpData) {
                const editingItem = (fpData.id || '') !== '';
                const data = jsonToFormData(fpData);
                const sectionId = panelDetails.featuredPlacements.id;
                const response: MarketingData = editingItem
                    ? await updateMarketing('marketing', fpData.id || '', data)
                    : await addMarketing('marketing', listingId, data);
                if (response.id) {
                    if (isCmr) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: response,
                                editing: editingItem,
                            }),
                        );
                    } else {
                        dispatch(setUpcomingList([]));
                        dispatch(setPastList([]));
                    }
                    dispatch(setSuccess('Featured placement is saved successfully'));
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save featured placement'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const getTypes = (): AppThunk => async (dispatch) => {
    try {
        const typesResponse = await getMarketingTypes();
        if (typesResponse) {
            const updatedTypeResposnse = typesResponse.filter((type) => type.id !== 14);
            dispatch(updateMarketingTypesState(updatedTypeResposnse));
        }
    } catch (e) {
        dispatch(setError('Failed To Get Marketing Type'));
    } finally {
    }
};

export const saveAdvertisement =
    (listingId: string, advData: AdvertisementData, isCmr: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));

            if (advData) {
                const editingItem = (advData.id || '') !== '';
                const data = jsonToFormData(advData);
                const sectionId = panelDetails.advertisements.id;

                const response: AdvertisementData = editingItem
                    ? await editAdv(advData.id || '', data)
                    : await addAdv(listingId, data);

                if (response.id) {
                    if (isCmr) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: response,
                                editing: editingItem,
                            }),
                        );
                    } else {
                        dispatch(setUpcomingList([]));
                        dispatch(setPastList([]));
                    }
                    dispatch(setSuccess('Advertisement is saved successfully'));
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save advertisement'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const deleteMarketingFile =
    (fileData: MarketingFields, sectionId?: number): AppThunk =>
    async (dispatch) => {
        try {
            const marketingId = fileData.marketingId || fileData.id;
            if (marketingId && marketingId !== '') {
                const response = await deleteFile(marketingId);
                if (response.status === 204) {
                    if (sectionId) {
                        dispatch(
                            updateRecordInListById({
                                sectionId: sectionId,
                                data: fileData,
                                editing: true,
                            }),
                        );
                    }
                    dispatch(
                        setSuccess(
                            `Marketing file is removed from the server successfully`,
                        ),
                    );
                }
            }
        } catch (e) {
            dispatch(setError('Failed to delete the marketing file from server'));
        }
    };

export const saveOpenHouse =
    (openHouseData: OpenHouseData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));

            if (openHouseData) {
                let updatedData = openHouseData;
                const editingItem = (openHouseData.id?.length || 0) > 0;

                if (editingItem) {
                    const updatedCall = await updateOpenHouses(openHouseData);
                    if (updatedCall.status !== 200) {
                        throw Error('Unable to update open house');
                    }
                } else {
                    updatedData = await addOpenHouseData(openHouseData);
                    if (!updatedData.id) {
                        throw Error('Unable to add open house');
                    }
                }
                dispatch(
                    updateRecordInListById({
                        sectionId: panelDetails.openHouses.id,
                        data: updatedData,
                        editing: editingItem,
                    }),
                );
                dispatch(setSuccess('Open house saved successfully'));
            }
        } catch (e) {
            dispatch(setError('Failed to save open house'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const fetchListingImages =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(CmrEvents.DATA_GET));
            const listingImages = await getListingImages(listingId);
            if (listingImages) {
                dispatch(getselectedListingImages(listingImages));
            }
        } catch (e) {
            dispatch(setError('Failed To Fetch Listing Images'));
        } finally {
            dispatch(removeLoadingEvent(CmrEvents.DATA_GET));
        }
    };

export const editSelectedListingImage =
    (data: ImageVideoModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(CmrEvents.MEDIA_UPDATE));
            const imageResponse = await updateSelectedImage(data);
            if (data && !data.isVideoSelected) {
                const toSave = {
                    selectedListingImage: imageResponse.selectedListingImage,
                    isLandscape: data.isLandscape,
                };
                dispatch(updateselectedListingImage(toSave));
                dispatch(setSuccess('Image updated successfully'));
            } else {
                dispatch(updateselectedVideo(data.isVideoSelected));
                dispatch(setSuccess('Video updated successfully'));
            }
        } catch (e) {
            dispatch(setError('Failed to set selected video or image'));
        } finally {
            dispatch(removeLoadingEvent(CmrEvents.MEDIA_UPDATE));
        }
    };

export const saveWebisteData =
    (siteData: WebsiteData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updatePanelSpinner(new SpinnerState(true)));
            if (siteData) {
                const editingItem = (siteData.id?.length || 0) > 0;
                const data = jsonToFormData(siteData);

                const response: WebsiteData = editingItem
                    ? await updateWebSiteData(siteData.id || '', data)
                    : await addWebSiteData(data);

                if (response.id) {
                    const updatedSiteData: AnalyticsCounts = {
                        siteName: response.siteName,
                        deepLink: response.deepLink,
                        totalViews: response.totalViews,
                        isShowcase: false,
                        id: response.id,
                        logo: response.url,
                        listTrac: false,
                    };
                    dispatch(
                        updateSiteData({
                            data: updatedSiteData,
                            editing: editingItem,
                        }),
                    );
                    dispatch(setSuccess('Website data saved successfully'));
                } else {
                    throw Error('Failed to save Site Data');
                }
            }
        } catch (e) {
            dispatch(setError('Failed to save website data'));
        } finally {
            dispatch(updatePanelSpinner(new SpinnerState(false)));
        }
    };

export const {
    loadSortingData,
    updatePanelData,
    updateCurrentElement,
    updateListingId,
    updateListingData,
    updateDisplayReport,
    loadNotesValue,
    toDelete,
    updatePanelSpinner,
    updateMarketingTypesState,
    updateListingImageData,
    getselectedListingImages,
    updateselectedListingImage,
    updateRecordInListById,
    deleteSiteData,
    updateSiteData,
    updateselectedVideo,
} = cmrSlice.actions;
export const cmrDetails = (state: RootState): CmrSummary => state.cmr.builder;

export default cmrSlice.reducer;
