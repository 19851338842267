import React, { FunctionComponent } from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useTooltipStyle } from '../toolTip/toolTipStyle';

interface FormRowProps {
    label: string | JSX.Element;
    labelTooltip?: boolean;
    labelTooltipText?: string;
    control: JSX.Element;
    prefix?: string | null;
    suffix?: string | null;
    controlClass?: string | null;
    labelXs?: GridSize | null;
    labelSm?: GridSize | null;
    labelMd?: GridSize | null;
    controlXs?: GridSize | null;
    controlSm?: GridSize | null;
    controlMd?: GridSize | null;
    id?: string;
    showLabel?: boolean;
}

const FormRow: FunctionComponent<FormRowProps> = ({
    label,
    labelTooltip,
    labelTooltipText,
    control,
    prefix,
    suffix,
    controlClass,
    labelXs,
    labelSm,
    labelMd,
    controlXs,
    controlSm,
    controlMd,
    id,
    showLabel = true,
}) => {
    const tooltipClass = useTooltipStyle();
    return (
        <>
            {showLabel ? (
                <Grid
                    id={id}
                    item
                    className="field-label"
                    xs={labelXs ? labelXs : 12}
                    sm={labelSm ? labelSm : 12}
                    md={labelMd ? labelMd : 2}
                >
                    <label>{label}</label>
                    {labelTooltip ? (
                        <span className="tooltip">
                            <Tooltip
                                title={labelTooltipText ? labelTooltipText : ''}
                                classes={{
                                    tooltip: tooltipClass.tooltip,
                                }}
                                arrow
                                placement="top"
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                                </span>
                            </Tooltip>
                        </span>
                    ) : (
                        ''
                    )}
                </Grid>
            ) : (
                <></>
            )}
            <Grid
                item
                className="field-control"
                xs={controlXs ? controlXs : 12}
                sm={controlSm ? controlSm : 12}
                md={controlMd ? controlMd : 10}
            >
                {prefix?.length ? <span className="field-info">{prefix}</span> : ''}
                <FormControl className={controlClass || ''}>{control}</FormControl>{' '}
                {suffix?.length ? <span className="field-info">{suffix}</span> : ''}
            </Grid>
        </>
    );
};

export default FormRow;
