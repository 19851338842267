import React, { FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import './modalwindow.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { googleAnalyticsAction } from '../../../shared/functions/googleAnalytics/ga';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const SimpleModal: FunctionComponent = () => {
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        googleAnalyticsAction('Video', 'Click', 'How To Drag And Drop');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className="modal__bg" onClick={handleClose}>
            <div className="modal__align">
                <div className="modal__content">
                    <div className="modal__video-align">
                        <video
                            controls
                            width="800"
                            height="500"
                            className="modal__video-style"
                            playsInline
                            autoPlay
                            muted
                            webkit-playsinline={true}
                        >
                            <source src="/video/MoveVideo.mov" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div className="buttons">
                <button className="report action-button1" onClick={handleOpen}>
                    How to drag and drop{' '}
                    <FontAwesomeIcon className="drag" icon={faVideo} />
                </button>
            </div>
            <span className="imageActionText"></span>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
};

export default SimpleModal;
