import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { AddEmailProps } from '../schedulerModels';
import { Input } from '@material-ui/core';

const AddEmails: FunctionComponent<AddEmailProps> = ({
    id,
    inputEmailHandler,
    recipientsList,
    isSent,
    setSent,
}) => {
    const [recipientValue, setRecipientValue] = useState<string>('');
    const [newRecipientValue, setNewRecipientValue] = useState<string>('');
    const updateEmailHandler = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = event.target;
        setRecipientValue(value);
    };

    const addEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewRecipientValue(value);
    };

    useEffect(() => {
        if (recipientsList && recipientsList.length > 0) {
            setRecipientValue(recipientsList[id]);
        }
    }, [id, recipientsList]);

    useEffect(() => {
        if (isSent) {
            setRecipientValue('');
            setNewRecipientValue('');
            setSent();
        }
    }, [isSent, setSent]);

    return (
        <div className="actionHolder">
            {id > 0 ? (
                <span className="inputTitle"></span>
            ) : (
                <span className="inputTitle">EMAILS:</span>
            )}
            <span className="inputHolder">
                {recipientsList && recipientsList.length > 0 ? (
                    <span>
                        <Input
                            className="schedulerInput"
                            placeholder="Email"
                            name="email"
                            value={recipientValue}
                            inputProps={{ 'aria-label': 'Buyer', maxLength: 255 }}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                inputEmailHandler(event, id);
                                updateEmailHandler(event, id);
                            }}
                            disableUnderline={true}
                        />
                    </span>
                ) : (
                    <span>
                        <Input
                            className="schedulerInput"
                            placeholder="Email"
                            name="email"
                            value={newRecipientValue}
                            inputProps={{ 'aria-label': 'Buyer', maxLength: 255 }}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                inputEmailHandler(event, id);
                                addEmailHandler(event);
                            }}
                            disableUnderline={true}
                        />
                    </span>
                )}
            </span>
        </div>
    );
};

export default AddEmails;
