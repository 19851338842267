import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { events, masqueradeMinTimeout } from './sessionTimeoutConstants';
import CustomModal from '../modal';
import { Grid, Typography } from '@material-ui/core';
import './sessionTimeout.scss';
import {
    cancelMasquerade,
    getUser,
    sessionTimeOut,
    clearLocalStorage,
} from '../../auth/authService';
import OktaAuth from '@okta/okta-auth-js';
import _ from 'lodash';
import React from 'react';
import { getData, removeData, storeDataAsString } from '../../../utils/storageService';
import { useHistory } from 'react-router-dom';

interface SessionTimeoutProps {
    minTimeout: number;
    oktaAuth: OktaAuth;
}

const SessionTimeout: FC<SessionTimeoutProps> = ({ minTimeout, oktaAuth }) => {
    const timeout = useRef<NodeJS.Timeout>();
    const interval = useRef<number | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [logout, setLogout] = useState<boolean>(false);
    const [remainingSecs, setRemainingSecs] = useState<number>(30);
    const isMasquerading = useMemo(
        () => minTimeout === masqueradeMinTimeout,
        [minTimeout],
    );
    const history = useHistory();

    useEffect(() => {
        addEvents();
        return () => {
            removeEvents();
            timeout.current && clearTimeout(timeout.current);
        };
    });

    // Loop through an array of events and add it with add event listener
    const addEvents = () => {
        events.forEach((eventName: string) => {
            window.addEventListener(eventName, eventHandler);
        });
    };

    // Loop through an array of events and remove it with remove event handler
    const removeEvents = () => {
        events.forEach((eventName: string) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    const eventHandler = () => {
        if (!logout && !showModal) startTimeout();
    };

    const startTimeout = () => {
        if (timeout && timeout.current) clearTimeout(timeout.current);

        const currentDate = new Date();
        const sessionTimeoutTime = getData(sessionTimeOut);
        //when the difference between the current time and the session timeout time is greater that
        //3hrs 10mins, we will automatically logout the user. this will handle the user not logging out
        //overnight
        //3600000 is for hrs convertion
        if (
            (new Date(currentDate).getTime() - new Date(sessionTimeoutTime).getTime()) /
                3600000 >
            3.1
        ) {
            // will check if there is any active okta session
            oktaAuth.session.exists().then((isActive) => {
                // after cleaning local storage redirecting to home page(in case of no active session, will take to okta login page)
                if (!isActive) {
                    clearLocalStorage();
                    window.location.href =
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/eng/home';
                }
            });
        }

        timeout.current = setTimeout(() => {
            //include logic to valudate local storage time and current time before showing the
            //model for session timeout, this logic will keep user active across the tab
            if (new Date(currentDate) >= new Date(sessionTimeoutTime)) {
                setShowModal(true);
            }
        }, minTimeout);

        //update local storage with current time plus the minTimeout time
        const copyDate = new Date(currentDate);
        const minTimeoutMinute = minTimeout / 60000;
        copyDate.setMinutes(currentDate.getMinutes() + minTimeoutMinute);
        storeDataAsString(sessionTimeOut, copyDate.toString());

        const user = getUser();
        const {
            email,
            employeeType,
            firstName,
            lastName,
            oktaId,
            profileId,
            userId,
            userName,
        } = user;
        if (
            email === '' ||
            employeeType === '' ||
            firstName === '' ||
            lastName === '' ||
            oktaId === '' ||
            profileId === '' ||
            userId === '' ||
            userName === ''
        )
            handleLogout();
    };

    useEffect(() => {
        if (showModal) {
            startInterval();
        }
    }, [showModal]);

    const startInterval = () => {
        interval.current = window.setInterval(() => {
            setRemainingSecs((remainingSecs) => remainingSecs - 1);
        }, 1000);
    };

    const stopInterval = () => {
        if (interval.current) {
            window.clearInterval(interval.current);
            setRemainingSecs(30);
            interval.current = null;
        }
    };

    useEffect(() => {
        return () => {
            if (interval.current !== null) {
                window.clearInterval(interval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (remainingSecs === 0) {
            handleLogout();
        }
    }, [remainingSecs]);

    const handleLogout = () => {
        setLogout(true);
        setShowModal(false);
        removeEvents();
        timeout.current && clearTimeout(timeout.current);
        stopInterval();
        removeData(sessionTimeOut);
        if (isMasquerading) {
            cancelMasquerade();
        } else {
            clearLocalStorage();
            history.push(`/eng/logout`);
        }
    };

    const continueSession = () => {
        stopInterval();
        setShowModal(false);
        setLogout(false);
        startTimeout();
    };

    return (
        <CustomModal
            title={isMasquerading ? `Masquerade Timeout` : `Session Timeout`}
            show={showModal}
            onClose={continueSession}
        >
            <Grid item container xs={12} md={12} alignItems="center">
                <Grid item xs={12} md={12}>
                    <Typography className="session-expiry-message">
                        Your {isMasquerading ? `masquerade` : `session`} is going to
                        expire in {remainingSecs} seconds. Do you want to continue?
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    className="session-expiry-button-container"
                    xs={12}
                    md={12}
                >
                    <button onClick={continueSession} className="button customButton">
                        Continue
                    </button>
                    <button
                        onClick={() => handleLogout()}
                        className="button customButton"
                    >
                        {isMasquerading ? `Cancel` : `Logout`}
                    </button>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

const areSessionTimeoutPropsEqual = (
    prevProps: Readonly<React.PropsWithChildren<SessionTimeoutProps>>,
    nextProps: Readonly<React.PropsWithChildren<SessionTimeoutProps>>,
) => {
    return _.isEqual(prevProps, nextProps);
};

const MemoizedSessionTimeout = React.memo(SessionTimeout, areSessionTimeoutPropsEqual);

export default MemoizedSessionTimeout;
