import { FunctionComponent, useState, useEffect } from 'react';
import {
    Input,
    CircularProgress,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { validateImageSize, getPdfPageCount } from '../../../utils/photos/photoUtils';
import './uploadSection.scss';
import { useAppDispatch, useFormData } from '../../../../app/hooks';
import { getDimensions } from '../../../../utils/urlUtils';
import { ImageDetail } from '../../../models/images/sharedModel';
import { imageTypes } from '../../../constants/imageTypes';
import { UploadSectionProps } from '../../../models/images/sharedModel';
import { setError } from '../../../slices/messaging/messagingSlice';
import Dropzone from 'react-dropzone';
import { updateThumbnail } from '../../../../features/developments/developmentDetails/drawer/screens/developmentPhotosEdit/developmentPhotosSlice';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';

const initialImageDetail: ImageDetail = {
    id: '',
    orderNo: 0,
    width: 0,
    height: 0,
    size: '',
    mimeType: '',
    allowOnlyOneImage: false,
    entityType: '',
    entityId: '',
    imageType: '',
    file: undefined,
    fromImportOrPhotographer: false,
    caption: null,
    floorplancaption: null,
    tagId: null,
    shouldValidate: true,
};

const UploadSection: FunctionComponent<UploadSectionProps> = (props) => {
    const {
        imageOrderNo,
        images,
        isUploading,
        currentImageType,
        entityId,
        currentImageDetails,
        entityType,
        photoCredit,
        saveImageDetails,
        updateImageCount,
        updateOrderNumber,
        handlePhotoCreditChange,
        handleSuppressPhotoImportChange,
        maxAllowedPhotos,
        disableUpload,
        office,
    } = props;
    const dispatch = useAppDispatch();

    let activeOrderNo = imageOrderNo;

    const { formData, setFormData, handleTextChange, handleCheckboxChange } =
        useFormData(photoCredit);
    const [uploadCount, setUploadCount] = useState<number>(1);
    const [totalUploadCount, setTotalUploadCount] = useState<number>(0);
    const [newImageUploaded, setNewImageUploaded] = useState<boolean>(false);
    const [firstLandscape, setFirstLandscape] = useState<number>(-1);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const rejectedImages: string[] = [];

    useEffect(() => {
        if (photoCredit) {
            setFormData(photoCredit);
        }
    }, [photoCredit, setFormData]);

    useEffect(() => {
        if (photoCredit) {
            setFormData(formData);
        }
    }, [formData, setFormData]);

    // since width and height of pdf doesn't have any application
    // so assigning page count to same to be utilized in agent and showcase sites
    const getPdfTotalPages = async (file: File) => {
        const count = await getPdfPageCount(file);
        return {
            width: count > 0 ? count : 0,
            height: count > 0 ? count : 0,
        };
    };

    const handleFileUpload = async (files: File[]) => {
        if (files && files.length > 0) {
            if (maxAllowedPhotos && maxAllowedPhotos > 0) {
                const imageCountToBeUploaded =
                    files.length <= maxAllowedPhotos ? files.length : maxAllowedPhotos;
                setTotalUploadCount(imageCountToBeUploaded);
                setErrorMessage('');
                for (let index = 0; index < imageCountToBeUploaded; index++) {
                    const uploadedFile = files[index];
                    if (uploadedFile) {
                        // allowing pdf files from floorplans
                        const isFilePdf =
                            currentImageType === imageTypes.FloorPlan &&
                            uploadedFile.type.includes('pdf');
                        const dim = isFilePdf
                            ? await getPdfTotalPages(uploadedFile)
                            : await getDimensions(uploadedFile);
                        const isCorrectImageSize = isFilePdf
                            ? true
                            : validateImageSize(dim.width, dim.height);
                        if (isCorrectImageSize) {
                            activeOrderNo++;
                            const imageData: ImageDetail = {
                                ...initialImageDetail,
                                id: uuidv4(),
                                size: uploadedFile.size.toString(),
                                mimeType: uploadedFile.type,
                                imageType: currentImageType,
                                entityType: entityType,
                                entityId: entityId,
                                file: uploadedFile,
                                height: dim.height,
                                width: dim.width,
                                orderNo: activeOrderNo,
                                shouldValidate:
                                    (currentImageType === imageTypes.Buildings ||
                                        currentImageType === imageTypes.MainPhotos) &&
                                    currentImageDetails.photosLoaded === 0,
                            };
                            await dispatch(saveImageDetails(imageData));
                            setNewImageUploaded(true);
                            setUploadCount(index + 2);
                            googleAnalyticsAction(
                                'Image Upload',
                                'Upload',
                                `${
                                    entityType === 'listing'
                                        ? 'Listing Edit'
                                        : 'Development Edit'
                                }: Image(s) uploaded, Image type: ${currentImageType}`,
                            );
                            googleAnalyticsAction(
                                'Image Upload',
                                'Upload',
                                `${
                                    entityType === 'listing'
                                        ? 'Listing Edit'
                                        : 'Development Edit'
                                }: Image(s) uploaded, Image type: ${currentImageType}, Office: ${office}`,
                            );
                        } else {
                            rejectedImages.push(uploadedFile.name);
                        }
                    }
                }
                if (files.length > maxAllowedPhotos) {
                    dispatch(
                        setError(
                            `Only ${maxAllowedPhotos} out of ${files.length} images were uploaded due to image limitations`,
                        ),
                    );
                }
                if (rejectedImages.length > 0) {
                    const errorString =
                        rejectedImages.join(', ') +
                        ' image(s) are not uploaded because they do not meet the minimum recommended criteria or requested image size is too large.';
                    setErrorMessage(errorString);
                }

                updateOrderNumber({
                    imageType: currentImageType,
                    data: activeOrderNo,
                });
                setUploadCount(1);
            }
        }
    };

    const updateBrightCoveThumbnail = async () => {
        const imageId = images[firstLandscape] ? images[firstLandscape]['guid'] : '';
        await dispatch(updateThumbnail(entityId, imageId));
        setNewImageUploaded(false);
    };

    useEffect(() => {
        updateImageCount({
            imageType: currentImageType,
            data: images.length,
        });
        const firstLandscapeImage = images.findIndex((image) => {
            return image['islandscape'];
        });
        setFirstLandscape(firstLandscapeImage);
    }, [images.length, dispatch, currentImageType]);

    const getAcceptedFiles = () => {
        const accept = {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
        };
        // allowing pdf files to be uploaded in floorplans
        return currentImageType === imageTypes.FloorPlan
            ? {
                  ...accept,
                  'application/pdf': ['.pdf'],
              }
            : accept;
    };
    useEffect(() => {
        if (newImageUploaded && props.hasVideo && firstLandscape === images.length - 1) {
            updateBrightCoveThumbnail();
        }
    }, [newImageUploaded, firstLandscape]);

    return (
        <Grid container className="imageUploadSection">
            {isUploading ? (
                <div className="circularOverlay">
                    <span className="uploadingText">
                        Uploading {uploadCount} of {totalUploadCount} images. <br />
                        Large images will take time to upload. So do not close the tab.
                    </span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            <Grid container className="uploadSectionRows">
                <Grid container className="uploadRow">
                    <Grid container item md={4} sm={4} xs={12} className="imageHolder">
                        <Dropzone
                            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                            disabled={disableUpload}
                            accept={getAcceptedFiles()}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>
                                            Drag and drop files here, or click "Upload"
                                            below to add images.
                                        </p>
                                        <button
                                            className={
                                                disableUpload
                                                    ? 'imageSiteFile disabledFile'
                                                    : 'imageSiteFile'
                                            }
                                            disabled={disableUpload}
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowUpFromBracket}
                                                className="fileIcon"
                                            />
                                            Upload
                                        </button>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Grid>
                    <Grid
                        container
                        item
                        md={8}
                        sm={8}
                        xs={12}
                        justifyContent="flex-end"
                        className="outer-container"
                    >
                        {imageTypes.MainPhotos === currentImageType &&
                        photoCredit &&
                        formData ? (
                            <Grid className="parent-holder">
                                <Grid
                                    md={8}
                                    sm={8}
                                    xs={12}
                                    container
                                    item
                                    className="inputRow"
                                >
                                    <Grid
                                        container
                                        item
                                        className="actionHolder"
                                        justifyContent="flex-end"
                                    >
                                        <div className="creditText">Photo credit</div>

                                        <Grid className="credit-input-holder">
                                            <Input
                                                value={
                                                    formData.credit ? formData.credit : ''
                                                }
                                                onChange={(e) => handleTextChange(e, 255)}
                                                className="creditInput"
                                                placeholder=""
                                                name="credit"
                                                inputProps={{
                                                    'aria-label': 'Photo Credit',
                                                }}
                                                onBlur={
                                                    handlePhotoCreditChange
                                                        ? () =>
                                                              handlePhotoCreditChange(
                                                                  formData,
                                                              )
                                                        : undefined
                                                }
                                            />
                                            {!photoCredit.credit ? (
                                                <div className="noteTextHolder">
                                                    NOTE : This field is required to be
                                                    considered for PR placements
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                    className="mlsImageImport"
                                    justifyContent="flex-end"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                inputProps={{
                                                    'aria-label':
                                                        'Turn Off Mls Image Import',
                                                }}
                                                className="checkbox"
                                                checked={formData.suppressPhotoImport}
                                                onChange={(e) => {
                                                    handleCheckboxChange(e);
                                                    handleSuppressPhotoImportChange &&
                                                        handleSuppressPhotoImportChange(
                                                            formData,
                                                        );
                                                }}
                                                name="suppressPhotoImport"
                                            />
                                        }
                                        label={
                                            <div className="checkboxLabel">
                                                TURN OFF MLS IMAGE IMPORT
                                            </div>
                                        }
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>

                {errorMessage.length > 0 && (
                    <div className="errorRow">{errorMessage}</div>
                )}
            </Grid>
        </Grid>
    );
};

export default UploadSection;
