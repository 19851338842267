import React, { FC, useEffect, useMemo, useState } from 'react';
import { userRoles } from '../../../shared/constants/user/roles';
import { validateAgentAction } from '../../auth/permissions/permissionsService';
import {
    navigationDetails,
    openCloseMobileMenu,
    setBackTo,
    setTitle,
} from '../../slices/navigation/navigationSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    validateRoute,
    userIsInRole,
    userIsInAnyRole,
} from '../../auth/permissions/permissionsService';
import { getUser, clearLocalStorage } from '../../auth/authService';
import { Button } from '@material-ui/core';
import {
    userNotifications,
    fetchUserNotifications,
} from '../../../features/notifications/userNotifications/userNotificationSlice';
import { UserNotificationSearchCriteria } from '../../../features/notifications/userNotifications/userNotificationModels';
import { googleAnalyticsAction } from '../../functions/googleAnalytics/ga';
import Badge from '@material-ui/core/Badge';

const AuthorizedNav: FC = () => {
    const { url } = useRouteMatch();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [hasNotifications, setHasNotifications] = useState(false);
    const { notifications, criteria } = useAppSelector(userNotifications);
    const { showMobileMenu } = useAppSelector(navigationDetails);
    const user = useMemo(() => getUser(), []);

    useEffect(() => {
        if (!notifications.currentPage) {
            const newCriteria: UserNotificationSearchCriteria = {
                ...criteria,
                profileId: user.profileId || '',
            };
            dispatch(fetchUserNotifications(newCriteria));
        }
        setHasNotifications(notifications.totalRecords > 0);
    }, [notifications, dispatch, criteria, user.profileId]);

    const renderNavigation = (path: string) => {
        if (showMobileMenu) dispatch(openCloseMobileMenu(false));
        history.push(path);
    };

    const getNavItem = (title: string, path: string, prefix?: boolean) => {
        return (
            <div
                className="navItem"
                onClick={() => {
                    googleAnalyticsAction(
                        'Navigation',
                        'Click',
                        `Left Panel Navigation - ${title} - ${
                            prefix ? 'Agent' : 'Staff'
                        }`,
                    );
                    dispatch(setTitle(`${prefix ? 'My ' : ''}` + title));
                    dispatch(setBackTo({ url: '', title: '' }));
                    renderNavigation(`${url}/${path}`);
                }}
            >
                {`${prefix ? 'My ' : ''}` + title}
            </div>
        );
    };

    const renderNavItemWithBadge = (title: string, path: string, prefix?: boolean) => {
        return (
            <Badge variant="dot" className="notifications-badge">
                {getNavItem(
                    title,
                    path,
                    prefix && userIsInAnyRole(user, [userRoles.agent.key]),
                )}
            </Badge>
        );
    };

    const renderNavItem = (
        title = '',
        path = '',
        validate?: boolean,
        prefix?: boolean,
    ) => {
        return (
            <>
                {validate
                    ? validateRoute(user, path, 'navKey') &&
                      getNavItem(
                          title,
                          path,
                          prefix && userIsInAnyRole(user, [userRoles.agent.key]),
                      )
                    : getNavItem(
                          title,
                          path,
                          prefix && userIsInAnyRole(user, [userRoles.agent.key]),
                      )}
            </>
        );
    };

    const logoutHandler = () => {
        clearLocalStorage();
        history.push(`/eng/logout`);
    };

    return (
        <div className="leftNavControls">
            <div className="navRow">
                {renderNavItem('Home', 'home')}
                {hasNotifications
                    ? renderNavItemWithBadge('Notifications', 'notifications', true)
                    : getNavItem(
                          'Notifications',
                          'notifications',
                          true && userIsInAnyRole(user, [userRoles.agent.key]),
                      )}

                {renderNavItem('Tasks', 'mytask', true, true)}

                {renderNavItem('Masquerade', 'masquerade', true)}
                <div className="dividerContent">
                    <hr className="dividerLine" />
                </div>

                {renderNavItem('Listings', 'listings', false, true)}

                {userIsInAnyRole(user, [
                    userRoles.corporateMarketingEmployee.key,
                    userRoles.marketingCoordinator.key,
                    userRoles.staff.key,
                    userRoles.systemAdmin.key,
                ])
                    ? renderNavItem('Developments', 'developments', false, true)
                    : userIsInRole(user, userRoles.agent.key) &&
                      validateAgentAction(user, 'developmentAccess')
                    ? renderNavItem('Developments', 'developments', false, true)
                    : ''}

                {renderNavItem('Marketing', 'marketing', false, true)}
                {renderNavItem('My Business', 'business', true)}

                {renderNavItem('Offices', 'offices', true)}

                {(userIsInRole(user, userRoles.agent.key) &&
                    validateAgentAction(user, 'agentId')) ||
                userIsInAnyRole(user, [userRoles.systemAdmin.key, userRoles.staff.key])
                    ? renderNavItem('Agents', 'agents', true)
                    : ''}

                {(userIsInRole(user, userRoles.agent.key) &&
                    validateAgentAction(user, 'salesTeamId')) ||
                userIsInAnyRole(user, [userRoles.systemAdmin.key, userRoles.staff.key])
                    ? renderNavItem('Sales Teams', 'salesTeams', true)
                    : user.salesTeamIds?.length === 1
                    ? renderNavItem('My Teams', `salesTeam/${user.salesTeamIds[0]}`)
                    : ''}

                {renderNavItem('Users', 'users', true)}
                {renderNavItem('Sold Listing Upload', 'selectSoldsUpload', true)}
                {renderNavItem('Applications', 'applications', false, true)}
                {renderNavItem('Reporting', 'reporting', true, true)}
            </div>
            <div className="navRow">
                <div className="dividerContent">
                    <hr className="dividerLine" />
                </div>
                {/* {renderNavItem('Settings', 'mytask')} */}
                <Button className="logout-button" onClick={logoutHandler}>
                    Log Out
                </Button>
            </div>
        </div>
    );
};

export default AuthorizedNav;
