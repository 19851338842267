import { FunctionComponent, useState, useEffect } from 'react';
import { Input } from '@material-ui/core';
import moment from 'moment';
import { timeFormatHHMM } from '../../../utils/urlUtils';
import './timeInput.scss';

interface TimeInputInterface {
    dateValue: string;
    setDateTime: (property: string, dateTime: string) => void;
    property: string;
    disableUnderline?: boolean;
}

const TimeInput: FunctionComponent<TimeInputInterface> = ({
    dateValue,
    setDateTime,
    property,
    disableUnderline,
}) => {
    const timeValue = timeFormatHHMM(dateValue)?.split(':');
    const [hours, setHours] = useState<string>(timeValue?.length ? timeValue[0] : '');
    const [minutes, setMinutes] = useState<string>(
        timeValue?.length ? timeValue[1].split(' ')[0] : '',
    );
    const [isSelected, setSelected] = useState<boolean>(
        timeValue?.length ? (timeValue[1].split(' ')[1] === 'AM' ? true : false) : false,
    );
    const timeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        if (name === 'hour') {
            if (Number(value) < 13 && Number(value) > 0) {
                setHours(value);
                // set minutes as 00 if not selected
                setMinutes('00');
                // setting startDateTime and endDateTime in formData
                setDateTime(
                    property,
                    value.length ? getDateTime(dateValue, isSelected, value, '00') : '',
                );
            } else {
                setHours('');
                setMinutes('');
            }
        }
        if (name === 'minute') {
            if (Number(value) < 60 && Number(value) >= 0) {
                setMinutes(value);
                // setting startDateTime and endDateTime in formData
                setDateTime(
                    property,
                    hours.length ? getDateTime(dateValue, isSelected, hours, value) : '',
                );
            } else {
                setMinutes('00');
            }
        }
    };

    // concatenates date value with time and returns as string
    const getDateTime = (
        dateValue: string,
        format: boolean,
        hrs?: string,
        mins?: string,
    ): string => {
        const finalDateTime = moment(
            dateValue +
                ' ' +
                `${hrs ? hrs : hours}:${mins ? mins : minutes} ${format ? 'AM' : 'PM'}`,
            'YYYY-MM-DD hh:mm a',
        );
        return finalDateTime.format('YYYY-MM-DDTHH:mm');
    };

    useEffect(() => {
        // if no dateValue then clear time fields and respective formData fields
        if (!dateValue.length) {
            setHours('');
            setMinutes('');
            setSelected(true);
            setDateTime('', '');
        }
        // if dateValue changes then update startDateTime and endDateTime in formData
        if (dateValue.length && hours.length) {
            setDateTime(property, getDateTime(dateValue, isSelected));
        }
    }, [dateValue]);

    const selectHandler = (format: string) => {
        if (format === 'AM') {
            setSelected(true);
            setDateTime(property, getDateTime(dateValue, true));
        } else {
            setSelected(false);
            setDateTime(property, getDateTime(dateValue, false));
        }
    };

    return (
        <div className="timeContainer">
            <div className="inputHolder">
                <Input
                    value={hours}
                    placeholder="00"
                    name="hour"
                    className="cmrInput timeInput"
                    inputProps={{
                        'aria-label': 'Hour',
                        maxLength: 2,
                    }}
                    onChange={timeHandler}
                    disableUnderline={disableUnderline}
                />
                <span className="separator">:</span>
                <Input
                    value={minutes}
                    placeholder="00"
                    name="minute"
                    className="cmrInput timeInput"
                    inputProps={{
                        'aria-label': 'Minute',
                        maxLength: 2,
                    }}
                    onChange={timeHandler}
                    disableUnderline={disableUnderline}
                />
            </div>
            <div className="buttonHolder">
                <button
                    className={`timeButton amButton ${
                        isSelected ? 'selectedButton' : ''
                    }`}
                    onClick={() => selectHandler('AM')}
                >
                    AM
                </button>
                <button
                    className={`timeButton pmButton ${
                        isSelected ? '' : 'selectedButton'
                    }`}
                    onClick={() => selectHandler('PM')}
                >
                    PM
                </button>
            </div>
        </div>
    );
};

export default TimeInput;
