import { FunctionComponent, useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { SortingOrder, ContainerProps } from '../../cmrModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { sortableOptions, clickHandler } from './LeftPanel';
import { useWindowDimensions } from '../../../../shared/component/Window';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { cmrDetails, updateCurrentElement } from '../../cmrSlice';
import loDash from 'lodash';

const Container: FunctionComponent<ContainerProps> = ({
    block,
    blockIndex,
    setBlocks,
    reorderUpdate,
    componentMapping,
}) => {
    const { panelInfo } = useAppSelector(cmrDetails);
    const [drop, setDrop] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const dispatch = useAppDispatch();
    useEffect(() => {
        setDrop(false);
    }, [block]);

    const [sectionID, setSectionID] = useState<number[]>([]);
    const toggleIcon = (id: number) => {
        if (sectionID && loDash.indexOf(sectionID, id) === -1) {
            setSectionID((arr) => [...arr, id]);
        } else {
            setSectionID(
                sectionID.filter(function (value) {
                    return value !== id;
                }),
            );
        }
    };
    useEffect(() => {
        if (width > 1023) {
            setSectionID([]);
        }
    }, [width]);

    return (
        <div>
            {drop && block.childSections.length === 0 ? (
                <div className="dropholder">drop it here</div>
            ) : (
                ''
            )}
            <ReactSortable
                className={`childDrop`}
                id={block.sectionId + ''}
                key={block.id}
                list={block.childSections.map((x) => ({ ...x, chosen: true }))}
                setList={(currentList) => {
                    if (setBlocks) {
                        setBlocks((sourceList: SortingOrder[]) => {
                            const tempList = [...sourceList];
                            const _blockIndex = [...(blockIndex || [])];
                            const lastIndex = _blockIndex.pop();
                            const lastArr = _blockIndex.reduce(
                                (arr, i) => arr[i]['childSections'],
                                tempList,
                            );
                            lastArr[lastIndex || 0]['childSections'] = currentList;
                            return tempList;
                        });
                    }
                }}
                onEnd={() => {
                    if (reorderUpdate) {
                        reorderUpdate();
                    }
                }}
                onChange={(e) => {
                    if (
                        panelInfo.additionalPromotion.sectionId === Number(e.to.id) &&
                        !panelInfo.additionalPromotion.dndRule.includes(
                            Number(e.clone.dataset.sectionid),
                        )
                    ) {
                        setDrop(true);
                    } else if (
                        panelInfo.showings.sectionId === Number(e.to.id) &&
                        panelInfo.showings.dndRule === Number(e.clone.dataset.sectionid)
                    ) {
                        setDrop(true);
                    } else if (
                        panelInfo.openHouses.sectionId === Number(e.to.id) &&
                        panelInfo.openHouses.dndRule === Number(e.clone.dataset.sectionid)
                    ) {
                        setDrop(true);
                    }
                }}
                {...sortableOptions}
            >
                {block.childSections &&
                    componentMapping &&
                    block.childSections.map((childBlock: SortingOrder, index: number) => {
                        const ComponentToLoad = componentMapping[childBlock.sectionId];
                        return (
                            <div id={'containerId' + index} key={'con' + index}>
                                {width > 1023 ? (
                                    <div className="child" key={'leftPanel' + index}>
                                        <div
                                            className="block"
                                            id={`${childBlock.id}`}
                                            onClick={(
                                                event: React.MouseEvent<
                                                    HTMLDivElement,
                                                    MouseEvent
                                                >,
                                            ) => {
                                                event.stopPropagation();
                                                dispatch(
                                                    updateCurrentElement(block.sectionId),
                                                );
                                                clickHandler(childBlock.sectionId);
                                            }}
                                        >
                                            <div className={'content indi'}>
                                                <FontAwesomeIcon
                                                    className="drag"
                                                    icon={faGripVertical}
                                                />{' '}
                                                {childBlock.displayName}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Accordion
                                        className="child"
                                        key={'leftPanel' + index}
                                        onClick={() => {
                                            dispatch(
                                                updateCurrentElement(block.sectionId),
                                            );
                                        }}
                                        onChange={() => {
                                            toggleIcon(childBlock.sectionId);
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <FontAwesomeIcon
                                                    className="expand"
                                                    icon={
                                                        loDash.includes(
                                                            sectionID,
                                                            childBlock.sectionId,
                                                        )
                                                            ? faMinus
                                                            : faPlus
                                                    }
                                                />
                                            }
                                            aria-controls="panel1a-content"
                                            className="blocksummary"
                                            onClick={() => {
                                                dispatch(
                                                    updateCurrentElement(block.sectionId),
                                                );
                                            }}
                                        >
                                            <div
                                                className="block"
                                                id={`${childBlock.id}`}
                                            >
                                                <div className={'content indi'}>
                                                    <FontAwesomeIcon
                                                        className="drag"
                                                        icon={faGripVertical}
                                                    />{' '}
                                                    {childBlock.displayName}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="leftHolderContent">
                                                {
                                                    <ComponentToLoad
                                                        expanded={
                                                            loDash.includes(
                                                                sectionID,
                                                                childBlock.sectionId,
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        sectionId={childBlock.sectionId}
                                                    />
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </div>
                        );
                    })}
            </ReactSortable>
        </div>
    );
};

export default Container;
