import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { videoDetailsInitial } from '../../../../../../shared/constants/video/videoConstants';
import {
    VideoChosen,
    VideosState,
    DownloadedVideoStatus,
    VideoModel,
} from '../../../../../../shared/models/video/videoModels';
import loDash from 'lodash';
import { postVideoMetaData } from '../../../../../../shared/api/video/videoApi';
import { AppThunk, RootState } from '../../../../../../app/store';
import Logger from '../../../../../../utils/logging/logger';
import { setError } from '../../../../../../shared/slices/messaging/messagingSlice';
import { s3Upload } from '../../../../../../shared/functions/video/s3Upload/s3Upload';
import {
    ingestVideo,
    shareVideo,
} from '../../../../../../shared/api/video/brightcove/brightcoveApi';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';
import { getVideos } from '../../../../../../shared/slices/video/videoSlice';
import { entityTypes } from '../../../../../../shared/constants/entityTypes';

const videosState: VideosState = {
    videos: videoDetailsInitial,
    isLoading: false,
    downloadedVideo: {
        from: '',
        progress: 0,
    },
};

export const videosSlice = createSlice({
    name: 'videos',
    initialState: videosState,
    reducers: {
        addVideosToListing: (state, action: PayloadAction<Array<VideoModel>>) => {
            if (action.payload.length > 0) {
                action.payload.forEach((data) => {
                    const objIndex = loDash.findIndex(state.videos, function (o) {
                        return (
                            o.videoTypeId === data.videoTypeId &&
                            o.displayOrder ===
                                (data.displayOrder === null ? 0 : data.displayOrder)
                        );
                    });
                    if (objIndex !== -1) {
                        state.videos[objIndex] = { ...data, isCancelled: false };
                    }
                });
            }
        },
        updateVideos: (
            state,
            action: PayloadAction<{
                videoId: string;
                videoTypeId: number;
                reuploadInterval: number;
                isCancelled: boolean;
            }>,
        ) => {
            const indexFound = state.videos.findIndex((data) => {
                return (
                    data.videoId === action.payload.videoId &&
                    data.videoTypeId === action.payload.videoTypeId
                );
            });
            state.videos[indexFound].id = '';
            state.videos[indexFound].entityId = '';
            state.videos[indexFound].url = '';
            state.videos[indexFound].videoId = '';
            state.videos[indexFound].reference = '';
            state.videos[indexFound].status = 1;
            state.videos[indexFound].caption = '';
            state.videos[indexFound].reuploadInterval = action.payload.reuploadInterval;
            state.videos[indexFound].isCancelled = action.payload.isCancelled;
        },
        updateDownloadedListingVideoStatus: (
            state,
            action: PayloadAction<DownloadedVideoStatus>,
        ) => {
            state.downloadedVideo.from = action.payload.from;
            state.downloadedVideo.progress = action.payload.progress;
        },
        updateStatus: (
            state,
            action: PayloadAction<{ videoType: number; displayOrder: number }>,
        ) => {
            const indexFound = state.videos.findIndex(
                (data) =>
                    data.videoTypeId === action.payload.videoType &&
                    data.displayOrder === action.payload.displayOrder,
            );
            state.videos[indexFound].status = 2;
        },
        updateLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        resetVideoState: (state) => {
            return {
                ...state,
                ...videosState,
            };
        },
    },
});

export const uploadVideo =
    (
        data: VideoChosen,
        fromName: string,
        abortController: AwsAbortController,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (data) {
                const createBrightcove = {
                    videoType: fromName, //fromName
                    fileName: data.file.name,
                    displayOrder: data.displayOrder,
                };
                const s3Details = await postVideoMetaData(
                    data.listingId,
                    createBrightcove,
                );
                if (s3Details) {
                    dispatch(getVideos(data.listingId, entityTypes.listing.key, true));
                    const s3Uploaded = await s3Upload(
                        data.file,
                        s3Details,
                        abortController,
                    );
                    if (s3Uploaded === 'success') {
                        const apiUrl = process.env.REACT_APP_APIURL;
                        let ingestRequest;
                        const image = getState().listing.listing.data.images.find(
                            (i) => i.isLandscape,
                        );
                        if (image)
                            ingestRequest = {
                                poster: { url: image.imageUrl },
                                thumbnail: { url: image.imageUrl },
                                'capture-images': false,
                            };
                        ingestRequest = {
                            ...ingestRequest,
                            master: {
                                url: s3Details.api_Request_Url,
                            },
                            callbacks: [`${apiUrl}/brightcove/callback`],
                        };
                        const injRes = await ingestVideo(
                            ingestRequest,
                            s3Details.video_Id,
                        );
                        if (injRes) {
                            const sira = [{ id: '5699924528001' }];
                            await shareVideo(s3Details.video_Id, sira);
                        } else {
                            dispatch(setError('Injest request is failed'));
                        }
                    }
                } else {
                    dispatch(setError('Video is not created in Brightcove'));
                }
            }
        } catch (err) {
            Logger.error(`Video is not uploaded: ${JSON.stringify(err)}`);
            dispatch(setError('Video is not uploaded'));
        }
    };

export const {
    addVideosToListing,
    updateVideos,
    updateDownloadedListingVideoStatus,
    updateStatus,
    updateLoadingStatus,
    resetVideoState,
} = videosSlice.actions;

export const videos = (state: RootState): VideosState => state.listing.videos;

export default videosSlice.reducer;
