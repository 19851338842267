import {
    PaginationRequest,
    PaginationResponse,
} from '../../../shared/models/pagination/paginationModels';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import TextSearch from './components/fields/TextSearch';
import LookupSearch from './components/fields/LookupSearch';
import DateRangeSearch from './components/fields/DateRangeSearch';
import NumberRangeSearch from './components/fields/NumberRangeSearch';
import CheckboxSearch from './components/fields/CheckboxSearch';
import { ListingsResponse } from '../listingListModels';
import { Order } from '../../../shared/models/customTable/customTableModels';

export interface AdvancedSearchState {
    /** The list of all possible fields to search on and return in the result set */
    fields: AdvancedSearchField[];

    /** When loading saved searches or default fields, the list of selected fields for the search */
    selectedFields?: AdvancedSearchField[];

    /** When loading saved searches, the name of the search being executed */
    savedSearchName?: string;

    /** Flag for loader indicating the fields are being retrieved */
    isLoadingFields: boolean;

    /** Flag for loader indicating search results are being retrieved */
    isLoadingSearchResults: boolean;

    /** The criteria to filter on */
    criteria: AdvancedSearchCriteria[];

    /** The search results */
    results: PaginationResponse<ListingsResponse>;

    /** The sort order of the results */
    sortOrder: Order;

    /** The column to sort on */
    sortColumn: string;

    /** The list of the user's saved searches */
    savedSearches?: SavedSearchDetails[];

    /**preload the adv search based on the selection from office stats */
    officeStatsPreLoad?: {
        field: string;
        businessPurpose: string;
    };

    /**to show the Update/Save As New search button */
    showActionButtons: boolean;
}

/** Wrapper for performing an advanced search */
export interface AdvancedSearchRequest extends PaginationRequest {
    criteria: AdvancedSearchCriteria[];
}

/** Model containing data to save an advanced search */
export interface SavedSearchDetails {
    /** The ID of the saved search record */
    id?: string;

    /** The search criteria */
    criteria: AdvancedSearchCriteria[];

    /** The ID of the user saving the search */
    userId: string;

    /** Name of the saved search */
    name: string;

    /** The column to order the results by */
    sortColumn: string;

    /** The direction to order the results by (asc or desc) */
    sortDirection: Order;

    /** Flag indicating if this is the user's default search */
    isDefault?: boolean;
}

/**
 * Contains metadata around advanced search fields to be rendered
 * in the UI that we can filter listings by
 */
export interface AdvancedSearchField {
    /** The name of the field in Mongo to search on */
    fieldName: string;

    /** The name to display for the field in the UI */
    displayName: string;

    /** The name of the field containing the value to render in the results table */
    resultFieldName: string;

    /** The name of the field to sort on */
    sortFieldName: string;

    /** The type of field to render in the UI */
    searchFieldType: number;

    /**
     * The list of data that can be chosen from the drop down
     * in the UI during searching
     */
    lookups?: LookupInfo[];

    /** Flag indicating if this field allows searching on multiple values */
    allowMultiple: boolean;

    /**
     * Flag indicating if this is default criteria, loaded when page
     * is initially viewd
     */
    isDefault: boolean;

    /** Flag indicating if this field allows sorting */
    allowSort: boolean;

    /**column order */
    defaultColumnOrder: number;
}

/**
 * Contains metadata around advanced search fields to be rendered
 * in the UI
 */
export interface AdvancedSearchCriteria extends AdvancedSearchField {
    /** The value(s) to filter on (or the start value for a range query) */
    values: string[];

    /** For range queries, the end of the range to filter on */
    endValue?: string;
}

/** Represents how to render and handle the search field */
export const searchFieldTypes = {
    /** Search field should render as a text box */
    text: 1,

    /** Search field should render as a checkbox */
    checkbox: 2,

    /**
     * Search field should render as a multi-select drop down
     * with supplied lookup values
     */
    multiSelect: 3,

    /**
     * Search field should render as a date selector, and
     * have a start/end range
     */
    date: 4,

    /**
     * Search field should render as a text box, and
     * have a start/end range
     */
    number: 5,

    /**
     * Searches the owner info properties for an agent containing the
     * supplied name
     */
    ownerName: 6,

    /** Performs search indicating a field has a value in it */
    fieldPopulated: 7,

    /** Performs search indicating a field is null or empty */
    fieldNotPopulated: 8,

    /** Range search that filters based on number of images a listing has */
    imageCount: 9,

    /**
     * Checkbox search indicating if a listing has any images less than
     * the required width/height
     */
    imagesLowRes: 10,

    /**
     * Searches all agents for an agent containing the
     * supplied name
     */
    agentName: 11,

    /**
     * Searches open house data by date range
     */
    openHouse: 12,

    /**
     * Performs search indicating if the listing has a video
     */
    hasVideo: 13,

    /**
     * Performs search indicating if the listing has internet
     * ad copy
     */
    hasAdCopy: 14,

    /**
     * Performs search indicating if the listing is a
     * select sale for any agents
     */
    isSelectSale: 15,

    /**
     * Performs search indicating if the listing has an
     * external data setting selected
     */
    hasExternalDataSetting: 16,

    /**
     * Performs search indicating is a listing has a specific syndication
     * setting selected
     */
    hasSyndicationSetting: 17,
};

/** Represents properties needed by a search component */
export interface SearchComponentProps {
    criteria: AdvancedSearchCriteria;
    onCriteriaAdd: (criteria: AdvancedSearchCriteria) => void;
}

/** Maps the search field type to the appropriate component */
export const searchFieldTypeMap = new Map([
    [searchFieldTypes.text, TextSearch],
    [searchFieldTypes.agentName, TextSearch],
    [searchFieldTypes.ownerName, TextSearch],
    [searchFieldTypes.multiSelect, LookupSearch],
    [searchFieldTypes.date, DateRangeSearch],
    [searchFieldTypes.number, NumberRangeSearch],
    [searchFieldTypes.checkbox, CheckboxSearch],
    [searchFieldTypes.fieldPopulated, CheckboxSearch],
    [searchFieldTypes.fieldNotPopulated, CheckboxSearch],
    [searchFieldTypes.imageCount, NumberRangeSearch],
    [searchFieldTypes.imagesLowRes, CheckboxSearch],
    [searchFieldTypes.openHouse, DateRangeSearch],
    [searchFieldTypes.hasVideo, CheckboxSearch],
    [searchFieldTypes.hasAdCopy, CheckboxSearch],
    [searchFieldTypes.isSelectSale, CheckboxSearch],
    [searchFieldTypes.hasExternalDataSetting, CheckboxSearch],
    [searchFieldTypes.hasSyndicationSetting, CheckboxSearch],
]);

/** Used for date and number range searches */
export interface RangeSearch {
    start?: string;
    end?: string;
}

/**model used in the saved search list */
export interface SavedSearchView {
    id: string;
    name: string;
    isDefault: boolean;
}

/**model used radio button date range */
export const dateRangeSearchTypeConst: LookupInfo[] = [
    {
        id: '1',
        name: 'Last 7 Days',
    },
    {
        id: '2',
        name: 'Last 14 Days',
    },
    {
        id: '3',
        name: 'Next 7 Days',
    },
    {
        id: '4',
        name: 'Next 14 Days',
    },
    {
        id: '5',
        name: 'Last Month',
    },
    {
        id: '6',
        name: 'Last Quater',
    },
    {
        id: '7',
        name: 'MTD',
    },
    {
        id: '8',
        name: 'QTD',
    },
    {
        id: '9',
        name: 'YTD',
    },
    {
        id: '10',
        name: 'Date Range',
    },
];
