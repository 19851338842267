import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { BlockProps } from '../../cmrModels';
import Container from './Container';

const Block: FunctionComponent<BlockProps> = ({
    block,
    clickHandler,
    currentOrderElement,
    className,
    showContainer,
    blockIndex,
    setBlocks,
    reorderUpdate,
    componentMapping,
}) => {
    return (
        <div
            className={className && className.length > 0 ? className : ''}
            id={`${block.id}`}
            onClick={() => {
                clickHandler(block.sectionId);
            }}
            data-sectionid={block.sectionId}
            style={{ width: '100%' }}
        >
            <div
                className={`content ${showContainer ? '' : 'indi'} ${
                    block.sectionId === currentOrderElement ? 'selected' : ''
                }`}
            >
                <FontAwesomeIcon className="drag" icon={faGripVertical} />{' '}
                {block.displayName}
            </div>

            {showContainer && (
                <Container
                    block={block}
                    blockIndex={blockIndex}
                    setBlocks={setBlocks}
                    reorderUpdate={reorderUpdate}
                    componentMapping={componentMapping}
                />
            )}
        </div>
    );
};

export default Block;
