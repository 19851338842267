import { Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
    getData,
    listingShowCase,
    updateShowcaseWebsiteData,
    getUsers,
} from '../showcaseWebsiteSlice';
import './index.scss';
import UserTokens from './userTokens';
import VideoSection from './videoSection';
import PrivateShowcase from './privateShowcaseSection';
import Tracking from './tracking';
import SiteInfo from './siteInfo';
import DomainInfo from './domainInfo';
import { TabPanel } from '../../../../../../../shared/component/tabPanel/TabPanel';
import CustomTab from './customTab';
import { CustomTabModel, ShowCaseModel } from '../showcaseWebsiteModels';
import {
    useAppDispatch,
    useAppSelector,
    useFormData,
} from '../../../../../../../app/hooks';
import { listingDetail, setCurrentFormData } from '../../../../listingSlice';
import { editFormComponent } from '../../../../listingConstants';
import { videos } from '../../videos/videoSlice';
import { entityTypes } from '../../../../../../../shared/constants/entityTypes';
import { getVideos } from '../../../../../../../shared/slices/video/videoSlice';
import { showCaseWebsiteStyle } from '../style';
import { useWindowDimensions } from '../../../../../../../shared/component/Window';

const useStyles = makeStyles({
    tabs: {
        '& .MuiTab-root': {
            color: '#999',
            opacity: 1,
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#b28e3a',
        },
    },
});

const DomainDetails: FunctionComponent = () => {
    const { domainData, showCaseData, usersData } = useAppSelector(listingShowCase);
    const { data } = useAppSelector(listingDetail);
    const { downloadedVideo } = useAppSelector(videos);
    const { width } = useWindowDimensions();
    const dispatch = useAppDispatch();
    const listingId = data.id;
    const {
        formData,
        setFormData,
        handleTextChange,
        handleRadioButtonChange,
        handleEditorChange,
    } = useFormData(showCaseData);
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: unknown, newValue: number) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const showcaseSiteClass = showCaseWebsiteStyle();
    const initialState = {
        tabId: 0,
        orderNo: 1,
        title: '',
        body: '',
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    /**
     * to update the formdata when the slider related section is toggled
     * @param event
     */
    const switchChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setFormData({ ...formData, [name]: event.target.checked });
        updateShowCaseData({ ...formData, [name]: event.target.checked });
    };

    const updateShowCaseData = (updatedFormData?: ShowCaseModel) => {
        dispatch(
            setCurrentFormData({
                formData: updatedFormData ? updatedFormData : formData,
                listingEditFormName: editFormComponent.showCaseWebsite,
            }),
        );
    };

    const updateCTDetails = (updatedCustomTabs: CustomTabModel[]) => {
        const updatedFormData = {
            ...formData,
            displayOptions:
                formData.displayOptions && formData.displayOptions !== 2
                    ? formData.displayOptions
                    : formData.displayOptions === 2 && updatedCustomTabs.length
                    ? 2
                    : 3,
            customTabs: updatedCustomTabs,
        };
        setFormData(updatedFormData);
        updateShowCaseData(updatedFormData);
    };

    //initial api call to get the results
    useEffect(() => {
        if (listingId && !showCaseData.id) {
            dispatch(getData(listingId));
            dispatch(
                updateShowcaseWebsiteData({
                    property: 'eligibilityStatus',
                    value: true,
                }),
            );
        }
    }, [dispatch, listingId, showCaseData]);

    useEffect(() => {
        if (showCaseData.id) {
            dispatch(getVideos(listingId, entityTypes.listing.key, true));
            if (showCaseData.privateSite) dispatch(getUsers(showCaseData.id));
        }
    }, [showCaseData.id, dispatch]);

    useEffect(() => {
        if (showCaseData.id) {
            const updatedFormData = {
                ...showCaseData,
                headline:
                    showCaseData.headline === ''
                        ? `<p>${
                              data.showAddress && data?.address1 !== ''
                                  ? data?.address1 || ''
                                  : data?.city !== '' && data?.state !== ''
                                  ? data?.city + ',' + data?.state
                                  : data?.city || ''
                          }</p>`
                        : showCaseData.headline,
                subheadline: showCaseData.subheadline,
                displayOptions:
                    showCaseData.displayOptions && showCaseData.displayOptions !== 2
                        ? showCaseData.displayOptions
                        : showCaseData.displayOptions === 2 &&
                          showCaseData.customTabs?.length
                        ? 2
                        : 3,
                googleAnalyticsTrackingId: showCaseData.googleAnalyticsTrackingId,
                hotjarTrackingId: showCaseData.hotjarTrackingId,
                googleTagManager: showCaseData.googleTagManager,
                customTabs: showCaseData?.customTabs,
            };
            setFormData(updatedFormData);
            updateShowCaseData(updatedFormData);
        }
    }, [showCaseData, setFormData, data]);

    return (
        <Grid container className="showcaseSiteDetails">
            {showCaseData?.id !== '' ? (
                <>
                    <Grid item container md={12}>
                        <Grid item md={12}>
                            <Typography
                                variant="subtitle2"
                                className={showcaseSiteClass.status}
                            >
                                Refresh to check for status updates. Even after domain is
                                ready, website may take up to 24 hours to be accessible
                                from the internet.
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Typography
                                variant="subtitle1"
                                className={showcaseSiteClass.requiredFields}
                            >
                                * Indicates Required Fields
                            </Typography>
                        </Grid>
                    </Grid>
                    <DomainInfo
                        domainData={domainData}
                        formData={formData}
                        showCaseData={showCaseData}
                        switchChange={switchChange}
                    ></DomainInfo>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { background: '#b28e3a' } }}
                        className={classes.tabs}
                        variant="scrollable"
                        scrollButtons={width > 767 ? 'auto' : 'on'}
                    >
                        <Tab
                            className="customHeading"
                            id="simple-tab-0"
                            label="Main Menu"
                        />
                        <Tab
                            className="customHeading"
                            id="simple-tab-1"
                            label="Tracking"
                        />
                        <Tab
                            className="customHeading"
                            id="simple-tab-2"
                            label="Video Section"
                        />
                        <Tab
                            className="customHeading"
                            id="simple-tab-3"
                            label="Private Showcase Site"
                        />
                        <Tab
                            className="customHeading"
                            id="simple-tab-4"
                            label="Custom Tabs"
                        />
                    </Tabs>
                    <TabPanel
                        id="simple-tab"
                        value={value}
                        index={0}
                        className="showcaseTab"
                    >
                        <Grid container className="fields" spacing={1}>
                            <SiteInfo
                                handleCheckboxChange={handleCheckboxChange}
                                formData={formData}
                                updateShowCaseData={updateShowCaseData}
                                handleRadioButtonChange={handleRadioButtonChange}
                                handleEditorChange={handleEditorChange}
                            ></SiteInfo>
                        </Grid>
                    </TabPanel>
                    <TabPanel
                        id="simple-tab"
                        value={value}
                        index={1}
                        className="showcaseTab"
                    >
                        <Grid container className="fields" spacing={1}>
                            <Tracking
                                handleTextChange={handleTextChange}
                                formData={formData}
                                updateShowCaseData={updateShowCaseData}
                            ></Tracking>
                        </Grid>
                    </TabPanel>
                    <TabPanel
                        id="simple-tab"
                        value={value}
                        index={2}
                        className="showcaseTab"
                    >
                        <VideoSection
                            listingId={listingId}
                            isVideoDownloadData={downloadedVideo}
                        ></VideoSection>
                    </TabPanel>
                    <TabPanel
                        id="simple-tab"
                        value={value}
                        index={3}
                        className="showcaseTab"
                    >
                        <Grid
                            container
                            direction="column"
                            spacing={1}
                            className="privateSite"
                        >
                            <PrivateShowcase
                                handleCheckboxChange={handleCheckboxChange}
                                formData={formData}
                                updateShowCaseData={updateShowCaseData}
                                handleRadioButtonChange={handleRadioButtonChange}
                                showcaseSiteId={showCaseData?.id || ''}
                            ></PrivateShowcase>
                        </Grid>
                        <UserTokens
                            showcaseSiteId={showCaseData?.id || ''}
                            users={usersData}
                        />
                    </TabPanel>
                    <TabPanel
                        id="simple-tab"
                        value={value}
                        index={4}
                        className="showcaseTab"
                    >
                        <CustomTab
                            initialState={initialState}
                            customTabs={formData?.customTabs || []}
                            updateCTDetails={updateCTDetails}
                        ></CustomTab>
                    </TabPanel>
                </>
            ) : (
                <></>
            )}
        </Grid>
    );
};
export default DomainDetails;
