import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { BrightcoveResponse } from '../../../models/video/brightcove/brightcoveModels';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';

export const s3Upload = async (
    file: File,
    s3Details: BrightcoveResponse,
    abortController: AwsAbortController,
): Promise<string> => {
    const target = { Bucket: s3Details.bucket, Key: s3Details.object_Key, Body: file };

    const creds = {
        accessKeyId: s3Details.access_Key_Id,
        secretAccessKey: s3Details.secret_Access_Key,
        sessionToken: s3Details.session_Token,
    };

    try {
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: 'us-east-1', credentials: creds }),
            params: target,
            abortController: abortController,
        });

        parallelUploads3.on('httpUploadProgress', (progress) => {
            console.log('Video Upload Progress:', progress);
        });
        await parallelUploads3.done();
        return 'success';
    } catch (e) {
        console.log('Exception during upload to S3:', e);
        return 'failure';
    }
};
