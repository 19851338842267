import { FunctionComponent, useEffect } from 'react';
import {
    photoEditorForms,
    photoEditorFormMap,
    fetchPhotoCredits,
    photoDetails,
    fetchImages,
    fetchTotalImagesCount,
    fetchImageTypes,
    fetchFilter,
} from '../../photoEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import { CircularProgress } from '@material-ui/core';
import './mainPhotoMenu.scss';
import { DrawerContainerProps } from '../../../../../listingModels';
import { handlePhotoEditorSwitch } from '../../../../../listingSlice';
import { imageTypes } from '../../../../../../../../shared/constants/imageTypes';
import { googleAnalyticsAction } from '../../../../../../../../shared/functions/googleAnalytics/ga';

// Renders "Photos" navbar, selected section and main loader
const MainPhotoMenu: FunctionComponent<DrawerContainerProps> = (props) => {
    const photoInfo = useAppSelector(photoDetails);
    const dispatch = useAppDispatch();
    const selectedSection =
        props.editFormData.editForm?.listingEditFormName || photoEditorForms.mainPhotos;

    // Corresponding selected section from navbar to be rendered
    const RenderSection = props.editFormData
        ? photoEditorFormMap.get(selectedSection)
        : null;

    // flag corresponding to the particular section to limit number of get calls
    const imagesFlag =
        photoInfo.allImagesFlags[
            imageTypes[
                selectedSection as keyof typeof imageTypes
            ] as keyof typeof photoInfo.allImagesFlags
        ];
    const handleNavClick = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        formName: string,
    ) => {
        event.preventDefault();
        dispatch(handlePhotoEditorSwitch(formName, props.editFormData));
    };

    useEffect(() => {
        if (!photoInfo.flags.creditsFlag) {
            dispatch(fetchPhotoCredits(props.editFormData.data.id));
        }
    }, [props.editFormData.data.id, photoInfo.flags.creditsFlag, dispatch]);

    useEffect(() => {
        if (!photoInfo.flags.filterFlag) {
            dispatch(fetchFilter());
        }
    }, [photoInfo.flags.filterFlag, dispatch]);

    useEffect(() => {
        if (!imagesFlag) {
            dispatch(
                fetchImages(
                    'listing',
                    props.editFormData.data.id,
                    imageTypes[selectedSection as keyof typeof imageTypes],
                ),
            );
        }
    }, [selectedSection, dispatch, imagesFlag, props.editFormData.data.id]);

    useEffect(() => {
        if (!photoInfo.flags.imageCountFlag) {
            dispatch(fetchTotalImagesCount(props.editFormData.data.id, 'listing'));
        }
    }, [photoInfo.flags.imageCountFlag, dispatch, props.editFormData.data.id]);

    useEffect(() => {
        if (!photoInfo.flags.imageTypesFlag) {
            dispatch(fetchImageTypes(props.editFormData.data.id, 'listing'));
        }
    }, [photoInfo.flags.imageTypesFlag, dispatch, props.editFormData.data.id]);

    const renderNavItem = (title: string, formName: string) => {
        return (
            <span
                className={
                    formName === selectedSection ? 'nav-link selected' : 'nav-link'
                }
                onClick={(e) => {
                    handleNavClick(e, formName);
                    googleAnalyticsAction(
                        'Tab Change',
                        'Click',
                        `Listing Edit: Photos Tab clicked, Tab: ${
                            title.split(' (')[0]
                        }`,
                    );
                    googleAnalyticsAction(
                        'Tab Change',
                        'Click',
                        `Listing Edit: Photos Tab clicked, Tab: ${
                            title.split(' (')[0]
                        }, Office: ${props.editFormData.data.office}`,
                    );
                }}
            >
                {title}
            </span>
        );
    };

    const renderTopNav = () => {
        return (
            <div className="top-nav">
                {renderNavItem(
                    `MAIN PHOTOS (${photoInfo.imagesCount.photos})`,
                    photoEditorForms.mainPhotos,
                )}
                {renderNavItem(
                    `FLOOR PLAN (${photoInfo.imagesCount.floorplans})`,
                    photoEditorForms.floorPlan,
                )}
                {renderNavItem(
                    `SHOWCASE HERO IMAGES (${photoInfo.imagesCount.vanity})`,
                    photoEditorForms.showcaseHeroImages,
                )}
                {renderNavItem(
                    `PHOTOGRAPHER UPLOADED IMAGES (${photoInfo.imagesCount.photographers})`,
                    photoEditorForms.photographerUploadedImages,
                )}
            </div>
        );
    };
    return (
        <>
            {renderTopNav()}
            {!imagesFlag ? (
                <div className="circularOverlay">
                    <span className="loadingText">Loading...</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            {RenderSection ? (
                <RenderSection data={props} currentSection={selectedSection} />
            ) : (
                <></>
            )}
        </>
    );
};

export default MainPhotoMenu;
