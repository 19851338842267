import { FunctionComponent } from 'react';
import '../index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { dateFormatMMDDYYY } from '../../../../../../utils/urlUtils';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';
import { MarketingData } from '../../../../../../shared/models/marketing/marketingModels';

interface ReadOnlyApProps {
    addition: MarketingData;
    onEdit(addition: MarketingData): void;
    deleteItem(id: string | undefined): void;
}

const ReadOnlyList: FunctionComponent<ReadOnlyApProps> = ({
    addition,
    onEdit,
    deleteItem,
}) => {
    const ga = () => {
        googleAnalyticsAction('view detail', 'Click', 'Additional Promotion - Builder');
    };
    return (
        <div className="data-container">
            <div className="additionData">
                {addition.date && dateFormatMMDDYYY(addition.date.toString()) ? (
                    <p className="date">{dateFormatMMDDYYY(addition.date.toString())}</p>
                ) : (
                    ''
                )}
                {addition.type && addition.type.trim().length > 0 ? (
                    <p>{addition.type}</p>
                ) : (
                    ''
                )}
                {addition.description && addition.description.trim().length > 0 ? (
                    <p>{addition.description}</p>
                ) : (
                    ''
                )}
                <div className="data-links">
                    {addition.url && addition.url.trim().length > 0 ? (
                        <div className="url-link">
                            {addition.url.includes('.pdf') &&
                            addition.url.includes('http') ? (
                                <a href={addition.url} target="_blank" rel="noreferrer">
                                    <span onClick={ga}>VIEW</span>
                                </a>
                            ) : (
                                <a href={addition.url} target="_blank" rel="noreferrer">
                                    <span onClick={ga}>LINK</span>
                                </a>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="icon-container">
                <span className="icon">
                    <FontAwesomeIcon
                        className="drag"
                        icon={faEdit}
                        onClick={() => {
                            onEdit(addition);
                        }}
                    />
                </span>
                <span className="icon lasticon">
                    <FontAwesomeIcon
                        className="drag"
                        icon={faTrashAlt}
                        onClick={(e) => {
                            deleteItem(addition.id);
                        }}
                    />
                </span>
            </div>
        </div>
    );
};

export default ReadOnlyList;
