import { AppThunk } from '../../../../../../app/store';
import { OwnerInfoModel } from '../../../../../../shared/models/ownerInfoModel/ownerInfoModel';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { updateMainListingData } from '../../../listingSlice';
import { getOwnerInfoApi, updateOwnerInfoApi } from './ownerInfoApi';

export const getOwnerInfo =
    (listingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getOwnerInfoApi(listingId);
            if (response.length > 0) {
                dispatch(updateMainListingData({ ownerInfo: response }));
            }
        } catch {
            Logger.error('Error in retrieving the owner information');
            dispatch(setError('Error in retrieving the owner information'));
        }
    };

export const updateOwnerInfoState =
    (listingId: string, ownerInfo: OwnerInfoModel[]): AppThunk =>
    async (dispatch) => {
        try {
            const response = await updateOwnerInfoApi(listingId, ownerInfo);
            if (response.length > 0) {
                dispatch(updateMainListingData({ ownerInfo }));
                dispatch(setSuccess('Owner information has been updated'));
            }
        } catch {
            Logger.error('Error in saving owner information');
            dispatch(setError('Error in saving owner information'));
        }
    };
