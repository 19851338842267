import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { JssProvider } from 'react-jss';
import './shared/fonts/BentonSans-Medium_sOT.otf';
import './shared/fonts/MercuryTextG4-Roman-Pro.otf';
import './shared/fonts/FreigBigProBoo.otf';
import './shared/fonts/BentonSans-Book_sOT.otf';
import './shared/fonts/MercuryTextG4-Italic-Pro.otf';
import './shared/fonts/FreigBigProMed.otf';
import './shared/fonts/BentonSans-Regular.otf';
import './index.scss';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AppWithRouterAccess from './shared/auth/AppWithRouterAccess';

ReactDOM.render(
    <React.StrictMode>
        <JssProvider disableStylesGeneration={true} classNamePrefix="appzeus">
            <CssBaseline />
            <Provider store={store}>
                <AppWithRouterAccess />
            </Provider>
        </JssProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
