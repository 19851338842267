import { FunctionComponent, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { getDimensions } from '../../../utils/urlUtils';
import { ImageDetail, PhotoTemplateProps } from '../../models/images/sharedModel';
import { useAppDispatch } from '../../../app/hooks';
import { setError } from '../../slices/messaging/messagingSlice';
import { v4 as uuidv4 } from 'uuid';
import './photoTemplate.scss';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Tooltip, makeStyles } from '@material-ui/core';
import {
    faCropSimple,
    faTrash,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { urlToFileUsingFetch } from '../../utils/photos/photoUtils';

import ComponentWithLoader from '../loader/ComponentWithLoader';
const PhotoTemplate: FunctionComponent<PhotoTemplateProps> = ({
    entityId,
    entityType,
    imageType,
    photoUrl,
    isUploading,
    infoText,
    handleUpload,
    deleteHandler,
    validateImageSize,
    imageCrop,
}) => {
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const rejectedPhotos: string[] = [];
    const validateType = (file: File): boolean => {
        return (
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/png'
        );
    };
    const dropHandler = async (files: File[]) => {
        setErrorMessage('');
        const toUpload = files[0];
        if (validateType(toUpload)) {
            const dim = await getDimensions(toUpload);
            if (
                validateImageSize(dim.width, dim.height)
                // &&
                // !isImageLarge(dim.width, dim.height)
            ) {
                const imageData: ImageDetail = {
                    id: uuidv4(),
                    size: toUpload.size.toString(),
                    mimeType: toUpload.type,
                    imageType: imageType,
                    entityType: entityType,
                    entityId: entityId,
                    file: toUpload,
                    height: dim.height,
                    width: dim.width,
                    orderNo: 1,
                    shouldValidate: false,
                    allowOnlyOneImage: false,
                    fromImportOrPhotographer: false,
                    caption: null,
                    floorplancaption: null,
                    tagId: null,
                };
                handleUpload(imageData);
            } else {
                rejectedPhotos.push(toUpload.name.toString());
            }
            if (rejectedPhotos.length > 0) {
                const errorString =
                    rejectedPhotos.join(', ') +
                    ' photo is not uploaded because it does not meet the minimum recommended criteria or requested image size is too large.';
                setErrorMessage(errorString);
            }
        } else {
            dispatch(setError('File type supported: JPG, PNG, JPEG'));
        }
    };

    // Cropper configurations
    const cropperRef = useRef<ReactCropperElement>(null);
    let cropper: Cropper | undefined;
    let cropWidth: number;
    let cropHeight: number;
    const onCrop = (event: CustomEvent) => {
        cropper = cropperRef.current?.cropper;
        cropWidth = Math.ceil(event.detail.width);
        cropHeight = Math.ceil(event.detail.height);
    };
    const useStyles = makeStyles({
        tooltip: {
            maxWidth: 200,
            fontSize: 14,
            backgroundColor: '#000000',
            color: '#FFFFFF',
            fontFamily: 'MercuryTextG4Roman',
            margin: '20px 0px',
        },
    });
    const classes = useStyles();
    const [toggleCropper, setToggleCropper] = useState(0);

    const saveCroppedImage = async () => {
        if (typeof cropper !== 'undefined') {
            const croppedFile = await urlToFileUsingFetch(
                cropper.getCroppedCanvas().toDataURL(),
                'croppedImageOutput.jpg',
                'image/jpg',
            );

            if (cropper) {
                if (validateImageSize(cropWidth, cropHeight)) {
                    const croppedImageData: ImageDetail = {
                        id: uuidv4(),
                        size: croppedFile.size.toString(),
                        mimeType: croppedFile.type,
                        imageType: imageType,
                        entityType: entityType,
                        entityId: entityId,
                        file: croppedFile,
                        height: cropHeight,
                        width: cropWidth,
                        orderNo: 1,
                        shouldValidate: false,
                        allowOnlyOneImage: false,
                        fromImportOrPhotographer: false,
                        caption: null,
                        floorplancaption: null,
                        tagId: null,
                    };
                    handleUpload(croppedImageData, true);
                    setToggleCropper(0);
                } else {
                    dispatch(
                        setError(
                            'Photo is not uploaded because it does not meet the minimum recommended criteria',
                        ),
                    );
                }
            }
        }
    };

    return (
        <main className="photoContainer">
            <ComponentWithLoader
                showLoader={isUploading}
                showOverlay={true}
                styleClass="loaderClass"
            />
            <Grid item container md={12} className="info-text">
                {infoText}
            </Grid>
            {photoUrl.length ? (
                <Grid className="imageSection">
                    <div className="imageController">
                        {!toggleCropper ? (
                            <img src={photoUrl} alt="" />
                        ) : (
                            <Cropper
                                src={photoUrl}
                                // Cropper.js options
                                initialAspectRatio={2 / 3}
                                aspectRatio={2 / 3}
                                guides={true}
                                crop={onCrop}
                                ref={cropperRef}
                            />
                        )}

                        <div className="bottomBar">
                            <div className="bottomActions">
                                {!toggleCropper ? (
                                    <>
                                        <Tooltip
                                            title="Delete Image"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faTrash}
                                                    onClick={deleteHandler}
                                                />
                                            </span>
                                        </Tooltip>
                                        {imageCrop ? (
                                            <Tooltip
                                                title="Crop Image"
                                                classes={{
                                                    tooltip: classes.tooltip,
                                                }}
                                                arrow
                                                placement="top"
                                            >
                                                <span>
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        icon={faCropSimple}
                                                        onClick={() =>
                                                            setToggleCropper(1)
                                                        }
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Tooltip
                                            title="Save Crop"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faCheck}
                                                    onClick={() => saveCroppedImage()}
                                                />
                                            </span>
                                        </Tooltip>
                                        <Tooltip
                                            title="Cancel Crop"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faTimes}
                                                    onClick={() => setToggleCropper(0)}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid>
                    <Dropzone
                        onDrop={(acceptedFiles) => dropHandler(acceptedFiles)}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <p>
                                        Try dropping some files here, or click to select
                                        files to upload.
                                    </p>
                                    <button className="button customButton">
                                        Upload
                                        <FontAwesomeIcon
                                            icon={faArrowUpFromBracket}
                                            className="icon"
                                        />
                                    </button>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    {errorMessage.length > 0 && (
                        <Grid className="errorRow">{errorMessage}</Grid>
                    )}
                </Grid>
            )}
        </main>
    );
};

export default PhotoTemplate;
