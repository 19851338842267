import {
    BrightCoveData,
    BrightcoveResponse,
} from '../../../../../../shared/models/video/brightcove/brightcoveModels';
import { postCall } from '../../../../../../utils/api/apiWrapper';

export function postAgentVideoMetaData(
    agentId: string,
    profileId: string,
    request: BrightCoveData,
): Promise<BrightcoveResponse> {
    return postCall<BrightCoveData, BrightcoveResponse>(
        `agent/${agentId}/${profileId}/videos`,
        request,
    );
}
