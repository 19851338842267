import { FunctionComponent, useState } from 'react';
import { Showing } from '../../../../../features/cmr/cmrModels';
import { dateFormatMMDDYYY } from '../../../../../utils/urlUtils';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    arrow: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
});

interface ShowingListProps {
    useGa: boolean;
    showing: Showing;
    deleteIt(id: string | undefined): void;
    editClickHandler(id: string | undefined): void;
}

const ListTemplate: FunctionComponent<ShowingListProps> = ({
    useGa,
    showing,
    deleteIt,
    editClickHandler,
}) => {
    const classes = useStyles();

    const [activeId, setActiveId] = useState<string>('');

    const clickHandler = (currentId: string | undefined) => {
        if (useGa) googleAnalyticsAction('view detail', 'Click', 'Showings Notes expand');
        if (currentId === activeId) {
            return setActiveId('');
        } else if (currentId) {
            setActiveId(currentId);
        }
    };

    return (
        <div className="rows">
            <div className="row-1">
                {showing.showingDate &&
                dateFormatMMDDYYY(showing.showingDate.toString()) ? (
                    <div className="date data-text">
                        {dateFormatMMDDYYY(showing.showingDate.toString())}
                    </div>
                ) : (
                    <div className="date data-text"></div>
                )}
                {showing.buyer && showing.buyer.trim().length > 0 ? (
                    <div className="buyer data-text">{showing.buyer}</div>
                ) : (
                    <div className="buyer data-text"></div>
                )}
                {showing.buyerAgents && showing.buyerAgents.trim().length > 0 ? (
                    <div className="location data-text">{showing.buyerAgents}</div>
                ) : (
                    <div className="location data-text"></div>
                )}
                {showing.count && showing.count > 0 ? (
                    <div className="location data-text">{showing.count}</div>
                ) : (
                    <div className="location data-text"></div>
                )}
                <div className="notes data-text" onClick={() => clickHandler(showing.id)}>
                    {activeId === showing.id ? (
                        <ArrowDropDownIcon className={classes.arrow} />
                    ) : (
                        <ArrowRightIcon className={classes.arrow} />
                    )}
                </div>
                <div className="showings-icons">
                    <div className="icon-container">
                        <span className="edit icon">
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="sm"
                                onClick={() => editClickHandler(showing.id)}
                            />
                        </span>
                        <span className="trash icon">
                            <FontAwesomeIcon
                                icon={faTrash}
                                size="sm"
                                onClick={() => deleteIt(showing.id)}
                            />
                        </span>
                    </div>
                </div>
            </div>
            {activeId === showing.id && (
                <div className="row-2">
                    <div className="notes-container">
                        <div className="notes-row">
                            <span className="notes-title-text">Notes</span>
                            {showing.notes && showing.notes.trim().length > 0 ? (
                                <div className="notes-content">{showing.notes}</div>
                            ) : (
                                <div className="notes-content empty-content"></div>
                            )}
                        </div>
                        <div className="notes-row">
                            <span className="notes-title-text">Feedback</span>
                            {showing.feedback && showing.feedback.trim().length > 0 ? (
                                <div className="notes-content">{showing.feedback}</div>
                            ) : (
                                <div className="notes-content empty-content"></div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListTemplate;
