import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import Logger from '../../utils/logging/logger';
import {
    setSuccess,
    setError,
    setWarning,
} from '../../shared/slices/messaging/messagingSlice';
import { uploadShowingsFile, uploadSelectSoldsFile } from './excelUploadApi';
import {
    ExcelUploadState,
    UploadedFile,
    ShowingsUploadResponse,
    UploadSelectSoldsResponse,
} from './excelUploadModel';
import { jsonToFormData } from '../../utils/urlUtils';

const initialExcelUpload: ExcelUploadState = {
    isUploading: false,
    showingsResponse: {
        total: 0,
        updated: 0,
        skipped: 0,
        success: false,
        text: '',
        skippedMls: [],
    },
    selectSoldsListingResponse: {
        total: 0,
        processed: 0,
        unprocessed: 0,
        unprocessedRows: [],
        missingAgentIds: [],
    },
};

export const excelUploadSlice = createSlice({
    name: 'excelUpload',
    initialState: initialExcelUpload,
    reducers: {
        isUploadingExcel: (state, action: PayloadAction<boolean>) => {
            state.isUploading = action.payload;
        },
        updateShowingsResponse: (
            state,
            action: PayloadAction<ShowingsUploadResponse>,
        ) => {
            state.showingsResponse = action.payload;
        },
        updateSelectSoldsImportResponse: (
            state,
            action: PayloadAction<UploadSelectSoldsResponse>,
        ) => {
            state.selectSoldsListingResponse = action.payload;
        },
    },
});

export const sendShowingsUploadFile =
    (data: UploadedFile): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isUploadingExcel(true));
            const response = await uploadShowingsFile(jsonToFormData(data));
            if (response.success) {
                dispatch(setSuccess(response.text));
            } else {
                dispatch(setError(response.text));
            }
            dispatch(updateShowingsResponse(response));
        } catch (e) {
            Logger.error(`Failed to upload the showings file`);
            dispatch(setError('Failed to upload the showings file'));
        } finally {
            dispatch(isUploadingExcel(false));
        }
    };

export const sendSelectSoldsUploadFile =
    (data: UploadedFile): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isUploadingExcel(true));
            const response = await uploadSelectSoldsFile(jsonToFormData(data));
            if (response.total > 0) {
                dispatch(updateSelectSoldsImportResponse(response));
                if (response.processed > 0)
                    dispatch(setSuccess('Select sold listings are processed'));
            } else {
                dispatch(setWarning('Select sold file is empty'));
            }
        } catch (e) {
            Logger.error(`Failed to upload the select sold file`);
            dispatch(setError('Failed to upload the select sold file'));
        } finally {
            dispatch(isUploadingExcel(false));
        }
    };

export const {
    isUploadingExcel,
    updateShowingsResponse,
    updateSelectSoldsImportResponse,
} = excelUploadSlice.actions;
export const excelUpload = (state: RootState): ExcelUploadState => state.excelUpload;
export default excelUploadSlice.reducer;
