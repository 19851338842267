import { useState, ChangeEvent, useEffect, MouseEvent } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { FLOATREGEX, MAXINTEGERVALUE } from '../utils/constants';
import { Moment } from 'moment';
import { setError } from '../shared/slices/messaging/messagingSlice';
import { LookupInfo } from '../shared/models/lookups/lookupsModels';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useFormData = <T>(
    initialState: T,
): {
    formData: T;
    setFormData: React.Dispatch<React.SetStateAction<T>>;
    handleTextChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
        validateHttps?: boolean,
    ) => void;
    handleEditorChange: (
        name: string,
        value: string,
        maxLength?: number | undefined,
    ) => void;
    handleDropDownChange: (
        event: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    handleDropDownSearch: (data: LookupInfo, name: string) => void;
    handleDateChange: (field: string, value: Moment | null) => void;
    handleNumberChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isPhone?: boolean,
    ) => void;
    handleFloatChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleRadioButtonChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleCurrencyChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean,
    ) => void;
    removeSpaces: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
    ) => void;
    handleFileUpload: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        showUrl?: boolean,
    ) => T;
    handleFileClear: (
        event: MouseEvent<HTMLButtonElement>,
        inputName: string,
        shouldDeleteFile: boolean,
        fileDeleteHandler?: (from: string) => void,
        handleUrls?: boolean,
    ) => void;
    handleUrlChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleCurrencyChangeWithDecimal: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean,
    ) => void;
} => {
    const [formData, setFormData] = useState(initialState);
    const dispatch = useAppDispatch();

    const handleTextChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
        validateHttps?: boolean,
    ) => {
        let { name, value } = e.target;
        if (value.length <= (maxLength || MAXINTEGERVALUE)) {
            if (validateHttps) {
                if (value.length >= 7 && value.search(/^http[s]?:\/\//) === 0) {
                    if (value.length === 8) {
                        value = '';
                    }
                } else if (value.length >= 7 && value.search(/^http[s]?:\/\//) === -1) {
                    value = `https://${value}`;
                }
            }

            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleEditorChange = (
        name: string,
        value: string,
        maxLength?: number | undefined,
    ) => {
        if (value.length <= (maxLength || MAXINTEGERVALUE)) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const removeSpaces = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
    ) => {
        const { name, value } = e.target;
        if (value && value.length <= (maxLength || MAXINTEGERVALUE)) {
            setFormData({
                ...formData,
                [name]: value.trim(),
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleDropDownChange = (
        e: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name || '']: value });
    };

    const handleDropDownSearch = (data: LookupInfo, name: string) => {
        setFormData({ ...formData, [name || '']: data.id });
    };

    const handleDateChange = (field: string, value: Moment | null) => {
        const dateVal = value !== null ? value.format('YYYY-MM-DDTHH:mm') : '';
        setFormData({ ...formData, [field]: dateVal });
    };

    const handleNumberChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isPhone?: boolean,
    ) => {
        const { name, value } = e.target;
        const numberVal = parseInt(value);
        if (
            !isNaN(numberVal) &&
            (isPhone ? numberVal.toString().length <= 10 : numberVal < MAXINTEGERVALUE)
        ) {
            setFormData({ ...formData, [name]: numberVal });
        } else {
            setFormData({ ...formData, [name]: '' });
        }
    };

    const handleFloatChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let { name, value } = e.target;
        if (value.match(FLOATREGEX)) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData({ ...formData, [name]: checked });
    };

    const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // If value is a number, use the number value, else we'll
        // assume it's a boolean
        const numberVal = parseInt(value);

        if (isNaN(numberVal)) {
            setFormData({ ...formData, [name]: value.toLowerCase() === 'true' });
        } else {
            setFormData({ ...formData, [name]: numberVal });
        }
    };

    const handleCurrencyChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean,
    ) => {
        const { name, value } = e.target;
        const numVal = parseInt(value.replace(/[$,]+/g, ''));
        setFormData({
            ...formData,
            [name]: checkLength ? (numVal < MAXINTEGERVALUE ? numVal : '') : numVal,
        });
    };

    // File Related Methods

    const fileUpload = (uploadedFile: File) => {
        const size = uploadedFile.size / 1024 / 1024; // in MiB
        const validType =
            uploadedFile.type === 'image/jpg' ||
            uploadedFile.type === 'image/jpeg' ||
            uploadedFile.type === 'image/png';

        if (size > 50) {
            dispatch(setError('Max file size supported: 50 MB'));
            return false;
        }
        if (!validType) {
            if (uploadedFile.type === 'application/pdf') {
                return true;
            } else {
                dispatch(setError('File format supported: JPG, JPEG, PNG, PDF'));
                return false;
            }
        }
        return true;
    };

    const handleFileUpload = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        showUrl?: boolean,
    ): T => {
        const inputEvent = event as ChangeEvent<HTMLInputElement>;
        if (inputEvent && inputEvent.target && inputEvent.target.files) {
            const updatedFile = new File(
                [inputEvent.target.files[0]],
                inputEvent.target.files[0].name.toLowerCase(),
                { type: inputEvent.target.files[0].type },
            );
            const uploadedFile = updatedFile;
            if (uploadedFile && fileUpload(uploadedFile)) {
                const inputName = inputEvent.target.name;
                const updatedFileData = showUrl
                    ? {
                          ...formData,
                          [inputName]: uploadedFile,
                          url: uploadedFile.name,
                          isFile: true,
                      }
                    : {
                          ...formData,
                          [inputName]: uploadedFile,
                      };
                setFormData(updatedFileData);
                return updatedFileData;
            }
        }
        return formData;
    };

    const handleFileClear = (
        event: MouseEvent<HTMLButtonElement>,
        inputName: string,
        shouldDeleteFile: boolean,
        fileDeleteHandler?: (from: string) => void,
        handleUrls?: boolean,
    ) => {
        event.preventDefault();
        const updatedFileData = handleUrls
            ? {
                  ...formData,
                  [inputName]: undefined,
                  isFile: false,
                  url: '',
              }
            : {
                  ...formData,
                  [inputName]: undefined,
              };
        setFormData(updatedFileData);

        if (shouldDeleteFile && fileDeleteHandler) {
            fileDeleteHandler(inputName);
        }
    };

    const handleUrlChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        let value = e.target.value;
        if (value.length > 0 && value.search(/^http[s]?:\/\//) === -1) {
            value = `https://${value}`;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleCurrencyChangeWithDecimal = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean,
    ) => {
        const { name, value } = e.target;
        let numVal;
        if (Number.isInteger(value)) {
            numVal = parseInt(value.replace(/[$,]+/g, ''));
        } else {
            if (value.endsWith('.')) {
                numVal = value;
            } else {
                const floatValue = parseFloat(value.replace(/[$,]+/g, ''));
                const decimalLength = (floatValue + '').split('.');
                if (decimalLength && decimalLength[1] && decimalLength[1].length > 2) {
                    numVal = floatValue.toFixed(2);
                } else {
                    numVal = floatValue;
                }
            }
        }
        setFormData({
            ...formData,
            [name]: checkLength
                ? Number(numVal) < MAXINTEGERVALUE
                    ? numVal
                    : ''
                : numVal,
        });
    };

    return {
        formData,
        setFormData,
        handleTextChange,
        handleEditorChange,
        handleDropDownChange,
        handleDropDownSearch,
        handleDateChange,
        handleNumberChange,
        handleFloatChange,
        handleCheckboxChange,
        handleRadioButtonChange,
        handleCurrencyChange,
        removeSpaces,
        handleFileUpload,
        handleFileClear,
        handleUrlChange,
        handleCurrencyChangeWithDecimal,
    };
};

export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}
