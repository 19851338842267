export interface LoaderDetails {
    loadingEvents: string[];
    isLoading: boolean;
}

export const DesignVaultEvents = {
    DESIGN_VAULT_OFFICE_TEMPLATE_ADD: 'addOfficeDesignVaultTemplate',
    DESIGN_VAULT_OFFICE_TEMPLATE_REMOVE: 'removeOfficeDesignVaultTemplate',
    DESIGN_VAULT_TEMPLATE_GET: 'getDesignVaultTemplate',
    DESIGN_VAULT_PROPERTYGUID_GET: 'getPropertyGuid',
};

export const MlsEmailEvents = {
    MLS_EMAILS_GET: 'getMlsEmails',
    MLS_EMAILS_SAVE: 'saveMlsEmail',
    MLS_EMAILS_DELETE: 'deleteMlsEmail',
};

export const SyndicationEvents = {
    LISTING_SYNDICATION_GET: 'getListingSyndication',
    LISTING_SYNDICATION_SAVE: 'saveListingSyndication',
};

export const ListingInformationEvents = {
    LISTING_INFORMATION_GET: 'getlistingDetails',
    LISTING_INFORMATION_TOGGLE: 'updateToggleSwitch',
    INVALID_LISTING_GET: 'getInvalidListing',
    LISTING_HEADER_UPDATE: 'updateListingDetailHeader',
    LISTING_AGENT_UPDATE: 'updateListingAgent',
    LISTING_MAP_UPDATE: 'updateListingMap',
};

export const MarketingEvents = {
    MARKETING_GET: 'getMarketing',
    MARKETING_SAVE: 'saveMarketing',
    MARKETING_DELETE: 'deleteMarketing',
};

export const CmrEvents = {
    DATA_GET: 'dataGet',
    MEDIA_UPDATE: 'mediaUpdate',
};

export const AdCopyEvents = {
    AD_COPY_GET: 'getAdCopies',
    AD_COPY_SAVE: 'saveAdCopy',
    LISTING_TITLE_SAVE: 'saveListingTitle',
};

export const ListingDetailEvents = {
    LISTING_GET: 'getListingDetails',
    LISTING_SAVE: 'saveListingDetails',
    LISTING_DELETE: 'deleteListingDetails',
};

export const PhotoEditorEvents = {
    PHOTOS_DELETE: 'deleteImages',
    PHOTOS_SAVE: 'saveImages',
    PHOTOS_GET: 'getImages',
};

export const AgentEvents = {
    AGENT_ADD: 'saveAgentDetails',
    AGENT_UPDATE: 'updateAgentDetails',
    AGENT_DELETE: 'deleteAgentDetails',
};

export const OfficeDetailEvents = {
    OFFICE_DETAILS_ADD: 'saveOfficeDetails',
    OFFICE_DETAILS_UPDATE: 'updateOfficeDetails',
    OFFICE_DETAILS_DELETE: 'deleteOfficeDetails',
};

export const DevelopmentDetailEvents = {
    DEVELOPMENT_GET: 'getDevelopmentDetails',
    DEVELOPMENT_SAVE: 'saveDevelopmenDetails',
    DEVELOPMENT_DELETE: 'deleteDevelopmenDetails',
};
