import { FunctionComponent, useState, useRef } from 'react';
import { Cropper } from 'react-cropper';
import {
    aspectRatio,
    minImageDimLandscape,
    minImageDimPortrait,
} from '../../../../constants/photos/photoConstants';
import {
    ImageData,
    ImageDetail,
    Flags,
    ImageTypes,
} from '../../../../models/images/sharedModel';
import { CircularProgress } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import {
    urlToFileUsingFetch,
    validateImageSize,
} from '../../../../utils/photos/photoUtils';
import { useAppDispatch } from '../../../../../app/hooks';
import { UpdateFlag } from '../../../../models/flag/flagModel';
import { AppThunk } from '../../../../../app/store';
import 'cropperjs/dist/cropper.css';
import './imageCrop.scss';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';

interface ImageCropProps {
    image: ImageData | undefined;
    currentImageType: string;
    cancelHandler(): void;
    isFullScreen: boolean;
    setSelectedAction: React.Dispatch<React.SetStateAction<string>>;
    saveImageDetails: (request: ImageDetail, oldImage?: ImageData) => AppThunk;
    flagUpdate: (data: UpdateFlag) => void;
    flags: Flags;
    currentSectionType: ImageTypes;
    entityType: string;
    office: string | null;
}

const ImageCrop: FunctionComponent<ImageCropProps> = ({
    image,
    currentImageType,
    cancelHandler,
    isFullScreen,
    setSelectedAction,
    saveImageDetails,
    flagUpdate,
    flags,
    currentSectionType,
    entityType,
    office,
}) => {
    const initialImageDetail: ImageDetail = {
        id: '',
        orderNo: 0,
        width: 0,
        height: 0,
        size: '',
        mimeType: '',
        allowOnlyOneImage: false,
        entityType: '',
        entityId: '',
        imageType: '',
        file: undefined,
        fromImportOrPhotographer: false,
        caption: image ? image.caption : null,
        floorplancaption: image ? image.floorplancaption : null,
        tagId: image ? image.tagId : null,
        shouldValidate: true,
    };
    // const { flags, types } = photoInfo;
    const [cropper, setCropper] = useState<Cropper>();
    const [orientation, setOrientation] = useState(aspectRatio.landscape);
    const [cropWidth, setCropWidth] = useState<number>(0);
    const [cropHeight, setCropHeight] = useState<number>(0);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const cropperRef = useRef<HTMLImageElement>(null);
    const cropperInstance = useRef<Cropper>();

    const dispatch = useAppDispatch();

    // const currentSectionType = types[currentImageType as keyof typeof types];

    const onCrop = (event: Cropper.CropEvent<HTMLImageElement>) => {
        if (image) {
            const width = Math.round(event.detail.width);
            const height = Math.round(event.detail.height);
            setCropWidth(width);
            setCropHeight(height);
            if (
                width < minImageDimLandscape.minCropWidth ||
                height < minImageDimLandscape.minCropHeight
            ) {
                if (cropperRef.current !== null && cropperInstance.current !== null) {
                    if (orientation === aspectRatio.landscape) {
                        setTimeout(() => {
                            cropperInstance.current &&
                                cropperInstance.current.setData({
                                    width:
                                        width < minImageDimLandscape.minCropWidth
                                            ? minImageDimLandscape.minCropWidth
                                            : width,
                                    height:
                                        height < minImageDimLandscape.minCropHeight
                                            ? minImageDimLandscape.minCropHeight
                                            : height,
                                });
                        });
                    } else if (orientation === aspectRatio.portrait) {
                        setTimeout(() => {
                            cropperInstance.current &&
                                cropperInstance.current.setData({
                                    width:
                                        width < minImageDimPortrait.minCropWidth
                                            ? minImageDimPortrait.minCropWidth
                                            : width,
                                    height:
                                        height < minImageDimPortrait.minCropHeight
                                            ? minImageDimPortrait.minCropHeight
                                            : height,
                                });
                        });
                    }
                }
            }
        }
    };

    const canCrop = (width: number, height: number) => {
        return (
            width >= minImageDimPortrait.minCropWidth &&
            height >= minImageDimPortrait.minCropHeight
        );
    };

    const getCropData = async () => {
        if (typeof cropper !== 'undefined') {
            flagUpdate({ property: 'savingCrop', value: true });
            // const { width, height } = cropper.getCroppedCanvas(); // whether this or state
            const croppedFile = await urlToFileUsingFetch(
                cropper.getCroppedCanvas().toDataURL(),
                'croppedImageOutput.jpg',
                'image/jpg',
            );

            if (image) {
                const croppedImageData: ImageDetail = {
                    ...initialImageDetail,
                    id: uuidv4(),
                    size: croppedFile.size.toString(),
                    mimeType: croppedFile.type,
                    imageType: currentImageType,
                    entityType: entityType,
                    entityId: image.entityId,
                    file: croppedFile,
                    height: cropHeight,
                    width: cropWidth,
                    orderNo: image.orderNo,
                };
                await dispatch(saveImageDetails(croppedImageData, image));
                googleAnalyticsAction(
                    'Save',
                    'Click',
                    `${
                        entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                    }: Image Edit, Image crop save clicked, Image type: ${currentImageType}`,
                );
                googleAnalyticsAction(
                    'Save',
                    'Click',
                    `${
                        entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                    }: Image Edit, Image crop save clicked, Image type: ${currentImageType}, Office: ${office}`,
                );
                setSelectedAction('');
            }
        }
    };

    return (
        <div
            className={
                isFullScreen ? 'cropperHolder cropperHolderFullScreen' : 'cropperHolder'
            }
        >
            {flags.savingCrop ? (
                <div className="cropSaveCircularOverlay">
                    <span className="savingCropText">Saving Cropped Image</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            {!isLoaded ? (
                <div className="circularOverlay">
                    <span className="imageLoadingText">Loading image</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            <Cropper
                style={{
                    height: isFullScreen ? '78%' : 400,
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    opacity: 'unset',
                }}
                zoomable={false}
                preview=".img-preview"
                src={image ? image.urls.originalUrl : ''}
                initialAspectRatio={aspectRatio.landscape}
                viewMode={1} //2
                aspectRatio={aspectRatio.landscape}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                    setCropper(instance);
                    cropperInstance.current = instance;
                }}
                // checkCrossOrigin={false}
                ref={cropperRef}
                crop={onCrop}
                onLoad={() => {
                    setLoaded(true);
                }}
            />
            <img
                className="propImage"
                src={image ? image.urls.originalUrl : ''}
                onLoad={() => setLoaded(true)}
                alt={image?.caption ? image.caption : 'Prop image'}
            ></img>
            {image ? (
                <div className="bottomContainer">
                    <div className="actionHolder">
                        <div className="cropActionHolder">
                            <div className="cropDetails">
                                <span className="aspectRatio">
                                    ASPECT RATIO IS SET TO 3X2
                                </span>
                                <span className="dimDetails">
                                    Width: {cropWidth > 0 ? cropWidth : image?.size.width}
                                    , Height:{' '}
                                    {cropHeight > 0 ? cropHeight : image?.size.height}
                                </span>
                            </div>
                            <div className="cropActions">
                                <button
                                    className={
                                        orientation === aspectRatio.landscape
                                            ? 'cropActionButton activeCropActionButton'
                                            : 'cropActionButton'
                                    }
                                    onClick={() => {
                                        cropper?.setAspectRatio(aspectRatio.landscape);
                                        setOrientation(aspectRatio.landscape);
                                    }}
                                    disabled={
                                        !currentSectionType.allowLandscape ||
                                        (image &&
                                            !validateImageSize(
                                                image.size.width,
                                                image.size.height,
                                            ))
                                    }
                                >
                                    <span
                                        className={
                                            !currentSectionType.allowLandscape ||
                                            (image &&
                                                !validateImageSize(
                                                    image.size.width,
                                                    image.size.height,
                                                ))
                                                ? 'inactiveActionButton'
                                                : ''
                                        }
                                    >
                                        landscape
                                    </span>
                                </button>
                                <button
                                    className={
                                        orientation === aspectRatio.portrait
                                            ? 'cropActionButton activeCropActionButton'
                                            : 'cropActionButton'
                                    }
                                    onClick={() => {
                                        cropper?.setAspectRatio(aspectRatio.portrait);
                                        setOrientation(aspectRatio.portrait);
                                    }}
                                    disabled={
                                        !currentSectionType.allowPortrait ||
                                        (image &&
                                            !validateImageSize(
                                                image.size.width,
                                                image.size.height,
                                            )) ||
                                        (image &&
                                            !canCrop(image.size.width, image.size.height))
                                    }
                                >
                                    <span
                                        className={
                                            !currentSectionType.allowPortrait ||
                                            (image &&
                                                !validateImageSize(
                                                    image.size.width,
                                                    image.size.height,
                                                )) ||
                                            (image &&
                                                !canCrop(
                                                    image.size.width,
                                                    image.size.height,
                                                ))
                                                ? 'inactiveActionButton'
                                                : ''
                                        }
                                    >
                                        portrait
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="buttonsHolder">
                            <button
                                className="actionButton cancel"
                                onClick={() => {
                                    setSelectedAction('');
                                    googleAnalyticsAction(
                                        'Cancel',
                                        'Click',
                                        `${
                                            entityType === 'listing'
                                                ? 'Listing Edit'
                                                : 'Development Edit'
                                        }: Image Edit, Image crop cancel clicked, Image type: ${currentImageType}`,
                                    );
                                    googleAnalyticsAction(
                                        'Cancel',
                                        'Click',
                                        `${
                                            entityType === 'listing'
                                                ? 'Listing Edit'
                                                : 'Development Edit'
                                        }: Image Edit, Image crop cancel clicked, Image type: ${currentImageType}, Office: ${office}`,
                                    );
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={
                                    image &&
                                    !validateImageSize(
                                        image.size.width,
                                        image.size.height,
                                    )
                                        ? 'actionButton saveActive saveInActive'
                                        : 'actionButton saveActive'
                                }
                                onClick={getCropData}
                                disabled={
                                    image &&
                                    !validateImageSize(
                                        image.size.width,
                                        image.size.height,
                                    )
                                }
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default ImageCrop;
