import { FunctionComponent, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { Input } from '@material-ui/core';
import { OpenHouseData } from '../../../../../features/cmr/cmrModels';
import { useDebounce, useFormData } from '../../../../../app/hooks';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';
import TimeInput from '../../../timeInput/TimeInput';
import 'antd/dist/antd.css';
import SearchAutoComplete from '../../../searchAutoComplete';
import { AgentSearchResult } from '../../../../models/agent/agentModel';
import { getAgentsByLastName } from '../../../../api/agent/agentApi';
import { datePickerFormat } from '../../../../../utils/urlUtils';

interface OpenHouseFormProps {
    useGa: boolean;
    openHouseData?: OpenHouseData;
    formEdit: boolean;
    initialState: OpenHouseData;
    editClickHandler(id: string | undefined): void;
    handleSaveClick(openHouseData: OpenHouseData): void;
    isCmr?: boolean;
}

const FormTemplate: FunctionComponent<OpenHouseFormProps> = (props) => {
    const {
        formData,
        setFormData,
        handleTextChange,
        handleNumberChange,
        handleCheckboxChange,
        removeSpaces,
    } = useFormData(props.openHouseData || props.initialState);

    const [active, setActive] = useState<boolean>(true);
    const [dateValue, setDateValue] = useState<string>(formData.startDateTime);
    const [fromTime, setFromTime] = useState<string>(formData.startDateTime);
    const [toTime, setToTime] = useState<string>(formData.endDateTime);
    const [agentSearchTerm, setAgentSearchTerm] = useState<string>('');
    const debouncedAgentSearchTerm: string = useDebounce<string>(agentSearchTerm, 500);
    const [agents, setAgents] = useState<AgentSearchResult[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<AgentSearchResult | undefined>(
        undefined,
    );

    const handleAppointmentToggle = () => {
        const currentVal = formData.byAppointmentOnly;
        setFormData({ ...formData, byAppointmentOnly: !currentVal });

        if (formData.isVirtual) {
            setFormData({ ...formData, isVirtual: false });
        }
        if (formData.doNotShowTime) {
            setFormData({ ...formData, doNotShowTime: false });
        }
    };

    const handleVirtualToggle = () => {
        const currentVal = formData.isVirtual;
        const updateModel: OpenHouseData = { ...formData, isVirtual: !currentVal };

        if (formData.byAppointmentOnly) {
            updateModel.byAppointmentOnly = false;
            updateModel.doNotShowTime = false;
        }
        if (!currentVal) {
            updateModel.virtualUrl = '';
        }

        setFormData({ ...formData, ...updateModel });
    };

    const handleDate = (date: Moment | null) => {
        if (date) {
            setDateValue(date.format('YYYY-MM-DDTHH:mm'));
        } else {
            setDateValue('');
            setActive(true);
        }
        setFromTime('');
        setToTime('');
    };

    // sets dateTime value to startDateTime and endDateTime in formData based on time changes
    const setDateTime = (property: string, dateTime: string) => {
        if (!property.length) {
            setFormData({ ...formData, startDateTime: '', endDateTime: '' });
        } else {
            setFormData({ ...formData, [property]: dateTime });
            if (property === 'startDateTime') {
                setFromTime(dateTime);
            } else if (property === 'endDateTime') {
                setToTime(dateTime);
            }
        }
    };

    useEffect(() => {
        if (fromTime.length && toTime.length) {
            setFormData({ ...formData, startDateTime: fromTime, endDateTime: toTime });
        }
    }, [fromTime, toTime]);

    const save = () => {
        props.handleSaveClick(formData);
        setFormData(props.initialState);
        setDateValue('');
        setFromTime('');
        setToTime('');
        if (formData.id) {
            props.editClickHandler(formData.id);
        }
        if (props.formEdit && props.useGa)
            googleAnalyticsAction('Edit', 'Click', 'Open House Edit Save');
        if (!props.formEdit && props.useGa)
            googleAnalyticsAction('Add', 'Click', 'Open House Add');
    };

    useEffect(() => {
        const { isVirtual, virtualUrl, startDateTime, endDateTime } = formData;
        const timesAreValid =
            startDateTime.length &&
            endDateTime.length &&
            new Date(startDateTime) < new Date(endDateTime);

        if (!timesAreValid) {
            setActive(true);
        } else if (isVirtual && virtualUrl !== null && virtualUrl.length) {
            setActive(false);
        } else if (isVirtual && (virtualUrl === null || !virtualUrl.length)) {
            setActive(true);
        } else if (!isVirtual) {
            setActive(!timesAreValid);
        }
    }, [formData, setFormData]);

    const getName = (selectedAgent: AgentSearchResult) => {
        const firstname = selectedAgent.preferredFirstName
            ? selectedAgent.preferredFirstName
            : selectedAgent?.firstName;
        const lastname = selectedAgent.preferredLastName
            ? selectedAgent.preferredLastName
            : selectedAgent?.lastName;
        return firstname + ' ' + lastname;
    };

    const addSelectedAgent = (selectedAgent: AgentSearchResult | null) => {
        setFormData({
            ...formData,
            showingAgent: selectedAgent && getName(selectedAgent),
        });
        setSelectedAgent(selectedAgent || undefined);
    };

    useEffect(() => {
        if (debouncedAgentSearchTerm !== '') {
            const fetch = async () => {
                const response = await getAgentsByLastName(debouncedAgentSearchTerm);
                if (response.length > 0) setAgents(response);
            };
            fetch();
        }
    }, [debouncedAgentSearchTerm]);

    return (
        <div>
            {props.formEdit ? <div className="overlayEditForm" /> : ''}
            <div
                className={
                    props.formEdit
                        ? 'openHouseEditContainer'
                        : 'openHouseEditContainer addOpenHouse'
                }
                style={{ zIndex: props.formEdit ? 1000 : 0 }}
            >
                <div className="editForm" id={'openHouseEdit' + props.openHouseData?.id}>
                    <div
                        className={
                            !props.formEdit ? 'editCol-1' : 'editCol-1 edit-inline'
                        }
                    >
                        <div className="row-1">
                            <span className="actionHolder editData-date formControlRoot">
                                <DatePicker
                                    className="datePickerCommon cmrDate"
                                    value={
                                        dateValue !== '' ? moment(dateValue) : undefined
                                    }
                                    format={datePickerFormat()}
                                    onChange={(date: Moment | null) => {
                                        handleDate(date);
                                    }}
                                    placeholder="OH Date"
                                    showToday={false}
                                />
                            </span>
                            <span className="actionHolder editData-agent">
                                <SearchAutoComplete
                                    searchTerm={agentSearchTerm}
                                    setSearchTerm={setAgentSearchTerm}
                                    placeholder="OH Agent"
                                    value={selectedAgent}
                                    results={agents}
                                    userSelected={addSelectedAgent}
                                    blurEvent={() => setAgents([])}
                                    isLoading={false}
                                    from="agent"
                                />
                            </span>
                            <span className="actionHolder editFromtime">
                                <TimeInput
                                    dateValue={fromTime.length ? fromTime : dateValue}
                                    setDateTime={setDateTime}
                                    property="startDateTime"
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </span>
                            <span className="actionHolder editTotime">
                                <TimeInput
                                    dateValue={toTime.length ? toTime : dateValue}
                                    setDateTime={setDateTime}
                                    property="endDateTime"
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </span>
                        </div>
                        <div className="row-2">
                            <span className="actionHolder attendees">
                                <Input
                                    value={
                                        (formData.attendees || 0) > 0
                                            ? formData.attendees
                                            : ''
                                    }
                                    name="attendees"
                                    className="cmrInput"
                                    placeholder="Attendees"
                                    inputProps={{ 'aria-label': 'Attendees' }}
                                    onChange={handleNumberChange}
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </span>
                            <span className="actionHolder notes">
                                <Input
                                    value={formData.notes}
                                    name="notes"
                                    className="cmrInput"
                                    placeholder="Notes"
                                    inputProps={{
                                        'aria-label': 'Notes',
                                        maxLength: 4000,
                                    }}
                                    onChange={handleTextChange}
                                    onBlur={removeSpaces}
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </span>
                        </div>
                        {!props.useGa && (
                            <div className="row-3">
                                <span className="actionHolder section">
                                    <Input
                                        value={formData.section}
                                        name="section"
                                        className="cmrInput"
                                        placeholder="OH Section"
                                        inputProps={{
                                            'aria-label': 'OH Section',
                                            maxLength: 200,
                                        }}
                                        onChange={handleTextChange}
                                        onBlur={removeSpaces}
                                        disableUnderline={props.isCmr ? false : true}
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            !props.formEdit ? 'editCol-2' : 'editCol-2 edit-inline'
                        }
                    >
                        <span className="individualCheckbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="byAppointmentOnly"
                                    className={
                                        formData.isVirtual
                                            ? 'customCheckbox inActiveCustomCheckbox'
                                            : 'customCheckbox'
                                    }
                                    checked={formData.byAppointmentOnly}
                                    onChange={handleAppointmentToggle}
                                    disabled={formData.isVirtual}
                                />
                                <span
                                    className={
                                        formData.byAppointmentOnly
                                            ? 'activeCheckboxContent'
                                            : formData.isVirtual
                                            ? 'inActiveTextCheckboxContent'
                                            : 'inActiveCheckboxContent'
                                    }
                                >
                                    By Appointment Only
                                </span>
                            </label>
                        </span>
                        <span className="individualCheckbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="doNotShowTime"
                                    className={
                                        !formData.byAppointmentOnly &&
                                        !formData.doNotShowTime
                                            ? 'customCheckbox inActiveCustomCheckbox'
                                            : 'customCheckbox'
                                    }
                                    disabled={!formData.byAppointmentOnly}
                                    checked={formData.doNotShowTime}
                                    onChange={handleCheckboxChange}
                                />
                                <span
                                    className={
                                        formData.doNotShowTime
                                            ? 'activeCheckboxContent'
                                            : !formData.byAppointmentOnly &&
                                              !formData.doNotShowTime
                                            ? 'inActiveTextCheckboxContent'
                                            : 'inActiveCheckboxContent'
                                    }
                                >
                                    Don't display time
                                </span>
                            </label>
                        </span>
                        <span className="individualCheckbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="brokerOnly"
                                    className="customCheckbox"
                                    checked={formData.brokerOnly}
                                    onChange={handleCheckboxChange}
                                />
                                <span
                                    className={
                                        formData.brokerOnly
                                            ? 'activeCheckboxContent'
                                            : 'inActiveCheckboxContent'
                                    }
                                >
                                    Broker Only
                                </span>
                            </label>
                        </span>
                        <span className="individualCheckbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="isVirtual"
                                    className="customCheckbox"
                                    checked={formData.isVirtual}
                                    onChange={handleVirtualToggle}
                                />
                                <span
                                    className={
                                        formData.isVirtual
                                            ? 'activeCheckboxContent'
                                            : 'inActiveCheckboxContent'
                                    }
                                >
                                    Virtual
                                </span>
                            </label>
                        </span>
                        {formData.isVirtual && (
                            <span className="actionHolder virtualTour">
                                <Input
                                    value={formData.virtualUrl}
                                    name="virtualUrl"
                                    className="virtualTourInput cmrInput"
                                    placeholder="Virtual Tour URL"
                                    inputProps={{
                                        'aria-label': 'Virtual Tour URL',
                                        maxLength: 500,
                                    }}
                                    onChange={handleTextChange}
                                    onBlur={removeSpaces}
                                    disableUnderline={props.isCmr ? false : true}
                                />
                            </span>
                        )}
                        <div className="buttonGroup">
                            {!props.formEdit ? (
                                <div className="addButtonHolder">
                                    <button
                                        disabled={active ? true : false}
                                        className={`addButton ${
                                            active ? 'active' : 'unActive'
                                        }`}
                                        onClick={save}
                                    >
                                        Add
                                    </button>
                                </div>
                            ) : (
                                <div className="editButtonHolder">
                                    <button
                                        className="actionButton cancel"
                                        onClick={() =>
                                            props.editClickHandler(formData.id)
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={active ? true : false}
                                        className={
                                            !active
                                                ? 'actionButton saveActive'
                                                : 'actionButton saveInActive'
                                        }
                                        onClick={save}
                                    >
                                        Save
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormTemplate;
