import { ClientMarketingSchedule, Schedules, SentEmails } from './schedulerModels';
import {
    deleteCall,
    getCall,
    postCall,
    putCallRaw,
} from '../../../../utils/api/apiWrapper';

export function fetchClientMarketingSchedule(
    listingId: string,
): Promise<ClientMarketingSchedule> {
    return getCall<ClientMarketingSchedule>(`ClientMarketingSchedule/${listingId}`);
}

export function addScehduleData(schedule: Schedules): Promise<Schedules> {
    return postCall<Schedules, Schedules>('ClientMarketingSchedule', schedule);
}

export function fetchScheduleSentEmails(listingId: string): Promise<SentEmails[]> {
    return getCall<SentEmails[]>(`ClientReportSendAudit/${listingId}`);
}

export function updateScheduleData(
    id: string,
    updatedSchedule: Schedules,
): Promise<Response> {
    return putCallRaw(`ClientMarketingSchedule/${id}`, updatedSchedule);
}

export function deleteScheduleData(id: string): Promise<Response> {
    return deleteCall(`ClientMarketingSchedule/${id}`);
}
