import SalesAgentDetails from './drawer/screens/details/SalesAgentDetails';
import {
    SalesTeamDetail,
    SalesTeamDescription,
    DescriptionDetails,
} from './salesTeamModels';
import Video from './drawer/screens/video/Video';
import { initializeVideoData } from '../../../shared/functions/video/video';
import PhotoLogo from './drawer/screens/photoLogo/index';
import Members from './drawer/screens/members/Members';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import Assistants from './drawer/screens/assistants/Assistants';
import SalesTeamTestimonials from './drawer/screens/salesTeamTestimonials/SalesTeamTestimonials';

const descriptionDetails: DescriptionDetails = {
    id: '',
    name: '',
    description: '',
    office: '',
    mlsNumber: '',
    email: '',
    websiteUrl: '',
    linkedInUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    youtubeUrl: '',
    phone: '',
    phoneNumberDisplay: '',
    canShareProperties: false,
    phoneNoDisplayOrder: 1,
    statusId: 1,
    status: 'Active',
};

export const initialSalesTeamDetailData: SalesTeamDetail = {
    ...descriptionDetails,
    createdDate: '',
    lastUpdated: '',
    isValid: false,
    accolades: '',
    accoladesTitle: '',
    brightCoveVideoUrl: '',
    photo: '',
    largePhoto: '',
    logo: '',
    teamMembers: [],
    videos: [
        initializeVideoData(entityTypes.salesTeam.key, videoTypes.salesTeamVideo.key, 1),
    ],
    salesTeamAssistants: [],
    dashId: '',
    teamDomainInfo: undefined,
    testimonials: [],
};

export const initialDescriptionData: SalesTeamDescription = {
    ...descriptionDetails,
    officeId: '',
    isValid: false,
};

export const editFormComponent = {
    description: 'Description',
    largePhoto: 'LargePhoto',
    members: 'Members',
    video: 'Video',
    assistants: 'Assistants',
    testimonials: 'SalesTeamTestimonials',
};

export const editFormMap = new Map([
    [
        editFormComponent.description,
        {
            component: SalesAgentDetails,
            title: 'Description',
        },
    ],
    [
        editFormComponent.largePhoto,
        {
            component: PhotoLogo,
            title: 'Photo',
        },
    ],
    [
        editFormComponent.members,
        {
            component: Members,
            title: 'Members',
        },
    ],
    [
        editFormComponent.video,
        {
            component: Video,
            title: 'Video',
        },
    ],
    [
        editFormComponent.assistants,
        {
            component: Assistants,
            title: 'Assistants',
        },
    ],
    [
        editFormComponent.testimonials,
        {
            component: SalesTeamTestimonials,
            title: 'Testimonials',
        },
    ],
]);

export const phoneDisplayOptions = [
    { id: '1', name: 'Primary Agent' },
    { id: '2', name: 'Both Agents' },
    { id: '3', name: 'Custom' },
];

export const salesTeamImageTypes = {
    largePhoto: 'salesteam-large',
};

export const minPhotoDimension = {
    width: 840,
    height: 1120,
};
