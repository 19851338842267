import { postCall, putCallRaw } from '../../../utils/api/apiWrapper';
import {
    UserNotificationDetails,
    UserNotificationSearchCriteria,
} from './userNotificationModels';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';

/**
 * Gets all of a user's notifications based on supplied criteria
 * @param criteria The criteria used to filter the notifications
 * @returns List of user's notifications that meet the supplied
 * criteria
 */
export function getNotifications(
    criteria: UserNotificationSearchCriteria,
): Promise<PaginationResponse<UserNotificationDetails>> {
    return postCall<
        UserNotificationSearchCriteria,
        PaginationResponse<UserNotificationDetails>
    >('user/notifications', criteria);
}

/**
 * Updates a notification's dismissed flag to true
 * @param ids The IDs (in Mongo) of the notifications to dismiss
 */
export function dismissNotifications(ids: string[]): Promise<Response> {
    return putCallRaw(`user/notification/dismiss`, { ids: ids });
}
