import React, { FunctionComponent, useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { LookupInfo } from '../../models/lookups/lookupsModels';
import { SearchedListingListModel } from '../../models/listing/commonModel';
import { currencyFormat } from '../../../utils/urlUtils';

interface MultiSelectWithSearchAutoCompleteListingProps<T> {
    results: T[];
    reset: number;
    handleSelection: (selectedOptions: LookupInfo[]) => void;
    blurEvent(): void;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    from?: string;
    isLoading?: boolean;
    customWidth?: boolean;
    resetValue?: boolean;
}

const MultiSelectWithSearchAutoCompleteListing: FunctionComponent<
    MultiSelectWithSearchAutoCompleteListingProps<SearchedListingListModel>
> = ({
    results,
    reset,
    handleSelection,
    blurEvent,
    searchTerm,
    setSearchTerm,
    from = 'user',
    isLoading,
    customWidth,
    resetValue,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [selectedOptions, setSelectedOptions] = useState<LookupInfo[]>([]);
    const [options, setOptions] = useState<LookupInfo[]>([]);
    const handleSelectedOptions = (options: LookupInfo[]) => {
        setSelectedOptions(options);
        handleSelection(options);
    };

    useEffect(() => {
        if (resetValue) {
            setSelectedOptions([]);
        }
    }, [resetValue]);

    useEffect(() => {
        if (from === 'listing') {
            const formattedResults = results.map((option) => {
                const add1 = option.address1 ? option.address1 : '';
                const add2 = option.address2 ? ', ' + option.address2 : '';
                const stat = option.status ? ' - ' + option.status : '';
                const curr = option.currentPrice
                    ? ' - $' + currencyFormat(option.currentPrice)
                    : '';
                const finalData = add1 + add2 + stat + curr;
                return {
                    id: option.listingId,
                    name: finalData,
                };
            });
            setOptions(formattedResults);
        }
    }, [results, from]);

    const handleBlur = () => {
        if (selectedOptions.length === 0) {
            setOptions([]);
            setSelectedOptions([]);
            handleSelection([]);
        }
    };

    return (
        <Autocomplete
            multiple
            key={reset}
            options={options}
            value={selectedOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => handleSelectedOptions(value)}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#001731' }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                />
            )}
            className={
                customWidth
                    ? 'customMultiSelectAutocomplete fullWidth'
                    : 'customMultiSelectAutocomplete'
            }
            open={searchTerm.length >= 1 ? true : false}
            onBlur={() => {
                blurEvent();
                handleBlur();
            }}
            loadingText="Loading..."
            loading={isLoading}
        />
    );
};

export default MultiSelectWithSearchAutoCompleteListing;
