import { FunctionComponent, useState } from 'react';
import { Button, Menu } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisVertical,
    faChevronRight,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { ListingsResponse, ManageListSub, MainHeading } from '../listingListModels';
import { Link, useHistory } from 'react-router-dom';
import { setBackTo } from '../../../shared/slices/navigation/navigationSlice';
import { useAppDispatch } from '../../../app/hooks';
import { mainHeading, manageList } from '../listingListConstants';
import { verifyListingEdit, validateAccess, getListingTabName } from '../utils';
import { googleAnalyticsAction } from '../../../shared/functions/googleAnalytics/ga';

interface MenuInter {
    details: ListingsResponse;
    designVaultRedirectFn: (id: string) => void;
    from: string;
}

const MenuAction: FunctionComponent<MenuInter> = ({
    details,
    designVaultRedirectFn,
    from,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [toShow, setToShow] = useState<string>('');
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setAction = (toOpen: string) => {
        setToShow(toOpen);
    };

    const handleNavigation = (
        id: string,
        toOpen: string,
        title?: string,
        listingNumber?: string,
    ) => {
        if (toOpen !== '') {
            if (title) {
                googleAnalyticsAction(
                    'Navigation',
                    'Click',
                    `Listing Results - List View: Manage, ${title} clicked, Id: ${listingNumber}, Tab: ${getListingTabName(
                        from,
                    )}`,
                );
            }
            dispatch(setBackTo({ url: '/eng/listings', title: 'Listings' }));
            history.push(`/eng/listing/${id}?edit=${toOpen}`);
        }
    };

    const updatedMainHeading = verifyListingEdit()
        ? mainHeading
        : mainHeading.filter((data) => data.title !== 'Manage');

    return (
        <>
            <span className="actions " onClick={handleClick}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={1}
                className="listActionHolder"
                MenuListProps={{
                    className: 'listActions',
                }}
            >
                {toShow === '' ? (
                    <div>
                        {updatedMainHeading.map((data: MainHeading, index: number) => (
                            <div className="listing-action" key={index}>
                                <span
                                    className="section"
                                    onClick={(e) => setAction(data.actionTitle)}
                                >
                                    {data.title}{' '}
                                    <FontAwesomeIcon
                                        className="delete"
                                        icon={faChevronRight}
                                    />
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )}
                <div>
                    {toShow === 'reports' ? (
                        <div className="listing-details">
                            <div
                                className="section heading"
                                onClick={(e) => setAction('')}
                            >
                                <FontAwesomeIcon
                                    className="leftChe"
                                    icon={faChevronLeft}
                                />
                                Reports{' '}
                            </div>
                            <div className="action-details">
                                <div className="section">
                                    <Button
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Reports, Advertising Summary clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            handleNavigation(
                                                details.listingId,
                                                'propertyMarketing',
                                            );
                                        }}
                                    >
                                        Advertising Summary
                                    </Button>
                                </div>
                                <div className="section">
                                    <Link
                                        target={'_blank'}
                                        to={`/reportbuilder/${details.listingId}`}
                                        onClick={() =>
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Reports, Client Marketing clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            )
                                        }
                                    >
                                        {' '}
                                        Client Marketing
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {toShow === 'marketing' ? (
                        <div className="listing-details">
                            <div
                                className="section heading"
                                onClick={(e) => setAction('')}
                            >
                                <FontAwesomeIcon
                                    className="leftChe"
                                    icon={faChevronLeft}
                                />
                                Marketing{' '}
                            </div>
                            <div className="action-details">
                                <div className="section">
                                    <a
                                        href="https://sso.sothebysrealty.com/SSO/Index?Application=APIPESIR"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() =>
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Marketing, Email Campaigns clicked, Tab: ${getListingTabName(
                                                    from,
                                                )}`,
                                            )
                                        }
                                    >
                                        Email Campaigns
                                    </a>
                                </div>
                            </div>
                            <div className="action-details">
                                <div className="section">
                                    <Button
                                        onClick={() => {
                                            googleAnalyticsAction(
                                                'Navigation',
                                                'Click',
                                                `Listing Results - List View: Marketing, Design Vault Express clicked, Id: ${
                                                    details.listingNumber
                                                }, Tab: ${getListingTabName(from)}`,
                                            );
                                            designVaultRedirectFn(details.listingId);
                                        }}
                                    >
                                        Design Vault Express
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {toShow === 'manage' ? (
                        <div className="listing-details">
                            <div
                                className="section heading"
                                onClick={(e) => setAction('')}
                            >
                                <FontAwesomeIcon
                                    className="leftChe"
                                    icon={faChevronLeft}
                                />
                                {manageList.title}{' '}
                            </div>
                            <div className="action-details">
                                {validateAccess(manageList).map(
                                    (data: ManageListSub, index: number) => (
                                        <div className="section" key={index}>
                                            <Button
                                                onClick={() =>
                                                    handleNavigation(
                                                        details.listingId,
                                                        data.toOpen,
                                                        data.subTitle,
                                                        details.listingNumber,
                                                    )
                                                }
                                            >
                                                {data.subTitle}
                                            </Button>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Menu>
        </>
    );
};
export default MenuAction;
