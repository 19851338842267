import { deleteCall, getCall, postCall, putCallRaw } from '../../../utils/api/apiWrapper';
import {
    OpenHouse,
    Showing,
} from '../../models/openHouseShowings/openHouseShowingsModels';

/**
 * Gets all showings associated with the given listing
 * @param id The ID of the listing to get showings for
 * @returns List of showings associated with the listing
 */
export function getShowingsApi(id: string): Promise<Showing[]> {
    return getCall<Showing[]>(`listing/${id}/showings`);
}
export function postShowingApi(showing: Showing): Promise<Showing> {
    return postCall<Showing, Showing>('Showing', showing);
}
export function putShowingApi(requestData: Showing): Promise<Response> {
    return putCallRaw(`Showing/${requestData.id}`, requestData);
}
export function deleteShowingApi(id: string): Promise<Response> {
    return deleteCall(`Showing/${id}`);
}

/**
 * Gets all open houses associated with the given listing
 * @param id The ID of the listing to get open houses for
 * @returns List of open houses associated with the listing
 */
export function getOpenHousesApi(id: string): Promise<OpenHouse[]> {
    return getCall<OpenHouse[]>(`listing/${id}/openHouse`);
}
export function postOpenHouseApi(openHouse: OpenHouse): Promise<OpenHouse> {
    return postCall<OpenHouse, OpenHouse>('OpenHouse', openHouse);
}
export function putOpenHouseApi(requestData: OpenHouse): Promise<Response> {
    return putCallRaw(`OpenHouse/${requestData.id}`, requestData);
}
export function deleteOpenHouseApi(id: string): Promise<Response> {
    return deleteCall(`OpenHouse/${id}`);
}
