import { ListingAdCopy } from '../../models/listing/adCopyModel';

export const initializeAdCopy = (
    adCopySizeId?: number,
    languageId?: number,
    heading?: string,
): ListingAdCopy => {
    const adCopy = {
        headline: '',
        body: '',
        adCopySizeId: adCopySizeId || 1,
        languageId: languageId || 1,
        entityIsValid: true,
        validationMessages: [],
    };
    return heading ? { ...adCopy, heading: heading } : adCopy;
};
