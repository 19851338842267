export const amenitySections = {
    interior: 'Interior Information',
    room: 'Room Information',
    construction: 'Construction Information',
    parking: 'Parking Information',
    lot: 'Lot Information',
    outdoor: 'Outdoor Information',
    association: 'Association Information',
    green: 'Green Information',
    building: 'Building Amenities',
};

// export const amenityGroups = {
//     interior: 'Interior Features',
//     parking: 'Parking Features',
//     exterior: 'Exterior Features',
// };

export const amenityGroups = {
    interior: {
        heating: 'Heating',
        interiorFeatures: 'Interior Features',
        cooling: 'Cooling',
        fireplace: 'Fireplace Features',
    },
    exterior: {
        viewDescription: 'View Description',
        frontageType: 'Frontage Type',
        exterior: 'Exterior Features',
        energyEfficient: 'Energy Efficient',
        greenBuilding: 'Green Building Verification Type',
        fencing: 'Fencing',
        pool: 'Pool',
        desirableSettings: 'Desirable Settings',
    },
    parking: 'Parking Features',
};
