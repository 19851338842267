import { CommonLookups } from '../../../../../../shared/models/lookups/lookupsModels';
import { DrawerContainerProps } from '../../../userDetailsModels';
import { FC, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { lookups as lookupsSlice } from '../../../../../../shared/slices/lookups/lookupsSlice';
import { officeGroupCreation } from '../../../../../../shared/utils/officePermission/officePermissionUtil';
import { OfficeGroup } from '../../../../../../shared/models/officePermission/officePermissionModels';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { setCurrentUserEditFormData, userDetails } from '../../../userDetailsSlice';
import { editFormComponent } from '../../../userDetailsConstants';
import { findIdFromLookups } from '../../../../../../utils/urlUtils';

import OfficePermission from '../../../../../../shared/component/officePermission/OfficePermission';

const OfficeAccess: FC<DrawerContainerProps> = () => {
    const { common } = useAppSelector(lookupsSlice);
    const { offices, salutations, statuses } = common as CommonLookups;
    const dispatch = useAppDispatch();
    const { details } = useAppSelector(userDetails);

    const [officeIds, setOfficeIds] = useState<string[]>(details.officeIds);
    const [officeGroup, setOfficeGroup] = useState<OfficeGroup[]>([]);

    useEffect(() => {
        const updatedOfficeGroup = officeGroupCreation(offices, true, officeIds);
        setOfficeGroup(updatedOfficeGroup);
    }, [offices, officeIds]);

    const updatedOfficePermissions = (data: {
        updatedValue: OfficeGroup[];
        newOfficeArray: string[];
    }) => {
        setOfficeIds(data.newOfficeArray);
        setOfficeGroup(data.updatedValue);
        dispatch(
            setCurrentUserEditFormData({
                formData: {
                    ...details,
                    officeIds: data.newOfficeArray,
                    salutation: findIdFromLookups(details.salutation, salutations),
                    status: findIdFromLookups(details.status, statuses),
                },
                userEditFormName: editFormComponent.officeAccess,
            }),
        );
    };

    return (
        <Grid container className="user-office-access-container">
            <Grid item container>
                <OfficePermission
                    updatedOfficePermission={updatedOfficePermissions}
                    officeGrp={officeGroup}
                    formDataOfficeIds={officeIds}
                />
            </Grid>
        </Grid>
    );
};

export default OfficeAccess;
