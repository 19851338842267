import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { listingDraftRequest } from './listingDraftConstants';
import {
    ListingDraftInitialKeyState,
    ListingDraftInitialState,
    ListingDraftRequest,
    UpdateListingDraftState,
} from './listingDraftModels';
import { AppThunk, RootState } from '../../../app/store';
import {
    getAgentNamesByIdsApi,
    getOfficeSalesRegionRentalPeriodApi,
} from './listingDraftApi';
import {
    CommonLookups,
    OfficeLookupInfo,
} from '../../../shared/models/lookups/lookupsModels';
import Logger from '../../../utils/logging/logger';
import { getUser } from '../../../shared/auth/authService';

const initializeListingDraft: ListingDraftInitialState | ListingDraftInitialKeyState = {
    draft: listingDraftRequest,
    agents: [],
    rentals: [],
};

export const listingDraftSlice = createSlice({
    name: 'listingDraft',
    initialState: initializeListingDraft,
    reducers: {
        updateListingDraftStateByKey: (
            state,
            action: PayloadAction<UpdateListingDraftState>,
        ) => {
            state[action.payload.key as keyof typeof state] = action.payload.value;
        },
    },
});

export const getAgentNamesByIds =
    (agentIds: string[]): AppThunk =>
    async (dispatch) => {
        const officeIds = getUser().officeIds;
        const response = await getAgentNamesByIdsApi(agentIds);
        try {
            if (response.length > 0) {
                const agentNames = response.map((agent) => ({
                    id: agent.id,
                    name: agent.name + ' - ' + agent.webOffice,
                }));
                dispatch(
                    updateListingDraftStateByKey({ key: 'agents', value: agentNames }),
                );
                dispatch(
                    getOfficeSalesRegionRentalPeriod((officeIds && officeIds[0]) || '', {
                        ...listingDraftRequest,
                        agentIds: agentNames.map((a) => a.id),
                    }),
                );
            }
        } catch (e) {
            Logger.error(
                `Failed to get agent names in listing draft: ${JSON.stringify(e)}`,
            );
        }
    };

export const getOfficeSalesRegionRentalPeriod =
    (officeId: string, draft: ListingDraftRequest): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (officeId !== '') {
                const { offices } = getState().lookups.common as CommonLookups;
                const { regionId, salesRegionId } = offices.find(
                    (o) => o.id === officeId,
                ) as OfficeLookupInfo;
                const response = await getOfficeSalesRegionRentalPeriodApi(officeId);
                if (response.length > 0) {
                    dispatch(
                        updateListingDraftStateByKey({ key: 'rentals', value: response }),
                    );
                    dispatch(
                        updateListingDraftStateByKey({
                            key: 'draft',
                            value: {
                                ...draft,
                                officeId: officeId,
                                regionId: regionId,
                                salesRegionId: salesRegionId,
                            },
                        }),
                    );
                }
            }
        } catch (e) {
            Logger.error(`Failed to get listing rentals: ${JSON.stringify(e)}`);
        }
    };

export const { updateListingDraftStateByKey } = listingDraftSlice.actions;

export const listingDraft = (
    state: RootState,
): ListingDraftInitialState | ListingDraftInitialKeyState => state.listingDraft;

export default listingDraftSlice.reducer;
