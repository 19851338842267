import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import loDash from 'lodash';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, displayReportToggle, deleteData } from '../../../cmrSlice';
import { getHeaderDetails, hideDisplayOnReport } from '../util';
import { SectionProps } from '../../../cmrModels';
import { total, rate, currencyFormat } from '../../../../../utils/urlUtils';
import './index.scss';
import EmailCampaignList from './components/EmailCampaignList';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import { EmailCampaignData } from '../../../../../shared/models/marketing/marketingModels';
import CustomEmailCampaign from '../../../../../shared/component/marketing/emailCampaign/CustomEmailCampaign';

const EmailCampaigns: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const listingId = listingdata.id;

    const emailCampaignData = paneldata.emailCampaigns as EmailCampaignData[];
    const dispatch = useAppDispatch();

    const sortEmailData = (data: EmailCampaignData[]): EmailCampaignData[] => {
        // TODO: Implement sortByDate DESC
        return loDash.sortBy(data, 'date').reverse();
    };

    const [editId, setEditId] = useState('');
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const [sortedEmails, setSortedEmails] = useState(sortEmailData(emailCampaignData));

    useEffect(() => {
        setSortedEmails(sortEmailData(emailCampaignData));
    }, [emailCampaignData]);

    const onEditClick = (marketingId: string) => {
        googleAnalyticsAction('Edit', 'Click', 'Email Campaign Edit');
        setEditId(marketingId);
    };

    const onCancelClick = () => {
        setEditId('');
    };

    const deleteIt = (id: string) => {
        googleAnalyticsAction('Delete', 'Click', 'Email Campaign Delete');
        const url = `listing/${listingId}/marketing/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'marketingId'));
    };

    return (
        <div className="email-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Email Campaign Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
                hideDisplay={
                    hideDisplayOnReport(9, sortOrder, props.sectionId) ? true : false
                }
            />
            <div className="inner-container">
                <CustomEmailCampaign
                    formEdit={false}
                    cancelClick={onCancelClick}
                    listingId={listingId}
                    sectionId={props.sectionId}
                />
                <div className="stats-container">
                    <div className="emails-sent stats-value">
                        <div className="text-value">
                            {currencyFormat(total(sortedEmails, 'emailsSent'))}
                        </div>
                        <span className="stats-text">emails sent</span>
                    </div>
                    <div className="total-views stats-value">
                        <div className="text-value">
                            {currencyFormat(total(sortedEmails, 'views'))}
                        </div>
                        <span className="stats-text">total views</span>
                    </div>
                    <div className="total-clicks stats-value">
                        <div className="text-value">
                            {currencyFormat(total(sortedEmails, 'clicks'))}
                        </div>
                        <span className="stats-text">total clicks</span>
                    </div>
                    {rate(
                        total(sortedEmails, 'views'),
                        total(sortedEmails, 'emailsSent'),
                    ) &&
                    isFinite(
                        rate(
                            total(sortedEmails, 'views'),
                            total(sortedEmails, 'emailsSent'),
                        ),
                    ) ? (
                        <div className="view-rate stats-value">
                            {rate(
                                total(sortedEmails, 'views'),
                                total(sortedEmails, 'emailsSent'),
                            ).toFixed(2)}
                            %<span className="stats-text">view rate</span>
                        </div>
                    ) : (
                        <div className="view-rate stats-value">
                            %<span className="stats-text">view rate</span>
                        </div>
                    )}
                    {rate(
                        total(sortedEmails, 'clicks'),
                        total(sortedEmails, 'emailsSent'),
                    ) &&
                    isFinite(
                        rate(
                            total(sortedEmails, 'clicks'),
                            total(sortedEmails, 'emailsSent'),
                        ),
                    ) ? (
                        <div className="interaction-rate stats-value">
                            {rate(
                                total(sortedEmails, 'clicks'),
                                total(sortedEmails, 'emailsSent'),
                            ).toFixed(2)}
                            %<span className="stats-text">interaction rate</span>
                        </div>
                    ) : (
                        <div className="interaction-rate stats-value">
                            %<span className="stats-text">interaction rate</span>
                        </div>
                    )}
                </div>
                <div className="table-heading">
                    <div className="date heading-text large">date</div>
                    <div className="name heading-text large">name</div>
                    <div className="emails-sent heading-text small">emails sent</div>
                    <div className="views heading-text small">views</div>
                    <div className="clicks heading-text small">clicks</div>
                    <div className="audience heading-text small">audience</div>
                    <div className="empty heading-text small"></div>
                </div>
                <div className="inner-data-container">
                    {sortedEmails && sortedEmails.length > 0
                        ? sortedEmails.map((email: EmailCampaignData) => (
                              <Fragment key={'email' + email.id}>
                                  {editId === email.marketingId ? (
                                      <CustomEmailCampaign
                                          emailCampaign={email}
                                          formEdit={true}
                                          cancelClick={onCancelClick}
                                          listingId={listingId}
                                          sectionId={props.sectionId}
                                      />
                                  ) : (
                                      <EmailCampaignList
                                          email={email}
                                          onEditClick={onEditClick}
                                          deleteIt={deleteIt}
                                      />
                                  )}
                              </Fragment>
                          ))
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default EmailCampaigns;
