import { FunctionComponent, useState, useEffect } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { ToggleButtonStyle } from './toggleButtonStyle';

interface ToggleButtonProps {
    isActive: boolean;
    data: string | null;
    action(data: string): void;
}

const ToggleButton: FunctionComponent<ToggleButtonProps> = (props) => {
    const [isActive, setActive] = useState(props.isActive);
    const classes = ToggleButtonStyle();
    useEffect(() => {
        setActive(props.isActive);
    }, [props]);
    const clickHandler = () => {
        setActive(!isActive);
        if (props.data) {
            props.action(props.data);
        }
    };
    return (
        <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="small outlined button group"
        >
            <Button
                className={
                    isActive ? classes.selectSaleActive : classes.selectSaleInactive
                }
                onClick={clickHandler}
            >
                Yes
            </Button>
            <Button
                className={
                    !isActive ? classes.selectSaleActive : classes.selectSaleInactive
                }
                onClick={clickHandler}
            >
                No
            </Button>
        </ButtonGroup>
    );
};

export default ToggleButton;
