import { MlsApiUrl } from './mlsDetailsModels';

export const flexOfficeMap = {
    westlake: 42,
    falmouth: 84,
    sandwich: 52,
    santaYnez: 62,
    osterville: 40,
    montecitoCoast: 11,
    montecito1290Coast: 159,
    palmBeach: 7,
    montecitoEastValley: 63,
    greenwich: 6,
};

const getMlsNameForDataSource = (dataSource: string): string => {
    switch (dataSource) {
        case 'Houston':
            return 'houstonmls';
        case 'LA CLAW':
            return 'losangelesmls';
        case 'Flex MLS':
            return 'flexmls';
        case 'Santa Fe MLS':
            return 'santafemls';
        case 'Monterey MLS':
            return 'montereymls';
        case 'San Francisco MLS':
            return 'sanfranciscomls';
        case 'Sonoma':
            return 'sonomamls';
        case 'Perchwell':
            return 'perchwell';
        default:
            return '';
    }
};

const getMlsNameForOffice = (officeIdentifier: number): string => {
    switch (officeIdentifier) {
        case 144:
        case 156:
        case 157:
            return 'beachesmls';
        case 3:
        case 4:
        case 5:
        case 105:
            return 'hamptonsmls';
        case 123:
        case 126:
        case 125:
        case 127:
        case 124:
        case 140:
            return 'houstonmls';
        case 30:
        case 27:
        case 34:
        case 35:
        case 42:
        case 2:
        case 28:
        case 129:
        case 131:
        case 135:
        case 158:
            return 'losangelesmls';
        case 121:
        case 47:
        case 50:
        case 155:
            return 'montereymls';
        case 8:
        case 142:
        case 146:
            return 'sanfranciscomls';
        case 24:
        case 119:
        case 120:
        case 148:
            return 'sonomamls';
        case flexOfficeMap.osterville:
        case flexOfficeMap.falmouth:
        case flexOfficeMap.sandwich:
        case flexOfficeMap.montecitoCoast:
        case flexOfficeMap.montecitoEastValley:
        case flexOfficeMap.santaYnez:
        case flexOfficeMap.greenwich:
        case flexOfficeMap.westlake:
        case flexOfficeMap.palmBeach:
        case flexOfficeMap.montecito1290Coast:
            return 'flexmls';
        case 1:
        case 13:
            return 'perchwell';
        case 56:
        case 57:
            return 'santafemls';
        default:
            return '';
    }
};

const generateFlexRegionParam = (officeIdentifier: number | null): string | undefined => {
    switch (officeIdentifier) {
        case flexOfficeMap.greenwich:
            return '?region=Greenwich';
        case flexOfficeMap.osterville:
        case flexOfficeMap.falmouth:
        case flexOfficeMap.sandwich:
            return '?region=CapeCod';
        case flexOfficeMap.montecitoCoast:
        case flexOfficeMap.montecitoEastValley:
        case flexOfficeMap.santaYnez:
            return '?region=SantaBarbara';
        case flexOfficeMap.westlake:
            return '?region=Ventura';
        case flexOfficeMap.palmBeach:
        case flexOfficeMap.montecito1290Coast:
            return '?region=PalmBeach';
        default:
            return undefined;
    }
};

/**
 * Generates the url details for the appropriate MLS for a listing, based on its office
 * @param listingId The MLS number of the listing to get the MLS url for
 * @param officeIdentifier The integer ID of the office to get the MLS url for
 * @param dataSource The name of the original data source for the listing
 */
export const getMlsApiUrlDetails = (
    listingId: string | null,
    officeIdentifier: number | null,
    dataSource: string | null,
): MlsApiUrl => {
    const emptyUrl: MlsApiUrl = { url: '' };
    if (!officeIdentifier && !dataSource) return emptyUrl;

    const apiUrlBase = process.env.REACT_APP_APIS_URL;
    let mlsName = '';
    if (dataSource) mlsName = getMlsNameForDataSource(dataSource);
    if (mlsName === '' && officeIdentifier)
        mlsName = getMlsNameForOffice(officeIdentifier);
    if (mlsName !== '') {
        let querystring = undefined;
        if (mlsName === 'flexmls') {
            querystring = generateFlexRegionParam(officeIdentifier);
        }
        const controller = mlsName === 'perchwell' ? 'listing' : 'listings';
        return {
            url: `${apiUrlBase}/${mlsName}/${controller}/${listingId}`,
            additionalParams: querystring,
        };
    }
    return emptyUrl;
};

/**
 * Generates the save url to the appropriate MLS for a listing
 * @param listingId The MLS number of the listing to get the MLS url for
 * @param officeIdentifier The integer ID of the office to get the MLS url for
 * @param dataSource The name of the original data source for the listing
 */
export const getMlsApiSaveUrl = (
    listingId: string | null,
    officeIdentifier: number | null,
    dataSource: string | null,
): string => {
    const urlDetails = getMlsApiUrlDetails(listingId, officeIdentifier, dataSource);
    if (urlDetails.url === '') return '';

    let importUrl = `${urlDetails.url}/save`;
    if (urlDetails.additionalParams) importUrl += urlDetails.additionalParams;
    return importUrl;
};
