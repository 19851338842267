import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import Logger from '../../../utils/logging/logger';
import { setSuccess, setError } from '../../../shared/slices/messaging/messagingSlice';
import {
    getNotificationAssignmentDetails,
    getNotifications,
    saveNotificationAssignments,
} from './manageNotificationsApi';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import {
    initialNotificationDetails,
    ManageNotificationsState,
    NotificationAssignment,
    NotificationAssignmentDetails,
} from './manageNotificationsModels';
import {
    updateAssignments,
    updateNotificationAssignmentFlag,
} from '../notificationUtils';

const initialState: ManageNotificationsState = {
    loading: false,
    notifications: [],
    notificationDetails: initialNotificationDetails,
};

export const manageNotificationsSlice = createSlice({
    name: 'manageNotifications',
    initialState,
    reducers: {
        /** Loads notifcations to state for table list */
        updateNotifications: (state, action: PayloadAction<LookupInfo[]>) => {
            state.notifications = action.payload;
        },
        /** Sets status of loader flag */
        setLoader(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        /**
         * Sets the current notification details with its
         * assignments to the state
         */
        setNotificationDetails(
            state,
            action: PayloadAction<NotificationAssignmentDetails>,
        ) {
            state.notificationDetails = action.payload;
            state.staticNotificationDetails = action.payload;
        },
        /** Updates assignments list on the state */
        updateAssignment(
            state,
            action: PayloadAction<{
                assignment: NotificationAssignment;
                added: boolean;
                key: keyof NotificationAssignment;
            }>,
        ) {
            const { assignment, added, key } = action.payload;
            const notificationDetails =
                state.notificationDetails || initialNotificationDetails;
            const assignments = updateAssignments(
                notificationDetails.assignments,
                assignment,
                added,
                key,
            );

            notificationDetails.assignments = assignments;
            state.notificationDetails = notificationDetails;
        },
        /** Updates the email flag on all current assignments */
        updateFlag(
            state,
            action: PayloadAction<{
                flagValue: boolean;
                idKey: keyof NotificationAssignment;
                id: string;
                flagKey: keyof NotificationAssignment;
            }>,
        ) {
            const { flagValue, idKey, id, flagKey } = action.payload;
            const notificationDetails =
                state.notificationDetails || initialNotificationDetails;
            state.notificationDetails = updateNotificationAssignmentFlag(
                notificationDetails,
                flagValue,
                idKey,
                id,
                flagKey,
            );
        },
    },
});

/** Gets all notifications in the system */
export const fetchNotifications = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoader(true));
        const notifications = await getNotifications();
        dispatch(updateNotifications(notifications));
    } catch (e) {
        Logger.error('Failed to get notifications', e);
        dispatch(setError('Failed to get notifications'));
    } finally {
        dispatch(setLoader(false));
    }
};

/**
 * Gets all assignments associated with a notification
 * @param notificationId The ID of the notification to get assignments for
 */
export const fetchNotificationAssignments =
    (notification: LookupInfo): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const notificationId = parseInt(notification.id);
            const notificationAssignments = await getNotificationAssignmentDetails(
                parseInt(notification.id),
            );
            const details: NotificationAssignmentDetails = {
                assignments: notificationAssignments,
                notificationId: notificationId,
                notificationName: notification.name,
            };
            dispatch(setNotificationDetails(details));
        } catch (e) {
            Logger.error('Failed to get notification assignments', e);
            dispatch(setError('Failed to get notification assignments'));
        } finally {
            dispatch(setLoader(false));
        }
    };

/**
 * Saves new assignments for the notification
 * @param details The list of assignments to persist
 */
export const updateNotificationAssignments =
    (details: NotificationAssignmentDetails): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader(true));
            await saveNotificationAssignments(
                details.notificationId,
                details.assignments,
            );
            dispatch(setSuccess('Notification assignments updated successfully'));
        } catch (e) {
            Logger.error('Failed to save notification assignments', e);
            dispatch(setError('Failed to save notification assignments'));
        } finally {
            dispatch(setLoader(false));
        }
    };

export const {
    updateNotifications,
    setLoader,
    setNotificationDetails,
    updateAssignment,
    updateFlag,
} = manageNotificationsSlice.actions;

export const manageNotifications = (state: RootState): ManageNotificationsState =>
    state.notifications;

export default manageNotificationsSlice.reducer;
