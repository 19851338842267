import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { findIdFromLookups, validateEmail } from '../../../utils/urlUtils';
import { editFormComponent } from './salesTeamConstants';
import {
    SalesTeamDescription,
    SalesTeamDetail,
    SalesTeamEditScreenRequiredFields,
} from './salesTeamModels';

export const fetchDescFromMainState = (
    data: SalesTeamDetail,
    officeList: LookupInfo[],
): SalesTeamDescription => {
    return {
        id: data.id,
        name: data.name,
        description: data.description,
        office: data.office,
        officeId: findIdFromLookups(data.office, officeList),
        mlsNumber: data.mlsNumber,
        email: data.email,
        websiteUrl: data.websiteUrl,
        linkedInUrl: data.linkedInUrl,
        facebookUrl: data.facebookUrl,
        twitterUrl: data.twitterUrl,
        instagramUrl: data.instagramUrl,
        youtubeUrl: data.youtubeUrl,
        phone: data.phone,
        phoneNumberDisplay: data.phoneNumberDisplay,
        phoneNoDisplayOrder: data.phoneNoDisplayOrder,
        canShareProperties: data.canShareProperties,
        isValid: data.isValid,
        statusId: data.statusId,
        status: data.status,
    };
};

export const generateSalesTeamEditScreens = (): string[] => {
    return [
        editFormComponent.description,
        editFormComponent.largePhoto,
        editFormComponent.members,
        editFormComponent.video,
        editFormComponent.assistants,
        editFormComponent.testimonials,
    ];
};

export const displayErrorForSalesTeamEditScreen = (
    formData: SalesTeamEditScreenRequiredFields,
): {
    isValid: boolean;
    errorMessage: string;
} => {
    let result = {
        isValid: true,
        errorMessage: '',
    };
    let match = 0;

    if (!formData.name)
        result = { isValid: false, errorMessage: result.errorMessage + 'Name,' };
    if (!formData.email)
        result = { isValid: false, errorMessage: result.errorMessage + 'Email,' };
    if (!formData.office)
        result = { isValid: false, errorMessage: result.errorMessage + 'Office,' };
    if (!formData.description || formData.description === '<br>')
        result = { isValid: false, errorMessage: result.errorMessage + 'Description,' };

    if (!result.errorMessage) {
        if (!validateEmail(formData.email))
            result = { isValid: false, errorMessage: 'Email is not valid' };
        else
            result = {
                isValid: true,
                errorMessage: '',
            };
    }

    match = result.errorMessage.match(/,/g)?.length || 0;

    if (match >= 1) {
        result = {
            ...result,
            errorMessage: result.errorMessage.slice(0, -1).replaceAll(',', ', '),
        };
    }

    return {
        ...result,
        errorMessage:
            result.errorMessage +
            (match > 1 ? ' are required' : match === 1 ? ' is required' : ''),
    };
};
