import { FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import 'antd/dist/antd.css';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import { DrawerContainerProps, DatesModel } from '../../../listingModels';
import { dealTypes, editFormComponent, listingStatuses } from '../../../listingConstants';
import { setCurrentFormData } from '../../../listingSlice';
import { useFormData, useAppDispatch } from '../../../../../../app/hooks';
import { setError } from '../../../../../../shared/slices/messaging/messagingSlice';
import { datePickerFormat } from '../../../../../../utils/urlUtils';
import './index.scss';
import { formListingDataInitiaObject } from '../../../listingUtils';
import { shouldDisableField } from '../../../../../../shared/auth/permissions/permissionsService';

const Dates: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const { dataSource, dealType, listingStatusId, priorBrokerSale } = editFormData.data;
    const initialDateData: DatesModel = formListingDataInitiaObject(editFormData.data);
    // closed, contract and cancelled date disabled if dataSource is Trident
    const tridentDisabled = dataSource === 'Trident' ? true : false;
    // mandatory condition for listing and expiration date
    const mandateField =
        dealType === dealTypes.saleSide && !priorBrokerSale ? false : true;
    const { formData, setFormData } = useFormData(initialDateData);
    const [disableDate, setDisableDate] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const handleDateChange = (field: string, value: Moment | null) => {
        const dateVal = value !== null ? value.format('YYYY-MM-DDTHH:mm') : '';
        // Pending status handler:
        if (listingStatusId === listingStatuses.pending) {
            if (field === 'contractDate' && !dateVal.length) {
                dispatch(
                    setError('Error: Status cannot be Pending without a Contract date.'),
                );
            } else if (field === 'canceledDate' && dateVal.length) {
                dispatch(
                    setError('Error: Status cannot be Pending with a Cancelled date.'),
                );
            } else {
                setFormData({ ...formData, [field]: dateVal });
                setFormDataToPersist({ ...formData, [field]: dateVal });
            }
        }
        // Sold status handler:
        else if (listingStatusId === listingStatuses.sold) {
            if (field === 'closedDate' && !dateVal.length) {
                dispatch(setError('Error: Status cannot be Sold without a Closed date.'));
            } else if (field === 'canceledDate' && dateVal.length) {
                dispatch(setError('Error: Status cannot be Sold with a Cancelled date.'));
            } else {
                setFormData({ ...formData, [field]: dateVal });
                setFormDataToPersist({ ...formData, [field]: dateVal });
            }
        }
        // Coming soon status handler:
        else if (listingStatusId === listingStatuses.comingSoon) {
            if (field === 'projectedListDate' && !dateVal.length) {
                dispatch(
                    setError(
                        'Error: Status cannot be Coming soon without a Projected list date.',
                    ),
                );
            } else {
                setFormData({ ...formData, [field]: dateVal });
                setFormDataToPersist({ ...formData, [field]: dateVal });
            }
        } else {
            setFormData({ ...formData, [field]: dateVal });
            setFormDataToPersist({ ...formData, [field]: dateVal });
        }
    };
    useEffect(() => {
        if (
            listingStatusId !== listingStatuses.pending &&
            listingStatusId !== listingStatuses.sold &&
            formData.canceledDate?.length &&
            (formData.contractDate?.length || formData.closedDate?.length)
        ) {
            dispatch(
                setError(
                    'Error: Cancel date cannot be set if Contract or Closed dates are set.',
                ),
            );
            setDisableDate(true); //disabled both contract and closed date if cancelled date is set
        } else {
            setDisableDate(false);
        }
    }, [formData.canceledDate]);
    useEffect(() => {
        if (!formData.contractDate?.length && formData.closedDate?.length) {
            dispatch(
                setError('Error: Closed date cannot be set without a Contract date.'),
            );
            setFormDataToPersist({ ...formData });
        }
    }, [formData.contractDate, formData.closedDate]);

    const setFormDataToPersist = (currentFormData: DatesModel) => {
        let updatedFormData = { ...currentFormData, isFormValid: true };
        if (mandateField) {
            updatedFormData = {
                ...updatedFormData,
                isFormValid:
                    updatedFormData.listingDate?.length &&
                    updatedFormData.expirationDate?.length
                        ? true
                        : false,
            };
        }
        // Projected list date mandatory if status is 'Coming soon'
        if (
            listingStatusId === listingStatuses.comingSoon &&
            !updatedFormData.projectedListDate?.length
        ) {
            updatedFormData = { ...updatedFormData, isFormValid: false };
        }
        // Contract date mandatory for closed date
        if (!updatedFormData.contractDate?.length && updatedFormData.closedDate?.length) {
            updatedFormData = { ...updatedFormData, isFormValid: false };
        }
        // For status Pending/sold cancelled date handled in handleDateChange
        if (
            listingStatusId !== listingStatuses.pending &&
            listingStatusId !== listingStatuses.sold &&
            updatedFormData.canceledDate?.length &&
            (updatedFormData.contractDate?.length || updatedFormData.closedDate?.length)
        ) {
            updatedFormData = { ...updatedFormData, isFormValid: false };
        }
        dispatch(
            setCurrentFormData({
                formData: updatedFormData,
                listingEditFormName: editFormComponent.dates,
            }),
        );
    };

    const renderDateRow = (label: string, property: string, disabled?: boolean) => {
        return (
            <FormRow
                label={label}
                control={
                    <DatePicker
                        value={
                            formData[property as keyof typeof formData] !== ''
                                ? moment(
                                      formData[
                                          property as keyof typeof formData
                                      ] as string,
                                  )
                                : undefined
                        }
                        format={datePickerFormat()}
                        onChange={(date: Moment | null) => {
                            handleDateChange(property, date);
                        }}
                        name={property}
                        showToday={false}
                        className={
                            disabled
                                ? 'datePickerCommon inputDisabled'
                                : 'datePickerCommon'
                        }
                        style={{
                            padding: '5px 10px',
                        }}
                        disabled={disabled ? disabled : false}
                    />
                }
                prefix=""
                suffix=""
                controlClass="dateSelect formControlRoot"
                labelXs={null}
                labelSm={2}
                labelMd={2}
                controlXs={null}
                controlSm={4}
                controlMd={4}
            />
        );
    };

    return (
        <div className="datesContainer">
            <Grid item container md={12} className="info-text">
                * Indicates Required Fields
            </Grid>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderDateRow(
                        mandateField ? 'LISTING DATE*' : 'LISTING DATE',
                        'listingDate',
                    )}
                    {renderDateRow(
                        mandateField ? 'EXPIRATION DATE*' : 'EXPIRATION DATE',
                        'expirationDate',
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderDateRow(
                        'CONTRACT DATE',
                        'contractDate',
                        tridentDisabled ? tridentDisabled : disableDate,
                    )}
                    {renderDateRow('CANCEL DATE', 'canceledDate', tridentDisabled)}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderDateRow(
                        'CLOSED DATE',
                        'closedDate',
                        tridentDisabled ? tridentDisabled : disableDate,
                    )}
                </Grid>
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderDateRow(
                        'FIRST PUBLISHED',
                        'publishedDate',
                        shouldDisableField(),
                    )}
                    {renderDateRow(
                        'LATEST RE-PUBLISH',
                        'lastPublishedDate',
                        shouldDisableField(),
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderDateRow('SHOW NEW LISTING BANNER UNTIL', 'newListingUntil')}
                    {renderDateRow(
                        listingStatusId === listingStatuses.comingSoon
                            ? 'PROJECTED LIST DATE*'
                            : 'PROJECTED LIST DATE',
                        'projectedListDate',
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default Dates;
