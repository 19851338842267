import './footer.scss';
import { useOktaAuth } from '@okta/okta-react';
import { FC } from 'react';

const Footer: FC = () => {
    const { authState } = useOktaAuth();
    const getCurrentYear = () => {
        return new Date().getUTCFullYear();
    };
    return authState?.isAuthenticated ? (
        <div className="footerHolder">
            <div className="footer">
                <span className="textHolder">
                    © {getCurrentYear()} Sotheby’s International Realty. All Rights
                    Reserved. Sotheby’s International Realty® is a registered trademark
                    and used with permission. Each Sotheby’s International Realty office
                    is independently owned and operated, except those operated by
                    Sotheby’s International Realty, Inc. This material is based upon
                    information which we consider reliable but because it has been
                    supplied by third parties, we cannot represent that it is accurate or
                    complete and it should not be relied upon as such. If your property is
                    listed with a real estate broker, please disregard. It is not our
                    intention to solicit the offerings of other real estate brokers. We
                    are happy to work with them and cooperate fully. Equal Housing
                    Opportunity.
                </span>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Footer;
