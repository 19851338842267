import React, { FunctionComponent, CSSProperties } from 'react';
import Modal from '@material-ui/core/Modal';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './index.scss';

interface CustomModalProps {
    width?: string;
    title?: string;
    show: boolean;
    onClose(status: boolean): void;
    children: React.ReactNode;
    customBoxStyle?: CSSProperties | undefined;
    customModalStyle?: CSSProperties | undefined;
}

const CustomModal: FunctionComponent<CustomModalProps> = ({
    width,
    title,
    show,
    onClose,
    children,
    customBoxStyle,
    customModalStyle,
}) => {
    return (
        <Modal
            className={`modalStyle ${
                width?.length && width === 'max' ? 'full-width' : 'mid-width'
            }`}
            open={show}
            onClose={() => onClose(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div
                className="boxStyle"
                style={
                    customBoxStyle
                        ? customBoxStyle
                        : { width: '102%', backgroundColor: '#FFFFFF' }
                }
            >
                <div className="modalHeader">
                    {title ? <div className="modalTitle">{title}</div> : ''}
                    <IconButton onClick={() => onClose(false)} className="closeButtonDiv">
                        <CloseIcon className="closeButton" />
                    </IconButton>
                </div>
                <div
                    className="modalContent"
                    style={customModalStyle ? customModalStyle : {}}
                >
                    {children}
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
