import { FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../developmentDetailsModel';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../../../app/hooks';
import './developmentFeaturesEdit.scss';
import { ListingAmenity } from '../../../../../../shared/models/listing/amenityModels';
import { getAmenitiesForGroup } from '../../../../../../shared/utils/listing/featureUtils';
import { amenitySections } from '../../../../../../shared/constants/listing/amenities';
import {
    fetchAmenities,
    listingFeaturesSliceState,
} from '../../../../../listings/listing/drawer/screens/listingFeatures/listingFeaturesSlice';
import {
    isFeatureUpdated,
    setCurrentDevelopmentEditFormData,
    updateMainDevelopmentData,
} from '../../../developmentDetailsSlice';
import { editFormComponent } from '../../../developmentDetailsConstants';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import MemoizedFeatureChips from '../../../../../listings/listing/drawer/screens/listingFeatures/components/shared/FeatureChips';
import Features from '../../../../createDevelopment/create/Components/Features';

const DevelopmentFeaturesEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const { formData, handleNumberChange, handleRadioButtonChange, handleFloatChange } =
        useFormData(editFormData.data);
    //gets data from listing amenities
    const { amenities } = useAppSelector(listingFeaturesSliceState);
    const [selectedFeatures, setSelectedFeatures] = useState<ListingAmenity[]>([]);

    /**fetch amenities if data is not present */
    useEffect(() => {
        if (!amenities.length) {
            dispatch(fetchAmenities());
        }
    }, [dispatch, amenities]);

    useEffect(() => {
        if (editFormData.data.amenities.length) {
            setSelectedFeatures(editFormData.data.amenities);
        }
    }, [editFormData]);

    const buildingAmenities = getAmenitiesForGroup(
        selectedFeatures,
        amenities.filter((a) => a.sectionName === amenitySections.building),
        false,
    );

    /** function to handle amentities selection */
    const handleAddAndDelete = (amenities: ListingAmenity[], isAdded: boolean) => {
        if (isAdded) {
            setSelectedFeatures(selectedFeatures.concat(amenities));
            dispatch(
                updateMainDevelopmentData({
                    amenities: selectedFeatures.concat(amenities),
                }),
            );
        } else {
            const id = amenities.map((a) => a.amenityId);
            const removedFeatures = selectedFeatures.filter(
                (a) => id.indexOf(a.amenityId) < 0,
            );
            setSelectedFeatures(removedFeatures);
            dispatch(updateMainDevelopmentData({ amenities: removedFeatures }));
        }
        dispatch(isFeatureUpdated(true));
        dispatch(
            setCurrentDevelopmentEditFormData({
                formData,
                developmentEditFormName: editFormComponent.developmentFeatures,
            }),
        );
    };

    const setFormDataToPersistInState = () => {
        dispatch(
            setCurrentDevelopmentEditFormData({
                formData,
                developmentEditFormName: editFormComponent.developmentFeatures,
            }),
        );
    };
    return (
        <Grid container>
            <ComponentWithLoader showLoader={false} showOverlay={true}>
                <Grid container spacing={2} className="developmentFeaturesEdit">
                    <Grid item container className="section" md={12} spacing={2}>
                        <Features
                            formData={formData}
                            handleRadioButtonChange={handleRadioButtonChange}
                            handleNumberChange={handleNumberChange}
                            setFormDataToPersistInState={setFormDataToPersistInState}
                            handleFloatChange={handleFloatChange}
                        />
                    </Grid>
                    <>
                        <Grid item xs={12} md={12} className="headline">
                            Building Amenities
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <MemoizedFeatureChips
                                chips={buildingAmenities}
                                listingId=""
                                handleAddAndDelete={handleAddAndDelete}
                            />
                        </Grid>
                    </>
                </Grid>
            </ComponentWithLoader>
        </Grid>
    );
};

export default DevelopmentFeaturesEdit;
