import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { postCall } from '../../utils/api/apiWrapper';
import {
    AdvertisementListModel,
    AdvertisementSearchRequest,
} from './advertisementHolder/advertisementModels';

export function getAdvertisementListApi(
    advertisementSearchRequest: AdvertisementSearchRequest,
): Promise<PaginationResponse<AdvertisementListModel>> {
    return postCall<
        AdvertisementSearchRequest,
        PaginationResponse<AdvertisementListModel>
    >(`advertisement/search`, advertisementSearchRequest);
}
