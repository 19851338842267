import { Chip, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import {
    OfficeChips,
    OfficeGroup,
} from '../../models/officePermission/officePermissionModels';
import './officePermission.scss';
interface Permission {
    officeGrp: OfficeGroup[];
    formDataOfficeIds: string[];
    updatedOfficePermission(data: {
        updatedValue: OfficeGroup[];
        newOfficeArray: string[];
    }): void;
}

const OfficePermission: FunctionComponent<Permission> = ({
    officeGrp,
    formDataOfficeIds,
    updatedOfficePermission,
}) => {
    // to update the selected data for the chipset based on the selection
    // status is mandate, based on the office and status we are determining the individaul chip selection
    // based on region name alone whole sales region selection is determined, when not region name is available
    // it is determined as parent select all.
    const actionHandler = (status: boolean, regionName?: string, officeId?: string) => {
        let officeArray: string[] = formDataOfficeIds || [];
        if (regionName !== '') {
            const regionIndex = officeGrp.findIndex((reg) => reg.region === regionName);
            //for individual select
            if (officeId) {
                const officeIndex = officeGrp[regionIndex].officeList.findIndex(
                    (off) => off.id === officeId,
                );
                officeGrp[regionIndex].officeList[officeIndex].selected = status;
                //officeArray is passed thus we can maintain the office based on add/delete process
                officeArray = [...updateOffArray(officeId, status, officeArray)];
            } else {
                //for region level select
                officeGrp[regionIndex].officeList.forEach((off) => {
                    off.selected = status;
                    officeArray = [...updateOffArray(off.id, status, officeArray)];
                });
            }
        } else {
            //for parent select
            officeGrp.forEach((reg) =>
                reg.officeList.forEach((off) => {
                    off.selected = status;
                    officeArray = [...updateOffArray(off.id, status, officeArray)];
                }),
            );
        }
        updatedOfficePermission({
            updatedValue: [...officeGrp],
            newOfficeArray: officeArray,
        });
    };

    // this function is used to remove and add the office id based on the individual or region or parent
    // level selection
    const updateOffArray = (officeId: string, status: boolean, officeArray: string[]) => {
        let data: string[] = [...officeArray];
        if (status) {
            data.push(officeId);
        } else {
            data = data.filter((list) => list !== officeId);
        }
        return data;
    };

    const scroll = (id: string) => {
        const elem = document.getElementById(id);
        if (elem !== null) {
            elem.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };
    return (
        <>
            <Grid
                container
                item
                xs={12}
                className="officeFullSelection"
                justifyContent="space-between"
            >
                <span>Selected Offices: </span>
                <span className="links-container">
                    <span className="link">
                        <button onClick={() => actionHandler(true, '', '')}>
                            Select All
                        </button>
                    </span>
                    <span className="link" onClick={() => actionHandler(false, '', '')}>
                        <button>Clear All</button>
                    </span>
                </span>
            </Grid>
            <Grid container className="regionSelection">
                <Grid item md={12} className="info-text">
                    click on the region name to navigate to the corresponding Regions
                    office list
                </Grid>
                {officeGrp && officeGrp.length > 0
                    ? officeGrp.map((reg: OfficeGroup, index: number) =>
                          reg && reg !== null ? (
                              <span
                                  key={index}
                                  className="region"
                                  onClick={() => scroll(`${reg.region}-${index}`)}
                              >
                                  {reg.region}
                              </span>
                          ) : (
                              ''
                          ),
                      )
                    : ''}
            </Grid>
            <Grid container item xs={12} className="officeList">
                {officeGrp && officeGrp.length > 0
                    ? officeGrp.map((reg: OfficeGroup, index: number) =>
                          reg && reg !== null ? (
                              <Grid
                                  container
                                  item
                                  xs={12}
                                  key={index}
                                  className="officeChips"
                              >
                                  <Grid
                                      container
                                      item
                                      className="officeFullSelection region"
                                      justifyContent="space-between"
                                      id={`${reg.region}-${index}`}
                                  >
                                      <span>{reg.region} </span>
                                      <span className="links-container">
                                          <span className="link">
                                              <button
                                                  onClick={() =>
                                                      actionHandler(true, reg.region, '')
                                                  }
                                              >
                                                  Select All
                                              </button>
                                          </span>
                                      </span>
                                  </Grid>
                                  <Grid container item>
                                      {reg.officeList.map((chip: OfficeChips) =>
                                          chip.selected ? (
                                              <Chip
                                                  key={chip.id}
                                                  label={chip.name}
                                                  className="chip selected"
                                                  onDelete={() =>
                                                      actionHandler(
                                                          false,
                                                          reg.region,
                                                          chip.id,
                                                      )
                                                  }
                                              />
                                          ) : (
                                              <Chip
                                                  key={chip.id}
                                                  label={chip.name}
                                                  className="chip"
                                                  onClick={() =>
                                                      actionHandler(
                                                          true,
                                                          reg.region,
                                                          chip.id,
                                                      )
                                                  }
                                              />
                                          ),
                                      )}
                                  </Grid>
                              </Grid>
                          ) : (
                              ''
                          ),
                      )
                    : ''}
            </Grid>
        </>
    );
};

export default OfficePermission;
