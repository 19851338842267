import { MlsEmailDetail } from './mlsEmailsModels';
import { getCall, postCall, deleteCall } from '../../utils/api/apiWrapper';

export function getForMls(mls: string): Promise<MlsEmailDetail[]> {
    return getCall<MlsEmailDetail[]>(`mlsemails/${mls}`);
}

export function saveEmail(mlsEmail: MlsEmailDetail): Promise<MlsEmailDetail> {
    return postCall<MlsEmailDetail, MlsEmailDetail>('mlsemails', mlsEmail);
}

export function deleteEmail(id: string): Promise<Response> {
    return deleteCall(`mlsemails/${id}`);
}
