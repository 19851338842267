import {
    postCall,
    putCallRaw,
    getApiCall,
    postCallRaw,
} from '../../../../utils/api/apiWrapper';
import { MarketingReminderOptOutStatusModel } from '../listingModels';
import { MarketingReminder, DashListingDetails } from './activityAndReportingModels';

export function getMarketingReminders(
    id: string,
    userId: string,
): Promise<MarketingReminder[]> {
    return postCall<{ userId: string }, MarketingReminder[]>(
        `listing/${id}/Notification/`,
        { userId: userId },
    );
}
export function saveMarketingReminderOptOut(
    data: MarketingReminderOptOutStatusModel,
): Promise<Response> {
    return putCallRaw(`listing/${data.listingId}/Notification/`, data);
}

export function getDashListingDetails(
    listingNumber: string,
): Promise<DashListingDetails> {
    const dashUrl = process.env.REACT_APP_DASHURL;
    return getApiCall<DashListingDetails>(`${dashUrl}/listing/${listingNumber}`);
}

export function sendListingToDashQueue(listingNumber: string): Promise<Response> {
    return postCallRaw(`listing/${listingNumber}/sendtodash`, null);
}
