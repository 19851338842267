import { FunctionComponent } from 'react';
import LeftPanel from './leftPanel/LeftPanel';
import RightPanel from './rightPanel/RightPanel';
import './index.scss';
import { useAppSelector } from '../../../app/hooks';
import { cmrDetails } from '../cmrSlice';
import SimpleModal from '../modal/modalwindow';
import ComponentWithLoader from '../../../shared/component/loader/ComponentWithLoader';

const Content: FunctionComponent = () => {
    const { panelSpinner, panelSpinnerRight } = useAppSelector(cmrDetails);
    return (
        <div>
            <div className="contentHolder1">
                <SimpleModal />
            </div>
            <div className="contentHolder">
                <div className="leftHolder">
                    <div className="leftOverlay">
                        <ComponentWithLoader
                            showLoader={panelSpinner}
                            showOverlay={true}
                            styleClass="leftLoaderClass"
                        />
                        <LeftPanel />
                    </div>
                </div>
                <div className="rightHolder" id="rightHolder">
                    <div className="rightOverlay">
                        <ComponentWithLoader
                            showLoader={panelSpinnerRight}
                            showOverlay={true}
                            styleClass="rightLoaderClass"
                        />
                        <RightPanel />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
