import { VideoModel } from '../../models/video/videoModels';
import { refQueryParam, videoStatuses } from '../../constants/video/videoConstants';
import { getVideoInfo } from '../../api/video/brightcove/brightcoveApi';

export const initializeVideoData = (
    entityTypeId: number,
    videoTypeId: number,
    displayOrder: number,
    reuploadInterval = 0,
    isCancelled = false,
): VideoModel => {
    return {
        id: '',
        entityId: '',
        entityTypeId: entityTypeId,
        url: '',
        videoId: '',
        videoTypeId: videoTypeId,
        reference: '',
        status: videoStatuses.started,
        caption: '',
        description: '',
        displayOrder: displayOrder,
        reuploadInterval: reuploadInterval,
        isCancelled: isCancelled,
    };
};

export const validateVideoId = async (video: VideoModel): Promise<string> => {
    if (!video.videoId || video.videoId === '') {
        const referenceId = video?.url?.split(refQueryParam)[1];
        if (referenceId) {
            const videoInfoResponse = await getVideoInfo(referenceId);
            return videoInfoResponse[0].id;
        }
    }
    return video.videoId;
};
