import { useState, useEffect, FunctionComponent } from 'react';
import Header from '../../../shared/index';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { cmrDetails, updateNotesSection, displayReportToggle } from '../../../cmrSlice';
import './index.scss';
import { SectionProps, Notes } from '../../../cmrModels';
import { Editor } from '@tinymce/tinymce-react';
import { getHeaderDetails } from '../util';

const NotesComponent: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder } = useAppSelector(cmrDetails);
    const dispatch = useAppDispatch();
    const apiKeyOfTinyMce = process.env.REACT_APP_TINY_MCE;
    const sizeLimit = 3000;
    const notesData = paneldata.notes as Notes;
    const [notes, setNotes] = useState(notesData ? notesData.remark : '');
    useEffect(() => {
        if (notesData && notesData.remark) setNotes(notesData.remark);
    }, [notesData]);

    const handlerFunction = () => {
        if (notesData.remark !== notes) dispatch(updateNotesSection(notesData.id, notes));
    };
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    return (
        <div className="notes-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => dispatch(displayReportToggle(id))}
            />
            <div className="inner-container">
                <div className="data-container">
                    <Editor
                        apiKey={apiKeyOfTinyMce}
                        value={notes}
                        onEditorChange={(newValue, editor) => {
                            const length = editor.getContent({ format: 'text' }).length;
                            if (length <= sizeLimit) setNotes(newValue);
                        }}
                        onBlur={handlerFunction}
                        onBeforeAddUndo={(evt, editor) => {
                            const length = editor.getContent({ format: 'text' }).length;
                            if (length >= 3000) {
                                evt.preventDefault();
                            }
                        }}
                        init={{
                            placeholder: 'Type here to add notes.',
                            height: 240,
                            statusbar: false,
                            menubar: false,
                            plugins: ['link', 'lists', 'paste'],
                            branding: false,
                            paste_as_text: true,
                            setup: (editor) => {
                                editor.on('PostProcess', function (ed) {
                                    // we are cleaning empty paragraphs
                                    ed.content = ed.content
                                        .replace(/(<p>&nbsp;<\/p>)/gi, '<br>')
                                        .replace(/(<br \/>)/gi, '<br>')
                                        .replace(/(\n<li>)/gi, '<li>')
                                        .replace(/(<\/li>\n)/gi, '</li>')
                                        .replace(/(\r\n|\n|\r)/gi, '<br>')
                                        .replace(/(<br>){1,}/g, '<br>');
                                });
                            },
                            toolbar: 'bold | italic | bullist | numlist',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-editor-header {display: inline} p { margin: 0; }',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default NotesComponent;
