import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../../../../app/hooks';
import { Grid, TextField, Checkbox } from '@material-ui/core';
import {
    ListingAmenity,
    ListingAmenityWithSelected,
} from '../../../../../../../../shared/models/listing/amenityModels';
import { updateAmenities } from '../../../../../listingSlice';
import { toggleAmenityFlag } from '../../listingFeaturesSlice';

interface FeatureWithTextProps {
    listingAmenity: ListingAmenityWithSelected;
}

const FeatureWithText: FunctionComponent<FeatureWithTextProps> = ({ listingAmenity }) => {
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        setSelected(listingAmenity.selected);
        setText(listingAmenity.text || '');
    }, [listingAmenity]);

    const handleTextValChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const value = e.target.value;
        setText(value);

        const hasVal = (value?.length || 0) > 0;
        setSelected(hasVal);
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelected(e.target.checked);
    };

    const persistAmenityChanges = () => {
        const data = { ...listingAmenity, text: text } as ListingAmenity;
        dispatch(updateAmenities({ features: [data], addingUpdating: selected }));
        dispatch(toggleAmenityFlag(true));
    };

    return (
        <Grid item container alignItems="center" md={6} sm={12} spacing={3}>
            <Grid item className="field-label">
                <label>{listingAmenity.amenityName}</label>
            </Grid>
            <Grid item className="field-control">
                <Checkbox
                    checked={selected}
                    onChange={handleCheckboxChange}
                    onBlur={persistAmenityChanges}
                    name={listingAmenity.amenityId.toString()}
                    id={listingAmenity.amenityId.toString()}
                />
            </Grid>
            <Grid item className="field-control">
                <TextField
                    name={listingAmenity.amenityName}
                    value={text}
                    onBlur={persistAmenityChanges}
                    inputProps={{ maxLength: 100 }}
                    onChange={handleTextValChange}
                />
            </Grid>
        </Grid>
    );
};

export default FeatureWithText;
