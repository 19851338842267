import {
    getCall,
    putCall,
    postCall,
    deleteCall,
    postCallRaw,
} from '../../../utils/api/apiWrapper';
import { AgentDetailsForAssistant } from '../../agents/agentDashboard/agentModels';
import {
    UserDetails,
    DashRoleForm,
    UserDashRole,
    UserAuditSaveModel,
} from './userDetailsModels';

export function getUserDetailsApi(userId: string): Promise<UserDetails> {
    return getCall<UserDetails>(`user/${userId}/detailsummary`);
}

export function updateUserDetailsApi(
    userId: string,
    userDetails: UserDetails,
): Promise<Response> {
    return putCall<UserDetails, Response>(`user/${userId}/detailsummary`, userDetails);
}

export function getAgentsForAssistantApi(
    userId: string,
): Promise<AgentDetailsForAssistant[]> {
    return getCall<AgentDetailsForAssistant[]>(`agent/${userId}/agentDetails`);
}

export function saveDashRoleApi(request: DashRoleForm): Promise<string> {
    return postCall<DashRoleForm, string>(`userdashrole`, request);
}

export function getUserDashRolesApi(userId: string): Promise<UserDashRole[]> {
    return getCall<UserDashRole[]>(`userdashrole/${userId}/user`);
}

export function deleteDashRoleApi(id: string): Promise<Response> {
    return deleteCall(`userdashrole/${id}`);
}

export function saveConciergeUserLoginApi(
    request: UserAuditSaveModel,
): Promise<Response> {
    return postCallRaw<UserAuditSaveModel>(`user/audit`, request);
}
