import { FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../developmentDetailsModel';

import {
    AgentDNDItem,
    AgentSearchResult,
} from '../../../../../../shared/models/agent/agentModel';
import { useAppDispatch, useAppSelector, useDebounce } from '../../../../../../app/hooks';
import {
    fetchAgent,
    developmentDetails,
    setSearchedAgent,
    updateAgents,
    updateDevAgentsDisplayOrders,
} from '../../../developmentDetailsSlice';
import './developmentAgentsEdit.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { itemTypes } from '../../../../../../shared/constants/dragAndDrop/itemTypes';
import { reorderItems } from '../../../../../../shared/functions/entity/agent/dragAndDrop/AgentDNDFunction';
import _ from 'lodash';
import GridItem from '../../../../../../shared/component/entity/agent/dragAndDrop/GridItem';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import ComponentWithLoader from '../../../../../../shared/component/loader/ComponentWithLoader';
import SearchAutoComplete from '../../../../../../shared/component/searchAutoComplete';

const DevelopmentAgentsEdit: FunctionComponent<DrawerContainerProps> = ({
    editFormData,
}) => {
    const dispatch = useAppDispatch();
    const { data } = editFormData;
    const { searchLoader, searchedAgents } = useAppSelector(developmentDetails);
    const [agentSearchTerm, setAgentSearchTerm] = useState<string>('');
    const debouncedAgentSearchTerm: string = useDebounce<string>(agentSearchTerm, 500);
    const [developmentAgents, setDevelopmentAgents] = useState<AgentDNDItem[]>([]);

    useEffect(() => {
        if (debouncedAgentSearchTerm !== '') {
            dispatch(fetchAgent(debouncedAgentSearchTerm));
        }
    }, [dispatch, debouncedAgentSearchTerm]);

    useEffect(() => {
        const agents = data.agents.map((agent) => {
            return {
                agentId: agent.agentId,
                name: agent.name,
                office: agent.office,
                displayOrder: agent.displayOrder,
            };
        });
        setDevelopmentAgents(agents);
    }, [data.agents]);

    /**
     * Has logic to fetch the agent and add it
     * @param selectedAgent - selected Agent
     */
    const addSelectedAgent = (selectedAgent: AgentSearchResult | null) => {
        if (selectedAgent) {
            const existingAgents = developmentAgents.length;
            dispatch(
                updateAgents(data.id, {
                    ...selectedAgent,
                    displayOrder: existingAgents + 1,
                }),
            );
            setAgentSearchTerm('');
        }
    };

    /**
     * clear the stage data for agent and listing autocomplete
     */
    const autoCompleteBlur = () => {
        if (searchedAgents.length) dispatch(setSearchedAgent([]));
    };

    const moveItem = (
        dragIndex: number,
        hoverIndex: number,
        isDropped: boolean,
        entity: string,
    ) => {
        if (entity === itemTypes.developmentAgent) {
            if (dragIndex !== hoverIndex) {
                setDevelopmentAgents(
                    reorderItems(dragIndex, hoverIndex, developmentAgents),
                );
            }
            if (isDropped)
                dispatch(
                    updateDevAgentsDisplayOrders(data.id, developmentAgents, data.agents),
                );
        }
    };
    /**
     * to delete the selected agent from DB
     */
    const deleteItem = (agentId: string, entity: string) => {
        if (entity === itemTypes.developmentAgent)
            dispatch(updateAgents(data.id, undefined, agentId));
    };

    const updatedSearchedAgents = _.differenceBy(
        searchedAgents,
        developmentAgents,
        'agentId',
    );

    return (
        <Grid container className="developmentAgentsEdit">
            <ComponentWithLoader showLoader={false} showOverlay={true}>
                <Grid container>
                    <Grid item md={12} className="title">
                        LIST SIDE
                    </Grid>
                    <Grid item md={12} className="sub-title">
                        A Development may be represented by up to 20 agents per side. To
                        select an agent, type their last name into the textbox below. A
                        filtered list of agents will appear below. Click the name of the
                        agent to add them to the list.
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className="inputHolder "
                    spacing={2}
                >
                    <FormRow
                        label="Agent Search"
                        control={
                            <SearchAutoComplete
                                searchTerm={agentSearchTerm}
                                setSearchTerm={setAgentSearchTerm}
                                results={updatedSearchedAgents}
                                userSelected={addSelectedAgent}
                                blurEvent={autoCompleteBlur}
                                isLoading={searchLoader}
                                from="agent"
                            />
                        }
                        controlClass="formControlRoot expanded"
                        labelMd={2}
                        controlMd={10}
                    />
                </Grid>
                <DndProvider backend={HTML5Backend}>
                    <Grid
                        item
                        container
                        className="dndContainer"
                        // spacing={4}
                        alignItems="center"
                    >
                        <Grid
                            item
                            container
                            md={12}
                            spacing={2}
                            className="agentSplitter"
                        >
                            <Grid item md={1} sm={1} className="dndHeader">
                                SI.NO
                            </Grid>
                            <Grid item md={5} sm={5} className="dndHeader">
                                AGENT NAME
                            </Grid>
                            <Grid item md={5} sm={5} className="dndHeader">
                                OFFICE NAME
                            </Grid>
                            <Grid item md={1} sm={1}></Grid>
                        </Grid>
                        {developmentAgents && developmentAgents.length > 0 ? (
                            developmentAgents.map((agent, index) => (
                                <GridItem
                                    index={index}
                                    key={agent.agentId}
                                    item={agent}
                                    moveItem={moveItem}
                                    deleteItem={deleteItem}
                                    entity={itemTypes.developmentAgent}
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </Grid>
                </DndProvider>
            </ComponentWithLoader>
        </Grid>
    );
};

export default DevelopmentAgentsEdit;
