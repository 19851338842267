import { SortingOrder } from '../../cmrModels';

export const reorderBlockSectionBasedOnIndex = (
    records: SortingOrder[],
): SortingOrder[] => {
    return records.map((order: SortingOrder, index: number) => {
        return {
            ...order,
            sortOrder: index + 1,
        };
    });
};
