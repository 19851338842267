import { getCall, postCall } from '../../../../utils/api/apiWrapper';
import { NoteDetail } from './notesModel';

export function getNotes(id: string): Promise<NoteDetail[]> {
    return getCall<NoteDetail[]>(`listing/${id}/notes`);
}

export function saveNote(id: string, request: Partial<NoteDetail>): Promise<NoteDetail> {
    return postCall<Partial<NoteDetail>, NoteDetail>(`listing/${id}/notes`, request);
}
