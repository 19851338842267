import { FunctionComponent, useState } from 'react';
import { SearchComponentProps } from '../../advancedSearchModels';
import SearchFieldWrapper from './SearchFieldWrapper';
import { Checkbox, FormControl, Grid } from '@material-ui/core';

const CheckboxSearch: FunctionComponent<SearchComponentProps> = ({
    criteria,
    onCriteriaAdd,
}) => {
    // const [selected, setSelected] = useState<boolean>(false);
    const [selectedCheckboxVals, setSelectedCheckboxVals] = useState<string[]>(
        criteria.values,
    );

    /* useEffect(() => {
        if (criteria.values?.length) {
            const val = criteria.values[0] === '1' ? true : false;
            setSelected(val);
        }
    }, [criteria.values]); */

    /* const handleCriteriaUpdate = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setSelected(checked);
        const values = checked ? '1' : '0';
        onCriteriaAdd({ ...criteria, values: [values] });
    }; */

    // const component = (
    //     <Checkbox
    //         id={criteria.fieldName}
    //         checked={selected}
    //         onChange={handleCriteriaUpdate}
    //         className="checkbox-search"
    //     />
    // );

    /**
     * function to handle the checkbox selection and unselection.
     * @param id - from the lookup
     */
    const handleCheckboxCriteriaUpdate = (id: string) => {
        // if (selectedCheckboxVals.length === 0) {
        //     setSelectedCheckboxVals([...selectedCheckboxVals, id]);
        //     onCriteriaAdd({ ...criteria, values: [...selectedCheckboxVals, id] });
        // } else {
        //     if (selectedCheckboxVals.includes(id)) {
        //         const newSelectedCheckboxVals = selectedCheckboxVals.filter(
        //             (e) => e !== id,
        //         );
        //         setSelectedCheckboxVals([...newSelectedCheckboxVals]);
        //         onCriteriaAdd({ ...criteria, values: [...newSelectedCheckboxVals] });
        //     } else {
        //         setSelectedCheckboxVals([]);
        //         onCriteriaAdd({ ...criteria, values: [] });
        //     }
        // }
        if (selectedCheckboxVals.includes(id)) {
            const newSelectedCheckboxVals = selectedCheckboxVals.filter((e) => e !== id);
            setSelectedCheckboxVals([...newSelectedCheckboxVals]);
            onCriteriaAdd({ ...criteria, values: [...newSelectedCheckboxVals] });
        } else {
            setSelectedCheckboxVals([...selectedCheckboxVals, id]);
            onCriteriaAdd({ ...criteria, values: [...selectedCheckboxVals, id] });
        }
    };

    /**
     * used to calculate the checkbox checked status
     * @param id - from the lookup
     * @returns boolean
     */
    const setChecked = (id: string) => {
        return selectedCheckboxVals.includes(id) ? true : false;
    };

    const renderRow = (label: string, id: string): JSX.Element => {
        return (
            // <FormRow
            //     label={label}
            //     control={
            //         <Checkbox
            //             id={id}
            //             checked={setChecked(id)}
            //             onChange={() => handleCheckboxCriteriaUpdate(id)}
            //             className="checkbox-search customCheckbox"
            //         />
            //     }
            //     labelMd={4}
            //     controlMd={2}
            // />
            <>
                <Grid item className="field-control" xs={12} sm={12} md={1}>
                    <FormControl>
                        <Checkbox
                            id={id}
                            checked={setChecked(id)}
                            onChange={() => handleCheckboxCriteriaUpdate(id)}
                            className="checkbox-search customCheckbox"
                        />
                    </FormControl>
                </Grid>
                <Grid id={id} item className="field-label" xs={12} sm={12} md={5}>
                    <label>{label}</label>
                </Grid>
            </>
        );
    };

    const checkboxComponent = (): JSX.Element => {
        return (
            <Grid item container className="content" alignItems="center" md={12}>
                {renderRow('Yes', '1')}
                {renderRow('No', '0')}
            </Grid>
        );
    };
    return <SearchFieldWrapper control={checkboxComponent()} label="" />;
};

export default CheckboxSearch;
