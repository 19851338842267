import { TableHeadCell } from '../../shared/models/customTable/customTableModels';
import {
    AdvertisementSearchRequest,
    CreateAdvertisementModel,
} from './advertisementHolder/advertisementModels';

export const initialData: CreateAdvertisementModel = {
    mediaOutletId: '',
    name: '',
    publish: true,
    type: '',
    frequency: '',
    location: '',
    edition: '',
    runDate: '',
    reservationDeadline: '',
    totalCost: 0,
    notes: '',
    tearSheetUrl: '',
};

export const advertisementListTableHeadCells: TableHeadCell[] = [
    {
        id: 'name',
        label: 'Name',
        dbColMapping: 'Name',
        doSort: true,
    },
    {
        id: 'typeName',
        label: 'Type Of Add',
        dbColMapping: 'TypeName',
        doSort: true,
    },
    {
        id: 'frequencyName',
        label: 'Frequency',
        dbColMapping: 'FrequencyName',
        doSort: true,
    },
    {
        id: 'mediaOutletName',
        label: 'Media Outlet',
        dbColMapping: 'MediaOutletName',
        doSort: true,
    },
    {
        id: 'location',
        label: 'Location',
        dbColMapping: 'Location',
        doSort: true,
    },
    {
        id: 'edition',
        label: 'Edition',
        dbColMapping: 'Edition',
        doSort: true,
    },
    {
        id: 'runDate',
        label: 'Run Date',
        dbColMapping: 'RunDate',
        doSort: true,
        isDate: true,
    },
    {
        id: 'reservationDeadline',
        label: 'Reservation Deadline',
        dbColMapping: 'ReservationDeadline',
        doSort: true,
        isDate: true,
    },
    {
        id: 'copy',
        label: '',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'copy',
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'edit',
    },
];

export const advTabId = {
    viewAssignments: {
        id: 0,
    },
    bookOpenSpots: {
        id: 1,
    },
    reserveSpots: {
        id: 2,
    },
    agentCost: {
        id: 3,
    },
};

export const initialAdvertisementSearch: AdvertisementSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    name: '',
    edition: '',
    mediaOutletId: '',
    deadlineStartDate: '',
    deadlineEndDate: '',
    runStartDate: '',
    runEndDate: '',
    sortDirection: 'desc',
    sortColumn: 'runDate',
};
