import { Button, Chip, Grid, TextField, Typography } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import './invite.scss';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { sendInviteToPhotographer } from './inviteApi';
import { InviteRequest } from './inviteModels';
import loDash from 'lodash';
import { useAppDispatch } from '../../../../../../../../../app/hooks';
import { validateEmail } from '../../../../../../../../../utils/urlUtils';
import { getUser } from '../../../../../../../../../shared/auth/authService';
import ComponentWithLoader from '../../../../../../../../../shared/component/loader/ComponentWithLoader';
import {
    setSuccess,
    setError,
} from '../../../../../../../../../shared/slices/messaging/messagingSlice';
import { DrawerContainerProps } from '../../../../../../listingModels';
import { updatePhotographers } from '../../../photoEditorSlice';
import { googleAnalyticsAction } from '../../../../../../../../../shared/functions/googleAnalytics/ga';

interface InviteProps {
    data: DrawerContainerProps;
    photographers: string[];
}

const Invite: FunctionComponent<InviteProps> = ({ data, photographers }) => {
    const [email, setEmail] = useState<string>('');
    const [chips, setChips] = useState<string[]>([]);
    const [message, setMessage] = useState<string>('');
    const [invite, setInvite] = useState<boolean>(false);
    const [rejectedEmails, setRejectedEmails] = useState<string>('');
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const handleSendButton = () => {
        const concatenatedEmails: string[] = [];
        if (chips.length > 0) {
            setLoading(true);
            chips.forEach(function (chip: string) {
                const concatenated = chip + ',' + data.editFormData.data.listingNumber;
                concatenatedEmails.push(concatenated);
            });
            sendInvite(concatenatedEmails);
            googleAnalyticsAction(
                'Save',
                'Click',
                `Listing Edit: Invite Photographers, Image type: photographers-upload`,
            );
            googleAnalyticsAction(
                'Save',
                'Click',
                `Listing Edit: Invite Photographers, Image type: photographers-upload, Office: ${data.editFormData.data.office}`,
            );
        }
    };

    const deleteChip = (chip: string) => {
        setChips(chips.filter((item) => item !== chip));
    };
    const clearState = () => {
        setChips([]);
        setEmail('');
        setMessage('');
    };
    const validateEmails = () => {
        const emailAddress = email.toLowerCase();
        if (validateEmail(email) && chips.length < 5 && !chips.includes(emailAddress)) {
            setChips([...chips, emailAddress]);
            setEmail('');
        }
    };
    const sendInvite = async (emails: string[]) => {
        const address1 =
            data.editFormData.data.address1 && data.editFormData.data.address1;
        const address2 =
            data.editFormData.data.address2 && data.editFormData.data.address2;
        const loggedInUser = getUser();
        const invite: InviteRequest = {
            toAddress: emails,
            fromAddress: loggedInUser && loggedInUser.email,
            fromName: loggedInUser && loDash.capitalize(loggedInUser.email.split('@')[0]),
            message: message,
            listingId: data.editFormData.data.id,
            listingAddress: address2 ? address1 + ', ' + address2 : address1,
            city: data.editFormData.data.city,
            state: data.editFormData.data.state,
            zip: data.editFormData.data.zip,
        };
        try {
            const response = await sendInviteToPhotographer(invite);
            if (response.length > 0) {
                setRejectedEmails(response.toString());
            } else {
                setRejectedEmails('');
                dispatch(setSuccess('Invite Sent'));
                dispatch(updatePhotographers([...photographers, ...chips]));
            }
        } catch {
            dispatch(setError("Invite didn't send"));
        } finally {
            setLoading(false);
        }
        clearState();
    };
    return (
        <Grid className="photographerInviteContainer">
            <ComponentWithLoader
                showLoader={loading}
                showOverlay={true}
                styleClass="loaderClass"
                loadingText="Sending..."
            />
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                className="photographerInviteSection"
            >
                <Grid item container className="toggleInvite">
                    Invite Photographers / Videographers
                    {invite ? (
                        <ArrowDropDownIcon
                            className="arrowIcon"
                            onClick={() => setInvite(!invite)}
                        />
                    ) : (
                        <ArrowRightIcon
                            className="arrowIcon"
                            onClick={() => setInvite(!invite)}
                        />
                    )}
                </Grid>
                {invite ? (
                    <>
                        <Grid item container className="toGrid">
                            <Grid item md={1} className="toLabel">
                                TO
                            </Grid>
                            <Grid item md={11} className="toChips">
                                {chips && chips.length > 0 ? (
                                    chips.map((chip: string) => (
                                        <Chip
                                            key={chip}
                                            label={chip}
                                            onDelete={() => deleteChip(chip)}
                                            className="emailChip"
                                        />
                                    ))
                                ) : (
                                    <></>
                                )}
                                {chips.length < 5 ? (
                                    <>
                                        <TextField
                                            value={email}
                                            className="email"
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                            name="email"
                                            InputProps={{
                                                disableUnderline: true,
                                                autoComplete: 'off',
                                            }}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    validateEmails();
                                                }
                                            }}
                                            onBlur={validateEmails}
                                        />
                                        <Typography className="note">
                                            Note: Only five email addresses can be added
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container className="messageGrid">
                            <Grid item md={1} className="messageLabel">
                                MESSAGE
                            </Grid>
                            <Grid item md={11}>
                                <TextField
                                    value={message}
                                    className="messageField"
                                    onChange={(e) => setMessage(e.target.value)}
                                    name="message"
                                    InputProps={{
                                        disableUnderline: true,
                                        autoComplete: 'off',
                                    }}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                            <Button
                                className="sendButton"
                                onClick={handleSendButton}
                                disabled={chips.length === 0 || message === ''}
                            >
                                Send
                            </Button>
                        </Grid>
                        <Grid item container justifyContent="center">
                            {rejectedEmails ? (
                                <Typography className="rejectedEmails">
                                    Invitation is already sent for {rejectedEmails}.
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};
export default Invite;
