import { Grid } from '@material-ui/core';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { userRoleLookups } from '../../../../../../shared/constants/user/roles';
import {
    CommonLookups,
    UserRoleLookups,
} from '../../../../../../shared/models/lookups/lookupsModels';
import { editFormComponent } from '../../../userDetailsConstants';
import { setCurrentUserEditFormData, userDetails } from '../../../userDetailsSlice';
import { findIdFromLookups } from '../../../../../../utils/urlUtils';
import { lookups as lookupsSlice } from '../../../../../../shared/slices/lookups/lookupsSlice';

import RoleExplanation from './RoleExplanation';
import UserRoleCustomChips from './UserRoleCustomChips';

const Roles: FC = () => {
    const dispatch = useAppDispatch();
    const { details } = useAppSelector(userDetails);
    const { common } = useAppSelector(lookupsSlice);
    const { salutations, statuses } = common as CommonLookups;
    const updatedUserRoleLookups = userRoleLookups.map((userRole) => {
        return {
            ...userRole,
            key: userRole.key.toLowerCase(),
            isSelected: details.roleIds.includes(userRole.key.toLowerCase())
                ? true
                : false,
        };
    });
    const [userRoles, setUserRoles] = useState<UserRoleLookups[]>(updatedUserRoleLookups);
    const updateUserRoles = (updatedUserRole: UserRoleLookups, isAdded: boolean) => {
        let roles = [];
        if (isAdded) {
            roles = userRoles.map((userRole) => {
                return userRole.key === updatedUserRole.key
                    ? { ...userRole, isSelected: true }
                    : { ...userRole };
            });
        } else {
            roles = userRoles.map((userRole) => {
                return userRole.key === updatedUserRole.key
                    ? { ...userRole, isSelected: false }
                    : { ...userRole };
            });
        }
        setUserRoles(roles);
        const roleIds = roles.filter((role) => role.isSelected).map((role) => role.key);
        dispatch(
            setCurrentUserEditFormData({
                formData: {
                    ...details,
                    roleIds: roleIds,
                    salutation: findIdFromLookups(details.salutation, salutations),
                    status: findIdFromLookups(details.status, statuses),
                },
                userEditFormName: editFormComponent.roles,
            }),
        );
    };

    return (
        <Grid item container className="user-roles-container">
            <Grid item>
                {userRoles
                    .filter((data) => data.grouping === 'normal')
                    .map((userRole) => (
                        <UserRoleCustomChips
                            key={userRole.key}
                            userRole={userRole}
                            updateUserRoles={updateUserRoles}
                        />
                    ))}
            </Grid>
            <Grid item className="marginTop20">
                {userRoles
                    .filter((data) => data.grouping === 'permission')
                    .map((userRole) => (
                        <UserRoleCustomChips
                            key={userRole.key}
                            userRole={userRole}
                            updateUserRoles={updateUserRoles}
                        />
                    ))}
            </Grid>
            <Grid className="marginTop30">
                <RoleExplanation />
            </Grid>
        </Grid>
    );
};

export default Roles;
