import { FunctionComponent, useState } from 'react';
import { SectionProps } from '../../photoEditorModels';
import {
    photoDetails,
    saveImageDetails,
    updateOrderNo,
    updateTotalImageCount,
    deleteImageDetails,
    updateAllImages,
    saveImageSharePackage,
    downloadImagePackage,
    imageDownload,
    fetchImageTags,
    updateFlag,
    saveImageCaption,
    saveImageTags,
} from '../../photoEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import PhotoTab from '../../../../../../../../shared/component/photos/PhotoTab';
import { imageTypes } from '../../../../../../../../shared/constants/imageTypes';
import { UpdateFlag } from '../../../../../../../../shared/models/flag/flagModel';
import { setCurrentFormData, listingDetail } from '../../../../../listingSlice';
import {
    ImageData,
    ImageUpdate,
    OrderNoUpdate,
} from '../../../../../../../../shared/models/images/sharedModel';

const FloorPlan: FunctionComponent<SectionProps> = ({ data, currentSection }) => {
    const photoInfo = useAppSelector(photoDetails);
    const {
        data: { salesRegionId },
    } = useAppSelector(listingDetail);
    const dispatch = useAppDispatch();
    const { currentOrderNo, allImages, flags, types, filter, imageTags } = photoInfo;
    const [imageSelectCount, setImageSelectCount] = useState<number>(0);
    const updateOrderNumber = (data: OrderNoUpdate) => {
        dispatch(updateOrderNo(data));
    };

    const updateImageCount = (data: OrderNoUpdate) => {
        dispatch(updateTotalImageCount(data));
    };

    const updateImages = (imageData: ImageUpdate) => {
        dispatch(updateAllImages(imageData));
    };

    const flagUpdate = (data: UpdateFlag) => {
        dispatch(updateFlag(data));
    };

    const setFormData = (images: ImageData[], formName: string) => {
        dispatch(
            setCurrentFormData({
                formData: images,
                listingEditFormName: formName,
            }),
        );
    };

    const getMaxAllowedPhotos = (): number => {
        return (
            parseInt(types.photos.maxPhotos) -
            (types.photos.photosLoaded + types.floorplans.photosLoaded)
        );
    };

    const disableUpload = () => {
        return (
            types.photos.photosLoaded + types.floorplans.photosLoaded >=
            parseInt(types.photos.maxPhotos)
        );
    };

    return (
        <>
            <PhotoTab
                from="listing"
                imageOrderNo={currentOrderNo.floorplans}
                images={allImages.floorplans}
                isUploading={flags.isUploading}
                currentImageType={imageTypes.FloorPlan}
                entityId={data.editFormData.data.id}
                saveImageDetails={saveImageDetails}
                updateOrderNumber={updateOrderNumber}
                updateImageCount={updateImageCount}
                currentImageDetails={types.floorplans}
                filter={filter}
                flags={flags}
                deleteImageDetails={deleteImageDetails}
                updateImages={updateImages}
                saveImageSharePackage={saveImageSharePackage}
                downloadImagePackage={downloadImagePackage}
                imageDownload={imageDownload}
                fetchImageTags={fetchImageTags}
                flagUpdate={flagUpdate}
                saveImageCaption={saveImageCaption}
                imageTags={imageTags}
                saveImageTags={saveImageTags}
                entityType="listing"
                setFormData={setFormData}
                currentSection={currentSection}
                imageSelectCount={imageSelectCount}
                setImageSelectCount={setImageSelectCount}
                maxAllowedPhotos={getMaxAllowedPhotos()}
                disableUpload={disableUpload()}
                salesRegionId={salesRegionId}
                office={data.editFormData.data.office}
            />
        </>
    );
};
export default FloorPlan;
