import moment from 'moment';
import { TableHeadCell } from '../../../../shared/models/customTable/customTableModels';

export const marketingsTableHeadCells: TableHeadCell[] = [
    {
        id: 'date',
        label: 'Date',
        dbColMapping: '',
        isDate: true,
        doSort: false,
    },
    {
        id: 'marketingTypeName',
        label: 'Marketing Type',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'description',
        label: 'Description',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'url',
        label: 'Url',
        dbColMapping: '',
        doSort: false,
        isLink: true,
    },
    {
        id: 'edit',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'edit',
        doSort: false,
    },
    {
        id: 'delete',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'delete',
        doSort: false,
    },
    // {
    //     id: 'additionalPromotionType',
    //     label: 'Additional Promotion',
    //     dbColMapping: '',
    //     doSort: false,
    // },
];

// Commented for now, don't remove
// export const marketingsTableHeadCells: TableHeadCell[] = [
//     {
//         id: 'date',
//         label: 'Date',
//         dbColMapping: '',
//         isDate: true,
//         doSort: false,
//     },
//     {
//         id: 'description',
//         label: 'Description',
//         dbColMapping: '',
//         doSort: false,
//     },
//     {
//         id: 'marketingTypeName',
//         label: 'Marketing Type',
//         dbColMapping: '',
//         doSort: false,
//     },
//     {
//         id: 'additionalPromotionType',
//         label: 'Additional Promotion',
//         dbColMapping: '',
//         doSort: false,
//     },
//     {
//         id: 'marketingTypeName',
//         label: 'Edit',
//         dbColMapping: '',
//         customIcon: true,
//         actionType: 'edit',
//         doSort: false,
//     },
//     {
//         id: 'delete',
//         label: 'Delete',
//         dbColMapping: '',
//         customIcon: true,
//         actionType: 'delete',
//         doSort: false,
//     },
// ];

// Commented for now, don't remove
// export const paidAdsTableHeadCells: TableHeadCell[] = [
//     {
//         id: 'date',
//         label: 'Date',
//         dbColMapping: '',
//         isDate: true,
//         doSort: false,
//     },
//     {
//         id: 'description',
//         label: 'Advertisement Name',
//         dbColMapping: '',
//         doSort: false,
//     },
//     {
//         id: 'advertisementType',
//         label: 'Advertisement Type',
//         dbColMapping: '',
//         doSort: false,
//     },
// ];

export const initialPropertyMarketingDetails = {
    date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    marketingTypeId: 16,
    isFile: false,
};

export const propertyMarketingList = [
    { id: '0', name: 'All' },
    { id: '1', name: 'Paid Advertisement' },
    { id: '14', name: 'Advertisement' },
    { id: '12', name: 'Email Campaign' },
    { id: '15', name: 'Social Campaign' },
    { id: '10', name: 'Featured Placement' },
    { id: '16', name: 'Additional Promotion' },
    { id: '2', name: 'Press' },
    { id: '3', name: 'Open House' },
    { id: '4', name: 'Showing' },
];
