import {
    AgentReorderRequest,
    AgentSearchResult,
} from '../../../shared/models/agent/agentModel';
import { ListingAmenity } from '../../../shared/models/listing/amenityModels';
import {
    BrightCoveData,
    BrightcoveResponse,
} from '../../../shared/models/video/brightcove/brightcoveModels';
import {
    getCall,
    deleteCall,
    postCall,
    postCallRaw,
    putCallRaw,
    getCallRaw,
} from '../../../utils/api/apiWrapper';
import {
    DevelopmentAgent,
    DevelopmentDetailsResponse,
    DevelopmentListing,
    UpdateDevelopmentRequest,
} from './developmentDetailsModel';

/*
 * Development Details
 */

export function getDevelopmentDetailsData(
    developmentId: string,
): Promise<DevelopmentDetailsResponse> {
    return getCall<DevelopmentDetailsResponse>(`building/${developmentId}/detailsummary`);
}

export function getDevelopmentUnits(developmentId: string): Promise<Response> {
    return getCallRaw(`building/${developmentId}/units`);
}

export function updateDevelopmentApi(
    updateRequest: UpdateDevelopmentRequest,
): Promise<Response> {
    return putCallRaw<UpdateDevelopmentRequest>('building', updateRequest);
}

/*
 * Development Features
 */

export function getDevelopmentAmentitiesData(
    developmentId: string,
): Promise<ListingAmenity[]> {
    return getCall<ListingAmenity[]>(`building/${developmentId}/amenitydetails`);
}

/*
 * Development Agents
 */

export function getDevelopmentAgentsData(
    developmentId: string,
): Promise<DevelopmentAgent[]> {
    return getCall<DevelopmentAgent[]>(`building/${developmentId}/agentdetailsummary`);
}

export function updateAgentsApi(
    developmentId: string,
    data: AgentSearchResult,
): Promise<DevelopmentAgent> {
    return postCall<AgentSearchResult, DevelopmentAgent>(
        `building/${developmentId}/agent`,
        data,
    );
}

export function deleteAgentsApi(
    developmentId: string,
    agentId: string,
): Promise<Response> {
    return deleteCall(`building/${developmentId}/agent/${agentId}`);
}

/*
 * Development Video
 */

export function postVideoMetaData(
    id: string,
    data: BrightCoveData,
): Promise<BrightcoveResponse> {
    return postCall<BrightCoveData, BrightcoveResponse>(`building/${id}/videos`, data);
}

/*
 * Associated Listings
 */

export function getDevelopmentListingssData(
    developmentId: string,
): Promise<DevelopmentListing[]> {
    return getCall<DevelopmentListing[]>(
        `building/${developmentId}/listingdetailsummary`,
    );
}

export function updateListingsApi(
    developmentId: string,
    data: string[],
): Promise<Response> {
    return postCallRaw<string[]>(`building/${developmentId}/listing`, data);
}

export function deleteListingsApi(
    developmentId: string,
    listingId: string,
): Promise<Response> {
    return deleteCall(`building/${developmentId}/listing/${listingId}`);
}

export function saveDevelopmentAmenities(
    amenities: ListingAmenity[],
    developmentId: string,
): Promise<Response> {
    return postCallRaw(`building/${developmentId}/amenities`, amenities);
}

export function updateDevPublish(developmentId: string): Promise<Response> {
    return putCallRaw(`building/${developmentId}/togglepublish`, {});
}

export const updateDevAgentsDisplayOrdersApi = (
    developmentId: string,
    devAgents: AgentReorderRequest[],
): Promise<Response> => {
    return putCallRaw(`building/${developmentId}/reorderagents`, devAgents);
};
