import { FunctionComponent, useState, useEffect } from 'react';
import {
    faDownload,
    faPencil,
    faTag,
    faCropSimple,
    faPenToSquare,
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ImageData,
    ImageDetail,
    Flags,
    CaptionDetails,
    Filter,
    ImageTypes,
} from '../../../../models/images/sharedModel';
import { imageEditMenuForms } from '../../../../constants/photos/photoConstants';
import { Tooltip, makeStyles } from '@material-ui/core';
import { validateImageSize } from '../../../../utils/photos/photoUtils';
import { useAppDispatch } from '../../../../../app/hooks';
import './editMenu.scss';
import { imageTypes } from '../../../../constants/imageTypes';
import { AppThunk } from '../../../../../app/store';
import { UpdateFlag } from '../../../../models/flag/flagModel';
import ImageCrop from '../imageCrop/ImageCrop';
import ImageCaption from '../imageCaption/ImageCaption';
import ImageDownload from '../imageDownload/ImageDownload';
import ImageTags from '../imageTags/ImageTags';
export const imageEditMenuFormMap = new Map([[imageEditMenuForms.imageCrop, ImageCrop]]);

const useStyles = makeStyles({
    tooltip: {
        maxWidth: 200,
        fontSize: 14,
        fontFamily: 'MercuryTextG4Roman',
        margin: '20px 0px',
    },
});

interface EditMenuProps {
    image: ImageData | undefined;
    currentImageType: string;
    cancelHandler(): void;
    imageDownload: (url: string) => AppThunk;
    fetchImageTags: (entityId: string, imageId: string) => AppThunk;
    saveImageDetails: (request: ImageDetail, oldImage?: ImageData) => AppThunk;
    flagUpdate: (data: UpdateFlag) => void;
    flags: Flags;
    currentImageDetails: ImageTypes;
    saveImageCaption: (id: string, data: CaptionDetails, imageType: string) => AppThunk;
    imageTags: Filter[];
    saveImageTags: (
        image: ImageData,
        chipData: Filter | undefined,
        currentImageType: string,
    ) => AppThunk;
    entityType: string;
    office: string | null;
}

const EditMenu: FunctionComponent<EditMenuProps> = ({
    image,
    currentImageType,
    cancelHandler,
    imageDownload,
    fetchImageTags,
    saveImageDetails,
    flagUpdate,
    flags,
    currentImageDetails,
    saveImageCaption,
    imageTags,
    saveImageTags,
    entityType,
    office,
}) => {
    const [selectedAction, setSelectedAction] = useState<string>('ImageCaption');
    const [isFloorPlanTitle, setFloorPlanTitle] = useState<boolean>(false);
    const [isFullScreen, setFullScreen] = useState<boolean>(false);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const RenderAction =
        selectedAction.length > 0 ? imageEditMenuFormMap.get(selectedAction) : null;

    const selectActionHandler = (action: string) => {
        selectedAction === action ? setSelectedAction('') : setSelectedAction(action);
    };

    useEffect(() => {
        if (image && !imageTags.length) {
            dispatch(fetchImageTags(image.entityId, image.guid));
        }
    }, [image, dispatch]);

    return (
        <div className={isFullScreen ? 'imageEditMenuHolderFullScreen' : ''}>
            {RenderAction && selectedAction === imageEditMenuForms.imageCrop ? (
                <RenderAction
                    image={image}
                    currentImageType={currentImageType}
                    cancelHandler={cancelHandler}
                    isFullScreen={isFullScreen}
                    setSelectedAction={setSelectedAction}
                    saveImageDetails={saveImageDetails}
                    flagUpdate={flagUpdate}
                    flags={flags}
                    currentSectionType={currentImageDetails}
                    entityType={entityType}
                    office={office}
                />
            ) : (
                <div
                    className={
                        isFullScreen
                            ? 'editMenuHolder editMenuHolderFullScreen'
                            : 'editMenuHolder'
                    }
                >
                    <div
                        className={
                            isFullScreen
                                ? 'imageContainer imageContainerFullScreen'
                                : 'imageContainer'
                        }
                        style={
                            // isFullScreen
                            //     ? {
                            //           background: `url(${
                            //               image ? image.urls.smallUrl : ''
                            //           }) no-repeat center`,
                            //           backgroundSize: `contain`,
                            //       }
                            //     :
                            {
                                background: `url(${
                                    image ? image.urls.smallUrl : ''
                                }) no-repeat center`,
                                backgroundSize: `contain`,
                            }
                        }
                    >
                        <div className="fullScreenIcon">
                            <span>
                                <FontAwesomeIcon
                                    className="fileIcon"
                                    icon={
                                        isFullScreen
                                            ? faDownLeftAndUpRightToCenter
                                            : faUpRightAndDownLeftFromCenter
                                    }
                                    onClick={() =>
                                        !isFullScreen
                                            ? setFullScreen(true)
                                            : setFullScreen(false)
                                    }
                                    title={
                                        isFullScreen
                                            ? 'Exit Fullscreen'
                                            : 'Switch to Fullscreen'
                                    }
                                />
                            </span>
                        </div>
                        {selectedAction === imageEditMenuForms.imageCaption && (
                            <ImageCaption
                                image={image}
                                currentImageType={currentImageType}
                                isFloorPlanTitle={isFloorPlanTitle}
                                cancelHandler={() => setSelectedAction('')}
                                isFullScreen={isFullScreen}
                                saveImageCaption={saveImageCaption}
                                entityType={entityType}
                                office={office}
                            />
                        )}
                        {selectedAction === imageEditMenuForms.imageDownload && (
                            <ImageDownload
                                image={image}
                                // currentImageType={getCurrentImageType(currentImageType)}
                                currentImageType={currentImageType}
                                cancelHandler={() => setSelectedAction('')}
                                size={0}
                                imageDownload={imageDownload}
                                entityType={entityType}
                                office={office}
                            />
                        )}
                        {selectedAction === imageEditMenuForms.imageTags && (
                            <div className="tagsContainer">
                                <ImageTags
                                    cancelHandler={() => setSelectedAction('')}
                                    image={image}
                                    currentImageType={currentImageType}
                                    isFullScreen={isFullScreen}
                                    imageTags={imageTags}
                                    saveImageTags={saveImageTags}
                                    entityType={entityType}
                                    office={office}
                                />
                            </div>
                        )}
                        <div className="bottomBar">
                            <div className="bottomActions">
                                <Tooltip
                                    title="Download Image"
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                    arrow
                                    placement="top"
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            className="fileIcon"
                                            icon={faDownload}
                                            onClick={() =>
                                                selectActionHandler(
                                                    imageEditMenuForms.imageDownload,
                                                )
                                            }
                                        />
                                    </span>
                                </Tooltip>
                                {imageTypes.ShowcaseHeroImages !== currentImageType ? (
                                    <>
                                        {' '}
                                        |
                                        <Tooltip
                                            title="Edit Caption"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="fileIcon"
                                                    icon={faPencil}
                                                    onClick={() => {
                                                        selectActionHandler(
                                                            imageEditMenuForms.imageCaption,
                                                        );
                                                        setFloorPlanTitle(false);
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                                {imageTypes.ShowcaseHeroImages !== currentImageType ? (
                                    <>
                                        |
                                        <Tooltip
                                            title="Edit Tags"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="fileIcon"
                                                    icon={faTag}
                                                    onClick={() =>
                                                        selectActionHandler(
                                                            imageEditMenuForms.imageTags,
                                                        )
                                                    }
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                                {imageTypes.FloorPlan === currentImageType ? (
                                    <>
                                        {' '}
                                        |
                                        <Tooltip
                                            title="Edit Floor Plan Title"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <span>
                                                <FontAwesomeIcon
                                                    className="fileIcon"
                                                    icon={faPenToSquare}
                                                    onClick={() => {
                                                        selectActionHandler(
                                                            imageEditMenuForms.imageCaption,
                                                        );
                                                        setFloorPlanTitle(true);
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                                {image &&
                                validateImageSize(image.size.width, image.size.height) ? (
                                    <>
                                        {' '}
                                        |
                                        <Tooltip
                                            title="Crop Image"
                                            classes={{
                                                tooltip: classes.tooltip,
                                            }}
                                            arrow
                                            placement="top"
                                        >
                                            <FontAwesomeIcon
                                                className="fileIcon"
                                                icon={faCropSimple}
                                                onClick={() =>
                                                    selectActionHandler(
                                                        imageEditMenuForms.imageCrop,
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditMenu;
