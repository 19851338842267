import { Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import FormRow from '../../../../../../../../shared/component/formFields/FormRow';
import { ShowCaseModel } from '../../showcaseWebsiteModels';

interface TrackingProps {
    handleTextChange(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
    ): void;
    formData: ShowCaseModel;
    updateShowCaseData(): void;
}

const Tracking: FunctionComponent<TrackingProps> = ({
    handleTextChange,
    formData,
    updateShowCaseData,
}) => {
    return (
        <>
            <Grid item container className="spacing smallSection customMarginTop">
                <FormRow
                    label="GOOGLE ANALYTICS TRACKING ID"
                    control={
                        <TextField
                            value={
                                formData.googleAnalyticsTrackingId
                                    ? formData.googleAnalyticsTrackingId
                                    : ''
                            }
                            size="small"
                            className=" "
                            onChange={handleTextChange}
                            name="googleAnalyticsTrackingId"
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'inputFields',
                            }}
                            onBlur={() => updateShowCaseData()}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={4}
                    controlMd={8}
                    labelSm={4}
                    controlSm={8}
                />
            </Grid>
            <Grid item container className="spacing smallSection">
                <FormRow
                    label="HOTJAR TRACKING ID"
                    control={
                        <TextField
                            value={
                                formData.hotjarTrackingId ? formData.hotjarTrackingId : ''
                            }
                            size="small"
                            onChange={handleTextChange}
                            name="hotjarTrackingId"
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'inputFields',
                            }}
                            onBlur={() => updateShowCaseData()}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={4}
                    controlMd={8}
                    labelSm={4}
                    controlSm={8}
                />
            </Grid>
            <Grid item container className="spacing smallSection">
                <FormRow
                    label="GOOGLE TAG MANAGER"
                    control={
                        <TextField
                            value={
                                formData.googleTagManager ? formData.googleTagManager : ''
                            }
                            size="small"
                            onChange={handleTextChange}
                            name="googleTagManager"
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'off',
                                className: 'inputFields',
                            }}
                            onBlur={() => updateShowCaseData()}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={4}
                    controlMd={8}
                    labelSm={4}
                    controlSm={8}
                />
            </Grid>
        </>
    );
};

export default Tracking;
