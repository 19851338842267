import React, { BrowserRouter } from 'react-router-dom';
import App from '../../App';

const AppWithRouterAccess = (): JSX.Element => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};

export default AppWithRouterAccess;
