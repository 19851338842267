import { getCall, putCallRaw } from '../../../utils/api/apiWrapper';
import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';
import { NotificationAssignment } from './manageNotificationsModels';

/** Gets notification lookup data to bind to the drop down */
export function getNotifications(): Promise<LookupInfo[]> {
    return getCall<LookupInfo[]>('notifications');
}

/**
 * Gets details of all entities assigned to a notification
 * @param notificationId The ID of the notification to get
 * assignments for
 */
export function getNotificationAssignmentDetails(
    notificationId: number,
): Promise<NotificationAssignment[]> {
    const url = `notification/${notificationId}/assignments`;
    return getCall<NotificationAssignment[]>(url);
}

/**
 * Updates the assignments associated with a notification
 * @param notificationId The ID of the notifcation having its assignments updated
 * @param assignments Assignments associated with the notification
 */
export function saveNotificationAssignments(
    notificationId: number,
    assignments: NotificationAssignment[],
): Promise<Response> {
    const url = `notification/${notificationId}/assignments`;
    return putCallRaw<NotificationAssignment[]>(url, assignments);
}
