import { Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { SearchedListingListModel } from '../../../shared/models/listing/commonModel';
import { currencyFormat } from '../../../utils/urlUtils';
import { AgentSearchResult } from '../../models/agent/agentModel';
import { UserSearchResponse } from '../../models/users/userModels';
import { OfficesResponse } from '../../models/office/officeModel';
import './index.scss';

interface SearchAutoCompleteModel<T> {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    placeholder?: string;
    value?: T;
    results: T[];
    resetKey?: number;
    userSelected(value: T | null): void;
    blurEvent(): void;
    isLoading?: boolean;
    from?: string;
}

const SearchAutoComplete: FunctionComponent<
    SearchAutoCompleteModel<
        | UserSearchResponse
        | AgentSearchResult
        | SearchedListingListModel
        | OfficesResponse
    >
> = ({
    searchTerm,
    setSearchTerm,
    placeholder,
    value,
    results,
    resetKey,
    userSelected,
    blurEvent,
    isLoading,
    from = 'user',
}) => {
    const displayData = (
        data:
            | UserSearchResponse
            | AgentSearchResult
            | SearchedListingListModel
            | OfficesResponse,
    ) => {
        if (from === 'user') {
            const option = data as UserSearchResponse;
            return option.firstName + ' ' + option.lastName + ' - ' + option.brandEmail;
        } else if (from === 'agent') {
            const option = data as AgentSearchResult;
            const preferredFirstName =
                option.preferredFirstName &&
                option.preferredFirstName !== option.firstName
                    ? `"${option.preferredFirstName}"`
                    : '';

            return `${option.firstName} ${preferredFirstName} ${option.lastName}`;
        } else if (from === 'listing') {
            const option = data as SearchedListingListModel;
            const add1 = option.address1 ? option.address1 : '';
            const add2 = option.address2 ? ', ' + option.address2 : '';
            const stat = option.status ? ' - ' + option.status : '';
            const curr = option.currentPrice
                ? ' - $' + currencyFormat(option.currentPrice)
                : '';
            const rfgId = option.rfgid ? '- ' + option.rfgid : '';
            const mlsNumber = option.mlsNumber ? '- ' + option.mlsNumber : '';
            return add1 + add2 + stat + curr + rfgId + mlsNumber;
        } else if (from === 'salesTeamMember') {
            const option = data as AgentSearchResult;
            return option.name + ' - ' + option.office;
        } else if (from === 'office') {
            const option = data as OfficesResponse;
            return option.officeName;
        } else {
            return '';
        }
    };
    return (
        <Autocomplete
            value={value}
            key={resetKey}
            forcePopupIcon={false}
            options={results}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="autoComplete formControlRoot"
                    placeholder={placeholder || ''}
                />
            )}
            getOptionLabel={(option) => displayData(option)}
            onChange={(event, value) => userSelected(value)}
            className="searchAutoComplete"
            onBlur={() => blurEvent()}
            PaperComponent={({ children }) => (
                <Paper
                    style={{
                        fontSize: 12,
                        fontFamily: 'BentonSansRegular',
                        marginTop: '5px',
                    }}
                >
                    {children}
                </Paper>
            )}
            loading={isLoading}
            loadingText="Loading..."
            clearOnBlur={true}
            noOptionsText="No Results Found"
        />
    );
};

export default SearchAutoComplete;
