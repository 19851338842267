import { OfficeData, OfficeDetailsSummary } from './officeModels';
import Description from './drawer/screens/description/Description';
import OfficePhoto from './drawer/screens/photo/index';
import History from './drawer/screens/history/History';
import Managers from './drawer/screens/managers/index';
import Settings from './drawer/screens/settings';
import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';
import DesignVaultManage from './drawer/screens/designVaultManage';

export const officeDescriptionConstant: CustomFormedData[] = [
    {
        title: 'PRIMARY MLS NAME',
        value: 'primaryMlsName',
    },
    {
        title: 'PRIMARY MLS ID',
        value: 'mlsNumber',
    },
    {
        title: 'NAME',
        value: 'name',
    },
    {
        title: 'OFFICE REGION',
        value: 'officeRegion',
    },
    {
        title: 'SIR OFFICE LINK',
        value: 'sirLink',
        isExternal: true,
    },
    {
        title: 'SALES REGION',
        value: 'salesRegion',
    },
    {
        title: 'PUBLISH',
        value: 'publish',
    },
    {
        title: 'STATUS',
        value: 'status',
    },
    {
        title: 'Location Code',
        value: 'tridentLocationCode',
    },
    {
        title: 'Map',
        value: 'showMap',
        isMap: true,
    },
];

export const contactsConstant: CustomFormedData[] = [
    {
        title: 'ADDRESS 1',
        value: 'address1',
    },
    {
        title: 'ADDRESS 2',
        value: 'address2',
    },
    {
        title: 'CITY',
        value: 'city',
    },
    {
        title: 'STATE',
        value: 'state',
    },
    {
        title: 'ZIP',
        value: 'zip',
    },
];

export const phoneConstant: CustomFormedData[] = [
    {
        title: 'Office Phone',
        value: 'phoneNumber',
        isNum: true,
    },
    {
        title: 'Fax',
        value: 'fax',
        isNum: true,
    },
];

export const emailConstant: CustomFormedData[] = [
    {
        title: 'Email',
        value: 'email',
    },
    {
        title: 'Facebook',
        value: 'facebookUrl',
        isExternal: true,
    },
    {
        title: 'Twitter X',
        value: 'twitterUrl',
        isExternal: true,
    },
    {
        title: 'LinkedIn',
        value: 'linkedInUrl',
        isExternal: true,
    },
    {
        title: 'Instagram',
        value: 'instagramUrl',
        isExternal: true,
    },
];

export const managerConstant: CustomFormedData[] = [
    {
        title: 'Manager Name',
        value: 'managerName',
    },
];

export const initializeOfficeDetails: OfficeDetailsSummary = {
    id: '',
    name: '',
    status: '',
    isValid: false,
    publish: false,
    mlsNumber: '',
    primaryMlsName: '',
    tridentLocationCode: 0,
    tridentMetroId: 0,
    createdDate: '',
    lastUpdated: '',
    lastTridentImport: '',
    history: '',
    phoneNumber: '',
    fax: '',
    salesRegion: '',
    facebookUrl: '',
    twitterUrl: '',
    linkedInUrl: '',
    urlName: '',
    officeRegion: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    latitude: 0,
    longitude: 0,
    email: '',
    listingCount: 0,
    marketArea: '',
    buyerSideDealCount: 0,
    licenseNumber: '',
    dashMlsName: '',
    dashMlsCode: '',
    managerTitle: '',
    managerName: '',
    officeName: '',
    officeSpecializations: [],
    managerId: '',
};

export const officeInitialStateData: OfficeData = {
    officeDetails: initializeOfficeDetails,
    officePhoto: [],
};

export const editFormComponent = {
    description: 'Description',
    history: 'History',
    officePhoto: 'OfficePhoto',
    managers: 'Managers',
    manageDesignVault: 'ManageDesignVault',
    settings: 'Settings',
};

export const editFormMap = new Map([
    [
        editFormComponent.description,
        {
            component: Description,
            title: 'Details',
        },
    ],
    [
        editFormComponent.history,
        {
            component: History,
            title: 'Description',
        },
    ],
    [
        editFormComponent.officePhoto,
        {
            component: OfficePhoto,
            title: 'Photo',
        },
    ],
    [
        editFormComponent.managers,
        {
            component: Managers,
            title: 'Managers',
        },
    ],
    [
        editFormComponent.manageDesignVault,
        {
            component: DesignVaultManage,
            title: 'Manage Design Vault',
        },
    ],
    [
        editFormComponent.settings,
        {
            component: Settings,
            title: 'Settings',
        },
    ],
]);

export const officeRegion = [
    { id: '5', name: 'Central' },
    { id: '1', name: 'East' },
    { id: '4', name: 'International' },
    { id: '3', name: 'Mountain' },
    { id: '2', name: 'West' },
];

export const minPhotoDim = {
    minWidth: 2000,
    minHeight: 1200,
};
