import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useDebounce } from '../../../../../../app/hooks';
import { Grid } from '@material-ui/core';
import { DrawerContainerProps } from '../../../salesTeamModels';
import { UserSearchResponse } from '../../../../../../shared/models/users/userModels';
import { SalesTeamAssistant } from '../../../salesTeamModels';
import {
    deleteSalesTeamAssistant,
    addSalesTeamAssistant,
    getUsers,
    setSearchedUsers,
} from '../../../salesTeamSlice';
import SearchAutoComplete from '../../../../../../shared/component/searchAutoComplete';

const Assistants: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const { data, flags, searchedUsers } = editFormData;
    const { searchingAssistants } = flags;
    const assistants = data.salesTeamAssistants as SalesTeamAssistant[];
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm !== '') dispatch(getUsers(debouncedSearchTerm));
    }, [dispatch, debouncedSearchTerm]);

    const addSelectedSalesTeamAssistant = (selectedUser: UserSearchResponse | null) => {
        if (selectedUser) {
            const salesTeamAssistant = {
                salesTeamId: data.id,
                brandEmail: selectedUser.brandEmail,
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                userId: selectedUser.userId,
            };
            dispatch(addSalesTeamAssistant(data.id ?? '', salesTeamAssistant));
            setSearchTerm('');
        }
        dispatch(setSearchedUsers([]));
    };

    const deleteAssistant = (salesTeamAssistantId?: string) => {
        if (salesTeamAssistantId) {
            dispatch(deleteSalesTeamAssistant(data.id ?? '', salesTeamAssistantId));
        }
    };

    const autoCompleteBlur = () => {
        dispatch(setSearchedUsers([]));
    };

    return (
        <Grid container className="add-assistant-container">
            <Grid
                item
                md={12}
                container
                className="content"
                alignItems="center"
                spacing={2}
            >
                <Grid item sm={2} md={2} className="formControlLabel">
                    EMAIL
                </Grid>
                <Grid item sm={10} md={10}>
                    <SearchAutoComplete
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        results={searchedUsers}
                        userSelected={addSelectedSalesTeamAssistant}
                        blurEvent={autoCompleteBlur}
                        isLoading={searchingAssistants}
                    />
                </Grid>
            </Grid>

            {assistants?.length ? (
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item container md={12} className="formControlLabel">
                        <Grid item md={4} sm={4}>
                            Email
                        </Grid>
                        <Grid item md={4} sm={4}>
                            Name
                        </Grid>
                    </Grid>
                    {assistants.map((assistant: SalesTeamAssistant) => (
                        <Grid item container key={assistant.id} md={12}>
                            <Grid item sm={4} md={4} className="formControlData">
                                {assistant.brandEmail}
                            </Grid>
                            <Grid item sm={4} md={4} className="formControlData">
                                {assistant.firstName} {assistant.lastName}
                            </Grid>
                            <Grid item sm={4} md={4}>
                                <button
                                    className="button customButton"
                                    onClick={() => deleteAssistant(assistant?.id)}
                                >
                                    Delete
                                </button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};
export default Assistants;
