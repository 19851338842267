import { getCall } from '../../utils/api/apiWrapper';
import { CmrReportDataLeftPanel, CmrReportDataRightPanel } from './cmrReportModel';

export function fetchCmrReportDataLeftPanel(
    listingNumber: string,
): Promise<CmrReportDataLeftPanel[]> {
    return getCall<CmrReportDataLeftPanel[]>(`clientmarketing/${listingNumber}/sections`);
}

export function fetchCmrReportDataRightPanel(
    listingNumber: string,
): Promise<CmrReportDataRightPanel> {
    return getCall<CmrReportDataRightPanel>(`clientfacing/${listingNumber}`);
}

export const api = '';
