import { FunctionComponent } from 'react';
import { OpenHouseData } from '../../../../features/cmr/cmrModels';
import FormTemplate from './features/FormTemplate';
import ListTemplate from './features/ListTemplate';

interface OpenHouseTemplateProps {
    useGa: boolean;
    sortedOpenHouses: OpenHouseData[] | null;
    activeEditId: string;
    deleteIt(id: string | undefined): void;
    editClickHandler(currentEditId: string | undefined): void;
    initialState: OpenHouseData;
    handleSaveClick(openHouseData: OpenHouseData): void;
    isCmr?: boolean;
}

const OpenHouseTemplate: FunctionComponent<OpenHouseTemplateProps> = ({
    useGa,
    sortedOpenHouses,
    activeEditId,
    deleteIt,
    editClickHandler,
    initialState,
    handleSaveClick,
    isCmr,
}) => {
    return (
        <div className="data-container">
            <FormTemplate
                useGa={useGa}
                formEdit={false}
                editClickHandler={editClickHandler}
                initialState={initialState}
                handleSaveClick={handleSaveClick}
                isCmr={isCmr}
            />
            <div className="table-heading">
                <div className="date heading-text">OH date</div>
                <div className="agent heading-text">OH agent</div>
                <div className="from heading-text time">from time</div>
                <div className="to heading-text to-time">to time</div>
                <div className="more heading-text large">show more</div>
                <div className="empty heading-text"></div>
            </div>
            <div className="openHouseContainer">
                {sortedOpenHouses && sortedOpenHouses.length > 0
                    ? sortedOpenHouses.map((oH: OpenHouseData, index: number) => (
                          <div className="table-data" key={'openHouse' + index}>
                              {activeEditId === oH.id ? (
                                  <FormTemplate
                                      useGa={useGa}
                                      formEdit={true}
                                      openHouseData={oH}
                                      initialState={initialState}
                                      editClickHandler={editClickHandler}
                                      handleSaveClick={handleSaveClick}
                                      isCmr={isCmr}
                                  />
                              ) : (
                                  <ListTemplate
                                      useGa={useGa}
                                      oH={oH}
                                      deleteIt={deleteIt}
                                      editClickHandler={editClickHandler}
                                  />
                              )}
                          </div>
                      ))
                    : ''}
            </div>
        </div>
    );
};

export default OpenHouseTemplate;
