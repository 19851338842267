import { LookupInfo } from '../../../shared/models/lookups/lookupsModels';

export interface ManageNotificationsState {
    /** List of notifications to bind to the drop down */
    notifications: LookupInfo[];

    /** Flag indicating if we should show the loader  */
    loading: boolean;

    /**
     * Notification with its assignments currently
     * being managed in the drawer
     **/
    notificationDetails: NotificationAssignmentDetails;

    /**
     * Notification with its assignments currently
     * being managed in the drawer but not updated based on user updated
     **/
    staticNotificationDetails?: NotificationAssignmentDetails;
}

/**
 * Contains details of the notification and the entities
 * assigned to it (offices, roles, users)
 */
export interface NotificationAssignmentDetails {
    /** The name of the notification */
    notificationName: string;

    /** The ID of the notification with the assignment  */
    notificationId: number;

    /** List of entities assigned to the notification */
    assignments: NotificationAssignment[];
}

/** Represents details of entities assigned to a notification */
export interface NotificationAssignment {
    /** The ID of the notification assignment record */
    id?: string;

    /** The name of the office the notification is assigned to */
    officeName?: string;

    /** The ID of the office the notification is assigned to */
    officeId?: string;

    /** The full name of the user the notification is assigned to */
    userName?: string;

    /** The profile ID of the user the notification is assigned to */
    profileId?: string;

    /** The name of the role the notification is assigned to */
    roleName?: string;

    /** The ID of the role the notification is assigned to */
    roleId?: string;

    /**
     * Flag indicating if the user, office, or role assigned to the
     * notification should receive an email
     */
    sendEmail: boolean;

    /**
     * Flag indicating if the user wants notification emails in a batch,
     * as opposed to real-time
     */
    sendConsolidatedEmail: boolean;
}

/** Props used for components that list chips to select */
export interface NotificationListProps {
    /** Details with the notification info and its assignments */
    notificationDetails: NotificationAssignmentDetails;

    /** Action to update state when assignment is added/removed */
    updateAssignment: (
        assignment: NotificationAssignment,
        added: boolean,
        key: keyof NotificationAssignment,
    ) => void;
}

export const initialNotificationDetails: NotificationAssignmentDetails = {
    assignments: [],
    notificationName: '',
    notificationId: 0,
};

export const notificationTypes = {
    mlsListingCreated: 1,
    mlsListingUpdated: 2,
    listingCommissionExport: 23,
};
