import { FunctionComponent, useState, useEffect, Fragment } from 'react';
import loDash from 'lodash';
import Header from '../../../shared/index';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { cmrDetails, deleteData, displayReportToggle } from '../../../cmrSlice';
import './index.scss';
import { SectionProps } from '../../../cmrModels';
import { getHeaderDetails } from '../util';
import ReadOnlyList from './components/ReadOnlyList';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import { MarketingData } from '../../../../../shared/models/marketing/marketingModels';
import CustomAdditionalPromotion from '../../../../../shared/component/marketing/additionalPromotion/CustomAdditionalPromotion';

const AdditionalPromotion: FunctionComponent<SectionProps> = (props) => {
    const { paneldata, sortOrder, listingdata } = useAppSelector(cmrDetails);
    const listingId = listingdata.id;

    const sortPromos = (data: MarketingData[]): MarketingData[] => {
        // TODO: Implement sortByDate DESC
        return loDash.sortBy(data, 'date').reverse();
    };
    const [sortedPromo, setSortedPromo] = useState(
        sortPromos(paneldata.additionalPromotion as MarketingData[]),
    );
    const [editID, setEditId] = useState('');

    useEffect(() => {
        setSortedPromo(sortPromos(paneldata.additionalPromotion as MarketingData[]));
    }, [paneldata.additionalPromotion]);

    const dispatch = useAppDispatch();
    const headerDetails = getHeaderDetails(props.sectionId, sortOrder);
    const deleteItem = (id: string) => {
        googleAnalyticsAction('Delete', 'Click', 'Additional Promotion Delete');
        const url = `listing/${listingId}/marketing/${id}`;
        dispatch(deleteData(url, props.sectionId, id, 'id'));
    };
    const onEdit = (addition: MarketingData) => {
        setEditId(addition.id || '');
        googleAnalyticsAction('Edit', 'Click', 'Additional Promotion Edit');
    };
    const cancelClick = () => {
        setEditId('');
    };

    return (
        <div className="additional-container">
            <Header
                title={headerDetails.displayName}
                isButtonActive={headerDetails.displaySection}
                data={headerDetails.id}
                section={props.sectionId}
                action={(id: string) => {
                    googleAnalyticsAction(
                        'Display on Report',
                        'Slide',
                        'Additional Promotion Display on Report',
                    );
                    dispatch(displayReportToggle(id));
                }}
            />
            <CustomAdditionalPromotion
                formEdit={false}
                cancelClick={cancelClick}
                sectionId={props.sectionId}
                listingId={listingId}
            />

            <div className="inner-container">
                {sortedPromo &&
                    sortedPromo.map((addition: MarketingData) => (
                        <Fragment key={'editData' + addition.id}>
                            {editID === addition.id ? (
                                <CustomAdditionalPromotion
                                    additionalPromotion={addition}
                                    formEdit={true}
                                    cancelClick={cancelClick}
                                    sectionId={props.sectionId}
                                    listingId={listingId}
                                />
                            ) : (
                                <ReadOnlyList
                                    addition={addition}
                                    onEdit={onEdit}
                                    deleteItem={deleteItem}
                                />
                            )}
                        </Fragment>
                    ))}
            </div>
        </div>
    );
};
export default AdditionalPromotion;
