import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
    DynamicListingResponse,
    ListingRequestUpdateModel,
    ListingListState,
    ListingsRequest,
    ListingsResponse,
    ListingResponseFrom,
    LoaderUpdate,
    SetListingList,
} from './listingListModels';
import Logger from '../../utils/logging/logger';
import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import {
    initialListingListConstants,
    listingStatusAndTab,
    tabs,
    getDefaultValue,
} from './listingListConstants';
import { getListingsFromApi, radioStatusUpdate } from './listingListApi';
import { ListingSyncFlag } from '../../shared/models/listing/syndicationModel';
import { initializeListingsRequest, getMyMarketOffices } from './utils';
import { User } from '../../shared/auth/authModels';
import {
    CommonLookups,
    OfficeLookupInfo,
} from '../../shared/models/lookups/lookupsModels';
import _ from 'lodash';

const listingListInitialState: ListingListState = {
    data: initialListingListConstants,
    activeTabState: -1,
    isLoading: false,
    subLoader: false,
    showMyMarket: false,
    showComingSoonMyListings: false,
};

export const listingsListSlice = createSlice({
    name: 'listingListApp',
    initialState: listingListInitialState,
    reducers: {
        updateBoolean: (state, action: PayloadAction<LoaderUpdate>) => {
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            };
        },
        sortDetailsUpdate: (state, action: PayloadAction<ListingsRequest>) => {
            const { sortColumn, sortDirection, status, businessPurposeTypeId } =
                action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [status]: {
                        ...state.data[status],
                        tableAction: {
                            ...state.data[status].tableAction,
                            sortColumn: sortColumn,
                            sortDirection: sortDirection,
                            businessPurposeTypeId: businessPurposeTypeId,
                        },
                    },
                },
            };
        },
        addListings: (state, action: PayloadAction<DynamicListingResponse>) => {
            const { type, apiResults } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        totalRecords: apiResults.totalRecords,
                        results:
                            apiResults.currentPage === 1
                                ? apiResults.results
                                : [...state.data[type].results, ...apiResults.results],
                        tableAction: {
                            ...state.data[type].tableAction,
                            currentPage: apiResults.currentPage,
                            itemsPerPage: apiResults.recordsPerPage,
                        },
                    },
                },
            };
        },
        //to update listing list if action happend on the list or grid
        updateListingDetails: (state, action: PayloadAction<ListingResponseFrom>) => {
            const { listing, from } = action.payload;
            const list = state.data[from].results;
            if (list) {
                const findIndex = list.findIndex(
                    (x) => x.listingId === listing.listingId,
                );
                list[findIndex] = listing;
                state.data[from].results = list;
            }
        },
        //to set listing list if action happend on the list or grid
        setListingList: (state, action: PayloadAction<SetListingList>) => {
            const { list, from } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [from as keyof typeof state.data]: {
                        ...state.data[from as keyof typeof state.data],
                        results: list,
                    },
                },
            };
        },
        setActiveTabState: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                activeTabState: action.payload,
            };
        },
        //to update the list when action happens inside the listing details page
        updateActiveTabListingList: (
            state,
            action: PayloadAction<Partial<ListingRequestUpdateModel> | ListingSyncFlag>,
        ) => {
            //if the listing is found in the active tab
            if (tabs[state.activeTabState]) {
                const { results } = state.data[tabs[state.activeTabState]];
                const { listingId } = action.payload;
                const findIndex = results.findIndex((x) => x.listingId === listingId);
                if (findIndex !== -1) {
                    const newValue = {
                        ...results[findIndex],
                        ...action.payload,
                    };
                    state.data[tabs[state.activeTabState]].results[findIndex] = newValue;
                } else {
                    //if the listing is not found in the active tab, look into other tab and then update the data
                    _.map(state.data, (types) => {
                        const findIndex = types.results.findIndex(
                            (x) => x.listingId === listingId,
                        );
                        const find = types.results.find((x) => x.listingId === listingId);
                        if (findIndex !== -1 && find) {
                            const newTab = listingStatusAndTab.find(
                                (data) =>
                                    data.text.toLowerCase() === find.status.toLowerCase(),
                            );
                            if (newTab) {
                                const newValue = {
                                    ...find,
                                    ...action.payload,
                                };
                                state.data[tabs[newTab.tabId]].results[findIndex] =
                                    newValue;
                            }
                        }
                    });
                }
            }
        },
        resetTabDataInListingList: (state, action: PayloadAction<string>) => {
            const tabValue = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [tabValue]: getDefaultValue(tabValue),
                },
            };
        },
    },
});

const getAllOfficesFromSalesRegion = (
    offices: OfficeLookupInfo[],
    officeIds?: string[],
) => {
    const salesRegionList = offices
        .filter((x) => officeIds?.includes(x.id))
        .map((data) => {
            return data.salesRegionId;
        });
    return offices
        .filter((x) => salesRegionList?.includes(x.salesRegionId))
        .map((data) => {
            return data.id;
        });
};

const getStatus = (status: string) => {
    switch (status) {
        case 'temp Off-Market':
            return 'offMarket';
        case 'coming Soon':
            return 'comingSoon';
        case 'private':
            return 'privateListing';
        case 'my Listings':
            return 'comingSoonMyListing';
        case 'my Market':
            return 'myMarket';
        default:
            return status;
    }
};

export const getListings =
    (
        listingsRequest: ListingsRequest,
        isUserAgent?: boolean,
        holdSubTab?: boolean,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateBoolean({
                    field: `${
                        listingsRequest.status === 'my Listings' ||
                        listingsRequest.status === 'my Market'
                            ? 'subLoader'
                            : 'isLoading'
                    }`,
                    value: true,
                }),
            );
            const status = listingsRequest.status;
            const { offices } = getState().lookups.common as CommonLookups;
            let requestData = {
                ...listingsRequest,
                status: [status[0].toUpperCase() + status.slice(1)],
            };
            /**
             * do not set agent id for coming soon, thus we can see coming soon from
             * all the agents from the sales region
             */
            if (
                requestData.status.length === 1 &&
                requestData.status.includes('Coming Soon')
            ) {
                if (requestData.officeIds && requestData.officeIds?.length > 0) {
                    requestData = {
                        ...requestData,
                        officeIds: [
                            ...getAllOfficesFromSalesRegion(
                                offices,
                                requestData.officeIds,
                            ),
                        ],
                    };
                }
                requestData = { ...requestData, isComingSoonSearch: true };
            }
            // agent's coming soon listings
            else if (
                requestData.status.length === 1 &&
                requestData.status.includes('My Listings')
            ) {
                dispatch(
                    updateBoolean({
                        field: 'subLoader',
                        value: true,
                    }),
                );
                if (requestData.officeIds && requestData.officeIds?.length > 0) {
                    requestData = {
                        ...requestData,
                        officeIds: [
                            ...getAllOfficesFromSalesRegion(
                                offices,
                                requestData.officeIds,
                            ),
                        ],
                    };
                }
                if (isUserAgent && requestData.status.includes('My Listings')) {
                    requestData = {
                        ...requestData,
                        itemsPerPage: 100,
                        status: ['Coming Soon'],
                    };
                }
            } else if (
                requestData.status.length === 1 &&
                (requestData.status.includes('Private') ||
                    requestData.status.includes('My Market'))
            ) {
                let requiredOffices: string[] = [];
                const myMarketOffices = getMyMarketOffices(offices);
                if (myMarketOffices.length) {
                    if (requestData.status.includes('My Market')) {
                        requiredOffices = myMarketOffices;
                    } else {
                        requiredOffices = offices
                            .filter((x) => !myMarketOffices.includes(x.id))
                            .map((data) => data.id);
                    }
                    requestData = {
                        ...requestData,
                        isMarketSearch: true,
                        officeIds: requiredOffices,
                        status: ['Private'],
                    };
                    dispatch(
                        updateBoolean({
                            field: 'subLoader',
                            value: true,
                        }),
                    );
                } else {
                    requestData = {
                        ...requestData,
                        officeIds: offices
                            .filter((o) => o.regionId !== 7)
                            .map((o) => o.id),
                        isMarketSearch: true,
                        // for individual call, no need to send office ids if below field is included
                        // ignoreSecurity: true,
                    };
                }
            }
            const response = await getListingsFromApi(requestData);
            if (response) {
                const statusUpdate = getStatus(status);
                listingsRequest.status = statusUpdate;
                dispatch(sortDetailsUpdate(listingsRequest));
                const data = {
                    type: listingsRequest.status,
                    apiResults: response,
                };
                dispatch(addListings(data));
                if (
                    holdSubTab &&
                    (requestData.status.includes('Coming Soon') ||
                        requestData.status.includes('Private')) &&
                    response.results.length
                ) {
                    dispatch(
                        updateBoolean({
                            field: requestData.status.includes('Private')
                                ? 'showMyMarket'
                                : 'showComingSoonMyListings',
                            value: true,
                        }),
                    );
                }
            }
        } catch (e) {
            Logger.error('Failed to fetch the listings: ' + e);
            dispatch(setError(`Failed to fetch the listings`));
        } finally {
            dispatch(
                updateBoolean({
                    field: 'isLoading',
                    value: false,
                }),
            );
            dispatch(
                updateBoolean({
                    field: 'subLoader',
                    value: false,
                }),
            );
        }
    };

export const listingPublish =
    (listing: ListingsResponse, status: boolean, from: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateBoolean({
                    field: 'isLoading',
                    value: true,
                }),
            );
            await radioStatusUpdate(listing.listingId, 'publish', status);
            const newListing = { ...listing, publish: status };
            const data = {
                listing: newListing,
                from: from,
            };
            dispatch(updateListingDetails(data));
            dispatch(setSuccess(`Updated Successfully`));
        } catch (e) {
            Logger.error('Failed to update the listings publish status' + e);
            dispatch(setError(`Failed to update the listings publish status`));
        } finally {
            dispatch(
                updateBoolean({
                    field: 'isLoading',
                    value: false,
                }),
            );
        }
    };

export const listingOptionUpdate =
    (
        listing: ListingsResponse,
        name: string,
        status: boolean,
        from: string,
        agentId?: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                updateBoolean({
                    field: 'isLoading',
                    value: true,
                }),
            );
            await radioStatusUpdate(listing.listingId, name, status, agentId);
            const newListing = { ...listing, [name as keyof typeof listing]: status };
            const data = {
                listing: newListing,
                from: from,
            };
            dispatch(updateListingDetails(data));
            dispatch(setSuccess(`Updated Successfully`));
        } catch (e) {
            Logger.error('Failed to update the listings options' + e);
            dispatch(setError(`Failed to update the listings options`));
        } finally {
            dispatch(
                updateBoolean({
                    field: 'isLoading',
                    value: false,
                }),
            );
        }
    };

export const getActiveTabListing =
    (listingId: string, key: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const activeTabState = getState().listing.listingList.activeTabState;
            if (activeTabState !== -1) {
                const list =
                    getState().listing.listingList.data[tabs[activeTabState]].results;

                const findIndex = list.findIndex((x) => x.listingId === listingId);
                const data = list[findIndex];
                const updatedListing = {
                    ...data,
                    [key as keyof typeof data]: !data[key as keyof typeof data],
                };
                const toSave = {
                    listing: updatedListing,
                    from: tabs[activeTabState],
                };
                dispatch(updateListingDetails(toSave));
            }
        } catch (e) {
            Logger.error('Cannot fetch listing from active tab' + e);
        } finally {
        }
    };

/**
 * to refresh the tab to get updated list - implemented only for draft now
 * @param user
 * @returns
 */
export const refreshActiveTab =
    (
        user: User,
        listingId: string,
        oldListingStatus: number | null,
        newListingStatus: number | null,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            //find the tab details from the const
            const oldTab = listingStatusAndTab.find(
                (data) => data.statusId === oldListingStatus,
            );
            const newTab = listingStatusAndTab.find(
                (data) => data.statusId === newListingStatus,
            );
            if (oldTab && newTab) {
                //get listing list data
                const listingsList = getState().listing.listingList.data;
                const oldTabList = listingsList[tabs[oldTab?.tabId]];
                if (oldTabList.results.length > 0) {
                    //remove the data from the current list the listing belongs too
                    const filteredList = oldTabList.results.filter(
                        (data) => data.listingId !== listingId,
                    );
                    const data = {
                        type: tabs[oldTab?.tabId],
                        apiResults: {
                            currentPage: oldTabList.tableAction.currentPage,
                            recordsPerPage: oldTabList.tableAction.itemsPerPage,
                            totalRecords: oldTabList.totalRecords - 1,
                            results: filteredList.length ? filteredList : [],
                        },
                    };
                    dispatch(addListings(data));
                    //make the api call to update the listing for the status to which the listing is moved
                    // const updatedListing = oldTabList.results.filter(
                    //     (data) => data.listingId === listingId,
                    // );
                    // const newTabList = listingsList[tabs[newTab?.tabId]];
                    // const newData = {
                    //     type: tabs[newTab?.tabId],
                    //     apiResults: {
                    //         currentPage: newTabList.tableAction.currentPage,
                    //         recordsPerPage: newTabList.tableAction.itemsPerPage,
                    //         totalRecords: newTabList.totalRecords + 1,
                    //         results: [...updatedListing, ...newTabList.results],
                    //     },
                    // };
                    // dispatch(addListings(newData));
                    const listingsRequest = initializeListingsRequest(
                        listingsList[tabs[newTab?.tabId]].tableAction.sortColumn,
                        listingsList[tabs[newTab?.tabId]].tableAction.sortDirection,
                        1,
                        listingsList[tabs[newTab?.tabId]].tableAction.itemsPerPage,
                        listingsList[tabs[newTab?.tabId]].tableAction.status,
                        user,
                        listingsList[tabs[newTab?.tabId]].tableAction
                            .businessPurposeTypeId,
                    );
                    //timeout is added to wait for the
                    setTimeout(() => {
                        dispatch(getListings(listingsRequest));
                    }, 3000);
                }
            }
        } catch (e) {
            Logger.error('Cannot fetch listing from active tab' + e);
        } finally {
        }
    };

export const {
    updateBoolean,
    addListings,
    sortDetailsUpdate,
    updateListingDetails,
    setActiveTabState,
    updateActiveTabListingList,
    resetTabDataInListingList,
    setListingList,
} = listingsListSlice.actions;

export const listingsList = (state: RootState): ListingListState =>
    state.listing.listingList;

export default listingsListSlice.reducer;
