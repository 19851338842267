import { AppThunk } from '../../../../../../app/store';
import { OfficeDetailEvents } from '../../../../../../shared/models/loader/loaderModels';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { saveManager, deleteManager } from './managerApi';
import { ManagerData } from './managerModels';
import { updateEditAction, updateOfficeState } from '../../../officeSlice';

export const addManager =
    (officeId: string, managerData: ManagerData): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(OfficeDetailEvents.OFFICE_DETAILS_ADD));
            const response = await saveManager(officeId, {
                agentId: managerData.agentId,
                title: managerData.title,
            });
            if (response) {
                const currentOfficeDetails = getState().office.details.data.officeDetails;
                const updatedOfficeDetails = {
                    ...currentOfficeDetails,
                    managerName: managerData.managerName,
                    managerTitle: managerData.title,
                    officeName: response.officeName,
                };
                await dispatch(
                    updateOfficeState({
                        property: 'officeDetails',
                        value: updatedOfficeDetails,
                    }),
                );
                dispatch(updateEditAction(true));
                dispatch(setSuccess('Manager assigned'));
            }
        } catch {
            Logger.error('Error saving manager');
            dispatch(setError('Error saving manager'));
        } finally {
            dispatch(removeLoadingEvent(OfficeDetailEvents.OFFICE_DETAILS_ADD));
        }
    };

export const removeManager =
    (officeId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(OfficeDetailEvents.OFFICE_DETAILS_DELETE));
            const response = await deleteManager(officeId);
            if (response.status === 204) {
                const currentOfficeDetails = getState().office.details.data.officeDetails;
                const updatedOfficeDetails = {
                    ...currentOfficeDetails,
                    managerName: null,
                    managerTitle: null,
                };
                await dispatch(
                    updateOfficeState({
                        property: 'officeDetails',
                        value: updatedOfficeDetails,
                    }),
                );
                dispatch(updateEditAction(true));
                dispatch(setSuccess('Manager removed'));
            }
        } catch {
            Logger.error('Failed to remove manager');
            dispatch(setError('Failed to remove manager'));
        } finally {
            dispatch(removeLoadingEvent(OfficeDetailEvents.OFFICE_DETAILS_DELETE));
        }
    };
