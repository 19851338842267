import { OwnerInfoModel } from '../../../../../../shared/models/ownerInfoModel/ownerInfoModel';
import { getCall, postCall } from '../../../../../../utils/api/apiWrapper';

/**
 * READ owner information
 * @param listingId Id of the listing
 * @returns Owner information object
 */
export function getOwnerInfoApi(listingId: string): Promise<OwnerInfoModel[]> {
    return getCall<OwnerInfoModel[]>(`listing/${listingId}/owner`);
}

/**
 * CREATE / UPDATE owner information
 * @param listingId Id of the listing
 * @param ownerInfo Owner information object
 */
export function updateOwnerInfoApi(
    listingId: string,
    ownerInfo: OwnerInfoModel[],
): Promise<OwnerInfoModel[]> {
    return postCall<OwnerInfoModel[], OwnerInfoModel[]>(
        `listing/${listingId}/owner`,
        ownerInfo,
    );
}
