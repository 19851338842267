import winston, { LoggerOptions } from 'winston';
import WinstonCloudWatch, { CloudwatchTransportOptions } from 'winston-cloudwatch';

const generateStreamName = () => {
    const date = new Date().toISOString().split('T')[0];
    return `zeus-${date}`;
};

const cloudwatchOptions: CloudwatchTransportOptions = {
    logGroupName: process.env.REACT_APP_LOG_GROUP_NAME,
    logStreamName: generateStreamName(),
    awsRegion: 'us-east-1',
    jsonMessage: true,
    awsAccessKeyId: process.env.REACT_APP_ACCESS_KEY,
    awsSecretKey: process.env.REACT_APP_SECRET_KEY,
    level: 'debug',
};
const transports = [
    new winston.transports.Console(),
    new WinstonCloudWatch(cloudwatchOptions),
];

const loggerOptions: LoggerOptions = {
    level: 'debug',
    transports: transports,
};

const Logger = winston.createLogger(loggerOptions);

export default Logger;
