import { Input, MenuItem, Select } from '@material-ui/core';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import {
    MarketingData,
    MarketingTypes,
    marketingTypeIds,
} from '../../../models/marketing/marketingModels';
import { useAppDispatch, useAppSelector, useFormData } from '../../../../app/hooks';
import {
    cmrDetails,
    deleteMarketingFile,
    getTypes,
    saveAdditionalPromotion,
} from '../../../../features/cmr/cmrSlice';
import { useFileManagement } from '../../../marketing/hooks';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';
import { datePickerFormat } from '../../../../utils/urlUtils';

interface CustomAdditionalPromotionProps {
    /* Additional promotion data passed from parent component during edit */
    additionalPromotion?: MarketingData;
    /* Emit function when cancel button is clicked */
    cancelClick(): void;
    /* Indicates whether the form is in edit mode */
    formEdit?: boolean;
    /* Guid of the listing */
    listingId?: string;
    /* If defined, use it for CMR, otherwise for property marketing */
    sectionId?: number;
}

const initialPromotion: MarketingData = {
    id: '',
    date: '',
    description: '',
    isFile: false,
    file: undefined,
    url: '',
    marketingTypeId: marketingTypeIds.additionalPromotion,
};

const usePlaceholderStyles = makeStyles(() => ({
    placeholder: {
        color: '#777',
    },
}));

const Placeholder = ({ children }: { children: string }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const CustomAdditionalPromotion: FunctionComponent<CustomAdditionalPromotionProps> = ({
    additionalPromotion,
    cancelClick,
    formEdit,
    listingId,
    sectionId,
}) => {
    const { additionalPromotionTypes } = useAppSelector(cmrDetails);
    const { formData, setFormData, handleTextChange, handleDateChange, removeSpaces } =
        useFormData(additionalPromotion || initialPromotion);
    const {
        fileData,
        setFileData,
        handleFileUpload,
        handleUrlChange,
        ensureHttp,
        handleFileClear,
    } = useFileManagement(formData);
    const [active, setActive] = useState(true);
    const dispatch = useAppDispatch();

    const deleteFile = () => {
        dispatch(deleteMarketingFile(formData, sectionId));
    };

    useEffect(() => {
        setFormData(formData);
        setFileData(fileData);
        const { additionalPromotionId, date, description } = formData;
        setActive(
            !additionalPromotionId ||
                additionalPromotionId === 0 ||
                date === '' ||
                description === '',
        );
        if (additionalPromotionTypes.length === 0) dispatch(getTypes());
    }, [
        formData,
        fileData,
        setFileData,
        setFormData,
        additionalPromotionTypes.length,
        dispatch,
    ]);

    const handleTypeChange = (
        event: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        const { value } = event.target;
        const numVal = parseInt(String(value));
        const selectedType = additionalPromotionTypes.find((a) => a.id === numVal);
        setFormData({
            ...formData,
            type: selectedType?.name,
            additionalPromotionId: numVal,
        });
    };

    const save = (e: MouseEvent<HTMLButtonElement>) => {
        const saveRequest = { ...formData, ...fileData };
        if (formData.date !== '' && formData.description !== '')
            dispatch(
                saveAdditionalPromotion(
                    listingId || '',
                    saveRequest,
                    sectionId ? true : false,
                ),
            );
        setFormData(initialPromotion);
        handleFileClear(e, false);
        if (sectionId) {
            if (!formEdit)
                googleAnalyticsAction('Add', 'Click', 'Additional Promotion Add');
            else googleAnalyticsAction('Edit', 'Click', 'Additional Promotion Edit Save');
        }
        cancelClick();
    };

    return (
        <div>
            {formEdit && sectionId ? <div className="overlayEditForm" /> : ''}
            <div className={formEdit ? 'editPromotion' : 'additionPromotion'}>
                <div className={formEdit ? 'edit' : 'add'}>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <DatePicker
                            value={
                                formData.date && formData.date !== ''
                                    ? moment(formData.date, 'YYYY-MM-DDTHH:mm:ss')
                                    : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('date', date);
                            }}
                            name="date"
                            placeholder="Date"
                            className={
                                formEdit
                                    ? 'datePickerCommon cmrEditDate'
                                    : 'datePickerCommon cmrDate'
                            }
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Select
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            label="Type"
                            name="additionalPromotionId"
                            placeholder="Type"
                            displayEmpty
                            value={formData?.additionalPromotionId || ''}
                            onChange={handleTypeChange}
                            inputProps={{ 'aria-label': 'Type' }}
                            renderValue={
                                formData.additionalPromotionId &&
                                formData.additionalPromotionId !== 0
                                    ? undefined
                                    : () => <Placeholder>Type</Placeholder>
                            }
                            disableUnderline={sectionId ? false : true}
                        >
                            {additionalPromotionTypes.map((option: MarketingTypes) => (
                                <MenuItem
                                    style={{ fontFamily: 'MercuryTextG4Roman' }}
                                    key={'add' + option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            placeholder="Description"
                            name="description"
                            value={formData?.description || ''}
                            onChange={(e) => handleTextChange(e, 255)}
                            onBlur={(e) => removeSpaces(e, 255)}
                            inputProps={{ 'aria-label': 'Description' }}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span className={formEdit ? 'editActionHolder' : 'actionHolder'}>
                        <Input
                            className={formEdit ? 'cmrEditInput' : 'cmrInput'}
                            placeholder="Url"
                            name="url"
                            onChange={handleUrlChange}
                            onBlur={ensureHttp}
                            inputProps={{ 'aria-label': 'Url' }}
                            value={fileData.url || ''}
                            disableUnderline={sectionId ? false : true}
                        />
                    </span>
                    <span
                        className={
                            formEdit
                                ? 'editActionHolder apFileHolder'
                                : 'actionHolder apFileHolder'
                        }
                    >
                        {!fileData.file && !fileData.isFile ? (
                            <label className="apFile">
                                Upload File
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                                <Input
                                    className={
                                        formEdit ? 'cmrEditInput file' : 'cmrInput file'
                                    }
                                    name="fileUpload"
                                    inputProps={{
                                        'aria-label': 'File Upload',
                                        accept: '.jpg,.png,.pdf',
                                    }}
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                            </label>
                        ) : (
                            <label className="apFile">
                                <button
                                    onClick={(e) =>
                                        handleFileClear(e, formEdit || false, deleteFile)
                                    }
                                    className="removeFile"
                                >
                                    Remove File
                                </button>
                                <FontAwesomeIcon className="fileIcon" icon={faFileAlt} />
                            </label>
                        )}

                        <span className="spec">
                            File Format: JPG, PNG, PDF | File Size: 50MB
                        </span>
                    </span>
                </div>
                {!formEdit ? (
                    <div className="addButtonHolder">
                        <button
                            className={`addButton ${active ? 'active' : 'unActive'}`}
                            onClick={save}
                            disabled={active ? true : false}
                        >
                            Add
                        </button>
                    </div>
                ) : (
                    <div className={formEdit ? 'editButtonHolder' : ''}>
                        <button className="actionButton cancel" onClick={cancelClick}>
                            Cancel
                        </button>
                        <button
                            disabled={active ? true : false}
                            className={
                                !active
                                    ? 'actionButton saveActive'
                                    : 'actionButton saveInActive'
                            }
                            onClick={save}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomAdditionalPromotion;
