import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { postCall } from '../../utils/api/apiWrapper';
import { AgentSearchRequest, AgentsResponse } from './agentListModels';

export function getAgentsFromApi(
    agentsRequest: AgentSearchRequest,
): Promise<PaginationResponse<AgentsResponse>> {
    return postCall<AgentSearchRequest, PaginationResponse<AgentsResponse>>(
        `agent/search`,
        agentsRequest,
    );
}
