import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { setError, setSuccess } from '../../shared/slices/messaging/messagingSlice';
import Logger from '../../utils/logging/logger';
import { jsonToFormData } from '../../utils/urlUtils';
import {
    deletApp,
    getAllAppsApi,
    getCCMAAppDetails,
    saveApp,
    updateAppsCanShow,
    updatePromotedApps,
    getApp,
    setFavouriteApp,
    removeFavouriteApp,
} from './applicationsApi';
import {
    AppsModel,
    ApplicationsState,
    SaveAppModel,
    SaveAppResModel,
    ToggleUpdateModel,
    AppsRequestModel,
    CCMAAppModel,
    IndividualAppData,
    AddAppsModel,
    ApplicationResponse,
    ApplicationSearchModel,
} from './applicationsModel';

const applicationsState: ApplicationsState = {
    apps: [],
    ccma: [],
    individualApp: null,
    currentAppData: null,
    isLoading: true,
    searchData: {
        applicationName: undefined,
    },
};

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState: applicationsState,
    reducers: {
        getAllApps: (state, action: PayloadAction<AppsModel[]>) => {
            return {
                ...state,
                apps: action.payload,
            };
        },
        ccmaAppDetails: (state, action: PayloadAction<CCMAAppModel[]>) => {
            return {
                ...state,
                ccma: action.payload,
            };
        },
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        addApps: (
            state,
            action: PayloadAction<{ data: SaveAppResModel[]; isEdit: boolean }>,
        ) => {
            let updatedApps = [];
            if (action.payload.isEdit) {
                updatedApps = state.apps.map((item) =>
                    item.id === action.payload.data[0].id
                        ? { ...item, ...action.payload.data[0] }
                        : item,
                );
            } else {
                updatedApps = [...state.apps, ...action.payload.data];
            }
            return {
                ...state,
                apps: updatedApps,
            };
        },
        deleteApps: (state, action: PayloadAction<string>) => {
            const updatedApps = state.apps.filter((data) => data.id !== action.payload);
            return {
                ...state,
                apps: updatedApps,
            };
        },
        updateAppToggleStatus: (state, action: PayloadAction<ToggleUpdateModel>) => {
            const { value, id, from } = action.payload;
            const selectedAppsIndex = state.apps.findIndex((data) => data.id === id);
            let selectedApps = state.apps[selectedAppsIndex];
            if (selectedAppsIndex !== -1 && from === 'promoted') {
                selectedApps = { ...selectedApps, promoted: value };
            } else if (selectedAppsIndex !== -1 && from === 'canShow') {
                selectedApps = { ...selectedApps, canShow: value };
            } else if (selectedAppsIndex !== -1 && from === 'favourite') {
                selectedApps = { ...selectedApps, isFavourite: value };
            }
            state.apps[selectedAppsIndex] = { ...selectedApps };
        },
        setIndividualAppData: (state, action: PayloadAction<IndividualAppData>) => {
            return {
                ...state,
                [action.payload.from]: action.payload.data,
            };
        },
        updateSearchText: (state, action: PayloadAction<ApplicationSearchModel>) => {
            return {
                ...state,
                searchData: action.payload,
            };
        },
    },
});

export const getAllApplications =
    (data: AppsRequestModel): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getAllAppsApi(data);
            if (response) {
                dispatch(getAllApps(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to get the application list`));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const saveApplications =
    (formData: SaveAppModel): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const { officeIds, roleIds, ...data } = formData;
            let jsonData;
            if (formData.id && typeof data.file === "string") {
                const { file, ...restData } = data;
                jsonData = jsonToFormData({ ...restData, logo: file });
            } else {
                jsonData = jsonToFormData(data);
            }
            jsonData.append('officeDetails', JSON.stringify(formData.officeIds));
            jsonData.append('roleDetails', JSON.stringify(formData.roleIds));
            const response: SaveAppResModel = await saveApp(jsonData);
            if (response) {
                const appDetail = [
                    {
                        id: response.id,
                        name: data.name,
                        link: data.link,
                        acronym: data.acronym,
                        logo: response.logo,
                        promoted: data.promoted,
                        canShow: data.canShow,
                        isCloudCma: false,
                    },
                ];
                dispatch(
                    addApps({ data: appDetail, isEdit: formData.id ? true : false }),
                );
                dispatch(
                    setSuccess(
                        `Application ${formData.id ? 'updated' : 'added'} successfully`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to ${formData.id ? 'update' : 'add'} application`));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const deleteApplications =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            if (window.confirm(`Are you sure you want to delete this App?`)) {
                const response = await deletApp(id);
                if (response) {
                    dispatch(deleteApps(id));
                    dispatch(setSuccess(`App deleted`));
                }
            }
        } catch (exception) {
            dispatch(setError(`Failed to a delete the app`));
        }
    };

export const togglePromotedAps =
    (id: string, status: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await updatePromotedApps(id);
            if (response.status === 204) {
                const data = {
                    id: id,
                    from: 'promoted',
                    value: status,
                };
                dispatch(updateAppToggleStatus(data));
                dispatch(
                    setSuccess(
                        `Selected App is ${
                            status ? `choosen as` : `removed from`
                        } a Promoted App`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to a update app promotion status`));
            Logger.error(
                `Failed to a update app promotion status ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const toggleCanshowAps =
    (id: string, status: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await updateAppsCanShow(id);
            if (response.status === 204) {
                const data = {
                    id: id,
                    from: 'canShow',
                    value: status,
                };
                dispatch(updateAppToggleStatus(data));
                dispatch(
                    setSuccess(
                        `Selected App is will be ${
                            status ? `visible` : `not visible`
                        } for all`,
                    ),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to a update app show/no show status`));
            Logger.error(
                `Failed to a update app promotion status ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const getCCMAAppsDetails = (): AppThunk => async (dispatch) => {
    try {
        const response = await getCCMAAppDetails();
        if (response) {
            dispatch(ccmaAppDetails(response));
        }
    } catch (exception) {
        dispatch(setError(`Failed to get fetch the CCMA app details`));
    } finally {
        dispatch(updateLoader(false));
    }
};

export const getAppById =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getApp(id);
            if (response) {
                const individualApp = {
                    id: response.id,
                    name: response.name,
                    link: response.link,
                    acronym: response.acronym,
                    promoted: response.promoted,
                    isFile: !!response.logo,
                    file: response.logo as unknown as File,
                    canShow: response.canShow,
                    officeIds: response.officeIds,
                    roleIds: response.roleIds,
                    isCloudCma: false,
                };
                dispatch(
                    setIndividualAppData({ from: 'individualApp', data: individualApp }),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to get application details`));
            Logger.error(
                `Failed to get application details ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const updateFavStatus =
    (appId: string, profileId: string, action: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            let response: Response;
            if (action === 'add') {
                response = await setFavouriteApp(appId, profileId);
            } else {
                response = await removeFavouriteApp(appId, profileId);
            }
            if (response) {
                if (action === 'add') {
                    dispatch(
                        setSuccess(`Successfully set the selected app as favorite`),
                    );
                } else {
                    dispatch(
                        setSuccess(`Successfully removed the selected app as favorite`),
                    );
                }
                const data = {
                    id: appId,
                    from: 'favourite',
                    value: action === 'add' ? true : false,
                };
                dispatch(updateAppToggleStatus(data));
            }
        } catch (exception) {
            dispatch(setError(`Failed to set / remove application as favorite`));
            Logger.error(
                `Failed to set / remove application as favorite ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const {
    getAllApps,
    updateLoader,
    addApps,
    deleteApps,
    updateAppToggleStatus,
    ccmaAppDetails,
    setIndividualAppData,
    updateSearchText,
} = applicationsSlice.actions;

export const applications = (state: RootState): ApplicationsState => state.applications;

export default applicationsSlice.reducer;
