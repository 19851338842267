import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../agentModels';
import SelectSale from '../../../shared/selectSale/SelectSale';

const SelectSolds: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    return (
        <div className="select-sales-container">
            <SelectSale businessPurposeTypeId={1} stateData={editFormData} />
        </div>
    );
};

export default SelectSolds;
