export function storeData<T>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
}

export function getData(key: string): string {
    return localStorage.getItem(key) || '';
}

export function removeData(key: string): void {
    localStorage.removeItem(key);
}

export function storeDataAsString(key: string, data: string): void {
    localStorage.setItem(key, data);
}
