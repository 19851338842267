import { TableHeadCell } from '../../../../../../../../../../shared/models/customTable/customTableModels';
import {
    AssignListingSearchRequestModel,
    AssignListingToSpotSearchRequest,
} from '../../../../spotTypeModels';

export const initialAssignListing: AssignListingToSpotSearchRequest = {
    currentPage: 1,
    itemsPerPage: 20,
    agentName: '',
    address1: '',
    businessPurposeTypeId: '1',
    listingNumber: '',
    officeId: '',
    officeName: '',
    openHouseStartDate: '',
    openHouseEndDate: '',
    status: '',
    currentStartPrice: '',
    currentEndPrice: '',
    sortColumn: 'ListingNumber',
    sortDirection: 'asc',
    officeIds: [],
};

export const spotTypeListingsTableHeadCells: TableHeadCell[] = [
    {
        id: 'isValid',
        label: 'Data Complete',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'isValid',
    },
    {
        id: 'publish',
        label: 'Publish',
        dbColMapping: '',
        doSort: false,
        customIcon: true,
        actionType: 'publish',
    },
    {
        id: 'address1',
        label: 'Address',
        dbColMapping: 'address1',
        doSort: true,
    },
    {
        id: 'mlsNumber',
        label: 'MLS Number',
        dbColMapping: 'mlsNumber',
        doSort: true,
    },
    {
        id: 'status',
        label: 'Status',
        dbColMapping: 'status',
        doSort: true,
    },
    {
        id: 'businessPurposeTypeId',
        label: 'Listing Type',
        dbColMapping: 'businessPurposeTypeId',
        doSort: true,
    },
    {
        id: 'currentPrice',
        label: 'Price',
        dbColMapping: 'CurrentPrice',
        isPrice: true,
        doSort: true,
    },
    {
        id: 'officeName',
        label: 'Office Name',
        dbColMapping: 'OfficeName',
        doSort: true,
    },
    {
        id: 'agentName',
        label: 'Agent Name',
        dbColMapping: '',
        doSort: false,
    },
    {
        id: 'lastAdvertised',
        label: 'Last Advertised',
        dbColMapping: 'lastAdvertised',
        doSort: true,
        isDate: true,
    },
    {
        id: 'listingId',
        label: '',
        dbColMapping: '',
        customIcon: true,
        actionType: 'assignListingButton',
        doSort: false,
    },
];

export const AgentEntityId = 4;
export const OfficeEntityId = 3;

export const initialAssignListingSearch: AssignListingSearchRequestModel = {
    currentPage: 1,
    itemsPerPage: 20,
    agentName: '',
    address1: '',
    agentIds: [],
    officeIds: [],
    status: ['Active', 'Pending'],
    currentStartPrice: '',
    currentEndPrice: '',
    openHouseStartDate: '',
    openHouseEndDate: '',
    businessPurposeTypeId: '1',
    mlsNumber: '',
    rfgid: '',
};
