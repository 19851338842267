import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './header';
import Content from './content';
import './cmr.scss';
import { useAppDispatch } from '../../app/hooks';
import { sortingOrderByListingId } from './cmrSlice';
import { CmrDetailsProps } from './cmrModels';
import Footer from '../../shared/component/footer/Footer';

const Cmr: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { listingId } = useParams<CmrDetailsProps>();

    useEffect(() => {
        if (listingId) {
            dispatch(sortingOrderByListingId(listingId));
        }
    }, [listingId, dispatch]);

    return (
        <>
            <div className="cmrMain">
                <Header />
                <Content />
            </div>
            <Footer />
        </>
    );
};

export default Cmr;
