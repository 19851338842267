import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { Grid, GridSize, TextField } from '@material-ui/core';
import FormRow from '../../../../../../../shared/component/formFields/FormRow';
import DropDownSearch from '../../../../../../../shared/component/formFields/DropDownSearch';
import { LookupInfo } from '../../../../../../../shared/models/lookups/lookupsModels';
import RadioButtonToggle from '../../../../../../../shared/component/formFields/RadioButtonToggle';
import { useAppDispatch } from '../../../../../../../app/hooks';
import { setError } from '../../../../../../../shared/slices/messaging/messagingSlice';
import { currencyFormat, findIdFromLookups } from '../../../../../../../utils/urlUtils';
import { disableListingPriceEdit } from '../../../../../utils';
import {
    taxSettings,
    flipTaxList,
    listingStatuses,
    buyerCommissionTypes,
    listingCommissionTypes,
} from '../../../../listingConstants';
import { ListingData } from '../../../../listingModels';
import { PricesModel } from '../priceModel';
import { shouldDisableField } from '../../../../../../../shared/auth/permissions/permissionsService';

interface SalePricesProps {
    renderRow: (
        label: string,
        property: string,
        disabled?: boolean,
        endAdornment?: string,
        refIndex?: number,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => JSX.Element;
    renderCheckBox: (
        label: string,
        property: string,
        disabled?: boolean,
        customField?: string,
        customFieldValue?: boolean,
    ) => JSX.Element;
    renderField: (
        label: string,
        property: string,
        disabled?: boolean,
        labelSize?: GridSize,
        controlSize?: GridSize,
    ) => JSX.Element;
    formData: PricesModel;
    setFormData: React.Dispatch<React.SetStateAction<PricesModel>>;
    setFormDataToPersist: () => void;
    handleRadioButtonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCurrencyChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checkLength?: boolean | undefined,
    ) => void;
    data: ListingData;
    inputRefs: React.MutableRefObject<HTMLInputElement[]>;
    canAccess: boolean;
}

const SalePrices: FunctionComponent<SalePricesProps> = ({
    renderRow,
    renderCheckBox,
    renderField,
    formData,
    setFormData,
    setFormDataToPersist,
    handleCurrencyChange,
    handleRadioButtonChange,
    data,
    inputRefs,
    canAccess,
}) => {
    const dispatch = useAppDispatch();
    const { dataSource, salesRegionId, priorBrokerSale, listingStatusId } = data;
    const isTrident = dataSource === 'Trident' ? true : false;

    useEffect(() => {
        if (Number(formData.maxFinancing) > 100 || Number(formData.flipTaxAmount) > 100) {
            dispatch(setError('Error: Incorrect value'));
        }
    }, [dispatch, formData.maxFinancing, formData.flipTaxAmount]);

    const handleDropDownChange = (data: LookupInfo, name: string) => {
        if (name === 'flipTaxSide') {
            setFormData({
                ...formData,
                flipTaxSide: data.id === '1' ? null : data.name,
            });
        }
    };

    //to handle the buyer comm radio change and update the comm based on selection
    const buyerCommRadioUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === '3') {
            setFormData({
                ...formData,
                buyerCommission: null,
                buyerCommissionType: 3,
            });
        } else {
            handleRadioButtonChange(event);
        }
    };

    return (
        <div className="pricesSection">
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'INITIAL PRICE*',
                        'initialPrice',
                        disableListingPriceEdit(),
                        '',
                        0,
                    )}
                    {renderRow(
                        'TRIDENT PRICE',
                        'tridentPrice',
                        isTrident || disableListingPriceEdit(),
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow('MLS PRICE', 'mlsPrice', shouldDisableField())}
                    <FormRow
                        label="CURRENT PRICE*"
                        control={
                            <TextField
                                value={currencyFormat(formData.currentPrice)}
                                onChange={handleCurrencyChange}
                                InputProps={{
                                    disableUnderline: true,
                                    'aria-label': 'currentPrice',
                                    className: `inputBaseRoot ${
                                        disableListingPriceEdit() ? 'inputDisabled' : ''
                                    }`,
                                    startAdornment: '$',
                                }}
                                inputProps={{ maxLength: 11 }}
                                onBlur={setFormDataToPersist}
                                name="currentPrice"
                                disabled={disableListingPriceEdit()}
                                inputRef={(e) => {
                                    if (e) inputRefs.current[1] = e;
                                }}
                            />
                        }
                        prefix=""
                        suffix=""
                        controlClass="formControlRoot"
                        labelXs={null}
                        labelSm={2}
                        labelMd={2}
                        controlXs={null}
                        controlSm={4}
                        controlMd={4}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item md={6} sm={6}>
                        {renderCheckBox(
                            'IGNORE TRIDENT PRICE UPDATES',
                            'ignoreTridentPrice',
                        )}
                    </Grid>
                    {renderCheckBox('PRICE UPON REQUEST', 'priceUponRequest')}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow('SOLD PRICE', 'soldPrice', disableListingPriceEdit())}
                    {listingStatusId === listingStatuses.sold &&
                        renderCheckBox(
                            'DISPLAY SOLD PRICE FOR SELECT SOLD',
                            'displayOfferPriceForSelectSold',
                            salesRegionId === 20 || salesRegionId === 9,
                        )}
                </Grid>
                {priorBrokerSale && (
                    <Grid
                        item
                        container
                        className="content"
                        alignItems="center"
                        spacing={2}
                        md={12}
                    >
                        {renderRow(
                            'LAST ASKING PRICE',
                            'lastAskingPrice',
                            disableListingPriceEdit(),
                        )}
                    </Grid>
                )}
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    <Grid item container alignItems="center" md={6}>
                        {formData.listingCommissionType &&
                        formData.listingCommissionType === 2
                            ? renderRow(
                                  'LISTING COMMISSION',
                                  'listingCommission',
                                  disableListingPriceEdit(),
                                  undefined,
                                  undefined,
                                  4,
                                  8,
                              )
                            : renderField(
                                  'LISTING COMMISSION',
                                  'listingCommission',
                                  disableListingPriceEdit(),
                                  4,
                                  8,
                              )}
                        <FormRow
                            label=""
                            control={
                                <RadioButtonToggle
                                    id={
                                        formData.listingCommissionType !== null
                                            ? formData.listingCommissionType
                                            : '1' //defaulted to percentage
                                    }
                                    name="listingCommissionType"
                                    items={listingCommissionTypes}
                                    handleRadioButtonChange={handleRadioButtonChange}
                                    handleBlur={setFormDataToPersist}
                                    formClass={{
                                        label: 'formControlLabel',
                                    }}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot customRadioButton"
                            labelXs={null}
                            labelSm={null}
                            labelMd={4}
                            controlXs={null}
                            controlSm={null}
                            controlMd={8}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems="baseline" md={12}>
                    <Grid
                        item
                        container
                        className="content"
                        alignItems="center"
                        spacing={2}
                        md={6}
                    >
                        {formData.buyerCommissionType &&
                        formData.buyerCommissionType === 2
                            ? renderRow(
                                  'BUYER COMMISSION',
                                  'buyerCommission',
                                  disableListingPriceEdit(),
                                  undefined,
                                  undefined,
                                  4,
                                  8,
                              )
                            : renderField(
                                  'BUYER COMMISSION',
                                  'buyerCommission',
                                  disableListingPriceEdit() ||
                                      formData.buyerCommissionType === 3,
                                  4,
                                  8,
                              )}
                        <FormRow
                            label=""
                            control={
                                <RadioButtonToggle
                                    id={
                                        formData.buyerCommissionType !== null
                                            ? formData.buyerCommissionType
                                            : '1' //defaulted to percentage
                                    }
                                    name="buyerCommissionType"
                                    items={buyerCommissionTypes}
                                    handleRadioButtonChange={buyerCommRadioUpdate}
                                    handleBlur={setFormDataToPersist}
                                    formClass={{
                                        label: 'formControlLabel',
                                    }}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot"
                            labelXs={null}
                            labelSm={null}
                            labelMd={4}
                            controlXs={null}
                            controlSm={null}
                            controlMd={8}
                        />
                    </Grid>
                    <Grid item container className="content" alignItems="center" md={6}>
                        {renderCheckBox(
                            'DO NOT DISPLAY BUYER COMMISSION ON WEBSITE *',
                            'displayBuyerCommissionOnWebsite',
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    className="content infoText"
                    alignItems="center"
                    md={12}
                >
                    * "Contact Listing Agent" text will appear on applicable websites
                </Grid>

                <Grid item container alignItems="baseline" md={12}>
                    <Grid
                        item
                        container
                        className="content"
                        alignItems="center"
                        spacing={2}
                        md={6}
                    >
                        {renderRow(
                            'REAL ESTATE TAXES',
                            'realEstateTax',
                            disableListingPriceEdit(),
                            undefined,
                            undefined,
                            4,
                            8,
                        )}
                        <FormRow
                            label=""
                            control={
                                <RadioButtonToggle
                                    id={
                                        formData.taxesAreMonthly !== null
                                            ? formData.taxesAreMonthly
                                            : ''
                                    }
                                    name="taxesAreMonthly"
                                    items={taxSettings}
                                    handleRadioButtonChange={handleRadioButtonChange}
                                    handleBlur={setFormDataToPersist}
                                    formClass={{
                                        label: 'formControlLabel',
                                    }}
                                />
                            }
                            prefix=""
                            suffix=""
                            controlClass="formControlRoot"
                            labelXs={null}
                            labelSm={null}
                            labelMd={4}
                            controlXs={null}
                            controlSm={null}
                            controlMd={8}
                        />
                    </Grid>
                    <Grid item container className="content" alignItems="center" md={6}>
                        {renderCheckBox(
                            'DO NOT DISPLAY TAX ON WEBSITE',
                            'doNotDisplayTaxesOnWebsite',
                        )}
                    </Grid>
                </Grid>
            </div>
            <div className="section">
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'COMMON CHARGES',
                        'commonCharges',
                        disableListingPriceEdit(),
                        '/month',
                    )}
                    {renderRow(
                        'MAINTENANCE',
                        'maintenance',
                        disableListingPriceEdit(),
                        '/month',
                    )}
                </Grid>
                <Grid
                    item
                    container
                    className="content"
                    alignItems="center"
                    spacing={2}
                    md={12}
                >
                    {renderRow(
                        'LISTING BUDGET',
                        'listingBudget',
                        disableListingPriceEdit(),
                    )}
                </Grid>
                {salesRegionId === 6 && (
                    <>
                        <Grid
                            item
                            container
                            className="content"
                            alignItems="center"
                            spacing={2}
                            md={12}
                        >
                            {renderField(
                                'MAX FINANCING',
                                'maxFinancing',
                                disableListingPriceEdit(),
                            )}
                            {renderField(
                                'FLIP TAX AMOUNT',
                                'flipTaxAmount',
                                disableListingPriceEdit(),
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            className="content"
                            alignItems="center"
                            spacing={2}
                            md={12}
                        >
                            <FormRow
                                label="FLIP TAX SIDE"
                                control={
                                    <DropDownSearch
                                        id={
                                            formData.flipTaxSide
                                                ? findIdFromLookups(
                                                      formData.flipTaxSide,
                                                      flipTaxList,
                                                  )
                                                : 1
                                        }
                                        items={flipTaxList}
                                        name="flipTaxSide"
                                        handleDropDownChange={handleDropDownChange}
                                        handleBlur={setFormDataToPersist}
                                    />
                                }
                                controlClass="formControlRoot"
                                labelXs={null}
                                labelSm={null}
                                labelMd={2}
                                controlXs={null}
                                controlSm={null}
                                controlMd={4}
                            />
                        </Grid>
                    </>
                )}
            </div>
        </div>
    );
};

export default SalePrices;
