import { FunctionComponent, useState } from 'react';
import { DesignVaultRedirect } from './designVaultModel';
import DesignImageSlider from './DesignImageSlider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import './DesignTemplate.scss';

const DesignTemplate: FunctionComponent<DesignVaultRedirect> = (props) => {
    const ssolink = 'https://app.marq.com/saml/sso/sir?return_url=';
    const redirectLink =
        props.templateGuid &&
        ssolink +
            props.template.createNew +
            '?propertyId=' +
            props.templateGuid +
            '&idpHash=1289405832';
    const imagesArray = props.template.images;
    const [position, updatePositon] = useState(0);
    const template = (
        <>
            <div className="templateImageHolder">
                {imagesArray.length === 1 ? (
                    <img
                        className="templateImage"
                        src={process.env.PUBLIC_URL + '/dv/' + props.template.images[0]}
                        alt={props.template.images[0]}
                    ></img>
                ) : (
                    <DesignImageSlider
                        images={imagesArray}
                        sliderPosition={position}
                    ></DesignImageSlider>
                )}
            </div>
            <div className="templateTitle">{props.template.title}</div>
            <div className="templateSpec">{props.template.specs}</div>
        </>
    );
    return (
        <div className="singleTemplateHolder">
            <div className="templatePreview">
                {imagesArray.length > 1 ? (
                    <span>
                        <IconButton
                            aria-label="Previous"
                            className="sliderNav"
                            disabled={position === 0}
                            onClick={() => updatePositon(position - 1)}
                        >
                            <ArrowBackIosIcon className="sliderIcons" />
                        </IconButton>
                        Page {position + 1} Preview
                        <IconButton
                            aria-label="Next"
                            className="sliderNav"
                            disabled={position === imagesArray.length - 1}
                            onClick={() => updatePositon(position + 1)}
                        >
                            <ArrowForwardIosIcon className="sliderIcons" />
                        </IconButton>
                    </span>
                ) : (
                    ''
                )}
            </div>
            {redirectLink ? (
                <a
                    href={redirectLink}
                    target="_blank"
                    className="templateLink"
                    rel="noreferrer"
                >
                    {template}
                </a>
            ) : (
                template
            )}
        </div>
    );
};

export default DesignTemplate;
