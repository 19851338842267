import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import { PaginationResponse } from '../../shared/models/pagination/paginationModels';
import { getAgentsFromApi } from './agentListApi';
import {
    AgentsResponse,
    AgentSearchRequest,
    AgentsState,
    AgentListUpdate,
    AgentTableActionType,
    agentInitialSearch,
} from './agentListModels';

const agentsState: AgentsState = {
    agents: {
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: 20,
        results: [],
    },
    tableAction: agentInitialSearch,
    progress: false,
    isSearched: false,
};
export const agentsSlice = createSlice({
    name: 'agents',
    initialState: agentsState,
    reducers: {
        addAgents: (state, action: PayloadAction<PaginationResponse<AgentsResponse>>) => {
            const results = [...state.agents.results, ...action.payload.results];
            let agents = {
                ...state.agents,
                currentPage: action.payload.currentPage,
                totalRecords: action.payload.totalRecords,
                recordsPerPage: action.payload.recordsPerPage,
            };
            if (
                state.agents.results.length !== 0 ||
                action.payload.results.length !== 0
            ) {
                agents = {
                    ...agents,
                    results:
                        state.agents.currentPage >= action.payload.currentPage
                            ? action.payload.results
                            : results,
                };
            }
            return {
                ...state,
                agents: agents,
            };
        },
        setProgress: (state, action: PayloadAction<boolean>) => {
            state.progress = action.payload;
        },
        updateAgentList: (state, action: PayloadAction<AgentListUpdate>) => {
            const { agentId } = action.payload;
            const findIndex = state.agents.results.findIndex(
                (x) => x.agentId === agentId,
            );
            const newValue = {
                ...state.agents.results[findIndex],
                ...action.payload,
            };
            state.agents.results[findIndex] = newValue;
        },
        updateTableAction: (state, action: PayloadAction<AgentTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        setIsSearched: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSearched: action.payload,
            };
        },
    },
});
export const getAgents =
    (agentsRequest: AgentSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setProgress(true));
            const response = await getAgentsFromApi(agentsRequest);
            if (
                response.currentPage ||
                response.recordsPerPage ||
                response.results.length
            )
                dispatch(addAgents(response));
        } catch (exception) {
            dispatch(setError(`Failed to retrieve the agent list`));
        } finally {
            // sam-1090 added timeout to delay the remove of loader
            setTimeout(() => {
                dispatch(setProgress(false));
            }, 1000);
        }
    };
export const {
    addAgents,
    setProgress,
    updateAgentList,
    updateTableAction,
    setIsSearched,
} = agentsSlice.actions;

export const agents = (state: RootState): AgentsState => state.agent.list;

export default agentsSlice.reducer;
