import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { SectionIdToNameMapping } from './cmrReportConstant';

import {
    CmrReport,
    CmrReportDataLeftPanel,
    CmrReportDataRightPanel,
} from './cmrReportModel';
import {
    fetchCmrReportDataLeftPanel,
    fetchCmrReportDataRightPanel,
} from './cmrReportApi';
import { setError } from '../../shared/slices/messaging/messagingSlice';
import { formatEventDate } from '../../shared/functions/viewsOnWeb/utils';
import Logger from '../../utils/logging/logger';

const initialCMRReportState: CmrReport = {
    selectedSection: 99,
    cmrReportLeftPanel: [],
    cmrReportRightPanel: {
        homePage: null,
        viewsOnWeb: null,
        inTheNews: null,
        advertisements: null,
        showings: null,
        emailCampaigns: null,
        socialCampaigns: null,
        openHouses: null,
        brokersLetter: null,
        featuredPlacements: null,
        additionalPromotion: null,
        agents: null,
        rfgid: '',
        showcaseSiteUrl: '',
    },
    currentActiveSection: 99,
    isLoading: true,
};

export const cmrReportSlice = createSlice({
    name: 'cmr',
    initialState: initialCMRReportState,
    reducers: {
        loadLeftPanelData: (state, action: PayloadAction<CmrReportDataLeftPanel[]>) => {
            state.cmrReportLeftPanel = action.payload;
        },
        updateRightPanelData: (state, action: PayloadAction<CmrReportDataRightPanel>) => {
            const {
                homePage,
                featuredPlacements,
                additionalPromotion,
                inTheNews,
                advertisements,
                showings,
                openHouses,
                socialCampaigns,
                emailCampaigns,
                brokersLetter,
                viewsOnWeb,
                agents,
                rfgid,
                showcaseSiteUrl,
            } = action.payload;
            state.cmrReportRightPanel.homePage = homePage;
            state.cmrReportRightPanel.featuredPlacements = featuredPlacements;
            state.cmrReportRightPanel.additionalPromotion = additionalPromotion;
            state.cmrReportRightPanel.inTheNews = inTheNews;
            state.cmrReportRightPanel.advertisements = advertisements;
            state.cmrReportRightPanel.showings = showings;
            state.cmrReportRightPanel.openHouses = openHouses;
            state.cmrReportRightPanel.socialCampaigns = socialCampaigns;
            state.cmrReportRightPanel.emailCampaigns = emailCampaigns;
            state.cmrReportRightPanel.brokersLetter = brokersLetter;
            state.cmrReportRightPanel.viewsOnWeb = formatEventDate(viewsOnWeb);
            state.cmrReportRightPanel.agents = agents;
            state.cmrReportRightPanel.rfgid = rfgid;
            state.cmrReportRightPanel.showcaseSiteUrl = showcaseSiteUrl;
            state.isLoading = false;
        },
        updateCurrentViewedPanel: (state, action: PayloadAction<number>) => {
            state.selectedSection = action.payload;
        },
        setSelectedSection: (state, action: PayloadAction<number>) => {
            state.currentActiveSection = action.payload;
        },
    },
});

export const loadCmrReportLeftPanelData =
    (listingNumber: string): AppThunk =>
    async (dispatch) => {
        try {
            const leftPanelData = await fetchCmrReportDataLeftPanel(listingNumber);
            if (leftPanelData && leftPanelData.length > 0) {
                const homePage: CmrReportDataLeftPanel = {
                    id: SectionIdToNameMapping.homePage,
                    sectionId: SectionIdToNameMapping.homePage,
                    sortOrder: null,
                    displayName: 'homepage',
                    displaySection: true,
                    childSections: null,
                };
                const overView: CmrReportDataLeftPanel = {
                    id: SectionIdToNameMapping.overView,
                    sectionId: SectionIdToNameMapping.overView,
                    sortOrder: null,
                    displayName: 'overview',
                    displaySection: true,
                    childSections: null,
                };
                const updatedLeftPanelData = [homePage, overView, ...leftPanelData];
                dispatch(loadLeftPanelData(updatedLeftPanelData));
            }
        } catch (e) {
        } finally {
        }
    };

export const loadCmrReportRightPanelData =
    (listingNumber: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await fetchCmrReportDataRightPanel(listingNumber);
            if (response && response.homePage && response.homePage.salesRegionId) {
                dispatch(updateRightPanelData(response));
            } else {
                dispatch(setError(`Failed to load data`));
            }
        } catch (e) {
            Logger.error('Failed to load report data ', e);
            dispatch(setError(`Failed to load data`));
        }
    };

export const {
    updateRightPanelData,
    updateCurrentViewedPanel,
    loadLeftPanelData,
    setSelectedSection,
} = cmrReportSlice.actions;
export const cmrReportDetails = (state: RootState): CmrReport => state.cmr.report;

export default cmrReportSlice.reducer;
