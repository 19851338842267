import { FunctionComponent } from 'react';
import { dateFormatMMDDYYY } from '../../../../../../utils/urlUtils';
import { AdvertisementData } from '../../../../cmrModels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { googleAnalyticsAction } from '../../../../../../shared/functions/googleAnalytics/ga';

interface ViewAdvertismentProps {
    advertisement: AdvertisementData;
    deleteAdvertisement(id: string | undefined): void;
    editAdvertisement(id: string | undefined): void;
}

const ViewAdvertisment: FunctionComponent<ViewAdvertismentProps> = (props) => {
    const { advertisement } = props;
    const ga = () => {
        googleAnalyticsAction('View detail', 'Click', 'Advertisement - Builder');
    };
    return (
        <div className="table-data">
            <div className="data-col-1">
                {advertisement.issueDate &&
                dateFormatMMDDYYY(advertisement.issueDate.toString()) ? (
                    <div className="data-date">
                        {dateFormatMMDDYYY(advertisement.issueDate.toString())}
                    </div>
                ) : (
                    ''
                )}
                {advertisement.publication &&
                advertisement.publication.trim().length > 0 ? (
                    <div className="data-publications">{advertisement.publication}</div>
                ) : (
                    ''
                )}
            </div>
            <div className="data-links">
                {!advertisement.allowEditing ? (
                    advertisement.tearsheetFilename &&
                    advertisement.tearsheetFilename.trim().length > 0 ? (
                        <a
                            href={advertisement.tearsheetFilename}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div onClick={ga}>TEAR SHEET</div>
                        </a>
                    ) : (
                        ''
                    )
                ) : advertisement.url && advertisement.url.trim().length > 0 ? (
                    advertisement.url.includes('.pdf') &&
                    advertisement.url.includes('http') ? (
                        <a href={advertisement.url} target="_blank" rel="noreferrer">
                            <div onClick={ga}>VIEW</div>
                        </a>
                    ) : (
                        <a href={advertisement.url} target="_blank" rel="noreferrer">
                            <div onClick={ga}>LINK</div>
                        </a>
                    )
                ) : (
                    ''
                )}
            </div>
            <div className="adv-icons">
                {advertisement.allowEditing ? (
                    <>
                        <span className="edit icon">
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="sm"
                                onClick={() => props.editAdvertisement(advertisement.id)}
                            />
                        </span>
                        <span className="trash icon">
                            <FontAwesomeIcon
                                icon={faTrash}
                                size="sm"
                                onClick={() =>
                                    props.deleteAdvertisement(advertisement.id)
                                }
                            />
                        </span>
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default ViewAdvertisment;
