import { CustomFormedData } from '../../../shared/models/customFormedData/customFormedData';
import Identity from './drawer/screens/identity';
import OfficeAccess from './drawer/screens/officeAccess';
import Roles from './drawer/screens/roles';
import DashRoles from './drawer/screens/dashRoles/index';
import { UserDetailsState } from './userDetailsModels';

export const identity: CustomFormedData[] = [
    {
        title: 'Status',
        value: 'status',
    },
    {
        title: 'Salutation',
        value: 'salutation',
    },
    {
        title: 'First Name',
        value: 'firstName',
    },
    {
        title: 'Middle Name',
        value: 'middleName',
    },
    {
        title: 'Last Name',
        value: 'lastName',
    },
    {
        title: 'Preferred First Name',
        value: 'preferredFirstName',
    },
    {
        title: 'Email',
        value: 'email',
    },
    {
        title: 'Title',
        value: 'title',
    },
    {
        title: 'Username',
        value: 'userName',
    },
    {
        title: 'Dash Id',
        value: 'dashStaffId',
    },
];

export const editFormComponent = {
    identity: 'Identity',
    officeAccess: 'OfficeAccess',
    roles: 'Roles',
    dashRoles: 'DashRoles',
};

export const editFormMap = new Map([
    [
        editFormComponent.identity,
        {
            component: Identity,
            title: 'Identity',
        },
    ],
    [
        editFormComponent.officeAccess,
        {
            component: OfficeAccess,
            title: 'Office Access',
        },
    ],
    [
        editFormComponent.roles,
        {
            component: Roles,
            title: 'Roles',
        },
    ],
    [
        editFormComponent.dashRoles,
        {
            component: DashRoles,
            title: 'Dash Roles',
        },
    ],
]);

export const initialUserDetails: UserDetailsState = {
    details: {
        id: '',
        status: '',
        salutation: '',
        firstName: '',
        middleName: '',
        lastName: '',
        preferredFirstName: '',
        email: '',
        title: '',
        userName: '',
        lastLogin: '',
        officeIds: [],
        roleIds: [],
        agents: [],
        userDashRoles: [],
        profileId: '',
        dashStaffId: '',
    },
    flags: {
        isSiteLoading: false,
    },
    editForm: {
        formOpen: false,
        userEditFormName: '',
    },
};
