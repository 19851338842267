import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { FunctionComponent } from 'react';
import FormRow from '../../../../../shared/component/formFields/FormRow';
import { datePickerFormat } from '../../../../../utils/urlUtils';
import { DevelopmentDetailsData } from '../../../developmentDetails/developmentDetailsModel';
import { CreateDevelopmentRequest } from '../createDevelopmentModel';

interface DatesProps {
    formData: CreateDevelopmentRequest | DevelopmentDetailsData;
    handleTextChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxLength?: number | undefined,
        validateHttps?: boolean,
    ) => void;
    handleDateChange: (field: string, value: Moment | null) => void;
    setFormDataToPersistInState?: () => void;
}

const Dates: FunctionComponent<DatesProps> = ({
    formData,
    handleTextChange,
    handleDateChange,
    setFormDataToPersistInState,
}) => {
    return (
        <>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    id="listDate"
                    label="List Date*"
                    control={
                        <DatePicker
                            value={
                                formData.listDate ? moment(formData.listDate) : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('listDate', date);
                            }}
                            name={'listDate'}
                            showToday={false}
                            className="datePickerCommon listingDate"
                            style={{
                                padding: '5px 10px',
                            }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    id="expirationDate"
                    label="Expiration Date*"
                    control={
                        <DatePicker
                            value={
                                formData.expirationDate
                                    ? moment(formData.expirationDate)
                                    : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('expirationDate', date);
                            }}
                            name="expirationDate"
                            showToday={false}
                            className="datePickerCommon expirationDate"
                            style={{
                                padding: '5px 10px',
                            }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
            <Grid item container md={12} alignItems="center">
                <FormRow
                    id="agreementDate"
                    label="Agreement Date*"
                    control={
                        <DatePicker
                            value={
                                formData.agreementDate
                                    ? moment(formData.agreementDate)
                                    : undefined
                            }
                            format={datePickerFormat()}
                            onChange={(date: Moment | null) => {
                                handleDateChange('agreementDate', date);
                            }}
                            name={'agreementDate'}
                            showToday={false}
                            className="datePickerCommon listingDate"
                            style={{
                                padding: '5px 10px',
                            }}
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    controlClass="formControlRoot"
                    labelMd={2}
                    controlMd={4}
                />
                <FormRow
                    id="websiteUrl"
                    label="Website Url*"
                    controlClass="formControlRoot"
                    control={
                        <TextField
                            value={formData.websiteUrl || ''}
                            onChange={(e) => handleTextChange(e, 247, true)}
                            InputProps={{
                                disableUnderline: true,
                                className: 'inputBaseRoot',
                            }}
                            name="websiteUrl"
                            onBlur={setFormDataToPersistInState}
                        />
                    }
                    labelMd={2}
                    controlMd={4}
                />
            </Grid>
        </>
    );
};

export default Dates;
