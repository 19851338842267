import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { Grid, Switch } from '@material-ui/core';
import FormRow from '../../../../../../shared/component/formFields/FormRow';
import { DrawerContainerProps } from '../../../officeModels';
import { OfficeSettings } from './officeSettingsModels';
import { getSettings, saveSettings, officeSettings } from './officeSettingsSlice';

const Settings: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const dispatch = useAppDispatch();
    const {
        data: { officeDetails },
    } = editFormData;
    const officeId = officeDetails.id || '';
    const { settings } = useAppSelector(officeSettings);
    const [currentOfficeSettings, setCurrentOfficeSettings] = useState<
        OfficeSettings | undefined
    >(settings);

    useEffect(() => {
        if (!settings) {
            dispatch(getSettings(officeId));
        } else {
            setCurrentOfficeSettings(settings);
        }
    }, [settings, dispatch]);

    const handleSwitchToggle = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        const updatedSettings: OfficeSettings = {
            ...currentOfficeSettings,
            [name]: checked,
        };
        dispatch(saveSettings(officeId, updatedSettings));
    };

    const renderRow = (
        name: keyof OfficeSettings,
        label: string,
        tooltipText: string,
        checked?: boolean,
    ) => {
        return (
            <FormRow
                label={label}
                labelMd={4}
                controlMd={8}
                labelTooltip={true}
                labelTooltipText={tooltipText}
                control={
                    <div className="customSwitch">
                        <Switch
                            className="switchIcon"
                            name={name}
                            checked={checked || false}
                            onChange={handleSwitchToggle}
                        />
                    </div>
                }
            ></FormRow>
        );
    };

    return (
        <>
            <Grid
                item
                container
                className="content"
                alignItems="center"
                spacing={2}
                md={12}
            >
                {renderRow(
                    'defaultPublish',
                    'New Listings: Default Publish to True',
                    'Indicates if new listings should be published.  Used for drafts and the MLS import',
                    currentOfficeSettings?.defaultPublish,
                )}
                {renderRow(
                    'allowSoldsToDisplaySoldPrice',
                    'Sold Listings: Allow Display of Sold Price',
                    "Indicates if office's listings should allow displaying of sold prices.  Used in agent/showcase sites.",
                    currentOfficeSettings?.allowSoldsToDisplaySoldPrice,
                )}
                {renderRow(
                    'allowDraftSendToTrident',
                    'Draft Listings: Send to Trident',
                    'Indicates if draft listings can be sent to Trident.',
                    currentOfficeSettings?.allowDraftSendToTrident,
                )}
                {renderRow(
                    'allowRentalSendToTrident',
                    'Rental Listings: Send to Trident',
                    'Indicates if rental listings can be sent to Trident.',
                    currentOfficeSettings?.allowRentalSendToTrident,
                )}
                {renderRow(
                    'defaultShowAddress',
                    'New Listings: Default Show Address to True',
                    'Indicates if new listings should have their address and map displayed.  Used by MLS and Trident imports.',
                    currentOfficeSettings?.defaultShowAddress,
                )}
                {renderRow(
                    'allowTridentBuyerSideRentals',
                    'Trident: Allow Buyer Side Rentals',
                    'Indicates if we can import buyer-side rentals from Trident.',
                    currentOfficeSettings?.allowTridentBuyerSideRentals,
                )}
                {renderRow(
                    'defaultSelectSale',
                    'Agents: Default Select Sales',
                    'Indicates if agents for the office should have their new listings automatically be assigned as select sales.  Used by the Trident agent import.',
                    currentOfficeSettings?.defaultSelectSale,
                )}
                {renderRow(
                    'showAgentTitle',
                    'Agents: Show Title',
                    "Indicates if we need to display the agent's title.  Used in client marketing report emails.",
                    currentOfficeSettings?.showAgentTitle,
                )}
                {renderRow(
                    'allowNewMlsListings',
                    'MLS: Allow New Listings',
                    "Indicates if new listings can be imported from the office's MLS.",
                    currentOfficeSettings?.allowNewMlsListings,
                )}
                {renderRow(
                    'suppressMlsImport',
                    'MLS: Supress Listing Import',
                    'Indicates if we should suppress MLS imports for new listings.  Used by the Trident import.',
                    currentOfficeSettings?.suppressMlsImport,
                )}
                {renderRow(
                    'suppressMlsPhotoImport',
                    'MLS: Supress Photo Import',
                    'Indicates if we should suppress MLS photo imports for new listings.  Used by the Trident import.',
                    currentOfficeSettings?.suppressMlsPhotoImport,
                )}
                {renderRow(
                    'suppressMlsAdCopyImport',
                    'MLS: Supress Ad Copy Import',
                    'Indicates if we should suppress MLS ad copy imports for new listings.  Used by the Trident import.',
                    currentOfficeSettings?.suppressMlsAdCopyImport,
                )}
                {renderRow(
                    'suppressMlsAgentImport',
                    'MLS: Supress Agent Import',
                    'Indicates if we should suppress MLS agent imports for new listings.  Used by the Trident import.',
                    currentOfficeSettings?.suppressMlsAgentImport,
                )}
            </Grid>
        </>
    );
};

export default Settings;
